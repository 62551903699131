import * as constants from './constants';
import {fromJS} from 'immutable';
import { actionCreators } from '.';

const defaultState=fromJS({
    filenav:"aboutus"
});

export default(state=defaultState,action)=>{
    if(action.type===constants.ABOUT_US_CLICK){
        return state.set('filenav','aboutus')
    }
    if(action.type===constants.REGISTER_FILE){
        return state.set('filenav','registerfile')
    }
    if(action.type===constants.HELP_CENTER_CLICK){
        return state.set('filenav','helpcenter')
    }
    if(action.type===constants.LINK_US_CLICK){
        return state.set('filenav','linkus')
    }
    if(action.type===constants.PRIVACY_CLICK){
        return state.set('filenav','privacy')
    }
    if(action.type===constants.TRADEMARK_CLICK){
        return state.set('filenav','trademark')
    }
    if(action.type===constants.SERVICE_AGREEMENT_CLICK){
        return state.set('filenav','serviceagreement')
    }
    if(action.type===constants.COMMUNITY_AGREEMENT_CLICK){
        return state.set('filenav','communityagreement')
    }
    if(action.type===constants.INFRINGEMENT_CLICK){
        return state.set('filenav','infringement')
    }
    if(action.type===constants.BUSINESS_LICENSE_CLICK){
        return state.set('filenav','businesslicense')
    }
    return state;
}
