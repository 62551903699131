import React, { Component } from 'react';
import PersonRegister from './components/Person';

export default class Register extends Component {

    async componentDidMount() {

    }

    render() {
        return (
            <PersonRegister />
        )
    }
}
