import styled from 'styled-components';
import {
    themenew,theme
} from '../../../../common/color';


export const BrandsListWrapper = styled.div`
    padding: 0.75rem 0;
    margin-left: 4%;
    width: 92%;
    border-bottom: 0.5px solid #E1E1E1;
    text-align: left;
    background: #fff;
    height: 4.375rem;
    position: relative;
`
export const BrandsListImg = styled.div`
    width: 2.875rem;
    height: 2.875rem;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 0.75rem;
    border-radius: 50%;
    >img{ 
        width:100%;
        min-height:2.875rem;
        object-fit: cover;
    }
`
export const BrandsListEn = styled.div`
    color: ${themenew.text_color};
    font-size: 1.125rem;
`
export const BrandsListCn = styled.div`
    color: ${themenew.subtext_color};
    font-size: 0.875rem;
    margin-top: 0.75rem;
`

export const ProductList = styled.div`
    width: 92%;
    margin-left: 4%;
    padding-top: 0.75rem;
    position: relative;
`
export const ProductListBtn = styled.div`
  color:${themenew.subtext_color};
  position: absolute;
  bottom: 1rem;
  right: 0;
  >span{
    font-size:0.75rem;
  }
  i {
      color: ${theme.rose_color};
  }
`
export const ProductListTitle = styled.div`
    width: 70%;
    font-size: 1.125rem;
    color: ${themenew.text_color};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.56rem;
    float: left;
`
export const ProductListImg = styled.div`
    width: 5rem;
    height: 5rem;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 0.5rem;
    margin-bottom: 0.56rem;
    border: 0.5px solid #d6d6d6;
    border-radius: 0.375rem;
    img{ 
        width:100%;
        min-height:5rem;
        object-fit: cover;
    }
`
export const ProductListLine = styled.div`
    clear: both;
    border-bottom: 0.5px solid #d6d6d6;
`
export const ProductListPub = styled.div`
    line-height:2rem;
    float: left;
    img{
        width:1.125rem;
        height:1.125rem;
        border-radius:50%;
        margin-right:0.375rem;
        vertical-align:middle;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }
    >span{
        display: inline-block;
        height: 1.125rem;
        line-height: 1.125rem;
        max-width: 9rem;
        margin-right:0.625rem;
        font-size:0.75rem;
        color:${themenew.subtext_color};
        vertical-align:middle;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap
    }
`