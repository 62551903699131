import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionCreators } from './store';
import AboutUs from './components/AboutUs';
import BusinessLicense from './components/BusinessLicense';
import CommunityAgreement from './components/CommunityAgreement';
import HelpCenter from './components/HelpCenter';
import Infringement from './components/Infringement';
import LinkUs from './components/LinkUs';
import Privacy from './components/Privacy';
import ServiceAgreement from './components/ServiceAgreement';
import Trademark from './components/Trademark';
import RegisterFile from './components/RegisterFile'
import {
    FileWrapper,
    Nav,
    NavItem,
    Content,
    NavBar,
    ReturnButton
} from './FileStyle';
import { Toast } from 'antd-mobile-v2';
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
const history = creatHistory();//返回上一页这段代码
const returnButton = require('../../images/return_button.svg')
class File extends Component {
    state = {
        filenav1: ''
    };
    componentDidMount() {
        if (this.props.match.params.id === 'privacy') {
            this.setState({
                filenav1: 'privacy'
            })
        };
        // Toast.info(JSON.stringify(this.props.match.params.id));
        const type = sessionStorage.getItem('AUDIO_FILE_TYPE');
        if (type === 'register') {
            this.props.handleRegisterFile();
            sessionStorage.setItem('AUDIO_FILE_TYPE', '')
        } else if (type === 'privacy') {
            this.props.handlePrivacyClick();
            sessionStorage.setItem('AUDIO_FILE_TYPE', '')
        }
    }

    goBackPage = () => { 
        history.goBack();//返回上一页这段代码
    }
    render() {
        const {
            filenav,
            handleAboutUsClick, handleHelpCenterClick, handleLinkUsClick, handlePrivacyClick,
            handleTrademarkClick, handleServiceAgreementClick, handleCommunityAgreementClick,
            handleInfringementClick, handleBusinessLicenseClick, handleRegisterFile
        } = this.props;
        const {
            filenav1
        } = this.state;
        return (
            <FileWrapper>
                <NavBar>
                    <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                    {filenav1 !== 'privacy' && filenav === 'aboutus' && <span>关于中乐之声</span>}
                    {filenav1 !== 'privacy' && filenav === 'registerfile' && <span>中乐之声用户协议</span>}
                    {filenav1 !== 'privacy' && filenav === 'linkus' && <span>联系我们/帮助中心</span>}
                    {filenav1 !== 'privacy' && filenav === 'helpcenter' && <span>法律声明</span>}
                    {filenav1 !== 'privacy' && filenav === 'trademark' && <span>商标声明</span>}
                    {filenav1 !== 'privacy' && filenav === 'serviceagreement' && <span>版权声明</span>}
                    {filenav1 !== 'privacy' && filenav === 'privacy' && <span>“中乐之声”隐私政策</span>}
                    {filenav1 === 'privacy' && <span>“中乐之声”隐私政策</span>}
                    {filenav1 !== 'privacy' && filenav === 'communityagreement' && <span>网站免责声明</span>}
                    {filenav1 !== 'privacy' && filenav === 'infringement' && <span>网络信息不良违法或侵权举报</span>}
                    {filenav1 !== 'privacy' && filenav === 'businesslicense' && <span>友情链接</span>}
                </NavBar>
                <div style={{ height: '5.5rem' }}></div>{/* NavBar的高度占位 */}
                <Content>
                    {
                        filenav1 !== 'privacy' && filenav === 'aboutus' ? <AboutUs /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'registerfile' ? <RegisterFile /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'helpcenter' ? <HelpCenter /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'linkus' ? <LinkUs /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'privacy' ? <Privacy /> : ''
                    }
                    {
                        filenav1 === 'privacy' ? <Privacy /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'trademark' ? <Trademark /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'serviceagreement' ? <ServiceAgreement /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'communityagreement' ? <CommunityAgreement /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'infringement' ? <Infringement /> : ''
                    }
                    {
                        filenav1 !== 'privacy' && filenav === 'businesslicense' ? <BusinessLicense /> : ''
                    }
                </Content>
            </FileWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filenav: state.getIn(['file', 'filenav'])
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        handleAboutUsClick() {
            dispatch(actionCreators.aboutUsClick());
        },
        handleRegisterFile() {
            dispatch(actionCreators.registerFile())
        },
        handleHelpCenterClick() {
            dispatch(actionCreators.helpCenterClick());
        },
        handleLinkUsClick() {
            dispatch(actionCreators.linkUsClick());
        },
        handlePrivacyClick() {
            dispatch(actionCreators.privacyClick());
        },
        handleTrademarkClick() {
            dispatch(actionCreators.trademarkClick());
        },
        handleServiceAgreementClick() {
            dispatch(actionCreators.serviceAgreementClick())
        },
        handleCommunityAgreementClick() {
            dispatch(actionCreators.communityAgreementClick())
        },
        handleInfringementClick() {
            dispatch(actionCreators.infringementClick())
        },
        handleBusinessLicenseClick() {
            dispatch(actionCreators.businessLicenseClick())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(File);
