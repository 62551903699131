import styled from 'styled-components';
import {
  theme, themenew
} from '../../../common/color';

export const NavBar = styled.div`
  width:100%;
  height:5.5rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.375rem;
    color:${themenew.text_color};
    margin-top:3.6rem;
    font-weight:700;
  }
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:4%;
  margin-top:3.5rem;
  float:left;
`
export const SearchIcon = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-right:4%;
  margin-top:3.5rem;
  float:right;
`
export const VideoIcon = styled.div`
  width: 100%;
  height: 3.125rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  z-index: 99;
  span{
    color: #fff;
    font-size: 3.125rem;
  }
`
export const ExhibitionDiv = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
  opacity: 0.6;
  position: absolute;
`
export const ExhibitionTime = styled.div`
  font-size: 1rem;
  color: #fff;
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
`
