/**
 * created by sunchenyao on 2021/2/2
 */
import React, {
    Component
} from 'react';
import {
    SettingsWrapper,
} from '../../../../common/generalStyle';
import { List } from 'antd-mobile-v2';

const Item = List.Item;

export default class Collection extends Component {
    state = {
        userPID:"",
    };
    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        };
    }
    render() {
        return <SettingsWrapper>
            <List renderHeader={() => ''}>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的资讯", weburl: "/MyCollectNews" + '?' + this.state.userPID })) }}>资讯</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的展会⁺", weburl: "/MyCollectExhibition" + '?' + this.state.userPID })) }}>展会⁺</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的社区", weburl: "/MyCollectPost" + '?' + this.state.userPID })) }}>社区</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的评测", weburl: "/MyCollectEvaluation" + '?' + this.state.userPID })) }}>评测</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的品牌", weburl: "/MyCollectBrands" + '?' + this.state.userPID })) }}>品牌</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的产品", weburl: "/MyCollectProduct" + '?' + this.state.userPID })) }}>产品</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的视&音", weburl: "/MyCollectAudition" + '?' + this.state.userPID })) }}>视{"&"}音</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我收藏的直播回看", weburl: "/MyCollectLookBack" + '?' + this.state.userPID })) }}>直播回看</Item>
            </List>
        </SettingsWrapper>
    }
}