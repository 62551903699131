import React, { Component } from 'react';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleNoImg,
    NewsTitleOneImg,
    NewsPubs,
    VideoImg,
    VideoIcon,
} from '../../common/generalStyle';
import {
    startMinsTime
} from '../../utils/utils';
const playSvg = require('../../images/play.svg');
const listVipLogo = require('../../images/listVipLogo.png');

class Item extends Component {

    render() {

        var obj = this.props.data;
        var value = this.props.value;

        //高亮词
        if (value != "" && obj != null) {
            var re = new RegExp(value, "g"); //定义正则
            obj.Title = obj.Title.replace(re, `<span>${value}</span>`); //进行替换，并定义高亮的样式
            if (obj.SubTitle != null) {
                obj.SubTitle = obj.SubTitle.replace(/<[^>]+>/g, ""); //截取html标签
                obj.SubTitle = obj.SubTitle.replace(/&nbsp;/ig, ""); //截取空格等特殊标签
                obj.SubTitle = obj.SubTitle.replace(re, `<span>${value}</span>`); //进行替换，并定义高亮的样式
            }
            if (obj.Content != null) {
                obj.Content = obj.Content.replace(/<[^>]+>/g, ""); //截取html标签
                obj.Content = obj.Content.replace(/&nbsp;/ig, ""); //截取空格等特殊标签
                obj.Content = obj.Content.replace(re, `<span>${value}</span>`); //进行替换，并定义高亮的样式
            }
        }

        return (
            <NewsDetailWrapper >
                {obj.type == 1 &&
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/news' + '?' + this.props.userPID, })); }}>
                        {
                            obj.ShowType == 3 ?
                                <NewsItemOther div key={obj.ID} >
                                    <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                    <VideoImg>
                                        {
                                            obj.ContentImage === null ? <img src={
                                                obj.ImageAppUrl.split(';')[0]
                                            } alt="" /> : <img src={obj.ContentImage} alt="" />
                                        }
                                        <VideoIcon>
                                            <img src={playSvg} alt="" />
                                        </VideoIcon>
                                    </VideoImg>
                                </NewsItemOther >
                                :
                                <>
                                    {
                                        obj.ShowType == 0 ?
                                            <NewsItem div key={obj.ID} >
                                                {obj.ContentImage != null ? <>
                                                    <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                    <NewsImgWrap>
                                                        <img src={obj.ContentImage} alt="" />
                                                    </NewsImgWrap>
                                                </> : <NewsTitleNoImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                }
                                            </NewsItem >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 2 ?
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl.split(';').map((image, index) => (
                                                                        index < 3 && <div><img src={image} alt="" /></div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                        :
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsImgWrap>
                                                                {
                                                                    obj.ContentImage === null ? <img src={
                                                                        obj.ImageAppUrl.split(';')[0]
                                                                    } alt="" /> : <img src={obj.ContentImage} alt="" />
                                                                }
                                                            </NewsImgWrap>
                                                        </NewsItem >
                                                }
                                            </>
                                    }
                                </>
                        }
                        <NewsPubs>
                            {
                                obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                            }
                            <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span>
                        </NewsPubs>
                    </a>
                }
                {obj.type == 2 &&
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/exhibition' + '?' + this.props.userPID, })); }}>
                        {
                            obj.ShowType == 3 ?
                                <NewsItemOther div key={obj.ID} >
                                    <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                    <VideoImg>
                                        {
                                            obj.ContentImage === null ? <img src={
                                                obj.ImageAppUrl.split(';')[0]
                                            } alt="" /> : <img src={obj.ContentImage} alt="" />
                                        }
                                        <VideoIcon>
                                            <img src={playSvg} alt="" />
                                        </VideoIcon>
                                    </VideoImg>
                                </NewsItemOther >
                                :
                                <>
                                    {
                                        obj.ShowType == 0 ?
                                            <NewsItem div key={obj.ID} >
                                                {obj.ContentImage != null ? <>
                                                    <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                    <NewsImgWrap>
                                                        <img src={obj.ContentImage} alt="" />
                                                    </NewsImgWrap>
                                                </> : <NewsTitleNoImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                }
                                            </NewsItem >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 2 ?
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl.split(';').map((image, index) => (
                                                                        index < 3 && <div><img src={image} alt="" /></div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                        :
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsImgWrap>
                                                                {
                                                                    obj.ContentImage === null ? <img src={
                                                                        obj.ImageAppUrl.split(';')[0]
                                                                    } alt="" /> : <img src={obj.ContentImage} alt="" />
                                                                }
                                                            </NewsImgWrap>
                                                        </NewsItem >
                                                }
                                            </>
                                    }
                                </>
                        }
                        <NewsPubs>
                            {
                                obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                            }
                            <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span>
                        </NewsPubs>
                    </a>
                }
                {obj.type == 5 &&
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/community' + '?' + this.props.userPID, })); }}>
                        {
                            obj.ShowType == 3 ?
                                <NewsItemOther div key={obj.ID} >
                                    <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                    <VideoImg>
                                        {
                                            obj.ContentImage === null ? <img src={
                                                obj.ImageAppUrl.split(';')[0]
                                            } alt="" /> : <img src={obj.ContentImage} alt="" />
                                        }
                                        <VideoIcon>
                                            <img src={playSvg} alt="" />
                                        </VideoIcon>
                                    </VideoImg>
                                </NewsItemOther >
                                :
                                <>
                                    {
                                        obj.ShowType == 0 ?
                                            <NewsItem div key={obj.ID} >
                                                {obj.ContentImage != null ? <>
                                                    <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                    <NewsImgWrap>
                                                        <img src={obj.ContentImage} alt="" />
                                                    </NewsImgWrap>
                                                </> : <NewsTitleNoImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                }
                                            </NewsItem >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 2 ?
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl.split(';').map((image, index) => (
                                                                        index < 3 && <div><img src={image} alt="" /></div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                        :
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsImgWrap>
                                                                {
                                                                    obj.ContentImage === null ? <img src={
                                                                        obj.ImageAppUrl.split(';')[0]
                                                                    } alt="" /> : <img src={obj.ContentImage} alt="" />
                                                                }
                                                            </NewsImgWrap>
                                                        </NewsItem >
                                                }
                                            </>
                                    }
                                </>
                        }
                        <NewsPubs>
                            {
                                obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                            }
                            <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span>
                        </NewsPubs>
                    </a>
                }
                {obj.type == 3 &&
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/evaluation' + '?' + this.props.userPID, })); }}>
                        {
                            obj.ShowType == 3 ?
                                <NewsItemOther div key={obj.ID} >
                                    <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                    <VideoImg>
                                        {
                                            obj.ContentImage === null ? <img src={
                                                obj.ImageAppUrl.split(';')[0]
                                            } alt="" /> : <img src={obj.ContentImage} alt="" />
                                        }
                                        <VideoIcon>
                                            <img src={playSvg} alt="" />
                                        </VideoIcon>
                                    </VideoImg>
                                </NewsItemOther >
                                :
                                <>
                                    {
                                        obj.ShowType == 0 ?
                                            <NewsItem div key={obj.ID} >
                                                {obj.ContentImage != null ? <>
                                                    <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                    <NewsImgWrap>
                                                        <img src={obj.ContentImage} alt="" />
                                                    </NewsImgWrap>
                                                </> : <NewsTitleNoImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                }
                                            </NewsItem >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 2 ?
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl.split(';').map((image, index) => (
                                                                        index < 3 && <div><img src={image} alt="" /></div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                        :
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg dangerouslySetInnerHTML={{ __html: obj.Title }} />
                                                            <NewsImgWrap>
                                                                {
                                                                    obj.ContentImage === null ? <img src={
                                                                        obj.ImageAppUrl.split(';')[0]
                                                                    } alt="" /> : <img src={obj.ContentImage} alt="" />
                                                                }
                                                            </NewsImgWrap>
                                                        </NewsItem >
                                                }
                                            </>
                                    }
                                </>
                        }
                        <NewsPubs>
                            {
                                obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                            }
                            <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span>
                        </NewsPubs>
                    </a>
                }
                {obj.type == 4 &&
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/audition' + '?' + this.props.userPID, })); }}>
                        <NewsItemOther div key={obj.ID} >
                            <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                            <VideoImg>
                                {
                                    obj.ContentImage === null ? <img src={
                                        obj.ImageAppUrl.split(';')[0]
                                    } alt="" /> : <img src={obj.ContentImage} alt="" />
                                }
                                <VideoIcon>
                                    <img src={playSvg} alt="" />
                                </VideoIcon>
                            </VideoImg>
                        </NewsItemOther >
                        <NewsPubs>
                            {
                                obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                            }
                            <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span>
                        </NewsPubs>
                    </a>
                }
                {obj.type == 7 &&
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/lookback' + '?' + this.props.userPID, })); }}>
                        <NewsItemOther div key={obj.ID} >
                            <NewsTitle dangerouslySetInnerHTML={{ __html: obj.Title }} />
                            <VideoImg>
                                {
                                    obj.ContentImage === null ? <img src={
                                        obj.ImageAppUrl.split(';')[0]
                                    } alt="" /> : <img src={obj.ContentImage} alt="" />
                                }
                                <VideoIcon>
                                    <img src={playSvg} alt="" />
                                </VideoIcon>
                            </VideoImg>
                        </NewsItemOther >
                        <NewsPubs>
                            {
                                obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                            }
                            <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span>
                        </NewsPubs>
                    </a>
                }
            </NewsDetailWrapper>
        )
    }
}

export default Item;
