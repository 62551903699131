import React, {
    Component
} from 'react';
import 'swiper/dist/css/swiper.min.css';
import 'antd/dist/antd.css';
import {
    BannerWrapper,
    Banner
} from './BannerStyle';
import httpAxios2 from "../../../http/httpAxios2";
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import { Swiper } from 'antd-mobile'

class BigBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerList: [],
            data: [],
            imgHeight: 172,
            liveID: 1,
            livedata: [],
        }
    }
    async componentDidMount() {
        this.getLiveData();
        const res = await httpAxios2(api.getNewsBanner, {
            Type: this.props.type
        });
        // console.log(res);
        if (res) {
            this.setState({
                data: res.Data
            })
        }
    }
    getLiveData = async () => {
        const res = await httpAxios(api.getLiveUrl, {
            liveID: this.state.liveID
        });
        if (res) {
            this.setState({
                livedata: res.Data,
            })
        }
    }
    render() {
        return (
            <BannerWrapper>
                <Banner>
                    <Swiper
                        autoplay
                        loop
                        indicatorProps={{
                            color: 'white',
                        }}
                        style={{
                            '--border-radius': '0.5rem',
                        }}
                        autoplayInterval={8000}
                    >
                        {this.state.data.map(val => (
                            <Swiper.Item ><div
                                onClick={() => {
                                    window.ReactNativeWebView.postMessage(JSON.stringify(
                                        val.LinkedID === 0 ? { type: "openlink", url: val.URL }
                                            : val.LinkedID === 1 ? { type: "detailnav", weburl: '/community/communitydetail/' + val.URL + '/news' + '?' + this.props.userPID }
                                                : val.LinkedID === 2 ? { type: "detailnav", weburl: '/exhibition/ExhibitionDetailList/' + val.URL + '/' + val.BannerTitle }
                                                    : val.LinkedID === 3 ? { type: "detailnav", weburl: '/community/communitydetail/' + val.URL + '/evaluation' + '?' + this.props.userPID }
                                                        : val.LinkedID === 4 ? { type: "detailnav", weburl: '/community/communitydetail/' + val.URL + '/audition' + '?' + this.props.UserPID }
                                                            : val.LinkedID === 5 ? { type: "detailnav", weburl: '/community/communitydetail/' + val.URL + '/community' + '?' + this.props.userPID }
                                                                : val.LinkedID === 7 ? { type: "ToLive", id: val.URL }
                                                                    : val.LinkedID === 8 ? { type: "detail", weburl: "/starDetail/" + val.URL }
                                                                        : val.LinkedID === 9 ? { type: "detail", weburl: "/TrainingDetail/" + val.URL }
                                                                            : val.LinkedID === 10 ? { type: "detailnav", weburl: '/community/communitydetail/' + val.URL + '/lookback' + '?' + this.props.userPID }
                                                                                : ''
                                    ))
                                }}
                            >
                                <img
                                    src={val.IMGURL + '?x-oss-process=image/resize,w_690,m_lfit'}
                                    alt=""
                                    style={{ width: '100%', height: '100%', borderRadius: "0.5rem" }}
                                />
                            </div>
                            </Swiper.Item>
                        ))}
                    </Swiper>
                </Banner>
            </BannerWrapper >
        )
    }
}

export default BigBanner