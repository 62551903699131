import React, {
    Component
} from 'react'
// import Header from './common/header'
import {
    Provider
} from 'react-redux';
import {
    BrowserRouter,
    Route
} from 'react-router-dom';
// import '../src/index.css';


import store from './store'
import Home from './pages/home'
import Community from './pages/community'
import News from './pages/news'
import StarHome from './pages/star'
import TrainingHome from './pages/training'
import starDetail from './pages/star/starDetail'
import TrainingDetail from './pages/training/starDetail'
import invitationDetail from './pages/star/invitationDetail'
import TraininginvitationDetail from './pages/training/invitationDetail'
import SearchInvitation from './pages/searchInvitation'
import AllStarList from './pages/star/AllStarList'
import AllTrainingList from './pages/training/AllStarList'
import StarMore from './pages/star/components/starMore'
import TrainingMore from './pages/training/components/starMore'
import Evaluation from './pages/evaluation'
import Audition from './pages/audition'
import ToLive from './pages/toLive'
import AddLiveApply from './pages/mycenter/components/MyLive/AddLiveApply';
import Exhibition from './pages/exhibition'
import ExhibitionDetailList from './pages/exhibition/ExhibitionDetailList'
import ExhibitionDetailListH5 from './pages/exhibition/ExhibitionDetailListH5'
import ReportComments from './pages/toLive/components/ReportComments'
import ReportPersonal from './pages/toLive/components/ReportPersonal'
import Search from './pages/search'
import Register from './pages/register'
import Login from './pages/login/Login'
import ForgetPass from './pages/login/component/Forget'
import ForgetNew from './pages/login/component/ForgetNew'
import CommunityDetail from './pages/community/CommunityDetail'
import CommunityDetailH5 from './pages/community/CommunityDetailH5'
import GpsCitySelect from './pages/community/GpsCitySelect'
import TextAreaMessage from './pages/community/TextAreaMessage'
import LivebroadcastDetailH5 from './pages/community/LivebroadcastDetailH5'
import ZhongyuexinggouH5 from './pages/community/ZhongyuexinggouH5'

import ReplyComment from './pages/community/ReplyComment'
import File from './pages/file'
import Privacy from './pages/files/Privacy'
import RegisterFile from './pages/files/RegisterFile'
import VIPAgreement from './pages/files/VIPAgreement'
import XunjiaFile from './pages/files/XunjiaFile'
import AboutUs from './pages/files/AboutUs'
import WithRouter from './utils/withRouter'
import Advertising from './pages/advertising'
import CompanyAuthor from './pages/author/CompanyAuthor'
import CompanyContentMore from './pages/author/components/CompanyContentMore'
import PersonalAuthor from './pages/author/PersonalAuthor'
import ShopList from './pages/author/components/ShopList'
import Brands from './pages/brands'
import FilterResultList from './pages/brands/filterResultList.js'
import MoreFilter from './pages/brands/components/moreFilter.js'
import BrandsDetail from './pages/brands/BrandsDetail'
import ProductDetail from './pages/brands/ProductDetail'
import BangDing from './pages/mycenter/components/BangDing';
import BangDingPhone from './pages/mycenter/components/BangDing/BangDingPhone';
import YaoYue from './pages/fabuyaoyue';
import YiPeiYaoYue from './pages/fabuyipeiyaoyue';



//个人中心
import Mycenter from './pages/mycenter'
import MycenterJump from './pages/mycenter/MycenterJump'
import Personal from './pages/mycenter/components/Personal'
import PersonalBuss from './pages/mycenter/components/PersonalBuss'
import Settings from './pages/mycenter/components/Settings'
import SharedList from './pages/mycenter/components/Settings/SharedList'
import Feedback from './pages/mycenter/components/Settings/Feedback'

import SecuritySetting from './pages/mycenter/components/Settings/SecuritySetting'
import NickName from './pages/mycenter/components/NickName'
import SignedName from './pages/mycenter/components/SignedName'
import SignedNameBuss from './pages/mycenter/components/SignedNameBuss'
import SignedPhone from './pages/mycenter/components/SignedPhone'
import Collection from './pages/mycenter/components/collection'
import MsgToFans from './pages/mycenter/components/MySendMsgToFans'
import MsgToFansRelease from './pages/mycenter/components/MySendMsgToFans/MsgToFansRelease'



import MyCollectBrands from './pages/mycenter/components/collection/MyCollectBrands'
import MyCollectProduct from './pages/mycenter/components/collection/MyCollectProduct'
import MyCollectNews from './pages/mycenter/components/collection/MyCollectNews'
import MyCollectExhibition from './pages/mycenter/components/collection/MyCollectExhibition'
import MyCollectEvaluation from './pages/mycenter/components/collection/MyCollectEvaluation'
import MyCollectPost from './pages/mycenter/components/collection/MyCollectPost'
import MyCollectAudition from './pages/mycenter/components/collection/MyCollectAudition'
import MyCollectLookBack from './pages/mycenter/components/collection/MyCollectLookBack'
import Message from './pages/mycenter/components/message'
import MyMessageReply from './pages/mycenter/components/message/MyMessageReply'
import MyMessageZan from './pages/mycenter/components/message/MyMessageZan'
import MyMessageSystem from './pages/mycenter/components/message/MyMessageSystem'
import MessageSettings from './pages/mycenter/components/message/MessageSettings'
import StarEdit from './pages/mycenter/components/StarEdit';
import YiPeiEdit from './pages/mycenter/components/YiPeiEdit';
import RenZheng from './pages/mycenter/components/Settings/RenZheng';
import DuiGong from './pages/mycenter/components/Settings/DuiGong';
import StarRenZheng from './pages/mycenter/components/Settings/StarRenZheng';
import EnterpriseRenZheng from './pages/mycenter/components/Settings/EnterpriseRenZheng';
import EnterpriseRenZhengPayment from './pages/mycenter/components/Settings/EnterpriseRenZhengPayment';

import RenZhengOK from './pages/mycenter/components/Settings/RenZhengOK';
import StarMyLike from './pages/mycenter/components/StarEdit/StarMyLike';
import YiPeiMyLike from './pages/mycenter/components/YiPeiEdit/StarMyLike';

import StarMyRelease from './pages/mycenter/components/StarEdit/StarMyRelease';
import YiPeiMyRelease from './pages/mycenter/components/YiPeiEdit/StarMyRelease';
import MyInvitationDetail from './pages/mycenter/components/StarEdit/MyInvitationDetail';
import StarInfo from './pages/mycenter/components/StarEdit/StarInfo';
import YiPeiInfo from './pages/mycenter/components/YiPeiEdit/StarInfo';
import CompanyInfo from './pages/mycenter/components/StarEdit/CompanyInfo';


import Comment from './pages/mycenter/components/comment'
import Release from './pages/mycenter/components/release'
import MyReleaseNews from './pages/mycenter/components/release/MyReleaseNews'
import MyReleaseEvaluation from './pages/mycenter/components/release/MyReleaseEvaluation'
import MyReleasePost from './pages/mycenter/components/release/MyReleasePost'
import MyReleaseAudition from './pages/mycenter/components/release/MyReleaseAudition'
import MyLike from './pages/mycenter/components/MyLike'
import MyLive from './pages/mycenter/components/MyLive'
import MyBrandsCenter from './pages/mycenter/components/MyBrandsCenter'
import MyBrandsCenterAddBrands from './pages/mycenter/components/MyBrandsCenter/AddBrands'
import MyBrandsCenterApplyBrand from './pages/mycenter/components/MyBrandsCenter/ApplyBrand'
import MyBrandsCenterAddBrandCheck from './pages/mycenter/components/MyBrandsCenter/AddBrandCheck'
import MyExhibition from './pages/mycenter/components/MyExhibition'
import MyExDetailList from './pages/mycenter/components/MyExhibition/MyExDetailList'
import MyExDetailListPost from './pages/mycenter/components/MyExhibition/MyExDetailListPost'
import MyExDetailBrandList from './pages/mycenter/components/MyExhibition/MyExDetailBrandList'

import MyShopCenter from './pages/mycenter/components/MyShopCenter'
import AddOnlineStore from './pages/mycenter/components/MyShopCenter/AddOnlineStore'
import AddExperienceStore from './pages/mycenter/components/MyShopCenter/AddExperienceStore'

import Fans from './pages/mycenter/components/FocusAndFans/Fans'
import Focus from './pages/mycenter/components/FocusAndFans/Focus'

import HisFans from './pages/author/components/HisFocusAndFans/Fans'
import HisFocus from './pages/author/components/HisFocusAndFans/Focus'

import SignIn from './pages/signIn'


//注销账户
import Cancellation from './pages/cancellation'
import CancellationText from './pages/file/cancellationText'
import CancellationOK from './pages/cancellation/cancellationOK'

import 'babel-polyfill'
class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <WithRouter>
                        <div>
                            {/* <Header/> */}
                            <Route path="/" exact component={Home}></Route>
                            <Route path="/community" exact component={Community}></Route>
                            <Route path="/news" exact component={News}></Route>
                            <Route path="/star" exact component={StarHome}></Route>
                            <Route path="/training" exact component={TrainingHome}></Route>
                            <Route path="/starDetail/:id" exact component={starDetail}></Route>
                            <Route path="/TrainingDetail/:id" exact component={TrainingDetail}></Route>
                            <Route path="/invitationDetail/:id" exact component={invitationDetail}></Route>
                            <Route path="/TraininginvitationDetail/:id" exact component={TraininginvitationDetail}></Route>
                            <Route path="/searchInvitation" exact component={SearchInvitation}></Route>
                            <Route path="/star/AllStarList" exact component={AllStarList}></Route>
                            <Route path="/training/AllTrainingList" exact component={AllTrainingList}></Route>
                            <Route path="/star/StarMore" exact component={StarMore}></Route>
                            <Route path="/training/TrainingMore" exact component={TrainingMore}></Route>
                            <Route path="/evaluation" exact component={Evaluation}></Route>
                            <Route path="/audition" exact component={Audition}></Route>
                            <Route path="/toLive" exact component={ToLive}></Route>
                            <Route path="/exhibition" exact component={Exhibition}></Route>
                            <Route path="/exhibition/ExhibitionDetailList/:id/:title" exact component={ExhibitionDetailList}></Route>
                            <Route path="/exhibition/ExhibitionDetailListH5/:id/:title" exact component={ExhibitionDetailListH5}></Route>
                            <Route path="/reportComments" exact component={ReportComments}></Route>
                            <Route path="/reportPersonal" exact component={ReportPersonal}></Route>
                            <Route path="/community/communitydetail/:id/:type?" exact component={CommunityDetail}></Route>
                            <Route path="/community/communitydetailh5/:id/:type?" exact component={CommunityDetailH5}></Route>
                            <Route path="/GpsCitySelect" exact component={GpsCitySelect}></Route>
                            <Route path="/TextAreaMessage" exact component={TextAreaMessage}></Route>
                            <Route path="/livebroadcastdetailh5/:id" exact component={LivebroadcastDetailH5}></Route>
                            <Route path="/zhongyuexinggouh5" exact component={ZhongyuexinggouH5}></Route>


                            <Route path="/replyComment/:id/:type?" exact component={ReplyComment}></Route>
                            <Route path="/search" exact component={Search}></Route>
                            <Route path="/register/:type" exact component={Register}></Route>
                            <Route path="/login" exact component={Login}></Route>
                            <Route path="/forget/:type" exact component={ForgetPass}></Route>
                            <Route path="/forgetnew/:type" exact component={ForgetNew}></Route>
                            <Route path="/file/:id?" exact component={File}></Route>
                            <Route path="/Privacy" exact component={Privacy}></Route>
                            <Route path="/RegisterFile" exact component={RegisterFile}></Route>
                            <Route path="/VIPAgreement" exact component={VIPAgreement}></Route>
                            <Route path="/XunjiaFile" exact component={XunjiaFile}></Route>
                            <Route path="/AboutUs" exact component={AboutUs}></Route>
                            <Route path="/advertising" exact component={Advertising}></Route>
                            <Route path="/CompanyAuthor/:id" exact component={CompanyAuthor}></Route>
                            <Route path="/CompanyContentMore" exact component={CompanyContentMore}></Route>
                            <Route path="/PersonalAuthor/:id" exact component={PersonalAuthor}></Route>
                            <Route path="/ShopList/:id" exact component={ShopList}></Route>
                            <Route path="/Brands" exact component={Brands}></Route>
                            <Route path="/Brands/FilterResultList" exact component={FilterResultList}></Route>
                            <Route path="/Brands/MoreFilter" exact component={MoreFilter}></Route>
                            <Route path="/Brands/BrandsDetail/:id" exact component={BrandsDetail}></Route>
                            <Route path="/Brands/ProductDetail/:id" exact component={ProductDetail}></Route>
                            <Route path="/YaoYue" exact component={YaoYue}></Route>
                            <Route path="/YiPeiYaoYue" exact component={YiPeiYaoYue}></Route>



                            {/* 个人中心的路由 */}
                            <Route path="/mycenter" exact component={Mycenter}></Route>
                            <Route path="/mycenterjump" exact component={MycenterJump}></Route>
                            <Route path='/personal' exact component={Personal}></Route>
                            <Route path='/personalbuss' exact component={PersonalBuss}></Route>
                            <Route path='/settings' exact component={Settings}></Route>
                            <Route path='/SharedList' exact component={SharedList}></Route>
                            <Route path='/Feedback' exact component={Feedback}></Route>
                            <Route path='/DuiGong' exact component={DuiGong}></Route>

                            <Route path='/SecuritySetting' exact component={SecuritySetting}></Route>
                            <Route path='/nickname' exact component={NickName}></Route>
                            <Route path='/signedname' exact component={SignedName}></Route>
                            <Route path='/signednamebuss' exact component={SignedNameBuss}></Route>
                            <Route path='/signedphone' exact component={SignedPhone}></Route>
                            <Route path='/collection' exact component={Collection}></Route>
                            <Route path='/msgtofans' exact component={MsgToFans}></Route>
                            <Route path='/msgtofansrelease' exact component={MsgToFansRelease}></Route>
                            <Route path='/MyCollectBrands' exact component={MyCollectBrands}></Route>
                            <Route path='/MyCollectProduct' exact component={MyCollectProduct}></Route>
                            <Route path='/MyCollectNews' exact component={MyCollectNews}></Route>
                            <Route path='/MyCollectExhibition' exact component={MyCollectExhibition}></Route>
                            <Route path='/MyCollectEvaluation' exact component={MyCollectEvaluation}></Route>
                            <Route path='/MyCollectPost' exact component={MyCollectPost}></Route>
                            <Route path='/MyCollectAudition' exact component={MyCollectAudition}></Route>
                            <Route path='/MyCollectLookBack' exact component={MyCollectLookBack}></Route>
                            <Route path='/message' exact component={Message}></Route>
                            <Route path='/MyMessageReply' exact component={MyMessageReply}></Route>
                            <Route path='/MyMessageZan' exact component={MyMessageZan}></Route>
                            <Route path='/MyMessageSystem' exact component={MyMessageSystem}></Route>
                            <Route path='/MessageSettings' exact component={MessageSettings}></Route>
                            <Route path='/StarEdit' exact component={StarEdit}></Route>
                            <Route path='/YiPeiEdit' exact component={YiPeiEdit}></Route>

                            <Route path='/RenZheng' exact component={RenZheng}></Route>
                            <Route path='/StarRenZheng' exact component={StarRenZheng}></Route>
                            <Route path='/EnterpriseRenZheng' exact component={EnterpriseRenZheng}></Route>
                            <Route path='/EnterpriseRenZhengPayment' exact component={EnterpriseRenZhengPayment}></Route>
                            <Route path='/RenZhengOK' exact component={RenZhengOK}></Route>
                            <Route path='/StarMyLike' exact component={StarMyLike}></Route>
                            <Route path='/YiPeiMyLike' exact component={YiPeiMyLike}></Route>
                            <Route path='/StarMyRelease' exact component={StarMyRelease}></Route>
                            <Route path='/YiPeiMyRelease' exact component={YiPeiMyRelease}></Route>
                            <Route path='/MyInvitationDetail/:id' exact component={MyInvitationDetail}></Route>
                            <Route path='/StarInfo' exact component={StarInfo}></Route>
                            <Route path='/YiPeiInfo' exact component={YiPeiInfo}></Route>
                            <Route path='/CompanyInfo' exact component={CompanyInfo}></Route>
                            <Route path='/BangDing' exact component={BangDing}></Route>
                            <Route path='/BangDingPhone/:id' exact component={BangDingPhone}></Route>


                            <Route path='/comment' exact component={Comment}></Route>
                            <Route path='/release' exact component={Release}></Route>
                            <Route path='/MyReleaseNews' exact component={MyReleaseNews}></Route>
                            <Route path='/MyReleaseEvaluation' exact component={MyReleaseEvaluation}></Route>
                            <Route path='/MyReleasePost' exact component={MyReleasePost}></Route>
                            <Route path='/MyReleaseAudition' exact component={MyReleaseAudition}></Route>
                            <Route path="/MyLike" exact component={MyLike}></Route>
                            <Route path="/MyLive" exact component={MyLive}></Route>
                            <Route path="/MyLive/AddLiveApply" exact component={AddLiveApply}></Route>
                            <Route path="/MyBrandsCenter" exact component={MyBrandsCenter}></Route>
                            <Route path="/MyBrandsCenter/AddBrands" exact component={MyBrandsCenterAddBrands}></Route>
                            <Route path="/MyBrandsCenter/ApplyBrand" exact component={MyBrandsCenterApplyBrand}></Route>
                            <Route path="/MyBrandsCenter/AddBrandCheck/:id" exact component={MyBrandsCenterAddBrandCheck}></Route>
                            <Route path="/MyExhibition" exact component={MyExhibition}></Route>
                            <Route path="/MyExhibition/MyExDetailList/:id/:title" exact component={MyExDetailList}></Route>
                            <Route path="/MyExhibition/MyExDetailListPost/:id/:pid" exact component={MyExDetailListPost}></Route>
                            <Route path="/MyExhibition/MyExDetailBrandList" exact component={MyExDetailBrandList}></Route>


                            <Route path="/MyShopCenter" exact component={MyShopCenter}></Route>
                            <Route path="/MyShopCenter/AddExperienceStore" exact component={AddExperienceStore}></Route>
                            <Route path="/MyShopCenter/AddOnlineStore" exact component={AddOnlineStore}></Route>


                            <Route path="/Fans" exact component={Fans}></Route>
                            <Route path="/Focus" exact component={Focus}></Route>

                            <Route path="/HisFans" exact component={HisFans}></Route>
                            <Route path="/HisFocus" exact component={HisFocus}></Route>
                            {/* 签到 */}
                            <Route path="/SignIn" exact component={SignIn}></Route>
                            
                            {/* 注销账户路由 */}
                            <Route path="/cancellation" exact component={Cancellation}></Route>
                            <Route path="/cancellationText" exact component={CancellationText}></Route>
                            <Route path="/cancellationOK" exact component={CancellationOK}></Route>
                            {/* <SideBar/> */}
                            {/* <Footer/> */}
                        </div>
                    </WithRouter>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App