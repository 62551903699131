/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleOneImg,
    NewsPub,
    VideoImg,
    VideoIcon
} from '../../../common/generalStyle';
import {
    ReturnButtons,
    EnterpriseWrapper,
    TopWrapper,
    BGWrapper,
    BGShading,
    HeadImgs,
    Focuseds,
    Focuss,
    EnterpriseNames,
    SubTitle,
    BrandWrapper,
    Fragment,
    NumWrapper,
    Fans,
    Num,
    Title,
    ShopWrapper,
    CompanyShopWrapper,
    ShopMove,
    ShopBox
} from './ContentStyle';

import Label from "./Label";
import httpAxios from "../../../http/httpAxios";
import httpAxios2 from "../../../http/httpAxios2";
import api from "../../../http/api";
import {
    startMinsTime
} from '../../../utils/utils';
import {
    message
} from 'antd';
import axios from 'axios';
const playSvg = require('../../../images/play.svg');
const returnButton = require('../../../images/return_button.svg')

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            // readType: '2',
            // listType: '1',
            targetID: '4',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],

            focused: false,
            commpanyBrands: [],
            moreFocus: false,
            companyShop: [],
            companyExperiences: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
       this.getComType();
       this.getList(null, null, null);
       this.checkBrandCollection();
       this.getCompanyShop();
       this.getCompanyExperiences();

        // alert(window.location.href);
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }
    componentWillReceiveProps(next) {
        if (this.props.info?.UserBID) {
            this.checkFocus(this.props.info?.UserBID);
        }
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    getListRefresh = async (targetID, readType, listType) => {
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }
    //线上店铺
    getCompanyShop = async () => {
        const res = await httpAxios2(api.getCompanyShops, {
            userBID: this.props.authorID,
            pageIndex: 1,
            pageSize: 10
        })
        if (res) {
            this.setState({
                companyShop: res.Data[0]
            })
        }
    }
    //体验店
    getCompanyExperiences = async () => {
        const res = await httpAxios2(api.getCompanyExperiences, {
            UserBID: this.props.authorID,
            pageIndex: 1,
            pageSize: 10
        })
        if (res) {
            this.setState({
                companyExperiences: res.Data[0],
            })
        }
    }
    //作者代理的品牌
    checkBrandCollection = async () => {
        const res = await httpAxios2(api.getCommpanyBrands, {
            userBID: this.props.authorID,
            num: 6,
        });
        if (res) {
            this.setState({
                commpanyBrands: res.Data
            })
        }
    }

    //关注作者
    addFocus = async (author) => {
        if (!this.props.userPID) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (this.props.userPID === author) {
            message.info('用户不能关注自己');
            return
        }
        const {
            focused
        } = this.state;
        if (focused) {
            const res = await httpAxios(api.removeUserFocus, {
                User1: this.props.userPID,
                User2: author
            })
            if (res) {
                this.setState({
                    focused: false
                })
            }
        } else {
            const res = await httpAxios(api.addUserFollow, {
                User1: this.props.userPID,
                User2: author
            })
            if (res) {
                this.setState({
                    focused: true
                })
            }
        }

    }
    //移除关注
    removeFocus = async (author) => {
        const {
            focused
        } = this.state;
        if (!focused) return
        const res = await httpAxios2(api.removeUserFocus, {
            User1: this.props.userPID,
            User2: author,
        })
        if (res) {
            this.setState({
                focused: false
            })
        }
    }
    //检查是否已经关注
    checkFocus = async (id) => {
        if (!id) return
        // if (!this.state.userId) return
        if (this.props.userPID) {
            const res = await axios.get(api.checkUserFocus.url + '?userID=' + this.props.userPID + '&focusUserID=' + id)
            if (res && res.status === 200) {
                if (res.data.Code !== 404) {
                    this.setState({
                        focused: true
                    })
                }
            }
        }
    }

    getList = async (targetID, readType, listType) => {
        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;
        if (!targetID) targetID = this.state.targetID;
        let params;
        params = {
            "UserPID": this.props.authorID,
            "Type": targetID,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
        }

        const res = await httpAxios2(api.userCommunityRead, params);
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                        targetID,
                    })
                }
            } else {
                this.setState({
                    data: [],
                    targetID,
                })
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length === this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    getComType = async () => {
        const res = await httpAxios(api.newsType);
        let typeKeys = {};
        if (res) {
            res.Data.map(i => {
                typeKeys[i.ID] = i.Title
            })
            this.setState({
                comTypes: res.Data,
                typeKeys: typeKeys
            })
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            info,
        } = this.props;
        const {
            data,
            comTypes,
            targetID,
            focused,
            commpanyBrands,
            companyShop,
            companyExperiences
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    {targetID === '1' && <NewsDetailWrapper >
                        <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/community' + '?' + this.props.userPID, })); }}>
                            {
                                obj.ShowType === 3 ?
                                    <NewsItemOther div key={obj.ID} >
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.ListIMG} alt="" />
                                            <VideoIcon>
                                                <img src={playSvg} alt="" />
                                            </VideoIcon>
                                        </VideoImg>
                                    </NewsItemOther >
                                    :
                                    <>
                                        {
                                            obj.ShowType === 0 || obj.ShowType === 1 ?
                                                <NewsItem div key={obj.ID} >
                                                    <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                    <NewsImgWrap><img src={obj.ListIMG} alt="" /></NewsImgWrap>
                                                </NewsItem >
                                                :
                                                <NewsItemOther div key={obj.ID} >
                                                    <NewsTitle>{obj.Title}</NewsTitle>
                                                    <NewsThreeImgWrap>
                                                        {
                                                            obj.ImageAppUrl?.split(';').map((image, index) => (
                                                                index < 3 && <div><img src={image} alt="" /></div>
                                                            ))
                                                        }
                                                    </NewsThreeImgWrap>
                                                </NewsItemOther >
                                        }
                                    </>
                            }
                            <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span></NewsPub>
                        </a>
                    </NewsDetailWrapper>
                    }
                    {targetID === '2' && <NewsDetailWrapper >
                        <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/evaluation' + '?' + this.props.userPID, })); }}>
                            {
                                obj.ShowType === 3 ?
                                    <NewsItemOther div key={obj.ID} >
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.CoverIMG} alt="" />
                                            <VideoIcon>
                                                <img src={playSvg} alt="" />
                                            </VideoIcon>
                                        </VideoImg>
                                    </NewsItemOther >
                                    :
                                    <>
                                        {
                                            obj.ShowType === 0 || obj.ShowType === 1 ?
                                                <NewsItem div key={obj.ID} >
                                                    <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                    <NewsImgWrap><img src={obj.CoverIMG} alt="" /></NewsImgWrap>
                                                </NewsItem >
                                                :
                                                <NewsItemOther div key={obj.ID} >
                                                    <NewsTitle>{obj.Title}</NewsTitle>
                                                    <NewsThreeImgWrap>
                                                        {
                                                            obj.ImageAppUrl?.split(';').map((image, index) => (
                                                                index < 3 && <div><img src={image} alt="" /></div>
                                                            ))
                                                        }
                                                    </NewsThreeImgWrap>
                                                </NewsItemOther >
                                        }
                                    </>
                            }
                            <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPub>
                        </a>
                    </NewsDetailWrapper>
                    }
                    {targetID === '3' && <NewsDetailWrapper >
                        <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.id + '/audition' + '?' + this.props.userPID, })); }}>
                            <NewsItemOther div key={obj.ID} >
                                <NewsTitle>{obj.Title}</NewsTitle>
                                <VideoImg>
                                    <img src={obj.PosterImg} alt="" />
                                    <VideoIcon>
                                        <img src={playSvg} alt="" />
                                    </VideoIcon>
                                </VideoImg>
                            </NewsItemOther >
                            <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ViewNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPub>
                        </a>
                    </NewsDetailWrapper>
                    }
                    {targetID === '4' && <NewsDetailWrapper >
                        <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/exhibition' + '?' + this.props.userPID, })); }}>
                            {
                                obj.ShowType === 3 ?
                                    <NewsItemOther div key={obj.ID} >
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.PosterImg} alt="" />
                                            <VideoIcon>
                                                <img src={playSvg} alt="" />
                                            </VideoIcon>
                                        </VideoImg>
                                    </NewsItemOther >
                                    : <NewsItem div key={obj.ID} >
                                        <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                        <NewsImgWrap><img src={obj.PosterImg} alt="" /></NewsImgWrap>
                                    </NewsItem >
                            }
                            <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.CommentNum}评论</span><span>{startMinsTime(obj.AddTime)}</span></NewsPub>
                        </a>
                    </NewsDetailWrapper>
                    }
                </div>
            );
        };
        return (<div>
            <TopWrapper>
                <EnterpriseWrapper>
                    <div style={{ display: 'flex' }}>
                        <ReturnButtons onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButtons>
                        <HeadImgs><img src={info?.HeadImg} alt="" /></HeadImgs>
                        <EnterpriseNames onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "企业详情", weburl: "/CompanyContentMore" + "?" + info?.UserBID })) }}>{info?.CompanyName}</EnterpriseNames>
                    </div>
                    {focused ? <Focuseds onClick={this.removeFocus.bind(this, info?.UserBID)}>已关注</Focuseds> : <Focuss onClick={this.addFocus.bind(this, info?.UserBID)}>关注</Focuss>}
                </EnterpriseWrapper>
                <NumWrapper>
                    <Fans onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "他的关注", weburl: "/HisFans" + "?" + info?.UserBID })) }}>
                        <Num>{info?.CountFollw || 0}</Num>
                        <Title>关注</Title>
                    </Fans>
                    <Fans onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "他的粉丝", weburl: "/HisFocus" + "?" + info?.UserBID })) }}>
                        <Num>{info?.CountFans || 0}</Num>
                        <Title>粉丝</Title>
                    </Fans>
                    <Fans onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "他的点赞", weburl: "/MyLike" + "?" + info?.UserBID })) }}>
                        <Num>{info?.zanTotal || 0}</Num>
                        <Title>点赞</Title>
                    </Fans>
                </NumWrapper>
                {
                    commpanyBrands[0] && <>
                        <BrandWrapper>
                            {
                                commpanyBrands.map(i => (
                                    i.include.map(item => (
                                        <Fragment key={item.UserBID}>
                                            <span >{item.BrandName}</span>
                                        </Fragment>
                                    ))
                                ))
                            }
                        </BrandWrapper>
                    </>
                }
                {(companyShop || companyExperiences) && 
                <ShopWrapper>
                    <ShopBox>
                        <CompanyShopWrapper>
                            <span>线上商城</span>
                            {companyShop ?
                                <a href={companyShop?.ShopLink} target='_blank'>
                                    <div><b>{companyShop?.ShopName}</b></div>
                                    <div>网址：{companyShop?.ShopLink}</div>
                                </a> : <div>还没有登录线上商城</div>
                            }
                        </CompanyShopWrapper>
                        <CompanyShopWrapper>
                            <span>线下体验店</span>
                            {companyExperiences ?
                            <div>
                                <div><b>{companyExperiences?.ShopName}</b></div>
                                <div>地址：{companyExperiences?.ShopAddress}</div>
                                <div>电话：{companyExperiences?.Telephone}</div>
                            </div>
                            : <div>还没有登录线下体验店</div>
                        }
                        </CompanyShopWrapper>
                    </ShopBox>
                    <ShopMove onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "线上商城&线下体验店", weburl: "/ShopList/" + info?.UserBID })) }}>点击查看全部</ShopMove>
                </ShopWrapper>
                 } 
                <div style={{ height: '2rem', }}>
                    <Label cb={this.getListRefresh} list={comTypes} />
                </div>
            </TopWrapper>

            {/* <div style={{ height: '8.5rem' }}></div> */}
            <ListView
                initialListSize={8}
                key={'1'}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (
                    <div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                    </div>)}
                renderRow={row}
                useBodyScroll
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    onRefresh={this.onRefresh}
                />}
                onEndReached={this.onEndReached}
                pageSize={NUM_ROWS}
            />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content