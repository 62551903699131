/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleNoImg,
    NewsTitleOneImg,
    NewsPubs,
    VideoImg,
    VideoIcon
} from '../../../common/generalStyle';
import {
    NavBar,
    ReturnButton,
    SearchIcon
} from './ContentStyle';

import Label from "./Label";
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import {
    startMinsTime
} from '../../../utils/utils';
const playSvg = require('../../../images/plays.svg');
const returnButton = require('../../../images/return_button.svg')
const searchButton = require('../../../images/search_button.svg')
const listVipLogo = require('../../../images/listVipLogo.png');

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            hasMore: true,
            rowCount: 0,

            // liveID: 1,
            // livedata: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        // this.getLiveData();
        pageIndex = 0;
        this.getComType();
        // this.getList(null, null, null);


        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }
    // getLiveData = async () => {
    //     const res = await httpAxios(api.getLiveUrl, {
    //         liveID: this.state.liveID
    //     });
    //     if (res) {
    //         this.setState({
    //             livedata: res.Data,
    //         })
    //     }
    // }

    goBackPage = () => {
        if (this.props.location?.search === '?v=1') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goHome" }));
        } else {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }

    getListRefresh = async (targetID, readType, listType) => {
        window.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async (targetID, readType, listType) => {
        if (!readType) readType = this.state.readType;
        if (!listType) listType = this.state.listType;
        if (!targetID) targetID = this.state.targetID;
        let params;
        if (targetID === '0') {
            params = {
                "pageSize": NUM_ROWS,
                "pageIndex": pageIndex,
                "ReadType": readType,
                "ListType": listType
            }
        } else {
            params = {
                "targetID": targetID,
                "pageSize": NUM_ROWS,
                "pageIndex": pageIndex,
                "ReadType": readType,
                "ListType": listType
            }
        }

        const res = await httpAxios(api.getComList, params);
        if (res) {
            const str = res.Message.split(':')[1];
            const count = Number(str.slice(0, str.length - 1));
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                        targetID,
                        readType,
                        listType
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                        targetID,
                        readType,
                        listType
                    })
                }
            } else {
                this.setState({
                    data: [],
                    targetID,
                    readType,
                    listType
                })
                this.state.hasMore = false;
            }

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    getComType = async () => {
        const res = await httpAxios(api.getComType);
        let typeKeys = {};
        if (res) {
            res.Data.map(i => {
                typeKeys[i.ID] = i.Title
            })
            this.setState({
                comTypes: res.Data,
                typeKeys: typeKeys
            })
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        pageIndex += 1;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
            comTypes,
        } = this.state;

        return (<div>
            <NavBar>
                <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                <span>社区</span>
                <SearchIcon onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><img src={searchButton} alt="搜索" /></SearchIcon>
                <Label cb={this.getListRefresh} list={comTypes} />
            </NavBar>
            <div style={{ height: '8.5rem' }}></div>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.Author, weburl: '/community/communitydetail/' + obj.ID + '/community' + '?' + this.state.userPID, })); }}>
                                    {
                                        obj.ShowType == 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    {/* {
                                                obj.ListIMG === null ? <img src={
                                                    obj.ImageAppUrl.split(';')[0]
                                                } alt="" /> : <img src={obj.ListIMG} alt="" />
                                            } */}
                                                    {
                                                        obj.ListIMG === null ? obj.ImageAppUrl.split(';')[0].indexOf('?') > 0 ? <img src={obj.ImageAppUrl.split(';')[0].slice(0, obj.ImageAppUrl.split(';')[0].indexOf('?')) + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                            : <img src={obj.ImageAppUrl.split(';')[0] + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                            : obj.ListIMG.indexOf('?') > 0 ? <img src={obj.ListIMG.slice(0, obj.ListIMG.indexOf('?')) + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                                : <img src={obj.ListIMG + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                    }
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 0 ?
                                                        <NewsItem div key={obj.ID} >
                                                            {obj.ListIMG != null ? <>
                                                                <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                                <NewsImgWrap>
                                                                    {/* <img src={obj.ListIMG} alt="" /> */}
                                                                    {
                                                                        obj.ListIMG.indexOf('?') > 0 ? <img src={obj.ListIMG.slice(0, obj.ListIMG.indexOf('?')) + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                            : <img src={obj.ListIMG + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                    }
                                                                </NewsImgWrap>
                                                            </> : <NewsTitleNoImg>{obj.Title}</NewsTitleNoImg>
                                                            }
                                                        </NewsItem >
                                                        :
                                                        <>
                                                            {
                                                                obj.ShowType == 2 ?
                                                                    <NewsItemOther div key={obj.ID} >
                                                                        <NewsTitle>{obj.Title}</NewsTitle>
                                                                        <NewsThreeImgWrap>
                                                                            {
                                                                                obj.ImageAppUrl.split(';').map((image, index) => (
                                                                                    index < 3 && <div>
                                                                                        {/* <img src={image} alt="" /> */}
                                                                                        {
                                                                                            image.indexOf('?') > 0 ? <img src={image.slice(0, image.indexOf('?')) + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                                                : <img src={image + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </NewsThreeImgWrap>
                                                                    </NewsItemOther >
                                                                    :
                                                                    <NewsItem div key={obj.ID} >
                                                                        <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                                        <NewsImgWrap>
                                                                            {/* {
                                                                        obj.ListIMG === null ? <img src={
                                                                            obj.ImageAppUrl.split(';')[0]
                                                                        } alt="" /> : <img src={obj.ListIMG} alt="" />
                                                                    } */}
                                                                            {
                                                                                obj.ListIMG === null ? obj.ImageAppUrl.split(';')[0].indexOf('?') > 0 ? <img src={obj.ImageAppUrl.split(';')[0].slice(0, obj.ImageAppUrl.split(';')[0].indexOf('?')) + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                                                    : <img src={obj.ImageAppUrl.split(';')[0] + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                                                    : obj.ListIMG.indexOf('?') > 0 ? <img src={obj.ListIMG.slice(0, obj.ListIMG.indexOf('?')) + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                                                        : <img src={obj.ListIMG + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                                            }
                                                                        </NewsImgWrap>
                                                                    </NewsItem >
                                                            }
                                                        </>
                                                }
                                            </>
                                    }
                                    <NewsPubs>
                                        {
                                            obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                        }
                                        <img src={obj.HeadImg} alt="" /><span>{obj.Author}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span></NewsPubs>
                                </a>
                            </NewsDetailWrapper>
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content