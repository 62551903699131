import styled from 'styled-components';

import {
  theme, themenew
} from '../../../../common/color';


export const Wrapper = styled.div`
  width:92%;
  margin:0 auto ;
  padding-top:1rem ;
  img{
    width:100px ;
    height:100px ;
  }
`