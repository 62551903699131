import React, {
    Component
} from 'react';
import { List, Picker } from 'antd-mobile-v2';
import {
    city,
} from '../../../../common/citySelect/cityJsonApp'
import {
    SettingsWrapper,
    HeaderImgList
} from '../MycenterPortraitStyle';
import axios from 'axios'
import api from "../../../../http/api"
import httpAxios from "../../../../http/httpAxios"
import { Toast } from 'antd-mobile-v2';

const headerImg = require('../../../../images/headimg.png')


const Item = List.Item;

class Personal extends Component {
    state = {
        pickerValue: [],
        phoneValue: [],
        HeadImg: '',
        PNickName: '',
        WriteSB: '',
        birthdayValue: [],
        userPID: ''
    };

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getUserInfo(data.UserPID, data.Token);
            // alert(JSON.stringify(data))
        }

        window.logoImage = function (imageUrl) {
            if (imageUrl != "") {
                that.setState({
                    HeadImg: imageUrl
                })
                that.saveLogoImage();
            }
        }
    }

    //获取用户信息
    getUserInfo = async (id, Token) => {
        const res = await httpAxios(api.getBussInfo, {
            userid: id,
            token: Token
        })
        // alert(JSON.stringify(res.Data));
        if (res && res.Data) {
            this.setState({ PNickName: res.Data.CompanyName });//企业名称
            this.setState({ WriteSB: res.Data.Introduction });//企业简介
            this.setState({ HeadImg: res.Data.HeadImg });//logo
            this.setState({ pickerValue: res.Data.Location });//所在地
            this.setState({ phoneValue: res.Data.CompanyPhone });//联系方式
        }
    }

    //显示上传图片
    handleLogoImage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadLogoImage" }));
    }

    //保存头像信息
    saveLogoImage = async () => {
        const res = await httpAxios(api.uploadHeadIMG, {
            "UserP": this.state.userPID,
            "ImgLinks": this.state.HeadImg
        })
    }

    //保存信息
    onSubmit = (key, value) => {
        let dataTemp = {
            UserBID: this.state.userPID
        };
        dataTemp[key] = value;
        httpAxios(api.bussUpdateUserInfo, dataTemp).then(res => {
            if (res) {
                Toast.info('信息修改成功')
            }
        })

    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    render() {
        return (
            <SettingsWrapper>
                <List renderHeader={() => ''}>
                    <HeaderImgList onClick={this.handleLogoImage}>
                        <span>企业Logo</span><div><img src={this.state.HeadImg == "" ? headerImg : this.state.HeadImg} alt="默认头像"></img></div>
                    </HeaderImgList>
                    <Item className='listheight' extra={this.state.PNickName} arrow="horizontal">企业名称</Item>
                    <Item className='listheight' extra={this.state.phoneValue} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '设置联系电话', weburl: '/signedphone' })); }}>联系电话</Item>
                    <Picker
                        cols={2}
                        data={city}
                        extra={this.state.pickerValue}
                        onOk={(v) => {
                            let tempData = city.filter(item => item.value == v[0]);
                            let tempData1 = tempData[0].children.filter(item => item.value == v[1]);
                            this.setState({ pickerValue: tempData1[0].province + "-" + tempData1[0].label });
                            this.onSubmit('Location', tempData1[0].province + "-" + tempData1[0].label);
                        }}
                    >
                        <List.Item className='listheight' extra={this.state.pickerValue} arrow="horizontal" onClick={() => { }}>
                            所在地
                        </List.Item>
                    </Picker>
                    <Item className='listheight' extra={this.state.WriteSB} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '设置企业简介', weburl: '/signednamebuss' })); }}>企业简介</Item>
                </List>
            </SettingsWrapper>
        )
    }
}

export default Personal;