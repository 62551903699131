import React,{Component} from 'react';
import {
    Wrapper,Title,Dec
} from '../FileStyle';
import httpAxios from "../../../http/httpAxios"
import api from "../../../http/api"

class BusinessLicense extends Component{
    state = {
        data: []
    }
    async componentDidMount() {
        const res = await httpAxios(api.getFriendLinks);
        if (res) {
            this.setState({
                data: res.Data.links
            })
        }
    }
    render(){
        return(
            <Wrapper>
                <Title>友情链接</Title>
                <Dec style={{textIndent: '0'}}>
                    {
                        this.state.data.map(d => (
                            <div key={d.SiteLink}>
                                <div>{d.SiteName}</div>
                                <a href={d.SiteLink} target='_blank'>
                                    {d.SiteLink}
                                </a>
                            </div>
                        ))
                    }
                </Dec>
            </Wrapper>
        )
    }
}

export default BusinessLicense
