import styled from 'styled-components';
import {
  theme, themenew
} from '../../../common/color';

export const NavBar = styled.div`
  width:100%;
  min-height:10rem;
  background-color: #fff;
  border-radius: 1.875rem 1.875rem 0 0;
  margin-top: 1rem;
  padding-top: 1.875rem;
`
export const BrandsTitle = styled.div`
    color: #fff;
    margin-left: 1.25rem;
    margin-top: 1.875rem;
    >div{
        font-size: 1.125rem;
        font-weight: 600;
    }
    >span{
        font-size: 0.875rem;
        margin-top: 0.625rem;
        display: block;
    }
`

export const BrandsImg = styled.div`
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    background: #000;
    float: right;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.875rem;
    margin-top: -6.25rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
    >img{
        width:100%;
        min-height:6.25rem;
        object-fit: cover;
    }
`
export const CollectionButton = styled.div`
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  border-radius: 4px;
  width: 3.625rem;
  height: 1.75rem;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  margin-left: 4%;
`
export const CollectionButtonSelect = styled.div`
  background: #f1f1f1;
  border-radius: 4px;
  width: 3.625rem;
  height: 1.75rem;
  color: ${themenew.text_color};
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  margin-left: 4%;
`

export const BrandsIntroduceTitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  color: #203152;
  letter-spacing: 0.5px;
  margin-top: 1.875rem;
  margin-left: 4%;
  margin-bottom: 0.81rem;
`

export const BrandsIntroduce = styled.div`
  margin:0 4%;
  font-size: 0.875rem;
  color: #203152;
  line-height: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 0.75rem;
`
export const BrandsIntroduceMore = styled.div`
  margin:0 4%;
  font-size: 0.875rem;
  color: #203152;
  line-height: 30px;
`
export const BrandsIntroduceMoreButton = styled.div`
  float: right;
  margin-right: 4%;
  font-size: 0.75rem;
  color: #7C8698;
  margin-bottom: 1.69rem;
`


export const BrandsBG = styled.div`
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    height: 10rem;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
`

export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:4%;
  margin-top:3.5rem;
  float:left;
`
export const SearchIcon = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-right:4%;
  margin-top:3.5rem;
  float:right;
`