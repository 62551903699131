import React, {
    Component
} from 'react';
import { List, Modal, Toast } from 'antd-mobile-v2';
import {
    SettingsWrapper
} from '../MycenterPortraitStyle';

import api from "../../../../http/api";
import axios from 'axios';
var u = navigator.userAgent;


const Item = List.Item;
const prompt = Modal.prompt;

class SecuritySetting extends Component {
    state = {
        userId:'',
        Phone: '',
        Email: '',
        password: ''
    }

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
            that.getUserInfo(data.UserPID);
        };
        
    }

    getUserInfo = async (id) => {
        const res = await axios.post(api.getUserInfo.url, {
            UserPID: id,
        })
        if (res && res.data.Data) {
            this.setState({
                info: res.data.Data
            })

            this.setState({ Phone: res.data.Data.Phone });
            this.setState({ Email: res.data.Data.Email });
        }
    }

    render() {
        return (
            <SettingsWrapper>
                <List renderHeader={() => ''}>
                    <Item className='listheight' extra="已设置" arrow="horizontal" onClick={() => {
                        //发送手机验证码
                        axios.post(api.changePassByPhone1.url, { UserPID: this.state.userId },
                            {
                                withCredentials: true
                            })
                            .then(res => console.log(res))
                            .catch(error => Toast.info("验证码发送错误！"));
                        //显示手机号前三位和后四位
                        var str = this.state.Phone
                            .match(/(\d{3})(\d{4})(\d{4})/)
                            .slice(1)
                            .reduce(function (value, item, index) {
                                //当index===1时，初始元素和当前元素累加并返回，value是初始值186，也是最终累加的返回值，item是当前索引下标是1的元素****。
                                return index === 1 ? value + "****" : value + item;
                            });
                        prompt(
                            '修改登录密码',
                            '请输入 ' + str + ' 收到验证码',
                            [
                                { text: '取消' },
                                {
                                    text: '下一步', onPress: value => new Promise((resolve, reject) => {
                                        resolve();
                                        //手机验证码
                                        axios.post(api.changePassByPhone2.url,
                                            {
                                                UserPID: this.state.userId,
                                                CheckNum: value,
                                                phone: this.state.Phone,
                                            },
                                            {
                                                withCredentials: true
                                            }
                                        )
                                            .then(res => {
                                                if (res.data.Code == 200) {

                                                    prompt(
                                                        '请输入新密码',
                                                        '密码长度为 6-20 位',
                                                        [
                                                            { text: '取消' },
                                                            {
                                                                text: '保存', onPress: value => {
                                                                    new Promise((resolve, reject) => {
                                                                        if (value == '' || value.length < 6 || value.length > 20) {
                                                                            Toast.info("密码长度为 6-20 位");
                                                                            setTimeout(() => {
                                                                                reject();
                                                                            }, 1000);
                                                                        }
                                                                        else {
                                                                            resolve();
                                                                            //修改用户密码
                                                                            axios.put(api.updateUserPassword.url,
                                                                                {
                                                                                    UserPID: this.state.userId,
                                                                                    Password: value
                                                                                },
                                                                                {
                                                                                    withCredentials: true
                                                                                }
                                                                            )
                                                                                .then(res1 => {
                                                                                    if (res1.data.Code == 200) {
                                                                                        Toast.info('密码修改成功');
                                                                                    } else {
                                                                                        Toast.info('密码修改失败');
                                                                                    }
                                                                                    resolve();
                                                                                })
                                                                                .catch(error => { Toast.info("验证码错误！"); });
                                                                        }
                                                                    });
                                                                },
                                                            }
                                                        ]
                                                    )
                                                }
                                                else {
                                                    Toast.info(res.data.Message);
                                                    reject();
                                                }

                                            })
                                            .catch(error => { Toast.info("验证码错误！"); });
                                    }),
                                },
                            ],
                            'text',
                        )
                    }}>登录密码</Item>
                    <Item className='listheight' extra={this.state.Phone} arrow="horizontal" >手机号码</Item>
                    <Item className='listheight' extra={this.state.Email} arrow="horizontal" onClick={() => {
                        //发送手机验证码
                        axios.post(api.changePassByPhone1.url, { UserPID: this.state.userId },
                            {
                                withCredentials: true
                            })
                            .then(res => console.log(res))
                            .catch(error => Toast.info("验证码发送错误！"));
                        //显示手机号前三位和后四位
                        var str = this.state.Phone
                            .match(/(\d{3})(\d{4})(\d{4})/)
                            .slice(1)
                            .reduce(function (value, item, index) {
                                //当index===1时，初始元素和当前元素累加并返回，value是初始值186，也是最终累加的返回值，item是当前索引下标是1的元素****。
                                return index === 1 ? value + "****" : value + item;
                            });
                        prompt(
                            '绑定邮箱',
                            '请输入 ' + str + ' 收到验证码',
                            [
                                { text: '取消' },
                                {
                                    text: '下一步', onPress: value => new Promise((resolve, reject) => {
                                        resolve();
                                        //手机验证码
                                        axios.post(api.changePassByPhone2.url,
                                            {
                                                UserPID: this.state.userId,
                                                CheckNum: value,
                                                phone: this.state.Phone,
                                            },
                                            {
                                                withCredentials: true
                                            }
                                        )
                                            .then(res => {
                                                if (res.data.Code == 200) {
                                                    prompt(
                                                        '邮箱绑定',
                                                        '请输入您要绑定的邮箱地址',
                                                        [
                                                            { text: '取消' },
                                                            {
                                                                text: '下一步', onPress: value => {
                                                                    new Promise((resolve, reject) => {
                                                                        let myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
                                                                        if (value == '' || !myreg.test(value)) {
                                                                            Toast.info("请输入正确的邮箱");
                                                                            setTimeout(() => {
                                                                                reject();
                                                                            }, 1000);
                                                                        }
                                                                        else {
                                                                            var email = value
                                                                            resolve();
                                                                            //发送邮箱验证码
                                                                            axios.post(api.updateUserEmail.url,
                                                                                {
                                                                                    UserID: this.state.userId,
                                                                                    Email: value,
                                                                                },
                                                                                {
                                                                                    withCredentials: true
                                                                                }
                                                                            )
                                                                                .then(res => {
                                                                                    if (res.data.Code == 200) {
                                                                                        prompt(
                                                                                            '邮件发送成功！',
                                                                                            '请检查您的收件箱（' + email + '）的邮件，输入验证码',
                                                                                            [
                                                                                                { text: '取消' },
                                                                                                {
                                                                                                    text: '完成', onPress: value => new Promise((resolve, reject) => {
                                                                                                        resolve();
                                                                                                        //邮箱验证码
                                                                                                        axios.post(api.checkUserEmailCode.url,
                                                                                                            {
                                                                                                                UserID: this.state.userId,
                                                                                                                Code: value,
                                                                                                            },
                                                                                                            {
                                                                                                                withCredentials: true
                                                                                                            }
                                                                                                        )
                                                                                                            .then(res => {
                                                                                                                if (res.data.Code == 200) {
                                                                                                                    Toast.info('绑定邮箱修改成功');
                                                                                                                    this.getUserInfo();
                                                                                                                    reject();
                                                                                                                } else {
                                                                                                                    Toast.info(res.data.Message);
                                                                                                                    reject();
                                                                                                                }
                                                                                                            })
                                                                                                    })
                                                                                                }
                                                                                            ]
                                                                                        )
                                                                                    }
                                                                                    else {
                                                                                        Toast.info(res.data.Message);
                                                                                        reject();
                                                                                    }
                                                                                })
                                                                        }
                                                                    });
                                                                },
                                                            }
                                                        ]
                                                    )
                                                }
                                                else {
                                                    Toast.info(res.data.Message);
                                                    reject();
                                                }

                                            })
                                    }),
                                },
                            ],
                            'text',
                        )
                    }}> 邮箱</Item>
                </List>
            </SettingsWrapper >
        )
    }
}

export default SecuritySetting;