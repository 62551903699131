import React, {
    Component
} from 'react';
import {
    Wrapper,
    Auto,
    Title1,
    Warning
} from './FileStyle';

class VIPAgreement extends Component {
    render() {
        return (
            <Wrapper>
                <Title1>
                    欢迎您使用中乐之声平台！
                </Title1>
                <Auto>
                    <p>申请企业认证是一项严肃的法律行为，拟认证主体特此申请企业帐号认证服务，已仔细阅读并同意以下内容及《中乐之声认证服务协议》。</p>
                </Auto>
                <Auto>
                    <p>1. 在帐号资质审核成功之后，帐号的使⽤权属于通过资质审核的拟认证主体，该帐号⾃注册时其产⽣的⼀切权利义务均由该主体承担，该帐号所获得的所有收益、权限均归认证后的主体享有，且所有运营活动都必须以该主体对外开展。</p>
                    <p>2. 拟认证主体提交给中乐之声的认证资料真实⽆误，并不可撤销地授权中乐之声及其委托的任何第三⽅审核机构对提交的资料进⾏甄别核实，⼀经申请即产⽣中乐之声及其委托的第三⽅审核机构的审核成本，故所交纳的认证审核服务费⽤将不因认证结果、拟认证主体是否提出撤回申请等因素⽽退回。</p>
                    <p>3. 拟认证主体在申请中乐之声企业认证服务过程中填写并向中乐之声提交的认证联系⼈（包括后续不时变更的⼈员）为拟认证主体指定的认证联系⼈员，并特此授权由该指定⼈员以拟认证主体的名义负责该帐号的内容维护、开发维护及运营管理。认证联系人的所有操作行为，均代表拟认证主体，拟认证主体均需承担一切责任。</p>
                    <p>4. 本认证服务仅对该帐号所提交的认证资料的真实性、合法性进⾏书⾯甄别核实，其功能、权限是否开通、帐号能否发布等均需遵守对应业务平台为此所制定的专项规则，⽽不与认证审核结果存在直接关联。</p>
                    <p>5. 拟认证主体如违反上述承诺，均需承担一切责任，对中乐之声或第三方造成损失的，须赔偿损失。在用户同意通过新的认证服务之前，本公函持续有效。</p>
                    <p>我已同意并遵守上述内容及《中乐之声认证服务协议》</p>
                </Auto>
                <Title1>
                    中乐之声企业认证服务协议
                </Title1>
                <Title1>
                    【首部及导言】
                </Title1>
                <Auto>
                    <p>欢迎你使用中乐之声！</p>
                    <p>为使用中乐之声服务（以下简称“本服务”），你应当阅读并遵守《中乐之声服务协议》（以下简称“本协议”），以及《中乐之声用户服务协议》。以及专项规则等。请你务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示你注意。
                        除非你已阅读并接受本协议所有条款，否则你无权使用中乐之声服务。你对本服务的登录、查看、发布信息等行为即视为你已阅读并同意本协议的约束。
                        如果你未满18周岁，请在法定监护人的陪同下阅读本协议及其他上述协议，并特别注意未成年人使用条款。
                    </p>
                </Auto>
                <Title1>
                    一、【协议的范围】
                </Title1>
                <Auto>
                    <p>1.1【协议适用主体范围】</p>
                    <p>本协议是你与中乐之声之间关于你使用中乐之声认证服务所订立的协议。“中乐之声”是指中乐之声公司及其相关服务可能存在的运营关联单位。“用户”是指申请中乐之声认证服务及/或已经认证成功的企业帐号用户，在本协议中更多地称为“你”。</p>
                    <p>1.2【协议关系及冲突条款】</p>
                    <p>本协议被视为《中乐之声用户服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。</p>
                    <p>本协议内容同时包括中乐之声可能不断发布的关于本服务的相关协议、服务声明、业务规则及公告指引等内容（以下统称为“专项规则”）。上述内容一经正式发布，即为本协议不可分割的组成部分，你同样应当遵守。</p>
                </Auto>
                <Title1>
                    二、【术语定义】
                </Title1>
                <Auto>
                    <p>2.1 中乐之声企业认证服务：是指符合一定条件的用户可以对帐号申请企业账号认证。中乐之声根据用户的申请及其提交的资料和信息，自行或者委托第三方审核机构进行审核，并根据审核情况确定认证结果和认证信息的服务。</p>
                    <p>2.2 认证审核：指用户向中乐之声注册用户提出申请，中乐之声平台根据法律规定及双方的约定和保证，基于用户提交的资料和信息，对用户的中乐之声企业号进行帐号资质审核和帐号名称审核。<br />
                        2.2.1 帐号资质审核：指用户向中乐之声提出申请，中乐之声根据法律规定及双方的约定和保证，对用户提交的主体资质证明或其所拥有的权利证明资料和信息进行甄别及核实的过程；<br />
                        2.2.2 帐号名称审核：指用户向中乐之声提出申请，中乐之声根据法律规定及双方的约定和保证，对用户帐号名称进行审查核定的过程。</p>
                    <p>2.3 第三方审核机构：指中乐之声委托对用户进行认证审核的第三方机构。</p>
                    <p>2.4 订单：指用户向中乐之声发起的本服务申请，每发起一次申请为一个订单。</p>
                    <p>2.5 新订单：指用户以获得认证成功或年审认证成功的审核结果为目的发起的本服务申请，在新订单的审核过程中，用户自发补充或者根据中乐之声及/或中乐之声委托第三方审核机构的要求所补充的资料作为同一订单的审核范围。</p>
                    <p>2.6 认证结果：是指中乐之声及其委托的第三方审核机构按照用户的申请进行认证审核后，中乐之声向用户输出的认证结果，认证结果分为帐号资质审核结果及帐号名称审核结果两部分，每一部分的认证结果只包括成功和失败两种情形。<br />
                        2.6.1 帐号资质审核结果：指中乐之声及其委托的第三方审核机构根据用户的申请，基于用户提交的资料和信息，对用户进行帐号资质审核后，中乐之声向用户输出的结果。<br />
                        2.6.2 帐号名称审核结果：指中乐之声及其委托的第三方审核机构根据用户的申请，对用户进行帐号名称审核后，中乐之声向用户输出的结果。</p>
                    <p>2.7 认证成功：指中乐之声自行或委托第三方审核机构对用户提交的资料和信息进行甄别及核实，在完成所有审核流程后，由中乐之声作出认证成功的判断。针对认证成功的用户，中乐之声将根据本协议确定用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限。未按期完成年审认证或者年审认证失败的用户不属于认证成功用户。<br />
                        2.7.1 帐号资质审核成功：指中乐之声自行或委托第三方审核机构对用户提交的资料和信息进行甄别及核实，在完成帐号资质审核流程后，由中乐之声作出帐号资质审核成功的判断。资质审核成功帐号可以申请中乐之声开通高级功能，但在用户帐号名称审核成功之前，该帐号不属于认证成功帐号，不会生成认证标识及认证信息，也不会开通高级权限。<br />
                        2.7.2 帐号名称审核成功：指中乐之声自行或委托第三方审核机构对用户提交的资料进行甄别及核实，在完成帐号名称审核流程后，由中乐之声作出帐号名称审核成功的判断。</p>
                    <p>2.8 系统通知用户调整申请内容：指用户在申请认证过程中，因不满足认证成功条件，而被中乐之声以系统通知的方式要求作调整或补充的情形。</p>
                    <p>2.9 认证失败：指中乐之声直接或委托第三方审核机构对用户的资料进行甄别及核实，在完成所有审核流程后，由中乐之声作出认证失败判断，包括但不限于以下任一情形：<br />
                        2.9.1 由于用户原因，经中乐之声三次系统通知用户调整申请内容，用户仍未能满足帐号资质审核及帐号名称审核要求的；<br />
                        2.9.2 由于用户原因：<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;2.9.2.1 自用户付费之日起三十日，用户仍未能满足帐号资质审核要求之一的；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;2.9.2.2 自用户付费之日起九十日，用户仍未能满足帐号名称审核要求之一的；<br />
                        2.9.3 帐号资质审核结果或名称审核结果其中一种为失败的；<br />
                        2.9.4 因其他原因而不能认证成功的情形。
                    </p>
                    <p>2.10 审核服务费用：指中乐之声根据用户的申请对用户所提交的资料和信息进行认证审核而产生的费用。</p>
                </Auto>
                <Title1>
                    三、【用户的权利义务】
                </Title1>
                <Auto>
                    <p>3.1 用户应保证其提供给中乐之声或者第三方审核机构的所有资料和信息的真实性、合法性、准确性和有效性。如用户提供服务或内容需要取得相关法律法规规定的许可或进行备案的，用户应当主动进行明确说明并提交相应的许可或备案证明。否则，中乐之声有权拒绝或终止提供本服务，并依照本协议对违规帐号进行处罚。因此给中乐之声或第三方造成损害的，你应当依法予以赔偿。 </p>
                    <p>3.2 用户同意授权中乐之声将其提交的资料和信息委托第三方审核机构进行甄别核实，第三方审核机构有权出于认证审核之必要查阅、使用及保存上述资料及信息，并有权受中乐之声委托以自身名义与用户就认证审核事宜进行联系与沟通，用户应当配合第三方审核机构在认证审核过程中所提出的相关需求。</p>
                    <p>3.3 用户不得通过非中乐之声授权的其他任何渠道使用本服务，如非因中乐之声或者第三方审核机构原因导致的认证失败、认证流程过长等后果，中乐之声及第三方审核机构不承担责任。</p>
                    <p>3.4 用户理解并且同意，用户提交的资料和信息是作出认证结果的重要依据，任何资料和信息的变更将可能影响认证结果，用户应当在资料和信息变更时及时提出补充认证申请，否则中乐之声有权依照本协议约定对用户进行处罚。中乐之声因此遭受损失的，用户也应当一并赔偿。</p>
                    <p>3.5 帐号资质审核成功后，用户可以申请为其开通高级功能，用户使用高级功能时应遵守法律、行政法规和本协议或相关服务条款的规定，否则，中乐之声有权不经通知随时视行为情节对违规用户的帐号采取包括但不限于限制、停止使用高级功能等措施。</p>
                    <p>3.6 认证成功后，用户有权以认证帐号名称和认证信息所公示的身份对外运营，并通过认证标识区别于其他非认证中乐之声企业号用户。</p>
                </Auto>
                <Title1>
                    四、【中乐之声的权利义务】
                </Title1>
                <Auto>
                    <p>4.1 保护用户信息是中乐之声的一项基本原则，中乐之声将会采取合理的措施保护用户所提交的一切资料及信息。除法律法规规定的情形外，未经用户许可中乐之声不会向其他第三方公开、透露上述资料及信息。用户基于申请本服务的需要，授权中乐之声将其提交的资料和信息委托第三方审核机构进行甄别核实，在本协议许可的范围内，中乐之声及第三方审核机构对相关资料及信息采用专业加密存储与传输方式进行传输及使用，保障用户的信息安全。</p>
                    <p>4.2 为依法保障中乐之声认证服务的规范有序，中乐之声有权根据相关法律法规的规定及行业标准，针对用户的不同认证需求制定不同的认证审核标准及要求，并有权在必要时对相关标准及要求进行修改。</p>
                    <p>4.3 中乐之声有权根据用户提交的资料及信息，并结合第三方审核机构甄别核实的情况独立判断，分别确定审核结果，并根据审核情况分阶段确定帐号资质审核结果以及用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限。</p>
                </Auto>
                <Title1>
                    五、【审核服务费用】
                </Title1>
                <Auto>
                    <p>5.1 用户理解并同意，用户申请中乐之声认证服务需要向中乐之声支付审核服务费用，每项订单收取一次费用，申请中乐之声认证服务因类别不同分为新订单与补充订单，具体费用标准以认证申请页面公示为准。用户在帐号资质审核成功后发起的首次补充订单免收审核服务费用。</p>
                    <p>5.2 用户理解并且同意，审核服务费用是基于中乐之声提供的认证审核服务而支付的一次性费用，用户每申请一次认证服务均应向中乐之声支付一次审核服务费，相关费用不以认证成功为前提，且不受任何一种审核结果的任何情形及认证状态的影响。</p>
                    <p>5.3 用户付款后，中乐之声将根据用户提交的发票信息向用户开具等额合法有效发票，用户应保证其提交的发票信息真实、准确及有效，否则因此导致的损失由用户自行承担。</p>
                </Auto>
                <Title1>
                    六、【认证服务规范】
                </Title1>
                <Auto>
                    <p>
                        6.1 【认证流程】<br />
                        6.1.1 用户申请本服务需要登录申请页面按照中乐之声提示提交有关资料及信息。你在申请本服务时应仔细阅读并同意本协议，你对本服务的接受、购买、提交资料和信息、付款等行为即视为你已阅读并同意受本协议的约束。<br />
                        6.1.2 用户在完成在线申请及资料提交流程，并完成审核费用支付后，中乐之声会在15个工作日内展开认证审核工作，用户应积极配合中乐之声及第三方审核公司的审核需求，并有权随时了解、查询审核进度。<br />
                        6.1.3 用户同意中乐之声可自行委托第三方审核机构对其提交的资料和信息进行甄别核实，并在完成所有审核流程后，由中乐之声独立作出帐号资质审核成功或者失败、帐号名称审核成功或者失败、认证成功或者认证失败的判断，并以前述判断为依据确定用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限等，用户应当予以配合。<br />
                        6.1.4 审核完成后，中乐之声将反馈以下审核结果：<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.4.1 帐号资质审核成功，用户获得申请中乐之声开通高级功能的权利；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.4.2 帐号名称审核成功，中乐之声将作出认证成功的判断，确定用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.4.3 认证失败，中乐之声将告知用户认证失败的原因。<br />
                        6.1.5 审核成功用户应根据以下条款申请年审认证：<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.5.1 用户帐号资质审核成功后帐号审核成功状态将会被保留一年（起算日为帐号资质审核成功日）。用户如需持续保留帐号审核成功状态，保持高级功能的申请、使用权利，则应自帐号资质审核成功之日起一年内发起并完成年审认证，年审认证流程及认证审核标准与原认证审核一致。如用户未能及时完成年审认证或者帐号资质审核失败，其帐号审核成功状态终止，高级功能、高级权限的申请、使用权将被取消。<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.5.2 用户认证成功后其认证帐号名称、认证标识及认证信息将会被保留一年（起算日为帐号资质审核成功日）。用户如需持续保留上述信息，则应自帐号资质审核成功之日起一年内发起并完成年审认证，年审认证流程及认证审核标准与原认证审核一致。如用户未能及时完成年审认证或者帐号资质审核失败，可继续使用认证帐号名称，但高级功能、高级权限的申请、使用权，帐号认证标识以及认证信息等将被取消；如用户帐号资质审核成功、帐号名称审核失败，可继续使用认证帐号名称，保留高级功能的申请、使用权，但开通的高级权限、帐号认证标识以及认证信息等将被取消。<br />
                        6.1.6 用户向中乐之声或者第三方审核机构提供的资料和信息如有变更的，应当及时采取以下措施：<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.6.1 如处于认证审核过程中的资料和信息发生变更，用户应立即通知中乐之声或负责审核订单的第三方审核机构更新有关资料及信息；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.6.2 如认证成功后资料和信息发生变更，用户应及时申请补充订单变更有关资料及信息；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.1.6.3 如认证成功后中乐之声发现资料和信息存在错误，用户应及时申请补充订单更正有关资料及信息。
                    </p>
                    <p>
                        6.2 【认证规则】<br />
                        6.2.1 用户理解并同意：中乐之声认证服务内容仅限于对用户提交的资料及信息进行甄别与核实，中乐之声将对前述资料及信息进行合理、谨慎的形式审查，但在中乐之声的合法权限和合理能力范围内，中乐之声无法实质审查用户的实际经营、运营以及推广等行为，并不为此提供任何担保。<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.2.2.1 用户是否拥有合法主体资质，以及是否取得提供服务或内容相应的权利或授权；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.2.2.2 帐号运营者主体身份，以及是否经过用户明确授权；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;6.2.2.3 其他中乐之声或第三方审核机构认为需要审核的内容。用户应就上述内容提供相应的证明文件予以证明并承担真实性、合法性、准确性的瑕疵担保责任。<br />
                        6.3 认证帐号名称应当遵守中乐之声为此制定的命名规则。中乐之声有权根据命名规则审核用户提交的认证帐号名称申请，并提供建议名称。如用户申请名称不能符合命名规则要求的，中乐之声有权以建议名称作为用户认证帐号名称。<br />
                        6.4 认证信息内容由中乐之声根据审核情况进行确定，并在认证帐号名称审核成功后生成，主要包括用户主体资质和权利等可有效证明的信息。中乐之声有权根据平台规范运营的需要调整认证信息的内容及格式。<br />
                        6.5 为保障中乐之声安全运营及平台其他用户的合法权益，若中乐之声发现或经他人举报用户的帐号存在任何违反法律法规、《中乐之声用户服务协议》及本协议等各项规范规则的情况，或中乐之声认为或经第三方通知用户的帐号存在或可能存在上述情况，不管该帐号是否已经认证成功，中乐之声均有权采取拒绝提供年审认证服务等方式停止为该用户提供本服务。
                    </p>
                </Auto>
                <Title1>
                    七、【法律责任】
                </Title1>
                <Auto>
                    <p>
                        7.1 如果中乐之声发现或收到他人投诉用户违反法律法规、《中乐之声用户服务协议》或本协议约定的，中乐之声有权不经通知随时视行为情节对违规用户的帐号处以包括但不限于拒绝提供本服务、终止本服务资质审核、责令用户补充资质审查资料、责令用户修改认证信息、责令用户再次申请认证、强制修改帐号名称及认证信息直至取消认证等措施。
                    </p>
                    <p>
                        7.2 用户理解并同意，因用户违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，用户应当独立承担责任；中乐之声因此遭受损失的，用户也应当一并赔偿。
                    </p>
                </Auto>
                <Title1>
                    八、【其他】
                </Title1>
                <Auto>
                    <p>8.1 你使用本服务即视为你已阅读并同意受本协议的约束。中乐之声有权在必要时修改本协议条款。你可以在相关服务页面查阅最新版本的条款。本协议条款变更后，如果你继续使用中乐之声认证服务，即视为你已接受修改后的协议。如果你不接受修改后的协议，应当停止使用中乐之声认证服务。</p>
                    <p>8.2 本协议签订地为中华人民共和国北京市房山区。</p>
                    <p>8.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
                    <p>8.4 若你和中乐之声之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，你同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</p>
                    <p>8.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
                    <p>8.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
                    <p>8.7 你在使用本服务过程中，如发现相关内容存在违反相关法律法规或者侵犯了你的权利，请及时向我们投诉或反馈（010-53322640法定工作日9:00-18:00），我们将依法处理。</p>
                </Auto>
                <Warning>
                    中乐之声团队
                </Warning>
            </Wrapper >
        )
    }
}

export default VIPAgreement