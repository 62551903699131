import styled from 'styled-components';
import {
  themenew
} from '../../../../common/color';

export const LoginWrapper = styled.div`
  width:100%;
  position:relative;
`
export const Registered = styled.div`
  margin-right:5%;
  float:right;
  color:${themenew.link_color};
`
export const Title = styled.div`
  margin-top:3.625rem;
  margin-left:5%;
  font-size:1.875rem;
  color:${themenew.text_color};
  div{
    margin-bottom:0.6rem;
  }
  span{
    display:block;
    font-size:0.75rem;
    color:${themenew.subtext_color};
    p{
      display:inline;
      color:${themenew.link_color};
    }
  }
`
export const BoxWrapper = styled.div`
  width:90%;
  margin:0.625rem 5%;
  background-color:#ffffff;
  padding:1.25rem 5%;
  box-sizing:border-box;
  height:15.625rem;
  position: relative;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05); 
  >span{
    font-size:0.875rem;
    color:${themenew.text_color};
  }
`
export const FormItem = styled.div`
  height:3.125rem;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  margin-bottom:1.25rem;
    input {
      width:100%;
      height:2.125rem;
      font-size:1.25rem;
      line-height:2.125rem;
      margin-top:0.2rem;
      border:0;
      color:${themenew.text_color};
      caret-color:${themenew.text_color};
    }
    input::-webkit-input-placeholder{
      color:${themenew.unavailable_color};
    }
    input:focus {
    }
    input.error {
      color: ${themenew.error_color};
    }
`
export const FormItemVerification = styled.div`
  height:3.125rem;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  margin-bottom:1.25rem;
    input {
      width:67%;
      height:2.125rem;
      font-size:1.25rem;
      line-height:2.125rem;
      margin-top:0.2rem;
      border:0;
      color:${themenew.text_color};
      caret-color:${themenew.text_color};
    }
    input::-webkit-input-placeholder{
      color:${themenew.unavailable_color};
    }
    input:focus {
    }
    input.error {
      color: ${themenew.error_color};
    }
    input.success {
      color: ${themenew.text_color};
    }
    span {
        width:33%;
        font-size: 0.875rem;
        display: inline-block;
    }
`
export const LoginButton = styled.button`
  width:88.8%;
  height:3.125rem;
  background-color:red;
  position: absolute;
  bottom:-1.55rem;
  margin:0 auto;
  border-radius:0.375rem;
  background-image:linear-gradient(135deg, #0060EC 0, #B82CFC 100%);
  color:${themenew.text_white_color};
  font-size:1rem;
`
export const LoginButtons = styled.button`
  width:88.8%;
  height:3.125rem;
  margin-top:4.875rem;
  margin-left:5.6%;
  border-radius:0.375rem;
  background-image:linear-gradient(135deg, #0060EC 0, #B82CFC 100%);
  color:${themenew.text_white_color};
  font-size:1rem;
`
export const FormItemPsassword = styled.div`
  height:3.125rem;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  margin-bottom:1.25rem;
    input {
      width:90%;
      height:2.125rem;
      font-size:1.25rem;
      line-height:2.125rem;
      margin-top:0.2rem;
      border:0;
      color:${themenew.text_color};
      caret-color:${themenew.text_color};
    }
    input::-webkit-input-placeholder{
      color:${themenew.unavailable_color};
    }
    input:focus {
    }
    input.error {
      color: ${themenew.error_color};
    }
    div{
      display:inline-block;
      width:10%;
      line-height:2.125rem;
      height:2.125rem;
      text-align:center;
      i{
        font-size:1.5rem;
      }
    }
`
export const Code = styled.button`
    display: inline-block;
    width: 100%;
    height: 2.1875rem;
    padding: 0px 10px;
    box-sizing: border-box;
    color: #ffffff!important;
    background-image:linear-gradient(135deg, #0060EC 0, #B82CFC 100%);
    margin-right: 30px;
    border-radius: 0.375rem;
    outline: none; 
    :hover{
        cursor: pointer;
    }
`
export const LoginTab = styled.div`
  width:100%;
  text-align:center;
  font-size:0.75rem;
  margin-top:2.625rem;
  color:${themenew.link_color};
  span{
    margin:0 0.25rem;
    a{
      color:${themenew.link_color};
    }
  }
`
export const File = styled.div`
  //position:absolute;
  //text-align:center;
  width:100%;
  font-size:0.6875rem;
  //top:43rem;
  line-height:1rem;
  span{
    color:${themenew.link_color};
  }
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:5%;
  margin-top:3.5rem;
`
export const NavBar = styled.div`
  width:100%;
  height:5.5rem;
  background-color:${themenew.bg_color};
  position:fixed;
  top:0;
  z-index: 10;
`
export const Info = styled.span`
  margin-left:0.5rem;
`
export const FinishIcon = styled.div`
  text-align: center;
  margin-top:3.95rem;
`
export const FinishTip = styled.div`
  text-align:center;
  font-size:1.5rem;
  margin-top:1.875rem;
  color:#379C79;
  line-height:2rem;
`
export const FrameTrigger = styled.div`
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:998;
  //background-color:red;
`
export const PermissionBox = styled.div`
  background-color:#fff;
  width:17.8rem;
  height:24.9rem;
  position:fixed;
  top:50%;
  left:50%;
  margin-top:-12.45rem;
  margin-left:-8.9rem;
  z-index:1000;
  border-radius: 0.5rem;
`
export const PermissnBG = styled.div`
background:rgba(0,0,0,0.5);
position:fixed;
top:0;
bottom:0;
left:0;
right:0;
z-index:999;
`
export const PermissionBoxTitle = styled.div`
  font-size:1rem;
  font-weight:600;
  margin:0 auto;
  margin-top:1.1875rem;
  margin-bottom:1rem;
  text-align:center;
  width:15.3rem;
  color:#2c2c2c;
`
export const PermissionBoxBody = styled.div`
  font-size:0.875rem;
  width:15.3rem;
  height:16.5rem;
  margin:0 auto;
  margin-bottom:1.25rem;
  overflow:auto;
  line-height:1.375rem;
  color:#333;
`
export const PermissionBoxButton = styled.div`
  width:15.9rem;
  margin:0 auto;
`
export const PermissionBoxButtonLeft = styled.div`
  width:7.625rem;
  height:2.5rem;
  float:left;
  line-height:2.5rem;
  border:1px #BEBEBE solid;
  border-radius:0.25rem;
  color:#646464;
  text-align:center;
`
export const PermissionBoxButtonRight = styled.div`
  width:7.625rem;
  height:2.5rem;
  float:right;
  line-height:2.5rem;
  border-radius:0.25rem;
  color:#fff;
  text-align:center;
  background: #015FEC;
`