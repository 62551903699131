import styled from 'styled-components';

import {
    theme, themenew
} from '../../../../common/color';

const bpheight = document.body.scrollHeight - 60 + 'px'

export const Wrapper = styled.div`
  width:100%;
  background: #fff;
`

export const CountryList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .hidden {
    display: none;
    }

    #app {
    height: 100%;
    font-size: 14px;
    }

    [data-reactroot] {
    height: 100%;
    }
`
export const FilterButton = styled.div`
    width: 100%;
    height: 3.75rem;
    line-height: 3.75rem;
    padding: 0 4%;
    background: #fff;
    border-bottom: 0.5px solid #D6D6D6;
    position:fixed;
    top:0;
    z-index: 9;
    font-size: 0.875rem;
    //position: relative;
    >div{
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        display: inline-block;
        background: #F3F5F9;
        border-radius: 4px;
        margin-right: 10px;
        color: #000;
    }
    >span{
        position: absolute;
        color: #000;
        display: inline-block;
        height: 3.65rem;
        background: #fff;
        right: 0;
        line-height: 3.65rem;
        width: 5rem;
        text-align: center;
        z-index: 5;
    }
`

export const CountryBorder = styled.div`
    flex: 1;
    overflow: auto;
    position: relative;
    .brandsList {
        padding: 0.75rem 4%;
        border-bottom: 0.5px solid #E1E1E1;
        text-align: left;
        background: #fff;
        height: 4.375rem;
    }
    .title{
        color: #4A4A4A;
        background: #F6F6F6;
        height: 1.75rem;
        line-height: 1.75rem;
        padding: 0 4%;
    }
    .char-list-border {
        padding-top: 60px;
        position: fixed;
        right: 0;
        top: 0;
        width: 20px;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    .char-list {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
    }
    .char-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
    .char-tip {
        position: fixed;
        width: 50px;
        height: 50px;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background-color: gray;
        border-radius: 6px;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 50px;
        text-align: center;
    }
`
export const BrandsListImg = styled.div`
    width: 2.875rem;
    height: 2.875rem;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 0.75rem;
    border-radius: 50%;
    >img{ 
        width:100%;
        min-height:2.875rem;
        object-fit: cover;
    }
`
export const BrandsListEn = styled.div`
    color: ${themenew.text_color};
    font-size: 1.125rem;
`
export const BrandsListCn = styled.div`
    color: ${themenew.subtext_color};
    font-size: 0.875rem;
    margin-top: 0.75rem;
`
export const MenuTitle = styled.div`
    width:100% ;
    height:3.75rem ;
    line-height:3.75rem ;
    padding: 0 1rem ;
    span{
      float:right ;
      font-weight:500 ;
    }
`
export const SubTitle = styled.div`
    width:100% ;
    height:28px ;
    line-height:28px ;
    padding: 0 1rem ;
    background: #F6F6F6;
    font-weight:500 ;
    font-size:16px ;
    color: #4A4A4A;
    
`
export const BrandAndProduct = styled.div`
    width:100% ;
    height:102px ;
    padding: 0.725rem 1rem ;
    display:flex ;
    border-bottom: 0.5px solid #E1E1E1;
    >div{
      height:78px ;
      position:relative ;
      >div{
        height:3rem ;
        line-height:1.5rem ;
        color: #203152;
        font-size:18px ;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      >span{
        display:block ;
        position:absolute ;
        bottom: 0;
        >i{
          font-size: 1.6rem;
          margin-right:1.5rem ;
        }
      }
    }
    img{
      width: 80px;
      height: 80px;
      margin-left: 1rem;
    }
`
export const BrandAndProductWrapper = styled.div`
    width:100% ;
    height: ${bpheight};
    overflow-y: auto;
`