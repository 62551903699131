import React, {
    Component
} from 'react';
import { Input, Picker, Radio, Space, TextArea } from 'antd-mobile'
import {
    message,
} from "antd"
import {
    CommunityWrapper,
    DIYDemoBlock,
    Xuanze,
    Fengmiantu,
    ImageUploader,
    ImageUploaderBox,
    VideoUploader,
    DeleteVideo
} from './style';

import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";
import axios from "axios"

class MyExDetailListPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            fenlei: '',
            fenleiname: '',
            visible: false,
            fenleidata: [],
            xiangguanpinpai: [],
            label: '1',
            ProductImgs: [],
            videoArr: [],
            cover: '',
            uploadImageFlag: 1,
            VideoIDs: []
        };
    }

    componentDidMount() {

        // alert(this.props.match.params.pid);

        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        this.getEvaType();
    }

    componentDidUpdate() {
        var that = this;
        //右上角按钮动作
        window.saveInfo = async function () {
            await that.submit();
            // window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "没有找到要关联的产品", buttonTitle: "保存", weburl: "/MyBrandsCenter/ApplyBrand" }))
        }

        //图片上传结果
        window.GetUploadImages = function (imagesUrl) {
            if (that.state.uploadImageFlag == 1) {
                that.setState({ cover: imagesUrl });
            }
            else {
                let imageList = imagesUrl.split(";");
                let tem = [...that.state.ProductImgs, ...imageList];

                that.setState({ ProductImgs: tem });
            }
        }

        //视频上传结果
        window.uploadVideo = function (video) {

            let tempData = JSON.parse(video);
            let tempData1 = [];
            tempData1.push(tempData.videoUrl);

            that.setState({ videoArr: tempData1 });

            let tempData2 = [];
            tempData2.push(tempData.vid);

            that.setState({ VideoIDs: tempData2 });
        }

        //搜索结果
        window.search = function (data) {

            if (data.length > 0) {
                let tempData = [];
                data.forEach(element => {
                    tempData.push(element.BrandName);
                });

                that.setState({ xiangguanpinpai: tempData });
            }
        }
    }

    //删除图片
    deleteImg = async (url) => {
        const res = axios.delete(api.bcDeleteImg.url + '?imgURL=' + url)
        if (res) {
            this.setState({
                ProductImgs: this.state.ProductImgs.slice(0).filter(i => i !== url),
            })
        }
    }
    //删除视频
    deleteVideo = async (url) => {
        const {
            videoArr,
        } = this.state;
        this.setState({
            videoArr: videoArr.filter(i => i !== url),
            percent: 0
        })
    }

    getEvaType = async () => {
        const res = await httpAxios(api.exhibitionProductTypes);

        if (res) {
            let data = [];
            let data1 = [];
            for (let index = 0; index < res.Data.length; index++) {
                const element = res.Data[index];
                data1.push({ label: element.Title, value: element.ID })
            }
            data.push(data1);
            this.setState({
                fenleidata: data
            }, () => {
                if (this.props.match.params.pid != "0") {
                    this.getProductInfo();
                }
            });
        }

    }

    submit = async () => {
        const {
            title,
            cover,
            label,
            ProductImgs,
            videoArr,
            description,
            fenlei,
            productLink,
            VideoIDs
        } = this.state;

        let brandIds = [];

        let tempBrand = localStorage.getItem("brand_selected");

        if (tempBrand != null && tempBrand.length > 0) {
            let tempBrands = JSON.parse(tempBrand);

            for (let index = 0; index < tempBrands.length; index++) {
                const element = tempBrands[index];
                brandIds.push(element.ID);
            }
        }

        if (!title) {
            message.info('请填写标题');
            return
        } else if (title.length > 50) {
            message.info('标题不能超过50字');
            return
        } else if (fenlei.length === 0) {
            message.info('请选择产品分类');
            return
        } else if (!cover) {
            message.info('请至少选择一种比例的封面图上传');
            return
        } else if (brandIds.length === 0) {
            message.info('请选择关联品牌');
            return
        } else if (label === 1 && ProductImgs.length === 0) {
            message.info('请上传产品图组');
            return
        } else if (label === 2 && videoArr.length === 0) {
            message.info('请上传产品视频');
            return
        }

        if (this.props.match.params.pid == 0) {

            let saveData = {
                "UserBID": this.state.userPID,
                "MainPicLandScape": cover,
                "ImageScale": "43",
                "CatalogId": fenlei[0],
                "EXID": this.props.match.params.id,
                "Title": title,
                "Description": description,
                "ImgLinks": label === "1" ? ProductImgs.join(',') : '',
                "VideoSrc": label === "2" ? videoArr.join(',') : '',
                "BrandIDs": brandIds.join(','),
                "Labels": label,
                "EXType": label,
                "ProductLink": productLink,
                "VideoIDs": VideoIDs.join(',')
            }

            const res = await httpAxios(api.addExProduct, saveData)
            if (res && res.Code === 200) {
                this.setState({
                    confirmHidden: true
                })
                message.success('发表成功');

                //清空品牌选择
                localStorage.setItem("brand_selected", "");

                setTimeout(() => {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goback" }));
                    localStorage.setItem('REFRESH_TAGS', 'start');
                }, 2000)
            }
        }
        else {

            let saveData = {
                "ID": this.props.match.params.pid,
                "MainPicLandScape": cover,
                "ImageScale": "43",
                "CatalogId": fenlei[0],
                "Title": title,
                "Description": description,
                "ImgLinks": label === "1" ? ProductImgs.join(',') : '',
                "VideoSrc": label === "2" ? videoArr.join(',') : '',
                "BrandIDs": brandIds.join(','),
                "Labels": label,
                "EXType": label,
                "ProductLink": productLink,
                "VideoIDs": VideoIDs.join(',')
            }

            const res = await httpAxios(api.editExProduct, saveData)
            if (res && res.Code === 200) {
                this.setState({
                    confirmHidden: true
                })
                message.success('发表成功');

                //清空品牌选择
                localStorage.setItem("brand_selected", "");

                setTimeout(() => {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goback" }));
                    localStorage.setItem('REFRESH_TAGS', 'start');
                }, 2000)
            }
        }
    }

    saveValue = value => {
        if (value.length > 1000) {
            alert('字数不能超过1000个字');
            value = value.substring(0, 1000)
        }
        this.setState({
            description: value,
        })
    }

    //获取编辑数据
    getProductInfo = async () => {
        const res = await httpAxios(api.getExProductInfo, {
            pid: this.props.match.params.pid
        });
        if (res) {

            let tempData = [];
            let tempDataLabel = [];
            res.Data.Brands.forEach(element => {
                tempData.push(element.ID);
                tempDataLabel.push(element.BrandName);
            });

            this.setState({
                cover: res.Data.MainPicLandScape,
                // cover916: res.Data.MainPicPortrait,
                title: res.Data.Title,
                description: res.Data.Description,
                descriptionNum: res.Data.Description == null ? 0 : res.Data.Description.length,
                ProductImgs: (res.Data.ImgLinks && res.Data.ImgLinks.split(',')) || [],
                videoArr: (res.Data.VideoSrc && res.Data.VideoSrc.split(',')) || [],
                brandIds: tempData,
                xiangguanpinpai: tempDataLabel,
                labelId: Number(res.Data.Labels),
                type: res.Data.TypeName,
                catalogId: res.Data.CatalogId,
                productLink: res.Data.ProductLink,
                imageScale: res.Data.ImageScale
            })

            //分类
            var fl = this.state.fenleidata[0].filter(a => a.value == res.Data.CatalogId);

            this.setState({
                fenleiname: fl[0].label,
                fenlei: [res.Data.CatalogId]
            })

            //图片和视频
            if (res.Data.VideoSrc != null && res.Data.VideoSrc.length > 5) {
                this.setState({ label: "2" });
            }

            //所属品牌
            localStorage.setItem("brand_selected", JSON.stringify(res.Data.Brands));
        }
    }

    render() {
        const {
            title,
            fenlei,
            fenleiname,
            visible,
            fenleidata,
            xiangguanpinpai,
            label,
            cover,
            ProductImgs,
            videoArr,
            description
        } = this.state;
        return (
            <CommunityWrapper>
                <DIYDemoBlock>
                    <span>展会产品标题</span>
                    <div>
                        <Input
                            placeholder='请输入内容'
                            value={title}
                            onChange={value => { this.setState({ title: value }) }}
                        />
                    </div>
                </DIYDemoBlock >
                <DIYDemoBlock>
                    <span>分类</span>
                    <div onClick={() => {
                        this.setState({
                            visible: true,
                        })
                    }}>
                        <Xuanze>
                            {
                                fenleiname == '' ? '请选择' : fenleiname
                            }
                            <i className='iconfont'>&#xe744;</i>
                        </Xuanze>
                        <Picker
                            columns={fenleidata}
                            visible={visible}
                            onClose={() => {
                                this.setState({
                                    visible: false,
                                })
                            }}
                            value={fenlei}
                            onConfirm={(v, extend) => {
                                this.setState({
                                    fenlei: v,
                                    fenleiname: extend.items[0].label
                                })
                            }}
                        />
                    </div>
                </DIYDemoBlock >
                <DIYDemoBlock>
                    <span>封面图</span>
                    <Fengmiantu>
                        {/* 请上传4:3、1:1 或 3:4 其中一种比例的封面图 */}
                        大小不超过5MB
                        <ImageUploader onClick={() => {
                            this.setState({ uploadImageFlag: 1 }, () => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadimages", multiple: false }));
                            });
                        }}>
                            {cover ? <><img className='image' src={cover} alt="" /><i className="iconfont" style={{ color: '#fff', opacity: '0.6' }}>&#xe748;</i></> :
                                <i className="iconfont">&#xe7b0;</i>
                            }
                        </ImageUploader>
                    </Fengmiantu>
                </DIYDemoBlock>
                <DIYDemoBlock>
                    <span>相关品牌</span>
                    <div onClick={() => {
                        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "modal", modalTitl: '选择相关品牌', buttonModalTitle: '确定', weburl: '/MyExhibition/MyExDetailBrandList' }));
                    }}>
                        <Xuanze>
                            {
                                xiangguanpinpai.length == 0 ? '请选择（可多选）' : xiangguanpinpai.join(',')
                            }
                            <i className='iconfont'>&#xe744;</i>
                        </Xuanze>
                    </div>
                </DIYDemoBlock >
                <DIYDemoBlock>
                    <span>请选择产品发布形式</span>
                    <Fengmiantu>
                        <Radio.Group value={label}
                            onChange={val => {
                                this.setState({
                                    label: val
                                })
                            }}>
                            <Space direction='vertical'>
                                <Radio value='1'>图片</Radio>
                                <Radio value='2'>视频</Radio>
                            </Space>
                        </Radio.Group>

                        {label == '1' ? <>
                            <span>产品图片每张大小不超过5MB，最多上传20张，请使用JPG.JPEG.PNG格式</span>
                            <ImageUploaderBox>
                                {ProductImgs.map((i, index) => (
                                    <ImageUploader key={index}>
                                        <img src={i} alt="" />
                                        <i className="iconfont" style={{ color: '#fff', opacity: '0.6' }} onClick={this.deleteImg.bind(this, i)}>&#xe748;</i>
                                    </ImageUploader>
                                ))}

                                {ProductImgs.length < 20 && <ImageUploader onClick={() => {
                                    this.setState({ uploadImageFlag: 2 }, () => {
                                        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadimages", multiple: true }));
                                    });
                                }} >
                                    <i className='iconfont'>&#xe7b0;</i>
                                </ImageUploader>
                                }
                            </ImageUploaderBox>
                        </> : <>
                            <span>产品视频大小不超过400MB，时长不超过10分钟，请使用MP4格式</span>
                            <VideoUploader onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadvideo" }));
                            }}>

                                {videoArr.length == 0 ? <i className='iconfont'>&#xe7b0;</i> : <video style={{ width: '100%', height: '100%' }} controls src={videoArr[0]}></video>}
                            </VideoUploader>
                            {videoArr.length !== 0 && <DeleteVideo onClick={this.deleteVideo.bind(this, videoArr[0])}>删除视频</DeleteVideo>}
                        </>
                        }
                    </Fengmiantu>
                </DIYDemoBlock>
                <DIYDemoBlock>
                    <span>商品网店链接</span>
                    <div>
                        <Input
                            placeholder='请输入内容'
                            value={this.state.productLink}
                            onChange={value => { this.setState({ productLink: value }) }}
                        />
                    </div>
                </DIYDemoBlock >
                <DIYDemoBlock>
                    <span>参展产品描述</span>
                    <div>
                        <TextArea
                            placeholder='请输入内容'
                            value={description}
                            autoSize={{ minRows: 3, maxRows: 8 }}
                            onChange={value => this.saveValue(value)}
                        />
                    </div>
                </DIYDemoBlock >
                {/* <DIYDemoBlock style={{ height: '20px' }}>
                    <button onClick={this.submit}>保存</button>
                </DIYDemoBlock > */}
            </CommunityWrapper >
        )
    }
}

export default MyExDetailListPost