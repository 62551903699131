import React, { Component } from 'react';
import {
    Info,
    LoginWrapper,
    LoginButtons,
    FinishIcon,
    FinishTip,
} from '../../../login/LoginStyle';
import {
    SaveButton
} from './style';
const finishIcon = require('../../../../images/finish.svg');

export default class CancellationOK extends Component {
    // goLogout = () => {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({ type: "logout" }));
    // }
    render() {
        return (
            <LoginWrapper>
                <FinishIcon><img src={finishIcon} alt="成功" /></FinishIcon>
                <FinishTip>完成提交</FinishTip>
                <Info>审核时间预计2-3个工作日
                    <br />信息通过审核后即为您通过认证
                    </Info>
                <SaveButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mycenter" })); }}>返回</SaveButton>
                <SaveButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '意见与反馈', buttonTitle: '提交', weburl: '/Feedback' })); }}>遇到问题</SaveButton>
            </LoginWrapper>
        )
    }
}