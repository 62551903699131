/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleNoImg,
    NewsTitleOneImg,
    NewsPub,
    VideoImg,
    VideoIcon,
    NoFocusBtnNews,
    Empty,
    BtnNews
} from '../../../../common/generalStyle';
import Confirm from "../../../../common/confirm"
import Collection from '../../../../images/collection.svg';
import httpAxios2 from "../../../../http/httpAxios2";
import api from "../../../../http/api";
import {
    startMinsTime
} from '../../../../utils/utils';
import creatHistory from 'history/createHashHistory';  //返回上一页这段代码
const playSvg = require('../../../../images/play.svg');

const NUM_ROWS = 10;
let pageIndex = 1;
// let USERID = this.props.location.search.replace('?', '');//从网址中取栏目类型

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class MyReleasePost extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userId: this.props.location?.search.replace('?', ''),//从网址中取栏目类型
            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            typeKeys: {},
            hasMore: true,
            rowCount: 0,
            confirmHidden: true,
            deleteId: ''
        };
    };


    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
        };

        this.getList();

        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    getListRefresh = async () => {
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        await this.getList();
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }
    //读取发布的社区文章
    getList = async () => {
        const res = await httpAxios2(api.userCommunityRead, {
            Type: 1,
            UserPID: this.state.userId,
            pageSize: NUM_ROWS,
            pageIndex: pageIndex,
        });
        if (res) {
            this.state.rowCount = res.Message;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];
                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.setState({
                    data: [],
                })
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    cancelPost = (id) => {
        this.setState({
            deleteId: id,
            confirmHidden: false
        })
    }
    delete = async () => {
        const res = await httpAxios2(api.deleteCommunity, {
            "UserID": this.state.userId,
            "NewsID": this.state.deleteId
        });
        if (res && res.Code === 200) {
            this.setState({
                confirmHidden: true
            })
            this.getListRefresh()
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh();
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList();
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };


    render() {
        const {
            data,
            confirmHidden
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <NewsDetailWrapper>
                        <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/community' + '?' + this.state.userId, })); }}>
                            {
                                obj.ShowType == 3 ?
                                    <NewsItemOther div key={obj.ID} >
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.ListIMG} alt="" />
                                            <VideoIcon>
                                                <img src={playSvg} alt="" />
                                            </VideoIcon>
                                        </VideoImg>
                                    </NewsItemOther >
                                    :
                                    <>
                                        {
                                            obj.ShowType == 0 ?
                                                <NewsItem div key={obj.ID} >
                                                    <NewsTitleNoImg>{obj.Title}</NewsTitleNoImg>
                                                </NewsItem >
                                                :
                                                <>
                                                    {
                                                        obj.ShowType == 2 ?
                                                            <NewsItemOther div key={obj.ID} >
                                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                                <NewsThreeImgWrap>
                                                                    {
                                                                        obj.ImageAppUrl.split(';').map((image, index) => (
                                                                            index < 3 && <div><img src={image} alt="" /></div>
                                                                        ))
                                                                    }
                                                                </NewsThreeImgWrap>
                                                            </NewsItemOther >
                                                            :
                                                            <NewsItem div key={obj.ID} >
                                                                <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                                <NewsImgWrap><img src={obj.ListIMG} alt="" /></NewsImgWrap>
                                                            </NewsItem >
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </a>
                        <NewsPub>
                            <img src={obj.HeadImg} alt="" />
                            <span>{obj.PNickName}</span>
                            <span>{obj.ClickNum}阅读</span>
                            <span>{startMinsTime(obj.Addtime)}</span>

                            {/* <span onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "community", id: obj.ID })); }}>编辑</span> */}
                            <NoFocusBtnNews className='cursor' onClick={this.cancelPost.bind(this, obj.ID)}>
                                <i className="iconfont">&#xe748;</i>
                                {/* <span>取消发布</span> */}
                            </NoFocusBtnNews>
                            <BtnNews className='cursor' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "community", id: obj.ID })); }}>
                                <i className="iconfont">&#xe6f6;</i>
                                {/* <span >编辑</span> */}
                            </BtnNews>
                        </NewsPub>
                    </NewsDetailWrapper>
                </div>
            );
        };
        return (<div>
            {
                this.state.rowCount != 0 ?
                    <ListView
                        initialListSize={8}
                        key={'1'}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                            {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                        </div>)}
                        renderRow={row}
                        useBodyScroll
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                        onEndReached={this.onEndReached}
                        pageSize={NUM_ROWS}
                    /> :
                    <Empty>
                        <img src={Collection} alt="发布无内容"></img>
                        <b>暂无发布内容</b>
                        <span>你还没有发布任何内容，快去发布吧～</span>
                        {/* 隐藏ListView不能去掉，不然ReactDOM高度会报错 */}
                        <ListView
                            style={{ display: 'none' }}
                            initialListSize={8}
                            key={'1'}
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource}
                            renderRow={row}
                            useBodyScroll
                            onEndReached={this.onEndReached}
                            pageSize={NUM_ROWS}
                        />
                    </Empty>
            }
            <Confirm
                content='确认取消发布吗？'
                hidden={confirmHidden}
                onOk={this.delete}
                onClose={() => { this.setState({ confirmHidden: true }) }}
            />
        </div>);
    }
}

ReactDOM.render(<MyReleasePost />, document.getElementById('root'));
export default MyReleasePost