import * as constants from './constants';

export const aboutUsClick=()=>({
    type:constants.ABOUT_US_CLICK
})
export const registerFile=()=>({
    type:constants.REGISTER_FILE
})
export const helpCenterClick=()=>({
    type:constants.HELP_CENTER_CLICK
})
export const linkUsClick=()=>({
    type:constants.LINK_US_CLICK
})
export const privacyClick=()=>({
    type:constants.PRIVACY_CLICK
})
export const trademarkClick=()=>({
    type:constants.TRADEMARK_CLICK
})
export const serviceAgreementClick=()=>({
    type:constants.SERVICE_AGREEMENT_CLICK
})
export const communityAgreementClick=()=>({
    type:constants.COMMUNITY_AGREEMENT_CLICK
})
export const infringementClick=()=>({
    type:constants.INFRINGEMENT_CLICK
})
export const businessLicenseClick=()=>({
    type:constants.BUSINESS_LICENSE_CLICK
})
