import React, {
    Component
} from 'react';

import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';

import { Form, Input, Button, Toast } from 'antd-mobile'



const MyBrandsCenterApplyBrand = () => {
    const [form] = Form.useForm()
    const [userPID, setUserPID] = React.useState('');

    React.useEffect(() => {
        window.userInfo = function (data, params) {
            setUserPID(data.UserPID)
        }
    }, []);

    //提交数据
    const onSubmit = async () => {
        const values = form.getFieldsValue()
        // alert(JSON.stringify(values))
        const res = await httpAxios(api.addApplyProduct, {
            userBID: userPID,
            BrandName: values.brand,
            ProdunctName: values.product,
            Telephone: values.phone
        });

        if (res) {
            Toast.show({
                icon: 'success',
                content: '提交成功！'
            });
        }
    }

    return (
        <>
            <div style={{ padding: '16px', fontSize: '15px', color: '#697b8c', lineHeight: '20px' }}>
                您代理的品牌或产品可能还没有被中乐之声收录。<br />请提供您的联系方式，我们的工作人员会尽快和您取得联系。
            </div>
            <Form
                form={form}
                requiredMarkStyle='text-required'
                footer={
                    <Button block color='primary' onClick={onSubmit} size='large'>
                        提交
                    </Button>
                }>
                {/* <Form.Header>没有找到要关联的产品</Form.Header> */}
                <Form.Item name='brand' label='申请品牌' rules={[{ required: true }]}>
                    <Input placeholder='请输入申请品牌' />
                </Form.Item>
                <Form.Item name='product' label='申请产品型号' help='可以填写多个以逗号隔开' rules={[{ required: true }]}>
                    <Input placeholder='请输入申请产品型号' />
                </Form.Item>
                <Form.Item name='phone' label='联系电话' rules={[{ required: true }]}>
                    <Input placeholder='请输入联系电话' />
                </Form.Item>
            </Form>
        </ >
    )
}

export default MyBrandsCenterApplyBrand