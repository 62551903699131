import * as constants from './constants';
import {fromJS} from 'immutable';

const defaultState=fromJS({
    inputfocused:false,
    searchValue: '',
    userInfo: {}
});

export default(state=defaultState,action)=>{
    if(action.type===constants.INPUT_FOCUS){
        return state.set('inputfocused',true)
    }
    if(action.type===constants.INPUT_BLUR){
        return state.set('inputfocused',false)
    }
    if (action.type === constants.HOME_SEARCH) {
        return state.set('searchValue', action.value)
    }
    if (action.type === constants.SET_USER_INFO) {
        return state.set('userInfo', action.value)
    }
    return state;
}
