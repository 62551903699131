import React, {
    Component
} from 'react';
import {
    message,
} from "antd"
import {
    Wrapper,
    DIYDemoBlock,
    ImageUploader,
    ImageUploaderBox
} from './style';

import {
    TextArea
} from 'antd-mobile';
import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";
import axios from "axios"

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            ProductImgs: [],
        };
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }
    }

    componentDidUpdate() {
        var that = this;
        //右上角按钮动作
        window.saveInfo = async function () {
            await that.submit();
        }

        //图片上传结果
        window.GetUploadImages = function (imagesUrl) {

            let tem = [...that.state.ProductImgs, imagesUrl];

            that.setState({ ProductImgs: tem });
        }
    }

    submit = async () => {
        const {
            title,
            ProductImgs,
        } = this.state;

        if (!title) {
            message.info('请填建议或反馈');
            return
        } else if (title.length > 1000) {
            message.info('标题不能超过2000字');
            return
        }

        let saveData = {
            "UserID": this.state.userPID,
            "Content": title,
            "Images": ProductImgs.join(';')
        }

        const res = await httpAxios(api.setFeedBack, saveData)
        if (res && res.Code === 200) {

            message.success('反馈成功');

            setTimeout(() => {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goback" }))
            }, 1000)
        }
    }

    //删除图片
    deleteImg = async (url) => {
        const res = axios.delete(api.bcDeleteImg.url + '?imgURL=' + url)
        if (res) {
            this.setState({
                ProductImgs: this.state.ProductImgs.slice(0).filter(i => i !== url),
            })
        }
    }

    render() {
        const {
            ProductImgs,
        } = this.state;
        return (
            <Wrapper>
                <DIYDemoBlock>
                    <span>您的建议或反馈</span>
                    <div>
                        <TextArea
                            placeholder='请描述您遇到的问题或者意见建议'
                            autoSize={{ minRows: 3, maxRows: 8 }}
                            onChange={value => { this.setState({ title: value }) }}
                        />
                    </div>
                </DIYDemoBlock>
                <DIYDemoBlock>
                    <span>请提供相关截图</span>
                    <ImageUploaderBox>
                        {ProductImgs.map((i, index) => (
                            <ImageUploader key={index}>
                                <div />
                                <img src={i} alt="" />
                                <i className="iconfont" style={{ color: '#fff', opacity: '0.6' }} onClick={this.deleteImg.bind(this, i)}>&#xe748;</i>
                            </ImageUploader>
                        ))}

                        {ProductImgs.length < 5 && <ImageUploader onClick={() => {
                            this.setState({ uploadImageFlag: 2 }, () => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadimages", multiple: false }));
                            });
                        }} >
                            <i className='iconfont'>&#xe7b0;</i>
                        </ImageUploader>
                        }
                    </ImageUploaderBox>
                </DIYDemoBlock>
            </Wrapper>
        );
    }
}

export default Feedback