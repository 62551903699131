import React,{Component} from 'react';
import {
    Tabs
} from './LabelStyle';

class Label extends Component{

    state = {
        activeKey: '0'
    }
    handleClick = (id) => {
        this.setState({
            activeKey: id
        })
        this.props.cb && this.props.cb(id, null, null);
        //返回顶部
        //window.scrollTo( 0, 0 );
    }
    render(){
        return(
            <Tabs>
                <div className={this.state.activeKey === '0' ? 'selected' : ''} onClick={this.handleClick.bind(this, '0')}>
                    <span>推荐</span>
                    {
                        this.state.activeKey === '0' ? <div /> : ''
                    }
                </div>
                {
                    this.props.list != null && 
                        this.props.list.map(i => (
                            <div key={i.ID} className={this.state.activeKey === i.ID ? 'selected' : ''} onClick={this.handleClick.bind(this, i.ID)}>
                                {
                                    this.state.activeKey === i.ID ? <><span>{i.Title}</span><div /></> : <>{i.Title}</>
                                }
                            </div>
                        ))
                }
            </Tabs>
        )
    }
}

export default Label;
