import styled from 'styled-components';
import {
  theme, themenew
} from '../../common/color';


export const CommunityDetailWrapper = styled.div`
  width:100%;
  background-color:#fff;
  margin:0 auto;
  padding-bottom: 5rem;
  video{
    background-color: #000;
  }
`
export const ContentWrapper = styled.div`
  width:92%;
  padding-top:1rem;
  margin:0 4% 1.25rem;
`
export const NavBar = styled.div`
  width:100%;
  height:5.5rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.125rem;
    color:${themenew.text_color};
    margin-top:3.5rem;
  }
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:4%;
  margin-top:3.5rem;
  float:left;
`
export const Author = styled.div`
  width:100%;
  height:5rem;
  padding-top:1.3rem;
  color:${themenew.text_color};
  position: relative;
  >img{
    width: 12px;
    height: 12px;
    position: absolute;
    top: 46px;
    left: 24px;
  }
  a{
    >img{
      width:2.25rem;
      height:2.25rem;
      border-radius:50%;
      border: 1px solid #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
      float:left;
    }
    >div{
      float:left;
      line-height:1rem;
      margin-left:0.5rem;
      height:2.25rem;
      color: ${themenew.text_color};
      >div{
        font-weight:bold;
        font-size:0.875rem;
        line-height:1.25rem;
      }
      >span{
        font-size:0.75rem;
        color:${themenew.subtext_color};
      }
    }
  }
`
export const ClickNum = styled.div`
  width:100%;
  font-size:0.75rem;
  color:${themenew.subtext_color};
  margin-bottom:1.25rem;
`
export const Focus = styled.div`
  width:3.625rem;
  height:1.75rem!important;
  line-height:1.75rem!important;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  border-radius: 4px;
  font-size:0.875rem;
  text-align:center;
  color:#fff;
  border-radius:0.25rem;
  float:right!important;
`
export const Focused = styled.div`
  width:3.625rem;
  height:1.75rem!important;
  line-height:1.75rem!important;
  font-size:0.875rem;
  text-align:center;
  background-color:#fff;
  color:#000;
  border:1px solid #888;
  border-radius:0.25rem;
  float:right!important;
`
export const TopDownload = styled.div`
  width:100%;
  line-height:3.125rem;
  height: 3.125rem;
  background-color: #fff;
  position: fixed;
  top:0;
  z-index:999;
  >img{
    width: 7.75rem;
    height: 2.375rem;
    float: left;
    margin-left: 4%;
    margin-top: 0.375rem;
  }
  div{
    width:5rem;
    height:1.75rem;
    line-height:1.75rem;
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    border-radius: 4px;
    font-size:0.875rem;
    text-align:center;
    color:#fff;
    border-radius:0.25rem;
    float:right;
    margin-right: 4%;
    margin-top: 0.6875rem;
  }
`

export const Title = styled.div`
  width:100%;
  font-size:1.5rem;
  font-weight:bold;
  line-height:2rem;
`
export const TimeAndInform = styled.div`
  width:56rem;
  height:2.5rem;
  line-height:2rem;
  color:${theme.subtext};
  @media (max-width:768px){
    width:100%;
    height:7rem;
  }
`
export const Time = styled.div`
  float:left;
`

export const Content = styled.div`
  width:100%;
  font-size:1.1rem;
  line-height:2rem;
  img{
    width:100%!important;
    height:auto!important;
  }
  video{
    width:100%!important;
    height:auto!important;
  }
  ol{
    margin-left: 10px;
  }
  .exhibitionBox{
    display: flex;
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }
  .exhibitionTitle{
    color:${themenew.text_color};
    font-size: 0.875rem;
    width: 22%;
  }
  .exhibitionText{
    color:${themenew.text_color};
    font-size: 0.875rem;
    width: 78%;
  }
  .auditiontext{
    color:${themenew.text_color};
    font-size: 0.875rem;
    width: 100%;
  }
`
export const Operation = styled.div`
  width:100%;
  padding-bottom:5.375rem;
  .active{
      border: 1px solid ${themenew.error_color};
      color: ${themenew.error_color};
      background-color:#FFE8E8;
    }
  >div{
    float:left;
    margin-right:1.6%;
    width:21.9%;
    height:2.25rem;
    line-height:2.25rem;
    padding:0 0.8rem;
    background: #FAFAFA;
    border: 1px solid #7C8698;
    border-radius: 1.125rem;
    overflow:hidden;
    >i{
      font-size:1.5rem;
      margin-right:0.1rem;
      //vertical-align:middle;
      vertical-align:-0.2rem;
    }
    >span{
      font-size:0.75rem;
      vertical-align:middle;
    }
  }
`
export const Label = styled.div`
  width:56rem;
  height:4rem;
  color:${theme.subtext};
  font-size:.8rem;
  margin-top:2.5rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const ArticleLikeBtn = styled.div`
  width:12rem;
  height:2rem;
  float:left;
  margin-left:20rem;
  padding:0 1rem;
  border-radius:8px;
  border:2px solid ${theme.gray_state};
  text-align:center;
  line-height:1.7rem;
  color:${theme.gray_state};
  &.actived{
    color: #fff;
    background-color: ${theme.rose_color};
    border:0;
  }
  i{
    margin-right:.3rem;
  }
  @media (max-width:768px){
    width:100%;
    margin-left:0;
  }
`
export const Read = styled.div`
  float:right;
`
export const MyComment = styled.div`
  width:56rem;
  margin:4rem auto 2rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const HotComment = styled.div`
  width:56rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const NewComment = styled.div`
  width:56rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const CommentAndLike = styled.div`
  font-size:.9rem;
  span{
    margin-right:1rem;
  }
  div{
    display:inline;
    color:${theme.rose_color};
  }
`
export const AddMore = styled.div`
  width:56rem;
  height:3rem;
  font-size:.9rem;
  color:${theme.subtext};
  text-align:center;
  line-height:3rem;
  background-color:${theme.line_color};
  margin-top:3rem;
  margin-bottom:5rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const CommentTitle = styled.div`
  height:1.6rem;
  border-left:5px solid ${theme.rose_color};
  line-height:1.6rem;
  text-indent:.5rem;
  font-size:1.1rem;
  margin-top:1rem;
  color:${theme.text};
`
export const CommentNum = styled.div`
  font-size:.9rem;
  text-align:right;
`
export const Input = styled.div`
  width:56rem;
  height:8rem;
  textarea{
    float:left;
    width:49rem;
    height:7rem;
    resize:none;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
  }
  @media (max-width:768px){
    width:100%;
    textearea{
      width:100%;
    }
  }
`
export const Submit = styled.div`
  float:left;
  width:7rem;
  height:7rem;
  color:#fff;
  text-align:center;
  background-color:${theme.rose_color};
  border-top-right-radius:5px;
  border-bottom-right-radius:5px;
  padding-top:5px;
  i{
    font-size:3rem;
    line-height:4rem;
  }
`
export const CommentItem = styled.div`
  width:56rem;
  clear:both;
  overflow:hidden;
  border-bottom:1px solid ${theme.line_color};
  margin:2rem 0;
  @media (max-width:768px){
    width:100%;
  }
`
export const Portrait = styled.div`
  float:left;
  width:3.5rem;
  height:3.5rem;
  img{
    width:3.5rem;
    height:3.5rem;
    border-radius:50%;
  }
`
export const CommentContent = styled.div`
  float:left;
  width:50rem;
  margin-left:1.5rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const Name = styled.div`
  display:inline-block;
  font-size:1rem;
  color:${theme.rose_color};
`
export const ReplyDec = styled.div`
  font-size:.9rem;
  color:${theme.subtext};
`
export const ReplyTime = styled.div`
  display:inline-block;
  font-size:.8rem;
  color:${theme.subtext};
  margin-left:1rem;
  span{
    margin-left:.6rem;
  }
`
export const ReplyAndLikeBtn = styled.div`
  width:56rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const ReplyBtn = styled.div`
  float:right;
  font-size:.9rem;
  color:${theme.subtext};
  span{
    margin-left:1rem;
  }
`
export const LikeBtn = styled.div`
  float:right;
  font-size:.9rem;
  color:${theme.rose_color};
  margin-left:1rem;
`
export const ToReply = styled.div`
  width:50rem;
  margin:.3rem 0 1rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const ToReplyItemRight = styled.div`
  width:46rem;
  clear:both;
  overflow:hidden;
  margin:1rem 0;
  @media (max-width:768px){
    width:100%;
  }
`
export const ToReplyItemRightPortrait = styled.div`
  float:right;
  width:5rem;
  text-align:center;
  img{
    width:3rem!important;
    height:3rem;
    border-radius:50%;
    margin:0;
  }
  div{
    font-size:.8rem;
    color:${theme.subtext};
  }
`
export const ToReplyItemRightContent = styled.div`
  float:right;
  width:37rem;
  background-color:${theme.line_color};
  border-radius:8px;
  @media (max-width:768px){
    width:100%;
  }
`
export const ToReplyItemRightContentDec = styled.div`
  font-size:.9rem;
  line-height:1.5rem;
  padding:.5rem 0 .3rem 1rem;
  span{
    color:${theme.rose_color};
  }
`
export const ToReplyItemRightContentTimeAndReply = styled.div`
  padding-left:1rem;
  width:37rem;
  font-size:.8rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const ToReplyItemRightContentTime = styled.div`
  float:left;
`
export const ToReplyItemRightContentReply = styled.div`
  float:right;
  margin-right:1rem;
  i{
    margin-left:1rem;
  }
`
export const ToReplyItemLeft = styled.div`
  width:50rem;
  clear:both;
  overflow:hidden;
  margin:1.5rem 0;
  @media (max-width:768px){
    width:100%;
  }
`
export const ToReplyItemLeftPortrait = styled.div`
  float:left;
  width:5rem;
  text-align:center;
  img{
    width:3rem;
    height:3rem;
    border-radius:50%;
    margin:0;
  }
  div{
    font-size:.8rem;
    color:${theme.subtext};
  }
`
export const ToReplyItemLeftContent = styled.div`
  float:left;
  width:37rem;
  background-color:${theme.line_color};
  border-radius:8px;
  @media (max-width:768px){
    width:100%;
  }
`
export const ToReplyItemLeftContentDec = styled.div`
  padding-left:1rem;
  font-size:.9rem;
  line-height:1.5rem;
  padding:.5rem 0 .3rem 1rem;
  span{
    color:${theme.rose_color};
  }
`
export const ToReplyItemLeftContentTimeAndReply = styled.div`
  padding-left:1rem;  
  width:37rem;
  font-size:.8rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const ToReplyItemLeftContentTime = styled.div`
  float:left;
`
export const ToReplyItemLeftContentReply = styled.div`
  float:right;
  margin-right:1rem;
  i{
    margin-left:1rem;
  }
`
export const Top = styled.div`
  width:15rem;
  height:20.7rem;
  background-color:#fff;
  @media (max-width:768px){
    width:100%;
  }
`
export const Bottom = styled.div`
  width:15rem;
  background-color:#fff;
  margin-top:2rem;
  padding-bottom:1.3rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const SideBarTitle = styled.div`
  width:13rem;
  font-size:1rem;
  height:2.5rem;
  line-height:2.5rem;
  margin:.5rem auto;
  border-bottom:1px solid ${theme.dark_line_color};
  color:${theme.subtext};
  @media (max-width:768px){
    width:92%;
    left:4%;
  }
`
export const Img = styled.div`
  width:5.2rem;
  height:5.2rem;
  margin:1rem auto .5rem;
  img{
    width:5rem;
    height:5rem;
    border-radius:50%;
  }
`
export const AuthorName = styled.div`
  width:13rem;
  height:1.5rem;
  line-height:1.5rem;
  text-align:center;
  margin:0 auto;
  font-size:1.1rem;
  color:${theme.text};
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  @media (max-width:768px){
    width:100%;
  }
`
export const Sign = styled.div`
  width:14rem;
  height:1.5rem;
  line-height:1.5rem;
  text-align:center;
  margin:0 auto;
  font-size:.8rem;
  color:${theme.subtext};
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  @media (max-width:768px){
    width:100%;
  }
`
export const Num = styled.div`
  width:12rem;
  height:4rem;
  margin:1rem auto 0;
  display:flex;
  color:${theme.subtext};
  font-size:.8rem;
  @media (max-width:768px){
    width:100%;
  }
`
export const NumItem = styled.div`
  width:6rem;
  text-align:center;
  line-height:1.5rem;
`
export const Button = styled.div`
  width:13rem;
  height:3rem;
  display:flex;
  margin:0 auto;
  text-align:center;
  justify-content:space-between;
  @media (max-width:768px){
    width:100%;
  }

`
export const Send = styled.div`
  width:6rem;
  height:2rem;
  line-height:1.8rem;
  color:${theme.theme_color};
  border:1px solid ${theme.theme_color};
  border-radius:5px;
  :hover{
    color:${theme.theme_light_auxi_color};
    border:1px solid ${theme.theme_light_auxi_color};
  }
`
export const BottomItem = styled.div`
  width:13rem;
  margin:1rem auto;
  :hover div{
    color:${theme.theme_color};
  }
  @media (max-width:768px){
    width:92%;
    left:4%;
  }
`
export const ItemTitle = styled.div`
  width:13rem;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size:1rem;
  color:${theme.subtext};
  @media (max-width:768px){
    width:100%;
  }
`
export const ItemAuthor = styled.div`
  width:13rem;
  height:2rem;
  display:flex;
  margin:0.5rem auto;
  text-align:center;
  justify-content:space-between;
  color:${theme.subtext};
  @media (max-width:768px){
    width:100%;
  }
`
export const ItemImg = styled.div`
  color:${theme.subtext};
  img{
    width:1.5rem;
    height:1.5rem;
    border-radius:50%;
    margin-top:-.2rem;
    margin-right:.5rem;
  }
`
export const VisitNum = styled.div`
  color:${theme.subtext};
  span{
    color:${theme.text};
    margin-right:.3rem;
  }
`
export const More = styled.div`
  width:11rem;
  height:2rem;
  text-align:center;
  margin:1rem auto 0;
  background-color:${theme.dark_line_color};
  line-height:2rem;
  font-size:1rem;
  color:#fff;
  border-radius:8px;
  :hover{
    background-color:${theme.subtext};
    cursor:pointer;
  }
  @media (max-width:768px){
    width:92%;
    left:4%;
  }
`
export const Block = styled.span`
  margin-left:0.625rem;
  i{
    vertical-align:-0.1rem;
  }
`
export const BlockBoxBG = styled.div`
background:rgba(0,0,0,0.5);
position:fixed;
top:0;
bottom:0;
left:0;
right:0;
z-index:999;
`
export const BlockBox = styled.div`
  background-color:#fff;
  width:17.8rem;
  height:11rem;
  position:fixed;
  top:50%;
  left:50%;
  margin-top:-5.5rem;
  margin-left:-8.9rem;
  z-index:1000;
  border-radius: 0.5rem;
`
export const BlockBoxTitle = styled.div`
  font-size:1rem;
  font-weight:600;
  margin:0 auto;
  margin-top:1.1875rem;
  margin-bottom:1rem;
  width:15.3rem;
  color:#2c2c2c;
`
export const BlockBoxBody = styled.div`
  font-size:0.875rem;
  width:15.3rem;
  height:2.5rem;
  margin:0 auto;
  margin-bottom:1.25rem;
  overflow:auto;
  line-height:1.375rem;
  color:#333;
`
export const BlockBoxButton = styled.div`
  width:15.9rem;
  margin:0 auto;
`
export const BlockBoxButtonLeft = styled.div`
  width:7.625rem;
  height:2.5rem;
  float:left;
  line-height:2.5rem;
  border:1px #BEBEBE solid;
  border-radius:0.25rem;
  color:#646464;
  text-align:center;
`
export const BlockBoxButtonRight = styled.div`
  width:7.625rem;
  height:2.5rem;
  float:right;
  line-height:2.5rem;
  border-radius:0.25rem;
  color:#fff;
  text-align:center;
  background: #015FEC;
`
export const Motai = styled.div`
  position: fixed;
  bottom: 3rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
`
export const Daojishi = styled.div`
  position: fixed;
  top: 140px;
  right: 15px;
  z-index: 99;
  >img{
    position: absolute;
    z-index: 99;
    top: 5px;
    left: 5px;
    width: 40px;
  }
`
export const QuickReply = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  background-color: #fff;
  box-shadow: 0 -2px 8px 0 rgba(85,59,163,0.16);
  z-index: 99;
  a{
    color: ${themenew.text_color};
  }
  .buttonFocuseds{
    color: #666;
  }
`
export const QuickReplys = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  background-color: #fff;
  box-shadow: 0 -2px 8px 0 rgba(85,59,163,0.16);
  z-index: 98;
  a{
    color: ${themenew.text_color};
  }
  .focused{
    width: 75%;
    i{
      display: none;
    }
    input{
      margin: 0 2%;
      width: 96%;
    }
  }
  .iconFocused{
    display: none;
  }
  .buttonFocused{
    display: block;
  }
`
export const QuickReplyDiv = styled.div`
  float: left;
  width: 40%;
  height: 2rem;
  border-radius: 0.275rem;
  background: #EFEFEF;  
  margin-left: 4%;
  margin-top: 0.5rem;
  i{
    width: 10%;
    margin-left: 4%;
    margin-right: 4%;
    float: left;
    line-height: 2rem;
  }
  div{
    width:80%;
    height: 100%;
    border: 0;
    background: #EFEFEF; 
    float: left;    
    line-height: 2rem;           
  }
`


export const QuickReplyInput = styled.div`
  float: left;
  width: 40%;
  height: 4rem;
  border-radius: 0.275rem;
  background: #EFEFEF;  
  margin-left: 4%;
  margin-top: 0.5rem;
   width: 75%;
  i{
    display: none;
    width: 10%;
    margin-left: 4%;
    margin-right: 4%;
  }
  textarea{
    margin: 0 2%;
    width: 96%;
    height: 100%;
    border: 0;
    background: #EFEFEF;  
    padding:0.4rem 0.2rem;
    line-height: 1.3rem;
  }
`
export const QuickReplyInputH5 = styled.div`
  float: left;
  width: 40%;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.275rem;
  background: #EFEFEF;  
  margin-left: 4%;
  margin-top: 0.5rem;
  
  i{
    width: 10%;
    margin-left: 4%;
    margin-right: 4%;
  }
  span{
    width:80%;
    height: 100%;
    border: 0;
    background: #EFEFEF;                
  }
`
export const QuickReplyInputTwo = styled.div`
  float: left;
  width: 77%;
  height: 2rem;
  border-radius: 0.275rem;
  background: #EFEFEF;  
  margin-left: 4%;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  i{
    width: 10%;
    margin-left: 4%;
  }
  span{
    width:80%;   
    line-height: 2rem;
  }
`
export const QuickReplyInputTwos = styled.div`
  float: left;
  width: 76%;
  height: 4rem;
  border-radius: 0.275rem;
  background: #EFEFEF;  
  margin-left: 4%;
  margin-top: 0.5rem;
  padding: 4px;
  textarea{
    width:100%;
    height: 100%;
    border: 0;
    background: #EFEFEF;   
    line-height: 20px;
  }
`

export const QuickReplyOperation = styled.div`
  float: left; 
  width:56%;
  .active{
      >i{
        color: ${themenew.error_color};
      }
    }
  div{
    float:left;
    margin-right:2%;
    width:20%;
    margin-top: 0.6rem;
    >i{
      font-size:1.8rem;
      margin-right:0.1rem;
      vertical-align:-0.2rem;
      color: ${themenew.text_color};
    }
  }
`
export const QuickReplyOperationTwo = styled.div`
  float: left; 
  width: 15%;
  .active{
      >i{
        color: ${themenew.error_color};
      }
    }
  div{
    margin-left: 1.2rem;
    margin-top: 0.6rem;
    >i{
      font-size:1.8rem;
      margin-right:0.1rem;
      vertical-align:-0.2rem;
      color: ${themenew.text_color};
    }
  }
`

export const CommentInputButton = styled.button`
  width: 18%;
  float: right;
  color: ${themenew.link_color};
  margin-right: 2%;
  text-align: center;
  line-height: 5rem;
  background-color: #fff;
`
export const CommentInputButtonBuding = styled.button`
  width: 18%;
  float: right;
  color: ${themenew.link_color};
  margin-right: 2%;
  text-align: center;
  line-height: 5rem;
  background-color: #fff;
`

export const CommentWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  >span{
    font-size: 1.125rem;
    color: ${themenew.text_color};
  }
`
export const CommentWrappers = styled.div`
  width: 100%;
  padding: 2rem 0 0;
  >span{
    font-size: 1.125rem;
    color: ${themenew.text_color};
  }
`
export const CommentBox = styled.div`
  width: 100%;
  padding: 1rem 0;
`
export const CommentBoxLeft = styled.div`
  width: 12%;
  float: left;  
  position: relative;
  >div{
    width: 12px;
    height: 12px;
    position: absolute;
    top:24px;
    left: 24px;
    >img{
      width: 100%;
    }
  }
  >img{
    width: 2.25rem;
    height: 2.25rem;
    border: 1px solid #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 50%;
  }
`
export const ExhibitionBuy = styled.div`
  width: 100%;
  height: 2.5rem;
  border-radius: 0.375rem;
  border:1px solid ${themenew.theme_color};
  color: ${themenew.theme_color};
  text-align: center;
  line-height: 2.5rem;
  margin:1.25rem auto;
  font-size: 0.875rem;
`
export const Inform = styled.span`
  margin-left:0.625rem;
  color: ${themenew.subtext_color};
  i{
    vertical-align:-0.1rem;
  }
`

export const CommentBoxRight = styled.div`
  width: 88%;
  display: inline-block;
  .isLike{
    i{
      color: ${themenew.error_color};
    }
  }
  >h1{
    font-size: 0.88rem;
    line-height: 2.25rem;
    color: ${themenew.text_color};
    >span{
      float: right;
      span{
        font-size:0.8125rem;
      }
      i{
        font-size:1.2rem;
      }
    }
  }
  >h2{
    margin-top: 0.25rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: ${themenew.text_color};
    margin-bottom: 0.5rem;
  }
  >h3{
    width: 100%;
    height: 1.88rem;
    background: #F7F7F7;
    border-radius: 4px;
    color: #7C8698;
    font-size: 13px;
    line-height: 1.88rem;
    padding:0 2%;
    margin:0.62rem 0;
  }
  >div{
    >div{
      display:inline-block;
      padding: 0 0.3rem 0 0.62rem;
      border-radius:0.72rem;
      height: 1.44rem;
      background-color: #F7F7F7;
      line-height: 1.44rem;
      margin-right: 0.75rem;
      >span{
        font-size: 0.75rem;
        color: ${themenew.text_color};
        float: left;
      }
      >i{
        font-size: 1rem;
        color: ${themenew.text_color};
      }
    }
    >span{
      font-size: 0.75rem;
      color: ${themenew.subtext_color};
    }
  }
`
export const CommentBoxRights = styled.div`
  width: 88%;
  display: inline-block;
  .isLike{
    i{
      color: ${themenew.error_color};
    }
  }
  >h1{
    font-size: 0.88rem;
    line-height: 2.25rem;
    color: ${themenew.text_color};
    >a{
      color: ${themenew.text_color};
      >span{
        float: right;
        span{
          font-size:0.8125rem;
        }
        i{
          font-size:1.2rem;
        }
      }
    }
  }
  >h2{
    margin-top: 0.25rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: ${themenew.text_color};
    margin-bottom: 0.5rem;
  }
  >h3{
    width: 100%;
    height: 1.88rem;
    background: #F7F7F7;
    border-radius: 4px;
    color: #7C8698;
    font-size: 13px;
    line-height: 1.88rem;
    padding:0 2%;
    margin:0.62rem 0;
  }
  >div{
    >a{
      >div{
        display:inline-block;
        padding: 0 0.3rem 0 0.62rem;
        border-radius:0.72rem;
        height: 1.44rem;
        background-color: #F7F7F7;
        line-height: 1.44rem;
        margin-right: 0.75rem;
        >span{
          font-size: 0.75rem;
          color: ${themenew.text_color};
          float: left;
        }
        >i{
          font-size: 1rem;
          color: ${themenew.text_color};
        }
      }
    }
    >span{
      font-size: 0.75rem;
      color: ${themenew.subtext_color};
    }
  }
`
export const CommentBoxRightTwo = styled.div`
  width: 88%;
  display: inline-block;
  >h1{
    font-size: 0.88rem;
    line-height: 2.25rem;
    color: ${themenew.text_color};
  }
  >h2{
    margin-top: 0.25rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: ${themenew.text_color};
    margin-bottom: 0.5rem;
  }
  >div{
    .isLike{
        i{
          color: ${themenew.error_color};
        }
      }
    >div{
      float: right;
      span{
        font-size:0.8125rem;
      }
      i{
        font-size:1.2rem;
      }
    }
    >span{
      font-size: 0.75rem;
      color: ${themenew.subtext_color};
    }
  }
`

export const QuickReplyBuding = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  background-color: #fff;
  box-shadow: 0 -2px 8px 0 rgba(85,59,163,0.16);
  z-index: 98;
  a{
    color: ${themenew.text_color};
  }
  .iconFocused{
    display: none;
  }
  .buttonFocused{
    display: block;
  }
`