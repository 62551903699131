import styled from 'styled-components';
import {
    theme
} from '../../common/color';

export const AdvertisingWrapper = styled.div`
    position:absolute;
    width:100%; 
	height:100%;
    background: #fff;
    img{
        width:100%; 
	    height:100%; 
    }
`