/**
 * created by zhangling on 2019/12/24
 */
// 追风的云月 18674009107 Zhang...123
// 前端测试账户 562329879@qq.com 4113b2db-9d55-47e2-a9f5-780afa5cad5e
// 品牌管理员 UserPID: "00000000-0000-0000-0000-000000000000"
// UserName: "音乐"
// Email: "zl8312070111@sina.com"
// Phone: "18674009100"
let info = JSON.parse(sessionStorage.getItem('AUDIO_USER_INFO')) || {};
let userType = sessionStorage.getItem('AUDIO_ACCOUNT_TYPE');
let userId = '',
	userName = '';
if (userType === 'buss') {
	userId = info.UserBID || '58ae3942-7c35-4842-aeee-71c905a66596'; // 后端
	userName = info.CompanyName || '未登录'
} else if (userType === 'user') {
	userId = info && info.UserPID || '502f54e3-c43d-4dca-a664-4e964cc4b077'; //
	userName = info && info.PNickName || '未登录'
}
let token = info.Token || '4AE3063EAF4A63D7BFC0C20E3ADA748B54A74C80';
// export const CURRENT_USER_TOKEN = token || '4AE3063EAF4A63D7BFC0C20E3ADA748B54A74C80'
export const CURRENT_USER_TOKEN = token
export const CURRENT_USER_NAME = userName
export const CURRENT_USER_TYPE = userType
export const CURRENT_USER_HEAD = info.HeadImg
// export const CURRENT_USER_ID = '';
export const CURRENT_USER_ID = userId || '';
// console.log(JSON.stringify(info))
export const CURRENT_USER_INFO = info;
export const FRESH_COMMENTS_PAGE_SIZE = 10;
export const CURRENT_USER_PASSWORD = sessionStorage.getItem('AUDIO_PASSWORD')

export const MYCENTER_POST_HTTP_PAGE_SIZE = 12
export const MYCENTER_COLLECTION_HTTP_PAGE_SIZE = 12

export const BUSSCENTER_POST_HTTP_PAGE_SIZE = 12

export const BUSSCENTER_COLLECTION_HTTP_PAGE_SIZE = 12
export const BRAND_DETAIL_HTTP_PAGE_SIZE = 12

export const AUDIO_URL = 'https://api.chinaaudio.net.cn'