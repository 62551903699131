import React, {
    Component
} from 'react';
import {
    CommunityDetailWrapper,
    TopDownload,
    ContentWrapper,
    Title,
    Author,
    SubjectVideo,
    QuickReply,
    QuickReplyInputs,
    SubjectWrapper,
    Subject
} from './LivebroadcastDetailH5Style';
import ReactPlayer from 'react-player';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";
import {
    endMinsTime
} from '../../utils/utils'

const h5logo = require('../../images/h5logo.png');

var u = navigator.userAgent;
// var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

const COLOR = ['#DFFF69', '#FFD769', '#7EC2FF', '#FFB4CB'];

class LivebroadcastDetailH5 extends Component {
    state = {
        data: [],
        videourl: '',
        getmessage: [],
        liveID: this.props.match.params.id,
        liveCount: 0,
    }
    componentDidMount() {
        this.getData();
        this.getMessage();
        this.getDayLiveClickCount();
        this.postClickCount();

        this.state.timer = setInterval(async () => {
            await this.getMessage();
        }, 3000);
    }
    getData = async () => {
        const res = await httpAxios(api.getLiveUrl, {
            liveID: this.state.liveID
        });
        if (res) {
            this.setState({
                data: res.Data,
                videourl: res.Data?.LiveUrls?.m3u8url
            })
        }
    }
    getMessage = async () => {
        const res = await httpAxios(api.getMessage, {
            liveID: this.state.liveID,
            pageIndex: 1,
            pageSize: 100,
        });
        if (res) {
            this.setState({
                getmessage: res.Data,
            })
        }
    }
    getDayLiveClickCount = async () => {
        const res = await httpAxios(api.getDayLiveClickCount, { liveID: this.state.liveID });
        if (res) {
            this.setState({
                liveCount: res.Data,
            })
        }
    }
    postClickCount = async () => {
        const res = await httpAxios(api.postClickCount, {
            liveID: this.state.liveID,
        });
    }
    render() {
        const {
            data,
            videourl,
            getmessage,
            liveCount,
        } = this.state;
        return (
            <CommunityDetailWrapper style={{ height: window.screen.height }}>
                <TopDownload>
                    <img src={h5logo} alt="" />
                    <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                        <div>打开App</div>
                    </a>
                </TopDownload>
                <ContentWrapper>
                    <Title>{data?.Title}</Title>
                    <Author>
                        <img src={data?.HeadImage} alt="" />
                        <div>
                            {data?.CompanyName}
                        </div>
                        <span><i className="iconfont">&#xe783;</i><span>{liveCount}</span></span>
                    </Author>
                </ContentWrapper>
                <SubjectVideo>
                    {
                        data.LiveState === 2 ? <ReactPlayer
                            url={videourl}
                            // url="https://cnaudio.oss-cn-beijing.aliyuncs.com/record/test/test/20210921zhangxingzhongqiu720p.mp4"
                            playing={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            pip={true}
                        >
                        </ReactPlayer > : <div style={{ color: "#fff", width: "100%", height: "100%", textAlign: "center", paddingTop: "10rem" }}>
                            {
                                data.LiveState === 1 ? endMinsTime(data?.BeginTime) + '开始直播' : '直播已结束'
                            }
                        </div>
                    }
                </SubjectVideo>
                <SubjectWrapper style={!isAndroid ? { bottom: '5rem' } : { bottom: '3rem' }}>
                    <Subject>
                        {
                            getmessage.map((d, index) => (
                                <div key={index} >
                                    <span style={{ color: COLOR[index % 4] }}>{d.PNickName}</span>
                                    {d.Message}
                                </div>
                            ))
                        }
                    </Subject>
                </SubjectWrapper>
                <QuickReply style={!isAndroid ? { height: '5rem' } : { height: '3rem' }}>
                    <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                        <QuickReplyInputs>
                            <i className='iconfont'>&#xe62d;</i>
                            <span>写评论...</span>
                        </QuickReplyInputs>
                    </a>
                </QuickReply>
            </CommunityDetailWrapper>
        )
    }
}

export default LivebroadcastDetailH5