import styled from 'styled-components';

export const BannerWrapper = styled.div`
  width:100%;
  //background-image: url(${require("../../../images/indexBG.png")});
  background-size:100% 100%;
  background-repeat:no-repeat;
  padding-top:1rem;
`
export const Banner = styled.div`
  //position:relative;
  width:92%;
  height:auto;
  //height:10.75rem;
  margin-left:4%;
  //border-radius: 0.5rem;
  overflow:hidden;
  //box-shadow: -1px 2px 8px -2px rgba(0,0,0,0.15);
`