import styled from 'styled-components';

import {
  theme, themenew
} from '../../../../common/color';


export const Wrapper = styled.div`
  width:92%;
  margin:0 auto ;
  padding-top:1rem ;
  img{
    width:100px ;
    height:100px ;
  }
`

export const DatePickerWrapper = styled.div`
  width:100% ;
  border:1px #d9d9d9 solid;
  background: #fff;
  height: 2rem;
  border-radius:2px ;
  line-height:2rem ;
  padding:0 0.8rem ;
`