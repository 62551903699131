import React, {
    Component
} from 'react';

class MycenterJump extends Component {
    state = {
        info: {
            "UserPID": "",
            "PNickName": "",
            "HeadImg": null,
            "Sex": null,
            "City": null,
            "IsVIP": false,
            "VipEndTime": null,
            "Count": null,
            "FollowVal": null,
            "FansVal": null,
            "Titles": null,
            "IsLocked": false,
            "proclamationTime": null,
            "proclamationDelect": null,
            "CreatTime": "",
            "Remark": null,
            "MediaMan": false,
            "EvaluationMan": false,
            "BussUserID": null,
            "IsMapBussUser": false,
            "ZanTotal": null,
            // WirteSB: '',
            // Count1: 0,
            // Count2: 0
        }
    }
    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                info: data
            })

            sessionStorage.setItem('BUSS_CENTER_TO_BRAND_MANAGER_ID', that.state.info.UserPID);
            sessionStorage.setItem('BUSS_CENTER_WEBAPP_USERINFO', JSON.stringify(data));

            if (params != null) {
                //跳转路由
                let tempData = JSON.parse(params);
                switch (tempData.type) {
                    case "personal":
                        if (that.props.history.location.pathname.indexOf("personal") == -1) {
                            that.props.history.push('/personal');
                        }
                        break;
                    case "settings":
                        if (that.props.history.location.pathname.indexOf("settings") == -1) {
                            that.props.history.push('/settings');
                        }
                        break;
                    case "community":
                        if (that.props.history.location.pathname.indexOf("community") == -1) {
                            that.props.history.push('community/communitydetail/' + tempData.id);
                        }
                        break;
                    case "mycollection":
                        if (that.props.history.location.pathname.indexOf("mycollection") == -1) {
                            that.props.history.push('/collection');
                        }
                        break;
                    case "mycomment":
                        if (that.props.history.location.pathname.indexOf("mycomment") == -1) {
                            that.props.history.push('/comment');
                        }
                        break;
                    case "myrelease":
                        if (that.props.history.location.pathname.indexOf("myrelease") == -1) {
                            that.props.history.push('/release');
                        }
                        break;
                }
            }
        }
    }
    render() {
        return (
            <>
            </>
        )
    }
}

export default MycenterJump;