import React, { Component } from 'react';

import {
    CommunityWrapper,
    TopBox,
    TaskTitle,
    TaskBox
} from './style';
import {
    Toast
} from 'antd-mobile';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";
import { Popover } from 'antd';

const returnButtonW = require('../../images/return_button_w.svg')
const returnButton = require('../../images/return_button.svg')
const jiFen = require('../../images/jifen.png');
const jiFens = require('../../images/jifens.png');
const liWu = require('../../images/liwu.png');
const jiFenKong = require('../../images/jifenkong.png');

const content = (
    <div style={{ fontSize: '12px' }}>
        <p>
            1、每7天为一个签到周期，每天签到可得10积分；<br />
            2、连续签到有奖励，第3天可得20积分，第7天可得30积分；<br />
            3、如果签到中断，将重新计算签到天数和积分。
        </p>
    </div>
);

class SignIn extends Component {
    state = {
        signInDay: 0,
        signInScore: 0,
        signInState: false,
        userPID: '',
        taskData: [],
        navAdd: false,
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);//捕获屏幕滑动式执行handleScroll
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getUserSign(data.UserPID);
            that.getUserTask(data.UserPID);
        }
        // this.getUserSign('d2de406e-4be2-42c2-82a9-6cfe27cd0bb7');
        // this.getUserTask('d2de406e-4be2-42c2-82a9-6cfe27cd0bb7');  
    }
    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    //捕获屏幕滑动
    handleScroll = () => {
        //定义handleScroll事件函数
        // let header = document.getElementById('header');
        // let footerfreeclass = document.getElementById('FooterFreeClass');
        let wholeScrollHeight = document.documentElement.scrollHeight, // 能够滚动的总高度
            visiableHeight = document.documentElement.clientHeight, // 可视区域高度
            currentOffset = document.documentElement.scrollTop; // 滚动的距离
        if (currentOffset > 20) {
            this.setState({
                navAdd: true
            });
            // header.classList.add('nav-header-change');
        } else {
            this.setState({
                navAdd: false
            });
            // header.classList.remove('nav-header-change');
        }
        // if (footerfreeclass !== undefined && footerfreeclass !== null) {
        //     if (currentOffset > 200 && wholeScrollHeight - currentOffset - visiableHeight > 400) {
        //         footerfreeclass.classList.add('footer-freeclass-block');
        //     } else {
        //         footerfreeclass.classList.remove('footer-freeclass-block');
        //     }
        // }

    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }
    //获取用户签到信息
    getUserSign = async (id) => {
        const res = await httpAxios(api.getUserSign, {
            "userID": id,
        });
        // alert(JSON.stringify(res.Data))
        if (res.Code == 200) {
            this.setState({
                signInDay: res.Data.day,
                signInScore: res.Data.score,
                signInState: res.Data.sign
            })
        }
    }
    //用户签到
    userSign = async (id) => {
        const res = await httpAxios(api.userSign, {
            "userID": id,
        });
        if (res.Code == 200) {
            Toast.show({
                icon: 'success',
                content: '签到成功',
            })
            this.getUserSign(id);
        } else {
            Toast.show({
                icon: 'fail',
                content: '签到出现错误，请联系管理员',
            })
        }
    }
    //获取用户任务信息
    getUserTask = async (id) => {
        const res = await httpAxios(api.getUserTask, {
            "userID": id,
        });
        // alert(JSON.stringify(res.Data))
        if (res.Code == 200) {
            this.setState({
                taskData: res.Data,
            })
        }
    }

    //用户领取任务积分
    userTaskScore = async (id, taskID) => {
        const res = await httpAxios(api.userTaskScore, {
            "userID": id,
            "taskID": taskID
        });
        if (res.Code == 200) {
            Toast.show({
                icon: 'success',
                content: '成功领取' + res.Data.score + '积分',
            })
            this.getUserSign(id);
            this.getUserTask(id);
        } else {
            Toast.show({
                icon: 'fail',
                content: '领取积分出现错误，请联系管理员',
            })
        }
    }


    render() {
        const {
            signInDay,
            signInScore,
            signInState,
            userPID,
            taskData,
            navAdd,
        } = this.state;
        return (
            <CommunityWrapper>
                {navAdd && <div className='go-back-flex'><div className='go-back buchong'><a onClick={this.goBackPage}><img src={returnButton} alt="返回" /></a><span>每日签到</span></div></div>}
                <div className='go-back'><a onClick={this.goBackPage}><img src={returnButtonW} alt="返回" /></a><span>每日签到</span></div>
                <div className='top-jifen'>{signInScore}<span>总积分</span></div>
                <TopBox>
                    <span>连续签到 赢惊喜大礼包</span>
                    <div className='sign-in-state'>
                        <div className={signInDay > 0 ? 'not-sign-in stateOK' : 'not-sign-in'}>
                            {
                                signInDay > 0 ? <img src={jiFens} alt="" />
                                    : <img src={jiFen} alt="" />
                            }
                            <span>+10</span>
                        </div>
                        <div className={signInDay > 1 ? 'not-sign-in stateOK' : 'not-sign-in'}>
                            {
                                signInDay > 1 ? <img src={jiFens} alt="" />
                                    : <img src={jiFen} alt="" />
                            }
                            <span>+10</span>
                        </div>
                        <div className={signInDay > 2 ? 'not-sign-in stateOK' : 'not-sign-in'}>
                            <img src={liWu} alt="" />
                            <span>+20</span>
                        </div>
                        <div className={signInDay > 3 ? 'not-sign-in stateOK' : 'not-sign-in'}>
                            {
                                signInDay > 3 ? <img src={jiFens} alt="" />
                                    : <img src={jiFen} alt="" />
                            }
                            <span>+10</span>
                        </div>
                        <div className={signInDay > 4 ? 'not-sign-in stateOK' : 'not-sign-in'}>
                            {
                                signInDay > 4 ? <img src={jiFens} alt="" />
                                    : <img src={jiFen} alt="" />
                            }
                            <span>+10</span>
                        </div>
                        <div className={signInDay > 5 ? 'not-sign-in stateOK' : 'not-sign-in'}>
                            {
                                signInDay > 5 ? <img src={jiFens} alt="" />
                                    : <img src={jiFen} alt="" />
                            }
                            <span>+10</span>
                        </div>
                        <div className={signInDay > 6 ? 'not-sign-in stateOK' : 'not-sign-in'}>
                            <img src={liWu} alt="" />
                            <span>+30</span>
                        </div>
                    </div>
                    {signInState ? <div className='sign-in-button disabled'>
                        已连续签到 {signInDay} 天
                    </div> : <div className='sign-in-button' onClick={() => { this.userSign(userPID) }}>
                        点击签到
                    </div>}
                </TopBox>
                <TaskTitle>
                    <div className='title'><div></div>做任务赚积分</div>
                    <Popover
                        placement="topRight"
                        title={'规则说明'}
                        content={content}
                        trigger="click"
                    >
                        <div className='illustrate'><i className='iconfont'>&#xe768;</i><span>规则说明</span></div>
                    </Popover>
                </TaskTitle>
                {
                    taskData.length !== 0 ? <TaskBox>
                        {taskData.teShuRenWu?.length !== undefined && <>
                            <div className='task-sub-title'>
                                专属积分
                            </div>
                            {
                                taskData.teShuRenWu.map((item) => (
                                    <div className='task' key={item.ID}>
                                        <div className='task-head-img'>
                                            <img src={item.Image} alt="" />
                                        </div>
                                        <div className='task-text'>
                                            <div className='task-name'>
                                                <span>
                                                    {item.Name}
                                                </span>
                                                <div className='task-jifen'>
                                                    +{item.Score}积分
                                                </div>
                                            </div>
                                            <div className='task-name-sub'>
                                                {item.Detail}
                                            </div>
                                        </div>
                                        {
                                            item.Sign == '1' ? <div
                                                className='task-button'
                                                onClick={() => {
                                                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '编辑个人信息', weburl: '/personal' }));
                                                    this.timeId = setInterval(() => {
                                                        if (localStorage.getItem('GO_BACK') == 'Refresh') {
                                                            this.getUserTask(userPID);
                                                            localStorage.setItem('GO_BACK', '');
                                                            clearInterval(this.timeId)
                                                        }
                                                    }, 1000);
                                                }}>
                                                去完成
                                            </div> : item.Sign == '2' ? <div className='task-button task-button-lingjiang' onClick={() => { this.userTaskScore(userPID, item.ID) }}>
                                                领取奖励
                                            </div> : <div className='task-button task-button-lingjiang-over'>
                                                领取奖励
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </>}
                        <div className='task-sub-title'>
                            更多积分
                        </div>
                        {
                            taskData.riChangRenWu.map((item) => (
                                <div className='task' key={item.ID}>
                                    <div className='task-head-img'>
                                        <img src={item.Image} alt="" />
                                    </div>
                                    <div className='task-text'>
                                        <div className='task-name'>
                                            <span>
                                                {item.Name}
                                            </span>
                                            <div className='task-jifen'>
                                                +{item.Score}积分
                                            </div>
                                        </div>
                                        <div className='task-name-sub'>
                                            {item.Detail}
                                        </div>
                                    </div>
                                    {
                                        item.Sign == '1' ? <div className='task-button' onClick={() => {
                                            if (item.Link == '' || item.Link == null) {
                                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "gohome" }));
                                            } else {
                                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: item.Link }));
                                            }
                                            localStorage.setItem('TASK_USER', userPID);
                                            localStorage.setItem('TASK_LINK', item.Link);
                                            localStorage.setItem('TASK_ID', item.ID);
                                            localStorage.setItem('TASK_TAG', item.Tag);
                                        }}>
                                            去完成
                                        </div> : item.Sign == '2' ? <div className='task-button task-button-lingjiang' onClick={() => { this.userTaskScore(userPID, item.ID) }}>
                                            领取奖励
                                        </div> : <div className='task-button task-button-lingjiang-over'>
                                            明天再来
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </TaskBox>
                        : <TaskBox>
                            <img src={jiFenKong} alt="" />
                        </TaskBox>
                }
            </CommunityWrapper>
        )
    }
}

export default SignIn