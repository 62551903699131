import styled from 'styled-components';

import {
  theme, themenew
} from '../../../../common/color';


export const Wrapper = styled.div`
  width:100%;
  background: #fff;
`
export const NewsPub = styled.div`
  width:100%;
  line-height:2rem;
  margin-top: 0.6rem;
  >span{
    display: inline-block;
    height: 1.125rem;
    line-height: 1.125rem;
    max-width: 9rem;
    margin-right:0.625rem;
    font-size:0.75rem;
    color:${themenew.subtext_color};
    vertical-align:middle;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
  }
  >div{
    float:right ;
    >i{
      font-size: 1.6rem;
      margin-left:1.5rem ;
    }
  }
`
export const NewsTitle = styled.div`
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size:1.125rem;
  line-height:1.7rem;
  color:${themenew.text_color};
`

export const NewsSubTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size:0.725rem;
  line-height:1.25rem;
  margin-top:0.5rem ;
  color:${themenew.subtext_color};
`

export const NewsLeft = styled.div`
  flex:1;
  width:50% ;
`
