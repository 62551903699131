import styled from 'styled-components';

export const Wrapper = styled.div`
  width:100%;
  margin:0 auto;
  background: #fff;
`
export const DIYDemoBlock = styled.div`
  width:100%;
  background: #FAFBFC;
  padding-top: 0.5rem;
  >span{
    display: block;
    height: 2.875rem;
    line-height: 2.875rem;
    color: #7C8698;
    width:92%;
    margin-left: 4%;
    font-size: 0.9rem;
  }
  >div{
    width:100%;
    background: #fff;
    //height: 3.375rem;
    padding: 0.85rem 4%;

  }
`
export const ImageUploader = styled.div`
  width:88px ;
  height: 88px;
  background-color: #F5F5F5;
  line-height: 88px;
  text-align: center;
  border-radius:5px ;
  margin-top: 0.6rem;
  margin-right: 0.75rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
  i{
    position: absolute;
    color: #999;
    font-size: 3rem;
    z-index: 11;
  }
  >div{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.2;
    z-index: 10;
  }
  >img{
    width: 100%;
    min-height:88px;
    object-fit: cover;   //对图片进行剪切
  }
`
export const ImageUploaderBox = styled.div`
  width:100%;
  display: flex;
  flex-wrap:wrap;
`

export const SaveButton = styled.div`
  //background-image: linear-gradient(135deg,#0060ec 0%,#B82cfc 100%);
  background-color: #015FEC;
  border-radius: 6px;
  height: 3.125rem;
  color: #fff;
  width: 88%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.875rem;
  line-height: 3.125rem;
  margin-top: 0.75rem;
  >span{
    font-size: 1.5rem;
    vertical-align: top;
    margin-right: 1rem;
  }
`

export const ARow = styled.div`
  display: flex;
  line-height: 22px;
  >span{
    display: block;
    width: 30%;
    font-size: 16px;
  }
  >div{
    width: 70%;
    font-size: 16px;
  }
`
export const Box = styled.div`
  width: 92%;
  margin-left: 4%;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 0.375rem;
  margin-top: 20px;
  margin-bottom: 30px;
  padding:8px 30px 30px;
  >span{
    color: #8A909A;
    font-size: 12px;
    margin-bottom: 28px;
    display: block;
  }
  >h1{
    font-size: 16px;
    text-align: center;
    margin-top: 38px;
  }
`
export const MoreWrapper = styled.div`
  padding: 20px 0;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  >span{
    display: block;
    font-size: 12px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    line-height: 16px;
    margin-bottom: 50px;
    >span{
      color: red;
    }
  }
`