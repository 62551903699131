import {Component} from "react"
import {withRouter} from 'react-router-dom';
import EventEmitter from '../utils/event'
import EventType from '../utils/eventType'
/**
 * created by zhangling on 2020/3/19
 */
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            EventEmitter.emit(EventType.routerChange, this.props.location.pathname)
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop)
