import React, {
    Component
} from 'react';
import {
    Wrapper,
    Title1,
    Auto,
    Auto1
} from './FileStyle';

class Privacy extends Component {
    render() {
        return (
            <Wrapper>
                <Auto1>
                    <p>北京中悦之声信息技术有限公司（简称“我们”）作为中乐之声的运营者，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定本“隐私政策”并特别提示：希望您在使用中乐之声及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。</p>
                </Auto1>
                <Auto1>
                    <p>下文将帮您详细了解我们如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；帮您了解查询、访问、删除、更正、撤回授权个人信息的方式。其中，有关您个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。<br />
                        1、我们如何收集和使用个人信息 <br />
                        2、我们如何共享、转让、公开披露个人信息 <br />
                        3、我们如何存储个人信息 <br />
                        4、我们如何保护个人信息的安全 <br />
                        5、管理您的个人信息 <br />
                        6、未成年人条款 <br />
                        7、隐私政策的修订和通知 <br />
                        8、联系我们 </p>
                </Auto1>
                <Title1>
                    1、我们如何收集和使用个人信息
                </Title1>
                <Auto1>
                    <p>我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：<br />
                        1.1注册、登录、<br />
                        当您注册、登录中乐之声及相关服务时，您可以通过手机号创建帐号，并且您可以完善相关的网络身份识别信息（头像、昵称、密码），收集这些信息是为了帮助您完成注册。您还可以根据自身需求选择填写性别、城市及个性签名来完善您的信息。<br />
                        1.2播放浏览 <br />
                        为保障内容的质量及相关信息，中乐之声可能会收集相关必要的日志信息。上述日志信息包括：<br />
                        您操作、使用的行为信息：点击、关注、收藏、搜索、浏览、分享<br />
                        您主动提供的信息：反馈、发布、点赞（喜欢）、评论<br />
                        1.3发布与互动<br />
                        1.3.1信息发布 <br />
                        a.您发布音视频、图片等内容或进行评论时，我们将收集您发布的信息，并展示您的昵称、头像、发布内容和信息。<br />
                        b.您使用发布图片、音视频功能时，我们会请求您授权相机、照片、麦克风权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用中乐之声的其他功能。<br />
                        c.您在开通直播等功能时，根据法律法规、安全保障要求，可能需要通过“人脸识别”的方式进行实名认证，以验证您的身份。在实名认证的过程中，我们和提供认证服务的第三方认证机构需要您提供真实姓名、身份证号码及面部特征值信息。部分信息是个人敏感信息，您可以拒绝提供，且不会影响其他功能的正常使用。这些信息仅供实名认证及法律法规所规定的用途，未经您明示授权，不会用作其他目的。<br />
                        d.用户因使用我们的产品或者服务而被我们收集的信息，例如其他用户发布的信息中可能含有您的部分信息（如：在评论、留言、发布图文、音视频中涉及到与您相关的信息）。<br />
                        1.3.2互动交流<br />
                        当您关注帐号、进行浏览、评论、关注（收藏）、喜欢（点赞）或分享时，我们会收集您关注的帐号和前述信息，并展示关注帐号发布内容。<br />
                        1.3.3消息通知 <br />
                        您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途，但请您放心，如您不愿接受这些信息，您可以直接与我们联系进行退订。<br />
                        1.4搜索 <br />
                        您使用中乐之声的搜索服务时，我们会收集您的搜索关键字信息、日志记录。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。<br />
                        1.5运营与安全运行 <br />
                        1.5.1运营与安全保障 <br />
                        我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。<br />
                        1.5.2设备信息与日志信息 <br />
                        a.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。<br />
                        b.为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。<br />
                        c.我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联方、合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。<br />
                        d.请注意：我们将使用友盟SDK进行统计分析，用以收集您的设备信息（IMEI/Mac/Android ID/IDFA/OPENUDID/GUID/SIM卡IMSI/地理位置等），这是友盟SDK的隐私权政策链接：https://www.umeng.com/page/policy，请您知悉并遵守。<br />
                        1.6分享与活动参与<br />
                        在您分享或接收被分享的信息、参加活动等情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。<br />
                        1.7统计分析<br />
                        为了分析应用新增、激活、留存、性能等统计性指标，我们可能会调用剪切板对相关统计信息进行归因分析，请您放心，我们不会收集您的隐私信息。<br />
                        1.8收集、使用个人信息目的变更<br />
                        请您了解，随着我们业务的发展，可能会对中乐之声的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。<br />
                        1.9依法豁免征得同意收集和使用的个人信息<br />
                        请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：<br />
                        a.与国家安全、国防安全直接相关的； <br />
                        b.与公共安全、公共卫生、重大公共利益直接相关的； <br />
                        c.与犯罪侦查、起诉、审判和判决执行等直接相关的； <br />
                        d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； <br />
                        e.所收集的您的个人信息是您自行向社会公众公开的；<br />
                        f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道； <br />
                        g.根据您的要求签订或履行合同所必需的； <br />
                        h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障； <br />
                        i.为合法的新闻报道所必需的； <br />
                        j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； <br />
                        k.法律法规规定的其他情形。 <br />
                        1.10自启动和关联启动说明<br />
                        为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启关联启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置 - 应用 - 应用启动管理 - 选择应用“中乐之声”- 关闭关联启动功能。<br />
                        当您打开本APP内部下载的文件后，会关联启动第三方App。<br />
                        特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
                    </p>
                </Auto1>
                <Title1>
                    2、我们如何共享、转让、公开披露个人信息
                </Title1>
                <Auto>
                    <p>2.1、共享<br />
                        2.1.1共享原则 <br />
                        a.授权同意原则：未经您的同意，我们不会共享您的个人信息，除非共享的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意。<br />
                        b.合法正当与最小必要原则：共享的数据必须具有合法正当目的，且共享的数据以达成目的必要为限。<br />
                        c.安全审慎原则：我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。<br />
                        2.1.2实现功能或服务的共享信息 <br />
                        当您在使用中乐之声中由我们的关联方、第三方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与我们联合为您提供服务时我们会将实现业务所必需的信息与这些关联方、第三方共享，用于综合统计。<br />
                        2.1.3实现广告相关的共享信息 <br />
                        a.广告推送与投放：我们可能与进行推广和广告投放的合作伙伴共享信息，但我们不会共享用于识别您个人身份的信息（姓名、身份证号），仅会向这些合作伙伴提供不能识别您个人身份的间接画像标签及去标识化的设备信息或匿名化后的设备、网络、渠道等信息，以帮助其在不识别您个人身份的前提下提升广告有效触达率。<br />
                        b.广告统计：我们可能与业务的服务商、供应商和其他合作伙伴共享分析去标识化的设备信息或统计信息，这些信息难以或无法与您的真实身份相关联。这些信息将帮助我们分析、衡量广告和相关服务的有效性。<br />
                        2.1.4实现安全与分析统计的共享信息 <br />
                        a.保障使用安全：我们非常重视帐号、服务及内容安全，为保障您和其他用户的帐号与财产安全，使您和我们的正当合法权益免受不法侵害，我们和关联方或服务提供商可能会共享必要的设备、帐号及日志信息。<br />
                        b.分析产品使用情况：为分析我们服务的使用情况，提升用户使用的体验，可能会与关联方或第三方共享产品使用情况（崩溃、闪退）的统计性数据，这些数据难以与其他信息结合识别您的个人身份。<br />
                        c.学术研究与科研：为提升相关领域的科研能力，促进科技发展水平，我们在确保数据安全与目的正当的前提下，可能会与科研院所、高校等机构共享去标识化或匿名化的数据。<br />
                        2.2、转让<br />
                        a.我们不会转让您的个人信息给任何其他第三方，除非征得您的明确同意。 <br />
                        b.随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。 <br />
                        2.3、公开展示 <br />
                        a.我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。<br />
                        b.对违规帐号、欺诈行为进行处罚公告时，我们会披露相关帐号的信息。<br />
                        c.当您授权游戏或小程序后，您和朋友的头像、昵称将会在排名及相关功能中展示。<br />
                        d.您公开发布的作品、动态、喜欢（点赞）将会在个人主页中展示。<br />
                        2.4、依法豁免征得同意共享、转让、公开披露的个人信息<br />
                        请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意：<br />
                        a.与国家安全、国防安全直接相关的；<br />
                        b.与公共安全、公共卫生、重大公共利益直接相关的；<br />
                        c.与犯罪侦查、起诉、审判和判决执行等直接相关的；<br />
                        d.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />
                        e.您自行向社会公众公开的个人信息；<br />
                        f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
                    </p>
                </Auto>
                <Title1>
                    3、我们如何存储个人信息
                </Title1>
                <Auto>
                    <p>3.1、存储地点<br />
                        我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。<br />
                        3.2、存储期限<br />
                        我们仅在为提供中乐之声及服务之目的所必需的期间内保留您的个人信息：您发布的信息、评论、喜欢（点赞）及相关信息，在您未撤回、删除或未注销帐号期间，我们会保留相关信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
                    </p>
                </Auto>
                <Title1>
                    4、我们如何保护个人信息的安全
                </Title1>
                <Auto>
                    <p>a.我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或在未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。<br />
                        b.我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。<br />
                        c.我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。<br />
                        d.尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。<br />
                        e.您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人等。<br />
                        f.我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。<br />
                        g.您一旦离开中乐之声及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在中乐之声及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于中乐之声的链接或引导。
                    </p>
                </Auto>
                <Title1>
                    5、管理您的个人信息
                </Title1>
                <Auto>
                    <p>我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、注销帐号以及投诉举报相关权利，以使您有能力保障您的隐私和信息安全。<br />
                        5.1、改变或撤回授权范围<br />
                        您可以在设备本身操作系统中关闭精确地理位置信息、通讯录、摄像头、麦克风、相册权限、日历权限，改变同意范围或撤回您的授权。撤回授权后我们将不再收集与这些权限相关信息。<br />
                        5.2、改变或撤回授权的信息处理<br />
                        特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。<br />
                        5.3、访问、删除、更正您的个人信息<br />
                        5.3.1访问个人帐号信息<br />
                        您可以查询、访问您的头像、昵称、个人签名、性别、城市，您可以在【个人中心】-【设置昵称】进行查询、访问、更正。<br />
                        5.3.2查询访问、更正、删除信息<br />
                        a.进入【个人中心】-【我的发布】中访问、删除您发布的音视频信息<br />
                        b.进入【个人中心】-【点赞】中访问、删除您点赞（喜欢）的信息<br />
                        c.进入【个人中心】-【关注】可以访问、查询关注的帐号信息，在关注列表点击相应帐号后的“已关注”按钮（如有）或进入相应关注帐号主页可以选择取消关注该帐号<br />
                        d.进入【个人中心】-【设置】（右上角“齿轮”状图标）-【登录密码】设置、更正密码<br />
                        e.进入【个人中心】-【设置】（右上角“齿轮”状图标）-【关于中乐之声】-【检测新版本】更新版本软件<br />
                        f.进入【个人中心】-【设置】（右上角“齿轮”状图标）-【账户注销】-【填写注销原因并勾选“我已阅读并同意《账户注销协议》”】-【申请注销】待管理员后台审核通过之后，账户即被注销<br />
                        5.4、访问隐私政策<br />
                        a.您可以在注册页面查看本隐私政策的全部内容。<br />
                        b.请您了解，本隐私政策中所述的中乐之声及相关服务可能会根据您所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的中乐之声软件及相关服务为准。<br />
                        5.5、停止运营向您告知<br />
                        如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
                    </p>
                </Auto>
                <Title1>
                    6、未成年人条款
                </Title1>
                <Auto>
                    <p>6.1、未成年人通用条款<br />
                        a.若您是未满18周岁的未成年人，在使用中乐之声及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。<br />
                        b.我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。<br />
                        c.若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
                    </p>
                </Auto>
                <Title1>
                    7、隐私政策的修订和通知
                </Title1>
                <Auto>
                    <p>a.为了给您提供更好的服务，中乐之声及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。<br />
                        b.本隐私政策更新后，我们会在中乐之声发出更新版本，并在更新后的条款生效前通过官方网站公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。
                    </p>
                </Auto>
                <Title1>
                    8、第三方数据共享清单
                </Title1>
                <Auto>
                    <p>a.第三方SDK目录<br />
                    为适配在不同手机设备上使用中乐之声APP及第三方平台服务，实现您的设备接收PUSH推送、数据统计等功能，中乐之声APP接入了第三方SDK。<br />
                    我们对接入的相关第三方SDK在本目录中列明。我们会对努力保障您的信息安全，尽可能及时更新并向您说明中乐之声APP所接入第三方的SDK的最新情况。但请您注意，第三方SDK可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。<br /><br />
                    <b>安卓系统第三方SDK列表</b><br />
                    新浪微博<br />
                    所属公司：北京微梦创科网络技术有限公司<br />
                    使用目的：微博登录和分享<br />
                    收集数据：设备识别符、MAC地址，网络状态、网络类型、网络信息、手机基本信息<br />
                    <a href='https://open.weibo.com/wiki/Sdk/privacy'>点击查看第三方隐私政策</a><br /><br />
                    腾讯QQ互联<br />
                    所属公司：腾讯科技（深圳）有限公司<br />
                    使用目的：QQ登录和分享<br />
                    收集数据：设备标识符、获取任务信息、读取手机状态、读/写入外部存储、访问网络、网络信息、检索正在运行的应用<br />
                    <a href='https://wiki.connect.qq.com/qq%E4%BA%92%E8%81%94sdk%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E'>点击查看第三方隐私政策</a><br /><br />
                    微信开放平台SDK<br />
                    所属公司：深圳市腾讯计算机系统有限公司<br />
                    使用目的：微信登录和分享功能<br />
                    收集数据：用户相册图片和视频、微信头像、泥称、地区、语言、性别信息、软件安装列表、设备型号、MAC地址、设备参数和系统信息、网络信息、设备标识信息、位置信息<br />
                    <a href='https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8'>点击查看第三方隐私政策</a><br /><br />
                    极光推送<br />
                    所属公司：深圳市和讯华谷信息技术有限公司<br />
                    使用目的：消息推送<br />
                    收集数据：设备参数及系统信息、设备识别符、网络信息与位置信息、APP应用列表及活跃状态信息<br />
                    <a href='https://www.jiguang.cn/license/privacy'>点击查看第三方隐私政策</a><br /><br />
                    华为推送<br />
                    所属公司：华为技术有限公司<br />
                    使用目的：消息推送<br />
                    收集数据：设备识别符、应用Token，Topic订阅关系、缓存消息内容、消息下发记录、Token申请记录、显示/点击/关闭打点上报记录<br />
                    <a href='https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177'>点击查看第三方隐私政策</a><br /><br />
                    小米推送<br />
                    所属公司：小米移动软件有限公司<br />
                    使用目的：消息推送<br />
                    收集数据；设备识别符、设备参数、网络信息、MAC地址、任务列表、设备参数和系统信息、网络设备制造商<br />
                    <a href='https://dev.mi.com/console/doc/detail?pId=1822'>点击查看第三方隐私政策</a><br /><br />
                    oppo推送<br />
                    所属公司：广东欢太科技有限公司<br />
                    使用目的：消息推送<br />
                    收集数据：设备识别符、手机Region设置，设备型号，手机电量，手机操作系统版本及语言<br />
                    <a href='https://open.oppomobile.com/new/developmentDoc/info?id=10288'>点击查看第三方隐私政策</a><br /><br />
                    VIVO推送<br />
                    所属公司：维沃移动通信有限公司<br />
                    使用目的：消息推送<br />
                    收集数据；设备识别符、设备参数、网络信息、MAC地址、任务列表、设备参数和系统信息、网络设备制造商<br />
                    <a href='https://dev.vivo.com.cn/documentCenter/doc/366#w1-08512613'>点击查看第三方隐私政策</a><br /><br />
                    魅族推送<br />
                    所属公司：珠海市魅族通讯设备有限公司<br />
                    使用目的：消息推送<br />
                    收集数据：设备识别符，WiFi扫描列表，网络信息，软件安装列表，设备基本信息、推送到达信息、推送点击信息<br />
                    <a href='http://static.meizu.com/resources/term/privacy8.html'>点击查看第三方隐私政策</a><br /><br />
                    淘宝Agoo推送<br />
                    所属公司：浙江淘宝网络有限公司、淘宝（中国）软件有限公司<br />
                    使用目的：用于向用户推送消息<br />
                    收集数据：设备信息（IMSI、IMEI、MEID、运行中应用列表）<br />
                    <a href='https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao201703241622_61002.html?spm=a21bo.2017.1997523009.39.319b11d9wOV0o3'>点击查看第三方隐私政策</a><br /><br />
                    阿里push/阿里推送<br />
                    所属公司：阿里云计算有限公司<br />
                    收集用途/目的：提供推送信息服务<br />
                    收集个人信息类型：常用设备信息（如设备Mac地址、唯一设备IMEI/Android ID/device ID/GUID、SIM卡 IMSI信息等）；个人位置信息（GPS定位）；运⾏中进程信息<br />
                    可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、保持唤醒<br />
                    <a href='https://docs.jiguang.cn/jpush/guideline/intro/'>点击查看第三方隐私政策</a><br /><br />
                    阿里OSS<br />
                    所属公司：阿里云计算有限公司<br />
                    收集用途/目的：提供云储存服务<br />
                    收集个人信息类型：常用设备信息（如设备Mac地址、唯一设备IMEI/Android ID/device ID/GUID、SIM卡 IMSI信息等、运⾏中进程信息）<br />
                    可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、保持唤醒、内存卡写入、内存卡读取、访问外置内存卡、访问网络状态<br />
                    <a href='https://help.aliyun.com/document_detail/30052.html?spm=a2c4g.11186623.6.675.50a73a5aEFO3DI'>点击查看第三方隐私政策</a><br /><br />
                    阿里云mPaaS<br />
                    所属公司：阿里云计算有限公司<br />
                    收集用途/目的：阿里云mPaaS使用移动开发平台<br />
                    收集个人信息类型：常用设备信息（如设备Mac地址、唯一设备IMEI/Android ID/device ID/GUID、SIM卡 IMSI信息等、运⾏中进程信息）<br />
                    可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、保持唤醒、内存卡写入、内存卡读取、访问外置内存卡、访问网络状态<br />
                    <a href='http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202107091605_49213.html'>点击查看第三方隐私政策</a><br /><br />
                    React-native<br />
                    所属公司：Facebook<br />
                    使用目的：使用JavaScript语言实现跨平台移动应用开发<br />
                    收集数据：网络状态、设备识别符，Mac地址、网络地址、网络信息、运营商信息、设备参数和系统信息、网络状态、App版本信息、定位、粘贴板<br />
                    <a href='https://opensource.fb.com/legal/privacy/'>点击查看第三方隐私政策</a><br /><br />
                    友盟数据分析<br />
                    所属公司：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司<br />
                    使用目的：用户使用效果分析，并通过地理位置校准报表数据准确性，提供基础反作弊能力<br />
                    收集数据：网络状态、设备识别符、网络信息、任务列表、设备参数和系统信息<br />
                    <a href='https://www.umeng.com/policy'>点击查看第三方隐私政策</a><br /><br />
                    百度统计<br />
                    所属公司：北京百度网讯科技有限公司<br />
                    收集用途/目的：提供统计分析<br />
                    收集个人信息类型：设备信息、日志信息、位置信息、应用程序列表信息<br />
                    可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、内存卡写入、内存卡读取、访问外置内存卡、访问网络状态<br />
                    <a href='https://tongji.baidu.com/web/help/article?id=330&type=0'>点击查看第三方隐私政策</a><br /><br />
                    移动一键登录<br />
                    所属公司：中移互联网有限公司<br />
                    使用场景：在用户使用移动手机号一键登录时使用<br />
                    收集个人信息类型：设备标识符 (如IDFV) 、WLAN接入点(如SSID、BSSID) 、位置信息 (如GPS) 、IP地址网络信息、设备MAC地址、软件列表、手机号、设备信息<br />
                    <a href='https://wap.cmpassport.com/resources/html/contract.html'>点击查看第三方隐私政策</a><br /><br />
                    联通一键登录<br />
                    所属公司：中国联合网络通信有限公司<br />
                    使用场景：在用户使用联通手机号一键登录时使用<br />
                    收集个人信息类型：WLAN接入点 (如SSID)、IP地址、手机号、网络信息、设备信息<br />
                    <a href='https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true'>点击查看第三方隐私政策</a><br /><br />
                    电信一键登录<br />
                    所属公司：世纪龙信息网络有限责任公司<br />
                    使用场景：在用户使用电信手机号一键登录时使用<br />
                    收集个人信息类型：手机号、IP地址、网络信息、设备信息<br />
                    <a href='https://e.189.cn/sdk/agreement/detail.do'>点击查看第三方隐私政策</a><br /><br />
                    号码认证<br />
                    所属公司：网易公司<br />
                    收集用途/目的：号码认证<br />
                    使用场景：实现电话号码一键认证注册、登录<br />
                    收集个人信息类型：网络类型、Wi-Fi 类型、IMSI（仅 Android）<br />
                    <a href='https://dun.163.com/clause/privacy'>点击查看第三方隐私政策</a><br /><br />
                    网易易盾号码认证<br />
                    所属公司：网易公司<br />
                    收集用途/目的：号码认证<br />
                    使用场景：实现电话号码一键认证注册、登录<br />
                    收集个人信息类型：网络类型、Wi-Fi 类型、IMSI（仅 Android）<br />
                    <a href='https://dun.163.com/clause/privacy'>点击查看第三方隐私政策</a><br /><br />
                    
                    <b>iOS系统第三方SDK列表</b><br />
                    新浪微博<br />
                    所属公司：北京微梦创科网络技术有限公司<br />
                    使用目的：微博登录和分享<br />
                    收集数据：设备识别符、MAC地址，网络状态、网络类型、网络信息、手机基本信息<br />
                    <a href='https://open.weibo.com/wiki/Sdk/privacy'>点击查看第三方隐私政策</a><br /><br />
                    腾讯QQ互联<br />
                    所属公司：腾讯科技（深圳）有限公司<br />
                    使用目的：QQ登录和分享<br />
                    收集数据：设备标识符、获取任务信息、读取手机状态、读/写入外部存储、访问网络、网络信息、检索正在运行的应用<br />
                    <a href='https://wiki.connect.qq.com/qq%E4%BA%92%E8%81%94sdk%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E'>点击查看第三方隐私政策</a><br /><br />
                    微信开放平台SDK<br />
                    所属公司：深圳市腾讯计算机系统有限公司<br />
                    使用目的：微信登录和分享功能<br />
                    收集数据：用户相册图片和视频、微信头像、泥称、地区、语言、性别信息、软件安装列表、设备型号、MAC地址、设备参数和系统信息、网络信息、设备标识信息、位置信息<br />
                    <a href='https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8'>点击查看第三方隐私政策</a><br /><br />
                    极光推送<br />
                    所属公司：深圳市和讯华谷信息技术有限公司<br />
                    使用目的：消息推送<br />
                    收集数据：设备参数及系统信息、设备识别符、网络信息与位置信息、APP应用列表及活跃状态信息<br />
                    <a href='https://www.jiguang.cn/license/privacy'>点击查看第三方隐私政策</a><br /><br />
                    React-native<br />
                    所属公司：Facebook<br />
                    使用目的：使用JavaScript语言实现跨平台移动应用开发<br />
                    收集数据：网络状态、设备识别符，Mac地址、网络地址、网络信息、运营商信息、设备参数和系统信息、网络状态、App版本信息、定位、粘贴板<br />
                    <a href='https://opensource.fb.com/legal/privacy/'>点击查看第三方隐私政策</a><br /><br />
                    b.中乐之声与第三方共享数据列表<br />
                    为了向您提供更完善、优质的产品和服务，我们可能委托授权合作伙伴为您提供服务或代表我们履行职能，向其共享部分用户信息。对共享信息的合作方，我们会与其订立专门的数据保护协议或数据安全条款，约定严格的数据保护措施，在确保安全的前提下共享信息。<br />
                    功能类型：平台基础服务<br />
                    涉及第三方：包括一键登录、消息推送等合作方<br />
                    共享目的：实现实名认证、一键登录、消息推送等功能所需，详见第一部分《第三方SDK目录》<br />
                    共享的个人信息；基本通过SDK的方式实现，详见第一部分《第三方SDK目录》<br />
                    功能类型：数据服务<br />
                    涉及第三方；数据统计、数据分析的合作方<br />
                    共享目的：数据统计所需<br />
                    共享的个人信息：设备信息、终端型号、地理位置，详见第一部分《第三方SDK目录》<br />
                    功能类型：内容安全监测服务<br />
                    涉及第三方：安全监测合作方<br />
                    共享目的：违规文本、音视频标注和分析<br />
                    共享的个人信息：直播流信息
                    </p>
                </Auto>
                <Title1>
                    9、联系我们
                </Title1>
                <Auto>
                    <p>如果您对个人信息保护问题有投诉、建议、疑问，您可以联系我们，我们会及时处理并回复。以下是我们的联系方式：<br />
                        公司名称：北京中悦之声信息技术有限公司<br />
                        注册地址：北京市丰台区造甲街110号院28幢A1-209<br />
                        常用办公地址：北京市房山区翠枫路7号院超级蜂巢8号楼1001<br />
                        信息保护负责人联系电话：孙晨尧 400-8020201
                    </p>
                </Auto>
                <Title1>
                    更新日期：2022年6月13日
                </Title1>
            </Wrapper>
        )
    }
}

export default Privacy