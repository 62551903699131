/**
 * created by sunchenyao on 2021/7/29
 */
import React, {
    Component
} from 'react';
import {
    SettingsWrapper,
} from '../../../../common/generalStyle';
import { List, Switch } from 'antd-mobile-v2';
import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";

const returnButton = require('../../../../images/return_button.svg');
const Item = List.Item;

export default class MessageSettings extends Component {
    state = {
        userPID: "",
        replyChecked: false,
        likeChecked: false,
        messageChecked: false,
        pushChecked: false
    };
    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.setMessage(null, null, null, null);
        };
    }
    //获取对我回复的统计数量
    setMessage = async (reply, like, message, push) => {
        const {
            userPID,
        } = this.state;
        const res = await httpAxios(api.setMessage, {
            UserID: userPID,
            ShowComment: reply,
            ShowLike: like,
            ShowMessage: message,
            ShowPush: push
        })
        if (res && res.Data) {
            this.setState({
                replyChecked: res.Data.ShowComment,
                likeChecked: res.Data.ShowLike,
                messageChecked: res.Data.ShowMessage,
                pushChecked: res.Data.ShowPush,
            });
        }
    }

    render() {
        const {
            replyChecked,
            likeChecked,
            messageChecked,
            pushChecked
        } = this.state;
        return <SettingsWrapper>
            <List renderHeader={() => '是否关闭消息通知'}>
                <Item
                    className='listheight'
                    extra={<Switch
                        checked={replyChecked}
                        onChange={async () => {
                            this.setState({
                                replyChecked: !replyChecked,
                            });
                            await this.setMessage(!replyChecked, null, null);
                        }}
                    />}
                >关闭评论消息</Item>
                <Item
                    className='listheight'
                    extra={<Switch
                        checked={likeChecked}
                        onChange={async () => {
                            this.setState({
                                likeChecked: !likeChecked,
                            });
                            await this.setMessage(null, !likeChecked, null);
                        }}
                    />}
                >关闭点赞消息</Item>
                <Item
                    className='listheight'
                    extra={<Switch
                        checked={messageChecked}
                        onChange={async () => {
                            this.setState({
                                messageChecked: !messageChecked,
                            });
                            await this.setMessage(null, null, !messageChecked);
                        }}
                    />}
                >关闭系统消息</Item>
                <Item
                    className='listheight'
                    extra={<Switch
                        checked={pushChecked}
                        onChange={async () => {
                            this.setState({
                                pushChecked: !pushChecked,
                            });
                            await this.setMessage(null, null, null, !pushChecked);

                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mypush", data: !pushChecked }));
                        }}
                    />}
                >关闭推送消息</Item>
            </List>
        </SettingsWrapper>
    }
}