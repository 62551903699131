import React, { Component } from 'react';
import {
    Tabs
} from './LabelStyle';

class Label extends Component {

    state = {
        activeKey: '1'
    }
    handleClick = (id) => {
        this.setState({
            activeKey: id
        })
        this.props.cb && this.props.cb(id, null, null);
        //返回顶部
        //window.scrollTo( 0, 0 );
    }
    render() {
        return (
            <Tabs>
                <div className={this.state.activeKey === '1' ? 'selected' : ''} onClick={this.handleClick.bind(this, '1')}>
                    <span>艺培邀约</span>
                    {
                        this.state.activeKey === '1' ? <div /> : ''
                    }
                </div>
                {/* <div className={this.state.activeKey === '2' ? 'selected' : ''} onClick={this.handleClick.bind(this, '2')}>
                    <span>艺人邀约</span>
                    {
                        this.state.activeKey === '2' ? <div /> : ''
                    }
                </div> */}
            </Tabs>
        )
    }
}

export default Label;
