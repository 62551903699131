import React, {
    Component
} from 'react';
import {
    Wrapper,
    Auto,
    Title1
} from './FileStyle';

class XunjiaFile extends Component {
    render() {
        return (
            <Wrapper>
                <Auto>
                    <p>
                        版本更新时间： 2023年9月1日<br />
                        版本发布时间： 2023年9月1日
                    </p>
                </Auto>
                <Auto>
                    <p>保护用户个人信息是北京中悦之声信息技术有限公司（简称“我们”）的一项基本原则。我们将按照《隐私政策-询价功能》 (以下简称“本政策”)提示的询价功能及《我们隐私政策》(链接地址： https：//www.51audio.com/file)的规定收集、使用、储存和分享您的个人信息。本政策未作明确规定的，以《中乐之声隐私政策》的内容为准。若对个人信息保护规定的内容与上述《中乐之声隐私政策》相冲突的，以本政策为准。</p>
                </Auto>
                <Title1>
                    目录
                </Title1>
                <Auto>
                    <p>
                        一、本政策的适用范围<br />
                        二、我们如何收集和使用您的个人信息<br />
                        三、我们如何使用Cookie和同类技术<br />
                        四、我们如何分享、使用、转让、公开披露您的个人信息<br />
                        五、我们如何存储个人信息<br />
                        六、我们如何保护个人信息的安全<br />
                        七、个人信息的管理<br />
                        八、未成年人个人信息保护<br />
                        九、修订和通知<br />
                        十、联系我们<br />
                        十一、附则
                    </p>
                </Auto>
                <Title1>
                    一、本政策的适用范围
                </Title1>
                <Auto>
                    <p>适用于我们以网站、App、小程序以及随技术发展出现的新形态产品向您提供的价格咨询服务(或称“询价服务”) 。如我们及关联公司的产品或服务中使用了询价服务但未设独立隐私政策的，则本政策同样适用于该部分服务。</p>
                    <p>除本政策说明的相关信息收集使用活动外，本政策不适用于其他第三方向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。</p>
                </Auto>
                <Title1>
                    二、我们如何收集和使用您的个人信息
                </Title1>
                <Auto>
                    <p>向您提供询价服务是我们的基本业务功能，为了向您选择的经销商、销售顾问询问音频产品价格，以便为您提供更好的产品、服务，优化并丰富您的用户体验，我们将且仅收集与为您提供询价相关的产品、服务有关的信息。</p>
                    <p>1.您使用获取/咨询底价服务时，为便于了解您的需求、与您联系并为您匹配符合您需求的经销商，我们将收集：<br />
                        （1）您的称呼；<br />
                        （2）手机号码；<br />
                        （3）您在网站的表格上输入的其他信息 (所在城市、自定义输入内容)；<br />
                        （4）您在与我们沟通过程中主动提供的其他信息。请您注意： 我们会在与您沟通并获取您的单独同意后，向经销商提供您的手机号用于联系您。
                    </p>
                    <p>2.您向经销商询价时，为了与您进行联系并了解您的需求，我们将收集：<br />
                        （1）您的称呼；<br />
                        （2）手机号码；<br />
                        （3）您在网站的表格上输入的其他信息 (所在城市)。
                    </p>
                    <p>3.您向销售顾问询价时，为了与您进行联系并了解您的需求，我们将收集：<br />
                        （1）您的称呼；<br />
                        （2）手机号码；<br />
                        （3）您在与顾问沟通过程中主动提供的其他信息
                    </p>
                    <p>4.为了提供新的优惠促销和营销信息、进行服务回访，我们将收集您的手机号码，以便通过电话或短信的方式与部分用户进行服务回访或向您告知信息和通知，包括但不限于关于我们的资讯、新产品和/或服务、音响优惠促销信息或其他您可能感兴趣的内容。对于优惠促销、营销电话和短信我们仅会在征得您的同意后再开展。您同意本政策即代表您已同意我们向您拨打营销电话或发送短信。</p>
                    <p>
                        5.请您注意：您向我们提供手机号，即代表您同意在您和经销商成交后，我们可获取经销商经您授权后向我们提供相关的资料 (包括订单信息和经过去标识化后的手机号码)，以便用于验证线索转化率。<br />
                        以上个人信息均是您自愿提供。您有权拒绝提供，但如果您拒绝提供某些个人信息，您将可能无法使用我们提供的产品、服务，或者可能对您使用产品、服务造成一定的影响。
                    </p>
                    <p>
                        6.对于不满14岁的用户，您需要让您的法定监护人阅读本政策，并且同意向我们提供您的个人信息。<br />
                        请您知悉： 您同意本政策仅代表您已了解询价功能，以及询价功能运行所需的必要个人信息，并不代表您已同意我们可以收集非必要个人信息，非必要个人信息会根据您使用过程中的授权情况单独征求您的同意。
                    </p>
                </Auto>
                <Title1>
                    三、我们如何使用Cookie等同类技术
                </Title1>
                <Auto>
                    <p>
                        1.为确保网站正常运转、为您获得更轻松的访问体验、并向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为Cookie的数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。我们向您发送Cookie是为了简化您重复登录的步骤，帮助判断您的登录状态以及账户或数据安全。
                    </p>
                    <p>
                        2.我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则每一次访问我们网站或应用时需要另行设置，而且您之前所记录的相应信息可能也会被删除，或者对您所使用服务的安全产生一定影响。
                    </p>
                </Auto>
                <Title1>
                    四、我们如何分享、使用、转让、公开披露您的个人信息
                </Title1>
                <Auto>
                    <p>
                        1、共享
                    </p>
                    <p>为了向您提供更完善、更优质的产品和服务，除非存在以下一种或多种情形，否则我们不会与任何第三方公司、组织和个人共享您的个人信息：<br />
                        （1）基于您自行提出的要求、或事先获得您的明示授权或同意；<br />
                        （2）为订立、履行您作为一方当事人的合同所必需；<br />
                        （3）我们履行法定职责或法定义务所必需；<br />
                        （4）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br />
                        （5）在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；<br />
                        （6）关联公司间共享；<br />
                        便于我们基于我们账号共同向您提供服务，或者向您推荐可能感兴趣的信息、保护提供我们关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会在我们的关联公司间进行共享。我们只会共享您必要的个人信息，且这种共享受本政策所声明目的的约束。一旦我们共享您的个人信息或关联公司改变个人信息的处理目的，我们将会再次征求您的授权同意。<br />
                        （7）与相关音响经销商、厂商及集团合作伙伴间共享：<br />
                        为了与您沟通您咨询的产品、定期或不定期地向您发送有关产品、服务或相关活动的信息以及向您提供更完善、优质的产品和服务，我们可能会与相关音响经销商、厂商及集团共享您的个人信息 (我们会在与您沟通确认需求的过程中向您告知具体经销商名称和联系方式以及共享的信息类型，您也可以在产品页面中进行查看) 。我们仅会出于合法正当、必要、特定、明确的目的共享您的个人信息，并且只会共享为您提供服务所必要的个人信息。<br />
                        请您知悉：该等共享的合作方为数据控制者，以其自己的名义获得您的同意以处理您的个人信息合作方可能有其独立的隐私政策和用户协议，我们建议您认真阅读并遵守合作方的用户协议及隐私政策。如果您拒绝我们的合作方在提供服务时收集为提供服务所必需的个人信息，将可能导致您无法在我们应用中享用该合作方提供的服务。请您注意： 您向我们提供手机号，即代表您同意在您和经销商成交后，我们可获取经销商向我们提供的验证线索转化率的资料 (包括订单信息和经过去标识化后的手机号码)。<br />
                    </p>
                    <p>
                        2、委托处理
                    </p>
                    <p>
                        为了向您提供更完善、更优质的询价服务，我们可能会委托技术服务合作方处理您的某些个人信息，这些公司只能将您的个人信息用于代表我们向您提供服务。对我们委托处理您个人信息的公司、组织和个人，我们会与其签署严格的保密协议，明确数据处理事项、处理期限、处理性质、处理目的以及双方责任等，要求合作方仅按照我们的要求、本政策的规定以及其他任何相关的保密和安全措施来处理您的个人信息。如果您拒绝我们的合作方在提供服务时收集为提供服务所必需的个人信息，将可能导致您在使用我们产品与/或服务的过程中无法享受该合作方提供的相应服务。
                    </p>
                    <p>
                        3、转让
                    </p>
                    <p>
                        原则上我们不会将您的个人信息控制权向其他公司、组织或个人进行转移。<br />
                        如因合并、分立、解散、被宣告破产等原因确需转移，我们将向您告知接收方的名称或者姓名和联系方式，并要求接收方继续履行个人信息处理者的义务。接收方变更原先的处理目的、处理方式的我们将要求其应当依照法律规定重新取得个人同意。<br />
                        请您知悉： 如具备上述事由确需转让，我们将在转让前向您告知转让信息的目的、类型、信息受让方(如涉及敏感信息，我们还将告知涉及的敏感信息的内容)，并在征得您同意后再转让，但法律法规另有规定的除外。
                    </p>
                    <p>
                        4、公开
                    </p>
                    <p>
                        我们不会公开您的个人信息，取得您单独同意的除外。
                    </p>
                </Auto>
                <Title1>
                    五、我们如何存储个人信息
                </Title1>
                <Auto>
                    <p>1.根据《网络安全法》的规定，我们会对网络日志信息至少保存6个月，对于您的其他个人信息，我们仅在为您提供本政策所述之目的所必需的最短期间内保留，法律法规另有规定或者用户另行授权同意的除外。超出上述存储期限后，我们将对您的个人信息进行删除或匿名化处理。</p>
                    <p>2.上述信息将存储于中华人民共和国境内。在本政策下所收集及处理的个人信息不存在出境的情况。</p>
                    <p>3.如我们因经营不善或其他原因出现停止运营的情况，除非法律法规或规范性文件另有规定，我们将会向您告知此类情况，并及时停止对您个人信息进行收集，同时删除已收集到的个人信息或对其做匿名化处理。</p>
                </Auto>
                <Title1>
                    六、我们如何保护个人信息的安全
                </Title1>
                <Auto>
                    <p>
                        1.数据安全技术措施<br />
                        （1）我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露、分享。我们将使用加密技术 (例如SSL) 、匿名化处理及相关合理可行的手段保护您的个人信息并使用安全保护机制防止您的个人信息遭到恶意攻击。<br />
                        （2）在数据访问和使用的安全控制方面，我们实施了严格的数据权限控制机制，采取多重身份认证技术，并对能够处理您的个人信息的行为进行监控，避免数据被违规访问和未授权使用。我们还对数据生命周期的全流程进行监控，防止您的个人信息遭遇未授权的访问、公开披露、使用、修改、人为或意外的损坏或丢失等。<br />
                        （3）但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。如因我们的重大过失导致您的信息被非授权访问、公开披露、篡改、或毁坏，而导致您的合法权益受损，我们将承担相应的法律责任。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。<br />
                    </p>
                    <p>
                        2、数据安全组织和管理措施<br />
                        （1）根据有关法规要求，我们任命了个人信息保护负责人和个人信息保护工作机构。我们还建立了相关的内控管理流程，以最小授权为原则，对可能接触到您个人信息的工作人员，严格设定信息访问权限，控制个人信息的知悉范围。<br />
                        （2）我们建立了数据安全使用的内部规范制度，保障对您个人信息的处理，在收集、传输、使用、存储、转移、销毁等环节均满足法律法规的安全要求。根据有关法规要求，对需要访问个人信息的工作人员，我们会要求其经过个人信息保护负责人或者其授权的管理人员审批，记录访问情况，并采取技术措施，避免违法访问、修改、复制、下载个人信息。<br />
                        （3）我们组织员工参加安全与隐私保护相关培训并要求其完成规定的考核，加强员工对于保护个人信息重要性的认知。<br />
                        （4）我们还采取了其他可行的安全组织和管理措施。
                    </p>
                    <p>
                        3.合作协议条款保证<br />
                        （1）在我们从第三方间接收集您的个人信息前，我们会明确以书面形式 (如合作协议、承诺书)要求该第三方在已经取得您明示同意后收集以及处理 (如共享等) 您的个人信息，并要求第三方对个人信息来源的合法性和合规性以书面协议的形式作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应的法律责任。<br />
                        （2）在我们向业务合作伙伴共享您的个人信息前，我们会严格要求合作伙伴承担信息保护义务与责任。为此，我们会在合作之前要求业务合作伙伴签署数据处理协议或在双方签署的合作协议中列明有关数据保护的条款。协议严格约定业务合作伙伴的用户信息保密义务，包括信息的保管、使用和流转等均应满足我们的管控要求，接受我们的审核监督和审计，一旦有任何违反，我们会要求对方承担相应的法律责任。
                    </p>
                </Auto>
                <Title1>
                    七、个人信息的管理
                </Title1>
                <Auto>
                    <p>
                        1.访问您的个人信息<br />
                        您有权随时查阅和访问您向我们提供的个人信息，具体方式为：<br />
                        （1）我们网站： 进入“https://www.51audio.com/personalsetting”页面;<br />
                        （2）我们App： 您可以通过【我的】-【头像】-【编辑资料】查看。请您知悉并同意，您对您的账号和密码信息负有保密义务。<br />
                        任何情况下，请小心妥善保管。
                    </p>
                    <p>
                        2.更正或删除您的个人信息<br />
                        我们鼓励您更新和修改您的信息以使其更准确有效。<br />
                        我们将尽一切可能采取适当的技术手段，保证您可以访问、修改或删除自己的注册信息或使用我们服务时提供的其他个人信息。如您无法访问、修改或删除您的个人信息，或您需要访问、修改或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为我们存在任何违反法律法规或本隐私政策的行为，您可以通过本政策【联系我们】中提供的方式联系我们。我们将在不超过【15】天或法律法规规定期限内完成身份验证和响应处理。
                    </p>
                    <p>
                        3.改变您授权同意的范围或撤回您的授权<br />
                        请您知悉，有些信息是使用我们询价服务所必需的，但大多数其他信息的提供是由您自己决定的。我们会采取合理可行的措施，确保未收集与询价服务无关的个人信息。若我们拟将信息用于其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。<br />
                        您可以通过以下方式改变您授权我们继续收集信息的范围，或撤回您此前的授权或变更授权范围：<br />
                        （1）删除您的个人信息，关闭特定功能<br />
                        （2）通过本政策【联系我们】中提供的方式联系我们
                    </p>
                    <p>
                        4.访问本政策<br />
                        中乐之声App：您可以在询价页面询价按钮下面或询价页面底部查看本政策，开始会话后表示您已同意本政策的全部内容，您也可以通过【我的】-【设置】-【关于我们】 -【隐私政策】查看完整版我们隐私政策。
                    </p>
                    <p>
                        5.获取您的个人信息副本<br />
                        您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您可通过拨打人工客服热线、在线客服，人工客服会对您进行个人信息校验，完成个人信息主体确认后，将个人信息副本通过中乐之声App在线客服提交给您。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供您的个人信息副本。
                    </p>
                    <p>
                        6.如果您不想接受我们给您发送的询价或促销信息，您随时可以通过以下方式取消：<br />
                        （1）您可以根据短信的内容提示，退订我们给您发送的手机短信。<br />
                        （2）您可以通过人工客服或者拨打个人信息保护负责人电话4008020201提出取消事项。
                    </p>
                    <p>
                        7.约束信息系统自动决策<br />
                        在询价功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害我们商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。
                    </p>
                </Auto>
                <Title1>
                    八、未成年人个人信息保护
                </Title1>
                <Auto>
                    <p>
                        我们非常重视对未成年人个人信息的保护。询价服务完全面向成人，因此我们不收集未成年人的个人信息。如果您是未满18周岁的未成年人，我们将不会依据本政策为您提供相应的服务，请您立即停止使用询价服务。如果发现无意收集了未成年人的个人信息，我们会于发现后的一定合理时间内设法尽快删除相关数据。
                    </p>
                </Auto>
                <Title1>
                    九、修订和通知
                </Title1>
                <Auto>
                    <p>
                        1、我们保留适时修订或更新本政策的条款的权利。对于本政策的任何修订或者更新构成本政策的组成部分。对于变更可能对您的权益造成影响的，我们会启动弹窗等方式发布公告通知，如您不同意本政策的修改，或对本政策修改、更新的内容有异议，您应当选择停止使用我们询价服务。未经您明确同意，我们不会削减您按照本政策所应享有的权利，但请您知悉，您账号注销之前、停止使用本服务之前的行为和活动仍受本政策的约束。
                    </p>
                    <p>
                        2、任何修改都会将您的满意度置于首位。我们鼓励您在每次使用我们询价服务时都查阅本政策。
                    </p>
                    <p>
                        3、若涉及重大、实质性变更，我们会依据具体情况，以显著的方式通知您。
                    </p>
                </Auto>
                <Title1>
                    十、联系我们
                </Title1>
                <Auto>
                    <p>
                        如果您对个人信息保护问题有投诉、建议、疑问，您可以联系我们，我们会及时处理并回复。以下是我们的联系方式：<br />
                        公司名称：北京中悦之声信息技术有限公司<br />
                        注册地址：北京市丰台区造甲街110号院28幢A1-209<br />
                        常用办公地址：北京市房山区翠枫路7号院超级蜂巢8号楼1001<br />
                        信息保护负责人联系电话：孙晨尧4008020201
                    </p>
                </Auto>
                <Title1>
                    十一、附则
                </Title1>
                <Auto>
                    <p>
                        1.本政策的解释及争议解决均应适用中华人民共和国法律。与本政策相关的任何纠纷，双方应经友好协商解决：若不能协商解决，您在此同意将争议提交至本司所在地人民法院。
                    </p>
                    <p>
                        2、如果有管辖权的任何法院裁定或判决本政策的任何条款无效，则该条款将从本政策中移除，但该条款的无效不影响本政策其余条款的效力。本政策的其余条款将继续执行。
                    </p>
                    <p>
                        3、本政策的标题仅为方便阅读而设计，不影响本政策任何条款的含义或解释。
                    </p>
                </Auto>
            </Wrapper >
        )
    }
}

export default XunjiaFile