import React, {
    Component
} from 'react';
import { List, InputItem } from 'antd-mobile-v2';
import {
    SettingsWrapper
} from './MycenterPortraitStyle';
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
import axios from 'axios'
import api from "../../../http/api"
import httpAxios from "../../../http/httpAxios"
import { Toast, Button } from 'antd-mobile-v2';

const history = creatHistory();//返回上一页这段代码

const returnButton = require('../../../images/return_button.svg')
const headerImg = require('../../../images/headimg.png')

class NickName extends Component {
    state = {
        PNickName: '',
        userPID: ''
    };

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getUserInfo(data.UserPID);
        }
        //await this.getUserInfo();
    }

    //获取用户信息
    getUserInfo = async (id) => {
        const res = await axios.post(api.getUserInfo.url, {
            //UserPID: sessionStorage.getItem("BUSS_CENTER_TO_BRAND_MANAGER_ID"),
            UserPID: id
        })
        if (res && res.data.Data) {
            this.setState({ PNickName: res.data.Data.PNickName });
        }
    }

    //保存信息
    onSubmit = (key, value) => {
        const {
            PNickName
        } = this.state;
        if (PNickName.length > 10) {
            Toast.info('昵称不能超过10个字');
            return
        };
        let dataTemp = {
            UserPID: this.state.userPID,
            PNickName: PNickName
        };
        dataTemp[key] = value;

        httpAxios(api.updateUserInfo, dataTemp).then(res => {
            if (res) {
                Toast.info('信息修改成功');
                this.userTeShuTaskSign("添加昵称")
            }
        })
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    goBackPage = () => {
        history.goBack();  //返回上一页这段代码
    }

    //用户特殊任务签到
    userTeShuTaskSign = async (taskTag) => {
        const res = await httpAxios(api.userTeShuTaskSign, {
            "userID": this.state.userPID,
            "tag": taskTag
        });
        if (res.Code == 200) {
            localStorage.setItem('GO_BACK', 'Refresh');
            localStorage.setItem('GO_BACK_NAME', 'Refresh');
        }
    }

    render() {
        return (
            <SettingsWrapper>
                {/* <NavBar>
                    <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                    <span>修改昵称</span>
                    <a onClick={() => {
                        this.onSubmit('PNickName', this.state.PNickName);
                    }}>保存</a>
                </NavBar>
                <div style={{ height: '5.5rem' }}></div> */}
                {/* NavBar的高度占位 */}

                <List renderHeader={() => ''}>
                    <InputItem
                        className='listheight'
                        placeholder="请输入昵称"
                        value={this.state.PNickName}
                        onChange={(value) => this.setState({ PNickName: value })}
                    >昵称</InputItem>
                </List>
                <Button type="primary" style={{ margin: '1rem 4%' }} onClick={() => {
                    this.onSubmit('WriteSB', this.state.WriteSB);
                }}>保存信息</Button>
            </SettingsWrapper>
        )
    }
}

export default NickName;