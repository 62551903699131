/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    DividingLine,
} from '../../common/style';
import {
    SearchWrapper,
    Lately,
    LatelyText,
} from './style';

import Label from "./Label";
import Item from './Item';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

const playSvg = require('../../images/play.svg');

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',
            value: "",
            searchList: localStorage.getItem('LATELY_SEARCH_VALUE') == null ? [] : localStorage.getItem('LATELY_SEARCH_VALUE').split(","),
            searching: false,

            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            hasMore: true,
            rowCount: 0,

            orderType: '2',

            // liveID: 1,
            // livedata: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        pageIndex = 0;

        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }
        window.searchInfo = function (keyWork) {
            if (keyWork != "") {
                that.setState({
                    value: keyWork
                });
            };
            that.setState({
                refreshing: true,
            });
            that.getData(keyWork);

        }

        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    //初始查询数据
    getData = async (value) => {
        let hasItem = this.state.searchList.filter(item => item == value);
        if (hasItem.length == 0) {
            let list = [value, ...this.state.searchList];
            localStorage.setItem('LATELY_SEARCH_VALUE', list.toString());
            // this.setState({ searchList: list });
        }
        this.setState({
            searchList: localStorage.getItem('LATELY_SEARCH_VALUE').split(",")
        });
        // this.getList(null, null, null);
    }

    getListRefresh = async (targetID, value, listType) => {
        window.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, null, null);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }
    //按历史查询
    searchDataOld = async (value) => {
        this.getList(null, value, null);
        this.setState({
            value: value,
        });
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchtitle", keyWork: value }));
    }

    getList = async (targetID, value, listType) => {
        // alert(targetID);
        // alert(value);
        if (!value) value = this.state.value;
        // if (!listType) listType = this.state.listType;
        if (!targetID) targetID = this.state.targetID;
        let params;
        params = {
            type: targetID,
            keyWork: value,
            order: this.state.orderType,
            pageIndex: pageIndex,
            pageSize: NUM_ROWS
        }

        const res = await httpAxios(api.search, params);
        // alert(JSON.stringify(res));
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            // alert(JSON.stringify(res.Data));
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                        targetID,
                        refreshing: false,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];
                    this.setState({
                        data: tempData,
                        targetID,
                    })
                }
            } else {
                this.setState({
                    data: [],
                    targetID,
                })
                this.state.hasMore = false;

            }

            // alert(this.state.data.length.toString() + ";" + this.state.rowCount.toString());

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };
    //清空历史记录
    clearSearchOld = () => {
        localStorage.removeItem('LATELY_SEARCH_VALUE');
        this.setState({ searchList: [] });
    }

    render() {
        const {
            data,
            comTypes,
            livedata,
            targetID,
            searching,
            searchList,
            value,
            userPID
        } = this.state;

        return (<SearchWrapper>
            {value != "" ?
                <>
                    <Label cb={this.getListRefresh} list={comTypes} />
                    <div style={{ height: '3rem' }}></div>
                    <PullToRefresh
                        onRefresh={async () => {
                            await sleep(1000)
                            await this.onRefresh();
                        }}
                    >
                        {
                            data.map((obj, indexKey) => (
                                <div key={indexKey}>
                                    <Item data={obj} value={value} userPID={userPID}></Item>
                                </div>
                            ))
                        }
                    </PullToRefresh>
                    <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
                </> :
                <>
                    <Lately>
                        <div><i className="iconfont">&#xe6f8;</i><span>历史搜索</span></div>
                        <a onClick={() => this.clearSearchOld()}>清空历史</a>
                    </Lately>
                    <LatelyText>
                        {
                            searchList.map(item => (
                                item != "" && <div onClick={() => this.searchDataOld(item)}>{item}</div>
                            ))
                        }
                    </LatelyText>
                    <Lately style={{ clear: 'both' }}><DividingLine /></Lately>
                    {/* 隐藏ListView不能去掉，不然ReactDOM高度会报错 */}

                </>

            }
        </SearchWrapper>);
    }
}

ReactDOM.render(<Search />, document.getElementById('root'));
export default Search