import React, {
	Component
} from 'react';
import Content from './ShopListContent';
import {
	CommunityWrapper,
} from './ShopListStyle';

class ShopList extends Component {
	render() {
		return (
			<CommunityWrapper>
				<Content userid={this.props.match.params.id}/>
			</CommunityWrapper>
		)
	}
}

export default ShopList