/**
 * created by SunChenyao on 2020/11/25
 */
import styled from 'styled-components';
import {
  themenew
} from '../../../common/color';

export const BoxWrapper = styled.div`
  width:90%;
  margin:0.625rem 5%;
  background-color:#ffffff;
  padding:1.25rem 5%;
  box-sizing:border-box;
  position: relative;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05); 
  span{
    font-size:0.875rem;
    color:${themenew.text_color};
  }
`
export const Info = styled.span`
  margin-left:0.5rem;
  color:${themenew.text_color};
  font-size:0.875rem;
  span{
    color:${themenew.link_color};
  }
`
export const UserCheckWrap = styled.div`
  margin-bottom:1.25rem;
`
export const Title = styled.div`
  margin-top:7.375rem;
  margin-left:5%;
  font-size:1.875rem;
  color:${themenew.text_color};
  div{
    margin-bottom:0.6rem;
  }
  span{
    display:block;
    font-size:0.75rem;
    color:${themenew.subtext_color};
  }
`