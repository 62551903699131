import React, { Component } from 'react';
import{
    Info,
    LoginWrapper,
    LoginButtons,
    FinishIcon,
    FinishTip,
}from '../login/LoginStyle';
const finishIcon = require('../../images/finish.svg');

export default class CancellationOK extends Component {
    goLogout = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "logout" }));
    }
    render() {
        return (
            <LoginWrapper>
                <FinishIcon><img src={finishIcon} alt="成功" /></FinishIcon>
                <FinishTip>注销申请提交成功<br/>账户已经被锁定</FinishTip>
                <Info>客服将在3天内完成审核</Info>
                <LoginButtons onClick={this.goLogout}>退出登陆</LoginButtons>
            </LoginWrapper>
        )
    }
}