import React, {
    Component
} from 'react';
import {
    NewMycenterPortraitWrapper,
    Title,
    Num,
    Fans,
    ListWrapper,
    NewNumWrapper,
    LabelOne,
    LabelTwo,
    LabelThree,
    NewTopWrapper,
} from './MycenterPortraitStyle';
import { Badge } from 'antd-mobile-v2';
import Collection from '../../../images/message.svg';

const jiFen = require('../../../images/jifen.png');


class MycenterPortrait extends Component {
    render() {
        const {
            info,
            ToMyTotalData
        } = this.props;
        return (
            <>
                <NewTopWrapper>
                    {/* {
                        info.EvaluationMan && <LabelThree>专业评测师</LabelThree>
                    }
                    {
                        info.MediaMan && <LabelOne>媒体人</LabelOne>
                    }
                    {
                        info.YinYueMan && <LabelTwo>音悦人</LabelTwo>
                    } */}
                    <div className='new-top-left'>
                        <div className='new-protrait'>
                            <img onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '编辑个人信息', weburl: '/personal' })); }} src={info.HeadImg + '?x-oss-process=image/resize,w_278,m_lfit'} alt="" />
                        </div>
                    </div>
                    <div className='new-top-center' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '编辑个人信息', weburl: '/personal' })); }}>
                        <div className='new-name'>{info.PNickName === "" ? <span>设置昵称</span> : info.PNickName}</div>
                        {info.WriteSB && <div className='new-message'>{info.WriteSB}</div>}
                    </div>
                    <div className='new-top-right'>
                        <div className='new-fix-item' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '设置', weburl: '/settings' })); }}>
                            <i className="iconfont">&#xe771;</i>
                        </div>
                        <div
                            className='qiandao-button'
                            onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/SignIn" })) }}
                        >
                            <img src={jiFen} alt="" />
                            {info.Sign ? '已签到' : '签到'}
                        </div>
                    </div>
                </NewTopWrapper>
                <NewNumWrapper>
                    <div className='fans' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的关注", weburl: "/Focus" + "?" + info.UserPID })) }}>
                        <div className='num'>{info.FollowVal || 0}</div>
                        <div className='title'>关注</div>
                    </div>
                    <div className='fans' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的粉丝", weburl: "/Fans" + "?" + info.UserPID })) }}>
                        <div className='num'>{info.FansVal || 0}</div>
                        <div className='title'>粉丝</div>
                    </div>
                    <div className='fans' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的点赞", weburl: "/MyLike" + "?" + info.UserPID })) }}>
                        <div className='num'>{info.ZanTotal || 0}</div>
                        <div className='title'>点赞</div>
                    </div>
                </NewNumWrapper>
                <NewMycenterPortraitWrapper style={{ height: (info.IsStarUser || info.IsBussUser) ? '22.35rem' : '18.625rem' }}>
                    <ListWrapper onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的发布", weburl: "/release" })) }}>
                        <i className="iconfont">&#xe86b;</i><span>我的发布</span><div><i className="iconfont">&#xe744;</i></div>
                    </ListWrapper>
                    <ListWrapper onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的评论", weburl: "/comment" + "?" + info.UserPID })) }}>
                        <i className="iconfont">&#xe82e;</i><span>我的评论</span><div><i className="iconfont">&#xe744;</i></div>
                    </ListWrapper>
                    <ListWrapper onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的收藏", weburl: "/collection" })) }}>
                        <i className="iconfont">&#xe839;</i><span>我的收藏</span><div><i className="iconfont">&#xe744;</i></div>
                    </ListWrapper>
                    <ListWrapper onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessage", title: "我的消息", buttonTitle: "消息设置", weburl: "/message" })) }}>
                        <i className="iconfont">&#xe83e;</i><span>我的消息</span><div><Badge text={ToMyTotalData} overflowCount={99} /><i className="iconfont">&#xe744;</i></div>
                    </ListWrapper>
                    <ListWrapper onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "中乐之星", weburl: "/YiPeiEdit" })) }}>
                        <i className="iconfont">&#xe87b;</i><span>中乐之星</span><div><i className="iconfont">&#xe744;</i></div>
                    </ListWrapper>
                    {
                        info.IsStarUser && <ListWrapper onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "艺人合作", weburl: "/StarEdit" })) }}>
                            <i className="iconfont">&#xe86f;</i><span>艺人合作</span><div><i className="iconfont">&#xe744;</i></div>
                        </ListWrapper>
                    }
                </NewMycenterPortraitWrapper >
            </>
        )
    }
}

export default MycenterPortrait;