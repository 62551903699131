import styled from 'styled-components';
import {
  theme,
  themenew
} from '../../common/color';

export const SearchWrapper = styled.div`
  position:absolute;
  width:100%; 
	height:100%;
  background: #fff;
`
export const Left = styled.div`
  width:17.5rem;
  max-height:38rem;
  overflow:hidden;
  float:left;
  .select{
    background: ${themenew.line_color};
    border-radius: 0.375rem;
  }
`
export const SearchTab = styled.div`
  width:100%;
  height:3.13rem;
  float:left;
  div{
    float:left;
    width:2rem;
    height:2rem;
    line-height:2rem;
    text-align:center;
    border-radius: 0.375rem;
    background-color:${themenew.subicon_color};
    margin-left:0.75rem;
    margin-top:0.56rem;
    margin-right:1.25rem;
    i{
      font-size:1.5rem;
      color:${themenew.text_white_color};
    }
  }
  span{
    float:left;
    height:2rem;
    line-height:2rem;
    font-size:0.88rem;
    margin-top:0.56rem;
  }
`
export const Lately = styled.div`
  width:92%;
  margin-left:4%;
  height:4rem;
  padding-top:1rem;
  div{
    float:left;
    font-size:1rem;
    color:${themenew.text_color};
    line-height:2rem;
    i{
      float:left;
      color: #B6B6C6;
      font-size:1.6rem;
    }
    span{
      float:left;
      margin-left:0.375rem;
    }
  }
  a{
    line-height:2rem;
    float:right;
    color: ${themenew.subtext_color};
    font-size:0.875rem;
  }
`
export const LatelyText = styled.div`
  width:92%;
  margin-left:4%;
  clear:both;
  div{
    display:inline-block;
    line-height:1.25rem;
    text-align:center;
    height:1.25rem;
    background-color:#F3F5F9;
    min-width:3.25rem;
    padding:0 0.625rem;
    border-radius:0.375rem;
    color: ${themenew.text_color};
    margin-right:0.625rem;
    font-size:0.875rem;
    margin-bottom:0.5rem;
  }
`
export const Right = styled.div`
  width:42.56rem;
  float:right;
`
export const Screen = styled.div`
  width:100%;
  height:1.5rem;
  >span{
    float:left;
    font-size:0.88rem;
    color:${themenew.text_color};
  }
  a{
    float:left;
    font-size:0.88rem;
    color:${themenew.subtext_color};
    margin-right:0.5rem;
  }
  div{
    float:right;
    font-size:0.88rem;
    color:${themenew.text_color};
    >span{
      color:${themenew.theme_color};
    }
  }
`
export const List = styled.div`
  display:inline-flex;
  width:100%;
  overflow:hidden;
  margin-top:1rem;
  position: relative;
  margin-bottom:1rem;
  h1{
    width:50%;
    height:13rem;
    position:relative;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    img{
      width:100%;
      height:13rem;
      object-fit: cover;
    }
    i{
      position:absolute;
      line-height:13rem;
      left:4.8rem;
      color:${themenew.text_white_color};
      font-size:5rem;
      opacity:0.8;
    }
  }
  div{
    width:100%;
    height:100%;
    padding-left:1.25rem;
    span{
      color:${themenew.theme_color};
    }
    >img{
      display:block;
      float:left;
      width:2.38rem;
      height:2.38rem;
      border-radius: 1.19rem;
      margin-right:0.63rem;
    }
    h3{
      float:left;
      line-height:2.38rem;
      font-size:1rem;
      color:${themenew.text_color};
      margin-right:0.69rem;
    }
    h4{
      float:left;
      line-height:2.38rem;
      font-size:0.88rem;
      color:${themenew.subtext_color};
    }
    h5{
      clear:both;
      font-size:1.25rem;
      color:${themenew.text_color};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    p{
      //max-height:4rem;
      font-size:0.88rem;
      color:${themenew.subtext_color};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin-bottom:2rem;
    }
    h2{
      position:absolute;
      line-height:1.5rem;
      font-size:1rem;
      color:${themenew.subtext_color};
      bottom:0;
      right:0;
      >i{
        font-size:1.5rem;
        color:${themenew.subicon_color};
        vertical-align:middle;
      }
      >span{
        font-size:0.75rem;
        color:${themenew.subtext_color};
        vertical-align:middle;
      }
    }
  } 
`
export const ClearBoth = styled.div`
clear:both;
`

export const StarList = styled.div`
  width: 92%;
  margin-left: 4%;
  padding-top: 1rem;
  padding-bottom: .1rem;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >h1{
    font-size: 18px;
    font-weight: 600;
    color: ${themenew.text_color};
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  >div{
    margin-bottom: 1rem;
    >span{
      display: inline-block;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      height: 1rem;
      >img{
        float: left;
        width: 15px;
        height: 15px;
        margin-right: 6px;
        color: ${themenew.subtext_color};
      }
      >h3{
        //float: left;
        display: inline;
        font-size: 14px;
        color: ${themenew.subtext_color};
        //margin-right: 16px;
      }
    }
    >div{
      width: 72%;
      float: left;
      >span{
        border:1px solid ${themenew.subtext_color};
        color: ${themenew.subtext_color};
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 4px;
        margin-right: 8px;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }
    
    >h2{
      font-size: 20px;
      color: #EF4266;
      float: right;
    }
    >a{
      float: right;
      color: ${themenew.text_color};
      >span{
        font-size: 12px;
        line-height: 27px;
        display: inline-block;
      }
      >i{
        font-size: 18px;
        float: right;
      }
    }
  }
`

//清楚浮动
export const ClearFloat = styled.div`
  clear: both;
`