import React, {
    Component
} from 'react';
import { List } from 'antd-mobile-v2';
import {
    Logo,
    RenZhengWrapper,
    ButtonBoxWrapper,
    SaveButton,
    AgreementBox,
    OpenPaymentWrapper,
    PaymentButton
} from '../MycenterPortraitStyle';


import {
    message,
    Collapse,
    Form,
    Input,
    Icon,
    Modal,
    Button,
    Tooltip,
    Radio,
    Cascader,
    Row,
    Col,
    Checkbox
} from "antd";

import UUID from 'uuid/v1';
import api from "../../../../http/api";
import { Toast } from 'antd-mobile-v2';
import httpAxios from "../../../../http/httpAxios";

import {
    getBase64,
    uploadImgWithName
} from "../../../../utils/utils";
import * as imageConversion from 'image-conversion';

const identityclass = [
    {
        value: '身份证',
        label: '身份证',
    },
    {
        value: '护照',
        label: '护照',
    },
    {
        value: '港澳居民来往内地通行证',
        label: '港澳居民来往内地通行证',
    },
    {
        value: '台湾居民往来大陆通行证',
        label: '台湾居民往来大陆通行证',
    },
    {
        value: '外国人居留证',
        label: '外国人居留证',
    },
]
const residences = [
    {
        value: '电子产品/IT/互联网',
        label: '电子产品/IT/互联网',
        children: [
            {
                value: '音响产品',
                label: '音响产品',
            },
            {
                value: '团购/导购返利',
                label: '团购/导购返利',
            },
            {
                value: '电子商务',
                label: '电子商务',
            },
            {
                value: '打印机及耗材',
                label: '打印机及耗材',
            },
            {
                value: '笔记本',
                label: '笔记本',
            },
            {
                value: '台式机',
                label: '台式机',
            },
            {
                value: '电脑配件',
                label: '电脑配件',
            },
            {
                value: '软件',
                label: '软件',
            },
            {
                value: '网络产品',
                label: '网络产品',
            },
            {
                value: '平板电脑',
                label: '平板电脑',
            },
            {
                value: '大家电/生活电器',
                label: '大家电/生活电器',
            },
            {
                value: '数码产品周边/配件',
                label: '数码产品周边/配件',
            },
            {
                value: '照相机',
                label: '照相机',
            },
            {
                value: '手机厂商',
                label: '手机厂商',
            },
            {
                value: '互联网技术',
                label: '互联网技术',
            },
            {
                value: '游戏',
                label: '游戏',
            },
            {
                value: '内容资讯',
                label: '内容资讯',
            },
            {
                value: 'app应用',
                label: 'app应用',
            },
            {
                value: '社交媒体',
                label: '社交媒体',
            },
            {
                value: '运营商电信服务',
                label: '运营商电信服务',
            },

            {
                value: '数码产品',
                label: '数码产品',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '商务服务',
        label: '商务服务',
        children: [
            {
                value: '法律咨询',
                label: '法律咨询',
            },
            {
                value: '财务会计/评估',
                label: '财务会计/评估',
            },
            {
                value: '广告公关',
                label: '广告公关',
            },
            {
                value: '人力资源',
                label: '人力资源',
            },
            {
                value: '网站建设/推广',
                label: '网站建设/推广',
            },
            {
                value: '翻译/速记',
                label: '翻译/速记',
            },
            {
                value: '咨询顾问',
                label: '咨询顾问',
            },
            {
                value: '办公耗材/设备',
                label: '办公耗材/设备',
            },
            {
                value: '印刷包装',
                label: '印刷包装',
            },
            {
                value: '招商加盟',
                label: '招商加盟',
            },
            {
                value: '互联网营销',
                label: '互联网营销',
            },
            {
                value: '礼品制定',
                label: '礼品制定',
            },
            {
                value: '网络解决方案',
                label: '网络解决方案',
            },
            {
                value: '展会服务',
                label: '展会服务',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '餐饮美食',
        label: '餐饮美食',
        children: [
            {
                value: '日本料理',
                label: '日本料理',
            },
            {
                value: '中餐',
                label: '中餐',
            },
            {
                value: '西餐',
                label: '西餐',
            },
            {
                value: '面包甜点',
                label: '面包甜点',
            },
            {
                value: '自助餐',
                label: '自助餐',
            },
            {
                value: '火锅',
                label: '火锅',
            },
            {
                value: '本地特色',
                label: '本地特色',
            },
            {
                value: '外卖送餐',
                label: '外卖送餐',
            },
            {
                value: '清真菜',
                label: '清真菜',
            },
            {
                value: '烧烤',
                label: '烧烤',
            },
            {
                value: '素食',
                label: '素食',
            },
            {
                value: '海鲜',
                label: '海鲜',
            },
            {
                value: '咖啡厅/茶馆',
                label: '咖啡厅/茶馆',
            },
            {
                value: '东南亚菜',
                label: '东南亚菜',
            },
            {
                value: '韩国料理',
                label: '韩国料理',
            },
            {
                value: '其他餐厅',
                label: '其他餐厅',
            },
        ],
    },
    {
        value: '食品饮料',
        label: '食品饮料',
        children: [
            {
                value: '食品',
                label: '食品',
            },
            {
                value: '酒精类',
                label: '酒精类',
            },
            {
                value: '饮料',
                label: '饮料',
            },
            {
                value: '乳品',
                label: '乳品',
            },
            {
                value: '奶粉',
                label: '奶粉',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '美容美发/彩妆卫浴',
        label: '美容美发/彩妆卫浴',
        children: [
            {
                value: '美容美发/造型服务',
                label: '美容美发/造型服务',
            },
            {
                value: '美体减肥',
                label: '美体减肥',
            },
            {
                value: '彩妆',
                label: '彩妆',
            },
            {
                value: '护肤护发',
                label: '护肤护发',
            },
            {
                value: '香水',
                label: '香水',
            },
            {
                value: '洗浴用品',
                label: '洗浴用品',
            },
            {
                value: '卫生用品',
                label: '卫生用品',
            },
            {
                value: '洗衣用品',
                label: '洗衣用品',
            },
            {
                value: '纸尿布',
                label: '纸尿布',
            },
            {
                value: '美甲服务',
                label: '美甲服务',
            },
            {
                value: '口腔护理',
                label: '口腔护理',
            },
            {
                value: '纹身',
                label: '纹身',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '休闲娱乐',
        label: '休闲娱乐',
        children: [
            {
                value: '桑拿/洗浴/足疗',
                label: '桑拿/洗浴/足疗',
            },
            {
                value: '按摩/SPA',
                label: '按摩/SPA',
            },
            {
                value: '游乐景点',
                label: '游乐景点',
            },
            {
                value: '酒吧/夜店/KTV',
                label: '酒吧/夜店/KTV',
            },
            {
                value: '电影院',
                label: '电影院',
            },
            {
                value: '会馆会所',
                label: '会馆会所',
            },
            {
                value: '户外扩展',
                label: '户外扩展',
            },
            {
                value: '度假村/农家乐',
                label: '度假村/农家乐',
            },
            {
                value: '景点/旅游/公园',
                label: '景点/旅游/公园',
            },
            {
                value: 'DIY手工坊',
                label: 'DIY手工坊',
            },
            {
                value: '网吧网咖',
                label: '网吧网咖',
            },
            {
                value: '游乐游艺',
                label: '游乐游艺',
            },
            {
                value: '台球',
                label: '台球',
            },
            {
                value: '桌游棋牌/密室',
                label: '桌游棋牌/密室',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '服饰/箱包/运动户外',
        label: '服饰/箱包/运动户外',
        children: [
            {
                value: '内衣/家居服',
                label: '内衣/家居服',
            },
            {
                value: '女装',
                label: '女装',
            },
            {
                value: '童装',
                label: '童装',
            },
            {
                value: '运动服装',
                label: '运动服装',
            },
            {
                value: '眼镜墨镜',
                label: '眼镜墨镜',
            },
            {
                value: '箱包',
                label: '箱包',
            },
            {
                value: '鞋类',
                label: '鞋类',
            },
            {
                value: '母婴',
                label: '母婴',
            },
            {
                value: '男装',
                label: '男装',
            },
            {
                value: '户外/登山/旅行用品',
                label: '户外/登山/旅行用品',
            },
            {
                value: '运动/健身/球迷用品',
                label: '运动/健身/球迷用品',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '生活服务',
        label: '生活服务',
        children: [
            {
                value: '家政服务',
                label: '家政服务',
            },
            {
                value: '婚庆服务',
                label: '婚庆服务',
            },
            {
                value: '搬家搬运',
                label: '搬家搬运',
            },
            {
                value: '维修疏通',
                label: '维修疏通',
            },
            {
                value: '鲜花速递',
                label: '鲜花速递',
            },
            {
                value: '快递物流',
                label: '快递物流',
            },
            {
                value: '交友征婚',
                label: '交友征婚',
            },
            {
                value: '宠物',
                label: '宠物',
            },
            {
                value: '摄影/摄像/写真',
                label: '摄影/摄像/写真',
            },
            {
                value: '洗衣店/洗染服务',
                label: '洗衣店/洗染服务',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '文化体育',
        label: '文化体育',
        children: [
            {
                value: '文化活动演出/票务',
                label: '文化活动演出/票务',
            },
            {
                value: '体育户外',
                label: '体育户外',
            },
            {
                value: '文化出版',
                label: '文化出版',
            },
            {
                value: '影视传播',
                label: '影视传播',
            },
            {
                value: '体育场馆',
                label: '体育场馆',
            },
            {
                value: '书店/图书馆',
                label: '书店/图书馆',
            },
            {
                value: '比赛',
                label: '比赛',
            },
            {
                value: '展览',
                label: '展览',
            },
            {
                value: '博物馆',
                label: '博物馆',
            },
            {
                value: '唱片/娱乐公司',
                label: '唱片/娱乐公司',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '旅游酒店',
        label: '旅游酒店',
        children: [
            {
                value: '旅行服务/旅行社',
                label: '旅行服务/旅行社',
            },
            {
                value: '票务服务',
                label: '票务服务',
            },
            {
                value: '各国签证',
                label: '各国签证',
            },
            {
                value: '酒店住宿',
                label: '酒店住宿',
            },
            {
                value: '户外旅行',
                label: '户外旅行',
            },
            {
                value: '境外游',
                label: '境外游',
            },
            {
                value: '签证服务',
                label: '签证服务',
            },
            {
                value: '游记攻略',
                label: '游记攻略',
            },
            {
                value: '自由行',
                label: '自由行',
            },
            {
                value: '快捷酒店',
                label: '快捷酒店',
            },
            {
                value: '景点门票',
                label: '景点门票',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '教育出国',
        label: '教育出国',
        children: [
            {
                value: '职业技能培训',
                label: '职业技能培训',
            },
            {
                value: '高考/中考/小升初',
                label: '高考/中考/小升初',
            },
            {
                value: '留学出国',
                label: '留学出国',
            },
            {
                value: '胎教/母婴教育亲子',
                label: '胎教/母婴教育亲子',
            },
            {
                value: '外语培训',
                label: '外语培训',
            },
            {
                value: '在线教育',
                label: '在线教育',
            },
            {
                value: '家教',
                label: '家教',
            },
            {
                value: 'AIT培训',
                label: 'AIT培训',
            },
            {
                value: 'MBA管理培训',
                label: 'MBA管理培训',
            },
            {
                value: '少儿英语',
                label: '少儿英语',
            },
            {
                value: '考研',
                label: '考研',
            },
            {
                value: '公务员考试',
                label: '公务员考试',
            },
            {
                value: '司法考试',
                label: '司法考试',
            },
            {
                value: '心理教育',
                label: '心理教育',
            },
            {
                value: '艺术培训',
                label: '艺术培训',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '金融服务',
        label: '金融服务',
        children: [
            {
                value: '基金证券',
                label: '基金证券',
            },
            {
                value: '银行',
                label: '银行',
            },
            {
                value: '保险',
                label: '保险',
            },
            {
                value: '金融理财',
                label: '金融理财',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '医疗健康企业',
        label: '医疗健康企业',
        children: [
            {
                value: '药品',
                label: '药品',
            },
            {
                value: '医疗器材',
                label: '医疗器材',
            },
            {
                value: '医院',
                label: '医院',
            },
            {
                value: '诊所/出诊',
                label: '诊所/出诊',
            },
            {
                value: '整容整形',
                label: '整容整形',
            },
            {
                value: '康复/疗养',
                label: '康复/疗养',
            },
            {
                value: '保健养生',
                label: '保健养生',
            },
            {
                value: '心理健康',
                label: '心理健康',
            },
            {
                value: '体检中心',
                label: '体检中心',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '汽车交通',
        label: '汽车交通',
        children: [
            {
                value: '航空公司',
                label: '航空公司',
            },
            {
                value: '汽车租赁及其他租赁',
                label: '汽车租赁及其他租赁',
            },
            {
                value: '汽车品牌企业',
                label: '汽车品牌企业',
            },
            {
                value: '汽车经销',
                label: '汽车经销',
            },
            {
                value: '汽车周边/服务保养',
                label: '汽车周边/服务保养',
            },
            {
                value: '出租车',
                label: '出租车',
            },
            {
                value: '公共交通',
                label: '公共交通',
            },
            {
                value: '汽车资讯/评测',
                label: '汽车资讯/评测',
            },
            {
                value: '铁路',
                label: '铁路',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '商场购物',
        label: '商场购物',
        children: [
            {
                value: '购物中心',
                label: '购物中心',
            },
            {
                value: '超市/便利店',
                label: '超市/便利店',
            },
            {
                value: '数码家电',
                label: '数码家电',
            },
            {
                value: '专业市场',
                label: '专业市场',
            },
            {
                value: '特色门店',
                label: '特色门店',
            },
            {
                value: '农贸市场',
                label: '农贸市场',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '房产家居/装饰',
        label: '房产家居/装饰',
        children: [
            {
                value: '开发商',
                label: '开发商',
            },
            {
                value: '楼盘宣传',
                label: '楼盘宣传',
            },
            {
                value: '房产中介',
                label: '房产中介',
            },
            {
                value: '装饰装灌',
                label: '装饰装灌',
            },
            {
                value: '建材',
                label: '建材',
            },
            {
                value: '家具/家居用品',
                label: '家具/家居用品',
            },
            {
                value: '家居装饰',
                label: '家居装饰',
            },
            {
                value: '商圈/社区生活',
                label: '商圈/社区生活',
            },
            {
                value: '建筑工程',
                label: '建筑工程',
            },
            {
                value: '室内设计',
                label: '室内设计',
            },
            {
                value: '物业管理',
                label: '物业管理',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '通讯服务',
        label: '通讯服务',
        children: [
            {
                value: '宽带/电信服务',
                label: '宽带/电信服务',
            },
            {
                value: '通讯器材',
                label: '通讯器材',
            },
            {
                value: '运营商',
                label: '运营商',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '工农贸易',
        label: '工农贸易',
        children: [
            {
                value: '工业用品',
                label: '工业用品',
            },
            {
                value: '能源',
                label: '能源',
            },
            {
                value: '化工',
                label: '化工',
            },
            {
                value: '农林牧渔业',
                label: '农林牧渔业',
            },
            {
                value: '制造业',
                label: '制造业',
            },
            {
                value: '贸易公司',
                label: '贸易公司',
            },
            {
                value: '农副产品',
                label: '农副产品',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
    {
        value: '其他',
        label: '其他',
        children: [
            {
                value: '其他',
                label: '其他',
            },
        ],
    },
];
var imageType = 0;
class SendCode extends Component {

    state = {
        time: '',
        phoneHash: '',
        userId: '',
    }

    trick = () => {
        let {
            time,
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }



    phoneHashData = (event) => {
        this.setState({
            phoneHash: event
        });
    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    sendCode = async () => {
        if (!this.props.phone) {
            message.info('请输入手机号')
            return
        }
        // try {
        const res = await httpAxios(this.props.url, {
            phone: this.props.phone
        })
        if (res) {
            clearTimeout(this.timeId);
            this.setState({
                time: 59,
                phoneHash: res.Data
            })
            this.timeId = setInterval(this.trick, 1000);
            this.phoneHashData(this.state.phoneHash);
        }
    }
    render() {
        return (
            <>
                {
                    this.state.time > 0 ? <Button disabled>{`${this.state.time}s 后重发`}</Button> : <Button onClick={this.sendCode}>发送验证码</Button>
                }
            </>
        )
    }
}

class EnterpriseRenZheng extends Component {
    state = {
        userId: '',

        CompanyName: this.props.info?.CompanyName,
        CompanyType: ['电子产品/IT/互联网', '音响产品'],
        CompanyType1: this.props.info?.CompanyType1,
        CompanyType2: this.props.info?.CompanyType2,
        LicenseID: this.props.info?.LicenseID,
        ManagerName: this.props.info?.ManagerName,
        PhoneNo: this.props.info?.ContactsPhone,
        LegalPersonName: this.props.info?.ContactsName,
        IDCardType: this.props.info?.IDCardType === undefined ? ['身份证'] : [this.props.info?.IDCardType],
        Identity: this.props.info?.IDCardNum,
        logoImg: this.props.info?.HeadImg || require('../../../../images/addimg.png'),
        licenseImg: this.props.info?.LicenseImg || require('../../../../images/addimg.png'),
        prosImg: this.props.info?.IDCardFront || require('../../../../images/addimg.png'),
        consImg: this.props.info?.IDCardBack || require('../../../../images/addimg.png'),
        validatePhone: "success",
        validateLicense: '',
        validateCode: '',
        AuthorizationStatus: 1,
        userInfo: {},
        confirmProtocol: false,
        openPayment: false,
        CurrentOrderFormID: 0,
        EditStatus: 0,
        buttonclicked: false
    };

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
            that.getCheckPayStatus(data.UserPID);

            httpAxios(api.getBussInfo, {
                userid: data.UserPID,
                token: data.Token
            }).then(res => {
                if (res.Code === 200) {
                    if (res.Data.CompanyName != null) {
                        let info = res.Data;
                        that.setState({
                            CompanyName: info.CompanyName,
                            logoImg: info.HeadImg,
                            CompanyType1: info.CompanyType1,
                            CompanyType2: info.CompanyType2,
                            LicenseID: info.LicenseID,
                            licenseImg: info.LicenseImg,
                            ManagerName: info.ManagerName,
                            IDCardType: info.IDCardType,
                            Identity: info.IDCardNum,
                            prosImg: info.IDCardFront,
                            consImg: info.IDCardBack,
                            LegalPersonName: info.ContactsName,
                            PhoneNo: info.ContactsPhone,
                            yzm: "",
                            AuthorizationStatus: info.AuthorizationStatus,
                            CurrentOrderFormID: info.CurrentOrderFormID,
                            EditStatus: info.EditStatus
                        });

                        localStorage.setItem("CurrentOrderFormID", info.CurrentOrderFormID);
                    }
                }
            });
        };
    }

    getCheckPayStatus = async (id) => {
        const res = await httpAxios(api.getCheckPayStatus, {
            userPID: id
        })

        if (res) {
            if (res.Data.AuthorizationStatus == 4 && res.Message == '1') {
                this.setState({
                    buttonclicked: true
                })
            } else {
                this.setState({
                    buttonclicked: false
                })
            }
        }
    }

    //上传Logo图片
    updateIMG = () => {
        this.IMGInput.click();
    }
    handleIMGSelect = async (e) => {
        const file = e.target.files[0];
        const file64 = await getBase64(file);
        const suffix = file.name.split('.')[1];
        const name = UUID() + '.' + suffix;
        const compressBlob = await imageConversion.compressAccurately(file, 200);
        const compressFile = new File([compressBlob], name);
        if (file.size > 2 * 1024 * 1024) {
            message.error('上传图片大小不能超过2MB');
            return
        }

        const url = await uploadImgWithName(api.bcUploadProductImg, compressFile, name, {
            FileName: name
        })
        if (url) {
            const res = await httpAxios(api.bussUpdateLogo, {
                UserBID: this.state.userId,
                ImgUrl: url
            })
            if (res) {
                this.setState({
                    logoImg: file64
                });
                let info = JSON.parse(localStorage.getItem('AUDIO_USER_INFO'));
                info.HeadImg = url
                this.props.setUserInfo(info);
                localStorage.setItem('AUDIO_USER_INFO', JSON.stringify(info));
            }
        }
    }
    //上传营业执照图片
    updateLicense = (type) => {
        imageType = type;
        this.LicenseInput.click();
    }
    handleLicenseSelect = async (e) => {
        const file = e.target.files[0];
        const file64 = await getBase64(file);
        const suffix = file.name.split('.')[1];
        const name = UUID() + '.' + suffix;
        if (file.size > 2 * 1024 * 1024) {
            message.error('上传图片大小不能超过2MB');
            return
        }
        const url = await uploadImgWithName(api.bcUploadProductImg, file, name, {
            FileName: name
        })
        if (url) {
            // const res = await httpAxios(api.bussUpdateLicense, {
            //   UserBID: CURRENT_USER_ID,
            //   ImgUrl: url
            // })
            // if (res) {
            //   this.setState({
            //     licenseImg: file64
            //   })
            // }
            switch (imageType) {
                case 1://营业执照
                    this.setState({
                        licenseImg: url
                    })
                    break;
                case 2://公司logo
                    this.setState({
                        logoImg: url
                    })
                    break;
                case 3://证件证明
                    this.setState({
                        prosImg: url
                    })
                    break;
                case 4://证件反面
                    this.setState({
                        consImg: url
                    })
                    break;
            }
        }
    }

    //对社会统一信用代码校验
    handleLicenseID = (e) => {
        let value = e.target.value;
        value = value.trim();
        let myreg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
        if (!myreg.test(value)) {
            this.setState({
                validateLicense: "error",
            });
        } else {
            this.setState({
                validateLicense: "success",
            });
        }
    }
    //对手机号校验
    handlePhoneNo = (e) => {
        let value = e.target.value;
        value = value.trim();
        let myreg = /^1[3456789]\d{9}$/
        if (!myreg.test(value)) {
            this.setState({
                validatePhone: "error",
            });
        } else {
            this.setState({
                validatePhone: "success",
            });
        }
    }

    //保存数据
    handleSave = (e) => {
        e.preventDefault();

        const {
            confirmProtocol
        } = this.state;

        if (!confirmProtocol) {
            Toast.info('请先勾选我同意《企业会员服务协议》')
            return
        }

        let ok = "ok";
        if (this.state.CompanyName === "") {
            this.setState({ validateCompanyName: "error" });
            ok = "false1";
        }
        else {
            this.setState({ validateCompanyName: "success" });
        }

        if (this.state.CompanyType === undefined || this.state.CompanyType.length !== 2) {
            this.setState({ validateCompanyType1: "error" });
            ok = "false2";
        }
        else {
            this.setState({ validateCompanyType1: "success" });
        }

        if (this.state.LicenseID === "") {
            this.setState({ validateLicense: "error" });
            ok = "false3";
        }
        else {
            this.setState({ validateLicense: "success" });
        }

        if (this.state.logoImg == null || this.state.logoImg.indexOf("http") === -1) {
            this.setState({ validatelogoImg: "error" });
            ok = "false4";
        }
        else {
            this.setState({ validatelogoImg: "success" });
        }

        if (this.state.licenseImg == null || this.state.licenseImg.indexOf("http") === -1) {
            this.setState({ validatelicenseImg: "error" });
            ok = "false5";
        }
        else {
            this.setState({ validatelicenseImg: "success" });
        }

        if (this.state.licenseImg == null || this.state.licenseImg.indexOf("http") === -1) {
            this.setState({ validatelicenseImg: "error" });
            ok = "false6";
        }
        else {
            this.setState({ validatelicenseImg: "success" });
        }

        if (this.state.ManagerName === "") {
            this.setState({ validateManagerName: "error" });
            ok = "false7";
        }
        else {
            this.setState({ validateManagerName: "success" });
        }

        if (this.state.IDCardType === "") {
            this.setState({ validateIDCardType: "error" });
            ok = "false8";
        }
        else {
            this.setState({ validateIDCardType: "success" });
        }

        if (this.state.Identity === "") {
            this.setState({ validateIdentity: "error" });
            ok = "false9";
        }
        else {
            this.setState({ validateIdentity: "success" });
        }

        if (this.state.prosImg.indexOf("http") === -1) {
            this.setState({ validateprosImg: "error" });
            ok = "false10";
        }
        else {
            this.setState({ validateprosImg: "success" });
        }

        if (this.state.consImg.indexOf("http") === -1) {
            this.setState({ validateconsImg: "error" });
            ok = "false11";
        }
        else {
            this.setState({ validateconsImg: "success" });
        }

        if (this.state.LegalPersonName === "") {
            this.setState({ validateLegalPersonName: "error" });
            ok = "false12";
        }
        else {
            this.setState({ validateLegalPersonName: "success" });
        }

        if (this.state.PhoneNo === "") {
            this.setState({ validatePhone: "error" });
            ok = "false13";
        }
        else {
            this.setState({ validatePhone: "success" });
        }

        if (ok !== "ok") {
            console.log(ok)
            return;
        }
        let data = {
            UserPID: this.state.userId,
            CompanyName: this.state.CompanyName,
            HeadImg: this.state.logoImg,
            CompanyType1: this.state.CompanyType[0],
            CompanyType2: this.state.CompanyType[1],
            LicenseType: "社会统一信用代码",
            LicenseID: this.state.LicenseID,
            LicenseImg: this.state.licenseImg,
            ManagerName: this.state.ManagerName,
            IDCardType: this.state.IDCardType[0],
            IDCardNum: this.state.Identity,
            IDCardFront: this.state.prosImg,
            IDCardBack: this.state.consImg,
            ContactsName: this.state.LegalPersonName,
            ContactsPhone: this.state.PhoneNo,
            yzm: this.state.yzm
        }

        // console.log(data);

        httpAxios(api.bcCompanyApplyTemp, data).then(res => {
            // console.log(res);
            if (res.Code === 200) {
                // this.getCheckAuthorization();
                // window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证成功', weburl: '/RenZhengOK' }));
                if (this.state.buttonclicked) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '提交成功', weburl: '/RenZhengOK' }));
                } else {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证付费', weburl: '/EnterpriseRenZhengPayment' }));
                }
            }
        });
    }
    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    checkProtocol = () => {
        const {
            confirmProtocol,
        } = this.state;
        this.setState({
            confirmProtocol: !confirmProtocol
        })
    }


    // handlePayment = () => {
    //     const {
    //         confirmProtocol
    //     } = this.state;

    //     if (!confirmProtocol) {
    //         Toast.info('请先勾选我同意《企业会员服务协议》')
    //         return
    //     }

    //     window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证付费', weburl: '/EnterpriseRenZhengPayment' }));
    // }

    render() {
        const {
            CompanyName,
            CompanyType1,
            CompanyType2,
            IDCardType,
            validateCompanyName,
            validateCompanyType1,
            LicenseID,
            ManagerName,
            validateManagerName,
            logoImg,
            validatelogoImg,
            licenseImg,
            validatelicenseImg,
            validateLicense,
            validateCode,
            LegalPersonName,
            validateLegalPersonName,
            validateIDCardType,
            Identity,
            validateIdentity,
            prosImg,
            validateprosImg,
            consImg,
            validateconsImg,
            PhoneNo,
            validatePhone,
            AuthorizationStatus,
            CompanyType,
            openPayment,
            CurrentOrderFormID,
            EditStatus
        } = this.state;
        const formItemLayout = {
            labelCol: {
                span: 5,
            },
            wrapperCol: {
                span: 10,
            },
        };
        const tailFormItemLayout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                xs: {
                    span: 0,
                    offset: 0,
                },
                sm: {
                    span: 10,
                    offset: 5,
                },
            },
        };
        return (
            <>
                <RenZhengWrapper>
                    <Form {...formItemLayout} onSubmit={this.handleSave}>
                        <Form.Item
                            label="企业名称"
                            validateStatus={validateCompanyName}
                            help={validateCompanyName === "error" ? "请输入企业名称" : ""}
                        >
                            <Input placeholder="请输入与营业执照上的相同的企业名称" value={CompanyName} onChange={(e) => { this.setState({ CompanyName: e.target.value.trim() }) }} />
                        </Form.Item>
                        <Form.Item
                            label="选择企业类型"
                            validateStatus={validateCompanyType1}
                            help={validateCompanyType1 === "error" ? "请输选择企业类型" : ""}
                        >
                            <Cascader
                                defaultValue={CompanyType1 === undefined ? CompanyType : [CompanyType1, CompanyType2]}
                                options={residences}
                                onChange={(value) => this.setState({ CompanyType: value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="社会统一信用代码"
                            validateStatus={validateLicense}
                            help={validateLicense === "error" ? "请输入正确的社会统一信用代码" : ''}
                        >
                            <Input placeholder="请输入与营业执照上的相同的统一信用代码" value={LicenseID} onChange={(e) => { this.setState({ LicenseID: e.target.value.trim() }) }} onBlur={this.handleLicenseID} />
                        </Form.Item>
                        <Form.Item
                            label="上传营业执照"
                            validateStatus={validatelicenseImg}
                            help={validatelicenseImg === "error" ? "上传营业执照" : ''}
                        >
                            <Logo>
                                <img onClick={() => this.updateLicense(1)} src={licenseImg} alt="" />
                                <input type="file" accept="image/*" hidden ref={ref => this.LicenseInput = ref} onChange={this.handleLicenseSelect} />
                            </Logo>
                        </Form.Item>
                        <Form.Item
                            label="上传公司logo图标"
                            validateStatus={validatelogoImg}
                            help={validatelogoImg === "error" ? "请上传公司logo图标" : ''}
                        >
                            <Logo>
                                <img src={logoImg} alt="" onClick={() => this.updateLicense(2)} />
                                <input type="file" accept="image/*" hidden ref={ref => this.IMGInput = ref} onChange={this.handleIMGSelect} />
                            </Logo>
                        </Form.Item>
                        <Form.Item
                            label="法人名称"
                            validateStatus={validateManagerName}
                            help={validateManagerName === "error" ? "请输入法人名称" : ''}
                        >
                            <Input placeholder="请输入法人姓名" value={ManagerName} onChange={(e) => { this.setState({ ManagerName: e.target.value.trim() }) }} />
                        </Form.Item>
                        <Form.Item
                            label="法人有效的身份证件"
                            validateStatus={validateIDCardType}
                            help={validateIDCardType === "error" ? "请选择法人有效的身份证件" : ''}
                        >
                            <Cascader
                                defaultValue={IDCardType}
                                options={identityclass}
                                onChange={(value) => this.setState({ IDCardType: value })}
                            />
                        </Form.Item>
                        <Form.Item label="证件号码"
                            validateStatus={validateIdentity}
                            help={validateIdentity === "error" ? "请输入证件号码" : ''}
                        >
                            <Input placeholder="请输入证件号码" value={Identity} onChange={(e) => { this.setState({ Identity: e.target.value.trim() }) }} />
                        </Form.Item>
                        <Form.Item label="上传证件正面"
                            validateStatus={validateprosImg}
                            help={validateprosImg === "error" ? "请上传证件正面" : ''}
                        >
                            <Logo>
                                <img src={prosImg} alt="" onClick={() => this.updateLicense(3)} />
                                {/* <input type="file" accept="image/*" hidden ref={ref => this.LicenseInput = ref} onChange={this.handleLicenseSelect} /> */}
                            </Logo>
                        </Form.Item>
                        <Form.Item label="上传证件反面"
                            validateStatus={validateconsImg}
                            help={validateconsImg === "error" ? "请上传证件反面" : ''}
                        >
                            <Logo>
                                <img src={consImg} alt="" onClick={() => this.updateLicense(4)} />
                                {/* <input type="file" accept="image/*" hidden ref={ref => this.LicenseInput = ref} onChange={this.handleLicenseSelect} /> */}
                            </Logo>
                        </Form.Item>
                        <Form.Item label="联系人姓名"
                            validateStatus={validateLegalPersonName}
                            help={validateLegalPersonName === "error" ? "请输入联系人姓名" : ''}
                        >
                            <Input placeholder="请输入联系人姓名" value={LegalPersonName} onChange={(e) => { this.setState({ LegalPersonName: e.target.value.trim() }) }} />
                        </Form.Item>
                        <Form.Item
                            label="联系人手机号"
                            validateStatus={validatePhone}
                            help={validatePhone === "error" ? "请输入正确的手机号" : ''}
                        >
                            <Input placeholder="请输入联系人手机号" value={PhoneNo} onChange={(e) => { this.setState({ PhoneNo: e.target.value.trim() }) }} onBlur={this.handlePhoneNo} />
                        </Form.Item>
                        <Form.Item
                            label="手机验证码"
                            validateStatus={validateCode}
                            help={validateCode === "error" ? "验证码不正确" : ''}
                        >
                            <Row gutter={8}>
                                <Col span={16}>
                                    <Input onChange={(e) => { this.setState({ yzm: e.target.value.trim() }) }} />
                                </Col>
                                <Col span={8}>
                                    <SendCode url={api.loginCode} phone={PhoneNo} />
                                    {/* <Button onClick={() => this.sendCode()}>发送验证码</Button> */}
                                </Col>
                            </Row>
                        </Form.Item >

                        {/* <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" block >
                            提交
                        </Button>
                    </Form.Item> */}
                        <div style={{ height: '5rem' }} />
                    </Form >

                </RenZhengWrapper>
                <ButtonBoxWrapper>
                    <SaveButton onClick={this.handleSave}>
                        下一步
                    </SaveButton>
                    <AgreementBox>
                        <Checkbox onClick={this.checkProtocol} />&nbsp;&nbsp;开通前请阅读<a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '企业会员服务协议', weburl: '/VIPAgreement' })); }}>《企业会员服务协议》</a>
                    </AgreementBox>
                </ButtonBoxWrapper>
            </>
        )
    }
}

export default EnterpriseRenZheng;