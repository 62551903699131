/**
 * created by sunchenyao on 2021/2/2
 */
import React, {
    Component
} from 'react';
import {
    SettingsWrapper
} from '../../../../common/generalStyle';
import { List, Badge } from 'antd-mobile-v2';
import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";

const Item = List.Item;

export default class Message extends Component {
    state = {
        userPID: "",
        ToMyReplyData: 0,
        ToMyLikeData: 0,
        ToMyMessageData: 0,
    };
    componentDidUpdate() {
        var that = this;
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "消息设置", weburl: "/MessageSettings"}))
        }
    }
    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getToMyReplyCount();
            that.getToMyLikeCount();
            that.getToMyMessageCount();
        };
    }
    //获取对我回复的统计数量
    getToMyReplyCount = async () => {
        const {
            userPID
        } = this.state;
        const res = await httpAxios(api.getToMyReplyCount, {
            userID: userPID,
        })
        if (res && res.Data) {
            this.setState({
                ToMyReplyData: res.Data,
            });
        }
    }
    //获取对我点赞的统计数量
    getToMyLikeCount = async () => {
        const {
            userPID
        } = this.state;
        const res = await httpAxios(api.getToMyLikeCount, {
            userID: userPID,
        })
        if (res && res.Data) {
            this.setState({
                ToMyLikeData: res.Data,
            });
        }
    }
    //获取我的系统消息统计数量
    getToMyMessageCount = async () => {
        const {
            userPID
        } = this.state;
        const res = await httpAxios(api.getToMyMessageCount, {
            userID: userPID,
        })
        if (res && res.Data) {
            this.setState({
                ToMyMessageData: res.Data,
            });
        }
    }

    render() {
        const {
            ToMyReplyData,
            ToMyLikeData,
            ToMyMessageData
        } = this.state;
        return <SettingsWrapper>
            <List renderHeader={() => ''}>
                <Item
                    className='listheight'
                    extra={""}
                    arrow="horizontal"
                    onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "回复我的", weburl: "/MyMessageReply" + '?' + this.state.userPID })) }}
                    extra={<Badge text={ToMyReplyData} overflowCount={99} />}
                >回复我的</Item>
                <Item
                    className='listheight'
                    extra={""}
                    arrow="horizontal"
                    onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "给我点赞", weburl: "/MyMessageZan" + '?' + this.state.userPID })) }}
                    extra={<Badge text={ToMyLikeData} overflowCount={99} />}
                >给我点赞</Item>
                <Item
                    className='listheight'
                    extra={""}
                    arrow="horizontal"
                    onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "系统消息", weburl: "/MyMessageSystem" + '?' + this.state.userPID })) }}
                    extra={<Badge text={ToMyMessageData} overflowCount={99} />}
                >系统消息</Item>
            </List>
        </SettingsWrapper>
    }
}