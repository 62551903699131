import React, {
    Component
} from 'react';
import Content from './components/PersonalContent';
import {
    CommunityWrapper,
} from './style';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

class CompanyAuthor extends Component {
    state = {
        info: {},
        userPID: '',
        authorID: this.props.match.params.id,
    }

    componentDidMount() {
        this.getUserInfo();
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }
    }

    getUserInfo = async () => {
        const res = await httpAxios(api.getUserInfo, {
            UserPID: this.props.match.params.id
        })
        if (res) {
            this.setState({
                info: res.Data
            })
        }
    }
    render() {
        const {
            info,
            userPID,
            authorID
        } = this.state
        // alert(JSON.stringify(info));

        if (userPID != "") {
            sessionStorage.setItem("au_userPID", userPID);
        }
        if (authorID != "") {
            sessionStorage.setItem("au_authorID", authorID);
        }

        return (
            <CommunityWrapper>
                <Content info={info} userPID={userPID} authorID={authorID} />
            </CommunityWrapper>
        )
    }
}

export default CompanyAuthor