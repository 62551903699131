/**
 * created by zhangling on 2019/12/26
 */
import styled from 'styled-components';
import {
  theme, themenew
} from '../color';


export const Box = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1000;
    width:92%;
    margin-left:4%;
    max-height: 460px;
    overflow-y: auto;
    background-color: ${theme.background_color};
    padding: 1.25rem 1.1rem;
    border-radius: 0.5rem;
  >i {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 10;
    font-size:1.8rem;
    :hover {
      
    }
  }
  h5{
    font-size: 1rem;
    margin-top:0.2rem;
    span{
      color: ${themenew.link_color};
    }
  }
  h6{
    width:100%;
    height:4.6rem;
    background:#F7F7F7;
    padding:0.95rem;
    font-size:0.875rem;
    line-height:1.25rem;
    margin-top:1rem;
    margin-bottom:0.9375rem;
    span{
      color: ${themenew.link_color};
    }
  }
  p {
    margin-bottom: 5px;
  }
`
export const Shading = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background:rgba(0,0,0,0.5);
  z-index:999;
`
export const Item = styled.span`
  display: inline-block;
  margin: 0.3125rem 0.25rem;
  padding: 0 0.9375rem;
  line-height: 1.875rem;
  border-radius: 0.25rem;
  background-color: #F7F7F7;
  color: ${themenew.text_color};
`
export const ItemSelected = styled.span`
  display: inline-block;
  margin: 0.3125rem 0.25rem;
  padding: 0 0.9375rem;
  line-height: 1.875rem;
  border-radius: 0.25rem;
  background-color: ${themenew.link_color};
  color: ${themenew.text_white_color};
`
export const Comment = styled.input`
  height: 4.5625rem;
  width: 100%;
  margin: 0.3125rem 0px;
  background-color: #F7F7F7;
  border:0;
  color:${themenew.text_color};
  padding:0.95rem;
`

export const Submit = styled.button`
  display: inline-block;
  width:7.5rem;
  line-height: 1.875rem;
  border-radius: 0.25rem;
  background-color: ${themenew.link_color};
  color: #ffffff;
  float: right;
  margin-top:0.9375rem;
`
export const Inform = styled.span`
  margin-left:0.625rem;
  i{
    vertical-align:-0.1rem;
  }
`