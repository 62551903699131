import React, {
    Component
} from 'react';

import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    NewsDetailWrapper,
    Empty,
} from '../../../../common/generalStyle';

import {
    CommunityWrapper,
    SystemBox,
} from './style';

import Collection from '../../../../images/message.svg';
import httpAxios2 from "../../../../http/httpAxios2";
import api from "../../../../http/api";
import {
    startMinsTime
} from '../../../../utils/utils';

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class MsgToFans extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userId: this.props.location?.search.replace('?', ''),//从网址中取栏目类型
            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,
            data: [],
            typeKeys: {},
            hasMore: true,
            rowCount: 0,
        };
    }

    componentDidUpdate() {
        var that = this;
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "发布公告", buttonTitle: "发布", weburl: "/msgtofansrelease" + "?" + that.state.userId }))
        }
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
        };
        this.getList();
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    getListRefresh = async () => {
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        await this.getList();
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }
    //加载数据列表
    getList = async () => {
        const res = await httpAxios2(api.bcGetMessages, {
            bussID: this.state.userId,
        });

        // alert(JSON.stringify(res.Data.length))
        if (res) {
            this.state.rowCount = res.Message;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];
                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.setState({
                    data: [],
                })
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }
    //刷新开始
    onRefresh = async () => {
        pageIndex = 1;
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh();
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);

    };
    //刷新结束
    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList();
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            // console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <NewsDetailWrapper>
                        <SystemBox>
                            <div>{startMinsTime(obj.SendTime)}</div>
                            <span>{obj.Content}</span>
                        </SystemBox>
                    </NewsDetailWrapper>
                </div>
            );
        };
        return (<CommunityWrapper>
            {
                data.length != 0 ?
                    <ListView
                        initialListSize={8}
                        key={'1'}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                            {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                        </div>)}
                        renderRow={row}
                        useBodyScroll
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                        onEndReached={this.onEndReached}
                        pageSize={NUM_ROWS}
                    />
                    :
                    <Empty>
                        <img src={Collection} alt="收藏无内容"></img>
                        <b>暂无发布过的系统消息</b>
                        <span>你还没有发布过任何系统消息。</span>
                        {/* 隐藏ListView不能去掉，不然ReactDOM高度会报错 */}
                        <ListView
                            style={{ display: 'none' }}
                            initialListSize={8}
                            key={'1'}
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource}
                            renderRow={row}
                            useBodyScroll
                            onEndReached={this.onEndReached}
                            pageSize={NUM_ROWS}
                        />
                    </Empty>
            }
        </CommunityWrapper>);
    }
}

ReactDOM.render(<MsgToFans />, document.getElementById('root'));
export default MsgToFans