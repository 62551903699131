/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    NavBar,
    BrandsBG,
    BrandsImg,
    BrandsTitle,
    CollectionButton,
    CollectionButtonSelect,
    BrandsIntroduceTitle,
    BrandsIntroduce,
    BrandsIntroduceMore,
    BrandsIntroduceMoreButton
} from './style';
import {
    ProductList,
    ProductListTitle,
    ProductListImg,
    ProductListLine,
    ProductListPub
} from '../style';

import {
    Tabs
} from './LabelStyle';
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import {
    filterHtml,
} from '../../../utils/utils';

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class BrandsDetail extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userPID: '',

            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            productCatalog: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            hasMore: true,
            rowCount: 0,

            moreFocus: false,
            brandID: this.props.match?.params?.id,
            brandsDetail: {},
            activeKey: 0,
            isCollected: false,
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })

            that.checkBrandCollection();

        }

        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);

        if (this.state.brandID != undefined) {
            await this.getBrandDetail(this.state.brandID);
        }

        await this.getProductCatalog();

        await this.getList(0);

    }

    getBrandDetail = async (id) => {
        const res = await httpAxios(api.getBrandInfoById, { brandID: id });
        if (res) {
            this.setState({ brandsDetail: res.Data[0] });
        }
    }

    //验证收藏
    checkBrandCollection = async () => {
        const res = await httpAxios(api.checkBrandCollection, { BrandID: this.state.brandID, UserID: this.state.userPID });
        if (res.Code == 200) {
            this.setState({ isCollected: res.Data.isCollected });
        }
    }

    //增加收藏
    addBrandCollection = async () => {
        const res = await httpAxios(api.addBrandCollection, { BrandID: this.state.brandID, UserID: this.state.userPID });
        if (res.Code == 200) {
            this.setState({ isCollected: true });
        }
    }

    //取消收藏
    removeBrandCollection = async () => {
        const res = await httpAxios(api.removeBrandCollection, { BrandID: this.state.brandID, UserID: this.state.userPID });
        if (res.Code == 200) {
            this.setState({ isCollected: false });
        }
    }

    goBackPage = () => {
        if (this.props.location?.search === '?v=1') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goHome" }));
        } else {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }

    getListRefresh = async () => {
        this.setState({
            data: [],
        })
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(this.state.activeKey);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async (catalogID) => {
        if (this.state.brandID == undefined) {
            return;
        }

        let params;
        params = {
            targetID: this.state.brandID,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            catalogID: catalogID
        }

        const res = await httpAxios(api.getProductsByBrandID, params);
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }

        this.setState({
            refreshing: false,
            isLoading: false,
        });
    }

    getProductCatalog = async () => {
        const res = await httpAxios(api.getCatalog);
        if (res) {
            this.setState({
                productCatalog: [{ ID: 0, Name: "全部产品" }, ...res.Data],
            })
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh();
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(this.state.activeKey);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    handleClick = (id) => {
        this.setState({
            activeKey: id
        })

        this.state.activeKey = id;

        this.onRefresh();
    }

    //收藏
    handleCollectionClick = () => {
        if (!this.state.userPID) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能收藏文章');
            return
        }

        if (this.state.isCollected) {
            this.removeBrandCollection();
        }
        else {
            this.addBrandCollection()
        }
    }

    render() {
        const {
            data,
            comTypes,
            livedata,
            moreFocus,
            targetID,
            brandsDetail,
            productCatalog,
            isCollected
        } = this.state;

        // alert(productCatalog);

        const row = (rowData, sectionID, rowID) => {
            //console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: brandsDetail.BrandName, weburl: '/Brands/ProductDetail/' + obj.ProductID })); }}>
                        <ProductList>
                            <ProductListTitle>{obj.ProductName}</ProductListTitle>
                            <ProductListImg><img src={obj.ProductCoverImg} alt="" /></ProductListImg>
                            <ProductListPub>
                                <img src={brandsDetail.BrandLogo} alt="" />
                                <span>{brandsDetail.BrandName}</span>
                                <span>{brandsDetail.BrandCountry}</span>
                            </ProductListPub>
                            <ProductListLine />
                        </ProductList>
                    </a>
                </div>
            );
        };
        return (<div>
            <BrandsBG />
            <BrandsTitle>
                <div>{brandsDetail?.BrandName}</div>
                <span>{brandsDetail?.BrandCountry}</span>
            </BrandsTitle>
            <NavBar>
                <BrandsImg>
                    <img src={brandsDetail?.BrandLogo} />
                </BrandsImg>
                {
                    isCollected ? <CollectionButtonSelect onClick={() => { this.handleCollectionClick() }}>已收藏</CollectionButtonSelect> : <CollectionButton onClick={() => { this.handleCollectionClick() }}>收藏</CollectionButton>
                }

                <BrandsIntroduceTitle>品牌故事</BrandsIntroduceTitle>
                {!moreFocus ? <BrandsIntroduce dangerouslySetInnerHTML={{ __html: filterHtml(brandsDetail?.BrandStory) }}></BrandsIntroduce> : <BrandsIntroduceMore dangerouslySetInnerHTML={{ __html: filterHtml(brandsDetail?.BrandStory) }}></BrandsIntroduceMore>}
                <BrandsIntroduceMoreButton>{!moreFocus ? <span onClick={() => { this.setState({ moreFocus: true }) }}><i className="iconfont">&#xe7b6;</i>展开更多</span> : <span onClick={() => { this.setState({ moreFocus: false }) }}><i className="iconfont">&#xe747;</i>收起内容</span>}</BrandsIntroduceMoreButton>
                <Tabs>
                    {productCatalog.map(item => (
                        <div className={this.state.activeKey === item.ID ? 'selected' : ''} onClick={this.handleClick.bind(this, item.ID)}>
                            <span>{item.Name}</span>
                            {
                                this.state.activeKey === item.ID ? <div /> : ''
                            }
                        </div>
                    ))}
                </Tabs>
            </NavBar>
            <ListView
                initialListSize={8}
                key={'1'}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (
                    <div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                    </div>)}
                renderRow={row}
                useBodyScroll
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    onRefresh={this.onRefresh}
                />}
                onEndReached={this.onEndReached}
                pageSize={NUM_ROWS}
            />
        </div>);
    }
}

ReactDOM.render(<BrandsDetail />, document.getElementById('root'));
export default BrandsDetail