import React, {
    Component
} from 'react';
import {
    ReportWrapper,
    SubText,
    ItemSelected,
    Item,
    ReportInput,
    UpImg,
    ReportCode,
    ReportContacts,
    ReportButton
} from './ReportCommentsStyle';
import {
    ClearFloat
} from '../../../common/style';

import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import { Toast } from 'antd-mobile-v2';

import UUID from 'uuid/v1'
import httpAxiosOss from "../../../http/httpAxiosOss"

class ReportComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportList: [{
                name: '涉政',
                selected: false
            }, {
                name: '暴恐血腥',
                selected: false
            }, {
                name: '色情、低俗',
                selected: false
            }, {
                name: '涉赌',
                selected: false
            }, {
                name: '诈骗',
                selected: false
            }, {
                name: '私服、外挂',
                selected: false
            }, {
                name: '未授权内容',
                selected: false
            }, {
                name: '其他违规行为',
                selected: false
            }],
            reportsList: [{
                name: '声音违规',
                selected: false
            }, {
                name: '聊天违规',
                selected: false
            }],
            liveID: 0,
            userId: '',
            userBID: '',
            reason: '',
            phone: '',
            images: [],
            code: '',
            checkCode: '0'
        }
    }
    componentDidMount() {
        var that = this;

        window.reportInfo = function (report) {
            that.setState({
                liveID: report.liveID,
                userId: report.userId,
                userBID: report.userBID
            })
        }

        //验证码
        this.getCode();
    }

    clickItem = (index) => {
        let list = [...this.state.reportList];
        list[index].selected = !list[index].selected;
        this.setState({
            reportList: list
        })
    }
    clickItems = (index) => {
        let list = [...this.state.reportsList];
        list[index].selected = !list[index].selected;
        this.setState({
            reportsList: list
        })
    }

    setReason = (value) => {
        this.setState({ reason: value });
    }

    setPhone = (value) => {
        this.setState({ phone: value });
    }

    setCode = (value) => {
        this.setState({ checkCode: value });
    }


    submitReport = async () => {
        if (this.state.checkCode.toLowerCase() != this.state.code.toLowerCase()) {
            alert("验证码不正确，请重新输入验证！");
            return;
        }

        const {
            reportList,
            reportsList
        } = this.state;
        var types = reportList.filter(item => (item.selected)).map(i => (i.name)).join(',');
        const type2 = reportsList.filter(item => (item.selected)).map(i => (i.name)).join(',');
        if (type2 != "") {
            types = types + ',' + type2;
        }

        const res = await httpAxios(api.postLiveReport, {
            "liveID": this.state.liveID,
            "UserBID": this.state.userId,
            "UserPID": this.state.userBID,
            "Phone": this.state.phone,
            "Type": types,
            "Reason": this.state.reason,
            "ImageUrl": this.state.images.toString()
        })


        if (res.Code == 200) {
            Toast.info("信息举报成功！");
            setTimeout(() => {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "closelive" }));
            }, 2000);
        }
    }

    //图上传
    handleClick = () => {
        this.input.click()
    }

    handleSelect = async (e) => {
        if (!e) return;

        const doc = e.target.files[0];
        const suffix = doc.name.split('.')[1];
        const name = UUID() + '.' + suffix;
        const res = await httpAxios(api.getDirectUploadSign, {
            filename: name,
            mtype: 4
        })

        let formData = new FormData();
        formData.append("name", name)
        formData.append("OSSAccessKeyId", res.Data.AccessID);
        formData.append("signature", res.Data.Signature);
        formData.append("policy", res.Data.Policy);
        formData.append("key", res.Data.Dir + name);
        formData.append("success_action_status", '200');
        formData.append("file", doc);
        httpAxiosOss({
            url: res.Data.OSSHost,
            method: 'post'
        }, formData).then(r => {
            this.setState({
                images: [...this.state.images, res.Data.FinalUrl]
            })
        }).catch(e => {

        })
    }

    //验证码
    getCode = () => {
        // 验证码的组成
        var moreCode = new Array(
            '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
            'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y', 'z',
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
            'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
            'U', 'V', 'W', 'X', 'Y', 'Z'
        );
        var codes = '';// 重新初始化验证码
        for (var i = 0; i < 6; i++) {
            // 随机获取一个数组
            var a = parseInt(Math.random() * moreCode.length);
            codes += moreCode[a];
        }

        this.setState({ code: codes });
    }

    render() {
        return (
            <ReportWrapper>
                <SubText>*画面违规</SubText>
                {
                    this.state.reportList.map((item, index) => {
                        if (item.selected) {
                            return <ItemSelected key={`item-${index}`} onClick={this.clickItem.bind(this, index)}>{item.name}</ItemSelected>
                        } else {
                            return <Item key={`item-${index}`} onClick={this.clickItem.bind(this, index)}>{item.name}</Item>
                        }
                    })
                }
                <SubText>*其他违规</SubText>
                {
                    this.state.reportsList.map((item, index) => {
                        if (item.selected) {
                            return <ItemSelected key={`item-${index}`} onClick={this.clickItems.bind(this, index)}>{item.name}</ItemSelected>
                        } else {
                            return <Item key={`item-${index}`} onClick={this.clickItems.bind(this, index)}>{item.name}</Item>
                        }
                    })
                }
                <SubText>*告诉我们哪里有问题，至少5字哦</SubText>
                <ReportInput placeholder="请输入您的问题" onChange={(e) => this.setReason(e.target.value)}></ReportInput>
                <SubText>*上传截图，最多上传3张，每张5M内</SubText>
                {
                    this.state.images.length < 3 && <UpImg onClick={this.handleClick}><i className="iconfont">&#xe7b0;</i></UpImg>
                }
                {
                    this.state.images?.map((item, index) => {
                        return <UpImg><img src={item} key={`image-${index}`} /></UpImg>
                    })

                }
                <ClearFloat />
                <input type="file" accept="image/*" hidden ref={ref => this.input = ref} onChange={this.handleSelect} />
                <SubText>*验证码</SubText>
                <ReportCode>
                    <input placeholder="请输入验证码" onChange={(e) => this.setCode(e.target.value)}></input>
                    <span>{this.state.code}</span>
                </ReportCode>
                <SubText>*请留下您的联系方式，方便回访</SubText>
                <ReportContacts placeholder="请输入您的联系方式" onChange={(e) => this.setPhone(e.target.value)}></ReportContacts>
                <ReportButton onClick={this.submitReport}>提交</ReportButton>
                <ClearFloat />
            </ReportWrapper>
        )
    }
}

export default ReportComments