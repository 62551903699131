import styled from 'styled-components';

export const CommunityWrapper = styled.div`
  width:100%;
  margin:0 auto;
  background-image: url(${require("../../images/signInBG.png")});
  background-size: 100% auto;
  background-repeat: repeat-x;
  padding-top: 10px;
  .go-back{
    margin-left:4%;
    margin-top:50px;
    display: flex;
    >a{
        height:1.375rem;
        width: 1.375rem;
        display: flex;
        margin-right: 8px;
        align-items: center;
        margin-top: -1px;
    }
    >span{
       line-height: 1.375rem;
       font-size: 22px;
       font-weight: 500;
       color: #fff;
    }
  }
  .go-back-flex{
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 4px;
    z-index:999;
	  border-bottom:0.03125rem #D6D6D6 solid;
    .buchong{
      >a{
        >img{
          margin-left: -7px;
        }
      }
      >span{
        color: #000;
      }
    }
  }
  .top-jifen{
    margin: 36px 4% 0;
    font-size: 42px;
    font-weight: 500;
    color: #fff;
    >span{
        font-size: 14px;
        margin-left: 12px;
        font-weight: 400;
    }
  }
  
`
export const TopBox = styled.div`
  width: 92%;
  height: 170px;
  border-radius: 12px;
  padding: 12px 15px;
  margin: 8px 4% 0;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  >span{
    color: #7C8698;
    font-size: 12px;
    font-weight: 500;
  }
  .sign-in-state{
    margin-top: 5px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    .not-sign-in{
      width: 40px;
      height: 60px;
      border-radius: 6px;
      background-color: #F8F8F8;
      >img{
        width: 24px;
        height: 24px;
        margin: 8px 8px 5px;
      }
      >span{
        display: block;
        color: #9B9B9B;
        text-align: center;
      }
    }
    .stateOK{
      background-color: #F7C700 !important;
      >img{
        box-shadow: 0 2px 2px 0 rgba(220,177,0,0.2);
      }
      >span{
        color: #FCF8BE !important;
      }
    }
  }
  .sign-in-button{
    width: 225px;
    height: 45px;
    border-radius: 22.5px;
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .disabled {
    opacity: 0.6;
  }
`
export const TaskTitle = styled.div`
  width: 92%;
  display: flex;
  justify-content: space-between;
  margin: 12px 4% 10px;
  .title{
    color: #262626;
    font-size: 18px;
    font-weight: 600;
    >div{
      width: 4px;
      height: 17px;
      border-radius: 2px;
      background: #F69E00;
      float: left;
      margin-right: 8px;
    }
  }
  .illustrate{
    display: flex;
    align-content: center;
    color: #F79D00;
    >i{
      display: block;
      font-size: 20px;
      margin-right: 3px;
    }
    >span{
      display: block;
      line-height: 20px;
      font-size: 14px;
    }
  }
`
export const TaskBox = styled.div`
  width: 92%;
  border-radius: 12px;
  padding: 4px 15px;
  margin: 8px 4% 0;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  >img{
    width: 100%;
    margin: 8px auto;
  }
  .task-sub-title{
    font-size: 16px;
    font-weight: 600;
    color: #203152;
    margin-top: 16px;
  }
  .task{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    border-bottom: #EEEEEE 0.5px solid;
    .task-head-img{
      border-radius: 50%;
      overflow: hidden;
      width: 38px;
      height: 38px;
      display:flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      >img{
        width: 100%;
        min-height: 38px;
        object-fit: cover;
      }
    }
    .task-text{
      flex: 1;
      .task-name{
        margin-bottom: 4px;
        display: flex;
        >span{
          font-size: 14px;
          color: #203152;
          line-height: 20px;
          margin-right: 8px;
        }
      }
      .task-jifen{
        height: 20px;
        background-image: url(${require("../../images/jifenlist.png")});
        background-size: 77px 20px;
        background-repeat: no-repeat;
        padding-left: 24px;
        color: #F79D00;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }
      .task-name-sub{
        font-size: 12px;
        color: #7C8698;
      }
    }
    .task-button{
      width: 63px;
      height: 26px;
      background-color: #F79D00;
      border-radius: 13px;
      line-height: 26px;
      text-align: center;
      color: #FCF8BE;
      font-size: 12px;
    }
    .task-button-lingjiang{
      background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
      color: #fff;
    }
    .task-button-lingjiang-over{
      background-color: #D6D6D6;
      color: #fff;
    }
  }
  `