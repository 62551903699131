/**
 * created by zhangling on 2019/12/21
 */
import axios from 'axios';
import {
    message
} from 'antd';
const loading = () => (message.loading('加载中', 100));
let hide = null;
// http request 拦截器
axios.interceptors.request.use(
    config => {
        let token = sessionStorage.getItem('MANAGEMENT_LOGIN_TOKEN'),
            token_correct = sessionStorage.getItem('MANAGEMENT_LOGIN_TOKEN_CORRECTNESS');
        if (token && token_correct) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.LoginToken = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const httpAxiosOss = (apiMoudle, data) => {
    if (!hide) {
        //开始展示加载条
        hide = loading();
    }
    let options = {
        url: apiMoudle.url,
        method: apiMoudle.method,
        dataType: apiMoudle.dataType || '',
        headers: {},
    };
    if (apiMoudle.method !== 'get') {
        options.headers['Accept'] = 'application/json';
        options.headers['Content-Type'] = 'application/json;charset=UTF-8';
        if (apiMoudle.dataType === 'formdata') {
            options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            // options.headers['Content-Type'] = 'application/json; application/octet-stream';
            // options.responseType = 'arraybuffer';
        }
        options.data = data;
    }
    // for formdata submitting
    if (apiMoudle.dataType === 'formdata') {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if (apiMoudle.method === 'get' && data !== undefined && data) {
        options.params = data;
    }
    if (apiMoudle.method === 'get/excel') {
        options.responseType = 'arraybuffer';
        options.headers['Content-Type'] = 'application/json; application/octet-stream';
        options.params = data;
        options.method = 'get';
    }
    return new Promise((resolve, reject) => {
        axios(options).then((res) => {
            /** 这里只要后端返回了错误状态 便不会进到then逻辑里面
             * 需要在catch里面根据不同的错误码做对应的错误处理
             * 这里也要做处理 有时候返回status状态为200
             * */
            if (res.status === 200) {
                resolve(res.data);
            } else {
                resolve(res.Message);
            }
            //加载结束
            hide();
            hide = null;
        }).catch((e) => {
            // note 这里因为后台返回了具体报错 需要给到前端处理
            reject(e.response);
            if (hide) {
                hide();
                hide = null
            }
        })
    })
}


export default httpAxiosOss;