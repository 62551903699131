import React, {
    Component
} from 'react';
import {
    CommunityWrapper,
    ExhibitionWrapper,
    ExhibitionTitle,
    ExhibitionZhinan,
    ExhibitionList,
    ExhibitionListImg,
    ExhibitionIcon,
    NewsPub,
    ExhibitionListIcon
} from './style';

import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

import ReactDOM from 'react-dom';
import AutoResponsive from 'autoresponsive-react'
import moment from 'moment';

const playSvg = require('../../images/exhibition_icon_play.svg');
const imgSvg = require('../../images/exhibition_icon_img.svg');
const imgDefault = require('../../images/damage.png')

const NUM_ROWS = 6;
let pageIndex = 1;

class ExhibitionDetailList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userPID: '',

            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,
            data: [],
            targetID: '0',
            hasMore: true,
            rowCount: 0,
            signDate: "",
            buttonIcon: ''
        };
    }
    componentDidUpdate() {
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(localStorage.getItem("buttonIcon"));
        }
    }
    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            // alert(data.UserPID);
            that.setState({
                userPID: data.UserPID
            },
                () => that.getExhibitionUserSignDate()
            )
        }

        this.getList();
        window.addEventListener('scroll', this.handleScroll);
    }

    getList = async () => {
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "targetID": this.props.match.params.id
        }

        const res = await httpAxios(api.getShowingProducts, params);
        if (res) {
            this.state.rowCount = res.Data.products.totalCount;

            if (res.Data.products.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data.products,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data.products];

                    this.setState({
                        data: tempData,
                    })
                }
            }
            else {
                this.state.hasMore = false;
            }
        }

        this.setState({ isLoading: false });
    }

    handleScroll = (e) => {

        if (this.getScrollTop() + this.getWindowHeight() + 100 > this.getScrollHeight()) {
            if (this.state.isLoading == false && this.state.hasMore) {
                this.setState({ isLoading: true });
                pageIndex++;
                this.getList();
            }
        }
    }

    getScrollTop = () => {
        var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
        if (document.body) {
            bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
            documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
        return scrollTop;
    }

    getScrollHeight = () => {
        var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
        if (document.body) {
            bodyScrollHeight = document.body.scrollHeight;
        }
        if (document.documentElement) {
            documentScrollHeight = document.documentElement.scrollHeight;
        }
        scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
        return scrollHeight;
    }

    getWindowHeight = () => {
        var windowHeight = 0;
        if (document.compatMode == "CSS1Compat") {
            windowHeight = document.documentElement.clientHeight;
        } else {
            windowHeight = document.body.clientHeight;
        }
        return windowHeight;
    }

    getAutoResponsiveProps() {
        return {
            itemMargin: 15,//布局元素间距(对下外边距)
            containerWidth: window.screen.width,//容器总体宽度
            itemClassName: 'item',//布局元素选择器
            gridWidth: window.screen.width / 2,//栅格宽度，栅格宽度值会影响布局的精确度，同时也会影响渲染效率
            transitionDuration: '0'//设置动画持续时间
        };
    }

    //展会签到
    getExhibitionUserSignDate = async () => {
        let params;
        params = {
            "userID": this.state.userPID,
            "ExID": this.props.match.params.id,
        }

        const res = await httpAxios(api.getExhibitionUserSignDate, params);
        if (res) {
            this.setState({ signDate: res.Data });
            // alert(res.Data)
        }
    }

    setExhibitionUserSign = async () => {
        let params;
        params = {
            "userID": this.state.userPID,
            "ExID": this.props.match.params.id,
        }

        const res = await httpAxios(api.setExhibitionUserSign, params);
        if (res) {
            this.setState({ signDate: res.Data });
            // alert(res.Data)
        }
    }

    render() {
        const {
            data,
            signDate,
            userPID
        } = this.state;
        return (
            <CommunityWrapper>
                <ExhibitionTitle>
                    <span>{this.props.match.params.title}</span>
                    <div className='mindiv' />
                    {/* {
                        (userPID != "" && this.props.match.params.id == 51) ?
                            signDate == "" ? <div onClick={() => this.setExhibitionUserSign()} >点击签到</div> : <div>{moment(signDate).format('DD日 HH:mm ')}已签到</div>
                            : <div className='mindiv' />
                    } */}
                </ExhibitionTitle>
                {/* {
                    this.props.match.params.id == 51 &&
                    <ExhibitionZhinan onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav",title: '线下展参观指南',  weburl: '/community/communitydetail/473/exhibition' + '?' + userPID, })); }}>
                        <div>线下展参观指南</div><i className='iconfont'>&#xe744;</i>
                    </ExhibitionZhinan>
                } */}
                <ExhibitionWrapper>
                    <AutoResponsive ref="container" {...this.getAutoResponsiveProps()} >
                        {data.map((item, index) => {
                            // let img = new Image();
                            // img.src = item.MainPicLandScape;
                            let style = {
                                width: '46%',
                                //height: img.height * (window.screen.width * 0.46 / img.width) + 130,
                                height: item.ImageScale == '43' ? 270 : item.ImageScale == '11' ? 315 : 375,
                            };

                            return (
                                <ExhibitionList style={style} onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: item.CompanyName, weburl: '/community/communitydetail/' + item.ProductID + '/exhibition' + '?' + this.state.userPID, })); }}>
                                    {/* <ExhibitionListImg style={item.ImageScale == '43' && {height:'8.5rem'}}> */}
                                    <ExhibitionListImg style={{ height: '8.5rem' }}>
                                        {item.MainPicLandScape != "" ? <img src={item.MainPicLandScape + '?x-oss-process=image/resize,w_330,m_lfit'} alt="" /> : <img src={imgDefault + '?x-oss-process=image/resize,w_330,m_lfit'} alt="" />}
                                        <span />
                                        <ExhibitionIcon>
                                            {item.ProductVideo != "" && <img src={playSvg} alt="" />}
                                            {item.ProductVideo == "" && <img src={imgSvg} alt="" />}
                                        </ExhibitionIcon>
                                    </ExhibitionListImg>
                                    <span>{item.ProductName}</span>
                                    <NewsPub>
                                        <div><img src={item.BussLogo + '?x-oss-process=image/resize,w_40,m_lfit'} alt="" /></div><span>{item.CompanyName}</span>
                                    </NewsPub>
                                    <ExhibitionListIcon>
                                        <i className="iconfont">&#xe753;</i>
                                        <span>{item.ClickNum}</span>
                                        <div>|</div>
                                        <i className="iconfont">&#xe755;</i>
                                        <span>{item.LikeNum}</span>
                                        <div>|</div>
                                        <i className="iconfont">&#xe7b2;</i>
                                        <span>{item.CommentNum}</span>
                                    </ExhibitionListIcon>
                                </ExhibitionList>

                            )
                        })}
                    </AutoResponsive>
                </ExhibitionWrapper>
                <div style={{ padding: 30, textAlign: 'center' }}>
                    {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                </div>
            </CommunityWrapper>
        )
    }
}

export default ExhibitionDetailList