import styled from 'styled-components';

export const CommunityWrapper = styled.div`
  width:100%;
  margin:0 auto;
  background: #fff;
`
export const TopDownload = styled.div`
  width:100%;
  line-height:3.125rem;
  height: 3.125rem;
  background-color: #fff;
  position: fixed;
  top:0;
  z-index:999;
  >img{
    width: 7.75rem;
    height: 2.375rem;
    float: left;
    margin-left: 4%;
    margin-top: 0.375rem;
  }
  div{
    width:5rem;
    height:1.75rem;
    line-height:1.75rem;
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    border-radius: 4px;
    font-size:0.875rem;
    text-align:center;
    color:#fff;
    border-radius:0.25rem;
    float:right;
    margin-right: 4%;
    margin-top: 0.6875rem;
  }
`
export const ExhibitionWrapper = styled.div`
  width:100%;
  /* margin:0 4%; */
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  //-moz-column-count:2; /* Firefox */
  //-webkit-column-count:2; /* Safari 和 Chrome 
  //column-count:2;
  //-moz-column-gap: 4%;
  //-webkit-column-gap: 4%;
  //column-gap: 4%; 
`
export const ExhibitionTitle = styled.div`
  width:92%;
  margin:0 4%;
  text-align: center;
  padding-top: 1rem;
  line-height: 2rem;
  span{
    font-size: 1.5rem;
    color: #203152;
  }
  div{
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    border-radius: 0.22rem;
    width: 10.375rem;
    height: 2.125rem;
    margin:1rem auto;
    text-align:center;
    color:#fff;
    font-size: 1rem;
  }
  .mindiv{
    width: 5.375rem;
    height: 0.437rem;
  }
`
export const ExhibitionZhinan = styled.div`
  border-bottom: #F0F0F0 1px solid;
  height: 40px;
  margin-bottom:20px; 
  display: flex;
  justify-content: center;
  width: 92%;
  margin-left: 4%;
  div{
    color: #7C8698;
    padding-top: 11px;
    font-size: 1rem;
  }
  i{
    color: #7C8698;
    padding-top: 10px;
    font-size: 1.3rem;
  }
`
export const ExhibitionList = styled.div`
  //width: 100%;
  //width: 48%;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  margin-left: 2%;
  >span{
    display: block;
    width: 92%;
    height: 2.3rem;
    margin: 0 auto;
    color: #00222C;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0.2rem;
  }
`
export const ExhibitionListImg = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 0.625rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  >img{ 
    width:100%;
    min-height: 8.5rem;
    object-fit: cover;   //对图片进行剪切
 }
  >span{
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.7rem;
    opacity: 0.6;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
  }
`
export const ExhibitionIcon = styled.div`
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  img{
    width: 2.6rem;
  }
`
export const NewsPub = styled.div`
  width:92%;
  height: 2rem;
  line-height:2rem;
  margin-left: 4%;
  >div{
    display: inline-block;
    width: 18%;
    >img{
      width:1.25rem;
      height:1.25rem;
      border-radius:50%;
      vertical-align:middle;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }
  }
  >span{
    width:82%;
    display: inline-block;
    font-size:0.75rem;
    color: #7C8698;
    vertical-align:middle;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
`
export const ExhibitionListIcon = styled.div`
  width: 92%;
  height: 1rem;
  margin:1rem 0 0;
  margin-left: 4%;
  text-align: right;
  i{
    font-size: 1.1rem;
    color: #828294;
  }
  span{
    font-size: 0.75rem;
    color: #828294;
  }
  div{
    display: inline-block;
    font-size: 1rem;
    color: #828294;
    margin:0 0.2rem;
  }
`
