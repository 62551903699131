import React, {
    Component
} from 'react';
import { List } from 'antd-mobile-v2';
import moment from 'moment';
import {
    SettingsWrapper,
} from '../MycenterPortraitStyle';
import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";

import {
    chinaTime
} from '../../../../utils/utils';

const Item = List.Item;
const Brief = Item.Brief;

class RenZheng extends Component {
    state = {
        userId: '',
        type: 0,
        buttonclicked: '',
        endTime: '',
        AuthorizationStatus: false,
        buttonOnClick: true
    };

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            //alert(JSON.stringify(data))
            that.setState({
                userId: data.UserPID
            })
            that.getCheckAuthorization(data.UserPID);
        };
    }

    componentDidUpdate() {
        var that = this;
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        // window.saveInfo = function () {
        //     window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "查看对公转账信息", weburl: "/DuiGong?Button" }))
        // }
    }

    getCheckAuthorization = async (id) => {
        const res = await httpAxios(api.getCheckAuthorization, {
            userPID: id
        })
        if (res) {
            let info = res.Data;
            if (res.Data != "") {
                //alert(info.CompanyType1);
                if (info.CompanyType1 == null && info.UserBID != "00000000-0000-0000-0000-000000000000") {
                    this.setState({
                        type: 1
                    })
                } else if (info.CompanyType1 != null && info.UserBID != "00000000-0000-0000-0000-000000000000") {
                    this.setState({
                        type: 2
                    })
                } else {
                    this.setState({
                        type: 0
                    })
                }
                // alert(JSON.stringify(info));
                if (info.AuthorizationStatus == 1) {
                    this.setState({
                        buttonclicked: '未认证',
                    })
                } else if (info.AuthorizationStatus == 2) {
                    this.setState({
                        buttonclicked: '认证中',
                        buttonOnClick: false
                    })
                } else if (info.AuthorizationStatus == 3) {
                    this.setState({
                        buttonclicked: moment(info.VipExpireTime).diff(moment(), 'days') < 90 ? '即将过期' : '已通过',
                        endTime: '到期时间' + chinaTime(info.VipExpireTime, 'day'),
                        AuthorizationStatus: true,
                        buttonOnClick: moment(info.VipExpireTime).diff(moment(), 'days') < 90 ? true : false
                    })
                } else if (info.AuthorizationStatus == 4) {
                    this.setState({
                        buttonclicked: '未通过'
                    })
                } else {
                    this.setState({
                        buttonclicked: '已过期'
                    })
                }
            }
        }
    }

    render() {
        const {
            buttonclicked,
            type,
            endTime,
            AuthorizationStatus,
            buttonOnClick
        } = this.state;
        return (
            <SettingsWrapper>
                <List renderHeader={() => ''}>
                    {
                        type != 2 && <Item className='listheight' extra={buttonclicked} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '身份认证', weburl: '/StarRenZheng' })); }}>身份认证</Item>
                    }
                    {
                        type != 1 && <Item className={!AuthorizationStatus ? 'listheight' : 'listheights'} extra={buttonclicked} arrow="horizontal" onClick={() => { buttonOnClick && window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '企业认证', weburl: '/EnterpriseRenZheng' })); }}>
                            企业认证
                            {AuthorizationStatus && <Brief>{endTime}</Brief>}
                        </Item>
                    }
                </List>
                {type != 1 && <div style={{ width: '100%', color: '#015FEC', position: 'absolute', bottom: '3rem', display: 'flex', justifyContent: 'center' }} onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "查看对公转账信息", weburl: "/DuiGong?Button" })) }}><i style={{ fontSize: '1.2rem' }} className="iconfont">&#xe768;</i><span style={{ lineHeight: '1.2rem' }}>查看对公转账信息</span></div>}
            </SettingsWrapper>
        )
    }
}

export default RenZheng;