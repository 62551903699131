import React, {
    Component, Fragment
} from 'react';

import {
    Wrapper,
    CountryList,
    FilterButton,
    CountryBorder,
    BrandsListCn,
    BrandsListEn,
    BrandsListImg,
    MenuTitle,
    SubTitle,
    BrandAndProduct,
    BrandAndProductWrapper
} from './style';
import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';
import { Popup } from 'antd-mobile';
import { Popover, Modal } from 'antd-mobile-v2';
import {
    message
} from 'antd';

const prompt = Modal.prompt;
const Item = Popover.Item;

class MyBrandsCenter extends Component {
    constructor() {
        super();
        this.state = {
            popoverOn: false,
            // selected: '',
            visible: false,
            isTouching: false,
            lastChar: 'A',
            country: [],
            charStr: [],
            dataBrandItems: [],
            userPID: '',
            showAll: false,
            isAll: true,
            saveLinkBrandID: 0,
            saveLinkData: '',
            showLink: 0,
            brandCount: 0,
            productCount: 0,
        }

        // this.isTouching = false;
        this.boxClientTop = 148;
        // this.lastChar = 'A';
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.getChar = this.getChar.bind(this);
        this.gotoChar = this.gotoChar.bind(this);
    }
    componentDidUpdate() {
        var that = this;
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "新增产品关联", buttonTitle: "没有找到要关联的产品", weburl: "/MyBrandsCenter/AddBrands" }))
        }
    }


    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {

            that.setState({
                userPID: data.UserPID
            }, () => {
                that.getBrands();
            })
        }

        //D2DE406E-4BE2-42C2-82A9-6CFE27CD0BB7
        // this.setState({
        //     userPID: 'D2DE406E-4BE2-42C2-82A9-6CFE27CD0BB7'
        // }, () => {
        //     that.getBrands();
        // })
    }

    onSelect = (opt) => {
        this.setState({
            popoverOn: false,
            // selected: opt.props.value,
        });
        this.onShowLink(opt.props.value)
    };
    handleVisibleChange = (popoverOn) => {
        this.setState({
            popoverOn,
        });
    };
    touchStart(e) {
        // e.preventDefault();
        // this.isTouching = true;
        this.setState({ isTouching: true });
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchMove(e) {
        e.preventDefault();
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchEnd(e) {
        e.preventDefault();
        this.setState({ isTouching: false });
    }
    getChar(clientY) {
        const charHeight = this.refs.charBar.offsetHeight / this.state.charStr.length;
        const index = Math.floor((clientY - this.boxClientTop) / charHeight);
        return this.state.charStr[index];
    }
    gotoChar(char) {
        if (char === this.lastChar) {
            return false;
        }
        this.setState({ lastChar: char });
        // this.lastChar = char;
        if (char === '*') {
            this.refs.countryList.scrollTop = 0;
        } else if (char === '#') {
            this.refs.countryList.scrollTop = this.refs.countryList.scrollHeight;
        }
        const target = document.querySelector('[data-en="' + char + '"]');

        if (target) {
            // target.scrollIntoView();
            window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
        }
    }

    getBrands = async () => {
        const res = await httpAxios(api.getCompanyBruandsApp, { userBID: this.state.userPID });
        if (res) {
            this.setState(
                {
                    charStr: res.Data.Letters,
                    country: res.Data.Data,
                    brandCount: res.Data.brandCount,
                    productCount: res.Data.productCount
                }
            );
        }
    }
    getCompanyProducts = async (brandID) => {
        const res = await httpAxios(api.getCompanySelectProducts, {
            userBID: this.state.userPID,
            brandID: brandID,
            link: this.state.showLink
        })
        if (res) {
            this.setState({
                dataBrandItems: res.Data,
            })
        }

        this.setState(
            {
                showAll: false,
                isAll: false,
                brandID: brandID
            }
        )
    }
    getCompanyProductsAll = async (brandID) => {
        const res = await httpAxios(api.getCompanySelectProductsAll, {
            userBID: this.state.userPID,
            link: this.state.showLink
        })
        if (res) {
            this.setState({
                dataBrandItems: res.Data,
                showAll: true,
                isAll: true
            })
        }
    }

    onShowLink = (value) => {
        this.setState({
            showLink: value
        }, async () => {
            if (this.state.isAll) {
                await this.getCompanyProductsAll();
            }
            else {
                await this.getCompanyProducts(this.state.brandID);
            }
        })
    }

    saveLink = async (brandID, link) => {
        this.setState({
            saveLinkBrandID: brandID,
            saveLinkData: link,
        })

        if (link == null || link == "") {
            link = 'http://';
        }

        prompt('请输入该商品的购买链接', '例如：http://www.51audio.com', [
            { text: '取消' },
            {
                text: '提交', onPress: value => {
                    if (value.length < 16) {
                        message.warn('您添加的产品购买链接太短！');
                        return;
                    }

                    this.saveLinkData(value);
                }
            },
        ], 'default', link);
        this.setState({
            visible: false
        })
    }

    saveLinkData = (link) => {

        //保存数据
        //   this.addCompanyExperience(data);
        const res = httpAxios(api.saveCompanyProductLink, {
            "UserBID": this.state.userPID,
            "ID": this.state.saveLinkBrandID,
            "Link": link
        })
        if (res) {
            message.info('您添加的产品购买链接提交成功！');
        }

        let tempData = [];
        for (let index = 0; index < this.state.dataBrandItems.length; index++) {

            const element = this.state.dataBrandItems[index];

            for (let index1 = 0; index1 < element.include.length; index1++) {
                const element1 = element.include[index1];

                if (element1.ID == this.state.saveLinkBrandID) {
                    element1.Link = link;
                }
            }

            tempData.push(element);
        }

        this.setState({ dataBrandItems: tempData });
    }

    deleteProduct = async (pid) => {
        const res = await httpAxios(api.addCompanyProduct, {
            userBID: this.state.userPID,
            ProductID: pid,
            Checked: false
        })
        if (res) {
            if (this.state.showAll == true) {
                await this.getCompanyProductsAll();
            } else {
                await this.getCompanyProducts(this.state.brandID);
            }
        }
    }


    render() {
        const {
            country,
            charStr,
            visible,
            popoverOn,
            dataBrandItems,
            showLink,
            brandCount,
            productCount
        } = this.state;
        return (
            <Wrapper>
                <CountryList>
                    <FilterButton>
                        已关联{brandCount}个品牌
                    </FilterButton>
                    <div style={{ height: '3.75rem' }}></div>
                    <CountryBorder ref="countryList" >
                        {
                            country.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <a onClick={() => {
                                            this.setState({ isAll: false }, async () => {
                                                await this.getCompanyProducts(item.ID);
                                            })
                                            this.setState({
                                                visible: true
                                            })
                                        }}>
                                            {item.First && <div data-en={item.Letter}></div>}
                                            <div
                                                className={item.First === true ? "title" : 'brandsList'}
                                            >
                                                {!item.First && <BrandsListImg><img src={item.BrandLogo} alt="" /></BrandsListImg>}
                                                <BrandsListEn>{item.BrandName}</BrandsListEn>
                                                {!item.First && <BrandsListCn>{item.BrandCountry}</BrandsListCn>}
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }
                        <div className="char-list-border">
                            <ul
                                className="char-list"
                                ref="charBar"
                                onTouchStart={this.touchStart}
                                onTouchMove={this.touchMove}
                                onTouchEnd={this.touchEnd}
                            >
                                {
                                    charStr.map((char, index) => {
                                        return (
                                            <li className="char-item" key={index}>{char}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        {this.state.isTouching && <div className="char-tip">{this.state.lastChar}</div>}
                    </CountryBorder>
                    <Popup
                        visible={visible}
                        onMaskClick={() => {
                            this.setState({
                                visible: false
                            });
                            this.onShowLink(0)
                        }}
                        position='right'
                        bodyStyle={{ minWidth: '80vw', maxWidth: '80vw' }}
                    >
                        <MenuTitle>
                            共关联了{productCount}个产品
                            <Popover mask
                                overlayClassName="fortest"
                                overlayStyle={{ color: 'currentColor' }}
                                visible={popoverOn}
                                overlay={[
                                    (<Item key="4" value="0"><div>全部</div></Item>),
                                    (<Item key="5" value="1"><div>已添加商品链接</div></Item>),
                                    (<Item key="6" value="2"><div>未添加商品链接</div></Item>),
                                ]}
                                align={{
                                    overflow: { adjustY: 0, adjustX: 0 },
                                    offset: [-10, 0],
                                }}
                                onVisibleChange={this.handleVisibleChange}
                                onSelect={this.onSelect}
                            >
                                <span>筛选<i className='iconfont'>&#xe7b6;</i></span>
                            </Popover>
                        </MenuTitle>
                        <BrandAndProductWrapper>
                        {dataBrandItems.map(item => (
                            <Fragment key={item.lable}>
                                <SubTitle>
                                    {item.lable}
                                </SubTitle>
                                {
                                    item.include.map(subItem => (
                                        <BrandAndProduct key={subItem.ID}>
                                            <div>
                                                <div>
                                                    {subItem.ProductName}
                                                </div>
                                                <span>
                                                    {subItem.Link != null ? subItem.Link.length > 0 && <i
                                                        className='iconfont'
                                                        onClick={() => {
                                                            this.saveLink(subItem.ID, subItem.Link);
                                                            this.onShowLink(0)
                                                        }}
                                                    >&#xe769;</i> :
                                                        <i
                                                            className="iconfont"
                                                            style={{ opacity: '0.5' }}
                                                            onClick={() => {
                                                                this.saveLink(subItem.ID, subItem.Link);
                                                                this.onShowLink(0)
                                                            }}
                                                        >&#xe769;</i>}
                                                    <i className='iconfont' onClick={() => this.deleteProduct(subItem.ID)}>&#xe748;</i>
                                                </span>
                                            </div>
                                            <img src={subItem.MainPicLandScape} alt="" />
                                        </BrandAndProduct>
                                    ))
                                }
                            </Fragment>
                        ))}
                        </BrandAndProductWrapper>
                    </Popup>
                </CountryList >
            </Wrapper >
        )
    }
}

export default MyBrandsCenter