import React, {
    Component
} from 'react';
import {
    CommunityDetailWrapper,
    ContentWrapper,
    ClickNum,
    Focused,
    Inform,
    TopDownload,
    Title,
    Author,
    Content,
    QuickReply,
    QuickReplyInputH5,
    QuickReplyOperation,
    CommentWrapper,
    CommentWrappers,
    CommentBox,
    CommentBoxLeft,
    CommentBoxRights,
    ExhibitionBuy
} from './CommunityDetailStyle';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleNoImg,
    NewsTitleOneImg,
    NewsPub,
    VideoImg,
    VideoIcon
} from '../../common/generalStyle';
import httpAxios2 from "../../http/httpAxios2";
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";
import {
    filterVideoSrc,
    scienceNumber,
    startMinsTime
} from '../../utils/utils';
import {
    Link
} from "react-router-dom";
import ReportBox from '../../common/reportBox';
import {
    message
} from 'antd';
import axios from 'axios';
import { Toast } from 'antd-mobile-v2'

const h5logo = require('../../images/h5logo.png');
const playSvg = require('../../images/plays.svg');

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

class CommunityDetailH5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoIsBusiUser: false,
            infoId: this.props.match.params.id,
            infoAuthorID: '',
            infoTitle: '',
            infoListIMG: '',
            infoAddTime: '',
            infoContentNews: '',
            infoImageAppUrl: '',
            infoAuthor: '',
            infoHeadIMG: '',
            infoVideoSrc: '',
            infoClickNum: 0,
            infoLikeNum: 0,
            infoCollectionNum: 0,
            infoCommNum: 0,

            userId: this.props.location.search.replace('?', ''),//从网址中取栏目类型
            type: this.props.match.params.type,
            freshComments: [],
            hotComments: [],
            reportComment: '',
            freshCommentsPageIndex: 1,
            freshCommentsTotal: 0,

            focused: false,
            openBlock: false,
            isLiked: false,
            isCollected: false,

            commentText: '',
            commentTextFocus: false,
            commentData: [],
            ImgLinks: [],
            EXID: 0,
            Brands: [],
            TypeName: '',
            ProductLink: '',

            ListData: []
        }
    }

    componentWillReceiveProps(next) {

        if (this.state.infoAuthorID) {
            this.checkFocus(this.state.infoAuthorID);
            this.checkBlock(this.state.infoAuthorID);
        }
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
        }
        document.oncontextmenu = () => false;
        if (this.state.type === "community") {
            this.getComInfo();
        } else if (this.state.type === "news") {
            this.getNewsInfo();
        } else if (this.state.type === "evaluation") {
            this.getEvalInfo();
        } else if (this.state.type === "audition") {
            this.getAuditionInfo();
        } else if (this.state.type === "lookback") {
            this.getLookBack();
        } else if (this.state.type === "exhibition") {
            this.getExProductInfo();
        }
        // this.getClickAndCollection();
        this.getCommonCommentList();
        this.getShareList();
    }

    //刷新内容
    // refresh = async (typeid) => {
    //     if (typeid === 5) {
    //         this.getComInfo();
    //     } else if (typeid === 1) {
    //         this.getNewsInfo();
    //     } else if (typeid === 3) {
    //         this.getEvalInfo();
    //     } else if (typeid === 4) {
    //         this.getAuditionInfo();
    //     } else if (typeid === 7) {
    //         this.getLookBack();
    //     } else if (typeid === 2) {
    //         this.getExProductInfo();
    //     }
    //     this.getClickAndCollection();
    //     this.getCommonCommentList();
    //     this.getShareList();
    // }

    //获取推荐列表
    getShareList = async (typeID, id = this.props.match.params.id) => {
        const {
            type
        } = this.state;
        if (type === "community") {
            typeID = 5;
        } else if (type === "news") {
            typeID = 1;
        } else if (type === "evaluation") {
            typeID = 3;
        } else if (type === "audition") {
            typeID = 4;
        } else if (type === "lookback") {
            typeID = 7;
        } else if (type === "exhibition") {
            typeID = 2;
        }
        const res = await httpAxios(api.getShareList, {
            id: id,
            type: typeID
        });
        if (res) {
            this.setState({
                ListData: res.Data,
            })
        }
    }

    //根据文字ID和类型获取评论列表
    getCommonCommentList = async (typeID, id = this.props.match.params.id, PageIndex = 1, PageSize = 100) => {
        const {
            type,
            userId
        } = this.state;
        let _userId = '00000000-0000-0000-0000-000000000000';
        if (userId != '') _userId = userId;
        if (type === "community") {
            typeID = 5;
        } else if (type === "news") {
            typeID = 1;
        } else if (type === "evaluation") {
            typeID = 3;
        } else if (type === "audition") {
            typeID = 4;
        } else if (type === "lookback") {
            typeID = 7;
        } else if (type === "exhibition") {
            typeID = 2;
        }
        const res = await httpAxios2(api.getCommonCommentList, {
            TitleID: id,
            Type: typeID,
            UserID: _userId,
            PageSize: PageSize,
            PageIndex: PageIndex
        })
        if (res && res.Data) {
            this.setState({
                commentData: res.Data,
            });
        }
    }



    //获取点赞和收藏个数
    getClickAndCollection = async () => {
        if (this.state.type === "community") {
            const res = await httpAxios2(api.checkComLikeAndCollection, {
                NewsID: this.state.infoId,
                UserID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.isZan,
                    isCollected: res.Data.isColl
                })
            }
        } else if (this.state.type === "news") {
            const res = await httpAxios2(api.getNewsLikedAndCollection, {
                NewID: this.state.infoId,
                UserID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.IsLiked,
                    isCollected: res.Data.IsCollected
                })
            }
        } else if (this.state.type === "evaluation") {
            const res = await httpAxios2(api.checkEvalLikeAndCollection, {
                evalID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.zan,
                    isCollected: res.Data.isCollected
                })
            }
        } else if (this.state.type === "audition") {
            const res = await httpAxios2(api.checkExperLikeAndColl, {
                ExperienceID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.isLiked,
                    isCollected: res.Data.isCollected
                })
            }
        } else if (this.state.type === "lookback") {
            const res = await httpAxios2(api.checkVideoPlaybackCollectAndZan, {
                VideoPlaybackID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.isZan,
                    isCollected: res.Data.isColl
                })
            }
        } else if (this.state.type === "exhibition") {
            const res = await httpAxios2(api.getExLikedAndCollection, {
                TargetID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.IsLiked,
                    isCollected: res.Data.IsCollected
                })
            }
        }
    }

    componentWillUnmount() {
        document.oncontextmenu = () => true;
    }
    // 详情页数据
    getComInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.getComInfo, {
            ID: id
        })
        if (res && res.Data) {
            this.setState({
                infoIsBusiUser: res.Data.isBusiUser,
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.ListIMG,
                infoAddTime: res.Data.Addtime,
                infoContentNews: res.Data.ContentNews,
                infoImageAppUrl: res.Data.ImageAppUrl,
                infoAuthor: res.Data.AutherInfo.Author,
                infoHeadIMG: res.Data.AutherInfo.HeadIMG,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.CollectionNum,
                infoCommNum: res.Data.CommNum,
            })
        }
    }
    getNewsInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.searchNewsInfo, {
            newsID: id
        })
        if (res && res.Data) {
            this.setState({
                infoIsBusiUser: res.Data.isBusiUser,
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.PosterLandscape,
                infoAddTime: res.Data.Addtime,
                infoContentNews: res.Data.ContentNews,
                infoImageAppUrl: res.Data.ImageAppUrl,
                infoAuthor: res.Data.Author,
                infoHeadIMG: res.Data.HeadImg,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.ColNum,
                infoCommNum: res.Data.CommNum,
            })
        }
    }
    getEvalInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.getEvalInfo, {
            ID: id
        })
        if (res && res.Data) {
            this.setState({
                infoIsBusiUser: res.Data[0].isBusiUser,
                infoId: res.Data[0].ID,
                infoAuthorID: res.Data[0].UserID,
                infoTitle: res.Data[0].Title,
                infoListIMG: res.Data[0].CoverIMG,
                infoAddTime: res.Data[0].AddTime,
                infoContentNews: res.Data[0].EditContent,
                infoImageAppUrl: res.Data[0].ImageAppUrl,
                infoAuthor: res.Data[0].PNickName,
                infoHeadIMG: res.Data[0].HeadImg,
                infoClickNum: res.Data[0].ClickNum,
                infoLikeNum: res.Data[0].LikeNum,
                infoCollectionNum: res.Data[0].ColNum,
                infoCommNum: res.Data[0].CommNum,
            })
        }
    }
    getAuditionInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.getExperInfo, {
            ID: id
        })
        if (res && res.Data) {
            this.setState({
                infoIsBusiUser: res.Data.isBusiUser,
                infoId: res.Data.id,
                infoAuthorID: res.Data.PubUserID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.PosterImg,
                infoAddTime: res.Data.AddTime,
                infoVideoSrc: res.Data.VideoSrc,
                infoAuthor: res.Data.PNickName,
                infoHeadIMG: res.Data.HeadImg,
                infoClickNum: res.Data.ViewNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.CollectNum,
                infoCommNum: res.Data.CommentNum,
            })
        }
    }
    getLookBack = async (id = this.props.match.params.id) => {

        const res = await httpAxios2(api.getVideoPlayback, {
            ID: id
        })
        if (res && res.Data) {
            this.setState({
                infoIsBusiUser: res.Data.isBusiUser,
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserBID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.ImageUrl,
                infoAddTime: res.Data.AddTime,
                infoVideoSrc: res.Data.VideoSrc,
                infoAuthor: res.Data.CompanyName,
                infoHeadIMG: res.Data.HeadImg,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.ColNum,
                infoCommNum: res.Data.CommNum,
            })
        }
    }
    getExProductInfo = async (id = this.props.match.params.id) => {

        const res = await httpAxios2(api.getExProductInfo, {
            pid: id
        })
        if (res && res.Data) {
            this.setState({
                infoIsBusiUser: res.Data.isBusiUser,
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserBID,
                infoTitle: res.Data.Title,
                ImgLinks: res.Data.ImgLinks.split(','),
                infoAddTime: res.Data.AddTime,
                infoVideoSrc: res.Data.VideoSrc,
                infoAuthor: res.Data.CompanyName,
                infoHeadIMG: res.Data.CompanyLogo,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.CollNum,
                infoCommNum: res.Data.CommNum,
                infoContentNews: res.Data.Description,
                EXID: res.Data.EXID,
                Brands: res.Data.Brands,
                TypeName: res.Data.TypeName,
                ProductLink: res.Data.ProductLink,
                infoListIMG: res.Data.MainPicLandScape
            })
        }
    }


    //增加和取消收藏
    toggleCollect = async () => {
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能收藏文章');
            return
        }
        const {
            isCollected,
            type,
            userId
        } = this.state;
        if (type === "community") {
            let url = isCollected ? api.removeComCollection : api.addComCollection;
            const res = await httpAxios2(url, {
                "CommunityID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "news") {
            let url = isCollected ? api.cancelNewsColletion : api.addNewsCollection;
            const res = await httpAxios2(url, {
                "NewID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "evaluation") {
            let url = isCollected ? api.removeEvalCollection : api.addEvalCollection;
            const res = await httpAxios2(url, {
                "EvalID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "audition") {
            let url = isCollected ? api.removeExperCollection : api.addExperCollection;
            const res = await httpAxios2(url, {
                "ExperID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "lookback") {
            let url = isCollected ? api.deleteVideoPlaybackCollection : api.addVideoPlaybackCollection;
            const res = await httpAxios2(url, {
                "VideoPlaybackID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "exhibition") {

            if (isCollected) {
                const res = await httpAxios2(api.removeExCollection, {
                    "targetID": this.props.match.params.id,
                    "UserID": userId,
                });
                if (res) {
                    this.setState({
                        isCollected: !isCollected,
                        infoCollectionNum: res.Data.split('：')[1]
                    })
                }
            } else {
                const res = await httpAxios2(api.addExCollection, {
                    "ProductID": this.props.match.params.id,
                    "UserID": userId,
                    "ExhibitionID": this.state.EXID
                });
                if (res) {
                    this.setState({
                        isCollected: !isCollected,
                        infoCollectionNum: res.Data.split('：')[1]
                    })
                }
            }

        }
    }
    //增加和取消点赞
    toggleLike = async () => {
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能点赞文章');
            return
        }
        const {
            isLiked,
            type
        } = this.state;

        if (type === "community") {
            let url = isLiked ? api.removeComZan : api.addComZan;
            const res = await httpAxios2(url, {
                "CommunityID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "news") {
            let url = isLiked ? api.cancelNewsZan : api.clickNewsZan;
            const res = await httpAxios2(url, {
                "NewID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "evaluation") {
            let url = isLiked ? api.removeEvalLike : api.addEvalLike;
            const res = await httpAxios2(url, {
                "EvalID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "audition") {
            let url = isLiked ? api.removeExperLike : api.addExperLike;
            const res = await httpAxios2(url, {
                "ExperienceID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "lookback") {
            let url = isLiked ? api.removeVideoPlaybackZan : api.updateVideoPlaybackZan;
            const res = await httpAxios2(url, {
                "VideoPlaybackID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "exhibition") {
            if (isLiked) {
                const res = await httpAxios2(api.cancelExLike, {
                    "UserID": this.state.userId,
                    "targetID": this.props.match.params.id
                });
                if (res) {
                    this.setState({
                        isLiked: !isLiked,
                        infoLikeNum: res.Data
                    })
                }
            }
            else {
                const res = await httpAxios2(api.clickExLike, {
                    "ProductID": this.props.match.params.id,
                    "UserID": this.state.userId,
                    "ExhibitionID": this.state.EXID
                });
                if (res) {
                    this.setState({
                        isLiked: !isLiked,
                        infoLikeNum: res.Data
                    })
                }
            }
        }
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }
    //检查是否已经关注
    checkFocus = async (id) => {
        if (!id) return
        // if (!this.state.userId) return
        if (this.state.userId) {
            const res = await axios.get(api.checkUserFocus.url + '?userID=' + this.state.userId + '&focusUserID=' + id)
            if (res && res.status === 200) {
                if (res.data.Code !== 404) {
                    this.setState({
                        focused: true
                    })
                }
            }
        }
    }
    //增加关注
    addFocus = async (author) => {

        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        // alert(this.props.location.search.replace('?', ''));
        if (this.state.userId === author) {
            message.info('用户不能关注自己');
            return
        }
        const {
            focused
        } = this.state;
        if (focused) return
        const res = await httpAxios2(api.addUserFollow, {
            User1: this.state.userId,
            User2: author
        })
        if (res) {
            this.setState({
                focused: true
            })
        }
    }
    //移除关注
    removeFocus = async (author) => {
        const {
            focused
        } = this.state;
        if (!focused) return
        const res = await httpAxios2(api.removeUserFocus, {
            User1: this.state.userId,
            User2: author,
        })
        if (res) {
            this.setState({
                focused: false
            })
        }
    }

    //检查是否已经拉黑
    checkBlock = async (id) => {
        if (!id) return
        // if (!this.state.userId) return
        if (this.state.userId) {
            const res = await axios.get(api.checkUserBlack.url + '?userID=' + this.state.userId + '&blackUserID=' + id);
            if (res && res.status === 200) {
                if (res.data.Code !== 404) {
                    this.setState({
                        openBlock: true
                    })
                }
            }
        }

    }
    //增加拉黑
    addBlock = async (author) => {
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能拉黑用户');
            return
        }
        if (this.state.userId === author) {
            message.info('用户不能拉黑自己');
            return
        }
        const res = await httpAxios2(api.addToBlackList, {
            User1: this.state.userId,
            User2: author
        })
        if (res) {
            Toast.info(JSON.stringify("成功拉黑了该作者，3秒后将退出该文章"));
            setInterval(async () => {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
            }, 3000);
        }
    }
    //移除拉黑
    removeBlock = async (author) => {
        const res = await httpAxios2(api.removeBlackUser, {
            User1: this.state.userId,
            User2: author,
        });
        if (res) {
            Toast.info(JSON.stringify("成功解除拉黑"));
            this.setState({
                openBlock: false
            })
        }
    }
    //评论input 输入框获取焦点时执行
    handleInputFocus = () => {
        this.setState({
            commentTextFocus: true
        })
    }
    //发布评论
    submitComment = async () => {
        const {
            type,
            userId,
            infoId,
            commentText
        } = this.state;
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (!this.state.commentText) return
        if (type === "community") {
            const res = await httpAxios(api.addComComment, {
                "CommunityID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (!res) return
        } else if (type === "news") {
            const res = await httpAxios(api.addComment, {
                "NewsID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (!res) return
        } else if (type === "evaluation") {
            const res = await httpAxios(api.createEvalComment, {
                "EvalID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (!res) return
        } else if (type === "audition") {
            const res = await httpAxios(api.createExperComment, {
                "ExperID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (!res) return
        } else if (type === "lookback") {
            const res = await httpAxios(api.addVideoPlaybackCommon, {
                "VideoPlaybackID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (!res) return
        } else if (type === "exhibition") {
            const res = await httpAxios(api.addExComment, {
                "TargetID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (!res) return
        };

        this.getCommonCommentList();
        window.location.href = "#commentTop";
        this.setState({
            commentTextFocus: false,
            commentText: ''
        });
    }
    //评论点赞
    submitCommentLike = async (commentId, typeID) => {
        const {
            userId,
            type,
            infoId
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (type === "community") {
            typeID = 5;
        } else if (type === "news") {
            typeID = 1;
        } else if (type === "evaluation") {
            typeID = 3;
        } else if (type === "audition") {
            typeID = 4;
        } else if (type === "lookback") {
            typeID = 7;
        }
        else if (type === "exhibition") {
            typeID = 2;
        }
        const res = await httpAxios2(api.createCommonCommentLike, {
            commentID: commentId,
            type: typeID,
            userID: userId,
        })
        if (res.Code == 200) {
            this.getCommonCommentList();
        } else {
            alert(res.Message);
        }
    }
    //删除评论点赞
    deleteCommonCommentLike = async (commentId, typeID) => {
        const {
            userId,
            type,
            infoId
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (type === "community") {
            typeID = 5;
        } else if (type === "news") {
            typeID = 1;
        } else if (type === "evaluation") {
            typeID = 3;
        } else if (type === "audition") {
            typeID = 4;
        } else if (type === "lookback") {
            typeID = 7;
        }
        else if (type === "exhibition") {
            typeID = 2;
        }


        const res = await httpAxios2(api.deleteCommonCommentLike, {
            commentID: commentId,
            type: typeID,
            userID: userId,
        })
        if (res.Code == 200) {
            this.getCommonCommentList();
        } else {
            alert(res.Message);
        }
    }



    render() {
        const {
            infoIsBusiUser,
            infoId,
            infoAuthorID,
            infoTitle,
            infoListIMG,
            infoAddTime,
            infoContentNews,
            infoImageAppUrl,
            infoVideoSrc,
            infoAuthor,
            infoHeadIMG,
            infoClickNum,
            infoLikeNum,
            infoCollectionNum,
            infoCommNum,
            ListData,
            type,
            userId,
            isCollected,
            isLiked,
            focused,
            openBlock,
            commentText,
            commentTextFocus,
            commentData,
            commentDataLike,
            ImgLinks,
            Brands,
            TypeName,
            ProductLink
        } = this.state;
        // alert(JSON.stringify(ListData))
        return (
            <CommunityDetailWrapper>
                <TopDownload>
                    <img src={h5logo} alt="" />
                    {/* <a href={!isAndroid ? "https://apps.apple.com/cn/app/%E4%B8%AD%E4%B9%90%E4%B9%8B%E5%A3%B0/id1552859946" : 'https://www.51audio.com/downloadapp'} target='_blank'> */}
                    <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                        <div>打开App</div>
                    </a>
                </TopDownload>
                <div style={{ height: '3.5rem' }} />{/* 占位高度 */}
                <ContentWrapper>
                    <Title>{infoTitle}</Title>
                    <Author>
                        <a>
                            <img src={infoHeadIMG} alt="" />
                            <div>
                                <div>{infoAuthor}</div>
                                <span>{startMinsTime(infoAddTime)}发布 · {scienceNumber(infoClickNum || 0)}浏览</span>
                            </div>
                        </a>
                        {/* <a href={!isAndroid ? "https://apps.apple.com/cn/app/%e4%b8%ad%e4%b9%90%e4%b9%8b%e5%a3%b0/id1552859946" : 'https://www.51audio.com/downloadapp'} target='_blank'> */}
                        <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                            <Focused>关注</Focused>
                        </a>
                    </Author>
                    {type === "exhibition" ? <Content>
                        {Brands && <div className='exhibitionBox'>
                            <div className='exhibitionTitle'>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌</div>
                            <div className='exhibitionText'>{
                                Brands.map((b, index) => (
                                    <>
                                        {index > 0 && "，"}
                                        {b.BrandName}
                                    </>
                                ))
                            }
                            </div>
                        </div>}
                        {TypeName && <div className='exhibitionBox'><div className='exhibitionTitle'>分&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类</div><div className='exhibitionText'>{TypeName}</div></div>}
                        {infoContentNews && <div className='exhibitionBox'><div className='exhibitionTitle'>产品介绍</div><div className='exhibitionText'>{infoContentNews}</div></div>}
                        {ImgLinks.map(item => (<img src={item} alt="" />))}
                        {infoVideoSrc && <>{
                            infoVideoSrc.indexOf('?') > 0 ? <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc.slice(0, infoVideoSrc.indexOf('?'))}></video> : <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc}></video>
                        }</>}
                        {ProductLink && 
                        // <a href={!isAndroid ? "https://apps.apple.com/cn/app/%e4%b8%ad%e4%b9%90%e4%b9%8b%e5%a3%b0/id1552859946" : 'https://www.51audio.com/downloadapp'} target='_blank'>
                        <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                            <ExhibitionBuy>点击去购买</ExhibitionBuy></a>}
                    </Content>
                        :
                        <Content>
                            {ImgLinks.map(item => (<img src={item} alt="" />))}
                            {infoContentNews && <div className='detail-img' ref={r => this.content = r} dangerouslySetInnerHTML={{ __html: filterVideoSrc(infoContentNews) }}></div>}
                            {infoVideoSrc && <>{
                                infoVideoSrc.indexOf('?') > 0 ? <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc.slice(0, infoVideoSrc.indexOf('?'))}></video> : <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc}></video>
                            }</>}
                        </Content>}
                    <ClickNum>
                        {scienceNumber(infoClickNum || 0)}浏览
                        <span>
                            {/* <a href={!isAndroid ? "https://apps.apple.com/cn/app/%e4%b8%ad%e4%b9%90%e4%b9%8b%e5%a3%b0/id1552859946" : 'https://www.51audio.com/downloadapp'} target='_blank'> */}
                            <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                            <Inform><i className="iconfont">&#xe76e;</i>举报文章</Inform></a></span>
                        {/* <ReportBox reportTitle={infoTitle} reportAuthor={infoAuthor} targetId={infoId} userId={userId} typeId={4} /> */}
                        {/* <Block onClick={this.addBlock.bind(this, infoAuthorID)}><i className="iconfont">&#xe75a;</i>拉黑作者</Block> */}
                    </ClickNum>
                    <a name="commentTop"></a>
                    <CommentWrapper>
                        <span>评论</span>
                        {commentData.length == 0 && <CommentBox><span style={{
                            width: '100%',
                            height: '5rem',
                            textAlign: 'center',
                            display: 'block',
                            lineHeight: '5rem',
                            fontSize: '0.88rem',
                            color: '#7C8698'
                        }}>暂无评论，点击抢沙发</span></CommentBox>}
                        {
                            commentData.map((d) => (
                                <CommentBox>
                                    <CommentBoxLeft>
                                        <img src={d.HeadImg}></img>
                                    </CommentBoxLeft>
                                    <CommentBoxRights>
                                        <h1>
                                            {d.PNickName}
                                            {/* <a href={!isAndroid ? "https://apps.apple.com/cn/app/%e4%b8%ad%e4%b9%90%e4%b9%8b%e5%a3%b0/id1552859946" : 'https://www.51audio.com/downloadapp'} target='_blank'> */}
                                            <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                                                <span>
                                                    <span>{d.LikeNum}</span>
                                                    <i className="iconfont">&#xe755;</i>
                                                </span>
                                            </a>
                                        </h1>
                                        <h2>{d.Content}</h2>
                                        <div>
                                            {/* <a href={!isAndroid ? "https://apps.apple.com/cn/app/%e4%b8%ad%e4%b9%90%e4%b9%8b%e5%a3%b0/id1552859946" : 'https://www.51audio.com/downloadapp'} target='_blank'> */}
                                            <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                                                <div>
                                                    <span>{d.ReplyNum}回复</span><i className="iconfont">&#xe744;</i>
                                                </div>
                                            </a>
                                            <span>{startMinsTime(d.AddTime)}</span>
                                        </div>
                                    </CommentBoxRights>
                                </CommentBox>
                            ))
                        }

                    </CommentWrapper>
                    <CommentWrappers>
                        <span>推荐</span>
                    </CommentWrappers>
                    {
                        ListData.map(obj => (
                            <NewsDetailWrapper>
                                {/* <Link to={`/community/communitydetailh5/${obj.ID}/${obj.type == 1 ? "news" : obj.type == 5 ? "community" : obj.type == 3 ? "evaluation" : "audition"}`} > */}
                                <a href={`/community/communitydetailh5/${obj.ID}/${obj.type == 1 ? "news" : obj.type == 5 ? "community" : obj.type == 3 ? "evaluation" : "audition"}`} >
                                    {/* <a onClick={this.refresh.bind(this,obj.type)}> */}
                                    {
                                        obj.ShowType == 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    {
                                                        obj.ListIMG === null ? <img src={
                                                            obj.ImageAppUrl.split(';')[0]
                                                        } alt="" /> : <img src={obj.ListIMG} alt="" />
                                                    }
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 0 ?
                                                        <NewsItem div key={obj.ID} >
                                                            {obj.ListIMG != null ? <>
                                                                <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                                <NewsImgWrap>
                                                                    <img src={obj.ListIMG} alt="" />
                                                                </NewsImgWrap>
                                                            </> : <NewsTitleNoImg>{obj.Title}</NewsTitleNoImg>
                                                            }
                                                        </NewsItem >
                                                        :
                                                        <>
                                                            {
                                                                obj.ShowType == 2 ?
                                                                    <NewsItemOther div key={obj.ID} >
                                                                        <NewsTitle>{obj.Title}</NewsTitle>
                                                                        <NewsThreeImgWrap>
                                                                            {
                                                                                obj.ImageAppUrl.split(';').map((image, index) => (
                                                                                    index < 3 && <div><img src={image} alt="" /></div>
                                                                                ))
                                                                            }
                                                                        </NewsThreeImgWrap>
                                                                    </NewsItemOther >
                                                                    :
                                                                    <NewsItem div key={obj.ID} >
                                                                        <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                                        <NewsImgWrap>
                                                                            {
                                                                                obj.ListIMG === null ? <img src={
                                                                                    obj.ImageAppUrl.split(';')[0]
                                                                                } alt="" /> : <img src={obj.ListIMG} alt="" />
                                                                            }
                                                                        </NewsImgWrap>
                                                                    </NewsItem >
                                                            }
                                                        </>
                                                }
                                            </>
                                    }
                                    <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.Author}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span></NewsPub>
                                </a>
                                {/* </Link> */}
                            </NewsDetailWrapper>
                        ))
                    }
                </ContentWrapper>
                <QuickReply style={!isAndroid ? { height: '5rem' }:{ height: '3rem' }}>
                    {/* <a href={!isAndroid ? "https://apps.apple.com/cn/app/%e4%b8%ad%e4%b9%90%e4%b9%8b%e5%a3%b0/id1552859946" : 'https://www.51audio.com/downloadapp'} target='_blank'> */}
                    <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                        <QuickReplyInputH5>
                            <i className='iconfont'>&#xe62d;</i>
                            <span>写评论...</span>
                        </QuickReplyInputH5>
                        <QuickReplyOperation className={commentTextFocus ? 'iconFocused' : ""}>
                            <div style={{ marginLeft: '12%' }}>
                                <i className="iconfont">&#xe7b2;</i>
                            </div>
                            <div>
                                {isCollected ? <i className="iconfont">&#xe79e;</i> : <i className="iconfont">&#xe79d;</i>}
                            </div>
                            <div>
                                {isLiked ? <i className="iconfont">&#xe780;</i> : <i className="iconfont">&#xe755;</i>}
                            </div>
                            <div>
                                <i className="iconfont">&#xe762;</i>
                            </div>
                        </QuickReplyOperation>
                    </a>
                </QuickReply>
                {commentTextFocus ? <div onClick={() => { this.setState({ commentTextFocus: false, }) }} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10 }}></div> : ''}
            </CommunityDetailWrapper >

        )
    }
}

export default CommunityDetailH5