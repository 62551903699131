import styled from 'styled-components';

import {
  theme, themenew
} from '../../../../common/color';


export const Wrapper = styled.div`
  width:100%;
  background: #fff;
`

export const VideoIcon = styled.div`
  width: 100%;
  height: 3.125rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  z-index: 99;
  span{
    color: #fff;
    font-size: 3.125rem;
  }
`

export const ExhibitionDiv = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
  opacity: 0.6;
  position: absolute;
`
export const ExhibitionTime = styled.div`
  font-size: 1rem;
  color: #fff;
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
`

export const NavBar = styled.div`
  width:100%;
  height:3rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.375rem;
    color:${themenew.text_color};
    margin-top:3.6rem;
    font-weight:700;
  }
`

export const CommunityWrapper = styled.div`
  width:100%;
  margin:0 auto;
  background: #fff;
`
export const DIYDemoBlock = styled.div`
  width:100%;
  background: #FAFBFC;
  padding-top: 0.5rem;
  >span{
    display: block;
    height: 2.875rem;
    line-height: 2.875rem;
    color: #7C8698;
    width:92%;
    margin-left: 4%;
    font-size: 0.9rem;
  }
  >div{
    width:100%;
    background: #fff;
    //height: 3.375rem;
    padding: 0.85rem 4%;

  }
`
export const Xuanze = styled.div`
  width:100%;
  font-size: 1.125rem;
  color: #262626;
  line-height: 1.65rem;
  >i{
    color: #ccc;
    float: right;
    margin-right: 4%;
  }
`
export const Fengmiantu = styled.div`
  //height: 9.45rem !important;
  padding-top: 0.85rem;
  line-height: 1.5rem;
  >span{
    display: block;
  }
`

export const ImageUploaderBox = styled.div`
  width:100%;
  display: flex;
  flex-wrap:wrap;
`
export const ImageUploader = styled.div`
  width:88px ;
  height: 88px;
  background-color: #F5F5F5;
  line-height: 88px;
  text-align: center;
  border-radius:5px ;
  margin-top: 0.6rem;
  margin-right: 0.75rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
  i{
    position: absolute;
    color: #999;
    font-size: 3rem;
  }
  >img{
    width: 100%;
    min-height:88px;
    object-fit: cover;   //对图片进行剪切
  }
`
export const VideoUploader = styled.div`
  width:100% ;
  height: 160px;
  background-color: #F5F5F5;
  line-height: 160px;
  text-align: center;
  border-radius:8px ;
  margin-top: 0.6rem;
  >i{
    color: #999;
    font-size: 3rem;
  }
`
export const DeleteVideo = styled.div`
width:100% ;
height: 48px;
background-color: #015FEC;
color: #fff;
line-height: 48px;
text-align: center;
border-radius:8px ;
margin-top: 0.5rem;
`

export const ExhibitionIcon = styled.div`
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  img{
    width: 2.6rem;
  }
`

export const ExhibitionList = styled.div`
  //width: 100%;
  //width: 48%;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  margin-left: 2%;
  >span{
    display: block;
    width: 92%;
    height: 2.3rem;
    margin: 0 auto;
    color: #00222C;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0.2rem;
  }
`

export const ExhibitionListIcon = styled.div`
  width: 92%;
  height: 1rem;
  margin:1rem 0 0;
  margin-left: 4%;
  text-align: right;
  i{
    font-size: 1.1rem;
    color: #828294;
  }
  span{
    font-size: 0.75rem;
    color: #828294;
  }
  div{
    display: inline-block;
    font-size: 1rem;
    color: #828294;
    margin:0 0.2rem;
  }
  button{
    display: inline-block;
  }
`

export const ExhibitionListImg = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 0.625rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  >img{ 
    width:100%;
    min-height: 8.5rem;
    object-fit: cover;   //对图片进行剪切
 }
  >span{
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.7rem;
    opacity: 0.6;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
  }
`
export const ExhibitionTitle = styled.div`
  width:92%;
  margin:0 4%;
  text-align: center;
  padding-top: 1rem;
  line-height: 2rem;
  span{
    font-size: 1.5rem;
    color: #203152;
  }
  div{
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    border-radius: 0.22rem;
    width: 5.375rem;
    height: 0.438rem;
    margin:1rem auto;
  }
`

export const ExhibitionWrapper = styled.div`
  width:100%;
`

export const NewsPub = styled.div`
  width:92%;
  height: 2rem;
  line-height:2rem;
  margin-left: 4%;
  >div{
    display: inline-block;
    width: 18%;
    >img{
      width:1.25rem;
      height:1.25rem;
      border-radius:50%;
      vertical-align:middle;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }
  }
  >span{
    width:82%;
    display: inline-block;
    font-size:0.75rem;
    color: #7C8698;
    vertical-align:middle;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
`