import { createStore, applyMiddleware, compose } from 'redux';
import reducer from "./reducer";
import thunk from 'redux-thunk';
import axios_middleware from '../utils/axios_middleware'

const composeEnhancers=
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}):compose;
const enhancer=composeEnhancers(
    applyMiddleware(thunk, axios_middleware)
);

const store = createStore(
    reducer,
    enhancer
);
export default store;
