import styled from 'styled-components';

import {
    themenew
} from '../../../../common/color';

export const CommunityWrapper = styled.div`
  width:100%;
  margin:0 auto;
`

export const SystemBox = styled.div`
    padding: 1.25rem 0;
    >div{
        font-size: 0.88rem;
        color: ${themenew.text_color};
        margin-bottom: 0.75rem;
        >span{
            float: right;
            font-size: 0.75rem;
            color: ${themenew.subtext_color}; 
        }
    }
    >span{
        font-size: 0.88rem;
        color: ${themenew.subtext_color}; 
    }
`