/**
 * created by sunchenyao on 2021/7/9
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    CommunityWrapper,
    SearchWrapper,
    SearchInput,
    FansListWrapper,
    FansWrapper,
} from './style';
import {
    Empty,
} from '../../../../common/generalStyle';
import Collection from '../../../../images/collection.svg';
import httpAxios2 from "../../../../http/httpAxios2";
import api from "../../../../http/api";

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class MyCollectAudition extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userId: this.props.location?.search.replace('?', ''),//从网址中取栏目类型
            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,
            data: [],
            typeKeys: {},
            hasMore: true,
            rowCount: 0,
            searchName: '',
            search: false
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
        };
        this.getList();
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    getListRefresh = async (name) => {
        this.setState({
            data: [],
        })
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(name);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }
    getList = async (name) => {
        if (!name) name = '0';
        const res = await httpAxios2(api.getUserFocus, {
            "nickName": name,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "userID": this.state.userId,
        });
        if (res) {
            this.state.rowCount = res.Data.totalCount;
            if (res.Data.userList.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data.userList,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data.userList];
                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                // this.setState({
                //     data: [],
                // })
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }
    //取消关注
    toggleCollect = async (focusUserID) => {
        let url = api.removeUserFocus
        const res = await httpAxios2(url, {
            "id": 0,
            "User1": this.state.userId,
            "User2": focusUserID
        });
        if (res) {
            this.getListRefresh()
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh();
        // setTimeout(() => {
        //     this.setState({
        //         refreshing: false,
        //         isLoading: false,
        //     });
        // }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList();
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };
    goSearch = () => {
        const {
            searchName,
        } = this.state;
        this.getListRefresh(searchName);
        this.setState({ search: true })
    };
    toSearch = () => {
        this.getListRefresh("");
        this.setState({ search: false })
    }


    render() {
        const {
            data,
            searchName,
            search
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <FansListWrapper>
                        <FansWrapper>
                            <img src={obj.HeadImg} alt="" />
                            <span>{obj.PNickName}</span>
                            <button onClick={this.toggleCollect.bind(this, obj.FocusUserID)}>取消关注</button>
                        </FansWrapper>
                    </FansListWrapper>
                </div>
            );
        };
        return (<CommunityWrapper>
            <SearchWrapper>
                <SearchInput>
                    <i className='iconfont'>&#xe7b4;</i>
                    <input value={searchName} onChange={(e) => { this.setState({ searchName: e.target.value.trim() }) }} placeholder="请输入您要搜索的内容"></input>
                </SearchInput>
                {!search ? <button onClick={this.goSearch}>搜索</button> : <button onClick={this.toSearch}>取消</button>}
            </SearchWrapper>
            {
                this.state.rowCount != 0 ?
                    <ListView
                        initialListSize={10}
                        key={'1'}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                            {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                        </div>)}
                        renderRow={row}
                        useBodyScroll
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                        onEndReached={this.onEndReached}
                        pageSize={NUM_ROWS}
                    />
                    :
                    <Empty>
                        <img src={Collection} alt="我的关注无内容"></img>
                        <b>暂无我的关注内容</b>
                        <span>你还没有关注任何人，快去转转吧～</span>
                        {/* 隐藏ListView不能去掉，不然ReactDOM高度会报错 */}
                        <ListView
                            style={{ display: 'none' }}
                            initialListSize={10}
                            key={'1'}
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource}
                            renderRow={row}
                            useBodyScroll
                            onEndReached={this.onEndReached}
                            pageSize={NUM_ROWS}
                        />
                    </Empty>
            }
        </CommunityWrapper >);
    }
}

ReactDOM.render(<MyCollectAudition />, document.getElementById('root'));
export default MyCollectAudition