import React, {
    Component
} from 'react';
import {
    Wrapper,
    Title,
    Dec
} from '../FileStyle';

class Trademark extends Component {
    render() {
        return (
            <Wrapper>
                <Title>商标声明</Title>
                <Dec>
                    <p>北京中悦之声信息技术有限公司(以下简称中悦之声)旗下的中乐之声以及他们的图标和商标或其它本网站中提及中悦之声其它产品的图标、商标以及图片、视频受法律保护，侵权必究。</p>
                    <p>未经本公司明确授予的任何权利均予保留。未经本公司或商标权人书面许可，任何单位及个人不得以任何方式或理由对该商标的任何部分进行使用、复制、修改、抄录、传播、展示、镜像或与其它产品捆绑使用、销售。</p>
                    <p>凡侵犯本公司商标权的，我公司必依法追究其法律责任。</p>
                    <p style={{fontWeight: 'bold'}}>特此郑重法律声明！</p>
                    <p className="bottom">
                        本站 举报 / 咨询 / 维权 联系电话：4008-0202-01
                    </p>
                </Dec>
            </Wrapper>
        )
    }
}

export default Trademark