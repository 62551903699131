import React, {
    Component
} from 'react';
import { List, Picker } from 'antd-mobile-v2';
import {
    city,
} from '../../../../common/citySelect/cityJsonApp'
import {
    birthday,
} from '../../../../common/birthdaySelect/birthdayJsonApp'
import {
    SettingsWrapper,
    NavBar,
    ReturnButton,
    HeaderImgList
} from '../MycenterPortraitStyle';
// import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
import axios from 'axios'
import api from "../../../../http/api"
import httpAxios from "../../../../http/httpAxios"
import { Toast } from 'antd-mobile-v2';

// const history = creatHistory();//返回上一页这段代码

const season = [
    {
        label: '男',
        value: '男',
    },
    {
        label: '女',
        value: '女',
    },
];

// const returnButton = require('../../../../images/return_button.svg')
const headerImg = require('../../../../images/headimg.png')


const Item = List.Item;

class Personal extends Component {
    state = {
        pickerValue: [],
        sValue: [],
        HeadImg: '',
        PNickName: '',
        WriteSB: '',
        birthdayValue: [],
        userPID: '',
        taskTag: ''
    };

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getUserInfo(data.UserPID);
        }

        window.logoImage = function (imageUrl) {
            if (imageUrl != "") {
                that.setState({
                    HeadImg: imageUrl
                })
                that.saveLogoImage();
            }
        }
    }
    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    //获取用户信息
    getUserInfo = async (id) => {
        const res = await axios.post(api.getUserInfo.url, {
            //UserPID: sessionStorage.getItem("BUSS_CENTER_TO_BRAND_MANAGER_ID"),
            UserPID: id
        })
        if (res && res.data.Data) {
            this.setState({ PNickName: res.data.Data.PNickName });
            this.setState({ WriteSB: res.data.Data.WriteSB });
            this.setState({ HeadImg: res.data.Data.HeadImg });
            this.setState({ pickerValue: res.data.Data.City });
            this.setState({ sValue: res.data.Data.Sex });
            this.setState({ birthdayValue: JSON.parse(res.data.Data.Birthday) });
        }
    }

    //显示上传图片
    handleLogoImage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadLogoImage" }));
    }

    //保存头像信息
    saveLogoImage = async () => {
        const res = await httpAxios(api.uploadHeadIMG, {
            "UserP": this.state.userPID,
            "ImgLinks": this.state.HeadImg
        })
        if (res.Code == 200) {
            this.userTeShuTaskSign("上传头像")
        }

    }

    //保存信息
    onSubmit = (key, value) => {
        let dataTemp = {
            // City: this.state.pickerValue,
            // Sex: this.state.sValue,            
            UserPID: this.state.userPID
        };
        dataTemp[key] = value;
        httpAxios(api.updateUserInfo, dataTemp).then(res => {
            if (res.Code == 200) {
                Toast.info('信息修改成功');
                if (key == 'City') {
                    this.userTeShuTaskSign("添加城市")
                } else if (key == 'Birthday') {
                    this.userTeShuTaskSign("添加生日")
                }
            }
        })
    }

    goBackPage = () => {
        //history.goBack();  //返回上一页这段代码
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    //用户特殊任务签到
    userTeShuTaskSign = async (taskTag) => {
        const res = await httpAxios(api.userTeShuTaskSign, {
            "userID": this.state.userPID,
            "tag": taskTag
        });
        if (res.Code == 200) {
            localStorage.setItem('GO_BACK', 'Refresh');
        }
    }

    render() {
        return (
            <SettingsWrapper>
                {/* <NavBar>
                    <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                    <span>编辑个人信息</span>
                </NavBar>
                <div style={{ height: '5.5rem' }}></div> */}
                {/* NavBar的高度占位 */}

                <List renderHeader={() => ''}>
                    <HeaderImgList onClick={this.handleLogoImage}>
                        <span>头像</span><div><img src={this.state.HeadImg == "" ? headerImg : this.state.HeadImg} alt="默认头像"></img></div>
                    </HeaderImgList>
                    <Item
                        className='listheight'
                        extra={this.state.PNickName}
                        arrow="horizontal"
                        onClick={() => {
                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '设置昵称', weburl: '/nickname' }));
                            this.timeId = setInterval(() => {
                                if (localStorage.getItem('GO_BACK_NAME') == 'Refresh') {
                                    this.getUserInfo(this.state.userPID);
                                    localStorage.setItem('GO_BACK_NAME', '');
                                    clearInterval(this.timeId)
                                }
                            }, 1000);
                        }}>
                        昵称
                    </Item>
                    <Picker
                        cols={1}
                        data={season}
                        extra={this.state.sValue}
                        // value={this.state.sValue}
                        onChange={v => this.setState({ sValue: v })}
                        onOk={v => {
                            this.setState({ sValue: v });
                            this.onSubmit('Sex', v[0]);
                        }}
                    >
                        <List.Item className='listheight' extra={this.state.sValue} arrow="horizontal" onClick={() => { }}>
                            性别
                        </List.Item>
                    </Picker>
                    <Picker
                        cols={2}
                        data={city}
                        extra={this.state.pickerValue}
                        // value={this.state.pickerValue}
                        onOk={(v) => {
                            let tempData = city.filter(item => item.value == v[0]);
                            let tempData1 = tempData[0].children.filter(item => item.value == v[1]);
                            this.setState({ pickerValue: tempData1[0].province + "-" + tempData1[0].label });
                            this.onSubmit('City', tempData1[0].province + "-" + tempData1[0].label);
                        }}
                    >
                        <List.Item className='listheight' extra={this.state.pickerValue} arrow="horizontal" onClick={() => { }}>
                            城市
                        </List.Item>
                    </Picker>
                    <Picker
                        cols={3}
                        data={birthday}
                        extra={this.state.birthdayValue}
                        cascade={false}
                        onOk={(v) => {
                            this.setState({ birthdayValue: v });
                            this.onSubmit('Birthday', JSON.stringify(v));
                        }}
                    >
                        <List.Item className='listheight' extra={this.state.birthdayValue} arrow="horizontal" onClick={() => { }}>
                            生日
                        </List.Item>
                    </Picker>
                    <Item className='listheight' extra={this.state.WriteSB} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '设置个性签名', weburl: '/signedname' })); }}>个性签名</Item>
                    {/* <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '中乐之星', weburl: '/StarEdit'})); }}>中乐之星</Item> */}
                </List>
            </SettingsWrapper>
        )
    }
}

export default Personal;