/**
 * created by zhangling on 2020/3/3
 */
import styled from 'styled-components';
import {theme} from '../color';


export const LeftDec=styled.div`
  color:${theme.subtext};
  line-height:1.5rem;
  margin-top:1rem;
`
export const Right=styled.div`
  float:right;
  width:23rem;
  height:9rem;
  margin-top:1rem;
`
export const RightTitle=styled.div`
  text-align:center;
  font-size:1rem;
  color:${theme.text};
  margin-top:2rem;
  span{
    font-size:2rem;
    color:${theme.rose_color};
  }
`
export const AddBtn=styled.div`
  width:6rem;
  height:2rem;
  line-height:2rem;
  background-color:${theme.theme_light_auxi_color};
  color:#fff;
  text-align:center;
  margin:1rem 0 0 13rem;
`
export const Sort=styled.div`
  width:69rem;
  height:3rem;
  margin:1rem auto 0;
  border-bottom:1px solid ${theme.line_color};
  div{
    color:${theme.text};
    font-weight:800;
  }
`
export const Item=styled.div`
  width:69rem;
  height:7rem;
  margin:1rem auto;
  div{
    color:${theme.subtext};
  }
  :hover{
    background-color:${theme.line_color};
  }
`

export const Mask = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, .45)
`
export const Window=styled.div`
  width:92%;
  // margin:2rem auto;
  height: 13rem;
  background-color:#fff;
  box-shadow:${theme.dark_line_color} 0px 0px 20px 3px;
  border-radius:5px;
  padding:1rem;
  transition: all 0.3s;
  position: fixed;
  z-index: 9999;
  margin:auto;
  top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`
export const WindowTitle=styled.div`
  width:33rem;
  margin:auto;
  color:${theme.red_color};
  font-size:1.3rem;
  >i{
    font-size:1.8rem;
    vertical-align:-.2rem;
    margin-right:.5rem;
  }
  span i{
    font-size:1.2rem;
    float:right;
    color: ${theme.subtext}
  }
`
export const WindowTip=styled.div`
  width:30rem;
  margin:1rem auto;
  line-height:2rem;
`
export const WindowBtnWrapper=styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  margin:2rem auto 1rem;
`
export const WindowSure=styled.div`
  width:8rem;
  height:2.5rem;
  line-height:2.5rem;
  background-color:${theme.theme_dark_auxi_color};
  text-align:center;
  color:#fff;
  border-radius:8px;
`
export const WindowCancel=styled.div`
  width:8rem;
  height:2.5rem;
  line-height:2.5rem;
  background-color:${theme.line_color};
  color:${theme.subtext};
  text-align:center;
  border-radius:8px;
`
