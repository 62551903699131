export const PERSON_REGISTER_CLICK = 'register/PERSON_REGISTER_CLICK';
export const COMPANY_REGISTER_CLICK = 'register/COMPANY_REGISTER_CLICK';
export const FIRSR_NEXT_STEP_CLICK = 'register/FIRSR_NEXT_STEP_CLICK';
export const SECOND_NEXT_STEP_CLICK = 'register/SECOND_NEXT_STEP_CLICK';
export const THIRD_NEXT_STEP_CLICK = 'register/THIRD_NEXT_STEP_CLICK';
export const ON_PERSON_USERNAME_CHANGE = 'register/ON_PERSON_USERNAME_CHANGE';
export const CHANGE_PERSON_REGISTER = 'register/CHANGE_PERSON_REGISTER';
export const ON_PERSON_PHONE_OR_EMALI_CHANGE = 'register/ON_PERSON_PHONE_OR_EMALI_CHANGE';
export const ON_PERSON_USER_PWD_CHANGE = 'register/ON_PERSON_USER_PWD_CHANGE';
export const ON_PERSON_USER_CONFIRM_PWD_CHANGE = 'register/ON_PERSON_USER_CONFIRM_PWD_CHANGE';
export const SEND_CHECK_NUM = 'register/SEND_CHECK_NUM';
export const ON_PERSON_USER_CODE_CHANGE = 'register/ON_PERSON_USER_CODE_CHANGE';
export const ON_CHECK_READ_AGREEMENT_CHANGE = 'register/ON_CHECK_READ_AGREEMENT_CHANGE';
