/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    NewsDetailWrapper,
    NewsItemOther,
    NewsTitle,
    NewsPubs,
    VideoImg,
    VideoIcon,
    LiveState,
    LiveStates
} from '../../../common/generalStyle';
import {
    NavBar,
    ReturnButton,
    SearchIcon
} from './ContentStyle';

// import Label from "./Label";
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import {
    endMinsTime
} from '../../../utils/utils';
const playSvg = require('../../../images/plays.svg');
const returnButton = require('../../../images/return_button.svg')
const searchButton = require('../../../images/search_button.svg')
const listVipLogo = require('../../../images/listVipLogo.png');

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            newsType: '5',
            auditionType: '999',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        pageIndex = 0;
        // this.getComType();
        // this.getList(null, null, null);


        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    // getListRefresh = async (targetID, readType, listType) => {
    //     this.lv.scrollTo(0, 0);
    //     this.state.refreshing = true;
    //     pageIndex = 1;
    //     await this.getList(targetID, readType, listType);
    //     this.state.refreshing = false;
    //     this.setState({
    //         isLoading: false,
    //     });
    // }

    getList = async (targetID, readType, listType) => {
        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;
        // if (!targetID) targetID = this.state.targetID;
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
        }
        const res = await httpAxios(api.getLiveUrls, params);
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.setState({
                    data: [],
                })
                this.state.hasMore = false;
            }

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    // getComType = async () => {
    //     const res = await httpAxios(api.getComType);
    //     let typeKeys = {};
    //     if (res) {
    //         res.Data.map(i => {
    //             typeKeys[i.ID] = i.Title
    //         })
    //         this.setState({
    //             comTypes: res.Data,
    //             typeKeys: typeKeys
    //         })
    //     }
    // }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        // await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        pageIndex += 1;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
            comTypes,
            livedata,
            targetID
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <NewsDetailWrapper >
                        {
                            obj.LiveState === 4 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.CompanyName, weburl: '/community/communitydetail/' + obj.ID + '/lookback' + '?' + this.state.userPID, })); }}>
                                <NewsItemOther div key={obj.ID} >
                                    <NewsTitle>{obj.Title}</NewsTitle>
                                    <VideoImg>
                                        <img src={obj.ImageUrl + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                        <VideoIcon>
                                            <img src={playSvg} alt="" />
                                        </VideoIcon>
                                        <LiveStates>往期直播</LiveStates>
                                    </VideoImg>
                                </NewsItemOther >
                                <NewsPubs>
                                    {
                                        obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                    }
                                    <img src={obj.HeadImage} alt="" /><span>{obj.CompanyName}</span><span>{obj.ClickCount} 观看</span>
                                </NewsPubs>
                            </a> :
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "ToLive", id: obj.ID })) }}>
                                    <NewsItemOther div key={obj.ID} >
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.ImageUrl + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                            <VideoIcon>
                                                <img src={playSvg} alt="" />
                                            </VideoIcon>
                                            {obj.LiveState === 1 ? <LiveState>{endMinsTime(obj.BeginTime)}直播</LiveState> : <LiveState>正在直播</LiveState>}
                                        </VideoImg>
                                    </NewsItemOther >
                                    <NewsPubs>
                                        {
                                            obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                        }
                                        <img src={obj.HeadImage} alt="" /><span>{obj.CompanyName}</span><span>{obj.ClickCount} 观看</span>
                                    </NewsPubs>
                                </a>
                        }
                    </NewsDetailWrapper>
                </div>
            );
        };
        return (<div>
            <NavBar>
                <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                <span>直播</span>
                <SearchIcon onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><img src={searchButton} alt="搜索" /></SearchIcon>
                {/* <Label cb={this.getListRefresh} list={comTypes} /> */}
            </NavBar>
            <div style={{ height: '5.5rem' }}></div>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            <NewsDetailWrapper >
                                {
                                    obj.LiveState === 4 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.CompanyName, weburl: '/community/communitydetail/' + obj.ID + '/lookback' + '?' + this.state.userPID, })); }}>
                                        <NewsItemOther div key={obj.ID} >
                                            <NewsTitle>{obj.Title}</NewsTitle>
                                            <VideoImg>
                                                <img src={obj.ImageUrl + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                <VideoIcon>
                                                    <img src={playSvg} alt="" />
                                                </VideoIcon>
                                                <LiveStates>往期直播</LiveStates>
                                            </VideoImg>
                                        </NewsItemOther >
                                        <NewsPubs>
                                            {
                                                obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                            }
                                            <img src={obj.HeadImage} alt="" /><span>{obj.CompanyName}</span><span>{obj.ClickCount} 观看</span>
                                        </NewsPubs>
                                    </a> :
                                        <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "ToLive", id: obj.ID })) }}>
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    <img src={obj.ImageUrl + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                    {obj.LiveState === 1 ? <LiveState>{endMinsTime(obj.BeginTime)}直播</LiveState> : <LiveState>正在直播</LiveState>}
                                                </VideoImg>
                                            </NewsItemOther >
                                            <NewsPubs>
                                                {
                                                    obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                                }
                                                <img src={obj.HeadImage} alt="" /><span>{obj.CompanyName}</span><span>{obj.ClickCount} 观看</span>
                                            </NewsPubs>
                                        </a>
                                }
                            </NewsDetailWrapper>
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content