import styled from 'styled-components';
import {
  theme, themenew
} from '../../common/color';

export const CommunityDetailWrapper = styled.div`
  width:100%;
  background-color:#252525;
  margin:0 auto;
  //position: fixed;
  //top:0;
  //left:0 ;
  //right:0 ;
  //bottom: 0;
  //z-index: 1;
`

export const TopDownload = styled.div`
  width:100%;
  line-height:3.125rem;
  height: 3.125rem;
  background-color: #fff;
  position: fixed;
  top:0;
  z-index:999;
  >img{
    width: 7.75rem;
    height: 2.375rem;
    float: left;
    margin-left: 4%;
    margin-top: 0.375rem;
  }
  div{
    width:5rem;
    height:1.75rem;
    line-height:1.75rem;
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    border-radius: 4px;
    font-size:0.875rem;
    text-align:center;
    color:#fff;
    border-radius:0.25rem;
    float:right;
    margin-right: 4%;
    margin-top: 0.6875rem;
  }
`
export const BottomDownload = styled.div`
  width:100%;
  line-height:3.125rem;
  height: 4.125rem;
  background-color: #fff;
  position: fixed;
  bottom:0;
  z-index:999;
  >img{
    width: 7.75rem;
    height: 2.375rem;
    float: left;
    margin-left: 4%;
    margin-top: 0.375rem;
  }
  div{
    width:5rem;
    height:1.75rem;
    line-height:1.75rem;
    background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
    border-radius: 4px;
    font-size:0.875rem;
    text-align:center;
    color:#fff;
    border-radius:0.25rem;
    float:right;
    margin-right: 4%;
    margin-top: 0.6875rem;
  }
`
export const ContentWrapper = styled.div`
  width:92%;
  padding-top:1rem;
  margin:0 4%;
  position: fixed;
  top:3.5rem;
  z-index:999;
`
export const Title = styled.div`
  width:100%;
  font-size:1.5rem;
  font-weight:bold;
  line-height:2rem;
  color: #fff;
`

export const Author = styled.div`
  width:100%;
  height:5rem;
  padding-top:1.3rem;
  color:${themenew.text_color};
    img{
      width:2.25rem;
      height:2.25rem;
      border-radius:50%;
      border: 1px solid #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
      float:left;
    }
    >div{
      float:left;
      line-height:2.25rem;
      margin-left:0.5rem;
      height:2.25rem;
      color: #fff;
      font-weight:bold;
      font-size:0.875rem;
    }
    >span{
        display:block ;
        line-height:2.25rem;
        height:2.25rem;
        color: #fff;
        float:right ;
        >span{
            float:left ;
            font-size:0.75rem;
        }
        >i{
            float:left ;
            color:red;
            font-size:1.3rem;
        }
    }
`
export const SubjectVideo = styled.div`
    width:100% ;
    position: fixed;
    top:13rem;
    z-index:999;
`

export const QuickReply = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  box-shadow: 0 -2px 8px 0 rgba(85,59,163,0.16);
  z-index: 99;
  a{
    color: ${themenew.text_color};
  }
  .focused{
    width: 75%;
    i{
      display: none;
    }
    input{
      margin: 0 2%;
      width: 96%;
    }
  }
  .iconFocused{
    display: none;
  }
  .buttonFocused{
    display: block;
  }
`
export const QuickReplyInputs = styled.div`
  float: left;
  width: 92%;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.275rem;
  background: #000;  
  margin-left: 4%;
  margin-top: 0.5rem;
  color: #fff;
  
  i{
    width: 10%;
    margin-left: 4%;
    margin-right: 4%;
  }
  span{
    width:80%;
    height: 100%;
    border: 0;              
  }
`


export const SubjectWrapper = styled.div`
  position: fixed;
  width:100%;
  height:12rem;
  overflow:hidden ;
  z-index:99;
`

export const Subject = styled.div`
    width:100%;
    padding:0 4%;
    height:12rem;
    overflow:auto;
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction:column-reverse;
    >div{
      font-size:0.875rem;
      color: #FFFFFF;
      background: #000;
      max-width:78% ;
      padding:1px 8px ;
      border-radius:6px ;
      margin-bottom:10px ;
      line-height:1.2rem ;
      >span{
        margin-right:8px ;
      }
    }
`
