import React, { Component } from 'react';
import {
    CountryList,
    NavBar,
    ReturnButton,
    Search,
    MessageWrapper,
    NewStar,
    NewStarTitle,
    NewStarBow,
    StarList,
} from './style';
import {
    convertTime
} from '../../utils/utils';
import {
    DividingLine,
    ClearFloat,
} from '../../common/style';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import BigBanner from "../home/components/Banner";
import Label from "./Label";
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

const returnButton = require('../../images/return_button.svg')
const NUM_ROWS = 10;
let pageIndex = 1;
class StarHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',
            info: [],
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            targetID: '1',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
            starData: []
        }
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID,
                info: data
            })
        }

        pageIndex = 0;
        this.getComType();
        // this.getList(null, null, null);

        this.getArtistInfoList();


        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }
    getListRefresh = async (targetID, readType, listType) => {
        window.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async (targetID, readType, listType) => {
        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;
        if (!targetID) targetID = this.state.targetID;
        let params;
        params = {
            "type": targetID,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
        }

        const res = await httpAxios(api.getArtistInvitationList, params);
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                        targetID,
                    })
                }
            } else {
                this.setState({
                    data: [],
                    targetID,
                })
                this.state.hasMore = false;
            }

            if (this.state.data.length <= this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    //获取最新艺人列表
    getArtistInfoList = async () => {
        let params;
        params = {
            "sex": "0",
            "country": "0",
            "letter": "0",
            "jointime": "1",
            "pageIndex": 1,
            "pageSize": 12,
        }
        const res = await httpAxios(api.getArtistInfoList, params)
        this.setState({
            starData: res.Data,
        })
    }


    getComType = async () => {
        const res = await httpAxios(api.evaluationType);
        let typeKeys = {};
        if (res) {
            res.Data.map(i => {
                typeKeys[i.ID] = i.Title
            })
            this.setState({
                comTypes: res.Data,
                typeKeys: typeKeys
            })
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        pageIndex += 1;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };


    render() {

        const {
            info,
            data,
            comTypes,
            starData
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <StarList onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "详情", weburl: "/invitationDetail/" + obj.ID }))}>
                        <h1>{obj.Title}</h1>
                        <div>
                            <div>
                                {
                                    obj.InvitationType?.split(',').map((d, index) => (
                                        <span key={index}>{d}</span>
                                    ))
                                }
                            </div>
                            <h2>{obj.MoneyRange}</h2>
                        </div>
                        <ClearFloat />
                        <div>
                            <span style={{ width: '30%' }}>
                                <img src={require("../../images/StarDidian.png")} alt="" />
                                <h3>{obj.Place}</h3>
                            </span>
                            <span style={{ width: '65%', marginLeft: '5%' }}>
                                <img src={require("../../images/StarTime.png")} alt="" />
                                <h3>{convertTime(obj.BeginDate, 'day')}—{convertTime(obj.EndDate, 'day')}</h3>
                            </span>
                        </div>
                        <ClearFloat />
                    </StarList>
                </div>
            );
        };
        return (
            <>
                <CountryList>
                    <NavBar>
                        <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                        <span>艺人合作</span>
                        <ClearFloat />
                        <div style={{ display: 'flex' }}>
                            <Search onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/searchInvitation" }))}><i className="iconfont">&#xe7b4;</i><span>输入搜索的关键词</span></Search>
                            {(info.IsStarUser || info.IsBussUser) && <MessageWrapper onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "发布艺人邀约", buttonTitle: "发布", weburl: "/YaoYue" }))}>
                                <img src={require("../../images/fabuyaoyue.png")} alt="" />
                                <span>艺人邀约</span>
                            </MessageWrapper>}
                        </div>
                    </NavBar>
                    <div style={{ height: '9.5rem' }}></div>
                    <BigBanner type={62} userPID={this.state.userPID} />
                    <NewStar>
                        <NewStarTitle>新加入艺人<span onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", title: "中乐之星", weburl: "/star/AllStarList" }))}>更多 {">"}</span></NewStarTitle>
                        <NewStarBow>
                            {
                                starData.map(item => {
                                    return (
                                        <div onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/starDetail/" + item.UserPID }))}>
                                            <div>
                                                <img src={item.HeadImg} alt="" />
                                            </div>
                                            <span>{item.UserName}</span>
                                        </div>
                                    )
                                })
                            }
                        </NewStarBow>
                        <div className='fengexian' style={{ marginTop: "20px" }} />
                    </NewStar>
                    <Label cb={this.getListRefresh} list={comTypes} />
                    {/* <ListView
                        initialListSize={8}
                        key={'1'}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 30, textAlign: 'center' }}>
                                {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                            </div>)}
                        renderRow={row}
                        useBodyScroll
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                        onEndReached={this.onEndReached}
                        pageSize={NUM_ROWS}
                    /> */}
                    <PullToRefresh
                        onRefresh={async () => {
                            await sleep(1000)
                            await this.onRefresh();
                        }}
                    >
                        {
                            data.map((obj, indexKey) => (
                                <div key={indexKey}>
                                    <StarList onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "详情", weburl: "/invitationDetail/" + obj.ID }))}>
                                        <h1>{obj.Title}</h1>
                                        <div>
                                            <div>
                                                {
                                                    obj.InvitationType?.split(',').map((d, index) => (
                                                        <span key={index}>{d}</span>
                                                    ))
                                                }
                                            </div>
                                            <h2>{obj.MoneyRange}</h2>
                                        </div>
                                        <ClearFloat />
                                        <div>
                                            <span style={{ width: '30%' }}>
                                                <img src={require("../../images/StarDidian.png")} alt="" />
                                                <h3>{obj.Place}</h3>
                                            </span>
                                            <span style={{ width: '65%', marginLeft: '5%' }}>
                                                <img src={require("../../images/StarTime.png")} alt="" />
                                                <h3>{convertTime(obj.BeginDate, 'day')}—{convertTime(obj.EndDate, 'day')}</h3>
                                            </span>
                                        </div>
                                        <ClearFloat />
                                    </StarList>
                                </div>
                            ))
                        }
                    </PullToRefresh>
                    <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
                </CountryList>
            </>
        )
    }
}


ReactDOM.render(<StarHome />, document.getElementById('root'));
export default StarHome;
