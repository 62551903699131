import * as constants from './constants';
import {fromJS} from 'immutable';

const defaultState=fromJS({
    leveloneclicked:"mycollect",
    mycollectleveltwoclicked:"brands",
    mycommunityleveltwoclicked:'mycommunitypost',
    myevaluationleveltwoclicked:'myevaluationpost',
    myauditionleveltwoclicked:'myauditionpost',
    focusandfansleveltwoclicked:'myfocus',
    isComponentUser:false
});

export default(state=defaultState,action)=>{
    if(action.type===constants.MY_COLLECT_CLICK){
        return state.set('leveloneclicked','mycollect')
    }
    if(action.type===constants.MY_COMMUNITY_CLICK){
        return state.set('leveloneclicked','mycommunity')
    }
    if(action.type===constants.MY_EVALUATION_CLICK){
        return state.set('leveloneclicked','myevaluation')
    }
    if(action.type===constants.MY_AUDITION_CLICK){
        return state.set('leveloneclicked','myaudition')
    }
    if(action.type===constants.FOCUS_AND_FANS_CLICK){
        return state.set('leveloneclicked','focusandfans')
    }
    if(action.type===constants.BRAND_CLICK){
        return state.set('mycollectleveltwoclicked','brands')
    }
    if(action.type===constants.PRODUCT_CLICK){
        return state.set('mycollectleveltwoclicked','product')
    }
    if(action.type===constants.NEWS_CLICK){
        return state.set('mycollectleveltwoclicked','news')
    }
    if(action.type===constants.EXHIBITION_CLICK){
        return state.set('mycollectleveltwoclicked','exhibition')
    }
    if(action.type===constants.POST_CLICK){
        return state.set('mycollectleveltwoclicked','post')
    }
    if(action.type===constants.EVALUATION_CLICK){
        return state.set('mycollectleveltwoclicked','evaluation')
    }
    if(action.type===constants.AUDITION_CLICK){
        return state.set('mycollectleveltwoclicked','audition')
    }
    if(action.type===constants.MY_COMMUNITY_POST_CLICK){
        return state.set('mycommunityleveltwoclicked','mycommunitypost')
    }
    if(action.type===constants.MY_COMMUNITY_COMMENT_CLICK){
        return state.set('mycommunityleveltwoclicked','mycommunitycomment')
    }
    if(action.type===constants.MY_COMMUNITY_LIKE_CLICK){
        return state.set('mycommunityleveltwoclicked','mycommunitylike')
    }
    if(action.type===constants.MY_FOCUS_CLICK){
        return state.set('focusandfansleveltwoclicked','myfocus')
    }
    if(action.type===constants.MY_FANS_CLICK){
        return state.set('focusandfansleveltwoclicked','myfans')
    }
    if(action.type===constants.MY_EVALUATION_POST_CLICK){
        return state.set('myevaluationleveltwoclicked','myevaluationpost')
    }
    if(action.type===constants.MY_EVALUATION_COMMENT_CLICK){
        return state.set('myevaluationleveltwoclicked','myevaluationcomment')
    }
    if(action.type===constants.MY_EVALUATION_LIKE_CLICK){
        return state.set('myevaluationleveltwoclicked','myevaluationlike')
    }
    if(action.type===constants.MY_AUDITION_LIKE_CLICK){
        return state.set('myauditionleveltwoclicked','myauditionlike')
    }
    if(action.type===constants.MY_AUDITION_POST_CLICK){
        return state.set('myauditionleveltwoclicked','myauditionpost')
    }
    if(action.type===constants.COMPONENT_USER_CLICK){
        return state.set('leveloneclicked','iscomponentuser')
    }
    return state;
}