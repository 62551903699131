import React, {
    Component
} from 'react';
import { List } from 'antd-mobile-v2';
import {
    SettingsWrapper,
} from '../MycenterPortraitStyle';
import { Modal, Toast } from 'antd-mobile-v2';

import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";
import httpAxios2 from "../../../../http/httpAxios2";

const alert = Modal.alert;
const Item = List.Item;

class BangDing extends Component {
    state = {
        info: '',
        wxNickName: '',
        weixin: false,
        userId: ''
    };

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
            that.bindingStatus(data.UserPID);
        };

        window.bound = function (state) {
            //state 1成功，2已绑定，3绑定失败，4程序出错
            if (state < 3) {
                that.setState({ weixin: true });
                that.bindingStatus(that.state.userId);
            }
        }

    }
    //看当前三方绑定状态
    bindingStatus = async (id) => {
        const res = await httpAxios(api.getUnBoundStatus, {
            userPID: id,
        })
        if (res && res.Data) {
            // alert(res.Data.wxNickName);
            this.setState({
                weixin: res.Data.wx,
                wxNickName: res.Data.wxNickName,
            })
        }
    }

    //解绑确认弹窗
    unbindModal = () => {

        // this.unbind('1');
    }


    //解除三方绑定
    unbind = async (state) => {
        const res = await httpAxios(api.unBoundUser, {
            userPID: this.state.userId,
            state: state
        })
        if (res.Code == 200) {
            // alert(res.Message);
            Toast.info('解除绑定成功！');
            this.bindingStatus(this.state.userId);
        }
    }

    render() {
        const {
            info,
            weixin,
            wxNickName,
        } = this.state;
        return (
            <SettingsWrapper>
                <List renderHeader={() => ''}>
                    {
                        weixin ? <Item className='listheight' extra={'已绑定'} arrow="horizontal" onClick={() =>
                            alert('解除绑定', '是否要解除微信账号绑定？', [
                                { text: '取消', onPress: () => console.log('cancel') },
                                { text: '确定', onPress: () => { this.unbind('1') } },
                            ])
                        }
                        >微信:{wxNickName}</Item> : <Item className='listheight' extra={'未绑定'} arrow="horizontal" onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "boundweixin" }))} >微信绑定</Item>
                    }
                </List>
            </SettingsWrapper>
        )
    }
}

export default BangDing;