/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    DividingLine,
} from '../../common/style';
import {
    SearchWrapper,
    Lately,
    LatelyText,
    StarList,
    ClearFloat
} from './style';
import {
    convertTime
} from '../../utils/utils';

import Label from "./Label";
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class SearchInvitation extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userPID: '',
            value: "",
            searchList: localStorage.getItem('LATELY_SEARCHINVITATION_VALUE') == null ? [] : localStorage.getItem('LATELY_SEARCHINVITATION_VALUE').split(","),
            searching: false,

            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            hasMore: true,
            rowCount: 0,

            orderType: '2',

            // liveID: 1,
            // livedata: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }
        window.searchInfo = function (keyWork) {
            if (keyWork != "") {
                that.setState({
                    value: keyWork
                });
                that.getList(null, keyWork, null);
            };
            that.setState({
                refreshing: true,
            });
            that.getData(keyWork);

        }

        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    //初始查询数据
    getData = async (value) => {
        let hasItem = this.state.searchList.filter(item => item == value);
        if (hasItem.length == 0) {
            let list = [value, ...this.state.searchList];
            localStorage.setItem('LATELY_SEARCHINVITATION_VALUE', list.toString());
            // this.setState({ searchList: list });
        }
        this.setState({
            searchList: localStorage.getItem('LATELY_SEARCHINVITATION_VALUE').split(",")
        });
        // this.getList(null, null, null);
    }

    getListRefresh = async (targetID, value, listType) => {
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, null, null);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }
    //按历史查询
    searchDataOld = async (value) => {
        this.setState({
            value: value,
        });

        this.getList(null, value, null);

        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchtitle", keyWork: value }));
    }

    getList = async (targetID, value, listType) => {

        if (value == null) {
            value = "0";
        }
        else {
            if (this.state.value != "") {
                value = this.state.value;
            }
        }

        let params;
        params = {
            "type": 1,
            "search": value,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
        }

        // alert(JSON.stringify(params));

        const res = await httpAxios(api.getArtistInvitationList, params);
        // alert(JSON.stringify(res));
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            // alert(JSON.stringify(res.Data));
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                        refreshing: false,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];
                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.setState({
                    data: [],
                    targetID,
                })
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };
    //清空历史记录
    clearSearchOld = () => {
        localStorage.removeItem('LATELY_SEARCHINVITATION_VALUE');
        this.setState({ searchList: [] });
    }

    render() {
        const {
            data,
            comTypes,
            livedata,
            targetID,
            searching,
            searchList,
            value,
        } = this.state;
        let detailType;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <StarList onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "详情", weburl: "/invitationDetail/" + obj.ID }))}>
                        <h1>{obj.Title}</h1>
                        <div>
                            <div>
                                {
                                    obj.InvitationType?.split(',').map((d, index) => (
                                        <span key={index}>{d}</span>
                                    ))
                                }
                            </div>
                            <h2>{obj.MoneyRange}</h2>
                        </div>
                        <ClearFloat />
                        <div>
                            <span style={{ width: '30%' }}>
                                <img src={require("../../images/StarDidian.png")} alt="" />
                                <h3>{obj.Place}</h3>
                            </span>
                            <span style={{ width: '65%', marginLeft: '5%' }}>
                                <img src={require("../../images/StarTime.png")} alt="" />
                                <h3>{convertTime(obj.BeginDate, 'day')}—{convertTime(obj.EndDate, 'day')}</h3>
                            </span>
                        </div>
                        <ClearFloat />
                    </StarList>
                </div>
            );
        };
        return (<SearchWrapper>
            {value != "" ?
                <>
                    <Label cb={this.getListRefresh} list={comTypes} />
                    <div style={{ height: '3rem' }}></div>
                    <ListView
                        initialListSize={8}
                        key={'1'}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (
                            <div style={{ padding: 30, textAlign: 'center' }}>
                                {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                            </div>)}
                        renderRow={row}
                        useBodyScroll
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                        onEndReached={this.onEndReached}
                        pageSize={NUM_ROWS}
                    />
                </> :
                <>
                    <Lately>
                        <div><i className="iconfont">&#xe6f8;</i><span>历史搜索</span></div>
                        <a onClick={() => this.clearSearchOld()}>清空历史</a>
                    </Lately>
                    <LatelyText>
                        {
                            searchList.map(item => (
                                item != "" && <div onClick={() => this.searchDataOld(item)}>{item}</div>
                            ))
                        }
                    </LatelyText>
                    <Lately style={{ clear: 'both' }}><DividingLine /></Lately>
                    {/* 隐藏ListView不能去掉，不然ReactDOM高度会报错 */}
                    <ListView
                        style={{ display: 'none' }}
                        initialListSize={8}
                        key={'1'}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderRow={row}
                        useBodyScroll
                        onEndReached={this.onEndReached}
                        pageSize={NUM_ROWS}
                    />
                </>

            }
        </SearchWrapper>);
    }
}

ReactDOM.render(<SearchInvitation />, document.getElementById('root'));
export default SearchInvitation