import styled from 'styled-components';
import {
  theme, themenew
} from '../../../../../common/color';

export const NavBar = styled.div`
  width:100%;
  height:3rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.375rem;
    color:${themenew.text_color};
    margin-top:3.6rem;
    font-weight:700;
  }
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:4%;
  margin-top:3.5rem;
  float:left;
`
export const SearchIcon = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-right:4%;
  margin-top:3.5rem;
  float:right;
`

export const NewsDetailWrapper = styled.div`
  width:92%;
  margin:0 auto;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
`

export const NewsItem = styled.div`
  width:100%;
  display:flex;
  padding-top:0.8rem;
  padding-bottom:0.8rem ;
`

export const NewsImgWrap = styled.div`
  width:6.25rem;
  height:6.25rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right:0.875rem;
  >img{
    width:100%;
    min-height:6.25rem;
    object-fit: cover;
  }
`

export const NewsTitleOneImg = styled.div`
  flex:1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height:1.7rem;
  font-size:1.125rem;
  line-height:1.7rem;
  color:${themenew.text_color};
`
export const NewsRight = styled.div`
  flex: 1;
  height: 6.25rem;
  position: relative;
`
export const NewsTitle = styled.div`
  flex:1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height:3.4rem;
  font-size:1.125rem;
  line-height:1.7rem;
  color:${themenew.text_color};
`
export const NewsSubTitle = styled.div`
  width:100% ;
  font-size:0.725rem;
  color:${themenew.subtext_color};
  line-height: 1rem;
`

export const NewsPub = styled.div`
  position:absolute ;
  width:100%;
  line-height:1.5rem;
  bottom: 0;
  margin-bottom:-0.7rem ;
  text-align:right ;
  >i{
    font-size:1.5rem;
    color:${themenew.subtext_color};
    margin-left:1rem ;
  }
`