import styled from 'styled-components';
import {
    theme, themenew
} from '../../../../common/color';

export const CommentBox = styled.div`
  width: 100%;
  padding: 1rem 0;
`
export const CommentBoxLeft = styled.div`
  width: 12%;
  float: left;  
  >img{
    width: 2.25rem;
    height: 2.25rem;
    border: 1px solid #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 50%;
  }
`
export const CommentBoxRight = styled.div`
    width: 88%;
    display: inline-block;
    >h1{
    font-size: 0.88rem;
    color: ${themenew.text_color};
        >span{
            float: right;
            font-size: 0.75rem;
            color: ${themenew.subtext_color};
        }
    }
    >h2{
        font-size: 0.88rem;
        color: ${themenew.subtext_color};
        margin-top: 0.5rem;
    }
    >h3{
        font-size: 0.88rem;
        color: ${themenew.text_color};
        line-height: 1.2rem;
        margin-top: 1rem;
    }
    >div{
        width: 100%;
        background: #F7F7F7;
        border-radius: 4px;
        color: #7C8698;
        font-size: 0.88rem;
        padding: 0.5rem 0;
        margin-top: 1rem;
        >span{
            display: block;
            color: ${themenew.link_color};
            margin: 0 3%;
            line-height: 1.2rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        >div{
            width: 100%;
            border-top:1px #D6D6D6 solid;
            margin:0.375rem 0;
        }
        >h1{
            display: block;
            color: ${themenew.subtext_color};
            margin: 0 3%;
            line-height: 1.2rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
`
export const SystemBox = styled.div`
    padding: 1.25rem 0;
    >div{
        font-size: 0.88rem;
        color: ${themenew.text_color};
        margin-bottom: 0.75rem;
        >span{
            float: right;
            font-size: 0.75rem;
            color: ${themenew.subtext_color}; 
        }
    }
    >span{
        font-size: 0.88rem;
        color: ${themenew.subtext_color}; 
    }
`
