import React, {
    Component, Fragment
} from 'react';

import {
    Wrapper,
    CountryList,
    SubTitle,
    BrandAndProduct
} from './style';
import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';
import {
    Checkbox,
} from 'antd';

class MyBrandsCenterAddBrandCheck extends Component {
    constructor() {
        super();
        this.state = {
            isTouching: false,
            userPID: '',
            dataBrandItems: [],
            brandID: 0
        }

        // this.isTouching = false;
        this.boxClientTop = 148;
        // this.lastChar = 'A';
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.getChar = this.getChar.bind(this);
        this.gotoChar = this.gotoChar.bind(this);
    }

    touchStart(e) {
        // e.preventDefault();
        // this.isTouching = true;
        this.setState({ isTouching: true });
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchMove(e) {
        e.preventDefault();
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchEnd(e) {
        e.preventDefault();
        this.setState({ isTouching: false });
    }
    getChar(clientY) {
        const charHeight = this.refs.charBar.offsetHeight / this.state.charStr.length;
        const index = Math.floor((clientY - this.boxClientTop) / charHeight);
        return this.state.charStr[index];
    }
    gotoChar(char) {
        if (char === this.lastChar) {
            return false;
        }
        this.setState({ lastChar: char });
        // this.lastChar = char;
        if (char === '*') {
            this.refs.countryList.scrollTop = 0;
        } else if (char === '#') {
            this.refs.countryList.scrollTop = this.refs.countryList.scrollHeight;
        }
        const target = document.querySelector('[data-en="' + char + '"]');

        if (target) {
            // target.scrollIntoView();
            window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
        }
    }

    componentDidUpdate() {
        var that = this;
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "没有找到要关联的产品", buttonTitle: "保存", weburl: "/MyBrandsCenter/ApplyBrand" }))
        }
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {

            that.setState({
                userPID: data.UserPID
            }, () => {
                that.getCompanyProducts(that.props.match.params.id);
            })
        }

        //D2DE406E-4BE2-42C2-82A9-6CFE27CD0BB7
        // this.setState({
        //     userPID: 'D2DE406E-4BE2-42C2-82A9-6CFE27CD0BB7'
        // }, () => {
        //     this.getCompanyProducts(this.props.match.params.id);
        // })
    }


    getCompanyProducts = async (brandID) => {
        this.setState({ brandID: brandID });

        const res = await httpAxios(api.getCompanyProducts, {
            userBID: this.state.userPID,
            brandID: brandID
        })
        if (res) {
            this.setState({
                dataBrandItems: res.Data
            })
        }
    }

    handleSaveCompanyProduct = async (e) => {
        console.log(e)
        let checked = e.target.checked;
        let pid = e.target.id;

        const res = await httpAxios(api.addCompanyProduct, {
            userBID: this.state.userPID,
            ProductID: pid,
            Checked: checked
        })
        if (res) {
            await this.getCompanyProducts(this.state.brandID);
        }
    };

    render() {
        const {
            dataBrandItems
        } = this.state;
        return (
            <Wrapper>
                <CountryList>
                    {dataBrandItems.map(item => (
                        <Fragment key={item.lable}>
                            <SubTitle>
                                {item.lable}
                            </SubTitle>
                            {
                                item.include.map(subItem => (
                                    <BrandAndProduct key={subItem.ID}>
                                        <div>
                                            <div>
                                                {subItem.ProductName}
                                            </div>
                                            <span onClick={this.checkProtocol}>
                                                <Checkbox
                                                    id={"" + subItem.ID + ""}
                                                    checked={subItem.Checked}
                                                    onChange={this.handleSaveCompanyProduct}
                                                />
                                            </span>
                                        </div>
                                        <img src={subItem.MainPicLandScape} alt="" />
                                    </BrandAndProduct>
                                ))}
                        </Fragment>
                    ))}
                </CountryList>
            </Wrapper >
        )
    }
}

export default MyBrandsCenterAddBrandCheck