import React, {
	Component
} from 'react';
import Content from './components/Content';
import {
	CommunityWrapper,
} from './style';

class News extends Component {
	render() {
		return (
			<CommunityWrapper>
                <Content/>
            </CommunityWrapper>
		)
	}
}

export default News