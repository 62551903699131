import React, {
    Component, createRef
} from 'react';

import {
    Form,
    Input,
    message
} from 'antd';
import { DatePicker } from 'antd-mobile-v2';
import {
    Wrapper,
    DatePickerWrapper
} from './AddLiveApplyStyle';
import reloadIMG from '../../../../images/addimg.png'
import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';

//时间选择组件
const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
// GMT is not currently observed in the UK. So use UTC now.
const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));

// Make sure that in `time` mode, the maxDate and minDate are within one day.
let minDate = new Date(nowTimeStamp - 1e7);
const maxDate = new Date(nowTimeStamp + 1e7);
// console.log(minDate, maxDate);
if (minDate.getDate() !== maxDate.getDate()) {
    // set the minDate to the 0 of maxDate
    minDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());
}

function formatDate(date) {
    /* eslint no-confusing-arrow: 0 */
    const pad = n => n < 10 ? `0${n}` : n;
    const dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
    const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
    return `${dateStr} ${timeStr}`;
}



class AddLiveApply extends Component {
    state = {
        loading: false,
        BrandLogo: '',
        userPID: '',
        dpValue: now,
        visible: false,
    }

    formRef = createRef();

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            }, () => {

                //编辑信息
                var id = that.props.location.search.replace('?', '').trim();
                if (id !== '') {
                    httpAxios(api.getApplyLiveVideo, {
                        userBID: that.state.userPID,
                        ID: id
                    }).then(res => {
                        if (res.Code === 200) {
                            let item = res.Data;
                            const dateFormat = "YYYY-MM-DD HH:mm";
                            that.formRef.current.setFieldsValue({
                                title: item.Title,
                                reason: item.LiveReason,
                                introduction: item.Intro,
                            });

                            that.setState({ BrandLogo: item.ImageUrl, dpValue: new Date(item.BeginTime.replace(/-/g, "/")) });
                        }
                    });
                }
            })
        }


        window.logoImage = function (imageUrl) {
            if (imageUrl !== "") {
                that.setState({
                    BrandLogo: imageUrl
                })
            }
        }

        //保存
        window.saveInfo = function () {
            that.formRef.current.submit();
        };
    }

    //显示上传图片
    handleLogoImage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadLogoImage" }));
    }

    editApplyLiveVideo = async (data) => {
        const res = await httpAxios(api.editApplyLiveVideo, {
            ID: data.LiveID,
            Title: data.LiveName,
            BeginTime: data.LiveDate,
            Intro: data.Introduction,
            ImageUrl: data.LiveImage,
            LiveReason: data.LiveReason
        })
        if (res.Code === 200) {
            message.info('修改成功，进入再次审核状态，请等待通过');
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }

    addLiveExperience = async (data) => {
        const res = await httpAxios(api.addApplyLiveVideo, {
            UserBID: this.state.userPID,
            Title: data.LiveName,
            BeginTime: data.LiveDate,
            Intro: data.Introduction,
            ImageUrl: data.LiveImage,
            LiveReason: data.LiveReason
        })
        if (res.Code === 200) {
            message.info('申请成功，请等待通过');
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }

    addLive = async (values) => {

        var id = this.props.location.search.replace('?', '').trim();

        const dateFormat = "YYYY-MM-DD HH:mm";

        var data = {};
        if (this.state.BrandLogo === '' || this.state.BrandLogo.indexOf("https://") === -1) {
            message.info('请上传直播封面图!');
            return;
        }

        data["LiveImage"] = this.state.BrandLogo;
        data["LiveName"] = values.title;
        data["LiveReason"] = values.reason;
        data["LiveDate"] = formatDate(this.state.dpValue);
        data["Introduction"] = values.introduction;
        data["LiveID"] = id;

        if (id === '') {
            //保存数据
            this.addLiveExperience(data);
        } else {
            //编辑数据
            this.editApplyLiveVideo(data);
        }
    }

    render() {
        const {
            BrandLogo,
        } = this.state;
        return (
            <Wrapper>
                <Form layout="vertical" onFinish={(values) => this.addLive(values)} ref={this.formRef}>
                    <Form.Item label="请上传直播封面图 不超过5MB">
                        <img src={BrandLogo || reloadIMG} onClick={this.handleLogoImage} alt="" />
                    </Form.Item>
                    <Form.Item label="请填写直播原因（不超过200字）" name="reason" rules={[{ required: true, message: '请填写直播原因!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="请填写直播开始时间" name="livedate" >
                        <DatePickerWrapper
                            extra={this.state.dpValue && formatDate(this.state.dpValue)}
                            onClick={() => this.setState({ visible: true })}
                        >
                            {formatDate(this.state.dpValue)}
                        </DatePickerWrapper>
                        <DatePicker
                            visible={this.state.visible}
                            value={this.state.dpValue}
                            onOk={date => this.setState({ dpValue: date, visible: false })}
                            onDismiss={() => this.setState({ visible: false })}
                        />
                    </Form.Item>
                    <Form.Item label="请填写直播标题" name="title" rules={[{ required: true, message: '请填写直播标题!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="请填写直播简介" name="introduction" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="link">
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
            </Wrapper>
        )
    }
}

export default AddLiveApply
