import React, {
    Component
} from 'react';
import {
    Wrapper,
    Title,
    Dec
} from '../FileStyle';

class ServiceAgreement extends Component {
    render() {
        return (
            <Wrapper>
                <Title>版权声明</Title>
                <Dec>
                    1. 用户发表并将其上传到本网站的任何内容，本社区在全世界范围内不限形式和载体地享有永久的、不可撤销的、免费的、非独家的使用权和转授权的权利，包括但不限于修改、复制、发行、展览、改编、汇编、出版、翻译、信息网络传播、广播、表演和再创作及著作权法等法律法规确定的其他权利。
                </Dec>
                <Dec>
                    2. 本社区特有的标识、版面设计、编排方式等版权均属本社区享有，未经本社区许可，不得任意复制或转载。
                </Dec>
                <Dec>
                    3. 用户从本社区的服务中获得的信息，未经本社区许可，不得任意复制或转载。
                </Dec>
                <Dec>
                    4. 使用本社区的任何内容均应注明"来源于中悦之声中乐之声社区(www.51audio.com)"及署上作者姓名，按法律规定需要支付稿酬的，应当通知本社区及作者及支付稿酬，并独立承担一切法律责任。
                </Dec>
                <Dec>
                    5. 本社区享有所有作品用于其它用途的使用权和优先权，包括但不限于网站、电子杂志、平面出版等，作者在本社区发布内容时视为同意本条款。
                </Dec>
                <Dec>
                    6. 本社区所有内容仅代表作者自己的立场和观点，与本社区无关，由作者本人承担一切法律责任。
                </Dec>
                <Dec>
                    7. 恶意转载本社区内容的，本社区保留将其诉诸法律的权利。
                </Dec>
                <Title style={{ marginTop: '2rem' }}>版权投诉须知</Title>
                <Dec>
                    尊重合法版权，反对侵权盗版是本网站的宗旨之一，为维护合法权利人的合法权益，中乐之声社区特开通“版权投诉通道”，合法权利人发现权益受到侵害时，可按本须知进行投诉。
                </Dec>
                <Dec>
                    1. 合法权利人应当阅读“填写说明”后按以下表格填写投诉内容，详细内容请点击：<br />


                </Dec>
                <p style={{ marginLeft: '3rem', textAlign: 'left' }}>
                    (1) 《要求删除或断开链接侵权网络内容的通知》填写说明；<br />
                    (2) 《要求删除或断开链接侵权网络内容的通知》；<br />
                    (3) 《要求恢复被删除或断开链接的网络内容的说明》填写说明；<br />
                    (4) 《要求恢复被删除或断开链接的网络内容的说明》；<br />

                </p>
                <Dec>
                    2. 合法权利人如还有其它疑问，可直接登陆中乐之声在线客服平台咨询。
                </Dec>
                <Dec>
                    3. 中乐之声客服收到完整的投诉材料后，会立即移交相关部门进行处理。提交的投诉材料不完整的，视为投诉未成功，中乐之声客服不予处理，投诉人有疑问或异议的，请致电中乐之声客服投诉电话4008-0202-01。
                </Dec>
                <Dec>
                    4. 合法权利人应对其提供材料的真实性负责并承担由此造成的全部法律责任。
                </Dec>
                <Dec>
                    5. 为使您的投诉顺利，避免您的损失，请务必按本须知的规定谨慎投诉。
                </Dec>
            </Wrapper>
        )
    }
}

export default ServiceAgreement