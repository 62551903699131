import React, {
	Component
} from 'react';
import Content from './components/CompanyContent';
import {
	CommunityWrapper,
} from './style';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

class CompanyAuthor extends Component {
	state = {
		info: {},
		userPID: '',
		authorID: this.props.match.params.id,
	}

	componentDidMount() {
		this.getUserBInfo();
		var that = this;
		window.userInfo = function (data, params) {
			that.setState({
				userPID: data.UserPID
			})
		}
	}

	getUserBInfo = async () => {
		const res = await httpAxios(api.getUserBInfo, {
			userBID: this.props.match.params.id
		})
		if (res) {
			this.setState({
				info: res.Data
			})
		}
	}
	render() {
		const {
			info,
			userPID,
			authorID
		} = this.state
		return (
			<CommunityWrapper>
				<Content info={info} userPID={userPID} authorID={authorID} />
			</CommunityWrapper>
		)
	}
}

export default CompanyAuthor