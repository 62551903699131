import React, { Component, createRef } from 'react'
import {
    Checkbox,
    // message
} from 'antd';

import {
    Button,
    Popup,
    Form,
    Input,
    Toast
} from 'antd-mobile';
// import { Toast } from 'antd-mobile-v2';
import {
    MoreButtonBG,
    MoreButton,
    File,
    Focus,
    Code,
    DiyAppForm,
    DiyAppFormLeft,
    DiyAppFormRight
} from './style';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

let isiOS = false;
let isAndroid = false;
if (typeof window !== 'undefined') {
    let u = window.navigator.userAgent;
    isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
}

class SendCode extends Component {

    state = {
        time: '',
        phoneHash: ''
    }

    trick = () => {
        let {
            time
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }
    sendCode = async () => {
        let value = this.props.phone;
        value = value.trim();
        let myreg1 = /^1[3456789]\d{9}$/
        if (!myreg1.test(value)) {
            Toast.show({
                content: '请输入正确的手机号码',
            })
            return
        }
        if (!this.props.phone) {
            Toast.show({
                content: '请输入手机号',
            })
            return
        }
        // try {
        const res = await httpAxios(this.props.url, {
            phone: this.props.phone
        })
        if (res) {
            clearTimeout(this.timeId);
            this.setState({
                time: 59,
                phoneHash: res.Data
            })
            this.timeId = setInterval(this.trick, 1000);
            this.props.phoneHashData(this.state.phoneHash);
        }
    }

    render() {
        return (
            <span>
                {
                    this.state.time > 0 ? this.state.time + 's 后重发' : <a onClick={this.sendCode}>发送验证码</a>
                }
            </span>
        )
    }
}


export default class DijiaModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hqdjPopup: false,
            userData: this.props.userData,
            PhoneTai: false,
            phoneStatus: '',
            phoneHash: '',
            phone: '',
            userCode: '',
            userName: '',
            confirmProtocol: false,
            dijiaImg: true,
        }
    }
    formRef = createRef()
    componentDidMount() {

    }
    componentWillReceiveProps() {
        this.setState({
            phone: this.props.userData.Phone,
            userName: this.props.userData.PNickName
        });
    }
    showPhomeErro = () => {
        this.setState({
            phoneStatus: 'error',
        })
    }
    phoneHashData = (event) => {
        this.setState({
            phoneHash: event
        });
    }
    checkProtocol = () => {
        this.setState({
            confirmProtocol: !this.state.confirmProtocol
        })
    }
    checkUserName = (type, e) => {
        let value = e.target.value;
        value = value.trim();
        let myreg1 = /^1[3456789]\d{9}$/
        if (!myreg1.test(value)) {
            this.setState({
                phoneVerification: false,
            })
            return
        }
        this.setState({
            phoneVerification: true,
        })
    }

    render() {

        const {
            hqdjPopup,
            userData,
            PhoneTai,
            phone,
            userName,
            confirmProtocol,
            dijiaImg,
        } = this.state;
        return (
            <>
                {
                    this.props.type == "product" ? <a
                        onClick={() => {
                            this.setState({
                                hqdjPopup: true,
                                phone: this.props.userData.Phone,
                                userName: this.props.userData.PNickName
                            })
                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "getgpsinfo", value: 1 }));
                        }}
                    >
                        获取该产品底价
                    </a> : <>
                        <Focus
                            onClick={() => {
                                this.setState({
                                    hqdjPopup: true,
                                    phone: this.props.userData.Phone,
                                    userName: this.props.userData.PNickName
                                })
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "getgpsinfo", value: 1 }));
                            }}
                        >
                            获取底价｜预约试听
                        </Focus>{
                            dijiaImg && <div style={{ position: 'fixed', bottom: '100px', right: '0', height: '96px', width: '78px' }}>
                                <div
                                    style={{ width: '21px', height: '21px', position: 'absolute', zIndex: '1' }}
                                    onClick={() => {
                                        this.setState({
                                            dijiaImg: false
                                        })
                                    }}
                                >
                                    <img style={{ width: '21px', height: '21px' }} src={require("../../images/guandijia.png")} alt="" />
                                </div>
                                <div
                                    style={{ width: '75px', height: '88px', position: 'absolute', right: '0', bottom: '0' }}
                                    onClick={() => {
                                        this.setState({
                                            hqdjPopup: true,
                                            phone: this.props.userData.Phone,
                                            userName: this.props.userData.PNickName
                                        })
                                        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "getgpsinfo", value: 1 }));
                                    }}
                                >
                                    <img style={{ width: '75px', height: '88px' }} src={require("../../images/dijia.png")} alt="" />
                                </div>
                            </div>
                        }
                    </>
                }

                <Popup
                    visible={hqdjPopup}
                    onMaskClick={() => {
                        this.setState({
                            hqdjPopup: false
                        })
                    }}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: phone == "" || this.props.userData.Phone != phone ? '430px' : '400px'
                    }}
                >
                    <div style={{ padding: '20px 12px 20px 0', position: 'relative' }}>
                        <div style={{ marginBottom: '14px' }}>
                            <div style={{ textAlign: 'center', color: '#203152', fontSize: '18px', fontWeight: '500' }}>获取优惠｜预约试听</div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '17px',
                                    right: '16px'
                                }}
                                onClick={() => {
                                    this.setState({
                                        hqdjPopup: false
                                    })
                                }}
                            ><i className='iconfont' style={{ fontSize: '26px' }}>&#xe74c;</i></div>
                        </div>
                        <DiyAppForm>
                            <DiyAppFormLeft>
                                姓名
                            </DiyAppFormLeft>
                            <DiyAppFormRight>
                                <Input
                                    placeholder='请输入姓名'
                                    clearable
                                    onChange={(v) => {
                                        this.setState({ userName: v });
                                    }}
                                    value={userName}
                                />
                            </DiyAppFormRight>
                        </DiyAppForm>
                        <DiyAppForm>
                            <DiyAppFormLeft>
                                手机号
                            </DiyAppFormLeft>
                            <DiyAppFormRight>
                                <Input
                                    placeholder='请输入手机号'
                                    clearable
                                    type='number'
                                    onlyShowClearWhenFocus={false}
                                    onChange={(v) => {
                                        this.setState({ phone: v });
                                    }}
                                    value={phone}
                                />
                            </DiyAppFormRight>
                        </DiyAppForm>
                        {
                            phone == "" || this.props.userData.Phone != phone ?
                                <DiyAppForm>
                                    <DiyAppFormLeft>
                                        短信验证码
                                    </DiyAppFormLeft>
                                    <DiyAppFormRight style={{ display: 'flex' }}>
                                        <div style={{ flex: '1' }}>
                                            <Input placeholder='请输入' clearable name='userCode' onChange={(v) => {
                                                this.setState({ userCode: v });
                                            }} />
                                        </div>
                                        <div style={{
                                            borderLeft: 'solid 1px #eeeeee',
                                            paddingLeft: '12px',
                                            fontSize: '17px',
                                            lineHeight: '22px',
                                            width: '100px',
                                            height: '22px',
                                        }}>
                                            <SendCode url={api.loginCode} phone={phone} showPhomeErro={this.showPhomeErro} phoneHashData={this.phoneHashData.bind(this)} />
                                        </div>
                                    </DiyAppFormRight>
                                </DiyAppForm> : ''
                        }
                        <DiyAppForm>
                            <DiyAppFormLeft>
                                咨询城市
                            </DiyAppFormLeft>
                            <DiyAppFormRight>
                                <div
                                    onClick={() => {
                                        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "modal", modalTitl: '选择省份城市', buttonModalTitle: '', weburl: "/GpsCitySelect" }));
                                    }}
                                >
                                    <Input
                                        placeholder='请选择咨询城市'
                                        value={this.props.selectCity}
                                        readOnly
                                    />
                                </div>
                            </DiyAppFormRight>
                        </DiyAppForm>
                        <DiyAppForm>
                            <DiyAppFormLeft>
                                填写留言
                            </DiyAppFormLeft>
                            <DiyAppFormRight>
                                <div
                                    onClick={() => {
                                        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "modal", modalTitl: '填写留言', buttonModalTitle: '确定', weburl: "/TextAreaMessage" }));
                                    }}
                                >
                                    <Input
                                        placeholder='请输入留言'
                                        value={this.props.liuyan}
                                        readOnly
                                    />
                                </div>
                            </DiyAppFormRight>
                        </DiyAppForm>
                        <MoreButtonBG
                            style={
                                isiOS ? { height: '6.5rem' } : { height: '7.8rem' }
                            }
                        >
                            <File><Checkbox onClick={this.checkProtocol} />&nbsp;&nbsp;我已同意<span onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "中乐之声隐私政策-询价功能", weburl: "/XunjiaFile" }))}>《中乐之声隐私政策-询价功能》</span></File>
                            <MoreButton onClick={async () => {
                                if (!confirmProtocol) {
                                    Toast.show({
                                        content: '请先勾选我同意《中乐之声隐私政策-询价功能》',
                                    })
                                    return
                                }
                                if (userName == '') {
                                    Toast.show({
                                        content: '请输入姓名',
                                    })
                                    return
                                }
                                let flag = 0;
                                if (phone == "" || this.props.userData.Phone != phone) {
                                    //用户登录
                                    let params = {
                                        Account: phone,
                                        Code: this.state.userCode,
                                        PhoneHash: this.state.phoneHash
                                    };

                                    let login = await httpAxios(api.userLoginPhoneApp, params);
                                    if (login.Code == 200) {
                                        //用户app登录信息
                                        // window.ReactNativeWebView.postMessage(JSON.stringify({ type: "islogin", userInfo: JSON.stringify(login.Data) }));

                                    } else if (login.Code == 403) {
                                        Toast.show({
                                            content: '验证码不正确',
                                        })
                                        flag = 1;
                                    }
                                }

                                if (flag == 0) {
                                    //频道类别
                                    let ContentID = 0;
                                    switch (this.props.type) {
                                        case "news":
                                            ContentID = 1;
                                            break;
                                        case "exhibition":
                                            ContentID = 2;
                                            break;
                                        case "evaluation":
                                            ContentID = 3;
                                            break;
                                        case "audition":
                                            ContentID = 4;
                                            break;
                                        case "community":
                                            ContentID = 5;
                                            break;
                                        case "lookback":
                                            ContentID = 7;
                                            break;
                                        case "product":
                                            ContentID = 8;
                                            break;
                                    }

                                    //保存数据
                                    const saveData = {
                                        UserID: this.props.userData.UserPID,
                                        ChannelType: ContentID,
                                        ContentID: this.props.infoId,
                                        Place: this.props.selectCity,
                                        Detail: this.props.liuyan,
                                        Phone: phone,
                                        UserName: userName,
                                    }

                                    const res = await httpAxios(api.askPriceInfo, saveData);
                                    if (res.Code == 200) {
                                        Toast.show({
                                            icon: 'success',
                                            content: '提交成功。',
                                        })
                                        this.setState({
                                            hqdjPopup: false
                                        })
                                    } else {
                                        Toast.show({
                                            icon: 'fail',
                                            content: '提交出现错误，请联系管理员',
                                        })
                                    }
                                }
                            }}>
                                确认提交
                            </MoreButton>
                            <div className='subtitle'>提交信息后经销商会为您电话报价</div>
                        </MoreButtonBG>
                    </div>
                </Popup >
            </>
        )
    }
}