/**
 * created by sunchenyao on 2020/12/17
 */


export const birthday = [
    [{
        "label": "2020年",
        "value": "2020年",
    }, {
        "label": "2019年",
        "value": "2019年",
    }, {
        "label": "2018年",
        "value": "2018年",
    }, {
        "label": "2017年",
        "value": "2017年",
    }, {
        "label": "2016年",
        "value": "2016年",
    }, {
        "label": "2015年",
        "value": "2015年",
    }, {
        "label": "2014年",
        "value": "2014年",
    }, {
        "label": "2013年",
        "value": "2013年",
    }, {
        "label": "2012年",
        "value": "2012年",
    }, {
        "label": "2011年",
        "value": "2011年",
    }, {
        "label": "2010年",
        "value": "2010年",
    }, {
        "label": "2009年",
        "value": "2009年",
    }, {
        "label": "2008年",
        "value": "2008年",
    }, {
        "label": "2006年",
        "value": "2006年",
    }, {
        "label": "2005年",
        "value": "2005年",
    }, {
        "label": "2004年",
        "value": "2004年",
    }, {
        "label": "2003年",
        "value": "2003年",
    }, {
        "label": "2002年",
        "value": "2002年",
    }, {
        "label": "2001年",
        "value": "2001年",
    }, {
        "label": "2000年",
        "value": "2000年",
    }, {
        "label": "1999年",
        "value": "1999年",
    }, {
        "label": "1998年",
        "value": "1998年",
    }, {
        "label": "1997年",
        "value": "1997年",
    }, {
        "label": "1996年",
        "value": "1996年",
    }, {
        "label": "1995年",
        "value": "1995年",
    }, {
        "label": "1994年",
        "value": "1994年",
    }, {
        "label": "1993年",
        "value": "1993年",
    }, {
        "label": "1992年",
        "value": "1992年",
    }, {
        "label": "1991年",
        "value": "1991年",
    }, {
        "label": "1990年",
        "value": "1990年",
    }, {
        "label": "1989年",
        "value": "1989年",
    }, {
        "label": "1988年",
        "value": "1988年",
    }, {
        "label": "1987年",
        "value": "1987年",
    }, {
        "label": "1986年",
        "value": "1986年",
    }, {
        "label": "1985年",
        "value": "1985年",
    }, {
        "label": "1984年",
        "value": "1984年",
    }, {
        "label": "1983年",
        "value": "1983年",
    }, {
        "label": "1982年",
        "value": "1982年",
    }, {
        "label": "1981年",
        "value": "1981年",
    }, {
        "label": "1980年",
        "value": "1980年",
    }, {
        "label": "1979年",
        "value": "1979年",
    }, {
        "label": "1978年",
        "value": "1978年",
    }, {
        "label": "1977年",
        "value": "1977年",
    }, {
        "label": "1976年",
        "value": "1976年",
    }, {
        "label": "1975年",
        "value": "1975年",
    }, {
        "label": "1974年",
        "value": "1974年",
    }, {
        "label": "1973年",
        "value": "1973年",
    }, {
        "label": "1972年",
        "value": "1972年",
    }, {
        "label": "1971年",
        "value": "1971年",
    }, {
        "label": "1970年",
        "value": "1970年",
    }, {
        "label": "1969年",
        "value": "1969年",
    }, {
        "label": "1968年",
        "value": "1968年",
    }, {
        "label": "1967年",
        "value": "1967年",
    }, {
        "label": "1966年",
        "value": "1966年",
    }, {
        "label": "1965年",
        "value": "1965年",
    }, {
        "label": "1964年",
        "value": "1964年",
    }, {
        "label": "1963年",
        "value": "1963年",
    }, {
        "label": "1962年",
        "value": "1962年",
    }, {
        "label": "1961年",
        "value": "1961年",
    }, {
        "label": "1960年",
        "value": "1960年",
    }, {
        "label": "1959年",
        "value": "1959年",
    }, {
        "label": "1958年",
        "value": "1958年",
    }, {
        "label": "1957年",
        "value": "1957年",
    }, {
        "label": "1956年",
        "value": "1956年",
    }, {
        "label": "1955年",
        "value": "1955年",
    }, {
        "label": "1954年",
        "value": "1954年",
    }, {
        "label": "1953年",
        "value": "1953年",
    }, {
        "label": "1952年",
        "value": "1952年",
    }, {
        "label": "1951年",
        "value": "1951年",
    }, {
        "label": "1950年",
        "value": "1950年",
    }, {
        "label": "1959年",
        "value": "1959年",
    }, {
        "label": "1958年",
        "value": "1958年",
    }, {
        "label": "1957年",
        "value": "1957年",
    }, {
        "label": "1956年",
        "value": "1956年",
    }, {
        "label": "1955年",
        "value": "1955年",
    }, {
        "label": "1954年",
        "value": "1954年",
    }, {
        "label": "1953年",
        "value": "1953年",
    }, {
        "label": "1952年",
        "value": "1952年",
    }, {
        "label": "1951年",
        "value": "1951年",
    }, {
        "label": "1950年",
        "value": "1950年",
    }, {
        "label": "1949年",
        "value": "1949年",
    }, {
        "label": "1948年",
        "value": "1948年",
    }, {
        "label": "1947年",
        "value": "1947年",
    }, {
        "label": "1946年",
        "value": "1946年",
    }, {
        "label": "1945年",
        "value": "1945年",
    }, {
        "label": "1944年",
        "value": "1944年",
    }, {
        "label": "1943年",
        "value": "1943年",
    }, {
        "label": "1942年",
        "value": "1942年",
    }, {
        "label": "1941年",
        "value": "1941年",
    }, {
        "label": "1940年",
        "value": "1940年",
    }, {
        "label": "1939年",
        "value": "1939年",
    }, {
        "label": "1938年",
        "value": "1938年",
    }, {
        "label": "1937年",
        "value": "1937年",
    }, {
        "label": "1936年",
        "value": "1936年",
    }, {
        "label": "1935年",
        "value": "1935年",
    }, {
        "label": "1934年",
        "value": "1934年",
    }, {
        "label": "1933年",
        "value": "1933年",
    }, {
        "label": "1932年",
        "value": "1932年",
    }, {
        "label": "1931年",
        "value": "1931年",
    }, {
        "label": "1930年",
        "value": "1930年",
    }, {
        "label": "1929年",
        "value": "1929年",
    }, {
        "label": "1928年",
        "value": "1928年",
    }, {
        "label": "1927年",
        "value": "1927年",
    }, {
        "label": "1926年",
        "value": "1926年",
    }, {
        "label": "1925年",
        "value": "1925年",
    }, {
        "label": "1924年",
        "value": "1924年",
    }, {
        "label": "1923年",
        "value": "1923年",
    }, {
        "label": "1922年",
        "value": "1922年",
    }, {
        "label": "1921年",
        "value": "1921年",
    }, {
        "label": "1920年",
        "value": "1920年",
    }, {
        "label": "1919年",
        "value": "1919年",
    }, {
        "label": "1918年",
        "value": "1918年",
    }, {
        "label": "1917年",
        "value": "1917年",
    }, {
        "label": "1916年",
        "value": "1916年",
    }, {
        "label": "1915年",
        "value": "1915年",
    }, {
        "label": "1914年",
        "value": "1914年",
    }, {
        "label": "1913年",
        "value": "1913年",
    }, {
        "label": "1912年",
        "value": "1912年",
    }, {
        "label": "1911年",
        "value": "1911年",
    }, {
        "label": "1910年",
        "value": "1910年",
    }, {
        "label": "1909年",
        "value": "1909年",
    }, {
        "label": "1908年",
        "value": "1908年",
    }, {
        "label": "1907年",
        "value": "1907年",
    }, {
        "label": "1906年",
        "value": "1906年",
    }, {
        "label": "1905年",
        "value": "1905年",
    }, {
        "label": "1904年",
        "value": "1904年",
    }, {
        "label": "1903年",
        "value": "1903年",
    }, {
        "label": "1902年",
        "value": "1902年",
    }, {
        "label": "1901年",
        "value": "1901年",
    }, {
        "label": "1900年",
        "value": "1900年",
    }],
    [{
        "label": "1月",
        "value": "1月",
    }, {
        "label": "2月",
        "value": "2月",
    }, {
        "label": "3月",
        "value": "3月",
    }, {
        "label": "4月",
        "value": "4月",
    }, {
        "label": "5月",
        "value": "5月",
    }, {
        "label": "6月",
        "value": "6月",
    }, {
        "label": "7月",
        "value": "7月",
    }, {
        "label": "8月",
        "value": "8月",
    }, {
        "label": "9月",
        "value": "9月",
    }, {
        "label": "10月",
        "value": "10月",
    }, {
        "label": "11月",
        "value": "11月",
    }, {
        "label": "12月",
        "value": "12月",
    }],
    [{
        "label": "1日",
        "value": "1日",
    }, {
        "label": "2日",
        "value": "2日",
    }, {
        "label": "3日",
        "value": "3日",
    }, {
        "label": "4日",
        "value": "4日",
    }, {
        "label": "5日",
        "value": "5日",
    }, {
        "label": "6日",
        "value": "6日",
    }, {
        "label": "7日",
        "value": "7日",
    }, {
        "label": "8日",
        "value": "8日",
    }, {
        "label": "9日",
        "value": "9日",
    }, {
        "label": "10日",
        "value": "10日",
    }, {
        "label": "11日",
        "value": "11日",
    }, {
        "label": "12日",
        "value": "12日",
    }, {
        "label": "13日",
        "value": "13日",
    }, {
        "label": "14日",
        "value": "14日",
    }, {
        "label": "15日",
        "value": "15日",
    }, {
        "label": "16日",
        "value": "16日",
    }, {
        "label": "17日",
        "value": "17日",
    }, {
        "label": "18日",
        "value": "18日",
    }, {
        "label": "19日",
        "value": "19日",
    }, {
        "label": "20日",
        "value": "20日",
    }, {
        "label": "21日",
        "value": "21日",
    }, {
        "label": "22日",
        "value": "22日",
    }, {
        "label": "23日",
        "value": "23日",
    }, {
        "label": "24日",
        "value": "24日",
    }, {
        "label": "25日",
        "value": "25日",
    }, {
        "label": "26日",
        "value": "26日",
    }, {
        "label": "27日",
        "value": "27日",
    }, {
        "label": "28日",
        "value": "28日",
    }, {
        "label": "29日",
        "value": "29日",
    }, {
        "label": "30日",
        "value": "30日",
    }, {
        "label": "31日",
        "value": "31日",
    }]
];