import React, { Component } from 'react';
import {
    CommunityDetailWrapper,
    ContentWrapper,
    Title,
    Content,
    SubTitle,
    MoreButton,
    MoreButtonBG
} from './style';
import { Checkbox } from 'antd';
import { Toast } from 'antd-mobile-v2';

import httpAxios from "../../http/httpAxios";
import api from "../../http/api";
import {
    convertTime
} from '../../utils/utils';
import { set } from 'immutable';

class invitationDetail extends Component {

    state = {
        invitationData: [],
        isFocus: false,
        userPID: '',
    }
    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }
        //统计查询结果
        await this.getData();
    }

    getData = async () => {
        let params;
        params = {
            "ID": this.props.match.params.id,
        }
        const res = await httpAxios(api.getArtistInvitationDetail, params)
        this.setState({
            invitationData: res.Data.info,
        })
    }

    setArtistFocus = async () => {
        let params;
        params = {
            "ArtistInvitationID": this.state.invitationData.ID,
            "UserPID": this.state.userPID
        }
        const res = await httpAxios(api.setArtistFocus, params);
        if (res.Code == 200) {
            Toast.info(res.Message);
            this.setState({
                isFocus: true,
            })
        }
    }

    render() {
        const {
            invitationData,
        } = this.state;

        return (
            <CommunityDetailWrapper>
                <ContentWrapper>
                    <Title>{invitationData?.Title}</Title>
                    <SubTitle>
                        <span /><h2>邀约金额</h2><h1>{invitationData?.MoneyRange}</h1>
                    </SubTitle>
                    <SubTitle>
                        <span /><h2>邀约时间</h2><h1> {convertTime(invitationData?.BeginDate, 'day')}-{convertTime(invitationData?.EndDate, 'day')}</h1>
                    </SubTitle>
                    <SubTitle>
                        <span /><h2>邀约地点</h2><h1>{invitationData?.Place}</h1>
                    </SubTitle>
                    <SubTitle>
                        <span /><h2>邀约详情</h2>
                    </SubTitle>
                    <Content>
                        {invitationData?.Detail}
                    </Content>
                </ContentWrapper>
                <MoreButtonBG>
                    <MoreButton onClick={() => { this.setArtistFocus(); }}>
                        感兴趣
                    </MoreButton>
                </MoreButtonBG>
            </CommunityDetailWrapper>
        )
    }
}

export default invitationDetail