import React, {
    Component
} from 'react';
import {
    Wrapper,
    Title,
    Dec
} from '../FileStyle';

class HelpCenter extends Component {
    render() {
        return (
            <Wrapper>
                <Title>法律声明</Title>
                <Dec>
                    北京中悦之声信息技术有限公司（以下简称中悦之声）旗下的中乐之声（www.51audio.com）提醒您：在使用中乐之声各项服务前，请您务必仔细阅读并透彻理解本声明。如果您使用中乐之声，您的使用行为将被视为对本声明全部内容的认可。
                </Dec>
                <Dec>
                    本网站为开放平台，网站提供会员免费注册功能，会员注册后可发布相关企业或者个人信息，凡是违反互联网相关管理条例如涉黄涉赌等违法违规信息我公司会一律删除，对于会员在信息发布过程中涉及侵权的信息，我公司相关免责条框中均有详细说明，对会员发布的信息，一切责任由会员自行承担，不过，我公司也承诺如果企业提供相关侵权证明如营业执照及商标证明等，我公司均在第一时间进行处理，相关内容可查看我公司法律声明。
                </Dec>
                <Dec>
                    中乐之声（www.51audio.com）站内所包涵的资讯、内容、广告等相关信息，均来自互联网络，或由客户或会员自行提供。内容所涉及的相关项目、商品（包括但不限于项目名称、品牌，公司名称、联系人及联络信息，产品的描述和说明，相关图片、视讯等）的信息，客户或会员依法应对其提供的任何信息承担全部责任。
                </Dec>
                <Dec>
                    任何单位或个人认为中乐之声网页内容（包括但不限于中乐之声会员发布的商品信息）可能涉嫌侵犯其合法权益，应该及时向中乐之声提出书面权利通知，并提供身份证明、权属证明、具体链接及详细侵权情况证明。中乐之声在收到上述法律文件后，将会依法尽快移除相关涉嫌侵权的内容。中乐之声转载作品（包括论坛内容）出于传递更多信息之目的，并不意味中乐之声（中乐之声关联企业）赞同其观点或证实其内容的真实性。
                </Dec>
            </Wrapper>
        )
    }
}

export default HelpCenter