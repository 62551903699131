/**
 * created by zhangling on 2019/12/21
 */

//正式环境
const apiUrl = 'https://api.chinaaudio.net.cn/frontend/api';
const userUrl = 'https://api.chinaaudio.net.cn/frontend';

//与魏明利联调环境
// const apiUrl = 'http://192.168.1.52/api';
// const userUrl = 'http://192.168.1.52';

// const apiUrl = 'http://192.168.3.18/api';
// const userUrl = 'http://192.168.3.18';


const api = {
    //获取最新状态
    getNewStatus: {
        url: `${apiUrl}/App/GetNewStatus`,
        method: 'get'
    },
    //看当前三方绑定状态
    getUnBoundStatus: {
        url: `${userUrl}/tools/UserP/GetUnBoundStatus`,
        method: 'get'
    },
    //解除三方绑定
    unBoundUser: {
        url: `${userUrl}/tools/UserP/UnBoundUser`,
        method: 'get'
    },
    //根据文章 id 获取通用评论列表
    getCommonCommentList: {
        url: `${apiUrl}/App/GetCommonCommentList`,
        method: 'get'
    },
    //根据评论id 和 type 获取评论信息
    getComment: {
        url: `${apiUrl}/App/GetComment`,
        method: 'get'
    },
    //获取回复分页数据
    getCommonReplyList: {
        url: `${apiUrl}/App/GetCommonReplyList`,
        method: 'get'
    },
    // 新增评论回复
    creatCommonReply: {
        url: `${apiUrl}/App/CreatCommonReply`,
        method: 'put'
    },
    // 删除回复点赞
    deleteCommonReplyLike: {
        url: `${apiUrl}/App/DeleteCommonReplyLike`,
        method: 'put'
    },
    // 回复点赞
    creatCommonReplyLike: {
        url: `${apiUrl}/App/CreatCommonReplyLike`,
        method: 'put'
    },
    //删除评论点赞
    deleteCommonCommentLike: {
        url: `${apiUrl}/App/DeleteCommonCommentLike`,
        method: 'get'
    },
    //通用评论点赞
    createCommonCommentLike: {
        url: `${apiUrl}/App/CreateCommonCommentLike`,
        method: 'get'
    },
    //获取对我回复的信息
    getToMyReplyList: {
        url: `${apiUrl}/App/GetToMyReplyList`,
        method: 'get'
    },
    //获取对我回复的统计数量
    getToMyReplyCount: {
        url: `${apiUrl}/App/GetToMyReplyCount`,
        method: 'get'
    },
    //获取对我点赞的信息
    getToMyLikeList: {
        url: `${apiUrl}/App/GetToMyLikeList`,
        method: 'get'
    },
    //获取对我点赞的统计数量
    getToMyLikeCount: {
        url: `${apiUrl}/App/GetToMyLikeCount`,
        method: 'get'
    },
    //获取我回复的信息
    getMyReplyList: {
        url: `${apiUrl}/App/GetMyReplyList`,
        method: 'get'
    },
    //获取系统消息列表
    getToMyMessageList: {
        url: `${apiUrl}/App/GetToMyMessageList`,
        method: 'get'
    },
    //获取我的系统消息统计数量
    getToMyMessageCount: {
        url: `${apiUrl}/App/GetToMyMessageCount`,
        method: 'get'
    },
    //获取总消息统计数量
    getToMyTotalCount: {
        url: `${apiUrl}/App/GetToMyTotalCount`,
        method: 'get'
    },
    //设置接收消息
    setMessage: {
        url: `${apiUrl}/App/SetMessage`,
        method: 'post'
    },
    // 举报
    reportBad: {
        url: `${apiUrl}/Tools/ReportBad`,
        method: 'post'
    },
    postLiveCommentReport: {
        url: `${apiUrl}/video/PostLiveCommentReport`,
        method: 'post'
    },
    postLiveReport: {
        url: `${apiUrl}/video//PostLiveReport`,
        method: 'post'
    },
    //随机广告
    getAdvertising: {
        url: `${apiUrl}/Tools/GetAdvertising`,
        method: 'get'
    },
    getOssSignature: {
        url: `${apiUrl}/Tools/TestOssSignature`,
        method: 'get'
    },
    uploadImgBase64: {
        url: `${apiUrl}/Tools/UploadImgBase64`,
        method: 'post'
    },
    uploadVideo: {
        url: `${apiUrl}/Tools/UploadVideo`,
        method: 'post'
    },
    refreshUploadVideo: {
        url: `${apiUrl}/Tools/RefreshUploadVideo`,
        method: 'get'
    },
    getVideoInfo: {
        url: `${apiUrl}/Tools/GetVideoInfo`,
        method: 'get'
    },
    getDirectUploadSign: {
        url: `${apiUrl}/Tools/GetDirectUploadSign`,
        method: 'post'
    },
    //防伪
    verifyProduct: {
        url: `${apiUrl}/antifake/VerifyProduct`,
        method: 'get'
    },
    // 新闻馆
    getNewsList: {
        url: `${apiUrl}/news/GetNewsPageList`,
        method: 'get'
    },
    newsType: {
        url: `${apiUrl}/news/NewsType`,
        method: 'get'
    },
    getNewsMedia: {
        url: `${apiUrl}/news/GetWeMedia`,
        method: 'get'
    },
    getHotFive: {
        url: `${apiUrl}/news/GetZyzsTopFive`,
        method: 'get'
    },
    getNewsInfo: {
        url: `${apiUrl}/news/GetYszxNews`,
        method: 'get'
    },
    getWeMediaTopScroll: {
        url: `${apiUrl}/news/GetWeMediaTopScroll`,
        method: 'get'
    },
    searchNewsList: {
        url: `${apiUrl}/news/SearchNewsList`,
        method: 'post'
    },
    searchNewsInfo: {
        url: `${apiUrl}/news/GetNewsDetail`,
        method: 'get'
    },
    getHotRecommend: {
        url: `${apiUrl}/news/GetHotRecommend`,
        method: 'get'
    },
    getNewsComment: {
        url: `${apiUrl}/news/GetNewsComment`,
        method: 'post'
    },
    getHotComment: {
        url: `${apiUrl}/news/GetHotComment`,
        method: 'get'
    },
    addComment: {
        url: `${apiUrl}/news/AddComment`,
        method: 'put'
    },
    getCommentReply: {
        url: `${apiUrl}/news/GetCommentReply`,
        method: 'post'
    },
    addReply: {
        url: `${apiUrl}/news/AddReplay`,
        method: 'put'
    },
    //新闻评论点赞
    addCommentLike: {
        url: `${apiUrl}/news/AddCommentLike`,
        method: 'post'
    },
    addReplyLike: {
        url: `${apiUrl}/news/AddReplyLike`,
        method: 'post'
    },
    //详情页新闻收藏点赞个数
    getNewsLikedAndCollection: {
        url: `${apiUrl}/news/GetLikeAndCollection`,
        method: 'post'
    },
    clickNewsZan: {
        url: `${apiUrl}/news/NewsclickZan`,
        method: 'post'
    },
    cancelNewsZan: {
        url: `${apiUrl}/news/NewsCancelZan`,
        method: 'put'
    },
    addNewsCollection: {
        url: `${apiUrl}/news/AddCollection`,
        method: 'post'
    },
    cancelNewsColletion: {
        url: `${apiUrl}/news/RemoveCollection`,
        method: 'post'
    },
    reportNewsBad: {
        url: `${apiUrl}/news/ReportBad`,
        method: 'post'
    },
    //banner查询 1.新闻栏目banner 2.展会轮播图 3.众测评 4.首页5.展会栏目广告图（详情页里）6.展会栏目报名轮播图7.首页新闻中心轮播8.首页展会精选轮播9.首页测评轮播10.新闻页内悦视资讯轮播，11.社区类轮播图 ，12音视频 13品牌会 ，14新闻页内上部轮播
    getNewsBanner: {
        url: `${apiUrl}/news/ReadNewsBanner`,
        method: 'get'
    },
    releaseNews: {
        url: `${apiUrl}/news/ReleaseNews`,
        method: 'post'
    },
    //明星接口
    //网页邀约查询
    getArtistInvitationList: {
        url: `${apiUrl}/Artist/GetArtistInvitationList`,
        method: 'get'
    },
    //邀约详情
    getArtistInvitationDetail: {
        url: `${apiUrl}/Artist/GetArtistInvitationDetail`,
        method: 'get'
    },
    //用户邀约查询
    getArtistInvitationSelfList: {
        url: `${apiUrl}/Artist/GetArtistInvitationSelfList`,
        method: 'get'
    },
    //保存邀约信息
    setArtistInvitation: {
        url: `${apiUrl}/Artist/SetArtistInvitation`,
        method: 'post'
    },
    //用户邀约查询
    getArtistInvitationDetailSelf: {
        url: `${apiUrl}/Artist/GetArtistInvitationDetailSelf`,
        method: 'get'
    },
    //设置关注邀约
    setArtistFocus: {
        url: `${apiUrl}/Artist/SetArtistFocus`,
        method: 'post'
    },
    //删除邀约
    deleteArtistInvitation: {
        url: `${apiUrl}/Artist/DeleteArtistInvitation`,
        method: 'post'
    },
    //取消邀约
    cancelArtistFocus: {
        url: `${apiUrl}/Artist/CancelArtistFocus`,
        method: 'post'
    },
    //艺人列表
    getArtistInfoList: {
        url: `${apiUrl}/Artist/GetArtistInfoList`,
        method: 'get'
    },
    //艺人检索
    getArtistInfoListSearch: {
        url: `${apiUrl}/Artist/GetArtistInfoListSearch`,
        method: 'get'
    },
    //艺人检索统计
    getArtistInfoListSearchCount: {
        url: `${apiUrl}/Artist/GetArtistInfoListSearchCount`,
        method: 'get'
    },
    //获取艺人信息
    getArtistInfo: {
        url: `${apiUrl}/Artist/GetArtistInfo`,
        method: 'get'
    },
    //保存艺人信息
    setArtistInfo: {
        url: `${apiUrl}/Artist/SetArtistInfo`,
        method: 'put'
    },
    //艺人主要作品、简历等
    setArtistList: {
        url: `${apiUrl}/Artist/SetArtistList`,
        method: 'put'
    },
    //我感兴趣邀约
    getArtistFocus: {
        url: `${apiUrl}/Artist/getArtistFocus`,
        method: 'get'
    },
    // 首页接口
    getHomePageNews: {
        url: `${apiUrl}/index/news`,
        method: 'get'
    },
    getBaiKe: {
        url: `${apiUrl}/index/GetBaiKe`,
        method: 'get'
    },
    getHomePageEvaluation: {
        url: `${apiUrl}/index/evaluation`,
        method: 'get'
    },
    getHomePageExhibition: {
        url: `${apiUrl}/index/exhibition`,
        method: 'get'
    },
    getHomePageExperience: {
        url: `${apiUrl}/index/experience`,
        method: 'get'
    },
    getHomePageScrollAndBrands: {
        url: `${apiUrl}/index/GetExhiScrollAndBrand`,
        method: 'get'
    },
    //教师
    //教师列表
    getTeacherInfoList: {
        url: `${apiUrl}/Teacher/GetTeacherInfoList`,
        method: 'get'
    },
    //教师检索
    getTeacherInfoListSearch: {
        url: `${apiUrl}/Teacher/GetTeacherInfoListSearch`,
        method: 'get'
    },
    //教师检索统计
    getTeacherInfoListSearchCount: {
        url: `${apiUrl}/Teacher/GetTeacherInfoListSearchCount`,
        method: 'get'
    },
    //我感兴趣邀约
    getTeacherFocus: {
        url: `${apiUrl}/Teacher/getTeacherFocus`,
        method: 'get'
    },

    // 百科接口
    getKnowledgeList: {
        url: `${apiUrl}/Baike/searchBikeList`,
        method: 'get'
    },
    getKnowledgeListNew: {
        url: `${apiUrl}/Baike/day3`,
        method: 'get'
    },
    searchKnowledgeList: {
        url: `${apiUrl}/Baike/searchTitle`,
        method: 'get'
    },
    getBaikeList: {
        url: `${apiUrl}/Baike/BaikeList`,
        method: 'get'
    },
    //展会相关接口
    //获取当前展出的展会信息
    getShowingExhition: {
        url: `${apiUrl}/exhibition/GetShowingExhi`,
        method: 'get'
    },
    exhibitionProductTypes: {
        url: `${apiUrl}/exhibition/ExhibitionProductTypes`,
        method: 'get'
    },
    //获取下一期的展会信息
    getNextExhition: {
        url: `${apiUrl}/exhibition/GetNextExhi`,
        method: 'get'
    },
    //获取正在展出的产品列表,带分页，默认第一页，每页20条
    getShowingProducts: {
        url: `${apiUrl}/exhibition/GetShowingProducts`,
        method: 'post'
    },
    getOldExhibition: {
        url: `${apiUrl}/exhibition/GetOldExhibition`,
        method: 'post'
    },
    getExhListOld: {
        url: `${apiUrl}/exhibition/GetExhListOld`,
        method: 'get'
    },
    getExhListNowAndOld: {
        url: `${apiUrl}/exhibition/GetExhListNowAndOld`,
        method: 'get'
    },
    getExProductInfo: {
        url: `${apiUrl}/exhibition/GetProductInfo`,
        method: 'get'
    },
    addExShareNum: {
        url: `${apiUrl}/exhibition/AddShareNum`,
        method: 'put'
    },
    clickExLike: {
        url: `${apiUrl}/exhibition/ClickLike`,
        method: 'post'
    },
    cancelExLike: {
        url: `${apiUrl}/exhibition/CancelLike`,
        method: 'put'
    },
    addExCollection: {
        url: `${apiUrl}/exhibition/AddCollection`,
        method: 'post'
    },
    removeExCollection: {
        url: `${apiUrl}/exhibition/RemoveCollection`,
        method: 'put'
    },
    getExHotComment: {
        url: `${apiUrl}/exhibition/GetHotComment`,
        method: 'get'
    },
    getExFreshComment: {
        url: `${apiUrl}/exhibition/GetComments`,
        method: 'post'
    },
    getExCommentReply: {
        url: `${apiUrl}/exhibition/GetCommentReply`,
        method: 'post'
    },
    //对展会评论的点赞
    addExCommentLike: {
        url: `${apiUrl}/exhibition/AddCommentLike`,
        method: 'post'
    },
    addExReplyLike: {
        url: `${apiUrl}/exhibition/AddReplyLike`,
        method: 'post'
    },
    getExSearchable: {
        url: `${apiUrl}/exhibition/GetSearchable`,
        method: 'get'
    },
    searchExByInput: {
        url: `${apiUrl}/exhibition/SearchByInput`,
        method: 'get'
    },
    addExComment: {
        url: `${apiUrl}/exhibition/AddComment`,
        method: 'post'
    },
    addExReply: {
        url: `${apiUrl}/exhibition/AddReply`,
        method: 'post'
    },
    getExLikedAndCollection: {
        url: `${apiUrl}/exhibition/GetLikeAndCollection`,
        method: 'post'
    },
    //当前正在展出展会的品牌汇的信息
    getJoinBrands: {
        url: `${apiUrl}/exhibition/GetExhNowBrand`,
        method: 'get'
    },
    //当前展会参展品牌的展出产品
    getBrandProducts: {
        url: `${apiUrl}/exhibition/GetBrandProducts`,
        method: 'get'
    },
    addExProduct: {
        url: `${apiUrl}/exhibition/AddexhiProduct`,
        method: 'post'
    },
    editExProduct: {
        url: `${apiUrl}/exhibition/EditExhiProduct`,
        method: 'put'
    },
    deleteExProduct: {
        url: `${apiUrl}/exhibition/DeleteExhiProduct`,
        method: 'delete'
    },
    getExList: {
        url: `${apiUrl}/exhibition/GetExhList`,
        method: 'get'
    },
    getExListAndNext: {
        url: `${apiUrl}/exhibition/GetExhListAndNext`,
        method: 'get'
    },
    getExhListNext: {
        url: `${apiUrl}/exhibition/GetExhListNext`,
        method: 'get'
    },
    getExhListCurrent: {
        url: `${apiUrl}/exhibition/GetExhListCurrent`,
        method: 'get'
    },
    getExhListApp: {
        url: `${apiUrl}/exhibition/GetExhListApp`,
        method: 'get'
    },

    //展会点击品牌，返回展出列表
    getProductsByBrand: {
        url: `${apiUrl}/exhibition/GetProductsByBrand`,
        method: 'get'
    },

    //展会签到
    getExhibitionUserSignDate: {
        url: `${apiUrl}/exhibition/GetExhibitionUserSignDate`,
        method: 'get'
    },
    setExhibitionUserSign: {
        url: `${apiUrl}/exhibition/SetExhibitionUserSign`,
        method: 'get'
    },

    // 测评接口
    getEvalList: {
        url: `${apiUrl}/Evaluation/ReadEvaluationIndex`,
        method: 'get'
    },
    readEvaluationCatalog: {
        url: `${apiUrl}/Evaluation/ReadEvaluationCatalog`,
        method: 'get'
    },
    evaluationType: {
        url: `${apiUrl}/Evaluation/EvaluationType`,
        method: 'get'
    },
    getEvalInfo: {
        url: `${apiUrl}/Evaluation/ReadEvaulationInfo`,
        method: 'get'
    },
    createEvalComment: {
        url: `${apiUrl}/Evaluation/CreatEvualComment`,
        method: 'put'
    },
    //测评评论点赞
    addEvalCommentLike: {
        url: `${apiUrl}/Evaluation/CreatEvualCommentLike`,
        method: 'post'
    },
    createEvalReply: {
        url: `${apiUrl}/Evaluation/CreatEvalReply`,
        method: 'put'
    },
    addEvalReplyLike: {
        url: `${apiUrl}/Evaluation/CreatEvalReplyLike`,
        method: 'put'
    },
    getEvalFreshComment: {
        url: `${apiUrl}/Evaluation/GetEvalCommentList`,
        method: 'post'
    },
    getEvalHotComment: {
        url: `${apiUrl}/Evaluation/GetEvalHotComment`,
        method: 'get'
    },
    getEvalCommentReply: {
        url: `${apiUrl}/Evaluation/GetEvalCommentReply`,
        method: 'post'
    },
    addEvalCollection: {
        url: `${apiUrl}/Evaluation/AddEvalCollection`,
        method: 'post'
    },
    removeEvalCollection: {
        url: `${apiUrl}/Evaluation/RemoveEvalCollection`,
        method: 'delete'
    },
    createEvalCommentEdit: {
        url: `${apiUrl}/Evaluation/CreatEvalCommentEdit`,
        method: 'put'
    },
    uploadEvalContentIMG: {
        url: `${apiUrl}/Evaluation/CreatEvalContentIMG`,
        method: 'put'
    },
    uploadEvalImgList: {
        url: `${apiUrl}/Evaluation/EvalList`,
        method: 'put'
    },
    addEvalLike: {
        url: `${apiUrl}/Evaluation/AddEvalZanRecord`,
        method: 'post'
    },
    removeEvalLike: {
        url: `${apiUrl}/Evaluation/RemoveEvalZanRecord`,
        method: 'delete'
    },
    //详情页测评收藏点赞个数
    checkEvalLikeAndCollection: {
        url: `${apiUrl}/Evaluation/CheckLikeAndCollection`,
        method: 'get'
    },
    addEvaluation: {
        url: `${apiUrl}/Evaluation/AddEvaluation`,
        method: 'put'
    },
    updateEvaluation: {
        url: `${apiUrl}/Evaluation/UpdateEval`,
        method: 'put'
    },
    deleteEvaluation: {
        url: `${apiUrl}/Evaluation/DeleteEval`,
        method: 'delete'
    },
    // 展会接口
    getExhListWeb: {
        url: `${apiUrl}/Exhibition/GetExhListWeb`,
        method: 'get'
    },
    getExperList: {
        url: `${apiUrl}/Experience/ReadExperIndex`,
        method: 'get'
    },
    getExperInfo: {
        url: `${apiUrl}/Experience/ReadExperInfo`,
        method: 'get'
    },
    //获取直播回看详情内容
    getVideoPlayback: {
        url: `${apiUrl}/video/GetVideoPlayback`,
        method: 'get'
    },
    createExperComment: {
        url: `${apiUrl}/Experience/CreatExperComment`,
        method: 'post'
    },

    addExperLike: {
        url: `${apiUrl}/Experience/AddLike`,
        method: 'post'
    },
    //增加一个点赞回看
    updateVideoPlaybackZan: {
        url: `${apiUrl}/video/UpdateVideoPlaybackZan`,
        method: 'put'
    },
    //增加一个视频回看的评论
    addVideoPlaybackCommon: {
        url: `${apiUrl}/video/AddVideoPlaybackCommon`,
        method: 'put'
    },

    removeExperLike: {
        url: `${apiUrl}/Experience/RemoveLike`,
        method: 'delete'
    },
    //取消一个点赞回看
    removeVideoPlaybackZan: {
        url: `${apiUrl}/video/RemoveVideoPlaybackZan`,
        method: 'delete'
    },
    //视频回看帖子评论点赞
    addVideoPlaybackCommonLike: {
        url: `${apiUrl}/video/CreatVideoPlaybackCommonLike`,
        method: 'get'
    },

    getOtherExper: {
        url: `${apiUrl}/Experience/GetOtherExper`,
        method: 'get'
    },
    //视音评论点赞
    addExperCommentLike: {
        url: `${apiUrl}/Experience/CreatExperCommentLike`,
        method: 'put'
    },
    createExperReply: {
        url: `${apiUrl}/Experience/CreatExperReply`,
        method: 'post'
    },
    addExperReplyLike: {
        url: `${apiUrl}/Experience/CreatExperReplyLike`,
        method: 'put'
    },

    getExperFreshComment: {
        url: `${apiUrl}/Experience/GetExperCommentList`,
        method: 'post'
    },
    // getExperHotComment: {
    //     url: `${apiUrl}/Experience/GetEvalHotComment`,
    //     method: 'get'
    // },
    getExperCommentReply: {
        url: `${apiUrl}/Experience/GetExperCommentReply`,
        method: 'post'
    },
    addExperCollection: {
        url: `${apiUrl}/Experience/AddExperCollection`,
        method: 'post'
    },
    //取消收藏的视音
    removeExperCollection: {
        url: `${apiUrl}/Experience/RemoveExperCollection`,
        method: 'post'
    },
    createExperText: {
        url: `${apiUrl}/Experience/CreatExperText`,
        method: 'post'
    },
    updateExperText: {
        url: `${apiUrl}/Experience/UpdateExpr`,
        method: 'put'
    },
    uploadExperImgList: {
        url: `${apiUrl}/Experience/ExperList`,
        method: 'put'
    },
    //详情页视音收藏点赞个数
    checkExperLikeAndColl: {
        url: `${apiUrl}/Experience/CheckZanAndCollect`,
        method: 'post'
    },
    //详情页回看收藏点赞个数
    checkVideoPlaybackCollectAndZan: {
        url: `${apiUrl}/video/CheckVideoPlaybackCollectAndZan`,
        method: 'post'
    },
    //增加一个收藏回看
    addVideoPlaybackCollection: {
        url: `${apiUrl}/video/AddVideoPlaybackCollection`,
        method: 'post'
    },
    //取消一个收藏回看
    deleteVideoPlaybackCollection: {
        url: `${apiUrl}/video/DeleteVideoPlaybackCollection`,
        method: 'delete'
    },

    // 企业用户接口
    bussSignIn: {
        url: `${apiUrl}/account/buss/singin`,
        method: 'post'
    },
    getBussInfo: {
        url: `${apiUrl}/account/buss/getbussinfo`,
        method: 'get'
    },
    getUserBInfo: {
        url: `${apiUrl}/account/buss/GetUserBInfo`,
        method: 'get'
    },

    uploadBussIMG: {
        url: `${apiUrl}/account/buss/uploadimg`,
        method: 'put'
    },
    uploadBussLicense: {
        url: `${apiUrl}/account/buss/UploadLicenseImg`,
        method: 'get'
    },
    uploadBussAuth: {
        url: `${apiUrl}/account/buss/UploadAuthorization`,
        method: 'get'
    },
    bussSignUp: {
        url: `${apiUrl}/account/buss/singup`,
        method: 'post'
    },
    bussSendCode: {
        url: `${apiUrl}/account/buss/sendcode`,
        method: 'post'
    },
    bussSendContactCode: {
        url: `${apiUrl}/account/buss/sendContactCcode2`,
        method: 'post'
    },
    bussCheckCode: {
        url: `${apiUrl}/account/buss/checkcode`,
        method: 'post'
    },
    bussCheckToken: {
        url: `${apiUrl}/account/buss/checktoken`,
        method: 'post'
    },
    bussUpdateUserInfo: {
        url: `${apiUrl}/account/buss/UpdateUserInfo`,
        method: 'put'
    },
    bussUploadCoverImg: {
        url: `${apiUrl}/account/buss/UploadCoverImg`,
        method: 'get'
    },
    bussModifyCoverImg: {
        url: `${apiUrl}/account/buss/ModifyCoverImg`,
        method: 'get'
    },
    bussSendYZMUpdatePWD: {
        url: `${apiUrl}/account/buss/SendYZMUpdatePWD`,
        method: 'get'
    },
    bussForgetPwdp: {
        url: `${apiUrl}/account/buss/forgetPwdp`,
        method: 'post'
    },
    bussVerifyPassword: {
        url: `${apiUrl}/account/buss/VerifyPassword`,
        method: 'post'
    },
    bussSendVerifyCodeAll: {
        url: `${apiUrl}/account/buss/SendVerifyCodeAll`,
        method: 'get'
    },
    bussVerifyCodeAll: {
        url: `${apiUrl}/account/buss/VerifyCodeAll`,
        method: 'post'
    },
    bussUpdatePassword: {
        url: `${apiUrl}/account/buss/UpdatePassword`,
        method: 'put'
    },
    bussUpdatePasswordNew: {
        url: `${apiUrl}/account/buss/UpdatePasswordNew`,
        method: 'post'
    },
    bussModifyNameAndPhone: {
        url: `${apiUrl}/account/buss/ModifyNameAndPhone`,
        method: 'put'
    },
    bussUpdateEmail: {
        url: `${apiUrl}/account/buss/UpdateEmail`,
        method: 'post'
    },
    bussSendVerifyCode: {
        url: `${apiUrl}/account/buss/SendVerifyCode`,
        method: 'get'
    },
    bussVerifyPhoneCode: {
        url: `${apiUrl}/account/buss/VerifyPhoneCode`,
        method: 'get'
    },
    bussModifyPhoneNum: {
        url: `${apiUrl}/account/buss/ModifyPhoneNum`,
        method: 'put'
    },
    bussForgetPassword: {
        url: `${apiUrl}/account/buss/RetrievePwd`,
        method: 'post'
    },
    bussForgetCheckCode: {
        url: `${apiUrl}/account/buss/CheckCode`,
        method: 'post'
    },
    bussOrderList: {
        url: `${apiUrl}/account/buss/GetOrderList`,
        method: 'get'
    },
    bussUpdateLogo: {
        url: `${apiUrl}/account/buss/UpdateBussLogo`,
        method: 'post'
    },
    bussUpdateLicense: {
        url: `${apiUrl}/account/buss/UpdateBussLicense`,
        method: 'post'
    },
    // 企业中心
    bcGetBrand: {
        url: `${apiUrl}/bussusercenter/brand/GetBrandsInBuss`,
        method: 'get'
    },
    bcAddBrand: {
        url: `${apiUrl}/bussusercenter/brand/AddBrand`,
        method: 'post'
    },
    bcRemoveBrand: {
        url: `${apiUrl}/bussusercenter/brand/RemoveBrand`,
        method: 'put'
    },
    bcOnSaleBrand: {
        url: `${apiUrl}/bussusercenter/brand/OnSaleBrand`,
        method: 'put'
    },

    //企业店铺
    getCompanyShop: {
        url: `${apiUrl}/shop/GetCompanyShop`,
        method: 'get'
    },
    getCompanyShops: {
        url: `${apiUrl}/shop/GetCompanyShops`,
        method: 'get'
    },
    addCompanyShop: {
        url: `${apiUrl}/shop/AddCompanyShop`,
        method: 'post'
    },
    removeCompanyShop: {
        url: `${apiUrl}/shop/RemoveCompanyShop`,
        method: 'delete'
    },

    getCompanyExperiences: {
        url: `${apiUrl}/shop/GetCompanyExperiences`,
        method: 'get'
    },
    getCommpanyExperiencesById: {
        url: `${apiUrl}/shop/GetCommpanyExperiencesById`,
        method: 'get'
    },
    getCompanyShopById: {
        url: `${apiUrl}/shop/GetCompanyShopById`,
        method: 'get'
    },
    getCommpanyExperiencesOutOne: {
        url: `${apiUrl}/shop/GetCommpanyExperiencesOutOne`,
        method: 'get'
    },

    addCompanyExperience: {
        url: `${apiUrl}/shop/AddCompanyExperience`,
        method: 'post'
    },
    removeCompanyExperience: {
        url: `${apiUrl}/shop/RemoveCompanyExperience`,
        method: 'delete'
    },


    //删除某个品牌，注意删除后将无法恢复
    bcDeleteBrand: {
        url: `${apiUrl}/bussusercenter/brand/DeleteBrand`,
        method: 'delete'
    },
    bcEditBrandInfo: {
        url: `${apiUrl}/bussusercenter/brand/EditBrandInfo`,
        method: 'put'
    },
    bcEditBrandLogo: {
        url: `${apiUrl}/bussusercenter/brand/EditBrandLogo`,
        method: 'put'
    },
    bcUploadBrandLogoImg: {
        url: `${apiUrl}/bussusercenter/brand/UploadBrandLogoImg`,
        method: 'post'
    },
    bcAddBrandManager: {
        url: `${apiUrl}/bussusercenter/brand/AddBrandManagers`,
        method: 'post'
    },
    bcSendManagerPhone: {
        url: `${apiUrl}/bussusercenter/brand/SendManagerPhone`,
        method: 'get'
    },
    bcSendManagerEmail: {
        url: `${apiUrl}/bussusercenter/brand/SendManagerEmail`,
        method: 'get'
    },
    bcSendEmailCode: {
        url: `${apiUrl}/bussusercenter/SendEmailCode`,
        method: 'get'
    },
    bcVerifyCode: {
        url: `${apiUrl}/bussusercenter/brand/VerifyCode`,
        method: 'get'
    },
    bcEditManagerInfo: {
        url: `${apiUrl}/bussusercenter/brand/EditManagerInfo`,
        method: 'put'
    },
    bcGetManagerInfo: {
        url: `${apiUrl}/bussusercenter/brand/GetManagerInfo`,
        method: 'get'
    },
    bcGetProductsList: {
        url: `${apiUrl}/bussusercenter/product/GetProductsList`,
        method: 'get'
    },
    bcAddProduct: {
        url: `${apiUrl}/bussusercenter/product/AddProduct`,
        method: 'post'
    },
    bcRemoveProduct: {
        url: `${apiUrl}/bussusercenter/product/RemoveProduct`,
        method: 'put'
    },
    bcOnSaleProduct: {
        url: `${apiUrl}/bussusercenter/product/OnSaleProduct`,
        method: 'put'
    },
    bcDeleteProduct: {
        url: `${apiUrl}/bussusercenter/product/DeleteProduct`,
        method: 'delete'
    },
    bcEditProductInfo: {
        url: `${apiUrl}/bussusercenter/product/UpdateProductInfo`,
        method: 'put'
    },
    bcGetProductInfo: {
        url: `${apiUrl}/bussusercenter/product/GetProductDetail`,
        method: 'get'
    },
    bcUploadProductImg: {
        url: `${apiUrl}/bussusercenter/product/UploadProductImage`,
        method: 'get'
    },
    bcCompanyApply: {
        url: `${userUrl}/UserCenter/PersonalInfo/CompanyApply`,
        method: 'post'
    },
    bcCompanyApplyTemp: {
        url: `${userUrl}/UserCenter/PersonalInfo/CompanyApplyTemp`,
        method: 'post'
    },
    bcOrderForm: {
        url: `${userUrl}/UserCenter/PersonalInfo/OrderForm`,
        method: 'post'
    },
    bcArtistApply: {
        url: `${userUrl}/UserCenter/PersonalInfo/ArtistApply`,
        method: 'post'
    },
    bcDeleteImg: {
        url: `${apiUrl}/bussusercenter/product/DeleteImg`,
        method: 'delete'
    },
    bcSendMessage: {
        url: `${apiUrl}/bussusercenter/SendMessageToFollower`,
        method: 'post'
    },
    bcGetMessages: {
        url: `${apiUrl}/bussusercenter/GetSendedMsg`,
        method: 'get'
    },
    // 企业中心-管理模块
    bcmGetCommunityPubs: {
        url: `${apiUrl}/buc_management/community/GetCommunityPubs`,
        method: 'get'
    },
    bcmDeleteCommunityPub: {
        url: `${apiUrl}/buc_management/DeleteCommunityPub`,
        method: 'delete'
    },
    bcmGetCommunityZans: {
        url: `${apiUrl}/buc_management/GetCommunityZans`,
        method: 'get'
    },
    bcmGetCommunityComms: {
        url: `${apiUrl}/buc_management/GetCommunityComms`,
        method: 'get'
    },
    bcmRemoveCommunityComms: {
        url: `${apiUrl}/buc_management/RemoveCommunityComms`,
        method: 'delete'
    },
    bcmGetCollectionBrands: {
        url: `${apiUrl}/buc_management/GetCollectionBrands`,
        method: 'get'
    },
    bcmRemoveCollection: {
        url: `${apiUrl}/buc_management/RemoveCollection`,
        method: 'delete'
    },
    bcmGetCollectionNews: {
        url: `${apiUrl}/buc_management/GetCollectionNews`,
        method: 'get'
    },
    bcmGetNewsLike: {
        url: `${apiUrl}/buc_management/GetNewsLike`,
        method: 'get'
    },
    bcmGetCommentNews: {
        url: `${apiUrl}/buc_management/GetCommentNews`,
        method: 'get'
    },
    bcmRemoveCommentNews: {
        url: `${apiUrl}/buc_management/RemoveCommentNews`,
        method: 'delete'
    },

    bcmGetPubEvaluations: {
        url: `${apiUrl}/buc_management/GetPubEvaluations`,
        method: 'get'
    },
    bcmShared: {
        url: `${apiUrl}/buc_management/Shared`,
        method: 'put'
    },
    bcmGetCommentEvaluations: {
        url: `${apiUrl}/buc_management/GetCommentEvaluations`,
        method: 'get'
    },
    bcmRemoveCommentEvaluations: {
        url: `${apiUrl}/buc_management/RemoveCommentEvaluations`,
        method: 'delete'
    },
    bcmGetZanEvaluations: {
        url: `${apiUrl}/buc_management/GetZanEvaluations`,
        method: 'get'
    },
    bcmGetProductCollection: {
        url: `${apiUrl}/buc_management/GetProductCollection`,
        method: 'get'
    },
    bcmGetExhiProductLike: {
        url: `${apiUrl}/buc_management/GetExhiProductLike`,
        method: 'get'
    },
    bcmGetExhiProductCollection: {
        url: `${apiUrl}/buc_management/GetExhiProductCollection`,
        method: 'get'
    },
    bcmGetCommentExhiProducts: {
        url: `${apiUrl}/buc_management/GetCommentExhiProducts`,
        method: 'get'
    },
    bcmRemoveCommentExhiProduct: {
        url: `${apiUrl}/buc_management/RemoveCommentExhiProduct`,
        method: 'delete'
    },
    bcmGetCommunityCollection: {
        url: `${apiUrl}/buc_management/GetCommunityCollection`,
        method: 'get'
    },
    bcmGetEvaluationCollection: {
        url: `${apiUrl}/buc_management/GetEvaluationCollection`,
        method: 'get'
    },
    bcmGetExperienceCollection: {
        url: `${apiUrl}/buc_management/GetExperinceCollection`,
        method: 'get'
    },
    bcmGetCommentExperience: {
        url: `${apiUrl}/buc_management/GetCommentExperience`,
        method: 'get'
    },
    bcmRemoveCommentExperience: {
        url: `${apiUrl}/buc_management/RemoveCommentExperience`,
        method: 'delete'
    },
    bcmGetExperiencePub: {
        url: `${apiUrl}/buc_management/GetExperiencePub`,
        method: 'get'
    },
    bcmGetExperienceZan: {
        url: `${apiUrl}/buc_management/GetExperienceZan`,
        method: 'get'
    },
    bcmPublishBussJob: {
        url: `${apiUrl}/buc_management/PublishBussJob`,
        method: 'post'
    },
    bcmEditJobInfo: {
        url: `${apiUrl}/buc_management/EditJobInfo`,
        method: 'put'
    },
    bcmDelBussJob: {
        url: `${apiUrl}/buc_management/DelBussJob`,
        method: 'delete'
    },
    bcmGetBussJob: {
        url: `${apiUrl}/buc_management/GetBussJobList`,
        method: 'get'
    },
    bcmGetJobApplies: {
        url: `${apiUrl}/buc_management/GetJobApplies`,
        method: 'get'
    },
    // 个人用户接口
    userSignIn: {
        url: `${userUrl}/tools/UserP/UserRegister`,
        method: 'post'
    },
    getUserInfo: {
        url: `${userUrl}/tools/UserP/GetUserInfo`,
        method: 'post'
    },
    userLogin: {
        url: `${userUrl}/tools/UserP/UserLogin`,
        method: 'post'
    },
    userLoginPhone: {
        url: `${userUrl}/tools/UserP/UserLoginPhone`,
        method: 'post'
    },
    getUserLoginInfo: {
        url: `${userUrl}/tools/UserP/GetUserLoginInfo`,
        method: 'get'
    },
    userSendCode: {
        url: `${userUrl}/tools/UserP/RegisterCheckNum`,
        method: 'post'
    },
    loginCode: {
        url: `${userUrl}/tools/UserP/GetPhoneCode`,
        method: 'post'
    },
    userForgetPassSendCode: {
        url: `${userUrl}/tools/UserP/RetrievePwd`,
        method: 'post'
    },
    userCheckCode: {
        url: `${userUrl}/tools/UserP/CheckCode`,
        method: 'post'
    },
    userForgetPassword: {
        url: `${userUrl}/tools/UserP/UpdatePassword`,
        method: 'post'
    },
    userCheckToken: {
        url: `${userUrl}/tools/UserP/TokenCheck`,
        method: 'post'
    },
    // 个人中心
    getCheckAuthorization: {
        url: `${userUrl}/UserCenter/PersonalInfo/CheckAuthorization`,
        method: 'get'
    },
    //支付和认证状态
    getCheckPayStatus: {
        url: `${userUrl}/UserCenter/PersonalInfo/CheckPayStatus`,
        method: 'get'
    },
    uploadHeadIMG: {
        url: `${userUrl}/UserCenter/PersonalInfo/UpdateUserHeadImg`,
        method: 'post'
    },
    updateUserInfo: {
        url: `${userUrl}/UserCenter/PersonalInfo/UserInfoUpdate`,
        method: 'post'
    },
    changePhoneStep1: {
        url: `${userUrl}/UserCenter/PersonalInfo/ChangePhoneStep1`,
        method: 'post'
    },
    changePhoneStep2: {
        url: `${userUrl}/UserCenter/PersonalInfo/ChangePhoneStep2`,
        method: 'post'
    },
    changePhoneStep3: {
        url: `${userUrl}/UserCenter/PersonalInfo/ChangePhoneStep3`,
        method: 'post'
    },
    changePhoneStep4: {
        url: `${userUrl}/UserCenter/PersonalInfo/ChangePhoneStep4`,
        method: 'post'
    },
    changePassByPhone1: {
        url: `${userUrl}/UserCenter/PersonalInfo/UpdatePWDStep1P`,
        method: 'post'
    },
    changePassByPhone2: {
        url: `${userUrl}/UserCenter/PersonalInfo/UpdatePWDStep2P`,
        method: 'post'
    },
    changePassByEmail1: {
        url: `${userUrl}/UserCenter/PersonalInfo/UpdatePWDStep1E`,
        method: 'post'
    },
    changePassByEmail2: {
        url: `${userUrl}/UserCenter/PersonalInfo/UpdatePWDStep2E`,
        method: 'post'
    },
    changePassByQues1: {
        url: `${userUrl}/UserCenter/PersonalInfo/TextVerifyStep1`,
        method: 'get'
    },
    changePassByQues2: {
        url: `${userUrl}/UserCenter/PersonalInfo/TextVerifyStep2`,
        method: 'post'
    },
    updateUserPassword: {
        url: `${userUrl}/UserCenter/PersonalInfo/UpdatePWD`,
        method: 'put'
    },
    createQuestionToken: {
        url: `${userUrl}/UserCenter/PersonalInfo/CreatQusetionToken`,
        method: 'put'
    },
    checkQuestionToken: {
        url: `${userUrl}/UserCenter/PersonalInfo/CheckQuestionToken`,
        method: 'put'
    },
    getQuestion: {
        url: `${userUrl}/UserCenter/PersonalInfo/GetQuestion`,
        method: 'get'
    },
    getCollectionList: {
        url: `${userUrl}/UserCenter/PersonalInfo/GetCollectionList`,
        method: 'post'
    },
    userUploadCoverImg: {
        url: `${userUrl}/UserCenter/PersonalInfo/UploadCoverImg`,
        method: 'get'
    },
    userModifyCoverImg: {
        url: `${userUrl}/UserCenter/PersonalInfo/ModifyCoverImg`,
        method: 'get'
    },
    //用户发布查询
    userCommunityRead: {
        url: `${userUrl}/UserCenter/PersonalInfo/UserCommunityRead`,
        method: 'get'
    },
    getUserLikeRead: {
        url: `${userUrl}/UserCenter/PersonalInfo/UserLikeRead`,
        method: 'get'
    },
    userLikeList: {
        url: `${userUrl}/UserCenter/PersonalInfo/UserLikeList`,
        method: 'get'
    },
    deleteUserLikeRead: {
        url: `${userUrl}/UserCenter/PersonalInfo/UserLikeDelete`,
        method: 'delete'
    },
    getUserCommunityCommon: {
        url: `${userUrl}/UserCenter/PersonalInfo/GetUserCommunityCommon`,
        method: 'get'
    },
    getUserEvaluationCommon: {
        url: `${userUrl}/UserCenter/PersonalInfo/GetUserEvaluationCommon`,
        method: 'get'
    },
    deleteAudition: {
        url: `${userUrl}/UserCenter/PersonalInfo/DeleteExperience`,
        method: 'delete'
    },
    updateUserEmail: {
        url: `${userUrl}/UserCenter/PersonalInfo/UpdateUserEmail`,
        method: 'post'
    },
    checkUserEmailCode: {
        url: `${userUrl}/UserCenter/PersonalInfo/CheckUserEmailCode`,
        method: 'post'
    },
    checkUserFocus: {
        url: `${userUrl}/UserCenter/PersonalInfo/CheckUserFocus`,
        method: 'get'
    },
    checkUserBlack: {
        url: `${userUrl}/UserCenter/PersonalInfo/CheckUserBlack`,
        method: 'get'
    },
    // 用户公共
    getUserFocus: {
        url: `${apiUrl}/usercommon/GetFocus`,
        method: 'get'
    },
    //他的关注
    getUserPageFocus: {
        url: `${apiUrl}/usercommon/GetUserPageFocus`,
        method: 'get'
    },
    getUserFollower: {
        url: `${apiUrl}/usercommon/GetFollowers`,
        method: 'get'
    },
    // 他的粉丝
    getUserPageFollowers: {
        url: `${apiUrl}/usercommon/GetUserPageFollowers`,
        method: 'get'
    },
    addUserFollow: {
        url: `${apiUrl}/usercommon/AddFollow`,
        method: 'post'
    },
    addToBlackList: {
        url: `${apiUrl}/usercommon/AddToBlackList`,
        method: 'post'
    },
    removeUserFocus: {
        url: `${apiUrl}/usercommon/RemoveFollow`,
        method: 'put'
    },
    removeBlackUser: {
        url: `${apiUrl}/usercommon/RemoveBlackUser `,
        method: 'put'
    },
    getUserSendedMessage: {
        url: `${apiUrl}/usercommon/GetSendedMessage`,
        method: 'get'
    },
    updateMessageState: {
        url: `${apiUrl}/usercommon/updateReadState`,
        method: 'put'
    },
    checkUserNewMsg: {
        url: `${apiUrl}/usercommon/CheckUserNewMsg`,
        method: 'get'
    },
    getUserReceivedMessage: {
        url: `${apiUrl}/usercommon/GetRecivedMessage`,
        method: 'get'
    },
    getUserLetterChat: {
        url: `${apiUrl}/usercommon/GetLetterChat`,
        method: 'get'
    },
    sendUserMessage: {
        url: `${apiUrl}/usercommon/SendMessage`,
        method: 'post'
    },
    sendBrandToFans: {
        url: `${apiUrl}/usercommon/BrandToFans`,
        method: 'post'
    },
    getSystemNotice: {
        url: `${apiUrl}/usercommon/GetSystemNotice`,
        method: 'get'
    },
    sendUserLetterReply: {
        url: `${apiUrl}/usercommon/SendLetterReply`,
        method: 'post'
    },
    getUserBlackList: {
        url: `${apiUrl}/usercommon/GetBlackList`,
        method: 'get'
    },
    removeUserBlackList: {
        url: `${apiUrl}/usercommon/RemoveBlackUser`,
        method: 'put'
    },
    getGetFocusBrands: {
        url: `${apiUrl}/usercommon/GetFocusBrands`,
        method: 'get'
    },
    unLockUser: {
        url: `${apiUrl}/usercommon/UnlockUser`,
        method: 'put'
    },
    // 搜索接口
    searchNews: {
        url: `${apiUrl}/Search/searchNews`,
        method: 'post'
    },
    searchExInfo: {
        url: `${apiUrl}/Search/SearchExhibitionInfo`,
        method: 'post'
    },
    searchEval: {
        url: `${apiUrl}/Search/SearchEval`,
        method: 'post'
    },
    searchCommunity: {
        url: `${apiUrl}/Search/SearchCommunity`,
        method: 'post'
    },
    searchExper: {
        url: `${apiUrl}/Search/SearchExper`,
        method: 'post'
    },
    search: {
        url: `${apiUrl}/Search/search`,
        method: 'post'
    },
    // 社区接口
    getComList: {
        url: `${apiUrl}/Community/ReadCommunity`,
        method: 'post'
    },
    getComInfo: {
        url: `${apiUrl}/Community/CommunityInfo`,
        method: 'get'
    },
    getComType: {
        url: `${apiUrl}/Community/CommunityType`,
        method: 'get'
    },
    writeCommunity: {
        url: `${apiUrl}/Community/WriteCommunity`,
        method: 'post'
    },
    updateCommunity: {
        url: `${apiUrl}/Community/UpdateCommunity`,
        method: 'put'
    },
    getComHotRecommend: {
        url: `${apiUrl}/Community/GetHotRecommend`,
        method: 'get'
    },
    deleteCommunity: {
        url: `${apiUrl}/Community/DeleteCommunity`,
        method: 'delete'
    },
    addComComment: {
        url: `${apiUrl}/Community/AddCommunityComment`,
        method: 'put'
    },
    //社区评论点赞
    addComCommentLike: {
        url: `${apiUrl}/Community/CreatCommunityCommentLike`,
        method: 'get'
    },
    addComReply: {
        url: `${apiUrl}/Community/AddCommunityReplay`,
        method: 'post'
    },
    addComReplyLike: {
        url: `${apiUrl}/Community/CreatCommunityReplyLike`,
        method: 'put'
    },
    addComCollection: {
        url: `${apiUrl}/Community/AddCommunityCollection`,
        method: 'post'
    },
    removeComCollection: {
        url: `${apiUrl}/Community/DeleteCommunityCollection`,
        method: 'delete'
    },
    uploadComImgEdit: {
        url: `${apiUrl}/Community/UploadCommunityEditIMG`,
        method: 'put'
    },
    uploadComImgList: {
        url: `${apiUrl}/Community/UploadCommunityListIMG`,
        method: 'put'
    },
    addComZan: {
        url: `${apiUrl}/Community/UpdateCommunityZan`,
        method: 'put'
    },
    removeComZan: {
        url: `${apiUrl}/Community/RemoveZan`,
        method: 'delete'
    },
    addComReplyZan: {
        url: `${apiUrl}/Community/CommunityReplyZan`,
        method: 'put'
    },
    getComComment: {
        url: `${apiUrl}/Community/GetCommunityCommon`,
        method: 'post'
    },
    getComReply: {
        url: `${apiUrl}/Community/GetCommunityReply`,
        method: 'post'
    },
    //详情页社区收藏点赞个数
    checkComLikeAndCollection: {
        url: `${apiUrl}/Community/CheckCollectAndZan`,
        method: 'post'
    },
    // 直播（作废）
    getLiveUrls: {
        url: `${apiUrl}/video/GetLiveUrls`,
        method: 'get'
    },
    //取消一个视频回看收藏
    deleteVideoPlaybackCollection: {
        url: `${apiUrl}/video/DeleteVideoPlaybackCollection`,
        method: 'delete'
    },
    // 品牌汇
    getProductCompany: {
        url: `${apiUrl}/brand/GetProductCompany`,
        method: 'get'
    },
    getBrandList: {
        url: `${apiUrl}/brand/GetAllBrands`,
        method: 'get'
    },
    getSearchCount: {
        url: `${apiUrl}/brand/GetSearchCount`,
        method: 'get'
    },
    getSearch: {
        url: `${apiUrl}/brand/GetSearch`,
        method: 'get'
    },
    getCompanyProducts: {
        url: `${apiUrl}/brand/GetCommpanyProducts`,
        method: 'get'
    },
    getCompanyProductsAll: {
        url: `${apiUrl}/brand/GetCommpanyProductsAll`,
        method: 'get'
    },
    getCompanySelectProducts: {
        url: `${apiUrl}/brand/GetCommpanySelectProducts`,
        method: 'get'
    },
    getCompanySelectProductsAll: {
        url: `${apiUrl}/brand/GetCommpanySelectProductsAll`,
        method: 'get'
    },
    saveCompanyProductLink: {
        url: `${apiUrl}/brand/SaveCompanyProductLink`,
        method: 'post'
    },
    addCompanyProduct: {
        url: `${apiUrl}/brand/AddCompanyProduct`,
        method: 'post'
    },
    getRecommondProducts: {
        url: `${apiUrl}/brand/GetRecommondProducts`,
        method: 'get'
    },
    getBrandInfoById: {
        url: `${apiUrl}/brand/GetBrandInfoByID`,
        method: 'get'
    },
    getProductsInBrand: {
        url: `${apiUrl}/brand/GetProductsInBrand`,
        method: 'post'
    },
    getProductsByBrandID: {
        url: `${apiUrl}/brand/GetProductsByBrandID`,
        method: 'post'
    },
    getCatalog: {
        url: `${apiUrl}/App/GetCatalog`,
        method: 'get'
    },
    getJobList: {
        url: `${apiUrl}/brand/GetJobList`,
        method: 'get'
    },

    applyForPosition: {
        url: `${apiUrl}/brand/ApplyForPosition`,
        method: 'post'
    },
    addBrand: {
        url: `${apiUrl}/brand/AddBrand`,
        method: 'post'
    },
    addApplyProduct: {
        url: `${apiUrl}/brand/AddApplyProduct`,
        method: 'post'
    },
    getProductDetail: {
        url: `${apiUrl}/brand/GetProductDetail`,
        method: 'get'
    },
    checkBrandCollection: {
        url: `${apiUrl}/brand/CheckCollection`,
        method: 'post'
    },
    addBrandCollection: {
        url: `${apiUrl}/brand/AddBrandCollection`,
        method: 'post'
    },
    removeBrandCollection: {
        url: `${apiUrl}/brand/RemoveBrandCollection`,
        method: 'delete'
    },
    getBrandFocus: {
        url: `${apiUrl}/brand/GetFocus`,
        method: 'post'
    },
    getBrandPubsRecords: {
        url: `${apiUrl}/brand/GetPubsRecords`,
        method: 'post'
    },
    getBrandZanRecords: {
        url: `${apiUrl}/brand/getZanRecords`,
        method: 'post'
    },
    getBrandEvalZanRecords: {
        url: `${apiUrl}/brand/GetEvalZanAndPub`,
        method: 'post'
    },
    creatProductCollection: {
        url: `${apiUrl}/brand/CreatProductCollection`,
        method: 'post'
    },
    checkProductCollection: {
        url: `${apiUrl}/brand/CheckProductCollection`,
        method: 'post'
    },
    removeProjectCollection: {
        url: `${apiUrl}/brand/RemoveProjectCollection`,
        method: 'delete'
    },
    getCommpanyBrands: {
        url: `${apiUrl}/brand/GetCommpanyBrands`,
        method: 'get'
    },
    getCountry: {
        url: `${apiUrl}/brand/GetCountry`,
        method: 'get'
    },
    getAudioJobList: {
        url: `${apiUrl}/china_audio/GetJobList`,
        method: 'get'
    },
    applyAudioJob: {
        url: `${apiUrl}/china_audio/ApplyForPosition`,
        method: 'post'
    },
    getFriendLinks: {
        url: `${apiUrl}/china_audio/GetFriendLink`,
        method: 'get'
    },
    //注销账户
    userApplyCancel: {
        url: `${userUrl}/tools/UserP/UserApplyCancel`,
        method: 'get'
    },
    //获取app品牌
    getAppBrands: {
        url: `${apiUrl}/brand/GetAppBrands`,
        method: 'get'
    },
    getCompanyBruandsApp: {
        url: `${apiUrl}/brand/GetCompanyBruandsApp`,
        method: 'get'
    },

    getStarIntroduction: {
        url: `${apiUrl}/bussusercenter/GetStarIntroduction`,
        method: 'get'
    },

    starIntroduction: {
        url: `${apiUrl}/bussusercenter/StarIntroduction`,
        method: 'post'
    },
    boundUserPhone: {
        url: `${userUrl}/tools/UserP/BoundUserPhone`,
        method: 'get'
    },
    getShareList: {
        url: `${apiUrl}/App/GetShareList`,
        method: 'get'
    },

    setFeedBack: {
        url: `${apiUrl}/App/SetFeedBack`,
        method: 'post'
    },

    //直播相关
    getLiveUrl: {
        url: `${apiUrl}/video/GetLiveUrl`,
        method: 'get'
    },
    getMessage: {
        url: `${apiUrl}/video/GetMessage`,
        method: 'get'
    },
    //获取当天直播人数
    getDayLiveClickCount: {
        url: `${apiUrl}/video/GetDayLiveClickCount`,
        method: 'get'
    },
    //观看直播计数
    postClickCount: {
        url: `${apiUrl}/video/PostClickCount`,
        method: 'post'
    },

    //企业用户直播申请列表
    getApplyLiveVideoList: {
        url: `${apiUrl}/video/GetApplyLiveVideoList`,
        method: 'get'
    },
    //申请直播
    addApplyLiveVideo: {
        url: `${apiUrl}/video/AddApplyLiveVideo`,
        method: 'post'
    },
    //修改申请直播
    editApplyLiveVideo: {
        url: `${apiUrl}/video/EditApplyLiveVideo`,
        method: 'post'
    },
    //获取一条企业申请直播信息
    getApplyLiveVideo: {
        url: `${apiUrl}/video/GetApplyLiveVideo`,
        method: 'get'
    },
    //删除直播申请
    deleteApplyLive: {
        url: `${apiUrl}/video/DeleteApplyLive`,
        method: 'get'
    },
    //
    updateLiveState: {
        url: `${apiUrl}/video/UpdateLiveState`,
        method: 'put'
    },

    //微信支付
    addWeiXinAppPay: {
        url: `${apiUrl}/WeiXinPay/AppPay`,
        method: 'post'
    },

    //对公订单状态
    getCompanyApplyState: {
        url: `${userUrl}/UserCenter/PersonalInfo/CompanyApplyState`,
        method: 'get'
    },
    //获取底价
    askPriceInfo: {
        url: `${apiUrl}/usercommon/AskPriceInfo`,
        method: 'post'
    },

    //用户手机登录注册
    userLoginPhoneApp: {
        url: `${userUrl}/tools/UserP/UserLoginPhoneApp`,
        method: 'post'
    },
    //获取用户签到信息
    getUserSign: {
        url: `${apiUrl}/usercommon/GetUserSign`,
        method: 'get'
    },
    //用户签到
    userSign: {
        url: `${apiUrl}/usercommon/UserSign`,
        method: 'get'
    },
    //获取用户任务信息
    getUserTask: {
        url: `${apiUrl}/usercommon/GetUserTask`,
        method: 'get'
    },
    //用户日常任务签到
    userRiChangTaskSign: {
        url: `${apiUrl}/usercommon/UserRiChangTaskSign`,
        method: 'get'
    },
    //用户特殊任务签到
    userTeShuTaskSign: {
        url: `${apiUrl}/usercommon/UserTeShuTaskSign`,
        method: 'get'
    },
    //用户领取任务积分
    userTaskScore: {
        url: `${apiUrl}/usercommon/UserTaskScore`,
        method: 'get'
    },
}


export default api;