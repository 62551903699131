import React, {
    Component
} from 'react';
import { List, TextareaItem, Toast } from 'antd-mobile-v2';
import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";


class MsgToFansReleaseWrapper extends Component {
    state = {
        userPID: this.props.location?.search.replace('?', ''),//从网址中取栏目类型
        message: '',
        count: ''
    };

    componentDidUpdate() {

        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID,
                info: data
            })
        }
        //右上角按钮动作
        window.saveInfo = function () {
            that.sendMessage()
        }
    }
    sendMessage = async () => {
        const {
            userPID,
            count
        } = this.state;
        if (count === '') return;

        const res = await httpAxios(api.bcSendMessage, {
            busiID: userPID,
            messageContent: count
        })
        if (res) {
            Toast.info('消息发送成功', 1);
        }

    }

    render() {
        return (
            <div>
                <List renderHeader={() => '此公告每周限制发布一次，限制200字。公告会显示在粉丝的消息中心和企业主页里。'}>
                    <TextareaItem
                        placeholder='请填写您公告内容'
                        rows={5}
                        count={100}
                        onChange={(value) => this.setState({ count: value })}
                    />
                </List>
            </div>
        )
    }
}


export default MsgToFansReleaseWrapper;