import {combineReducers} from 'redux-immutable';
import {reducer as headerReducer} from '../common/header/store';
import {reducer as sidebarReducer} from '../common/sidebar/store';
import {reducer as mycenterReducer} from '../pages/mycenter/store';
import {reducer as loginReducer} from '../pages/login/store';
import {reducer as registerReducer} from '../pages/register/store';
import {reducer as fileReducer} from '../pages/file/store';
import {reducer as homeReducer} from '../pages/home/store'

const reducer=combineReducers({
    header:headerReducer,
    sidebar:sidebarReducer,
    mycenter:mycenterReducer,
    login:loginReducer,
    register:registerReducer,
    file:fileReducer,
    home: homeReducer,

});
export default reducer;
