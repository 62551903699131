import React, {
    Component
} from 'react';
import {
    ReportWrapper,
    ItemSelected,
    Item,
    ReportInput,
    ReportButton
} from './ReportCommentsStyle';
import {
    ClearFloat
} from '../../../common/style';
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import { Toast } from 'antd-mobile-v2';

class ReportPersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            reportAuthor: '被举报人名',
            reportTitle: '被举报内容被举报内容被举报内容',
            reportList: [{
                name: '垃圾广告营销',
                selected: false
            }, {
                name: '侮辱谩骂',
                selected: false
            }, {
                name: '违法反动',
                selected: false
            }, {
                name: '淫秽色情',
                selected: false
            }, {
                name: '嘲讽/不友善',
                selected: false
            }, {
                name: '疑似机器人',
                selected: false
            }],
            toUserPID: '',
            ID: 0,
            reason: ''
        }
    }
    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            // alert(data.UserPID);
            that.setState({
                userId: data.UserPID
            })
        }

        window.reportInfo = function (report) {
            // alert(JSON.stringify(report));
            that.setState({
                reportAuthor: report.nikeName,
                reportTitle: report.reportTitle,
                toUserPID: report.toUserPID,
                ID: report.id,
                userId: report.userId
            })
        }
    }
    clickItem = (index) => {
        let list = [...this.state.reportList];
        list[index].selected = !list[index].selected;
        this.setState({
            reportList: list
        })
    }
    setReason = (value) => {
        this.setState({ reason: value });
    }

    submitReport = async () => {
        const {
            reportList
        } = this.state;
        const types = reportList.filter(item => (item.selected)).map(i => (i.name)).join(',');

        const res = await httpAxios(api.postLiveCommentReport, {
            "liveMessageID": this.state.ID,
            "UserPID": this.state.userId,
            "ToUserPID": this.state.toUserPID,
            "Phone": '',
            "Type": types,
            "Reason": this.state.reason,
            "State": 0
        })

        if (res.Code === 200) {
            Toast.info("信息举报成功！");
            setTimeout(() => {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "closeperson" }));
            }, 2000);
        }
    }

    render() {
        return (
            <ReportWrapper>
                <h6><span>{this.state.reportAuthor}</span>：{this.state.reportTitle}</h6>
                {
                    this.state.reportList.map((item, index) => {
                        if (item.selected) {
                            return <ItemSelected key={`item-${index}`} onClick={this.clickItem.bind(this, index)}>{item.name}</ItemSelected>
                        } else {
                            return <Item key={`item-${index}`} onClick={this.clickItem.bind(this, index)}>{item.name}</Item>
                        }
                    })
                }
                <ReportInput placeholder="请输入您的问题" onChange={(e) => this.setReason(e.target.value)} ></ReportInput>
                <ReportButton onClick={this.submitReport}>提交</ReportButton>
                <ClearFloat />
            </ReportWrapper>
        )
    }
}

export default ReportPersonal