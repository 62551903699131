/**
 * created by SunChenyao on 2020/08/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    NewsDetailWrapper,
    NewsItemOther,
    NewsTitle,
    NewsPub,
    VideoImg,
    LiveState,
    LiveStates
} from '../../../common/generalStyle';
import {
    NavBar,
    SearchIcon,
    VideoIcon,
    ExhibitionDiv,
    ExhibitionTime
} from './ContentStyle';

// import Label from "./Label";
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";

const searchButton = require('../../../images/search_button.svg')
const listVipLogo = require('../../../images/listVipLogo.png');

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            newsType: '5',
            auditionType: '999',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
            buttonIcon: '',
            IsBussUser: true
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        pageIndex = 0;
        // this.getComType();
        // this.getList(null, null, null);


        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    getList = async (targetID, readType, listType) => {
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "type": 0
        }
        const res = await httpAxios(api.getExhListWeb, params);
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.setState({
                    data: [],
                })
                this.state.hasMore = false;
            }

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        pageIndex += 1;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
            comTypes,
            livedata,
            IsBussUser,
            targetID
        } = this.state;

        // const row = (rowData, sectionID, rowID) => {
        //     // console.log(rowID);
        //     let obj = {};
        //     if (rowID < data.length) {
        //         obj = data[rowID];
        //     } else {

        //     }

        //     //alert(JSON.stringify(obj));
        //     return (
        //         <div key={rowID}>

        //             <NewsDetailWrapper >
        //                 <a onClick={() => {
        //                     localStorage.setItem("buttonIcon", JSON.stringify({ type: "share", buttontitle: obj.Title, description: obj.Description?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/exhibition/ExhibitionDetailListH5/' + obj.ExID + '/' + obj.Title, imageUrl: obj.TopImg }));
        //                     window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/exhibition/ExhibitionDetailList/' + obj.ExID + '/' + encodeURI(obj.Title) }));
        //                 }} >
        //                     <NewsItemOther>
        //                         <NewsTitle>{obj.Title}</NewsTitle>
        //                         <VideoImg>
        //                             <img src={obj.CoverImg + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
        //                             <VideoIcon>
        //                                 <span>{(new Date(obj.StartTime)).getFullYear()}</span>
        //                             </VideoIcon>
        //                             <ExhibitionDiv />
        //                             {obj.EXState == 2 && <LiveState>正在展出</LiveState>}
        //                             {obj.EXState == 3 && <LiveStates>往期展出</LiveStates>}
        //                             <ExhibitionTime>{(new Date(obj.StartTime)).getMonth() + 1}月{(new Date(obj.StartTime)).getDate()}日～{(new Date(obj.FinishTime)).getMonth() + 1}月{(new Date(obj.FinishTime)).getDate()}日</ExhibitionTime>
        //                         </VideoImg>
        //                     </NewsItemOther >
        //                     <NewsPub>
        //                         <img src={obj.HeadImage} alt="" />
        //                         <span>{obj.Organizer}</span>
        //                         <span>主办方</span>
        //                         {/* <span>荣誉冠名</span> */}
        //                     </NewsPub>
        //                 </a>
        //             </NewsDetailWrapper>

        //         </div>
        //     );
        // };
        return (<div>
            <NavBar>
                {/* <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton> */}
                <span>展会</span>
                <SearchIcon onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><img src={searchButton} alt="搜索" /></SearchIcon>
            </NavBar>
            <div style={{ height: '5.5rem' }}></div>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            <NewsDetailWrapper >
                                <a onClick={() => {
                                    localStorage.setItem("buttonIcon", JSON.stringify({ type: "share", title: obj.Title, description: obj.Description?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/exhibition/ExhibitionDetailListH5/' + obj.ExID + '/' + obj.Title, imageUrl: obj.TopImg }));
                                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/exhibition/ExhibitionDetailList/' + obj.ExID + '/' + encodeURI(obj.Title), buttonIcon: obj.Title }));
                                }} >
                                    <NewsItemOther>
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.CoverImg + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                            {/* <VideoIcon>
                                                <span>{(new Date(obj.StartTime)).getFullYear()}</span>
                                            </VideoIcon> */}
                                            {/* <ExhibitionDiv /> */}
                                            {obj.EXState == 2 && <LiveState>正在展出</LiveState>}
                                            {obj.EXState == 3 && <LiveStates>往期展出</LiveStates>}
                                            {/* <ExhibitionTime>{(new Date(obj.StartTime)).getMonth() + 1}月{(new Date(obj.StartTime)).getDate()}日～{(new Date(obj.FinishTime)).getMonth() + 1}月{(new Date(obj.FinishTime)).getDate()}日</ExhibitionTime> */}
                                        </VideoImg>
                                    </NewsItemOther >
                                    <NewsPub>
                                        {/* {
                                            IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                        } */}
                                        <img src={obj.HeadImage} alt="" />
                                        <span>{obj.Organizer}</span>
                                        <span>主办方</span>
                                        <div className='exhibition-time'>{(new Date(obj.StartTime)).getMonth() + 1}月{(new Date(obj.StartTime)).getDate()}日～{(new Date(obj.FinishTime)).getMonth() + 1}月{(new Date(obj.FinishTime)).getDate()}日</div>
                                        {/* <span>荣誉冠名</span> */}
                                    </NewsPub>
                                </a>
                            </NewsDetailWrapper>
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content