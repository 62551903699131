import React, {
    Component
} from 'react';
import { List, Modal } from 'antd-mobile-v2';
import {
    SettingsWrapper,
    ExitButton
} from '../MycenterPortraitStyle';
import api from "../../../../http/api";
import axios from 'axios';


const Item = List.Item;
const Brief = Item.Brief;
const prompt = Modal.prompt;

class Settings extends Component {
    state = {
        Phone: '',
        Email: '',
        password: ''
    }

    async componentDidMount() {
        await this.getUserInfo();
    }

    getUserInfo = async () => {
        const res = await axios.post(api.getUserInfo.url, {
            UserPID: sessionStorage.getItem("BUSS_CENTER_TO_BRAND_MANAGER_ID"),
        })
        if (res && res.data.Data) {
            this.setState({
                info: res.data.Data
            })

            this.setState({ Phone: res.data.Data.Phone });
            this.setState({ Email: res.data.Data.Email });
        }
    }

    goBackPage = () => {
        // history.goBack();  //返回上一页这段代码
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    goLogout = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "logout" }));
    }

    render() {
        return (
            <SettingsWrapper>
                {/* <NavBar>
                    <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                    <span>设置</span>
                </NavBar>
                <div style={{ height: '5.5rem' }}></div> */}
                {/* NavBar的高度占位 */}

                <List renderHeader={() => ''}>
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '安全设置', weburl: '/SecuritySetting' })); }}>安全设置</Item>
                    {/* <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '通知设置', weburl: '/StarEdit'})); }}>通知设置</Item> */}
                    {/* <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证设置', buttonTitle: '查看对公转账信息', weburl: '/RenZheng' })); }}>认证设置</Item> */}
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证设置', weburl: '/RenZheng' })); }}>认证设置</Item> 
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '三方社交账号绑定', weburl: '/BangDing' })); }}>三方社交账号绑定</Item>
                    <Item className='listheight' arrow="horizontal" extra={"请谨慎操作"} onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "账户注销", weburl: "/cancellation" })); }}>账户注销{/*<Brief>注销后账户无法恢复，请谨慎操作</Brief>*/}</Item>
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '第三方数据共享清单', weburl: '/SharedList' })); }}>第三方数据共享清单</Item>
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '意见与反馈', buttonTitle: '提交', weburl: '/Feedback' })); }}>意见与反馈</Item>
                </List>
                <List renderHeader={() => ''}>
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goabout" })); }}>关于中乐之声</Item>
                </List>
                <List renderHeader={() => ''}>
                    <ExitButton className='listheight' onClick={this.goLogout}>退出登录</ExitButton>
                </List>
            </SettingsWrapper >
        )
    }
}

export default Settings;