import React, {
    Component
} from 'react';
import {
    Wrapper,
    Auto,
    Title1
} from '../FileStyle';

class AboutUs extends Component {
    render() {
        return (
            <Wrapper>
                <Title1>中乐之声（Chinadudio.net.cn）</Title1>
                <Auto>
                    <p>中乐之声是北京中悦之声信息技术有限公司旗下的全资子平台，且是唯一可以使用“中乐之声”和“中乐之声网”在移动端进行全网整合的网络平台。“中乐之声网”信息名址已在工信部备案，编辑短信"中乐之声网"发送到106912114即可访问。</p>
                    <p>“中乐之声”设有展会⁺、视&音、新闻、社区、评测、品牌汇、直播、七个模块，为广大从业人员及爱好者提供一个全方位一站式数据信息技术服务平台，实现全国音响行业资源数据全盘整合。为企业及品牌商新增产品宣传渠道，为发烧友搭建互动交流平台，为音乐人提供风采展示窗口。</p>
                    <p>“中乐之声”是行业第一家开展线上“全国各类音响器材综合展会⁺”的平台，更有“视&音”板块可供行业领军人物、知名品牌专业工程师、资深评测人、音响发烧友、音乐人等业内人士对最新最前沿的行业资讯即时交流。</p>
                    <p>愿“中乐之声”成为全球最值得信赖的音响行业平台，与广大同业者、爱好者共同致力于推动行业新发展。</p>
                </Auto>
            </Wrapper>
        )
    }
}

export default AboutUs