import React, {
	Component
} from 'react';
import Content from './components/Content';
import {
	CommunityWrapper,
} from './style';

class MyShopCenter extends Component {
	render() {
		return (
			<CommunityWrapper>
				<Content userid={this.props.location?.search.replace('?', '')}/>
			</CommunityWrapper>
		)
	}
}

export default MyShopCenter