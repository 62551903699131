import styled from 'styled-components';
import {
  theme,themenew
} from '../../common/color';

export const FileWrapper = styled.div `
  width:100%;
`
export const Wrapper = styled.div `
  padding-top:1.75rem;
  margin-left:4%;
  width:92%;
  line-height:1.8rem;
`
export const Auto = styled.div `
  margin-top:1rem;
  color:${themenew.text_color};
  font-size:0.875rem;
  /* text-indent:1.75rem; */
  text-align:left;
  .bottom {
    text-align: right
  }
  span{
    font-weight:600;
  }
`
export const Auto1 = styled.div `
  margin-top:1rem;
  color:${themenew.text_color};
  font-size:0.875rem;
  text-indent:1.75rem;
  text-align:left;
  .bottom {
    text-align: right
  }
  span{
    font-weight:600;
  }
`
export const Title1 = styled.div `
  margin-top:1rem;
  color:${themenew.text_color};
  font-size:1.125rem;
  font-weight:bold;
  text-align:left;
  .bottom {
    text-align: right
  }
`
export const Warning = styled.div `
  margin-top:1rem;
  color:${theme.rose_color};
  text-align:left;
  .bottom {
    text-align: right
  }
`
export const NavBar = styled.div`
  width:100%;
  height:5.5rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.125rem;
    color:${themenew.text_color};
    margin-top:3.5rem;
  }
`
export const Content = styled.div `
  width:100%;
  //text-align:center;
  padding-bottom:5rem;
  line-height:1.8rem;
  background-color:#fff;
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:4%;
  margin-top:3.5rem;
  float:left;
`



export const Nav = styled.div `
  width:14rem;
  text-align:center;
  padding-top:3rem;
  .active{
    background-color:${theme.line_color};
  }
  @media (max-width:768px){
    width:100%;
  }
`
export const NavItem = styled.div `
  height:3rem;
  color:${theme.subtext};
  line-height:3rem;
  cursor:pointer;
  :hover{
      background-color:${theme.line_color};
  }
`


export const Title = styled.div `
  font-size:1.4rem;
  color:${theme.text};
`
export const Dec = styled.div `
  margin-top:1rem;
  color:${theme.subtext};
  text-indent:2rem;
  text-align:left;
  .bottom {
    text-align: right
  }
`

