/**
 * created by sunchenyao on 2021/2/2
 */
import React, {
    Component
} from 'react';
import {
    SettingsWrapper,
} from '../MycenterPortraitStyle';
import { List } from 'antd-mobile-v2';

const returnButton = require('../../../../images/return_button.svg');
const Item = List.Item;

export default class Collection extends Component {
    state = {
        userPID:"",
    };
    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        };
    }
    render() {
        return <SettingsWrapper>
            <List renderHeader={() => ''}>
                {/* <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我发布的新闻", weburl: "/MyReleaseNews" + '?' + this.state.userPID })) }}>新闻</Item> */}
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我发布的社区", weburl: "/MyReleasePost" + '?' + this.state.userPID })) }}>社区</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我发布的评测", weburl: "/MyReleaseEvaluation" + '?' + this.state.userPID })) }}>评测</Item>
                <Item className='listheight' extra={""} arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我发布的视&音", weburl: "/MyReleaseAudition" + '?' + this.state.userPID })) }}>视{"&"}音</Item>
                <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我发布的中乐之星", weburl: "/YiPeiMyRelease" })); }}>中乐之星</Item>
            </List>
        </SettingsWrapper>
    }
}