import styled from 'styled-components';
import {
  theme,
  themenew
} from "./color";

/**
 * created by zhangling on 2020/2/6
 */
export const PaginationWrapper = styled.div `
  // width:75rem;
  text-align:center;
  margin-top:5rem;
  .ant-pagination{
    display: inline-block 
  }
  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active){
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

  }
  .ant-pagination-item{
    line-height: 1.5rem;
    display: inline-block;
    background: transparent;
    border-radius: 0.125rem;
    margin: 0 0.3125rem!important;
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.65)!important;
    &:hover{
        border: 0.0625rem solid ${theme.blue_color};
        a{
            color: rgba(0, 0, 0, 0.25);
        }
    }
    &:focus{
        border: 0.0625rem solid ${theme.blue_color};
        a{
            color: #fff
        }
    }
  }
  >span{
    vertical-align:top;
    color: rgba(0, 0, 0, 0.25)
  }
  a:visited{
    color: #fff;
  }
  .ant-pagination-item-active{
        border: 0.0625rem solid ${theme.blue_color};
  }
    .ant-pagination-item-active a{
        color: #fff;
        background-color: ${theme.blue_color}
    }
    .ant-pagination-prev, .ant-pagination-next{
        border: 0.0625rem solid rgba(0, 0, 0, 0.25);
        i {
            vertical-align: 0
        }
    }
`
export const PurplePaginationWrapper = styled.div `
  // width:75rem;
  text-align:center;
  margin-top:3rem;
  
  .ant-pagination{
    display: inline-block 
  }
  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active){
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

  }
  .ant-pagination-item{
    line-height: 1.5rem;
    display: inline-block;
    background: transparent;
    border-radius: 0.125rem;
    margin: 0rem 0.3125rem!important;
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.65)!important;
    &:hover{
        border: 0.0625rem solid ${theme.theme_color};
        a{
            color: rgba(0, 0, 0, 0.25);
        }
    }
    &:focus{
        border: 0.0625rem solid ${theme.theme_color};
        a{
            color: #fff
        }
    }
  }
  >span{
    vertical-align:top;
    color: rgba(0, 0, 0, 0.25)
  }
  a:visited{
    color: #fff;
  }
  .ant-pagination-item-active{
        border: 0.0625rem solid ${theme.theme_color};
  }
    .ant-pagination-item-active a{
        color: #fff;
        background-color: ${theme.theme_color}
    }
    .ant-pagination-prev, .ant-pagination-next{
        border: 0.0625rem solid rgba(0, 0, 0, 0.25);
        i {
            vertical-align: 0
        }
    }
`
export const RedPaginationWrapper = styled.div `
  // width:75rem;
  text-align:center;
  margin-top:3rem;
  .ant-pagination{
    display: inline-block 
  }
  .ant-pagination-item{
    line-height: 1.5rem;
    background: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.65)!important;
    &:hover{
        a{
            color: ${theme.rose_color}
        }
    }
    &:focus{
        a{
            color: #fff
        }
    }
  }
  >span{
    vertical-align:top;
    color: ${theme.gray_state}
  }
  a{
    border-radius: 50%;
  }
  a:visited{
    color: #fff;
  }
    .ant-pagination-item-active a{
        color: #fff;
        background-color: ${theme.rose_color}
    }
`
export const Cancel = styled.span `
    float: right;
  line-height:2.5rem;
  color:${theme.theme_light_auxi_color};
  margin-right:4rem;
  :hover{
    cursor:pointer;
    color:${theme.theme_dark_auxi_color};
  }
  
`
export const NoResult = styled.div `
  text-align:center;
  margin: 1.875rem auto;
`
export const Choose = styled.div `
  position:relative;
  top:-8rem;
  left:13.2rem;
  color:#fff;
  >i{
    font-size:1.3rem;
  }
  :hover {
    .choose-list{
     visibility: visible
    }
  }
  .choose-list{
    visibility: hidden;
    transition: all .2s
  }
`
export const ChooseList = styled.div `
  background-color:#fff;
  color: ${theme.subtext};
  width:4.5rem;
  // height:4.3rem;
  
  position:absolute;
  top:0.7rem;
  left:-4rem;
  text-align:center;
  padding-top:0.1rem;
  font-size:0.8rem;
  div{
    margin:0.4rem 0;
  }
  div:hover{
    color:${theme.rose_color};
    cursor:pointer;
  }
  i{
    margin-right:0.5rem;
    font-weight: bold;
  }
`
export const Nav = styled.div `
  color:${theme.subtext};
  i{
    font-size:0.8rem;
    margin:0 0.5rem;
  }
  a {
    color: ${theme.subtext}!important;
  }
  margin-bottom: 0.625rem;
  @media (max-width:768px){
    margin-left:4%;
  }
`
//缺省页样式
export const EmptyWrapper = styled.div `
  width:100%;
  min-height:48rem;
  padding-top:4rem;
`
export const Empty = styled.div `
  width:20rem;
  height:23rem;
  text-align:center;
  margin:38% auto 3rem;
  img{
    width:15.5rem;
  }
  b{
    display:inline-block;
    width:100%;
    font-size: 1.25rem;
    color: #00222C;
    margin-bottom:1rem;
  }
  span{
    display:inline-block;
    width:100%;
    font-size: 1rem;
    color: #6D6D6D;
    margin-bottom:2.38rem;
  }
  botton{
    display:inline-block;
    width:18.2rem;
    height:3.5rem;
    background: #771AE3;
    border-radius: 0.5rem;
    line-height:3.5rem;
    color:#fff;
    :hover {
        background-color: ${theme.rose_color};
        cursor: pointer;
    }
  }
`
//上传图片
export const UploadImg = styled.div `
  width:7.8rem;
  height:7.8rem;
  line-height:7.8rem;
  background: #FFFFFF;
  border: 0.0625rem solid #F1F1F1;
  border-radius: 0.19rem;
  position: relative;
  img{
    width:100%;
    min-height:7.8rem;
  }
`
//面包屑
export const BreadcrumbNavigation = styled.div `
  line-height:2.38rem;
  font-size:0.88rem;
  margin-left:0.625rem;
  float:left;
  a{
    color:${themenew.text_color};
  }
  a:hover{
    opacity:0.9;
  }
  span{
    color:${themenew.subtext_color};
  }
`
//按钮样式
export const ButtonStyle = styled.div `
  float:right;
  margin-right:0.625rem;
  button{
    min-width:9.38rem;
    height:2.38rem;
    line-height:2.38rem;
    font-size:0.88rem;
    color:${themenew.text_white_color};
    background-color:${themenew.theme_color};
    border-radius:0.375rem;
    box-sizing:border-box;
    padding:0 1rem;
    margin-left:1.25rem;
  }
  button:hover{
    opacity:0.9;
  }
  //不可点样式
  .focused{
    background-color:${themenew.subtext_color};
  }
`
//分割线
export const DividingLine = styled.div `
  display:block;
  width:100%;
  min-width:100%;
  height:0.5px;
  background-color:${themenew.line_color};
  clear:both;
`
//数据流样式
export const Distributor = styled.div `
  width:23.75rem;
  height:7.19rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.25rem 0 rgba(0,0,0,0.14);
  border-radius: 0.5rem;
  margin-right:1.25rem;
  box-sizing:border-box;
  padding:1rem;
  float:left;
  div{
    float:left;
    width:5rem;
    height:5rem;
    overflow:hidden;
    margin-right:1.3rem;
    img{
      width:100%;
      min-height:5rem;
    }
  }
  h1{
    font-size:1.13rem;
    height:1.5rem;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-bottom:1rem;
  }
  h2{
    font-size:0.75rem;
    margin-bottom:0.25rem;
  }
  h3{
    font-size:0.75rem;
    :hover{
      color:${themenew.theme_color};
    }
  }
`
export const NewAndEvaluation = styled.div `
  display:inline-flex;
  width:36.25rem;
  height:13rem;
  color:${themenew.text_color};
  background:${themenew.bg_color};
  box-shadow:0 0 0.25rem 0 rgba(0,0,0,0.14);
  border-radius:0.5rem;
  margin:0 0.625rem 1.25rem;
  overflow:hidden;
  h7{           //平衡无图时的外框位置错误bug
    width:0;
    >img{
      min-height:13rem;
    }
  }
  h6{
    width:68%;
    height:13rem;
    position:relative;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    img{
      width:100%;
      min-height:13rem;
      object-fit: cover;
    }
    >i{
      position:absolute;
      line-height:13rem;
      left:4.8rem;
      color:${themenew.text_white_color};
      font-size:5rem;
      opacity:0.8;
      a{
        color:${themenew.text_white_color};
      }
    }
  }
  div{
    position: relative;
    width:100%;
    height:100%;
    padding:0 1.25rem;
  }
  h1{
    font-size:1.25rem;
    position:relative;
    top:1.56rem;
    margin-bottom:1.56rem;
    margin-right:1.25rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    :hover b{
      color:${themenew.theme_color};
    }
  }
  h2{
    font-size:0.88rem;
    position:relative;
    top:1rem;
    margin-bottom:0.5rem;
    margin-right:1.25rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  h3{
    line-height:1.5rem;
    font-size:1rem;
    color:${themenew.subtext_color};
    position:absolute;
    bottom:0.8rem;
    >i{
      font-size:1.5rem;
      color:${themenew.subicon_color};
      vertical-align:middle;
    }
    >span{
      font-size:0.75rem;
      color:${themenew.subtext_color};
      vertical-align:middle;
    }
  }
  h4{
    position:absolute;
    bottom:0.625rem;
    right:1rem;
    >i{
      font-size:1.5rem;
      color:${themenew.subicon_color};
    }
    >i:hover{
      color:${themenew.error_color};
    }
  }
  h5{
    position:absolute;
    bottom:0.625rem;
    right:3.5rem;
    >i{
      font-size:1.5rem;
      color:${themenew.subicon_color};
    }
    >i:hover{
      color:${themenew.text_color};
    }
  }
  h8{
    position:absolute;
    bottom:0.625rem;
    right:1rem;
    >i{
      font-size:1.5rem;
      color:${themenew.error_color};
    }
    >i:hover{
      color:${themenew.subicon_color};
    }
  }
  :hover{
    box-shadow: 0 1.25rem 2.5rem 0 rgba(0,0,0,0.06);
  }
`
export const EvaluationAndExhibition = styled.div `
  display:inline-block;
  width:17.5rem;
  height:17.5rem;
  color:${themenew.text_color};
  background:${themenew.bg_color};
  box-shadow:0 0 0.25rem 0 rgba(0,0,0,0.14);
  border-radius:0.5rem;
  position: relative;
  margin:0 0.625rem 1.25rem;
  overflow:hidden;
  div{
    width:17.5rem;
    height:13.13rem;
    line-height:13.13rem;
    text-align:center;
    position:absolute;
    i{
      color:${themenew.text_white_color};
      font-size:5rem;
      opacity:0.8;
    }
  }
  img{
    width:17.5rem;
    height:13.13rem;
    margin-bottom:0.63rem;
  }
  h1{
    width:90%;
    font-size:0.88rem;
    margin-left:5%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
  }
  h2{
    line-height:1.5rem;
    font-size:1rem;
    color:${themenew.subtext_color};
    position:absolute;
    bottom:0.3rem;
    left:3%;
    >i{
      font-size:1.5rem;
      color:${themenew.subicon_color};
      vertical-align:middle;
    }
    >span{
      font-size:0.75rem;
      color:${themenew.subtext_color};
      vertical-align:middle;
    }
  }
  h3{
    position:absolute;
    bottom:0;
    right:3%;
    >i{
      font-size:1.5rem;
      color:${themenew.subicon_color};
    }
    >i:hover{
      color:${themenew.error_color};
    }
  }
  h4{
    position:absolute;
    bottom:0;
    right:15%;
    >i{
      font-size:1.5rem;
      color:${themenew.subicon_color};
    }
    >i:hover{
      color:${themenew.text_color};
    }
  }
  h5{
    position:absolute;
    bottom:0;
    right:3%;
    >i{
      font-size:1.5rem;
      color:${themenew.error_color};
    }
    >i:hover{
      color:${themenew.subicon_color};
    }
  }
  :hover{
    box-shadow: 0 1.25rem 2.5rem 0 rgba(0,0,0,0.06);
  }
`
export const BrandAndProduct = styled.div `
  display:inline-block;
  width:11.25rem;
  height:13.75rem;
  color:${themenew.text_color};
  background:${themenew.bg_color};
  box-shadow:0 0 0.25rem 0 rgba(0,0,0,0.14);
  border-radius:0.5rem;
  position: relative;
  margin:0 0.625rem 1.25rem;
  overflow:hidden;
  img{
    width:11.25rem;
    height:11.25rem;
    margin-bottom:0.63rem;
  }
  h1{
    width:65%;
    font-size:0.88rem;
    text-align:center;
    margin-left:17.5%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
  }
  .checkbox{
    position:absolute;
    bottom:0.5rem;
    left:5%;
    .ant-checkbox-checked::after{
      border:1px solid ${themenew.theme_color};
    }
    .ant-checkbox-checked .ant-checkbox-inner{
      background-color:${themenew.theme_color};
      border-color:${themenew.theme_color};
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
      border-color:${themenew.theme_color};
    }
  }
  h2{
    position:absolute;
    bottom:0.05rem;
    right:3%;
    >i{
      font-size:1.5rem;
      color:${themenew.subicon_color};
    }
    >i:hover{
      color:${themenew.error_color};
    }
  }
  :hover{
    box-shadow: 0 1.25rem 2.5rem 0 rgba(0,0,0,0.06);
  }
`
export const ShopAndAuditionWrapper = styled.div `
  width:100%;
  margin:2.38rem 0 1rem;
`
export const ShopAndAudition = styled.div `
  display:inline-block;
  width:36.25rem;
  height:13rem;
  color:${themenew.text_color};
  background:${themenew.bg_color};
  box-shadow:0 0 4px 0 rgba(0,0,0,0.14);
  border-radius:0.5rem;
  position: relative;
  margin:0 0.625rem 1.25rem;
  overflow:hidden;
  img{
    width:13rem;
    height:13rem;
    float:left;
    margin-right:1.25rem;
  }
  div{
    width:20.13rem;
    position:absolute;
    top:2.38rem;
    left:14.25rem;
  }
  h1{
    width:20.13rem;
    font-size:1.25rem;
    position:absolute;
    top:2.38rem;
    left:14.25rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  i{
    font-size:1.5rem;
    color:${themenew.subicon_color};
    float:right;
    margin-top:0.625rem;
    margin-right:1rem;
  }
  i:hover{
    color:${themenew.error_color};
  }
  h2{
    width:20.13rem;
    font-size:1rem;
    margin-top:6rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  h3{
    font-size:0.88rem;
    margin-top:0.13rem;
  }
  span{
    display:block;
    width:20.13rem;
    font-size:0.75rem;
    color:${themenew.subtext_color};
    position:absolute;
    bottom:1.5rem;
    left:14.25rem;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
    >a{
      color:${themenew.businesscenter_blue_color};
    }
    >a:hover{
      color:${themenew.theme_color};
    }
  }
  //:hover{
  //  box-shadow: 0 1.25rem 2.5rem 0 rgba(0,0,0,0.06);
  //}
`
export const Exhibition = styled.div `
  display:inline-block;
  width:23.75rem;
  height:12.5rem;
  color:${themenew.text_color};
  background:${themenew.bg_color};
  box-shadow:0 0 4px 0 rgba(0,0,0,0.14);
  border-radius:0.5rem;
  position: relative;
  margin:0 0.625rem 1.25rem;
  overflow:hidden;
  img{
    width:23.75rem;
    height:7.5rem;
    margin-bottom:0.63rem;
  }
  h1{
    width:94%;
    font-size:0.88rem;
    margin-left:3%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  h2{
    line-height:1.5rem;
    font-size:0.75rem;
    color:${themenew.subtext_color};
    position:absolute;
    bottom:0.3rem;
    right:3%;
  }
  :hover{
    box-shadow: 0 1.25rem 2.5rem 0 rgba(0,0,0,0.06);
  }
`
export const ExhibitionStateOne = styled.div `
  display:inline-block;
  min-width:3.5rem;
  height:1rem;
  line-height:1rem;
  text-align:center;
  font-size:0.63rem;
  border-radius:0.125rem;
  color:${themenew.text_white_color};
  background-color:${themenew.theme_color};
  box-sizing:border-box;
  padding:0 0.5rem;
  position:absolute;
  bottom:0.5rem;
  left:3%;
`
export const ExhibitionStateTwo = styled.div `
  display:inline-block;
  min-width:3.5rem;
  height:1rem;
  line-height:1rem;
  text-align:center;
  font-size:0.63rem;
  border-radius:0.125rem;
  color:${themenew.text_white_color};
  background-color:${themenew.warning_color};
  box-sizing:border-box;
  padding:0 0.5rem;
  position:absolute;
  bottom:0.5rem;
  left:3%;
`
export const ExhibitionStateThree = styled.div `
  display:inline-block;
  min-width:3.5rem;
  height:1rem;
  line-height:1rem;
  text-align:center;
  font-size:0.63rem;
  border-radius:0.125rem;
  color:${themenew.text_white_color};
  background-color:${themenew.subicon_color};
  box-sizing:border-box;
  padding:0 0.5rem;
  position:absolute;
  bottom:0.5rem;
  left:3%;
`
//展开查看更多样式
export const More = styled.div `
  width:100%;
  text-align:center;
  span{
    font-size:1rem;
    color:${themenew.text_color};
  }
  i{
    font-size:1rem;
    color:${themenew.subicon_color};
  }
  :hover{
    span{
      color:${themenew.theme_color};
    }
    i{
      color:${themenew.theme_color};
    }
  }
`
//Tab样式
export const BusinesscenterTab = styled.div `
  width:100%;
  height:2.8rem;
`
export const TabHover = styled.div `
  display:inline-block;
  min-width:9.38rem;
  height:2.8rem;
  line-height:2.8rem;
  font-size:0.94rem;
  font-weight:600;
  text-align:center;
  border-bottom:0.06rem solid ${themenew.businesscenter_blue_color};
  color:${themenew.businesscenter_blue_color};
  box-sizing:border-box;
  padding:0 1rem;
`
export const Tab = styled.div `
  display:inline-block;
  min-width:9.38rem;
  height:2.8rem;
  line-height:2.8rem;
  font-size:0.94rem;
  text-align:center;
  color:${themenew.text_color};
  box-sizing:border-box;
  padding:0 1rem;
`
//翻页样式
export const PaginationNewWrapper = styled.div `
  width:100%;
  text-align:center;
  //margin-top:2rem;
  position: absolute;
  bottom:1rem;
`
//清楚浮动
export const ClearFloat = styled.div`
  clear: both;
`
