import React, { Component } from 'react';
import {
    MoreFilterWrapper,
    FilterContent,
    FilterContentDeletions,
    FilterContentlist,
    MoreButtonBG,
    MoreButton,
    MoreFilterTab,
    MoreFilterRight,
    MoreFilterRightBrands
} from '../style';
import { Checkbox } from 'antd';

import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import { set } from 'immutable';

class MoreFilter extends Component {
    state = {
        moreTab: '1',
        country: [],
        brand: [],
        label: [
            {
                id: "3", name: "耳机", Selected: false, include: [
                    {
                        name: "佩戴方式", include: [
                            { name: "头戴式", Selected: false },
                            { name: "半入耳式", Selected: false },
                            { name: "入耳式", Selected: false },
                            { name: "颈挂式", Selected: false },
                            { name: "骨传导式", Selected: false }
                        ]
                    },
                    {
                        name: "场景", include: [
                            { name: "舞台耳机", Selected: false },
                            { name: "运动耳机", Selected: false },
                            { name: "监听耳机", Selected: false },
                            { name: "游戏耳机", Selected: false },
                            { name: "录音耳机", Selected: false },
                            { name: "商用耳机", Selected: false }
                        ]
                    },
                    {
                        name: "连接类型", include: [
                            { name: "有线耳机", Selected: false },
                            { name: "蓝牙耳机", Selected: false },
                            { name: "真无线耳机", Selected: false },
                            { name: "无线耳机", Selected: false },
                        ]
                    },
                    {
                        name: "发声原理", include: [
                            { name: "动圈", Selected: false },
                            { name: "动铁", Selected: false },
                            { name: "圈铁结合", Selected: false },
                            { name: "静电", Selected: false },
                        ]
                    },
                    {
                        name: "降噪", include: [
                            { name: "通话降噪", Selected: false },
                            { name: "主动降噪", Selected: false },
                        ]
                    },
                ]
            },
            {
                id: "4", name: "音箱", Selected: false, include: [
                    {
                        name: "用途", include: [
                            { name: "便携音箱", Selected: false },
                            { name: "智能音箱", Selected: false },
                            { name: "HIFI音箱", Selected: false },
                            { name: "家庭影院系统", Selected: false },
                            { name: "商用音箱", Selected: false },
                        ]
                    },
                    {
                        name: "摆放方式", include: [
                            { name: "落地音箱", Selected: false },
                            { name: "书架音箱", Selected: false },
                            { name: "嵌入式音箱", Selected: false },
                            { name: "回音壁音箱", Selected: false },
                        ]
                    },
                    {
                        name: "场景", include: [
                            { name: "户外音箱", Selected: false },
                            { name: "舞台音箱", Selected: false },
                            { name: "家庭音箱", Selected: false },
                            { name: "车载音箱", Selected: false },
                        ]
                    },
                    {
                        name: "音源", include: [
                            { name: "有源音箱", Selected: false },
                            { name: "无源音箱", Selected: false },
                        ]
                    },
                    {
                        name: "连接方式", include: [
                            { name: "有线音箱", Selected: false },
                            { name: "无线音箱", Selected: false },
                            { name: "蓝牙音箱", Selected: false },
                            { name: "WIFI音箱", Selected: false },
                        ]
                    }
                ]
            },
            {
                id: "5", name: "功放", Selected: false, include: [
                    {
                        name: "类型", include: [
                            { name: "前级功放", Selected: false },
                            { name: "后级功放", Selected: false },
                            { name: "综合功放", Selected: false },
                            { name: "台式耳放", Selected: false },
                            { name: "便携耳放", Selected: false },
                            { name: "演出功放", Selected: false },
                        ]
                    },
                    {
                        name: "元件类型", include: [
                            { name: "胆机（电子管）", Selected: false },
                            { name: "晶体管", Selected: false },
                        ]
                    }
                ]
            },
            {
                id: "6", name: "播放机", Selected: false, include: [
                    {
                        name: "类型", include: [
                            { name: "黑胶播放机", Selected: false },
                            { name: "CD播放机", Selected: false },
                            { name: "网络播放机", Selected: false },
                            { name: "蓝光播放机", Selected: false },
                        ]
                    }
                ]
            },
            {
                id: "7", name: "DAC", Selected: false, include: [
                    {
                        name: "类型", include: [
                            { name: "解码器", Selected: false },
                        ]
                    },
                    {
                        name: "场景", include: [
                            { name: "桌面DAC", Selected: false },
                            { name: "便携DAC", Selected: false },
                        ]
                    }
                ]
            },
            {
                id: "8", name: "配件", Selected: false, include: [
                    {
                        name: "线材", include: [
                            { name: "电源线", Selected: false },
                            { name: "网线", Selected: false },
                            { name: "音频线", Selected: false },
                        ]
                    },
                    {
                        name: "其它", include: [
                            { name: "脚垫", Selected: false },
                            { name: "音响架", Selected: false },
                            { name: "插头", Selected: false },
                        ]
                    }
                ]
            },
            {
                id: "9", name: "音源净化", Selected: false, include: [
                    {
                        name: "类型", include: [
                            { name: "音源净化器", Selected: false },
                            { name: "电源净化器", Selected: false },
                            { name: "声音净化器", Selected: false },
                            { name: "网络净化器", Selected: false },
                        ]
                    }
                ]
            },
        ],
        searchList: [],
        searchCount: 0
    }

    async componentDidMount() {
        // alert(localStorage.getItem("searchList"));
        //存储的选择项
        let tempSearchData = [];
        if (localStorage.getItem("searchList") != null) {
            tempSearchData = JSON.parse(localStorage.getItem("searchList"));
            this.setState({ searchList: tempSearchData });
        }

        //类别标签
        this.state.label.forEach(item => {
            item.include.forEach(item1 => {
                item1.include.forEach(item2 => {
                    let tempItem = tempSearchData.filter(item0 => item0 == item2.name);
                    if (tempItem.length > 0) {
                        item2.Selected = true;
                    }
                })
            })
        })

        //数据列表
        await this.getCountry();
        await this.getBrandList();

        //统计查询结果
        await this.getSearchCount();
    }

    //获取国家列表
    getCountry = async () => {
        const res = await httpAxios(api.getCountry);
        if (res.Code == 200) {
            //选中状态
            let tempData = [];
            res.Data.forEach(element => {
                let tempItem = this.state.searchList.filter(item => item == element);
                if (tempItem.length > 0) {
                    tempData.push({ name: element, Selected: true });
                }
                else {
                    tempData.push({ name: element, Selected: false });
                }

            });

            this.setState({ country: tempData });
        }
    }

    //选择国家列表
    setCountry = (name, select) => {
        this.state.country.forEach(element => {
            if (element.name == name) {
                element.Selected = !select;
            }
        });

        this.setState({ country: this.state.country });

        //标签控制
        if (select) {
            this.removeOneLabel(name);
        }
        else {
            this.addOneLabel(name);
        }

        //统计查询结果
        this.getSearchCount();
    }

    //获取品牌列表
    getBrandList = async () => {
        const res = await httpAxios(api.getBrandList);
        if (res.Code == 200) {
            //选中状态
            res.Data.forEach(element => {
                element.include.forEach(element1 => {
                    let tempItem = this.state.searchList.filter(item => item == element1);
                    if (tempItem.length > 0) {
                        element1.Selected = true;
                    }
                    else {
                        element1.Selected = false;
                    }
                });
            });

            this.setState({ brand: res.Data });
        }
    }

    //选择品牌列表
    setBrandList = (name, select) => {
        this.state.brand.forEach(item => {
            item.include.forEach(item1 => {
                if (item1.BrandName == name) {
                    item1.Selected = !select;
                }
            });
        });

        this.setState({ brand: this.state.brand });

        //标签控制
        if (select) {
            this.removeOneLabel(name);
        }
        else {
            this.addOneLabel(name);
        }

        //统计查询结果
        this.getSearchCount();
    }

    //选择类别标签
    setLabel = (name, select) => {
        this.state.label.forEach(item => {
            item.include.forEach(item1 => {
                item1.include.forEach(item2 => {
                    if (item2.name == name) {
                        item2.Selected = !select;
                    }
                })
            })
        })

        this.setState({ label: this.state.label });

        //标签控制
        if (select) {
            this.removeOneLabel(name);
        }
        else {
            this.addOneLabel(name);
        }

        //统计查询结果
        this.getSearchCount();
    }

    //设置全部标签
    setLabelAll = () => {
        let name = [];
        let name_ = []
        this.state.label.forEach(item => {
            if (item.id == this.state.moreTab) {
                let select = item.Selected;

                item.include.forEach(item1 => {
                    item1.include.forEach(item2 => {
                        item2.Selected = !select;

                        //标签控制
                        if (select == true) {
                            name_.push(item2.name);
                        }
                        else {
                            name.push(item2.name);
                        }
                    })
                })

                item.Selected = !item.Selected;
            }
        })

        this.setState({ label: this.state.label });

        if (name.length > 0) {
            let tempData = [...name, ...this.state.searchList];

            this.setState({ searchList: tempData });
        }
        else {
            //移除标签
            let newName = [];

            this.state.searchList.forEach(item => {
                let hasName = false;
                name_.forEach(item1 => {
                    if (item === item1) {
                        hasName = true;
                    }
                });

                if (!hasName) {
                    newName.push(item);
                }
            });

            this.setState({ searchList: newName });
        }

        //统计查询结果
        this.getSearchCount();
    }

    //清除数据
    clearAll = () => {
        this.setState({ searchList: [] });

        //国家
        this.state.country.forEach(element => {
            element.Selected = false;
        });

        this.setState({ country: this.state.country });

        //品牌
        this.state.brand.forEach(item => {
            item.include.forEach(item1 => {
                item1.Selected = false;
            });
        });

        this.setState({ brand: this.state.brand });

        //标签
        this.state.label.forEach(item => {
            item.include.forEach(item1 => {
                item1.include.forEach(item2 => {
                    item2.Selected = false;
                })
            })
        })

        this.setState({ label: this.state.label });

        //统计查询结果
        this.getSearchCount();
    }

    //删除单个标签数据
    removeOneLabel = (name) => {
        let tempData = [];
        this.state.searchList.forEach(item => {
            if (item != name) {
                tempData.push(item);
            }
        });

        this.setState({ searchList: tempData });
    }

    //增加单标签数据
    addOneLabel = (name) => {
        let tempData = [name, ...this.state.searchList];

        this.setState({ searchList: tempData });
    }

    //删除已选择标签
    removeOneSelectLabel = (name) => {
        //国家
        this.setCountry(name, true);
        //品牌
        this.setBrandList(name, true);
        //标签
        this.setLabel(name, true);

        //统计查询结果
        this.getSearchCount();
    }

    //获取查询统计
    getSearchCount = async () => {
        let country = [];
        this.state.country.forEach(item => {
            if (item.Selected) {
                country.push(item.name);
            }
        });

        let brand = [];
        this.state.brand.forEach(item => {
            item.include.forEach(item1 => {
                if (item1.Selected) {
                    brand.push(item1.BrandName);
                }
            });
        });

        let label = [];
        this.state.label.forEach(item => {
            item.include.forEach(item1 => {
                item1.include.forEach(item2 => {
                    if (item2.Selected) {
                        label.push(item2.name);
                    }
                })
            })
        })

        let params = {
            country: country.length == 0 ? "-1" : "'" + country.join("','") + "'",
            brand: brand.length == 0 ? "-1" : "'" + brand.join("','") + "'",
            label: label.length == 0 ? "-1" : "'" + label.join("','") + "'",
        };

        sessionStorage.setItem("search", JSON.stringify(params));

        const res = await httpAxios(api.getSearchCount, params);
        if (res.Code == 200) {
            this.setState({ searchCount: res.Data });
        }
    }

    render() {
        const {
            moreTab,
            country,
            brand,
            label,
            searchList,
            searchCount
        } = this.state;

        return (
            <MoreFilterWrapper>
                <div style={{ position: 'fixed', top: 0, paddingTop: '0.45rem', backgroundColor: '#fff', minWidth: '100%', zIndex: '10' }}>
                    <FilterContentDeletions onClick={() => this.clearAll()}><span>清除</span><i className='iconfont'>&#xe748;</i></FilterContentDeletions>
                    <FilterContent>
                        {
                            searchList.map(item => (
                                <FilterContentlist key={'s' + item}>{item}<i className='iconfont' onClick={() => { this.removeOneSelectLabel(item) }}>&#xe74c;</i></FilterContentlist>
                            ))
                        }
                        <div style={{ width: '5rem', flexShrink: '0' }}></div>{/* 作用是调整最后的标签不被清除按钮遮住 */}
                    </FilterContent>
                </div>

                <MoreFilterTab>
                    <div className={moreTab === '1' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '1' }) }}>国&nbsp;&nbsp;&nbsp;家</div>
                    <div className={moreTab === '2' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '2' }) }}>品&nbsp;&nbsp;&nbsp;牌</div>
                    <div className={moreTab === '3' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '3' }) }}>耳&nbsp;&nbsp;&nbsp;机</div>
                    <div className={moreTab === '4' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '4' }) }}>音&nbsp;&nbsp;&nbsp;箱</div>
                    <div className={moreTab === '5' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '5' }) }}>功&nbsp;&nbsp;&nbsp;放</div>
                    <div className={moreTab === '6' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '6' }) }}>播放机</div>
                    <div className={moreTab === '7' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '7' }) }}>DAC</div>
                    <div className={moreTab === '9' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '9' }) }}>音源净化</div>
                    <div className={moreTab === '8' ? 'select' : ''} onClick={() => { this.setState({ moreTab: '8' }) }}>配&nbsp;&nbsp;&nbsp;件</div>
                </MoreFilterTab>
                <MoreFilterRight className={moreTab === '1' ? '' : 'unchecked'}>
                    国家
                    <div></div>
                    {
                        country.map((item) => (
                            <span className={item.Selected == true ? 'select' : ''} onClick={() => { this.setCountry(item.name, item.Selected) }} key={item.name}>{item.name}</span>
                        ))

                    }
                    {/* <span>中国</span><span>日本</span><span>奥地利</span><span>德国</span><span>美国</span>
                    <span className='select'>韩国</span><span>丹麦</span><span>英国</span> */}
                </MoreFilterRight>
                <MoreFilterRightBrands className={moreTab === '2' ? '' : 'unchecked'}>
                    <span>品牌</span>
                    {
                        brand.map(item => (
                            <>
                                <div className='letter'>{item.letter}</div>
                                {item.include.map(item1 => (
                                    <div className='brands' key={item1.ID}>
                                        <div className='brandsimg'>
                                            <img src={item1.BrandLogo} alt="" />
                                        </div>
                                        <div className='brandsname'>{item1.BrandName}</div>
                                        <div className='brandscheckbox'><Checkbox checked={item1.Selected} onClick={() => this.setBrandList(item1.BrandName, item1.Selected)} /></div>
                                    </div>
                                ))}
                            </>
                        ))
                    }
                </MoreFilterRightBrands>
                {

                    label.map(item => (
                        <MoreFilterRight className={moreTab === item.id ? '' : 'unchecked'}>
                            {item.name}&nbsp;&nbsp;&nbsp;&nbsp;<span className={item.Selected == true ? 'select' : ''} onClick={() => this.setLabelAll()}>全部{item.name}</span>
                            {
                                item.include.map(item1 => (
                                    <>
                                        <div>{item1.name}</div>
                                        {
                                            item1.include.map(item2 => (
                                                <span className={item2.Selected == true ? 'select' : ''} onClick={() => { this.setLabel(item2.name, item2.Selected) }} key={item2.name}>{item2.name}</span>
                                            ))
                                        }
                                    </>
                                ))
                            }
                        </MoreFilterRight>
                    )
                    )
                }
                <MoreButtonBG>
                    <MoreButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "search", title: '更多筛选条件', weburl: "/Brands/FilterResultList", search: sessionStorage.getItem("search") })) }}>
                        共{searchCount}个产品
                    </MoreButton>
                </MoreButtonBG>
            </MoreFilterWrapper>
        )
    }
}

export default MoreFilter