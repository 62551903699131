/**
 * created by ChenyaoSun on 2020/11/17
 */
import React, {
    Component
} from 'react';
import {
    Link
} from 'react-router-dom';
import{
    Code,
    Info,
    LoginWrapper,
    LoginButton,
    LoginButtons,
    BoxWrapper,
    Title,
    NavBar,
    ReturnButton,
    FormItem,
    FormItemVerification,
    FinishIcon,
    FinishTip,
}from '../LoginStyle';
import axios from 'axios';
import api from "../../../http/api";
import httpAxios from "../../../http/httpAxios";
import { Toast } from 'antd-mobile-v2';
import 'antd-mobile-v2/dist/antd-mobile.css';
import creatHistory from 'history/createHashHistory';  //返回上一页这段代码
const history = creatHistory();//返回上一页这段代码

const finishIcon = require('../../../images/finish.svg');
const returnButton = require('../../../images/return_button.svg');

class SendCode extends Component {

    state = {
        time: '',
    }

    trick = () => {
        let {
            time
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }

    async componentDidMount() {

    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    sendCode = async () => {
        if (!this.props.params.account) {
            Toast.info('请输入手机号或邮箱')
            return
        }
        let postData = {
            method: 'post',
            url: this.props.url.url + '?account=' + this.props.params.account,
            withCredentials: true
        }
        axios(postData).then(res => {
            if (res && res.data && res.data.Code === 200) {
                this.props.cb && this.props.cb(res.data.Data)
                clearTimeout(this.timeId);
                this.setState({
                    time: 59
                })
                this.timeId = setInterval(this.trick, 1000);
            } else {
                Toast.info('请输入正确的手机号或邮箱')
                // Toast.info(res.data.Message)
            }
        }).catch(e => {
            Toast.info(JSON.stringify(e))
        })
    }

    render() {
        return (
            <span>
                {
                    this.state.time > 0 ? <Code>{`${this.state.time}s 后重发`}</Code> : <Code onClick={this.sendCode}>发送验证码</Code>
                }
            </span>
        )
    }
}
export default class UserForget extends Component {
    state = {
        step: 1,
        phoneValue: '',//手机input框中输入的内容
        PasswordValue: '',
        NewPasswordValue: '',
        CheckNumValue: '',//验证码input框中输入的内容
        NewPassword: '',
        phoneStatus: '',
        NewPasswordStatus: '',
        CheckNumStatus: '',
        UserPID: '',
        UserID: '',
        UserNickName: '',
        phoneValid:false,
    }
    async componentDidMount() {
        const res = await httpAxios(api.getAdvertising, {
            position: 1
        })
        if (res) {
            this.setState({
                bg: res.Data[0] && res.Data[0].CoverImgBig
            })
        }
    }
    checkCode = async () => {
        const {
            CheckNumValue,
            phoneValue
        } = this.state;
        if (!CheckNumValue || !phoneValue){
            Toast.info('请先将信息填写完整')
            return
        };
        let postData = {
            method: 'post',
            url: api.userCheckCode.url + '?code=' + CheckNumValue,
            withCredentials: true
        }
        axios(postData).then(res => {
            if (res && res.data && res.data.Code === 200) {
                this.setState({
                    step: 2
                })
            } else {
                // Toast.info('1')
                // Toast.info(res.data.Message)
            }
        }).catch(e => {
            Toast.info('验证码不正确')
            // Toast.info(JSON.stringify(e))
        })
    }

    finishUpdate = () => {
        // setTimeout(() => {
        this.link.click()
        // }, 1000)
    }
    saveInfo = info => {
        this.setState({
            ...this.setState,
            ...info
        })
    }

    //离开input时验证手机号和邮箱
    checkPhone = (e) => {
        let value = e.target.value;
        value = value.trim();
        let myregPhone = /^1[3456789]\d{9}$/;
        let myregEmail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!myregPhone.test(value) && !myregEmail.test(value)) {
            // Toast.info('请输入正确的手机号或邮箱');
            this.setState({
                phoneValid: false,
                phoneStatus: 'error'
            })
        } else {
            this.setState({
                phoneValid: true,
                phoneValue: value,
                phoneStatus: 'success'
            })
        }

    }

    validateInput = (type, e) => {
        const value = (e && e.target && e.target.value) || '';
        if (value) {
            this.setState({
                [`${type}Status`]: '',
                [`${type}Value`]: value
            })
        } else {
            this.setState({
                [`${type}Status`]: 'error'
            })
        }
    }

    checkPassword = (e) => {
        let value = e.target.value;
        value = value.trim();
        let myreg = /^(?:(?=.*[a-zA-Z])(?=.*[0-9])).{6,20}$/
        if (!myreg.test(value)) {
            Toast.info('请输入6~20位密码，必须包含字母、数字');
            this.setState({
                PasswordStatus: 'error',
            })
            return
        }
        this.setState({
            PasswordStatus: 'success',
            PasswordValue: value
        })
    }

    confirmPassword = (e) => {
        let value = e.target.value;
        value = value.trim();
        if (!value || value !== this.state.PasswordValue) {
            Toast.info('两次密码输入不一致');
            this.setState({
                NewPasswordStatus: 'error',
            })
            return
        }
        this.setState({
            NewPasswordStatus: 'success',
            NewPasswordValue: value,
            NewPassword: '',
        })
    }


    handleChange = (e) => {
        this.setState({
            phoneValue: e.target.value
        })
    }

    updatePwd = async () => {
        const {
            PasswordValue,
            UserID
        } = this.state;
        if (!PasswordValue) return;
        const res = await httpAxios(api.userForgetPassword, {
            Password: PasswordValue,
            UserPID: UserID
        })
        if (res) {
            this.setState({
                step: 3
            })
        }
    }

    goBackPage = () => {
        history.goBack();  //返回上一页这段代码

    }
    returnButton1 = () => {
        this.setState({
            step: 1
        })
    }
    returnButton2 = () => {
        this.setState({
            step: 2
        })
    }

    render() {
        const {
            step,
            NewPassword,
            phoneStatus,
            CheckNumStatus,
            UserNickName,
            phoneValue,
            PasswordValue,
            NewPasswordValue,
            CheckNumValue,
            NewPasswordStatus
        } = this.state;
        return (
            <LoginWrapper>
                {
                    step === 1 && <>
                        <NavBar>
                            <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                        </NavBar>
                        <Title><div>找回密码</div><span>请按以下格式输入您的信息</span></Title>
                        <BoxWrapper>
                            <span>手机号或邮箱</span>
                            <FormItem>
                                <input className={phoneStatus} type="text" placeholder='注册时预留的邮箱/手机号' value={phoneValue} onChange={this.handleChange} onBlur={this.checkPhone} />
                            </FormItem>
                            <span>验证码</span>
                            <FormItemVerification>
                                <input className={CheckNumStatus} type="text" value={CheckNumValue} onChange={(e) => { this.setState({ CheckNumValue: e.target.value.trim() }) }} placeholder='请输入验证码' onBlur={this.validateInput.bind(this, 'CheckNum')} />
                                <SendCode cb={this.saveInfo} url={api.userForgetPassSendCode} params={{ account: phoneValue }} />
                            </FormItemVerification>
                            <LoginButton onClick={this.checkCode}>下一步</LoginButton>
                        </BoxWrapper>
                    </>
                }
                {
                    step === 2 && <>
                        <NavBar>
                            <ReturnButton onClick={this.returnButton1}><img src={returnButton} alt="返回" /></ReturnButton>
                        </NavBar>
                        <Title><div>重设密码</div><span>您正在修改<p className='text-overflow-1' style={{ color: '#00bfff' }}>{UserNickName}</p>密码</span></Title>
                        <BoxWrapper>
                            <span>密码</span>
                            <FormItem>
                                <input type="text" value={PasswordValue} onChange={(e) => { this.setState({ PasswordValue: e.target.value.trim() }) }} placeholder='请输入新密码' onBlur={this.checkPassword} />
                            </FormItem>
                            <span>确认密码</span>
                            <FormItem>
                                <input type="text" value={NewPasswordValue} onChange={(e) => { this.setState({ NewPasswordValue: e.target.value.trim() }) }} placeholder='请确认新密码' onBlur={this.confirmPassword} />
                                <span className={NewPasswordStatus}>{NewPassword}</span>
                            </FormItem>
                            <LoginButton onClick={this.updatePwd}>下一步</LoginButton>
                        </BoxWrapper>
                    </>
                }
                {
                    step === 3 && <>
                        <NavBar>
                            <ReturnButton onClick={this.returnButton2}><img src={returnButton} alt="返回" /></ReturnButton>
                        </NavBar>
                        <FinishIcon><img src={finishIcon} alt="成功" /></FinishIcon>
                        <FinishTip>密码重设成功</FinishTip>
                        <Info>请谨慎保管您的密码, 不要再忘记哦</Info>
                        <Link ref={r => this.link = r} to='/login' />
                        <LoginButtons onClick={this.finishUpdate}>完成</LoginButtons>
                    </>
                }
            </LoginWrapper>
        )
    }
}