/**
 * created by zhangling on 2019/12/26
 */
import React, {
    Component
} from 'react';
import {
    Box,
    Shading,
    Comment,
    Submit,
    Item,
    ItemSelected,
    Inform
} from "./style"

import httpAxios from "../../http/httpAxios"
import api from "../../http/api"
import {
    CURRENT_USER_ID
} from "../../const/const"
import {
    message
} from 'antd'

class ReportBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            reportContent: '',
            reportList: [{
                name: '侮辱性词汇',
                selected: false
            }, {
                name: '带有淫秽内容',
                selected: false
            }, {
                name: '敏感的政治词汇',
                selected: false
            }, {
                name: '恶意煽动群众',
                selected: false
            }, {
                name: '诋毁其它品牌',
                selected: false
            }, {
                name: '传播有损国家荣誉的内容',
                selected: false
            }, {
                name: '言语辱骂',
                selected: false
            }, {
                name: '恶意欺骗',
                selected: false
            }, {
                name: '侵权',
                selected: false
            }]
        }
    }

    submitReport = async () => {
        const {
            reportContent,
            reportList
        } = this.state;
        const {
            targetId,
            userId,
            typeId
        } = this.props;
        const reason = reportList.filter(item => (item.selected)).map(i => (i.name)).join(',');
        const res = await httpAxios(api.reportBad, {
            "NewsID": targetId,
            "userID": userId,
            "ReasonLabel": reason,
            "Remark": reportContent,
            "TypeID": typeId
        })
        if (res) {
            this.closeReportModal()
        }
    }

    handleChange = (e) => {
        this.setState({
            reportContent: e.target.value
        })
    }

    clickItem = (index) => {
        let list = [...this.state.reportList];
        list[index].selected = !list[index].selected;
        this.setState({
            reportList: list
        })
    }

    openReportModal = () => {
        const {
            userId,
        } = this.props;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login"}));
            //message.info('需要登录后 才能进行举报操作')
            return
        }
        this.setState({
            visible: true
        })
    }

    closeReportModal = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        return (
            <span>
                <Inform onClick={this.openReportModal}><i className="iconfont">&#xe76e;</i>举报文章</Inform>
                <Shading onClick={this.closeReportModal} style={{visibility: this.state.visible ? 'visible' : 'hidden', transition: "visibility, .3s"}} />
                <Box style={{visibility: this.state.visible ? 'visible' : 'hidden', transition: "visibility, .3s"}}>
                    <i className="iconfont" onClick={this.closeReportModal}>&#xe74c;</i>
                    <h5>举报 <span>{this.props.reportAuthor}</span> 的文章</h5>
                    <h6><span>{this.props.reportAuthor}</span>：{this.props.reportTitle}</h6>
                    <>
                        {
                            this.state.reportList.map((item, index) => {
                                if (item.selected) {
                                    return <ItemSelected key={`item-${index}`} onClick={this.clickItem.bind(this, index)}>{item.name}</ItemSelected>
                                } else {
                                    return <Item key={`item-${index}`} onClick={this.clickItem.bind(this, index)}>{item.name}</Item>
                                }
                            })
                        }
                    </>
                    <Comment onChange={this.handleChange}/>
                    <Submit className='cursor' onClick={this.submitReport}>提交</Submit>
                </Box>
            </span>

        )
    }
}

export default ReportBox;