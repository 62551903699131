import React, { Component } from 'react';
// import { country } from './data';
// import './style';
import {
  CountryList,
  CountryBorder,
  NavBar,
  BrandsListImg,
  BrandsListEn,
  ReturnButton,
  Search,
  MoreText
} from './style';
import {
  ClearFloat,
} from '../../common/style';

import httpAxios from '../../http/httpAxios';
import api from '../../http/api';

const returnButton = require('../../images/return_button.svg')

// 头部搜索栏
class AllStarList extends Component {
  constructor() {
    super();
    this.state = {
      isTouching: false,
      lastChar: 'A',
      country: [],
      charStr: []
    }

    // this.isTouching = false;
    this.boxClientTop = 148;
    // this.lastChar = 'A';
    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
    this.getChar = this.getChar.bind(this);
    this.gotoChar = this.gotoChar.bind(this);
  }

  touchStart(e) {
    // e.preventDefault();
    // this.isTouching = true;
    this.setState({ isTouching: true });
    const char = this.getChar(e.touches[0].clientY);
    this.gotoChar(char);
  }
  touchMove(e) {
    e.preventDefault();
    const char = this.getChar(e.touches[0].clientY);
    this.gotoChar(char);
  }
  touchEnd(e) {
    e.preventDefault();
    this.setState({ isTouching: false });
  }
  getChar(clientY) {
    const charHeight = this.refs.charBar.offsetHeight / this.state.charStr.length;
    const index = Math.floor((clientY - this.boxClientTop) / charHeight);
    return this.state.charStr[index];
  }
  gotoChar(char) {
    if (char === this.lastChar) {
      return false;
    }
    this.setState({ lastChar: char });
    // this.lastChar = char;
    if (char === '*') {
      this.refs.countryList.scrollTop = 0;
    } else if (char === '#') {
      this.refs.countryList.scrollTop = this.refs.countryList.scrollHeight;
    }
    const target = document.querySelector('[data-en="' + char + '"]');

    if (target) {
      // target.scrollIntoView();
      window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
    }
  }
  componentWillMount() {
    // alert(JSON.stringify(this.country));    
  }

  componentDidMount() {
    this.getBrands();

    var that = this;
    window.search = async (data) => {
      await that.getBrands("0", data.sex, data.country, data.profession);
    }
  }
  goBackPage = () => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
  }



  getBrands = async (search = "0", sex = "0", country = "0", profession = "0") => {
    const res = await httpAxios(api.getArtistInfoListSearch, { search: search, sex: sex, country: country, profession: profession });
    if (res) {
      this.setState(
        {
          charStr: res.Data.Letters,
          country: res.Data.Data
        }
      );
    }
  }
  render() {

    const {
      country,
      charStr,
    } = this.state;

    return (
      <>
        <CountryList>
          <NavBar>
            <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
            <span>全部艺人</span>
            <MoreText onClick={() => {
              //记录查询条件
              localStorage.removeItem("searchList");
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: "modal", title: '更多筛选条件', weburl: "/star/StarMore" }));
            }}>更多筛选<i className='iconfont'>&#xe744;</i></MoreText>
            <ClearFloat />
            <Search style={{ width: '92%' }}><i className="iconfont">&#xe7b4;</i><input placeholder="输入搜索的关键词" onChange={(e) => {
              if (e.target.value == "") {
                this.getBrands();
              }
              else {
                this.getBrands(e.target.value)
              }
            }}></input></Search>
          </NavBar>
          <div style={{ height: '9.25rem' }}></div>

          {/* <Detial>这是detail page</Detial> */}
          <CountryBorder ref="countryList" >
            {
              country.map((item, index) => {
                return (
                  <div key={index}>
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: '/starDetail/' + item.ID })); }}>
                      {item.First && <div data-en={item.Letter}></div>}
                      <div
                        className={item.First === true ? "title" : 'brandsList'}
                      >
                        {!item.First && <BrandsListImg><img src={item.BrandLogo} alt="" /></BrandsListImg>}
                        <BrandsListEn>{item.BrandName}</BrandsListEn>
                        {/* {!item.First && <BrandsListCn>{item.BrandCountry}</BrandsListCn>} */}
                      </div>
                    </a>
                  </div>
                )
              })
            }
            <div className="char-list-border">
              <ul
                className="char-list"
                ref="charBar"
                onTouchStart={this.touchStart}
                onTouchMove={this.touchMove}
                onTouchEnd={this.touchEnd}
              >
                {
                  charStr.map((char, index) => {
                    return (
                      <li className="char-item" key={index}>{char}</li>
                    )
                  })
                }
              </ul>
            </div>
            {this.state.isTouching && <div className="char-tip">{this.state.lastChar}</div>}
          </CountryBorder>
        </CountryList>
      </>
    )
  }
}



export default AllStarList;
