/**
 * created by zhangling on 2019/12/19
 */
import moment from 'moment';
import httpAxiosOss from "../http/httpAxiosOss";
import httpAxios from "../http/httpAxios";
import {
    message
} from 'antd';
//import UUID from 'uuid/v1';

export function http(options) {
    let url = options.url
    const method = options.method.toLocaleLowerCase() || 'get'
    const async = options.async !== false // default is true
    const data = options.data
    const xhr = new XMLHttpRequest()

    if (options.timeout && options.timeout > 0) {
        xhr.timeout = options.timeout
    }
    return new Promise((resolve, reject) => {
        xhr.ontimeout = () => reject && reject('请求超时')
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4) {
                if (xhr.status >= 200 && xhr.status < 300 || xhr.status == 304) {
                    resolve && resolve(xhr.responseText)
                } else {
                    reject && reject()
                }
            }
        }
        xhr.onerror = err => reject && reject(err)

        let paramArr = []
        let encodeData
        if (data instanceof Object) {
            for (let key in data) {
                // 参数拼接需要通过 encodeURIComponent 进行编码
                paramArr.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            }
            encodeData = paramArr.join('&')
        }

        if (method === 'get') {
            // 检测 url 中是否已存在 ? 及其位置
            const index = url.indexOf('?')
            if (index === -1) url += '?'
            else if (index !== url.length - 1) url += '&'
            // 拼接 url
            url += encodeData
        }

        xhr.open(method, url, async)
        if (method === 'get') xhr.send(null)
        else {
            // post 方式需要设置请求头
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
            xhr.send(encodeData)
        }
    })
    // ajax({
    //     url: 'your request url',
    //     method: 'get',
    //     async: true,
    //     timeout: 1000,
    //     data: {
    //         test: 1,
    //         aaa: 2
    //     }
    // }).then(
    //     res => console.log('请求成功: ' + res),
    //     err => console.log('请求失败: ' + err)
    // )
}

export function createAsyncTypes(type) {
    return [`${type}_STARTED`, `${type}_SUCCESSED`, `${type}_FAILED`]
}

export const convertTime = (time, type) => {
    if (!time) return ''
    if (type && type === 'day') {
        return moment(time).format('YYYY/MM/DD')
    }
    return moment(time).format('YYYY/MM/DD HH:mm')
}
export const chinaTime = (time, type) => {
    if (!time) return ''
    if (type && type === 'day') {
        return moment(time).format('YYYY年MM月DD日')
    }
    if (type && type === 'month') {
        return moment(time).format('YYYY年MM月')
    }
    return moment(time).format('YYYY年MM月DD日 HH时mm分')
}

export const startMinsTime = (dateTimeStamp) => {
    if (!dateTimeStamp) return ''
    dateTimeStamp = moment(dateTimeStamp).format('YYYY/MM/DD HH:mm:ss'); //如果Safari报错，YYYY/MM/DD是Safari浏览器兼容写法
    let date = new Date(dateTimeStamp)
    let dateTime = date.getTime()

    var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
    var hour = minute * 60;
    var day = hour * 24;
    var week = day * 7;
    var halfamonth = day * 15;
    var month = day * 30;
    var year = day * 365;
    var now = new Date().getTime(); //获取当前时间毫秒
    // console.log(now);
    var diffValue = now - dateTime; //时间差

    if (diffValue < 0) {
        return;
    }
    var minC = diffValue / minute; //计算时间差的分，时，天，周，月
    var hourC = diffValue / hour;
    var dayC = diffValue / day;
    var weekC = diffValue / week;
    var monthC = diffValue / month;
    var yearC = diffValue / year;
    var result;
    // console.log(diffValue)
    // console.log(yearC)
    if (yearC >= 1) {
        result = " " + parseInt(yearC) + "年前";
    } else if (monthC >= 1 && monthC <= 12) {
        result = " " + parseInt(monthC) + "月前";
    } else if (weekC >= 1 && weekC <= 5) {
        result = " " + parseInt(weekC) + "周前";
    } else if (dayC >= 1 && dayC <= 7) {
        result = " " + parseInt(dayC) + "天前";
    } else if (hourC >= 1 && hourC <= 24) {
        result = " " + parseInt(hourC) + "小时前";
    } else if (minC >= 1 && minC <= 60) {
        result = " " + parseInt(minC) + "分钟前";
    } else if (diffValue >= 0 && diffValue <= minute) {
        result = "刚刚";
    } else {
        var datetime = new Date();
        datetime.setTime(dateTimeStamp);
        var Nyear = datetime.getFullYear();
        var Nmonth =
            datetime.getMonth() + 1 < 10 ?
                "0" + (datetime.getMonth() + 1) :
                datetime.getMonth() + 1;
        var Ndate =
            datetime.getDate() < 10 ?
                "0" + datetime.getDate() :
                datetime.getDate();
        var Nhour =
            datetime.getHours() < 10 ?
                "0" + datetime.getHours() :
                datetime.getHours();
        var Nminute =
            datetime.getMinutes() < 10 ?
                "0" + datetime.getMinutes() :
                datetime.getMinutes();
        var Nsecond =
            datetime.getSeconds() < 10 ?
                "0" + datetime.getSeconds() :
                datetime.getSeconds();
        result = Nyear + "/" + Nmonth + "/" + Ndate;
    }
    return result;
}
export const endMinsTime = (dateTimeStamp) => {
    if (!dateTimeStamp) return ''
    dateTimeStamp = moment(dateTimeStamp).format('YYYY/MM/DD HH:mm:ss'); //如果Safari报错，YYYY/MM/DD是Safari浏览器兼容写法
    let date = new Date(dateTimeStamp)
    let dateTime = date.getTime()

    var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
    var hour = minute * 60;
    var day = hour * 24;
    var week = day * 7;
    var halfamonth = day * 15;
    var month = day * 30;
    var year = day * 365;
    var now = new Date().getTime(); //获取当前时间毫秒
    // console.log('现在'+ now);
    // console.log('设定时间'+ dateTime);
    var diffValue = dateTime - now; //时间差

    // if (diffValue < 0) {
    //     return;
    // }
    var minC = diffValue / minute; //计算时间差的分，时，天，周，月
    var hourC = diffValue / hour;
    var dayC = diffValue / day;
    var weekC = diffValue / week;
    var monthC = diffValue / month;
    var yearC = diffValue / year;
    var result;
    // console.log(diffValue)
    // console.log(yearC)
    if (yearC >= 1) {
        result = " " + parseInt(yearC) + "年后";
    } else if (monthC >= 1 && monthC <= 12) {
        result = " " + parseInt(monthC) + "月后";
    } else if (weekC >= 1 && weekC <= 5) {
        result = " " + parseInt(weekC) + "周后";
    } else if (dayC >= 1 && dayC <= 7) {
        result = " " + parseInt(dayC) + "天后";
    } else if (hourC >= 1 && hourC <= 24) {
        result = " " + parseInt(hourC) + "小时后";
    } else if (minC >= 1 && minC <= 60) {
        result = " " + parseInt(minC) + "分钟后";
    } else if (diffValue <= minute) {
        result = "即将";
    } else {
        var datetime = new Date();
        datetime.setTime(dateTimeStamp);
        var Nyear = datetime.getFullYear();
        var Nmonth =
            datetime.getMonth() + 1 < 10 ?
                "0" + (datetime.getMonth() + 1) :
                datetime.getMonth() + 1;
        var Ndate =
            datetime.getDate() < 10 ?
                "0" + datetime.getDate() :
                datetime.getDate();
        var Nhour =
            datetime.getHours() < 10 ?
                "0" + datetime.getHours() :
                datetime.getHours();
        var Nminute =
            datetime.getMinutes() < 10 ?
                "0" + datetime.getMinutes() :
                datetime.getMinutes();
        var Nsecond =
            datetime.getSeconds() < 10 ?
                "0" + datetime.getSeconds() :
                datetime.getSeconds();
        result = Nyear + "/" + Nmonth + "/" + Ndate;
    }
    return result;
}

export const getBase64 = (file, limit) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (limit) {
                const size = getImgByteSize(reader.result, limit);
                if (!size) {
                    resolve('')
                } else {
                    resolve(reader.result);
                }
            } else {
                resolve(reader.result);
            }
        }
        reader.onerror = error => reject(error);
    });
}

export const getVideoBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = error => reject(error);
    });
}

export const getImgByteSize = (graphicContents, limit) => {
    let size;
    if (graphicContents) { // 获取base64图片byte大小
        const equalIndex = graphicContents.indexOf('='); // 获取=号下标
        if (equalIndex > 0) {
            const str = graphicContents.substring(0, equalIndex); // 去除=号
            const strLength = str.length;
            const fileLength = strLength - (strLength / 8) * 2; // 真实的图片byte大小
            size = Math.floor(fileLength); // 向下取整
        } else {
            const strLength = graphicContents.length;
            const fileLength = strLength - (strLength / 8) * 2;
            size = Math.floor(fileLength); // 向下取整
        }
    } else {
        size = null;
    }
    if (size / (1024 * 1024) < limit) {
        return size
    } else {
        return false
    }
}

export const compressIMG = (path, scaleW = 1, scaleH = 1) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = path;
        img.onload = function () {
            let that = this;
            let w = this.width,
                h, scale = scaleW / scaleH;
            h = (w / scale);
            let quality = 1; // 默认图片质量为0.7
            // 生成canvas
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            // 创建属性节点
            let anw = document.createAttribute('width');
            anw.nodeValue = w;
            let anh = document.createAttribute('height');
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            ctx.drawImage(that, 0, 0, w, h);
            let base64 = canvas.toDataURL('image/jpeg', quality);
            // 回调函数返回base64的值)
            resolve(base64)
        }
    })
}
export const compressIMGWithFile = (path, name, scaleW = 1, scaleH = 1) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = path;
        img.onload = function () {
            let that = this;
            let w = this.width,
                h, scale = scaleW / scaleH;
            h = (w / scale);
            let quality = 0.5; // 默认图片质量为0.7
            // 生成canvas
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            // 创建属性节点
            let anw = document.createAttribute('width');
            anw.nodeValue = w;
            let anh = document.createAttribute('height');
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            ctx.drawImage(that, 0, 0, w, h);
            let base64 = canvas.toDataURL('image/jpeg', quality);
            // 回调函数返回base64的值
            const file2 = dataURLtoFile(base64, name)
            resolve(file2)
        }
    })
}
export const compressCover = (path, name, quality = 0.9) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = path;
        // console.log(img)
        img.onload = function () {
            let that = this;
            // console.log(this)
            let w = this.width,
                h = this.height;
            // quality = 0.5; // 默认图片质量为0.7
            // 生成canvas
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            // 创建属性节点
            let anw = document.createAttribute('width');
            anw.nodeValue = w;
            let anh = document.createAttribute('height');
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            ctx.drawImage(that, 0, 0, w, h);
            let base64 = canvas.toDataURL('image/jpeg', quality);
            // 回调函数返回base64的值
            const file2 = dataURLtoFile(base64, name)
            resolve(file2)
        }
    })
}
export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
        type: mime
    });
}


export const getVideoDuration = (VideoSrc) => {
    return new Promise((resolve, reject) => {
        if (!VideoSrc) resolve('无视频地址')
        let audioElement = new Audio(VideoSrc);
        let duration;
        audioElement.addEventListener("loadedmetadata", function (_event) {
            duration = audioElement.duration;
            let min = parseInt(duration / 60);
            let sec = Math.ceil(duration % 60);
            duration = `${min}分${sec}秒`;
            resolve(duration)
        });
    });
}

export const checkVideoDurationAndSize = (video) => {
    return new Promise((resolve, reject) => {
        if (!video) resolve('视频不存在');
        let size = video.size;
        if (size > 50 * 1024 * 1024) {
            message.error('视频大小不能超过50MB');
            reject('视频大小不能超过50MB');
            return;
        }
        let videoSrc = URL.createObjectURL(video);
        let audioElement = new Audio(videoSrc);
        let duration;
        audioElement.addEventListener("loadedmetadata", function (_event) {
            duration = audioElement.duration;
            let min = parseInt(duration / 60);
            if (min > 3) {
                message.error('视频长度不能超过三分钟');
                reject('视频长度不能超过三分钟')
            }
            resolve(duration)
        });
    });
}


export const uploadImgWithName = (url, file, name, params = {}) => {
    return new Promise((resolve, reject) => {
        if (!url) resolve('无上传地址');
        httpAxios(url, params).then(res => {
            let formData = new FormData();
            formData.append("name", name)
            formData.append("OSSAccessKeyId", res.Data.AccessID);
            formData.append("signature", res.Data.Signature);
            formData.append("policy", res.Data.Policy);
            formData.append("key", res.Data.Dir + name);
            formData.append("success_action_status", '200');
            formData.append("file", file);
            httpAxiosOss({
                url: res.Data.OSSHost,
                method: 'post'
            }, formData).then(r => {
                resolve(res.Data.FinalUrl)
            }).catch(e => {
                reject(e)
            })
        }).catch(e => {
            reject(e)
        })
    });
}

export const uploadVideoWithName = (url, file, name, params = {}) => {
    return new Promise((resolve, reject) => {
        if (!url) resolve('无上传地址');
        httpAxios(url, params).then(res => {
            let formData = new FormData();
            formData.append("name", name)
            formData.append("OSSAccessKeyId", res.Data.AccessID);
            formData.append("signature", res.Data.Signature);
            formData.append("policy", res.Data.Policy);
            formData.append("key", res.Data.Dir + name);
            formData.append("success_action_status", '200');
            formData.append("file", file);
            httpAxiosOss({
                url: res.Data.OSSHost,
                method: 'post'
            }, formData).then(r => {
                resolve(res.Data.FinalUrl)
            }).catch(e => {
                reject(e)
            })
        }).catch(e => {
            reject(e)
        })
    });
}
export const hiddenStr = (str) => {
    return str
}
export const simpifyNumber = (str) => {
    return str
}
export const scienceNumber = (str) => {
    return str
}
export const replaceBlankAndLine = (str) => {
    if (!str) return str;
    str = str.replace(/\n|\r\n/g, '<br/>');
    str = str.replace(/\s/g, '&nbsp;');
    return str
}
export const filterHtml = str => {
    if (!str) return str;
    let dd = str.replace(/<[^>]+>/g, ""); //截取html标签
    let dds = dd.replace(/&nbsp;/ig, " "); //截取空格等特殊标签
    return dds
    // $(list[i]).text(dds.substring(0,200) + "...")//截取200个字符
}
export const filterVideoSrc = str => {
    if (!str) return
    let result = str.replace(/<video [^>]*src=['"]([^'"]+)[^>]*>/g, function (str, key) {
        return `<video controls='controls' controlslist='nodownload' src='${key.slice(0, key.indexOf('?') > -1 ? key.indexOf('?') : key.length)}'>`
    })
    return result
}

export const getLinkedId = url => {
    if (!url) return '';
    if (url.lastIndexOf('/') > -1) {
        let id = url.slice(url.lastIndexOf('/') + 1, url.length);
        if (id.length > 5) {
            return ''
        } else {
            return id
        }
        // if (typeof Number(id) === 'number') {
        //     return id
        // } else {
        //     return ''
        // }
    } else {
        return ''
    }
}


export const getQueryVariable = (variable) => {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}