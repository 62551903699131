/**
 * created by ChenyaoSun on 2022/03/18
 */
import React, {
    Component
} from 'react';
import {
    Code,
    LoginWrapper,
    LoginButton,
    BoxWrapper,
    Title,
    FormItem,
    FormItemVerification,
} from './style';
import axios from 'axios';
import api from "../../../../http/api";
import httpAxios2 from "../../../../http/httpAxios2";
import httpAxios from "../../../../http/httpAxios";
import { Modal, Toast } from 'antd-mobile-v2';
import 'antd-mobile-v2/dist/antd-mobile.css';

import {
    message,
} from 'antd';

const alert = Modal.alert;

class SendCode extends Component {

    state = {
        time: '',
        phoneHash: ''
    }

    trick = () => {
        let {
            time,
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }

    phoneHashData = (event) => {
        this.setState({
            phoneHash: event
        });
    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    sendCode = async () => {
        if (!this.props.phone) {
            message.info('请输入手机号')
            return
        }
        // try {
        const res = await httpAxios(this.props.url, {
            phone: this.props.phone
        })
        if (res) {
            clearTimeout(this.timeId);
            this.setState({
                time: 59,
                phoneHash: res.Data
            })
            this.timeId = setInterval(this.trick, 1000);
            this.phoneHashData(this.state.phoneHash);
        }
    }
    render() {
        return (
            <span>
                {
                    this.state.time > 0 ? <Code>{`${this.state.time}s 后重发`}</Code> : <Code onClick={this.sendCode}>发送验证码</Code>
                }
            </span>
        )
    }
}

export default class UserForget extends Component {
    state = {
        step: 1,
        phoneValue: '',//手机input框中输入的内容
        PasswordValue: '',
        NewPasswordValue: '',
        CheckNumValue: '',//验证码input框中输入的内容
        NewPassword: '',
        phoneStatus: '',
        NewPasswordStatus: '',
        CheckNumStatus: '',
        UserPID: '',
        UserID: '',
        UserNickName: '',
        phoneValid: false,
    }
    async componentDidMount() {
        const res = await httpAxios(api.getAdvertising, {
            position: 1
        })
        if (res) {
            this.setState({
                bg: res.Data[0] && res.Data[0].CoverImgBig
            })
        }
    }
    checkCode = async () => {
        const {
            CheckNumValue,
            phoneValue
        } = this.state;
        if (!CheckNumValue || !phoneValue) {
            Toast.info('请先将信息填写完整')
            return
        };
        let id = this.props.match.params.id.split("_ios")
        httpAxios(api.boundUserPhone, {
            phone: phoneValue,
            code: CheckNumValue,
            unionid: id[0],
            state: id.length > 1 ? 4 : 1,
        }).then(res => {
            if (res.Code === 403) {
                //message.info(res.Message);
                alert(res.Message);
                // alert('此手机号已经被其它微信号绑定', '您可以使用此手机号登录后，在“设置-三方账号绑定“中进行更换绑定微信号的操作。', [
                //     { text: '绑定其它手机号', onPress: () => console.log('cancel') },
                //     { text: '去登录', onPress: () => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'login' })); } },
                // ])
                return
            }
            if (res.Code === 200) {
                setTimeout(() => {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'bangding', userinfo: res.Data }));
                }, 10);

            }
        }).catch(e => {

        })

    }

    saveInfo = info => {
        this.setState({
            ...this.setState,
            ...info
        })
    }

    //离开input时验证手机号和邮箱
    checkPhone = (e) => {
        let value = e.target.value;
        value = value.trim();
        let myregPhone = /^1[3456789]\d{9}$/;
        let myregEmail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!myregPhone.test(value) && !myregEmail.test(value)) {
            // Toast.info('请输入正确的手机号或邮箱');
            this.setState({
                phoneValid: false,
                phoneStatus: 'error'
            })
        } else {
            this.setState({
                phoneValid: true,
                phoneValue: value,
                phoneStatus: 'success'
            })
        }

    }

    //验证码
    validateInput = (type, e) => {
        const value = (e && e.target && e.target.value) || '';
        if (value) {
            this.setState({
                [`${type}Status`]: '',
                [`${type}Value`]: value
            })
        } else {
            this.setState({
                [`${type}Status`]: 'error'
            })
        }
    }

    //输入input内容
    handleChange = (e) => {
        this.setState({
            phoneValue: e.target.value
        })
    }

    render() {
        const {
            phoneStatus,
            CheckNumStatus,
            phoneValue,
            CheckNumValue,
        } = this.state;
        return (
            <LoginWrapper>
                <Title><div>绑定手机号</div><span>为了您的账号安全，请绑定手机号</span></Title>
                <BoxWrapper>
                    <span>手机号</span>
                    <FormItem>
                        <input className={phoneStatus} type="text" placeholder='请输入手机号码' value={phoneValue} onChange={this.handleChange} onBlur={this.checkPhone} />
                    </FormItem>
                    <span>验证码</span>
                    <FormItemVerification>
                        <input className={CheckNumStatus} type="text" value={CheckNumValue} onChange={(e) => { this.setState({ CheckNumValue: e.target.value.trim() }) }} placeholder='请输入验证码' onBlur={this.validateInput.bind(this, 'CheckNum')} />
                        {/* <SendCode cb={this.saveInfo} url={api.loginCode} params={{ account: phoneValue }} /> */}
                        <SendCode url={api.loginCode} phone={phoneValue} />
                    </FormItemVerification>
                    <LoginButton onClick={this.checkCode}>绑定</LoginButton>
                </BoxWrapper>
            </LoginWrapper>
        )
    }
}