import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './images/iconfont/iconfont.css';
import zhCN from 'antd/es/locale/zh_CN';
import {
	ConfigProvider
} from 'antd';
import { GlobalStyle } from './style';

ReactDOM.render(
	<ConfigProvider locale={zhCN}>
		<GlobalStyle />
		<App />
	</ConfigProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// 模块热替换的 API
if (module.hot) {
	module.hot.accept();
}