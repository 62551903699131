/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    NewsDetailWrapper,
    Empty,
} from '../../../../common/generalStyle';
import {
    CommentBox,
} from './style';
import Collection from '../../../../images/message.svg';
import httpAxios2 from "../../../../http/httpAxios2";
import api from "../../../../http/api";
import {
    startMinsTime
} from '../../../../utils/utils';

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Comment extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userId: this.props.location?.search.replace('?', ''),//从网址中取栏目类型
            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,
            data: [],
            typeKeys: {},
            hasMore: true,
            rowCount: 0,
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
        };
        this.getList();
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    getListRefresh = async () => {
        this.setState({
            data: [],
        })

        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        await this.getList();
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }
    getList = async () => {
        const res = await httpAxios2(api.getMyReplyList, {
            "PageSize": NUM_ROWS,
            "PageIndex": pageIndex,
            "userID": this.state.userId,
        });

        if (res) {
            this.state.rowCount = res.Message;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];
                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    onRefresh = async () => {
        pageIndex = 1;
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh();
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList();
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };


    render() {
        const {
            data,
            userId
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <NewsDetailWrapper>
                        <CommentBox>
                            <span>
                                {obj.TypeId == 1 && <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.TitlePNickName, weburl: '/community/communitydetail/' + obj.TitleId + '/news' + '?' + userId, })); }}>
                                    <i className="iconfont">&#xe769;</i>
                                    原文：{obj.Title}
                                </a>}
                                {obj.TypeId == 2 && <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.TitlePNickName, weburl: '/community/communitydetail/' + obj.TitleId + '/exhibition' + '?' + userId, })); }}>
                                    <i className="iconfont">&#xe769;</i>
                                    原文：{obj.Title}
                                </a>}
                                {obj.TypeId == 3 && <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.TitlePNickName, weburl: '/community/communitydetail/' + obj.TitleId + '/evaluation' + '?' + userId, })); }}>
                                    <i className="iconfont">&#xe769;</i>
                                    原文：{obj.Title}
                                </a>}
                                {obj.TypeId == 4 && <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.TitlePNickName, weburl: '/community/communitydetail/' + obj.TitleId + '/audition' + '?' + userId, })); }}>
                                    <i className="iconfont">&#xe769;</i>
                                    原文：{obj.Title}
                                </a>}
                                {obj.TypeId == 5 && <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.TitlePNickName, weburl: '/community/communitydetail/' + obj.TitleId + '/community' + '?' + userId, })); }}>
                                    <i className="iconfont">&#xe769;</i>
                                    原文：{obj.Title}
                                </a>}
                                {obj.TypeId == 7 && <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.TitlePNickName, weburl: '/community/communitydetail/' + obj.TitleId + '/lookback' + '?' + userId, })); }}>
                                    <i className="iconfont">&#xe769;</i>
                                    原文：{obj.Title}
                                </a>}
                            </span>
                            {
                                obj.ReplyID != 0 ? <><div>@{obj.PNickName}：{obj.Content}</div><h1>{obj.ReplyContent}</h1></>
                                    : <h1>{obj.Content}</h1>
                            }
                            <h2>
                                {obj.ReplyID == 0 && <div onClick={() => {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                        type: "detailnav",
                                        title: obj.ReplyCount + "条回复",
                                        weburl: '/replyComment/' + obj.CommentID + '/' + obj.TypeId + '?' + userId,
                                    }));
                                }}>
                                    <span>{obj.ReplyCount}回复</span><i className="iconfont">&#xe744;</i>
                                </div>}
                                <span>{startMinsTime(obj.SendTime)}</span>
                                <h3>
                                    <span>{obj.LikeNum}</span>
                                    <i className="iconfont">&#xe755;</i>
                                </h3>
                            </h2>

                        </CommentBox>
                    </NewsDetailWrapper>
                </div>
            );
        };
        return (<div>
            {
                this.state.rowCount != 0 ?
                    <ListView
                        initialListSize={8}
                        key={'1'}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                            {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                        </div>)}
                        renderRow={row}
                        useBodyScroll
                        pullToRefresh={<PullToRefresh
                            refreshing={this.state.refreshing}
                            onRefresh={this.onRefresh}
                        />}
                        onEndReached={this.onEndReached}
                        pageSize={NUM_ROWS}
                    />
                    :
                    <Empty>
                        <img src={Collection} alt="收藏无内容"></img>
                        <b>暂无回复内容</b>
                        <span>你还没有任何回复内容，多去转转吧～</span>
                        {/* 隐藏ListView不能去掉，不然ReactDOM高度会报错 */}
                        <ListView
                            style={{ display: 'none' }}
                            initialListSize={8}
                            key={'1'}
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource}
                            renderRow={row}
                            useBodyScroll
                            onEndReached={this.onEndReached}
                            pageSize={NUM_ROWS}
                        />
                    </Empty>
            }
        </div>);
    }
}

ReactDOM.render(<Comment />, document.getElementById('root'));
export default Comment