import React, {
    Component
} from 'react';
import {
    CommunityDetailWrapper,
    BottomDownload,
} from './LivebroadcastDetailH5Style';
const zyxgh5 = require('../../images/zyxgh5.png');
const h5logo = require('../../images/h5logo.png');

class ZhongyuexinggouH5 extends Component {
    render() {
        return (
            <CommunityDetailWrapper>
                <img src={zyxgh5} alt="" style={{width:'100%'}} />
                <BottomDownload>
                    <img src={h5logo} alt="" />
                    <a href={'https://a.app.qq.com/o/simple.jsp?pkgname=com.chinaauidonativeapp'} target='_blank'>
                        <div>打开App</div>
                    </a>
                </BottomDownload>
            </CommunityDetailWrapper>
        )
    }
}

export default ZhongyuexinggouH5