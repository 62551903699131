import React, { Component } from 'react';
import {
    Tabs
} from './LabelStyle';

class Label extends Component {

    state = {
        activeKey: '1',
        flag: 0
    }
    componentWillReceiveProps(next) {
        if (this.props.MediaMan == true && this.state.flag == 0) {
            this.handleClick('5');
            this.setState({ flag: 1 });
        }
    }

    handleClick = (id) => {
        this.setState({
            activeKey: id
        })
        this.props.cb && this.props.cb(id, null, null);
        //返回顶部
        //window.scrollTo( 0, 0 );
    }
    render() {
        return (
            <Tabs>
                {this.props.MediaMan ? <div className={this.state.activeKey === '5' ? 'selected' : ''} onClick={this.handleClick.bind(this, '5')}>
                    <span>新闻</span>
                    {
                        this.state.activeKey === '5' ? <div /> : ''
                    }
                </div> : ""}
                <div className={this.state.activeKey === '1' ? 'selected' : ''} onClick={this.handleClick.bind(this, '1')}>
                    <span>社区</span>
                    {
                        this.state.activeKey === '1' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '2' ? 'selected' : ''} onClick={this.handleClick.bind(this, '2')}>
                    <span>评测</span>
                    {
                        this.state.activeKey === '2' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '3' ? 'selected' : ''} onClick={this.handleClick.bind(this, '3')}>
                    <span>视音</span>
                    {
                        this.state.activeKey === '3' ? <div /> : ''
                    }
                </div>
                {/* {
                    this.props.list != null && 
                        this.props.list.map(i => (
                            <div key={i.ID} className={this.state.activeKey === i.ID ? 'selected' : ''} onClick={this.handleClick.bind(this, i.ID)}>
                                {
                                    this.state.activeKey === i.ID ? <><span>{i.Title}</span><div /></> : <>{i.Title}</>
                                }
                            </div>
                        ))
                } */}
            </Tabs>
        )
    }
}

export default Label;
