/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleOneImg,
    NewsPubs,
    VideoImg,
    VideoIcon
} from '../../../common/generalStyle';
import {
    NavBar,
    ReturnButton,
    SearchIcon
} from './ContentStyle';

import Label from "./Label";
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import {
    startMinsTime
} from '../../../utils/utils';
const playSvg = require('../../../images/plays.svg');
const returnButton = require('../../../images/return_button.svg')
const searchButton = require('../../../images/search_button.svg')
const listVipLogo = require('../../../images/listVipLogo.png');

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            // readType: '2',
            // listType: '1',
            targetID: '999',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        pageIndex = 0;
        this.getComType();
        // this.getList(null, null, null);

        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    goBackPage = () => {
        if (this.props.location?.search === '?v=1') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goHome" }));
        } else {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }

    getListRefresh = async (targetID, readType, listType) => {
        window.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async (targetID, readType, listType) => {
        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;
        if (!targetID) targetID = this.state.targetID;
        let params;
        params = {
            "catalogId": targetID,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
        }

        const res = await httpAxios(api.readEvaluationCatalog, params);
        if (res) {
            const str = res.Data.total;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.EvaluationList.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data.EvaluationList,
                        targetID,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data.EvaluationList];

                    this.setState({
                        data: tempData,
                        targetID,
                    })
                }
            } else {
                this.setState({
                    data: [],
                    targetID,
                })
                this.state.hasMore = false;
            }


            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    getComType = async () => {
        const res = await httpAxios(api.evaluationType);
        let typeKeys = {};
        if (res) {
            res.Data.map(i => {
                typeKeys[i.ID] = i.Title
            })
            this.setState({
                comTypes: res.Data,
                typeKeys: typeKeys
            })
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        pageIndex += 1;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
            comTypes,
        } = this.state;

        return (<div>
            <NavBar>
                <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                <span>评测</span>
                <SearchIcon onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><img src={searchButton} alt="搜索" /></SearchIcon>
                <Label cb={this.getListRefresh} list={comTypes} />
            </NavBar>
            <div style={{ height: '8.5rem' }}></div>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/evaluation' + '?' + this.state.userPID, })); }}>
                                    {
                                        obj.ShowType == 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    {/* <img src={obj.CoverIMG} alt="" />*/}
                                                    {
                                                        obj.CoverIMG.indexOf('?') > 0 ? <img src={obj.CoverIMG.slice(0, obj.CoverIMG.indexOf('?')) + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                            : <img src={obj.CoverIMG + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                    }
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 0 || obj.ShowType == 1 ?
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                            <NewsImgWrap>
                                                                {/* <img src={obj.CoverIMG} alt="" /> */}
                                                                {
                                                                    obj.CoverIMG.indexOf('?') > 0 ? <img src={obj.CoverIMG.slice(0, obj.CoverIMG.indexOf('?')) + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                        : <img src={obj.CoverIMG + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                }
                                                            </NewsImgWrap>
                                                        </NewsItem >
                                                        :
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle>{obj.Title}</NewsTitle>
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl?.split(';').map((image, index) => (
                                                                        index < 3 && <div>
                                                                            {/* <img src={image} alt="" /> */}
                                                                            {
                                                                                image.indexOf('?') > 0 ? <img src={image.slice(0, image.indexOf('?')) + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                                    : <img src={image + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                }
                                            </>
                                    }
                                    <NewsPubs>
                                        {
                                            obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                        }
                                        <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPubs>
                                </a>
                            </NewsDetailWrapper>
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content