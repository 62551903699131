import React, {
    Component
} from 'react';
import {
    TextArea
} from 'antd-mobile';

class TextAreaMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textAreaMessage: '',
        }
    }

    componentDidMount() {
        var that = this;

        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "search", title: '', search: JSON.stringify({ type: 2, value: that.state.textAreaMessage }) }))
        };
    }

    render() {
        const {
            textAreaMessage
        } = this.state;
        return (
            <div style={{ padding: '10px', background: '#fff', borderRadius: '8px' }}>
                <TextArea
                    placeholder='请输入留言'
                    value={textAreaMessage}
                    onChange={val => {
                        this.setState({
                            textAreaMessage: val
                        })

                    }}
                    showCount
                    maxLength={500}
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    ref={function (e) {
                        if (e != null) {
                            e.focus();
                        }
                    }}
                />
            </div>
        )
    }
}

export default TextAreaMessage