import React, {
    Component
} from 'react';
import {
    CommunityDetailWrapper,
    ContentWrapper,
    ClickNum,
    Focused,
    Focus,
    Title,
    Author,
    Content,
    Block,
    BlockBoxBG,
    BlockBox,
    BlockBoxTitle,
    BlockBoxBody,
    BlockBoxButton,
    BlockBoxButtonLeft,
    BlockBoxButtonRight,
    QuickReply,
    QuickReplys,
    QuickReplyInput,
    QuickReplyOperation,
    CommentInputButton,
    CommentWrapper,
    CommentBox,
    CommentBoxLeft,
    CommentBoxRight,
    ExhibitionBuy,
    QuickReplyDiv,
    Motai,
    Daojishi
} from './CommunityDetailStyle';
import httpAxios2 from "../../http/httpAxios2";
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

import {
    filterVideoSrc,
    scienceNumber,
    startMinsTime,
    filterHtml,
} from '../../utils/utils';
import ReportBox from '../../common/reportBox';
import DijiaModal from '../../common/dijiaModal';

import {
    message
} from 'antd';
import axios from 'axios';
import { Toast } from 'antd-mobile-v2';
import { ImageViewer, Button } from 'antd-mobile';
import { Progress, Space } from 'antd';

const jiFen = require('../../images/jifen.png');
const jiFenLingQu = require('../../images/jifenlingqu.png');
const xiangqingVipLogo = require('../../images/xiangqingVipLogo.png');

class CommunityDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoId: this.props.match.params.id,
            infoAuthorID: '',
            infoTitle: '',
            infoListIMG: '',
            infoAddTime: '',
            infoContentNews: '',
            infoDescription: '',
            infoImageAppUrl: '',
            infoAuthor: '',
            infoHeadIMG: '',
            infoVideoSrc: '',
            infoClickNum: 0,
            infoLikeNum: 0,
            infoCollectionNum: 0,
            infoCommNum: 0,
            infoIsBussUser: false,

            userId: this.props.location.search.replace('?', ''),//从网址中取栏目类型
            type: this.props.match.params.type,
            freshComments: [],
            hotComments: [],
            reportComment: '',
            freshCommentsPageIndex: 1,
            freshCommentsTotal: 0,

            focused: false,
            openBlock: false,
            isLiked: false,
            isCollected: false,

            commentText: '',
            commentTextFocus: false,
            commentData: [],
            ImgLinks: [],
            EXID: 0,
            Brands: [],
            TypeName: '',
            ProductLink: '',
            selectCity: '',
            liuyan: '',
            userData: [],
            daojishiPercent: '',
            time: ''
        }
    }

    componentWillReceiveProps(next) {
        // if (this.props.match.params.id !== next.match.params.id) {
        //     this.getComInfo(next.match.params.id);
        //     this.checkLikeAndCollection(next.match.params.id);
        // }

        if (this.state.infoAuthorID) {
            this.checkFocus(this.state.infoAuthorID);
            this.checkBlock(this.state.infoAuthorID);
        }
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID,
                userData: data
            })
        }

        window.gpsInfo = function (value) {
            that.setState({ selectCity: value });
        };

        window.search = (data) => {
            const dataJson = JSON.parse(data);
            if (dataJson.type == 1) {
                that.setState({ selectCity: dataJson.value });
            }

            if (dataJson.type == 2) {
                that.setState({ liuyan: dataJson.value })
            }
        }

        document.oncontextmenu = () => false;
        if (this.state.type === "community") {
            this.getComInfo();
        } else if (this.state.type === "news") {
            this.getNewsInfo();
        } else if (this.state.type === "evaluation") {
            this.getEvalInfo();
        } else if (this.state.type === "audition") {
            this.getAuditionInfo();
        } else if (this.state.type === "lookback") {
            this.getLookBack();
        } else if (this.state.type === "exhibition") {
            this.getExProductInfo();
        }

        // this.checkLikeAndCollection();
        this.getClickAndCollection();
        this.getCommonCommentList();
        // setTimeout(() => {
        //     window.ReactNativeWebView.postMessage(JSON.stringify({ type: "getgpsinfo", value: 1 }));
        // }, 1000);
        if (localStorage.getItem('TASK_TAG') == "阅读") {
            clearTimeout(this.timeId);
            this.setState({
                time: 10,
                daojishiPercent: 0
            })
            this.timeId = setInterval(this.trick, 1000);
        }

    }
    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    //根据文字ID和类型获取评论列表
    getCommonCommentList = async (typeID, id = this.props.match.params.id, PageIndex = 1, PageSize = 100) => {
        const {
            type,
            userId
        } = this.state;
        let _userId = '00000000-0000-0000-0000-000000000000';
        if (userId != '') _userId = userId;
        if (type === "community") {
            typeID = 5;
        } else if (type === "news") {
            typeID = 1;
        } else if (type === "evaluation") {
            typeID = 3;
        } else if (type === "audition") {
            typeID = 4;
        } else if (type === "lookback") {
            typeID = 7;
        } else if (type === "exhibition") {
            typeID = 2;
        }
        const res = await httpAxios2(api.getCommonCommentList, {
            TitleID: id,
            Type: typeID,
            UserID: _userId,
            PageSize: PageSize,
            PageIndex: PageIndex
        })
        if (res && res.Data) {
            this.setState({
                commentData: res.Data,
            });
        }
    }



    //获取点赞和收藏个数
    getClickAndCollection = async () => {
        if (this.state.type === "community") {
            const res = await httpAxios2(api.checkComLikeAndCollection, {
                NewsID: this.state.infoId,
                UserID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.isZan,
                    isCollected: res.Data.isColl
                })
            }
        } else if (this.state.type === "news") {
            const res = await httpAxios2(api.getNewsLikedAndCollection, {
                NewID: this.state.infoId,
                UserID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.IsLiked,
                    isCollected: res.Data.IsCollected
                })
            }
        } else if (this.state.type === "evaluation") {
            const res = await httpAxios2(api.checkEvalLikeAndCollection, {
                evalID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.zan,
                    isCollected: res.Data.isCollected
                })
            }
        } else if (this.state.type === "audition") {
            const res = await httpAxios2(api.checkExperLikeAndColl, {
                ExperienceID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.isLiked,
                    isCollected: res.Data.isCollected
                })
            }
        } else if (this.state.type === "lookback") {
            const res = await httpAxios2(api.checkVideoPlaybackCollectAndZan, {
                VideoPlaybackID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.isZan,
                    isCollected: res.Data.isColl
                })
            }
        } else if (this.state.type === "exhibition") {
            const res = await httpAxios2(api.getExLikedAndCollection, {
                TargetID: this.state.infoId,
                userID: this.state.userId
            });
            if (res) {
                this.setState({
                    isLiked: res.Data.IsLiked,
                    isCollected: res.Data.IsCollected
                })
            }
        }
    }

    componentWillUnmount() {
        document.oncontextmenu = () => true;
    }
    // 详情页数据
    getComInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.getComInfo, {
            ID: id
        })
        if (res && res.Data) {
            this.setState({
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.ListIMG,
                infoAddTime: res.Data.Addtime,
                infoContentNews: res.Data.ContentNews,
                infoImageAppUrl: res.Data.ImageAppUrl,
                infoAuthor: res.Data.AutherInfo.Author,
                infoHeadIMG: res.Data.AutherInfo.HeadIMG,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.CollectionNum,
                infoCommNum: res.Data.CommNum,
                infoIsBussUser: res.Data.IsBussUser
            }, () => {
                this.showImage();
            })
        }
    }
    getNewsInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.searchNewsInfo, {
            newsID: id
        })
        if (res && res.Data) {
            this.setState({
                infoIsBussUser: res.Data.IsBussUser,
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.PosterLandscape,
                infoAddTime: res.Data.Addtime,
                infoContentNews: res.Data.ContentNews,
                infoImageAppUrl: res.Data.ImageAppUrl,
                infoAuthor: res.Data.Author,
                infoHeadIMG: res.Data.HeadImg,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.ColNum,
                infoCommNum: res.Data.CommNum,
            }, () => {
                this.showImage();
            })
        }
    }
    getEvalInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.getEvalInfo, {
            ID: id
        })
        if (res && res.Data) {
            this.setState({
                infoId: res.Data[0].ID,
                infoAuthorID: res.Data[0].UserID,
                infoTitle: res.Data[0].Title,
                infoListIMG: res.Data[0].CoverIMG,
                infoAddTime: res.Data[0].AddTime,
                infoContentNews: res.Data[0].EditContent,
                infoImageAppUrl: res.Data[0].ImageAppUrl,
                infoAuthor: res.Data[0].PNickName,
                infoHeadIMG: res.Data[0].HeadImg,
                infoClickNum: res.Data[0].ClickNum,
                infoLikeNum: res.Data[0].LikeNum,
                infoCollectionNum: res.Data[0].ColNum,
                infoCommNum: res.Data[0].CommNum,
                infoIsBussUser: res.Data[0].IsBussUser
            }, () => {
                this.showImage();
            })
        }
    }
    getAuditionInfo = async (id = this.props.match.params.id) => {
        const res = await httpAxios2(api.getExperInfo, {
            ID: id
        })
        // alert(JSON.stringify(res.Data));
        if (res && res.Data) {
            this.setState({
                infoId: res.Data.id,
                infoAuthorID: res.Data.PubUserID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.PosterImg,
                infoAddTime: res.Data.AddTime,
                infoVideoSrc: res.Data.VideoSrc,
                infoDescription: res.Data.Description,
                infoAuthor: res.Data.PNickName,
                infoHeadIMG: res.Data.HeadImg,
                infoClickNum: res.Data.ViewNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.CollectNum,
                infoCommNum: res.Data.CommentNum,
                infoIsBussUser: res.Data.IsBussUser
            }, () => {
                this.showImage();
            })
        }
    }
    getLookBack = async (id = this.props.match.params.id) => {

        const res = await httpAxios2(api.getVideoPlayback, {
            ID: id
        })
        if (res && res.Data) {
            this.setState({
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserBID,
                infoTitle: res.Data.Title,
                infoListIMG: res.Data.ImageUrl,
                infoAddTime: res.Data.AddTime,
                infoVideoSrc: res.Data.VideoSrc,
                infoAuthor: res.Data.CompanyName,
                infoHeadIMG: res.Data.HeadImg,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.ColNum,
                infoCommNum: res.Data.CommNum,
                infoIsBussUser: res.Data.isBusiUser
            }, () => {
                this.showImage();
            })
        }
    }
    getExProductInfo = async (id = this.props.match.params.id) => {

        const res = await httpAxios2(api.getExProductInfo, {
            pid: id
        })
        if (res && res.Data) {
            this.setState({
                infoId: res.Data.ID,
                infoAuthorID: res.Data.UserBID,
                infoTitle: res.Data.Title,
                ImgLinks: res.Data.ImgLinks.split(','),
                infoAddTime: res.Data.AddTime,
                infoVideoSrc: res.Data.VideoSrc,
                infoAuthor: res.Data.CompanyName,
                infoHeadIMG: res.Data.CompanyLogo,
                infoClickNum: res.Data.ClickNum,
                infoLikeNum: res.Data.LikeNum,
                infoCollectionNum: res.Data.CollNum,
                infoCommNum: res.Data.CommNum,
                infoContentNews: res.Data.Description,
                EXID: res.Data.EXID,
                Brands: res.Data.Brands,
                TypeName: res.Data.TypeName,
                ProductLink: res.Data.ProductLink,
                infoListIMG: res.Data.MainPicLandScape,
                infoIsBussUser: res.Data.isBusiUser
            }, () => {
                this.showImage();
            })
        }
    }


    //增加和取消收藏
    toggleCollect = async () => {
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能收藏文章');
            return
        }
        const {
            isCollected,
            type,
            userId
        } = this.state;
        if (type === "community") {
            let url = isCollected ? api.removeComCollection : api.addComCollection;
            const res = await httpAxios2(url, {
                "CommunityID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "news") {
            let url = isCollected ? api.cancelNewsColletion : api.addNewsCollection;
            const res = await httpAxios2(url, {
                "NewID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "evaluation") {
            let url = isCollected ? api.removeEvalCollection : api.addEvalCollection;
            const res = await httpAxios2(url, {
                "EvalID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "audition") {
            let url = isCollected ? api.removeExperCollection : api.addExperCollection;
            const res = await httpAxios2(url, {
                "ExperID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "lookback") {
            let url = isCollected ? api.deleteVideoPlaybackCollection : api.addVideoPlaybackCollection;
            const res = await httpAxios2(url, {
                "VideoPlaybackID": this.props.match.params.id,
                "UserID": userId,
            });
            if (res) {
                this.setState({
                    isCollected: !isCollected,
                    infoCollectionNum: res.Data.split('：')[1]
                })
            }
        } else if (type === "exhibition") {

            if (isCollected) {
                const res = await httpAxios2(api.removeExCollection, {
                    "targetID": this.props.match.params.id,
                    "UserID": userId,
                });
                if (res) {
                    this.setState({
                        isCollected: !isCollected,
                        infoCollectionNum: res.Data.split('：')[1]
                    })
                }
            } else {
                const res = await httpAxios2(api.addExCollection, {
                    "ProductID": this.props.match.params.id,
                    "UserID": userId,
                    "ExhibitionID": this.state.EXID
                });
                if (res) {
                    this.setState({
                        isCollected: !isCollected,
                        infoCollectionNum: res.Data.split('：')[1]
                    })
                }
            }

        }
    }
    //增加和取消点赞
    toggleLike = async () => {
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能点赞文章');
            return
        }
        const {
            isLiked,
            type
        } = this.state;
        if (type === "community") {
            let url = isLiked ? api.removeComZan : api.addComZan;
            const res = await httpAxios2(url, {
                "CommunityID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "news") {
            let url = isLiked ? api.cancelNewsZan : api.clickNewsZan;
            const res = await httpAxios2(url, {
                "NewID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "evaluation") {
            let url = isLiked ? api.removeEvalLike : api.addEvalLike;
            const res = await httpAxios2(url, {
                "EvalID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "audition") {
            let url = isLiked ? api.removeExperLike : api.addExperLike;
            const res = await httpAxios2(url, {
                "ExperienceID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "lookback") {
            let url = isLiked ? api.removeVideoPlaybackZan : api.updateVideoPlaybackZan;
            const res = await httpAxios2(url, {
                "VideoPlaybackID": this.props.match.params.id,
                "UserID": this.state.userId,
            });
            if (res) {
                this.setState({
                    isLiked: !isLiked,
                    infoLikeNum: res.Data
                })
            }
        } else if (type === "exhibition") {
            if (isLiked) {
                const res = await httpAxios2(api.cancelExLike, {
                    "UserID": this.state.userId,
                    "targetID": this.props.match.params.id
                });
                if (res) {
                    this.setState({
                        isLiked: !isLiked,
                        infoLikeNum: res.Data
                    })
                }
            }
            else {
                const res = await httpAxios2(api.clickExLike, {
                    "ProductID": this.props.match.params.id,
                    "UserID": this.state.userId,
                    "ExhibitionID": this.state.EXID
                });
                if (res) {
                    this.setState({
                        isLiked: !isLiked,
                        infoLikeNum: res.Data
                    })
                }
            }
        }
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }
    //检查是否已经关注
    checkFocus = async (id) => {
        if (!id) return
        // if (!this.state.userId) return
        if (this.state.userId) {
            const res = await axios.get(api.checkUserFocus.url + '?userID=' + this.state.userId + '&focusUserID=' + id)
            if (res && res.status === 200) {
                if (res.data.Code !== 404) {
                    this.setState({
                        focused: true
                    })
                }
            }
        }
    }
    //增加关注
    addFocus = async (author) => {

        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        // alert(this.props.location.search.replace('?', ''));
        if (this.state.userId === author) {
            message.info('用户不能关注自己');
            return
        }
        const {
            focused
        } = this.state;
        if (focused) return
        const res = await httpAxios2(api.addUserFollow, {
            User1: this.state.userId,
            User2: author
        })
        if (res) {
            this.setState({
                focused: true
            })
        }
    }
    //移除关注
    removeFocus = async (author) => {
        const {
            focused
        } = this.state;
        if (!focused) return
        const res = await httpAxios2(api.removeUserFocus, {
            User1: this.state.userId,
            User2: author,
        })
        if (res) {
            this.setState({
                focused: false
            })
        }
    }

    //检查是否已经拉黑
    checkBlock = async (id) => {
        if (!id) return
        // if (!this.state.userId) return
        if (this.state.userId) {
            const res = await axios.get(api.checkUserBlack.url + '?userID=' + this.state.userId + '&blackUserID=' + id);
            if (res && res.status === 200) {
                if (res.data.Code !== 404) {
                    this.setState({
                        openBlock: true
                    })
                }
            }
        }

    }
    //增加拉黑
    addBlock = async (author) => {
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能拉黑用户');
            return
        }
        if (this.state.userId === author) {
            message.info('用户不能拉黑自己');
            return
        }
        const res = await httpAxios2(api.addToBlackList, {
            User1: this.state.userId,
            User2: author
        })
        if (res) {
            Toast.info(JSON.stringify("成功拉黑了该作者，3秒后将退出该文章"));
            setInterval(async () => {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
            }, 3000);
        }
    }
    //移除拉黑
    removeBlock = async (author) => {
        const res = await httpAxios2(api.removeBlackUser, {
            User1: this.state.userId,
            User2: author,
        });
        if (res) {
            Toast.info(JSON.stringify("成功解除拉黑"));
            this.setState({
                openBlock: false
            })
        }
    }
    //评论input 输入框获取焦点时执行
    handleInputFocus = () => {
        this.setState({
            commentTextFocus: true
        }, () => {
            this.textInput.focus()
        })
    }
    handleInputBlur = () => {
        this.setState({
            commentTextFocus: false
        })
    }
    //发布评论
    submitComment = async () => {
        const {
            type,
            userId,
            infoId,
            commentText
        } = this.state;
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (!this.state.commentText) return
        if (type === "community") {
            const res = await httpAxios(api.addComComment, {
                "CommunityID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (res.Code == 200) {
                if (localStorage.getItem('TASK_TAG') == "点击评论") {
                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                }
            }
            if (!res) return
        } else if (type === "news") {
            const res = await httpAxios(api.addComment, {
                "NewsID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (res.Code == 200) {
                if (localStorage.getItem('TASK_TAG') == "点击评论") {
                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                }
            }
            if (!res) return
        } else if (type === "evaluation") {
            const res = await httpAxios(api.createEvalComment, {
                "EvalID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (res.Code == 200) {
                if (localStorage.getItem('TASK_TAG') == "点击评论") {
                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                }
            }
            if (!res) return
        } else if (type === "audition") {
            const res = await httpAxios(api.createExperComment, {
                "ExperID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (res.Code == 200) {
                if (localStorage.getItem('TASK_TAG') == "点击评论") {
                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                }
            }
            if (!res) return
        } else if (type === "lookback") {
            const res = await httpAxios(api.addVideoPlaybackCommon, {
                "VideoPlaybackID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (res.Code == 200) {
                if (localStorage.getItem('TASK_TAG') == "点击评论") {
                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                }
            }
            if (!res) return
        } else if (type === "exhibition") {
            const res = await httpAxios(api.addExComment, {
                "TargetID": infoId,
                "UserID": userId,
                "ContentSB": commentText
            })
            if (res.Code == 200) {
                if (localStorage.getItem('TASK_TAG') == "点击评论") {
                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                }
            }
            if (!res) return
        };

        this.getCommonCommentList();
        window.location.href = "#commentTop";
        this.setState({
            commentTextFocus: false,
            commentText: ''
        });
        this.textInput.value = ''
    }
    //评论点赞
    submitCommentLike = async (commentId, typeID) => {
        const {
            userId,
            type,
            infoId
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (type === "community") {
            typeID = 5;
        } else if (type === "news") {
            typeID = 1;
        } else if (type === "evaluation") {
            typeID = 3;
        } else if (type === "audition") {
            typeID = 4;
        } else if (type === "lookback") {
            typeID = 7;
        }
        else if (type === "exhibition") {
            typeID = 2;
        }
        const res = await httpAxios2(api.createCommonCommentLike, {
            commentID: commentId,
            type: typeID,
            userID: userId,
        })
        if (res.Code == 200) {
            this.getCommonCommentList();
        } else {
            alert(res.Message);
        }
    }
    //删除评论点赞
    deleteCommonCommentLike = async (commentId, typeID) => {
        const {
            userId,
            type,
            infoId
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (type === "community") {
            typeID = 5;
        } else if (type === "news") {
            typeID = 1;
        } else if (type === "evaluation") {
            typeID = 3;
        } else if (type === "audition") {
            typeID = 4;
        } else if (type === "lookback") {
            typeID = 7;
        }
        else if (type === "exhibition") {
            typeID = 2;
        }


        const res = await httpAxios2(api.deleteCommonCommentLike, {
            commentID: commentId,
            type: typeID,
            userID: userId,
        })
        if (res.Code == 200) {
            this.getCommonCommentList();
        } else {
            alert(res.Message);
        }
    }

    //显示图片
    showImage = () => {
        if (this.content != null) {
            let imageObjects = this.content.getElementsByTagName("img");
            let ImgLinks = [];

            for (let index = 0; index < imageObjects.length; index++) {
                const element = imageObjects[index];

                ImgLinks.push(element.src);
            }


            for (let index = 0; index < imageObjects.length; index++) {
                const element = imageObjects[index];

                element.onclick = function () {
                    ImageViewer.Multi.show({ images: ImgLinks, defaultIndex: index })
                }
            }
        }
    }

    //用户日常任务签到
    userRiChangTaskSign = async (taskID, userID) => {
        if (this.state.userId == userID) {
            const res = await httpAxios(api.userRiChangTaskSign, {
                "userID": userID,
                "taskID": taskID
            });
            if (res.Code == 200) {
                sessionStorage.setItem('TASK_USER', '');
                sessionStorage.setItem('TASK_LINK', '');
                sessionStorage.setItem('TASK_ID', '');
                sessionStorage.setItem('TASK_TAG', '');
            }
        }
    }
    //倒计时
    trick = () => {
        let {
            time,
            daojishiPercent
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1,
                daojishiPercent: daojishiPercent + 10
            })
        } else if (time == 0) {
            this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
        }
    }

    render() {
        const {
            infoId,
            infoAuthorID,
            infoTitle,
            infoListIMG,
            infoAddTime,
            infoContentNews,
            infoImageAppUrl,
            infoVideoSrc,
            infoDescription,
            infoAuthor,
            infoHeadIMG,
            infoClickNum,
            infoLikeNum,
            infoCollectionNum,
            infoCommNum,

            type,
            userId,
            isCollected,
            isLiked,
            focused,
            openBlock,
            commentText,
            commentTextFocus,
            commentData,
            commentDataLike,
            ImgLinks,
            Brands,
            TypeName,
            ProductLink,
            infoIsBussUser,
            selectCity,
            liuyan,
            userData,
            daojishiPercent
        } = this.state;

        return (
            <CommunityDetailWrapper>
                <ContentWrapper>
                    {/* 阅读任务倒计时 */}
                    {localStorage.getItem('TASK_TAG') == "阅读" && < Daojishi >
                        <Progress
                            type="circle"
                            strokeColor={{
                                '0%': '#0060EC',
                                '100%': '#B82CFC',
                            }}
                            width={50}
                            percent={daojishiPercent}
                        />
                        {
                            daojishiPercent == 100 ? <img
                                src={jiFenLingQu}
                                alt=""
                                onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/SignIn" })) }}
                            />
                                : <img src={jiFen} alt="" />
                        }
                    </Daojishi>
                    }
                    <Title>{infoTitle}</Title>
                    <Author>
                        {infoIsBussUser && <img src={xiangqingVipLogo} alt="" />}
                        <a onClick={() => {
                            {
                                infoIsBussUser ? window.ReactNativeWebView.postMessage(JSON.stringify({
                                    type: "author", weburl: "/CompanyAuthor/" + infoAuthorID
                                })) : window.ReactNativeWebView.postMessage(JSON.stringify({
                                    type: "author", weburl: "/PersonalAuthor/" + infoAuthorID
                                }))
                            }
                        }}>

                            <img src={infoHeadIMG} alt="" />
                            <div>
                                <div>{infoAuthor}</div>
                                <span>{startMinsTime(infoAddTime)}发布 · {scienceNumber(infoClickNum || 0)}浏览</span>
                            </div>
                        </a>
                        {focused ? <Focused onClick={this.removeFocus.bind(this, infoAuthorID)}>已关注</Focused> : <Focus onClick={this.addFocus.bind(this, infoAuthorID)}>关注</Focus>}
                    </Author>
                    {type === "exhibition" ? <Content>
                        {Brands && <div className='exhibitionBox'>
                            <div className='exhibitionTitle'>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌</div>
                            <div className='exhibitionText'>{
                                Brands.map((b, index) => (
                                    <>
                                        {index > 0 && "，"}
                                        {b.BrandName}
                                    </>
                                ))
                            }
                            </div>
                        </div>}
                        {TypeName && <div className='exhibitionBox'><div className='exhibitionTitle'>分&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类</div><div className='exhibitionText'>{TypeName}</div></div>}
                        {infoContentNews && <div className='exhibitionBox'><div className='exhibitionTitle'>产品介绍</div><div className='exhibitionText'>{infoContentNews}</div></div>}
                        {
                            ImgLinks.map((item, index) => (<img src={item} alt="" onClick={() => {
                                ImageViewer.Multi.show({ images: ImgLinks, defaultIndex: index })
                            }} />))
                        }
                        {infoVideoSrc && <>{
                            infoVideoSrc.indexOf('?') > 0 ? <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc.slice(0, infoVideoSrc.indexOf('?'))}></video> : <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc}></video>
                        }</>}
                        {ProductLink && <a href={ProductLink} target="_blank"><ExhibitionBuy>点击去购买</ExhibitionBuy></a>}
                    </Content>
                        :
                        <Content>
                            {
                                ImgLinks.map((item, index) => (<img src={item} alt="" onClick={() => {
                                    ImageViewer.Multi.show({ images: ImgLinks, defaultIndex: index })
                                }} />))
                            }
                            {infoContentNews && <div className='detail-img' ref={r => this.content = r} dangerouslySetInnerHTML={{ __html: filterVideoSrc(infoContentNews) }}></div>}
                            {infoVideoSrc && <>{
                                infoVideoSrc.indexOf('?') > 0 ? <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc.slice(0, infoVideoSrc.indexOf('?'))}></video> : <video controls="controls" width='100%' controlslist='nodownload' src={infoVideoSrc}></video>
                            }</>}
                            {infoDescription && <div className='exhibitionBox'><div className='auditiontext'>{infoDescription}</div></div>}
                        </Content>}
                    <ClickNum>
                        {scienceNumber(infoClickNum || 0)}浏览
                        <ReportBox reportTitle={infoTitle} reportAuthor={infoAuthor} targetId={infoId} userId={userId} typeId={4} />
                        <Block onClick={this.addBlock.bind(this, infoAuthorID)}><i className="iconfont">&#xe75a;</i>拉黑作者</Block>
                    </ClickNum>
                    <a name="commentTop"></a>
                    {/* 获取底价功能 */}
                    <div>
                        <DijiaModal selectCity={selectCity} liuyan={liuyan} userData={userData} infoId={infoId} type={this.state.type} />
                    </div>
                    <CommentWrapper>
                        <span>评论</span>
                        {commentData.length == 0 && <CommentBox><span style={{
                            width: '100%',
                            height: '5rem',
                            textAlign: 'center',
                            display: 'block',
                            lineHeight: '5rem',
                            fontSize: '0.88rem',
                            color: '#7C8698'
                        }}>暂无评论，点击抢沙发</span></CommentBox>}
                        {
                            commentData.map((d) => (
                                <CommentBox>
                                    <CommentBoxLeft>
                                        {infoIsBussUser && <div><img src={xiangqingVipLogo} alt="" /></div>}
                                        <img src={d.HeadImg}></img>
                                    </CommentBoxLeft>
                                    <CommentBoxRight>
                                        <h1>
                                            {d.PNickName}
                                            {d.IsLike ? <span className="isLike" onClick={this.deleteCommonCommentLike.bind(this, d.CommentId)}>
                                                <span>{d.LikeNum}</span>
                                                <i className="iconfont">&#xe780;</i>
                                            </span> : <span onClick={this.submitCommentLike.bind(this, d.CommentId)}>
                                                <span>{d.LikeNum}</span>
                                                <i className="iconfont">&#xe755;</i>
                                            </span>}
                                        </h1>
                                        <h2>{filterHtml(d.Content)}</h2>
                                        <div>
                                            <div onClick={() => {
                                                window.ReactNativeWebView.postMessage(JSON.stringify({
                                                    type: "detailnav",
                                                    title: d.ReplyNum + "条回复",
                                                    weburl: '/replyComment/' + d.CommentId + '/' + d.TypeId + '?' + userId,
                                                }));
                                            }}><span>{d.ReplyNum}回复</span><i className="iconfont">&#xe744;</i></div>
                                            <span>{startMinsTime(d.AddTime)}</span>
                                        </div>
                                    </CommentBoxRight>
                                </CommentBox>
                            ))
                        }

                    </CommentWrapper>
                </ContentWrapper>
                {/* 拉黑 */}
                {
                    openBlock ? <><BlockBoxBG onTouchStart={this.goBackPage} />
                        <BlockBox>
                            <BlockBoxTitle onTouchStart={this.goBackPage}>
                                该作者已经被您拉黑
                            </BlockBoxTitle>
                            <BlockBoxBody onTouchStart={this.goBackPage}>
                                如果想浏览此文章需要解除拉黑
                            </BlockBoxBody>
                            <BlockBoxButton>
                                <BlockBoxButtonLeft onClick={this.goBackPage}>
                                    退出文章
                                </BlockBoxButtonLeft>
                                <BlockBoxButtonRight onClick={this.removeBlock.bind(this, infoAuthorID)}>
                                    解除拉黑
                                </BlockBoxButtonRight>
                            </BlockBoxButton>
                        </BlockBox>
                    </> : ""
                }
                {
                    commentTextFocus && <Motai onClick={this.handleInputBlur} />
                }

                <QuickReply style={{ bottom: commentTextFocus ? '0' : '-10rem' }}>
                    <QuickReplyInput>
                        <i className='iconfont'>&#xe62d;</i>
                        <textarea
                            //value={commentText}
                            ref={r => this.textInput = r}
                            onChange={
                                (e) => { this.setState({ commentText: e.target.value.trim() }) }
                            }
                            confirm-type='send'
                            placeholder='优质评论将会被优先展示'
                        ></textarea>
                    </QuickReplyInput>
                    {
                        !commentText ? <CommentInputButton onClick={this.handleInputFocus} className='buttonFocuseds'>发布</CommentInputButton>
                            : <CommentInputButton onClick={this.submitComment}>发布</CommentInputButton>
                    }
                </QuickReply>
                <QuickReplys>
                    <QuickReplyDiv onClick={this.handleInputFocus}>
                        <i className='iconfont'>&#xe62d;</i>
                        <div>写评论...</div>
                    </QuickReplyDiv>
                    <QuickReplyOperation>
                        <a href="#commentTop">
                            <div style={{ marginLeft: '12%' }}>
                                <i className="iconfont">&#xe7b2;</i>
                            </div>
                        </a>
                        <div className={`${isCollected ? 'active' : ''}`} onClick={this.toggleCollect}>
                            {isCollected ? <i className="iconfont">&#xe79e;</i> : <i className="iconfont">&#xe79d;</i>}
                        </div>
                        <div className={`${isLiked ? 'active' : ''}`} onClick={this.toggleLike}>
                            {isLiked ? <i className="iconfont">&#xe780;</i> : <i className="iconfont">&#xe755;</i>}
                        </div>
                        {this.state.type === "community" &&
                            <div onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "share", title: infoTitle, description: infoContentNews?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/community/communitydetailh5/' + infoId + '/' + 'community', imageUrl: infoListIMG != "" ? infoListIMG : infoImageAppUrl?.split(';')[0] }))
                                if (localStorage.getItem('TASK_TAG') == "点击转发") {
                                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                                }
                            }}>
                                <i className="iconfont">&#xe762;</i>
                            </div>}
                        {this.state.type === "news" &&
                            <div onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "share", title: infoTitle, description: infoContentNews?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/community/communitydetailh5/' + infoId + '/' + 'news', imageUrl: infoListIMG != "" ? infoListIMG : infoImageAppUrl?.split(';')[0] }))
                                if (localStorage.getItem('TASK_TAG') == "点击转发") {
                                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                                }
                            }}>
                                <i className="iconfont">&#xe762;</i>
                            </div>}
                        {this.state.type === "evaluation" &&
                            <div onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "share", title: infoTitle, description: infoContentNews?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/community/communitydetailh5/' + infoId + '/' + 'evaluation', imageUrl: infoListIMG != "" ? infoListIMG : infoImageAppUrl?.split(';')[0] }))
                                if (localStorage.getItem('TASK_TAG') == "点击转发") {
                                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                                }
                            }}>
                                <i className="iconfont">&#xe762;</i>
                            </div>}
                        {this.state.type === "audition" &&
                            <div onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "share", title: infoTitle, description: infoContentNews?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/community/communitydetailh5/' + infoId + '/' + 'audition', imageUrl: infoListIMG != "" ? infoListIMG : infoImageAppUrl?.split(';')[0] }))
                                if (localStorage.getItem('TASK_TAG') == "点击转发") {
                                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                                }
                            }}>
                                <i className="iconfont">&#xe762;</i>
                            </div>}
                        {this.state.type === "lookback" &&
                            <div onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "share", title: infoTitle, description: infoContentNews?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/community/communitydetailh5/' + infoId + '/' + 'lookback', imageUrl: infoListIMG != "" ? infoListIMG : infoImageAppUrl?.split(';')[0] }))
                                if (localStorage.getItem('TASK_TAG') == "点击转发") {
                                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                                }
                            }}>
                                <i className="iconfont">&#xe762;</i>
                            </div>}
                        {this.state.type === "exhibition" &&
                            <div onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "share", title: infoTitle, description: infoContentNews?.substring(0, 2000)?.replace(/<[^>]+>/g, "").substring(0, 100), webUrl: 'https://m.chinaaudio.net.cn/community/communitydetailh5/' + infoId + '/' + 'exhibition', imageUrl: infoListIMG != "" ? infoListIMG : infoImageAppUrl?.split(';')[0] }))
                                if (localStorage.getItem('TASK_TAG') == "点击转发") {
                                    this.userRiChangTaskSign(localStorage.getItem('TASK_ID'), localStorage.getItem('TASK_USER'));
                                }
                            }}>
                                <i className="iconfont">&#xe762;</i>
                            </div>}
                    </QuickReplyOperation>
                </QuickReplys>
                {commentTextFocus ? <div onClick={() => { this.setState({ commentTextFocus: false, }) }} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10 }}></div> : ''}
            </CommunityDetailWrapper >

        )
    }
}

export default CommunityDetail