import React, {
    Component
} from 'react';
import {
    city,
} from '../../common/citySelect/cityJsonApp';
import {
    CascaderView,
} from 'antd-mobile';

class GpsCitySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cityData: '',
            gpsinfo: ''
        }
    }

    componentDidMount() {
        var that = this;

        window.gpsInfo = function (value) {
            that.setState({ gpsinfo: value });
        };

        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "getgpsinfo", value: 2 }))
    }

    render() {
        const {
            // cityData,
            gpsinfo
        } = this.state;
        return (
            <>
                <div style={{ backgroundColor: '#fff' }}>
                    <div
                        style={{ backgroundColor: '#FAFAFA', height: '28px', lineHeight: '28px', paddingLeft: '15px', color: '#203152', fontSize: '12px' }}
                    >
                        GPS定位
                    </div>
                    <div
                        style={{ paddingLeft: '15px', height: '40px', lineHeight: '40px', display: 'flex' }}

                    >
                        <div style={{ fontSize: '14px', color: '#203152' }}>{gpsinfo != '' ? gpsinfo : '未定位'}</div>
                        <div style={{ marginLeft: '30px', color: '#7C8698' }}>
                            <i className='iconfont' style={{ marginRight: '8px', fontSize: '18px' }}>&#xe777;</i>
                        </div>
                        <div style={{ fontSize: '12px', color: '#7C8698' }} onClick={() => {
                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "getgpsinfo" }));
                        }}>
                            重新定位
                        </div>
                    </div>
                </div >
                <CascaderView
                    options={city}
                    loading={false}
                    onChange={(val, extend) => {
                        if (extend.items[1] === undefined) {
                            this.setState({
                                cityData: ''
                            })
                        } else {
                            this.setState({
                                cityData: extend.items[0]?.label + ',' + extend.items[1]?.label,
                                // hqdjPopup1: false
                            })
                            // alert(extend.items[0]?.label + ',' + extend.items[1]?.label);
                            // sessionStorage.setItem("cityDataSelect", extend.items[0]?.label + ',' + extend.items[1]?.label);
                            // window.ReactNativeWebView.postMessage(JSON.stringify({ type: "modalclose" }));

                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "search", title: '', search: JSON.stringify({ type: 1, value: extend.items[0]?.label + ',' + extend.items[1]?.label }) }))
                        }
                    }}
                />
            </>
        )
    }
}

export default GpsCitySelect