import * as constants from './constants';
import {fromJS} from 'immutable';

const defaultState=fromJS({
    showSideBar:false
});

export default(state=defaultState,action)=>{
    if(action.type===constants.SHOW_SIDE_BAR){
        return state.set('showSideBar',action.show)
    }
    return state;
}
