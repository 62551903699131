import * as constants from './constants';
import {
    fromJS
} from 'immutable';

const defaultState = fromJS({
    register: "personregister",
    companyregisterstep: "stepone",
    username: '',
    phoneoremail: '',
    password: '',
    confirmpassword: '',
    readagreement: '',
    usernametips: "4~16位字符，只能是汉字",
    userphoneoremailtips: '请输入安全邮箱、正确手机号码，密码丢失时可通过该邮箱、手机号找回',
    userpwdtips: '6-20位字符，不包括空格',
    userfonfirmpwdtips: '请再输入一次',
    usercheckcodetips: '请输入六位验证码',
    isPersonRegisterOK: false,
    code: false,
});

let _pwd = "";

export default (state = defaultState, action) => {
    if (action.type === constants.PERSON_REGISTER_CLICK) {
        return state.set('register', 'personregister')
    };
    if (action.type === constants.COMPANY_REGISTER_CLICK) {
        return state.set('register', 'companyregister')
    };
    if (action.type === constants.FIRSR_NEXT_STEP_CLICK) {
        return state.set('companyregisterstep', 'tosteptwo')
    };
    if (action.type === constants.SECOND_NEXT_STEP_CLICK) {
        return state.set('companyregisterstep', 'tostepthree')
    }
    if (action.type === constants.THIRD_NEXT_STEP_CLICK) {
        return state.set('companyregisterstep', 'tofinish')
    }
    if (action.type === constants.ON_PERSON_USERNAME_CHANGE) {
        // 个人注册用户名验证
        let elem_username = action.elem;
        let username = elem_username.value;
        let usernamereg = new RegExp("^[a-zA-Z0-9]{4,16}$");
        let result = usernamereg.test(username);
        let tips = "4~16位字符，只能是汉字";
        console.log(result);
        if (result === true) {
            tips = "格式正确";
            elem_username.setAttribute("data-validate", "1");
        }
        return state.set('usernametips', tips);
    }
    if (action.type === constants.ON_PERSON_PHONE_OR_EMALI_CHANGE) {
        // 个人注册手机或邮箱验证
        let elem_userphoneoremail = action.elem;
        let userphoneoremail = elem_userphoneoremail.value;
        let userphoneoremailreg = new RegExp("^[a-zA-Z0-9]{4,16}$");
        let result = userphoneoremailreg.test(userphoneoremail);
        let tips = "请输入安全邮箱、正确手机号码，密码丢失时可通过该邮箱、手机号找回";
        console.log(result);
        if (result === true) {
            tips = "格式正确";
            elem_userphoneoremail.setAttribute("data-validate", "1");
        }
        return state.set('userphoneoremailtips', tips);
    }
    if (action.type === constants.ON_PERSON_USER_PWD_CHANGE) {
        // 个人注册密码验证
        let elem_userpwd = action.elem;
        let userpwd = elem_userpwd.value;
        let userpwdreg = new RegExp("^[a-zA-Z0-9]{4,16}$");
        let result = userpwdreg.test(userpwd);
        let tips = "6-20位字符，不包括空格";
        console.log(result);
        if (result === true) {
            tips = "格式正确";
            elem_userpwd.setAttribute("data-validate", "1");
            _pwd = userpwd;
        }
        return state.set('userpwdtips', tips);
    }
    if (action.type === constants.ON_PERSON_USER_CONFIRM_PWD_CHANGE) {
        // 个人注册确认密码验证
        let elem_userfonfirmpwd = action.elem;
        let userfonfirmpwd = elem_userfonfirmpwd.value;
        let tips = "请再输入一次";
        //let pwd=state.password;
        console.log(_pwd);
        if (userfonfirmpwd === _pwd) {
            tips = "格式正确";
            elem_userfonfirmpwd.setAttribute("data-validate", "1");
        }
        return state.set('userfonfirmpwdtips', tips);
    }
    if (action.type === constants.SEND_CHECK_NUM) {
        // 发送验证码
        return state.set('code', true);
    }
    if (action.type === constants.ON_PERSON_USER_CODE_CHANGE) {
        // 输入验证码
        let elem_usercheckcode = action.elem;
        let usercheckcode = elem_usercheckcode.value;
        let tips = "请输入六位验证码";
        if (usercheckcode) {
            tips = "格式正确";
            elem_usercheckcode.setAttribute("data-validate", "1");
        }
        return state.set('usercheckcodetips', tips);
    }
    if (action.type === constants.ON_CHECK_READ_AGREEMENT_CHANGE) {
        // 个人注册已读用户协议
        let obj = action.elem
        console.log(obj.checked)
        if (obj.checked === true) {
            obj.setAttribute("data-validate", "1");
        } else {
            obj.setAttribute("data-validate", "0");
        }
        console.log(obj.getAttribute("data-validate"))
        return state.set('readagreement', obj.value);
    }
    return state;
}