import styled from 'styled-components';
import {
    themenew
} from '../../../common/color';

export const TopWrapper = styled.div`
  width:100%;
  background: #1677FF;
  position:relative ;
  height:183px;
  padding:1rem 0 0;
`
export const FixItem = styled.div`
  float:right;
  width:2rem;
  height:2rem;
  margin-top:2.5rem;
  margin-right:4%;
  color:#fff;
  i{
    font-size:2rem;
  }
`
export const FixItemLeft = styled.div`
  float:left;
  width:2rem;
  height:2rem;
  margin-top:2.5rem;
  margin-left:4%;
  color:#fff;
  i{
    font-size:2rem;
  }
`

export const BGShading = styled.div`
  position: absolute;
  bottom: -1px;
  margin-bottom:-3px ;
  img{
    width: 100%;
  }
`

export const Protrait = styled.div`
  width:8.7rem;
  height:8.7rem;
  margin:0 auto;
  margin-top:10rem ;
  border-radius:50%;
  border: 0.3rem solid #FFFFFF;
  box-shadow: 0 10px 28px 8px rgba(0,0,0,0.08);
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    width:100%;
    min-height:8.7rem;
    object-fit: cover;
  }
`
export const HeadImg = styled.div`
  position: absolute;
  z-index: 99;
  width: 7.46rem;
  height: 7.46rem;
  top: 6.38rem;
  left: 50%;
  margin-left: -3.73rem;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #FFFFFF;
  box-shadow: 0 1px 24px 0 rgba(0,0,0,0.10);
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
    min-height:7.46rem;
    object-fit: cover;
  }
`
export const EnterpriseName = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.88rem;
  color: ${themenew.text_color};
  margin-bottom: 0.5rem;
`
export const SubTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  color: ${themenew.subtext_color};
  margin-bottom: 1.15rem;
`
export const NumWrapper = styled.div` 
  display:flex;
  width:92%;
  height:4.5rem;
  margin-left:4%;
  margin-top:7.2rem ;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
`
export const BussButtonWrapper = styled.div` 
  display:flex;
  width:92%;
  margin-left:4%;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 0.375rem;
  flex-wrap:wrap;
  padding:1.25rem 4% 0.625rem;
`

export const Fans = styled.div`
  flex:1;
  width:3.75rem;
  height:4rem;
  float:left;
  text-align:center;
  padding-top:0.8rem;
`
export const Num = styled.div`
  color:${themenew.text_color};
  font-size:1.3rem;
`

export const Title = styled.div`
  color:${themenew.subtext_color};
  margin-top:.6rem;
  font-size:0.88rem;
`

export const BussButton = styled.div`
    width: 25%;
    height: 4.875rem;
    text-align:center ;
    margin-bottom:0.625rem ;
    >div{
        margin:0.625rem auto 0.375rem;
        width: 2.5rem;
        height: 2.5rem;
        line-height:2.5rem ;
        border-radius:0.5rem ;
        background-color:#2A83FE ;
        position:relative ;
        >img{
            width:1.5rem ;
            height:1.5rem ;
            margin:0.5rem auto 0;
        }
        >div{
            position:absolute ;
            top:-1.2rem ;
            right:-0.5rem;
        }
    }
    >span{
        font-size: 0.75rem;
        color:${themenew.subtext_color} ;
    }
`