export const ABOUT_US_CLICK='file/ABOUT_US_CLICK';
export const REGISTER_FILE='file/REGISTER_FILE';
export const HELP_CENTER_CLICK='file/HELP_CENTER_CLICK';
export const LINK_US_CLICK='file/LINK_US_CLICK';
export const PRIVACY_CLICK='file/PRIVACY_CLICK';
export const TRADEMARK_CLICK='file/TRADEMARK_CLICK';
export const SERVICE_AGREEMENT_CLICK='file/SERVICE_AGREEMENT_CLICK';
export const COMMUNITY_AGREEMENT_CLICK='file/COMMUNITY_AGREEMENT_CLICK';
export const INFRINGEMENT_CLICK='file/INFRINGEMENT_CLICK';
export const BUSINESS_LICENSE_CLICK='file/BUSINESS_LICENSE_CLICK';
