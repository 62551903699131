/**
 * created by zhangling on 2019/12/19
 */
import axios from 'axios';
import {
    createAsyncTypes
} from './utils'
const BASE_URL = 'https://api.chinaaudio.net.cn/frontend/api'

/**
 * 捕获action 然后发出action
 * action必须有自定义字段
 * @param url
 * @param type
 *
 */

const axios_middleware = store => next => action => {
    // 同时发出action
    // 改造action
    const {
        type,
        request = {}
    } = action;
    const {
        api = {}, params = ''
    } = request;
    const data = params ? params : null;
    // 如果不是异步action 直接派发
    if (JSON.stringify(request) === '{}' || !request) {
        return next(action);
    }
    const [START, SUCCESS, FAIL] = createAsyncTypes(type);
    // 设置get或者post请求
    let options = {
        url: BASE_URL + api.url,
        method: api.method ? api.method : 'get',
        headers: {},
    }
    if (options.method !== 'get') {
        options.headers['Accept'] = 'application/json';
        options.headers['Content-Type'] = 'application/json;charset=UTF-8';
        options.data = data;
    }
    if (options.method === 'get') {
        options.params = data;
    }
    next({
        ...action,
        type: START,
        loading: true
    });
    return axios(options).then((res) => {
        return next({
            ...action,
            type: SUCCESS,
            loading: false,
            result: res,
            data: res.data.Data
        })
    }).catch((error) => {
        return next({
            ...action,
            type: FAIL,
            loading: false,
            error: error
        })
    })
}

export default axios_middleware