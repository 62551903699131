/**
 * created by zhangling on 2020/3/3
 */
import React, {
    Component,
    Fragment
} from 'react';
import {
    Window,
    WindowBtnWrapper,
    WindowCancel,
    WindowSure,
    WindowTip,
    WindowTitle,
    Mask
} from "./style"


export default class Confirm extends Component {
    render() {
        const {
            content,
            onOk,
            onClose,
            hidden
        } = this.props
        return <Fragment>
                {
                    !hidden ? <Mask onClick={onClose}>
                        <Window style={{visibility: hidden ? 'hidden' : 'visible'}} onClick={(e) => {e.stopPropagation()}}>
                            <WindowTitle>
                                <i className="iconfont">&#xe607;</i>提醒<span onClick={onClose}><i className="iconfont">&#xe615;</i></span>
                            </WindowTitle>
                            <WindowTip>
                                {content}
                            </WindowTip>
                            <WindowBtnWrapper>
                                <WindowSure className='cursor' onClick={onOk}>确定</WindowSure>
                                <WindowCancel className='cursor' onClick={onClose}>取消</WindowCancel>
                            </WindowBtnWrapper>
                        </Window>
                    </Mask> : null
                }
            </Fragment>
    }
}