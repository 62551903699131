import React, { Component } from 'react';
import {
    CommunityWrapper,
    StarDetailWrapper,
    ReturnButton,
    StarName,
    StarHead,
    StarImgWrapper,
    IntroduceBox,
    BrandsIntroduceMoreButton,
    NavBarS
} from './style';
import { Carousel, WingBlank } from 'antd-mobile-v2';
import {
    DividingLine,
    ClearFloat,
} from '../../common/style';
import {
    filterHtml,
    chinaTime
} from '../../utils/utils';

import httpAxios from "../../http/httpAxios";
import api from "../../http/api";
const returnButton = require('../../images/return_button.svg');

class starDetail extends Component {
    state = {
        starImages: ['1', '2', '3'],
        starData: [],
        imgHeight: 300,
        introduceMore: false,
    }
    async componentDidMount() {
        //统计查询结果
        await this.getData();
    }

    getData = async () => {
        let params;
        params = {
            "userPID": this.props.match.params.id,
            // "userPID": "5940c1d2-a706-47b3-b5d8-0fada8eaab3b",
        }
        const res = await httpAxios(api.getArtistInfo, params)
        this.setState({
            starData: res.Data,
            starImages: res.Data.info?.PictureList?.split(',')
        })
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    render() {
        const {
            starData,
            introduceMore
        } = this.state;

        return (
            <CommunityWrapper>
                <NavBarS>
                    <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                    <StarName>合作艺人<span>{starData.info?.ChineseName}</span></StarName>
                    <StarHead src={starData.info?.MainPicture} alt="" />
                </NavBarS>
                <div style={{ height: '120px' }}></div>
                <StarImgWrapper>
                    <WingBlank>
                        <Carousel
                            frameOverflow="visible"
                            cellSpacing={10}
                            slideWidth={0.92}
                            // dots={false}
                            autoplay
                            infinite
                        // beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                        // afterChange={index => this.setState({ slideIndex: index })}
                        >
                            {this.state.starImages.map((val, index) => (
                                <div
                                    key={val}
                                    style={{
                                        display: 'flex',
                                        overflow: 'hidden',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative',
                                        borderRadius: '10px',
                                        // top: this.state.slideIndex === index ? -20 : 0,
                                        height: this.state.imgHeight,
                                        boxShadow: '2px 1px 1px rgba(0, 0, 0, 0.2)',
                                    }}
                                >
                                    <img
                                        src={`${val}`}
                                        alt=""
                                        style={{ width: '100%', minHeight: '300px', objectFit: 'cover' }}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </WingBlank>
                </StarImgWrapper>
                <StarDetailWrapper>
                    <IntroduceBox>
                        <h1>艺人简介</h1>
                        {/* <div className={introduceMore ? '' : 'more'} dangerouslySetInnerHTML={{ __html: filterHtml(starData.info?.Introduce) }}></div> */}
                        <div className={introduceMore ? '' : 'more'} dangerouslySetInnerHTML={{ __html: starData.info?.Introduce }}></div>
                        <BrandsIntroduceMoreButton>{!introduceMore ? <span onClick={() => { this.setState({ introduceMore: true }) }}><i className="iconfont">&#xe7b6;</i>展开更多</span> : <span onClick={() => { this.setState({ introduceMore: false }) }}><i className="iconfont">&#xe747;</i>收起内容</span>}</BrandsIntroduceMoreButton>
                    </IntroduceBox>
                    <ClearFloat />
                    <div className='fengexian' />
                    <IntroduceBox>
                        <h1>艺人资料</h1>
                        <div>
                            中文名：{starData.info?.ChineseName}
                            <br />
                            别    名：{starData.info?.EnglishName}
                            <br />
                            国    籍：{starData.info?.Country}
                            <br />
                            民    族：{starData.info?.Nation}
                            <br />
                            出生地：{starData.info?.Birthplace}
                            <br />
                            出生日期：{starData.info?.Birthday}
                            <br />
                            星    座：{starData.info?.Constellation}
                            <br />
                            血    型：{starData.info?.BloodGroup}
                            <br />
                            身    高：{starData.info?.Height}cm
                            <br />
                            体    重：{starData.info?.Weight}公斤
                            <br />
                            毕业院校：{starData.info?.School}
                            <br />
                            职    业：{starData.info?.Profession}
                        </div>
                    </IntroduceBox>
                    <div className='fengexian' />
                    <IntroduceBox>
                        <h1>主要作品</h1>
                        {
                            starData.dianying ? <h1>参演电影</h1> : ''
                        }
                        <table>
                            {
                                starData.dianying?.map((obj, index) => (
                                    <tr key={obj.A} style={{ background: index % 2 == 0 ? '#F9FAFB' : '' }}>
                                        <td style={{ width: '4%' }} /><td>{obj.A}</td> <td>{chinaTime(obj.B, 'month')}</td><td>{obj.C}</td><td>{obj.D}</td><td style={{ width: '4%' }} />
                                    </tr>
                                ))
                            }
                        </table>
                    </IntroduceBox>
                    {
                        starData.dianying ? <div className='fengexian' /> : ''
                    }
                    {
                        starData.dianshiju ? <><IntroduceBox>
                            <h1>参演电视剧</h1>
                            <table>
                                {
                                    starData.dianshiju?.map((obj, index) => (
                                        <tr key={obj.A} style={{ background: index % 2 == 0 ? '#F9FAFB' : '' }}>
                                            <td style={{ width: '4%' }} /><td>{obj.A}</td> <td>{chinaTime(obj.B, 'month')}</td><td>{obj.C}</td><td>{obj.D}</td><td style={{ width: '4%' }} />
                                        </tr>
                                    ))
                                }
                            </table>
                        </IntroduceBox>
                            <div className='fengexian' /></>
                            : ''
                    }
                    {
                        starData.zongyi ? <><IntroduceBox>
                            <h1>综艺节目</h1>
                            <table style={{ width: "100%" }}>
                                {
                                    starData.zongyi?.map((obj, index) => (
                                        <tr key={obj.A} style={{ background: index % 2 == 0 ? '#F9FAFB' : '' }}>
                                            <td style={{ width: '4%' }} /><td>{obj.A}</td> <td>{chinaTime(obj.B, 'month')}</td><td style={{ width: '4%' }} />
                                        </tr>
                                    ))
                                }
                            </table>
                        </IntroduceBox>
                            <div className='fengexian' /></> : ''
                    }
                    {
                        starData.yinyue ? <><IntroduceBox>
                            <h1>音乐</h1>
                            <table>
                                {
                                    starData.yinyue?.map((obj, index) => (
                                        <tr key={obj.A} style={{ background: index % 2 == 0 ? '#F9FAFB' : '' }}>
                                            <td style={{ width: '4%' }} /><td>{obj.A}</td> <td>{chinaTime(obj.B, 'month')}</td><td>{obj.C}</td><td style={{ width: '4%' }} />
                                        </tr>
                                    ))
                                }
                            </table>
                        </IntroduceBox>
                            <div className='fengexian' /></> : ''
                    }
                    {
                        starData.huodong ? <>
                            <IntroduceBox>
                                <h1>活动</h1>
                                <table>
                                    {
                                        starData.huodong?.map((obj, index) => (
                                            <tr key={obj.A} style={{ background: index % 2 == 0 ? '#F9FAFB' : '' }}>
                                                <td style={{ width: '4%' }} /><td>{obj.A}</td> <td>{chinaTime(obj.B, 'month')}</td><td style={{ width: '4%' }} />
                                            </tr>
                                        ))
                                    }
                                </table>
                            </IntroduceBox>
                            <div className='fengexian' />
                        </> : ''
                    }
                    {
                        starData.huojiangjingli ? <>
                            <IntroduceBox>
                                <h1>获奖经历</h1>
                                <table>
                                    {
                                        starData.huojiangjingli?.map((obj, index) => (
                                            <tr key={obj.A} style={{ background: index % 2 == 0 ? '#F9FAFB' : '' }}>
                                                <td style={{ width: '4%' }} /><td>{obj.A}</td> <td>{chinaTime(obj.B, 'month')}</td><td style={{ width: '4%' }} />
                                            </tr>
                                        ))
                                    }
                                </table>
                            </IntroduceBox>
                            <div className='fengexian' />
                        </> : ''
                    }
                    {
                        starData.daiyan ?
                            <IntroduceBox>
                                <h1>代言、广告项目</h1>
                                <table>
                                    {
                                        starData.daiyan?.map((obj, index) => (
                                            <tr key={obj.A} style={{ background: index % 2 == 0 ? '#F9FAFB' : '' }}>
                                                <td style={{ width: '4%' }} /><td>{obj.A}</td><td style={{ width: '4%' }} />
                                            </tr>
                                        ))
                                    }
                                </table>
                            </IntroduceBox> : ''
                    }

                </StarDetailWrapper>
            </CommunityWrapper>
        )
    }
}

export default starDetail