/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleOneImg,
    NewsPub,
    VideoImg,
    VideoIcon
} from '../../../common/generalStyle';
import {
    ReturnButton,
    TopWrapper,
    BGWrapper,
    BGShading,
    HeadImg,
    Focused,
    Focus,
    EnterpriseName,
    SubTitle,
    NewNumWrapper,
    Fans,
    Num,
    Title,
    LabelOne,
    LabelTwo,
    LabelThree,
} from './ContentStyle';

import Label from "./PersonalLabel";
import httpAxios from "../../../http/httpAxios";
import httpAxios2 from "../../../http/httpAxios2";
import api from "../../../http/api";
import {
    startMinsTime
} from '../../../utils/utils';
import {
    message
} from 'antd';
import axios from 'axios';
const playSvg = require('../../../images/play.svg');
const returnButton = require('../../../images/return_button.svg')

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            // readType: '2',
            // listType: '1',
            targetID: '4',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],

            focused: false,
            commpanyBrands: [],
            moreFocus: false,
            companyShop: [],
            companyExperiences: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        pageIndex = 0;

        this.getComType();

        // alert(this.props.info?.MediaMan);

        if (this.props.info?.MediaMan == undefined || this.props.info?.MediaMan == null) {
            this.state.targetID = "1";
        }

        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }
    componentWillReceiveProps(next) {
        this.checkFocus();
        // if (this.props.info?.UserBID) {
        //     this.checkFocus(this.props.info?.UserBID);
        // }
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    getListRefresh = async (targetID, readType, listType) => {
        window.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    //关注作者
    addFocus = async (author) => {
        if (!this.props.userPID) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (this.props.userPID === author) {
            message.info('用户不能关注自己');
            return
        }
        const {
            focused
        } = this.state;
        if (focused) {
            const res = await httpAxios(api.removeUserFocus, {
                User1: this.props.userPID,
                User2: author
            })
            if (res) {
                this.setState({
                    focused: false
                })
            }
        } else {
            const res = await httpAxios(api.addUserFollow, {
                User1: this.props.userPID,
                User2: author
            })
            if (res) {
                this.setState({
                    focused: true
                })
            }
        }

    }
    //移除关注
    removeFocus = async (author) => {
        const {
            focused
        } = this.state;
        if (!focused) return
        const res = await httpAxios2(api.removeUserFocus, {
            User1: this.props.userPID,
            User2: author,
        })
        if (res) {
            this.setState({
                focused: false
            })
        }
    }
    //检查是否已经关注
    checkFocus = async (id) => {
        if (sessionStorage.getItem("au_userPID") === null) return;
        if (sessionStorage.getItem("au_authorID") === null) return;

        const res = await axios.get(api.checkUserFocus.url + '?userID=' + sessionStorage.getItem("au_userPID") + '&focusUserID=' + sessionStorage.getItem("au_authorID"))
        if (res && res.status === 200) {
            if (res.data.Code !== 404) {
                this.setState({
                    focused: true
                })
            }
        }
    }

    getList = async (targetID, readType, listType) => {

        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;
        if (!targetID) targetID = this.state.targetID;

        let params;
        params = {
            "UserPID": this.props.authorID,
            "Type": targetID,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
        }

        const res = await httpAxios2(api.userCommunityRead, params);
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                        targetID,
                    })
                }
            } else {
                this.setState({
                    data: [],
                    targetID,
                })
                this.state.hasMore = false;
            }


            if (this.state.data.length === this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    getComType = async () => {
        const res = await httpAxios(api.newsType);
        let typeKeys = {};
        if (res) {
            res.Data.map(i => {
                typeKeys[i.ID] = i.Title
            })
            this.setState({
                comTypes: res.Data,
                typeKeys: typeKeys
            })
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            info,
        } = this.props;
        const {
            data,
            comTypes,
            livedata,
            targetID,
            focused,
            commpanyBrands,
            moreFocus,
            companyShop,
            companyExperiences
        } = this.state;
        return (<div>
            <TopWrapper>
                {
                    info?.EvaluationMan && <LabelThree>专业评测师</LabelThree>
                }
                {
                    info?.MediaMan && <LabelOne>媒体人</LabelOne>
                }
                {
                    info?.YinYueMan && <LabelTwo>音悦人</LabelTwo>
                }
                <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                {focused ? <Focused onClick={this.removeFocus.bind(this, info?.UserPID)}>已关注</Focused> : <Focus onClick={this.addFocus.bind(this, info?.UserPID)}>关注</Focus>}
                <BGWrapper></BGWrapper>
                {/* <BGShading><img src={require("../../../images/BGShading.png")} /></BGShading> */}
                <HeadImg><img src={info?.HeadImg} alt="" /></HeadImg>
                <div style={{ height: '3.3rem' }}></div>
                <EnterpriseName>{info?.PNickName}</EnterpriseName>
                <SubTitle>
                    {info?.WriteSB}
                </SubTitle>
                <NewNumWrapper>
                    <div className='fans' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "他的关注", weburl: "/HisFans" + "?" + info?.UserPID })) }}>
                        <div className='num'>{info?.FollowVal || 0}</div>
                        <div className='title'>关注</div>
                    </div>
                    <div className='fans' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "他的粉丝", weburl: "/HisFocus" + "?" + info?.UserPID })) }}>
                        <div className='num'>{info?.FansVal || 0}</div>
                        <div className='title'>粉丝</div>
                    </div>
                    <div className='fans' onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "他的点赞", weburl: "/MyLike" + "?" + info?.UserPID })) }}>
                        <div className='num'>{info?.ZanTotal || 0}</div>
                        <div className='title'>点赞</div>
                    </div>
                </NewNumWrapper>
                <div style={{ height: '2rem', }}>
                    <Label cb={this.getListRefresh} list={comTypes} MediaMan={info?.MediaMan} />
                </div>
            </TopWrapper>

            {/* <div style={{ height: '8.5rem' }}></div> */}
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            {targetID === '1' && <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/community' + '?' + this.props.userPID, })); }}>
                                    {
                                        obj.ShowType === 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    <img src={obj.ListIMG} alt="" />
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType === 0 || obj.ShowType === 1 ?
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                            <NewsImgWrap><img src={obj.ListIMG} alt="" /></NewsImgWrap>
                                                        </NewsItem >
                                                        :
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle>{obj.Title}</NewsTitle>
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl?.split(';').map((image, index) => (
                                                                        index < 3 && <div><img src={image} alt="" /></div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                }
                                            </>
                                    }
                                    <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span></NewsPub>
                                </a>
                            </NewsDetailWrapper>
                            }
                            {targetID === '2' && <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/evaluation' + '?' + this.props.userPID, })); }}>
                                    {
                                        obj.ShowType === 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    <img src={obj.CoverIMG} alt="" />
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType === 0 || obj.ShowType === 1 ?
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                            <NewsImgWrap><img src={obj.CoverIMG} alt="" /></NewsImgWrap>
                                                        </NewsItem >
                                                        :
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle>{obj.Title}</NewsTitle>
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl?.split(';').map((image, index) => (
                                                                        index < 3 && <div><img src={image} alt="" /></div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                }
                                            </>
                                    }
                                    <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPub>
                                </a>
                            </NewsDetailWrapper>
                            }
                            {targetID === '3' && <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/audition' + '?' + this.props.userPID, })); }}>
                                    <NewsItemOther div key={obj.ID} >
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.PosterImg} alt="" />
                                            <VideoIcon>
                                                <img src={playSvg} alt="" />
                                            </VideoIcon>
                                        </VideoImg>
                                    </NewsItemOther >
                                    <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPub>
                                </a>
                            </NewsDetailWrapper>
                            }
                            {targetID === '4' && <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/exhibition' + '?' + this.props.userPID, })); }}>
                                    {
                                        obj.ShowType === 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    <img src={obj.PosterImg} alt="" />
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            : <NewsItem div key={obj.ID} >
                                                <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                <NewsImgWrap><img src={obj.PosterImg} alt="" /></NewsImgWrap>
                                            </NewsItem >
                                    }
                                    <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ViewNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPub>
                                </a>
                            </NewsDetailWrapper>
                            }
                            {targetID === '5' && <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/news' + '?' + this.props.userPID, })); }}>
                                    {
                                        obj.ShowType === 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    <img src={obj.PosterLandscape} alt="" />
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType === 0 || obj.ShowType === 1 ?
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                            <NewsImgWrap><img src={obj.PosterLandscape} alt="" /></NewsImgWrap>
                                                        </NewsItem >
                                                        :
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle>{obj.Title}</NewsTitle>
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl?.split(';').map((image, index) => (
                                                                        index < 3 && <div><img src={image} alt="" /></div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                }
                                            </>
                                    }
                                    <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span></NewsPub>
                                </a>
                            </NewsDetailWrapper>
                            }
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content