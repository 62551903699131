import React, {
    Component, createRef
} from 'react';

import {
    Wrapper,
} from './AddOnlineStoreStyle';

import {
    Form,
    Input,
    Select,
    Button
} from 'antd';

import reloadIMG from '../../../../images/addimg.png'

import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';

class AddExperienceStore extends Component {
    state = {
        loading: false,
        BrandLogo: null,
        userPID: ''
    }

    formRef = createRef();

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        window.logoImage = function (imageUrl) {
            if (imageUrl != "") {
                that.setState({
                    BrandLogo: imageUrl
                })
            }
        }

        //保存
        window.saveInfo = function () {
            that.formRef.current.submit();
        };

        //编辑信息
        var id = this.props.location.search.replace('?', '').trim();
        if (id != '') {
            httpAxios(api.getCompanyShopById, {
                id: id
            }).then(res => {

                if (res.Code == 200) {
                    let item = res.Data;
                    console.log(item, 321)

                    this.formRef.current.setFieldsValue({
                        title: item.ShopName,
                        description: item.ShopLink == null ? '' : item.ShopLink.split("://")[1],
                    });

                    this.setState({ BrandLogo: item.ShopImage });
                }
            })
        }
    }

    //显示上传图片
    handleLogoImage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadLogoImage" }));
    }

    addCompanshop = async (values) => {
        var id = this.props.location.search.replace('?', '').trim();
        if (id == ''){
            id = 0
        }
        var data = {};
        data["ShopName"] = values.title;
        data["ShopLink"] = values.prefix + values.description;
        if (this.state.BrandLogo !== "") {
            data["ShopImage"] = this.state.BrandLogo;
        }

        const res = await httpAxios(api.addCompanyShop, {
            ID:id,
            UserBID: this.state.userPID,
            ShopName: data.ShopName,
            ShopLink: data.ShopLink,
            ShopImage: data.ShopImage
        })

        if (res.Code == 200) {
            // alert("save ok")
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }


    render() {
        const {
            BrandLogo,
            //httpSelect
        } = this.state;

        console.log(BrandLogo, 123);

        const {
            Option
        } = Select;

        const prefixSelector = (
            <Form.Item name="prefix" noStyle>
                <Select style={{ width: 90 }} >
                    <Option value="Http://">Http://</Option>
                    <Option value="Https://">Https://</Option>
                </Select>
            </Form.Item>
        );

        return (
            <Wrapper>
                <Form
                    layout="vertical"
                    ref={this.formRef}
                    initialValues={{ prefix: "Http://" }}
                    onFinish={(values) => this.addCompanshop(values)}
                >
                    <Form.Item label="添加店铺封面图">
                        <img src={BrandLogo || reloadIMG} onClick={this.handleLogoImage} alt="" />
                    </Form.Item>
                    <Form.Item label="店铺名称" name="title" rules={[{ required: true, message: '请输入店铺名称!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="店铺链接" name="description" rules={[{ required: true, message: '请输入店铺链接!' }]}>
                        <Input addonBefore={prefixSelector} />
                    </Form.Item>
                </Form>
            </Wrapper>
        )
    }
}

export default AddExperienceStore