import React, { Component } from 'react';
import {
    CommunityDetailWrapper,
    ContentWrapper,
    Title,
    Content,
    SubTitle
} from './style';

import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";
import {
    convertTime
} from '../../../../utils/utils';

class MyInvitationDetail extends Component {

    state = {
        invitationData: [],
        isFocus: false,
        userPID:'',
    }
    async componentDidMount() {
        var that = this;
		window.userInfo = function (data, params) {
			that.setState({
				userPID: data.UserPID
			})
            that.getData(data.UserPID);
		}
        //统计查询结果
    }

    getData = async (id) => {
       
        let params;
        params = {
            "ID": this.props.match.params.id,
            "userPID":id,
        }
        const res = await httpAxios(api.getArtistInvitationDetailSelf, params)
        this.setState({
            invitationData: res.Data,
        })
    }

    setArtistFocus = async () => {
        let params;
        params = {
            "ArtistInvitationID": this.state.invitationData.ID,
            "UserPID": this.state.invitationData.UserPID
        }
        const res = await httpAxios(api.setArtistFocus, params);
        if (res.Code === 200) {
            alert(res.Message);
            this.setState({
                isFocus: true,
            })
        }
    }


    render() {
        const {
            invitationData,
        } = this.state;
        
        return (
            <CommunityDetailWrapper>
                <ContentWrapper>
                    <Title>{invitationData?.Title}</Title>
                    <SubTitle>
                        <span /><h2>邀约金额</h2><h1>{invitationData?.MoneyRange}</h1>
                    </SubTitle>
                    <SubTitle>
                        <span /><h2>邀约时间</h2><h1> {convertTime(invitationData?.BeginDate, 'day')}-{convertTime(invitationData?.EndDate, 'day')}</h1>
                    </SubTitle>
                    <SubTitle>
                        <span /><h2>邀约地点</h2><h1>{invitationData?.Place}</h1>
                    </SubTitle>
                    <SubTitle>
                        <span /><h2>邀约详情</h2>
                    </SubTitle>
                    <Content>
                        {invitationData?.Detail}
                    </Content>
                </ContentWrapper>
            </CommunityDetailWrapper>
        )
    }
}

export default MyInvitationDetail