import React, { Component } from 'react';
import {
    AdvertisingWrapper,
} from './style';
const advertisingimg = require('../../images/advertisingimg.png');

export default class Advertising extends Component {
    state = {
        timer: null,
    }
    componentDidMount() {
        this.state.timer = setInterval(async () => {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "advertising",  weburl: "/advertising" }));
        }, 5000);
    }

    render() {
        return (
            <AdvertisingWrapper>
                <img src={advertisingimg} alt="开屏广告" />
            </AdvertisingWrapper>
        )
    }
}
