/**
 * created by zhangling on 2019/12/19
 */

import { fromJS } from 'immutable';
import { GET_HOME_NEWS } from './constants.js';

// 这里的defaultState定义了展示组件所需字段的初始状态
const defaultState = fromJS({
    homeNews: {
        textNews: [],
        picNews: [],
        hotNews: [],
        scrollPics: []
    }
});

const homeReducer = (state = defaultState, action) => {
    switch (action.type) {
        // 这个成功的type字段是写了公共方法自动添加的 所以我们只需要写一个基础的type
        // 这里把异步HTTP action获取的数据加入state 然后容器组件(Ctnr)可以获取
        case `${GET_HOME_NEWS}_SUCCESSED`: {
            const data = action.data || {};
            return state.set('homeNews', data)
        }
        default: {
            return state
        }
    }
}

export default homeReducer;
