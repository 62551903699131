import React, { Component } from 'react';
import {
    Tabs
} from './LabelStyle';

class Label extends Component {

    state = {
        activeKey: '0'
    }
    handleClick = (id) => {
        this.setState({
            activeKey: id
        })
        this.props.cb && this.props.cb(id, null, null);
        //返回顶部
        //window.scrollTo( 0, 0 );
    }
    render() {
        return (
            <Tabs>
                <div className={this.state.activeKey === '0' ? 'selected' : ''} onClick={this.handleClick.bind(this, '0')}>
                    <span>推荐</span>
                    {
                        this.state.activeKey === '0' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '1' ? 'selected' : ''} onClick={this.handleClick.bind(this, '1')}>
                    <span>资讯</span>
                    {
                        this.state.activeKey === '1' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '2' ? 'selected' : ''} onClick={this.handleClick.bind(this, '2')}>
                    <span>展会</span>
                    {
                        this.state.activeKey === '2' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '3' ? 'selected' : ''} onClick={this.handleClick.bind(this, '3')}>
                    <span>评测</span>
                    {
                        this.state.activeKey === '3' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '4' ? 'selected' : ''} onClick={this.handleClick.bind(this, '4')}>
                    <span>视{'&'}音</span>
                    {
                        this.state.activeKey === '4' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '5' ? 'selected' : ''} onClick={this.handleClick.bind(this, '5')}>
                    <span>社区</span>
                    {
                        this.state.activeKey === '5' ? <div /> : ''
                    }
                </div>
                <div className={this.state.activeKey === '7' ? 'selected' : ''} onClick={this.handleClick.bind(this, '7')}>
                    <span>直播回看</span>
                    {
                        this.state.activeKey === '7' ? <div /> : ''
                    }
                </div>
            </Tabs>
        )
    }
}

export default Label;
