import React, { Component } from 'react';
import {
    Wrapper,
    Auto,
    Title1
} from './FileStyle';

export default class CancellationText extends Component {
    render() {
        return (
            <Wrapper>
                <Auto><p>	中乐之声在此特别提醒，在您使用中乐之声提供的账户注销服务前，请务必审慎阅读《中乐之声账户注销协议》（以下称"《注销协议》"）的全部条款、充分理解各条款内容后再点击同意，特别是免除或者限制中乐之声责任的条款、对您的权利产生限制的条款、及其他以加粗下划线标识的重要条款，这些条款将尽可能以加粗及下划线方式标识。</p></Auto>
                <Auto><p>	【特别提示】当您按照注销页面提示填写信息、同意本《注销协议》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销协议》的全部内容。阅读本《注销协议》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止上账户注销程序。</p></Auto>
                <Title1>1、如果您确认注销中乐之声账户，您的账户需同时满足以下条件：</Title1>
                <Auto><p>	你所申请注销的中乐之声账户应当是您依照中乐之声相关规则注册并由中乐之声提供给您本人的账户。您应当依照中乐之声规定的程序或网站上的提示进行中乐之声账户注销。</p>
                    <p>	账户在中乐之声平台无应当履行而尚未履行完毕的义务。</p>
                    <p>	账户无任何纠纷，包括投诉举报或被投诉举报。账户为正常使用中的账户且当前账户未处于任何限制状态。</p>
                    <p><span>	您提交注销账户申请后，该申请不可撤销，因此请您谨慎操作。</span></p>
                </Auto>
                <Title1>2、在中乐之声账户注销审核期间，如果您的账户涉及或可能涉及争议、纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，中乐之声有权自行中止或终止本中乐之声账户的注销而无需另行得到您的同意。</Title1>
                <Title1>3、中乐之声账户注销将导致中乐之声终止为您提供相关服务，同时您将会丧失以下权益：</Title1>
                <Auto><p>	您将无法登录、使用本中乐之声账户;且一旦完成账户注销，您的个人身份信息、账户信息将会被清空且无法恢复，您的账号将不可被检索、访问。</p>
                    <p>	您在注销账户前发布的内容（包括但不限于文章、链接、评论等内容）在一定期限内将继续保留在中乐之声平台中，且您在使用中乐之声服务过程中根据《中乐之声软件服务协议》及其他规则对中乐之声进行的知识产权授权仍然有效;但您在注销账户前使用的头像、账号、昵称等信息将被释放，且允许其他第三方另行在中乐之声平台上进行注册使用。</p>
                    <p><span>	您理解并同意，中乐之声账户一旦被注销将不可恢复，中乐之声无法协助您重新恢复前述权益，请您在操作之前，确保您的账户无任何纠纷，并自行备份中乐之声账户相关的所有信息和数据，否则您应自行承担因所需信息和数据丢失所产生的风险。</span></p></Auto>
                <Title1>4、如在账户注销后，中乐之声发现或者接到其他第三方投诉举报您在注销账户前的行为存在违法违规或其他侵权情形的，中乐之声有权依据法律法规等相关规定进行处理。注销本中乐之声账户并不代表本中乐之声账户注销前的账户行为和相关责任得到豁免或减轻。</Title1>
            </Wrapper>
        )
    }
}