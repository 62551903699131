import React, { Component } from 'react';
import {
    MoreFilterWrapper,
    FilterContent,
    FilterContentDeletions,
    FilterContentlist,
    MoreButtonBG,
    MoreButton,
    MoreFilterRight,
} from '../style';

import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";

class StarMore extends Component {
    state = {
        sexs: [],
        country: [],
        profession: [],
        searchList: [],
        searchCount: 0
    }

    async componentDidMount() {
        // alert(localStorage.getItem("searchList"));
        //存储的选择项
        // let tempSearchData = [];
        // if (localStorage.getItem("searchList") != null) {
        //     tempSearchData = JSON.parse(localStorage.getItem("searchList"));
        //     this.setState({ searchList: tempSearchData });
        // }

        //数据列表
        let temp = [];
        temp.push({ name: "男", selected: false });
        temp.push({ name: "女", selected: false });
        this.setState({ sexs: temp });

        temp = [];
        temp.push({ name: "大陆", selected: false });
        temp.push({ name: "港台地区", selected: false });
        temp.push({ name: "国外", selected: false });
        this.setState({ country: temp });

        temp = [];
        temp.push({ name: "演员", selected: false });
        temp.push({ name: "歌手", selected: false });
        temp.push({ name: "舞蹈演员", selected: false });
        temp.push({ name: "导演", selected: false });
        temp.push({ name: "制作人", selected: false });
        temp.push({ name: "录音师", selected: false });
        temp.push({ name: "片场工作人员", selected: false });
        temp.push({ name: "其他", selected: false });
        this.setState({ profession: temp });

        //统计查询结果
        await this.getSearchCount();
    }

    setSex = (name, select) => {
        this.state.sexs.forEach(element => {
            if (element.name === name) {
                element.selected = !select;
            }
        });

        this.setState({ sexs: this.state.sexs });

        //标签控制
        if (select) {
            this.removeOneLabel(name);
        }
        else {
            this.addOneLabel(name);
        }

        //统计查询结果
        this.getSearchCount();
    }

    //选择国家列表
    setCountry = (name, select) => {
        this.state.country.forEach(element => {
            if (element.name === name) {
                element.selected = !select;
            }
        });

        this.setState({ country: this.state.country });

        //标签控制
        if (select) {
            this.removeOneLabel(name);
        }
        else {
            this.addOneLabel(name);
        }

        //统计查询结果
        this.getSearchCount();
    }


    //选择职业列表
    setProfession = (name, select) => {
        this.state.profession.forEach(element => {
            if (element.name === name) {
                element.selected = !select;
            }
        });

        this.setState({ profession: this.state.profession });

        //标签控制
        if (select) {
            this.removeOneLabel(name);
        }
        else {
            this.addOneLabel(name);
        }

        //统计查询结果
        this.getSearchCount();
    }

    //清除数据
    clearAll = () => {
        this.setState({ searchList: [] });

        //国家
        this.state.country.forEach(element => {
            element.selected = false;
        });

        this.setState({ country: this.state.country });

        //性别
        this.state.sexs.forEach(element => {
            element.selected = false;
        });


        this.setState({ sexs: this.state.sexs });

        //职业
        this.state.profession.forEach(element => {
            element.selected = false;
        });

        this.setState({ profession: this.state.profession });

        //统计查询结果
        this.getSearchCount();
    }

    //删除单个标签数据
    removeOneLabel = (name) => {
        let tempData = [];
        this.state.searchList.forEach(item => {
            if (item != name) {
                tempData.push(item);
            }
        });

        this.setState({ searchList: tempData });
    }

    //增加单标签数据
    addOneLabel = (name) => {
        let tempData = [name, ...this.state.searchList];

        this.setState({ searchList: tempData });
    }

    //删除已选择标签
    removeOneSelectLabel = (name) => {
        //国家
        this.setCountry(name, true);
        //性别
        this.setSex(name, true);
        //职业
        this.setProfession(name, true);

        //统计查询结果
        this.getSearchCount();
    }

    //获取查询统计
    getSearchCount = async () => {
        let country = [];
        this.state.country.forEach(item => {
            if (item.selected) {
                country.push(item.name);
            }
        });

        let sex = [];
        this.state.sexs.forEach(item => {
            if (item.selected) {
                sex.push(item.name);
            }
        });

        let profession = [];
        this.state.profession.forEach(item => {
            if (item.selected) {
                profession.push(item.name);
            }
        });

        let params = {
            country: country.length === 0 ? "0" : country.toString(),
            sex: sex.length === 0 ? "0" : sex.toString(),
            profession: profession.length === 0 ? "0" : profession.toString(),
        };

        sessionStorage.setItem("searchStar", JSON.stringify(params));

        const res = await httpAxios(api.getArtistInfoListSearchCount, params);
        if (res.Code === 200) {
            this.setState({ searchCount: res.Data });
        }
    }

    render() {
        const {
            sexs,
            country,
            profession,
            searchList,
            searchCount
        } = this.state;

        return (
            <MoreFilterWrapper>
                <div style={{ position: 'fixed', top: 0, paddingTop: '0.45rem', backgroundColor: '#fff', minWidth: '100%', zIndex: '10' }}>
                    <FilterContentDeletions onClick={() => this.clearAll()}><span>清除</span><i className='iconfont'>&#xe748;</i></FilterContentDeletions>
                    <FilterContent>
                        {
                            searchList.map(item => (
                                <FilterContentlist key={'s' + item}>{item}<i className='iconfont' onClick={() => { this.removeOneSelectLabel(item) }}>&#xe74c;</i></FilterContentlist>
                            ))
                        }
                        <div style={{ width: '5rem', flexShrink: '0' }}></div>{/* 作用是调整最后的标签不被清除按钮遮住 */}
                    </FilterContent>
                </div>

                <MoreFilterRight>
                    性别
                    <div />
                    {
                        sexs.map((item) => (
                            <span className={item.selected === true ? 'select' : ''} onClick={() => { this.setSex(item.name, item.selected) }} key={item.name}>{item.name}</span>
                        ))
                    }
                    <div />
                    地区
                    <div />
                    {
                        country.map((item) => (
                            <span className={item.selected === true ? 'select' : ''} onClick={() => { this.setCountry(item.name, item.selected) }} key={item.name}>{item.name}</span>
                        ))
                    }
                    <div />
                    职业
                    <div />
                    {
                        profession.map((item) => (
                            <span className={item.selected === true ? 'select' : ''} onClick={() => { this.setProfession(item.name, item.selected) }} key={item.name}>{item.name}</span>
                        ))
                    }
                </MoreFilterRight>

                <MoreButtonBG>
                    <MoreButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "search", title: '更多筛选条件', weburl: "/star/AllStarList", search: sessionStorage.getItem("searchStar") })) }}>
                        共{searchCount}位明星
                    </MoreButton>
                </MoreButtonBG>
            </MoreFilterWrapper>
        )
    }
}

export default StarMore