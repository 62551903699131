import styled from 'styled-components';
import {
  themenew
} from '../../common/color';

export const MoreFilterWrapper = styled.div`
    .unchecked{
        display: none;
    }
`
export const FilterContent = styled.div`
    width: 100%;
    height: 2.5rem;
    background: #fff;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    ::-webkit-scrollbar{
        width: 0;
        height: 0;
        display: none;
    } 
`
export const MoreButtonBG = styled.div`
    width: 100%;
    height: 6.25rem;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 -2px 4px 0 rgba(2,4,51,0.08);
    z-index: 10;
`
export const MoreButton = styled.div`
    background-image: linear-gradient(135deg,#0060ec 0%,#B82cfc 100%);
    border-radius: 6px;
    height: 3.125rem;
    color: #fff;
    width: 88%;
    margin: 0 auto;
    text-align: center;
    font-size: 0.875rem;
    line-height: 3.125rem;
    margin-top: 0.75rem;
`
export const MoreFilterTab = styled.div`
    width: 23.5%;
    background: #f8f9fb;
    height: auto;
    position: fixed;
    top:3rem;
    div{
        height: 3.6rem;
        line-height: 3.6rem;
        text-align: center;
        font-size: 0.875rem;
        color: ${themenew.text_color};
    }
    .select{
        background: #fff;
    }
`

export const FilterContentDeletions = styled.div`
    position: fixed;
    right: 0;
    width: 4.5rem;
    height: 2.5rem;
    line-height: 2rem;
    background: #fff; 
    padding-right: 1rem;
    i{
        font-size: 1.3rem;
        color: ${themenew.subtext_color};
        float: right;
    }
    span{
        font-size: 0.875rem;
        color: ${themenew.text_color};
        float: right;
    }
`
export const MoreFilterRight = styled.div`
    width: 100%;
    min-height: 90%;
    background: #fff;
    margin-top: 3rem;
    overflow:hidden;
    padding: 1rem 7% 8rem;
    font-size: 1.375rem;
    color: #262626;
    z-index: 1;
    >div{
        font-size: 1rem;
        margin-top: 1.375rem;
        margin-bottom: 1rem;
        font-weight: 600;
    }
    >span{
        font-size: 0.75rem;
        color: ${themenew.text_color};
        min-width: 30%;
        display: inline-block;
        background: #f8f9fb;
        text-align: center;
        height: 2.5rem;
        padding: 0 0.5rem;
        line-height: 2.5rem;
        border-radius: 0.25rem;
        margin: 1.65%;
    }
    .select{
        background: #ebf1ff;
        font-weight: 600;
        color: ${themenew.link_color};
    }
    
`
export const MoreFilterRightBrands = styled.div`
    width: 76.5%;
    min-height: 90%;
    background: #fff;
    position: absolute;
    top:3rem;
    right: 0;
    overflow:hidden;
    padding: 1rem 0 8rem;
    font-size: 1.375rem;
    color: #262626;
    >span{
        margin-left: 1rem;
        margin-bottom: 0.75rem;
        display: block;
    }
    .letter{
        background: #f6f6f6;
        height: 1.75rem;
        color: #4a4a4a;
        font-size: 1rem;
        padding-left: 1rem;
        line-height: 1.75rem;
    }
    .brands{
        width: 95%;
        background: #fff;
        margin-left: 5%;
        height: 4.375rem;
        line-height: 4.375rem;
        border-bottom: 0.5px solid #e1e1e1;
        display: flex;
        align-items: center;
        .brandsimg{
            width: 2.875rem;
            height: 2.875rem;
            overflow:hidden;
            display:flex;
            justify-content: center;
            align-items: center;
            float: left;
            margin-right: 0.75rem;
            border-radius: 50%;
            >img{ 
                width:100%;
                min-height:2.875rem;
                object-fit: cover;
            }
        }
        .brandsname{
            width: 55%;
            line-height: 1.56rem !important;
            font-size: 1.125rem;
        }
        .brandscheckbox{
            width: 3.125rem;
            text-align: center;
            margin-left: 0.25rem;
        }
    }
`
export const FilterContentDeletion = styled.div`
    position: absolute;
    right: 0;
    width: 4.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    background: #fff; 
    padding-right: 1rem;
    i{
        font-size: 1.3rem;
        color: ${themenew.subtext_color};
        float: right;
    }
    span{
        font-size: 0.875rem;
        color: ${themenew.text_color};
        float: right;
    }
`
export const FilterContentlist = styled.div`
        height: 1.25rem;
        line-height: 1.25rem;
        flex-shrink: 0;
        background: #f3f5f9;
        padding: 0 0.5rem;
        border-radius: 0.25rem;
        margin-left: 0.75rem;
        margin-top: 0.5rem;
        font-size: 0.75rem;
        >i{
            float: right;
        }
`
export const CountryList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    .hidden {
    display: none;
    }
    .fengexian{
      width:100%;
      height: .5rem;;
      background-color: #F6F6F6;
      margin-bottom: .5rem;
    }
    #app {
    height: 100%;
    font-size: 14px;
    }

    [data-reactroot] {
    height: 100%;
    }
`
export const NavBar = styled.div`
  width:100%;
  height:9.5rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  //box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.375rem;
    color:${themenew.text_color};
    margin-top:3.6rem;
    font-weight:700;
  }
`
export const NavBarS = styled.div`
  width:100%;
  height:120px;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  //box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.375rem;
    color:${themenew.text_color};
    margin-top:3.6rem;
    font-weight:700;
  }
`
export const MoreText = styled.div`
  font-size: 1rem;
  float: right;
  margin-right: 1.3rem;
  margin-top:3.6rem;
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:4%;
  margin-top:3.5rem;
  float:left;
`

export const Search = styled.div`
	width:92%;
	height:2.8rem;
	line-height:2.8rem;
  margin-top: 13px;
	//margin:0 auto 1.1rem;
	border: 1px solid #ECECEC;
	box-shadow: -1px 2px 8px -2px rgba(0,0,0,0.15);
	border-radius:0.375rem;
	font-size:1rem;
	background-color:#fff;
	margin-left: 4%;
  input{
    width: 86%;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		height: 2.65rem;
		//display:block;
		float:left;
		color: #BEBEBE;
    border: 0;
    color: ${themenew.text_color};
  }
	i{
		display:block;
		float:left;
		margin:auto 2%;
		font-size:1.5rem;
		color: #8F8F8F;
	}
	span{
		width: 86%;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		height: 2.8rem;
		display:block;
		float:left;
		color: #BEBEBE;
	}
`

export const MessageWrapper = styled.div`
  width:14%;
  text-align: center;
  margin-right: 3%;
  img{
	  width: 1.375rem;
	  margin-top: 1rem;
	  margin-bottom: 0.2rem;
  }
  span{
	  display: block;
	  font-size: 10px;
	color: #8F8F8F;
  }
`
export const NewStar = styled.div`
  width: 100%;
  margin-top: 20px;
`
export const NewStarTitle = styled.div`
  font-size: 16px;
  width:92%;
	margin-left:4%;
  color: ${themenew.text_color};
  >span{
    float: right;
    display: block;
    height: 1rem;
    line-height: 1rem;
    font-size:0.75rem;
		color: #7C8698;
  }
`
export const NewStarBow = styled.div`
  width:100%;
  height: 89px;
  display: flex;
  overflow-x:auto;
  overflow-y:hidden;
  margin-top: 13px;
  padding-left: -5px;
  ::-webkit-scrollbar{
		width:0;
		height: 0;
		display:none;
	}
  >div{
    margin-left: 20px;
    >div{
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      overflow:hidden;
      display:flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      margin-bottom: 12px;
      >img{
        width:100%;
        min-height:60px;
        object-fit: cover; 
      }
    }
    >span{
      display: block;
      width: 100%;
      font-size: 14px;
      color:${themenew.text_color};
      text-align: center;
    }
  }
`
export const StarList = styled.div`
  width: 92%;
  margin-left: 4%;
  padding-top: 1rem;
  padding-bottom: .1rem;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >h1{
    font-size: 18px;
    font-weight: 600;
    color: ${themenew.text_color};
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  >div{
    margin-bottom: 1rem;
    >span{
      display: inline-block;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      height: 1rem;
      >img{
        float: left;
        width: 15px;
        height: 15px;
        margin-right: 6px;
        color: ${themenew.subtext_color};
      }
      >h3{
        //float: left;
        display: inline;
        font-size: 14px;
        color: ${themenew.subtext_color};
        //margin-right: 16px;
      }
    }
    >div{
      width: 72%;
      float: left;
      >span{
        border:1px solid ${themenew.subtext_color};
        color: ${themenew.subtext_color};
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 4px;
        margin-right: 8px;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }
    
    >h2{
      font-size: 20px;
      color: #EF4266;
      float: right;
    }
    >a{
      float: right;
      color: ${themenew.text_color};
      >span{
        font-size: 12px;
        line-height: 27px;
        display: inline-block;
      }
      >i{
        font-size: 18px;
        float: right;
      }
    }
  }
`
export const BrandsListEn = styled.div`
    color: ${themenew.text_color};
    font-size: 1.125rem;
    margin-top: 0.8rem;
`
export const BrandsListCn = styled.div`
    color: ${themenew.subtext_color};
    font-size: 0.875rem;
    margin-top: 0.75rem;
`
export const BrandsListImg = styled.div`
    width: 2.875rem;
    height: 2.875rem;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 0.75rem;
    border-radius: 50%;
    >img{ 
        width:100%;
        min-height:2.875rem;
        object-fit: cover;
    }
`
export const CountryBorder = styled.div`
    flex: 1;
    overflow: auto;
    position: relative;
    .brandsList {
        padding: 0.75rem 4%;
        border-bottom: 0.5px solid #E1E1E1;
        text-align: left;
        background: #fff;
        height: 4.375rem;
    }
    .title{
        color: #4A4A4A;
        background: #F6F6F6;
        height: 1.75rem;
        line-height: 1.75rem;
        padding: 0 4%;
    }
    .char-list-border {
        padding-top: 148px;
        position: fixed;
        right: 0;
        top: 0;
        width: 20px;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    .char-list {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
    }
    .char-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
    .char-tip {
        position: fixed;
        width: 50px;
        height: 50px;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background-color: gray;
        border-radius: 6px;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 50px;
        text-align: center;
    }
`
export const CommunityDetailWrapper = styled.div`
  width:100%;
  background-color:#fff;
  margin:0 auto;
  padding-bottom: 5rem;
  video{
    background-color: #000;
  }
`
export const ContentWrapper = styled.div`
  width:92%;
  padding-top:1rem;
  margin:0 4% 1.25rem;
`
export const Title = styled.div`
  width:100%;
  font-size:1.5rem;
  font-weight:bold;
  line-height:2rem;
  color: ${themenew.text_color};
`
export const SubTitle = styled.div`
  
  line-height: 55px;
  height: 55px;
  >span{
    width: 4px;
    height: 15px;
    background-color: #771AE3;
    display: inline-block;
    border-radius: 2px;
    margin-right:.5rem ;
    //float: left;
    margin-top:1.3rem;
  }
  >h2{
    font-size: 14px;
    color: ${themenew.text_color};
    display: inline-block;
  }
  >h1{
    display: inline-block;
    margin-left:20px;
    color: ${themenew.text_color};
    font-weight: 600;
  }
`
export const Content = styled.div`
  width:100%;
  font-size:1.1rem;
  line-height:2rem;
  min-height:25rem;
  img{
    width:100%!important;
    height:auto!important;
  }
  video{
    width:100%!important;
    height:auto!important;
  }
  .exhibitionBox{
    display: flex;
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }
  .exhibitionTitle{
    color:${themenew.text_color};
    font-size: 0.875rem;
    width: 22%;
  }
  .exhibitionText{
    color:${themenew.text_color};
    font-size: 0.875rem;
    width: 78%;
  }
`
export const CommunityWrapper = styled.div`
  width:100%;
  margin:0 auto;
`
export const StarDetailWrapper = styled.div`
  width:100%;
  background-color:#fff;
  .fengexian{
		width:100%;
		height: .5rem;;
		background-color: #F6F6F6;
		margin-bottom: .5rem;
	}
`
export const StarImgWrapper = styled.div`
  width:100%;
  background-color:#fff;
  overflow: hidden;
  padding-top: 20px ;
`
export const IntroduceBox = styled.div`
  width:100%;
  padding-top: 20px;
  margin-bottom: 1rem;
  >h1{
    font-size: 17px;
    padding: 0 4%;
    color: ${themenew.text_color};
    font-weight: 600;
    margin-bottom: 13px;
  }
  >div{
    font-size: 14px;
    line-height: 30px;
    padding: 0 4%;
    color: ${themenew.text_color};
  }
  .more{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    height: 7.5rem;
  }
  >table{
    width: 100%;
    font-size: 14px;
    color: ${themenew.text_color};
    >tr{
      height: 40px;
      line-height: 40px;
    }
  }
`
export const BrandsIntroduceMoreButton = styled.div`
  float: right;
  margin-right: 4%;
  font-size: 0.75rem;
  color: #7C8698;
  
`


export const StarName = styled.div`
  float:left;
  margin-left:1.3rem;
  margin-top:3.8rem;
  font-size:1rem;
  color:#61656A;
  >span{
    display: block;
    font-size:28px;
    font-weight:700;
    color:#7D2AA3;
    margin-top: 8px;
  }
`
export const StarHead = styled.img`
  width: 50px;
  height: 50px;
  float: right;
  border-radius: 50%;
  margin-right:4%;
  margin-top:3.9rem;
`
