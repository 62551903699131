import React, {
	Component
} from 'react';
import Content from './components/Content';
import {
	CommunityWrapper,
} from './style';

class Evaluation extends Component {
	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		userPID: '',
	// 	}
	// }
	
	// componentWillMount() {
	// 	var that = this;
	// 	window.userInfo = function (data, params) {
	// 		that.setState({
	// 			userPID: data.UserPID
	// 		})
	// 		// alert(data.UserPID);
	// 		// localStorage.setItem('AUDIO_ACCOUNT_TYPE', data.UserPID);
	// 	}
	// }
	render() {
		return (
			<CommunityWrapper>
				<Content location={this.props.location} />
			</CommunityWrapper>
		)
	}
}

export default Evaluation