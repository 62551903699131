import React, {
    Component
} from 'react';
import {
    StarInfoWrapper,
    MoreButtonBG,
} from './style';
import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";
import {
    Form,
    Input,
    Button,
} from 'antd';
import { Toast } from 'antd-mobile-v2'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../StarEdit/editor.css';

class BussFaBuYaoYueForm extends React.Component {

    state = {
        userId: '',
        Introduce: ''
    }

    modules = {
        toolbar: false
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
    ]

    getStarIntroduction = async (userID) => {
        let params;
        params = {
            "UserID": userID,
        }
        const res = await httpAxios(api.getStarIntroduction, params)
        if (res.Code == 200) {
            // this.props.form.setFieldsValue({ Introduce: res.Data });
            this.setState({ Introduce: res.Data });
        }
    }

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            //alert(JSON.stringify(data))
            that.setState({
                userId: data.UserPID
            })
            that.getStarIntroduction(data.UserPID);
        };
        //alert(JSON.stringify(this.props.info))
        // await this.getArtistInfo();
    }


    //保存数据
    handleSubmit = async () => {
        let ok = 0;

        let dataSave = {
            UserID: this.state.userId,
            StarIntroduction: this.state.Introduce,
        };

        // console.log(dataSave);
        const res = await httpAxios(api.starIntroduction, dataSave);

        if (res.Code != 200) {
            ok = 1;
        }

        if (ok == 0) {
            Toast.info("数据保存成功");
        }
        else {
            Toast.info("数据保存失败");
        }

    }

    render() {

        return (
            <StarInfoWrapper>
                <Form
                    layout="horizontal"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={this.handleSubmit}
                >
                    <div className="form-group" style={{ margin: '1.5rem 0 0.5rem' }}>
                        <label htmlFor="brand_story" style={{ fontSize: '1.2rem', fontWeight: '600' }}>公司介绍</label>
                        <div className="d-flex">
                            {/* <Form.Item>
                                    {getFieldDecorator('Introduce')
                                        (<TextArea rows={10} />)}
                                </Form.Item> */}
                            {/* <WangEditor imgType={64} ref={r => this.editor = r} uploadImageUrl={api.getDirectUploadSign} uploadImgType={4} uploadVideoCb={this.handleUploadVideo} getVideoImg={this.getVideoImg} /> */}
                            <ReactQuill theme="snow"
                                modules={this.modules}
                                formats={this.formats}
                                value={this.state.Introduce}
                                onChange={(value) => this.setState({ Introduce: value })
                                }
                            />
                        </div>
                    </div>
                    <MoreButtonBG style={{ marginBottom: '-2rem', marginLeft: '-4%', width: '100%' }}>
                        <Form.Item>
                            <Button type="primary" block htmlType="submit" style={{ backgroundImage: 'linear-gradient(135deg,#0060ec 0%,#B82cfc 100%)', borderRadius: '6px', height: '3.125rem', width: '92%', marginTop: '0.75rem', border: '0', marginLeft: '4%' }}>保存</Button>
                        </Form.Item>
                    </MoreButtonBG>
                </Form >
            </StarInfoWrapper>
        );
    }
}

class StarInfo extends Component {
    render() {

        return <>
            <BussFaBuYaoYueForm />
        </>
    }
}

export default StarInfo;