export const theme = {
    theme_color: '#771ae3', // 紫主题色
    rose_color: '#ee4266', // 玫红主题色
    blue_color: '#3786c8', // 蓝主题色
    light_blue_color: '#e7f4fb', // 浅蓝主题色
    middle_light_blue_color: "#4db0e4", // 中等蓝色主题色
    text: '#202a25', // 主文本
    subtext: '#6d6d6d', // 次文本
    line_color: '#f1f1f1', // 分割线与阴影
    dark_line_color: '#d6d6d6', // 较深分割线
    theme_dark_auxi_color: '#540d6e', // 深紫辅色
    theme_light_auxi_color: '#7e007b', // 浅紫辅色
    header_footer_bg_color: '#43374c', //页首页尾背景色
    weixin_color: '#50b674', // 微信绿色
    weibo_color: '#ea5d5c', // 微博红色
    finish_green_color: '#00a300', // 成功绿色
    login_and_register_color: '#7e007b',
    login_and_register_hover_color: '#781be3',
    rose_btn_color: '#cd0174',
    input_bg_color: '',
    input_border_color: '#757584',
    gray_state: '#8a8a8a',
    light_theme_color: '#9f74df',
    agreement_color: '#008bff',
    upload_area_bg_color: '#f8f9fb',
    red_color: "#d81e06",
    background_color: '#ffffff',
    warning_color: '#d81e06',
    link_blue_color: '#40a9ff',
    button_color: '#7109aa',
    input_and_text_error: '#ef4b6d',
    input_label_color: '#6d6d6d',
    input_placeholder_color: 'rgb(214, 214, 214)',
    border_hover_color: '#833be1'
}

export const themenew = {
    theme_color: '#4228CF', // 主交互色（紫色）
    link_color: '#015FEC',//链接颜色
    subtext_color: '#7C8698', // 次文本颜色
    text_color: '#203152', // 主文本与主icon颜色
    line_color: '#DDDDDD', // 分割线
    bg_color: '#FAFAFA', //背景主色（白色）
    unavailable_color: '#D1D5DB',//不可用颜色
    error_color: '#E02020', //警示和报错颜色(红色)
    finish_color: '#00a300', // 成功颜色（绿色）
    text_white_color: '#FFFFFF', //反白文字与icon（白色）
    navbar_color: '#4228CF', //NavBar颜色（紫色）
    navbar_search_color:'#FFFFFF',//NavBar搜索框背景（白色）



    subtheme_color: '#EE4266', // 副交互色（桃红色）
    businesscenter_blue_color: '#1986DE', // 企业中心主色（蓝色）
    businesscenter_yellow_color: '#FAB91C', //企业中心目录链接色（黄色）
    
    subicon_color: '#B6B6C6', //副icon颜色
    
    subbg_color: '#F8F8F8', //背景配色（灰色）
    
    warning_color: '#F7B500', //低价警示（黄色）
    
}