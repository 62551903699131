import React, { Component } from 'react';
// import { country } from './data';
// import './style';
import {
  CountryList,
  CountryBorder,
  NavBar,
  SearchIcon,
  FilterButton,
  BrandsListImg,
  BrandsListEn,
  BrandsListCn
} from './style';

import httpAxios from '../../http/httpAxios';
import api from '../../http/api';

const searchButton = require('../../images/search_button.svg')

// 头部搜索栏
class Pager extends Component {
  constructor() {
    super();
    this.state = {
      isTouching: false,
      lastChar: 'A',
      country: [],
      charStr: []
    }

    // this.isTouching = false;
    this.boxClientTop = 148;
    // this.lastChar = 'A';
    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
    this.getChar = this.getChar.bind(this);
    this.gotoChar = this.gotoChar.bind(this);
  }

  touchStart(e) {
    // e.preventDefault();
    // this.isTouching = true;
    this.setState({ isTouching: true });
    const char = this.getChar(e.touches[0].clientY);
    this.gotoChar(char);
  }
  touchMove(e) {
    e.preventDefault();
    const char = this.getChar(e.touches[0].clientY);
    this.gotoChar(char);
  }
  touchEnd(e) {
    e.preventDefault();
    this.setState({ isTouching: false });
  }
  getChar(clientY) {
    const charHeight = this.refs.charBar.offsetHeight / this.state.charStr.length;
    const index = Math.floor((clientY - this.boxClientTop) / charHeight);
    return this.state.charStr[index];
  }
  gotoChar(char) {
    if (char === this.lastChar) {
      return false;
    }
    this.setState({ lastChar: char });
    // this.lastChar = char;
    if (char === '*') {
      this.refs.countryList.scrollTop = 0;
    } else if (char === '#') {
      this.refs.countryList.scrollTop = this.refs.countryList.scrollHeight;
    }
    const target = document.querySelector('[data-en="' + char + '"]');

    if (target) {
      // target.scrollIntoView();
      window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
    }
  }
  componentWillMount() {
    // alert(JSON.stringify(this.country));    
  }

  componentDidMount() {
    this.getBrands();
  }

  getBrands = async () => {
    const res = await httpAxios(api.getAppBrands, {});
    if (res) {
      this.setState(
        {
          charStr: res.Data.Letters,
          country: res.Data.Data
        }
      );
    }
  }
  render() {

    const {
      country,
      charStr,
    } = this.state;

    return (
      <>
        <CountryList>
          <NavBar>
            <span>品牌汇</span>
            <SearchIcon onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><img src={searchButton} alt="搜索" /></SearchIcon>
          </NavBar>
          <FilterButton>
            <div onClick={() => { localStorage.setItem("_search", "真无线耳机,1"); window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '真无线耳机', weburl: "/Brands/FilterResultList" })); }}>真无线耳机</div>
            <div onClick={() => { localStorage.setItem("_search", "落地音箱,1"); window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '落地音箱', weburl: "/Brands/FilterResultList", search: "落地音箱,1" })); }}>落地音箱</div>
            <div onClick={() => { localStorage.setItem("_search", "美国,2"); window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '美国', weburl: "/Brands/FilterResultList", search: "美国,2" })); }}>美国</div>
            {/* <div onClick={() => { localStorage.setItem("_search", "英国,2"); window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '英国', weburl: "/Brands/FilterResultList", search: "英国,2" })); }}>英国</div> */}
            <span onClick={() => {
              //记录查询条件
              localStorage.removeItem("searchList");
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '更多筛选条件', weburl: "/Brands/FilterResultList", model: true }));
            }}>更多筛选<i className='iconfont'>&#xe744;</i></span>
          </FilterButton>
          <div style={{ height: '9.25rem' }}></div>

          {/* <Detial>这是detail page</Detial> */}
          <CountryBorder ref="countryList" >
            {
              country.map((item, index) => {
                return (
                  <div key={index}>
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '品牌主页', weburl: '/Brands/BrandsDetail/' + item.ID })); }}>
                      {item.First && <div data-en={item.Letter}></div>}
                      <div
                        className={item.First === true ? "title" : 'brandsList'}
                      >
                        {!item.First && <BrandsListImg><img src={item.BrandLogo + '?x-oss-process=image/resize,w_92,m_lfit'} alt="" /></BrandsListImg>}
                        <BrandsListEn>{item.BrandName}</BrandsListEn>
                        {!item.First && <BrandsListCn>{item.BrandCountry}</BrandsListCn>}
                      </div>
                    </a>
                  </div>
                )
              })
            }
            <div className="char-list-border">
              <ul
                className="char-list"
                ref="charBar"
                onTouchStart={this.touchStart}
                onTouchMove={this.touchMove}
                onTouchEnd={this.touchEnd}
              >
                {
                  charStr.map((char, index) => {
                    return (
                      <li className="char-item" key={index}>{char}</li>
                    )
                  })
                }
              </ul>
            </div>
            {this.state.isTouching && <div className="char-tip">{this.state.lastChar}</div>}
          </CountryBorder>
        </CountryList>
      </>
    )
  }
}



export default Pager;
