import React, {
    Component, createRef
} from 'react';
import {
    CommunityWrapper,
} from './style';
import moment from 'moment';
import {
    Form,
    Input,
    message,
    Cascader,
    DatePicker,
    Row,
    Col,
    Button,
} from 'antd';
import { Popup, Calendar } from "antd-mobile";
import httpAxios from '../../http/httpAxios2';
import api from '../../http/api';
import { Toast } from 'antd-mobile-v2';
const { TextArea } = Input;
const { RangePicker } = DatePicker;

class SendCode extends Component {

    state = {
        time: '',
        phoneHash: ''
    }

    trick = () => {
        let {
            time,
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }

    componentDidMount() {

    }

    phoneHashData = (event) => {
        this.setState({
            phoneHash: event
        });
    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    sendCode = async () => {
        if (!this.props.phone) {
            message.info('请输入手机号')
            return
        }
        // try {
        const res = await httpAxios(this.props.url, {
            phone: this.props.phone
        })
        if (res) {
            clearTimeout(this.timeId);
            this.setState({
                time: 59,
                phoneHash: res.Data
            })
            this.timeId = setInterval(this.trick, 1000);
            this.phoneHashData(this.state.phoneHash);
        }
    }
    render() {
        return (
            <>
                {
                    this.state.time > 0 ? <Button disabled>{`${this.state.time}s 后重发`}</Button> : <Button onClick={this.sendCode}>发送验证码</Button>
                }
            </>
        )
    }
}

class YaoYue extends Component {
    state = {
        starNav: '1',
        type: localStorage.getItem('AUDIO_ACCOUNT_TYPE'),
        starData: [],
        data: [],
        xingqingdata: [],
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        xiangqingVisible: false,
        successBox: false,
        userId: '',
        phone: "",
        timeOpen: false,
        timeOne: "",
        timeTwo: ""
    }


    formRef = createRef();

    componentDidMount() {

        var that = this;
        window.userInfo = function (data, params) {
            //alert(JSON.stringify(data))
            that.setState({
                userId: data.UserPID
            })

            that.getStarData();
            that.getData('1');
        };

        //保存邀约
        window.saveInfo = function () {
            that.formRef.current.submit();
        };
    }
    comNavHover = () => {
        this.setState({
            starNav: '1'
        })
        this.state.pageIndex = 1;
        this.getData('1');
    }
    starNavHover = () => {
        this.setState({
            starNav: '2'
        })
        this.state.pageIndex = 1;
        this.getData('2');
    }
    getStarData = async () => {
        let params;
        params = {
            "sex": "0",
            "country": "0",
            "letter": "0",
            "jointime": "1",
            "pageIndex": 1,
            "pageSize": 12,
        }
        const res = await httpAxios(api.getArtistInfoList, params)
        this.setState({
            starData: res.Data,
        })
    }
    getData = async (type) => {
        let params;
        params = {
            "type": type,
            "pageIndex": this.state.pageIndex,
            "pageSize": this.state.pageSize,
        }
        const res = await httpAxios(api.getArtistInvitationList, params)
        this.setState({
            data: res.Data,
            total: res.Message
        })
    }

    xiangqingData = async (id) => {
        let params;
        params = {
            "ID": id,
        }
        const res = await httpAxios(api.getArtistInvitationDetail, params)
        this.setState({
            xingqingdata: res.Data,
            xiangqingVisible: true,
        })
    }

    onChange = async (page, pageSize) => {
        this.state.pageIndex = page;
        this.state.pageSize = pageSize;

        await this.getData(this.state.starNav);
    }
    onShowSizeChange = async (current, pageSize) => {
        this.state.pageIndex = 1;
        this.state.pageSize = pageSize;

        await this.getData(this.state.starNav);
    }

    render() {

        const { phone, timeOpen } = this.state;

        const residences = [
            {
                value: '1万以下',
                label: '1万以下',
            },
            {
                value: '1-10万',
                label: '1-10万',
            },
            {
                value: '10-20万',
                label: '10-20万',
            },
            {
                value: '20-50万',
                label: '20-50万',
            },
            {
                value: '50-100万',
                label: '50-100万',
            },
            {
                value: '100-200万',
                label: '100-200万',
            },
            {
                value: '500-1000万',
                label: '500-1000万',
            },
            {
                value: '1000万以上',
                label: '1000万以上',
            },
        ];
        const invitationType = [
            {
                value: '影视邀约',
                label: '影视邀约',
            },
            {
                value: '广告邀约',
                label: '广告邀约',
            },
            {
                value: '演出邀约',
                label: '演出邀约',
            },
        ]

        // function disabledDate(current) {
        //     // Can not select days before today and today
        //     return current && current < moment().endOf('day');
        // }

        return (
            <CommunityWrapper>
                <Form layout="vertical" ref={this.formRef}
                    onFinish={this.handleFaBuYaoYueCreate}
                >
                    <Form.Item label="标题" name="title" rules={[{ required: true, message: '请输入标题!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="报酬范围" name="description" rules={[{ required: true, message: '请选择报酬范围!' }]}>
                        <Cascader options={residences} />
                    </Form.Item>

                    <Form.Item label="选择邀约类型" name="invitationType" rules={[{ required: true, message: '请选择邀约类型!' }]}>
                        <Cascader options={invitationType} />
                    </Form.Item>
                    {/* <Form.Item label="时间范围" name="rangePicker" rules={[{ required: true, message: '请选择时间范围!' }]}> */}
                    <Form.Item label="时间范围">
                        {/* <RangePicker disabledDate={disabledDate} /> */}
                        <Button
                            onClick={() => {
                                this.setState({
                                    timeOpen: true
                                })
                            }}
                        >
                            {this.state.timeOne && this.state.timeTwo ? moment(this.state.timeOne).format('YYYY-MM-DD') + " - " + moment(this.state.timeTwo).format('YYYY-MM-DD') : "开始时间 - 结束时间"}
                        </Button>
                        <Popup
                            visible={timeOpen}
                            onMaskClick={() => {
                                this.setState({
                                    timeOpen: false
                                })
                            }}
                            onClose={() => {
                                this.setState({
                                    timeOpen: false
                                })
                            }}
                        // bodyStyle={{ height: "50vh" }}
                        >
                            <Calendar
                                min={new Date()}
                                selectionMode="range"
                                onChange={(val) => {
                                    // console.log(moment(val[0]).format('YYYY-MM-DD HH:mm:ss'));
                                    // console.log(moment(val[1]).format('YYYY-MM-DD HH:mm:ss'));
                                    console.log(new Date());
                                    this.setState({
                                        timeOne: moment(val[0]).format('YYYY-MM-DD HH:mm:ss'),
                                        timeTwo: moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
                                    })
                                }}
                            />
                        </Popup>
                    </Form.Item>
                    <Form.Item label="地点" name="place" rules={[{ required: true, message: '请输入地点!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="填写详情描述" name="details" rules={[{ required: true, message: '请输入详情描述!' }]}>
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item label="联系人" name="contacts" rules={[{ required: true, message: '请输入联系人!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="联系电话" name="phone" rules={[{ required: true, message: '请输入联系电话!' }]}>
                        <Input onChange={e => this.setState({ phone: e.target.value })} />
                    </Form.Item>
                    <Form.Item label="验证码" name="captcha" rules={[{ required: true, message: '请输入验证码!' }]}>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Input />
                            </Col>
                            <Col span={12}>
                                <SendCode url={api.loginCode} phone={phone} />
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </CommunityWrapper>
        )
    }

    //发布成功窗口动作
    successBoxCancel = async () => {
        this.setState({
            successBox: false,
        });
    }
    successBoxOK = async () => {
        this.setState({
            successBox: false,
        });
        if (this.state.type === 'buss') {
            this.businesscenterLink.click();
        } else {
            this.mycenterLink.click();
        }
    }


    handleFaBuYaoYueCreate = (values) => {
        if (this.state.timeTwo == "") {
            Toast.info('请选择时间范围')
            return
        }
        var data = {};
        data["Title"] = values.title;
        data["MoneyRange"] = values.description[0];
        data["InvitationType"] = values.invitationType.toString();
        // data["BeginDate"] = values.rangePicker[0];
        // data["EndDate"] = values.rangePicker[1];
        data["BeginDate"] = this.state.timeOne;
        data["EndDate"] = this.state.timeTwo;
        data["Place"] = values.place;
        data["Detail"] = values.details;
        data["ContactsName"] = values.contacts;
        data["ContactsPhone"] = values.phone;
        data["Code"] = values.captcha;
        // form.resetFields();
        //保存数据
        this.addFaBuYaoYue(data, '1');
    };

    addFaBuYaoYue = async (data, type) => {
        const res = await httpAxios(api.setArtistInvitation, {
            "ID": 0,
            "UserPID": this.state.userId,
            "Type": type,
            "Title": data.Title,
            "BeginDate": data.BeginDate,
            "EndDate": data.EndDate,
            "InvitationType": data.InvitationType,
            "Place": data.Place,
            "Detail": data.Detail,
            "ContactsName": data.ContactsName,
            "ContactsPhone": data.ContactsPhone,
            "MoneyRange": data.MoneyRange,
            "Code": data.Code
        })
        if (res.Code == 200) {
            message.info('提交成功。');

            try {
                setTimeout(() => {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'goback' }));
                }, 1000);
            }
            catch (error) {
                alert(JSON.stringify(error));
            }
        } else {
            message.info('提交出现错误，请联系管理员');
        }
    }

    //邀约详情弹窗动作
    handleYaoYueXiangQingCancel = () => {
        this.setState({
            xiangqingVisible: false
        });
    };

    handleYaoYueXiangQingCreate = async (id, iType) => {
        const {
            type,
        } = this.state;
        if (type === iType) {
            message.info('无效，无权限点击此按钮。');
            return
        }
        this.setState({
            xiangqingVisible: false
        });
        //保存数据
        const res = await httpAxios(api.setArtistFocus, {
            UserPID: this.state.userId,
            ArtistInvitationID: id,
        })
        if (res.Code === 200) {
            message.info('成功，您的要求已经提交至管理员，稍后工作人员匹配双方后会与您取得联系。');
        }
        if (res.Code === 204) {
            message.info(res.Message);
        }
    };
}

export default YaoYue