import React, {
    Component
} from 'react';
import { List, InputItem } from 'antd-mobile-v2';
import {
    SettingsWrapper
} from './MycenterPortraitStyle';
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
import axios from 'axios'
import api from "../../../http/api"
import httpAxios from "../../../http/httpAxios"
import { Toast, Button } from 'antd-mobile-v2';

const history = creatHistory();//返回上一页这段代码


class SignedName extends Component {
    state = {
        WriteSB: '',
        userPID: ''
    };

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getUserInfo(data.UserPID);
        }
    }

    //获取用户信息
    getUserInfo = async (id) => {
        const res = await axios.post(api.getUserInfo.url, {
            // UserPID: sessionStorage.getItem("BUSS_CENTER_TO_BRAND_MANAGER_ID"),
            UserPID: id
        })
        if (res && res.data.Data) {
            this.setState({ WriteSB: res.data.Data.WriteSB });
        }
    }

    //保存信息
    onSubmit = (key, value) => {
        let dataTemp = {
            UserPID: this.state.userPID
        };
        dataTemp[key] = value;

        httpAxios(api.updateUserInfo, dataTemp).then(res => {
            if (res) {
                Toast.info('信息修改成功')
            }
        })
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));

    }

    goBackPage = () => {
        history.goBack();  //返回上一页这段代码
    }

    render() {
        return (
            <SettingsWrapper>
                {/* <NavBar>
                    <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                    <span>编辑个性签名</span>
                    <a onClick={() => {
                        this.onSubmit('WriteSB', this.state.WriteSB);
                    }}>保存</a>
                </NavBar>
                <div style={{ height: '5.5rem' }}></div> */}
                {/* NavBar的高度占位 */}
                <List renderHeader={() => ''}>
                    <InputItem
                        className='listheight'
                        placeholder="请输入个性签名"
                        value={this.state.WriteSB}
                        onChange={(value) => this.setState({ WriteSB: value })}
                    >个性签名</InputItem>
                </List>
                <Button type="primary" style={{ margin: '1rem 4%' }} onClick={() => {
                    this.onSubmit('WriteSB', this.state.WriteSB);
                }}>保存信息</Button>
            </SettingsWrapper>
        )
    }
}

export default SignedName;