import React, {
    Component, createRef
} from 'react';
import moment from 'moment';
import {
    StarInfoWrapper,
    MoreButtonBG
} from './style';
import { DividingLine } from '../../../../common/style'
import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";
import {
    Form,
    Input,
    message,
    DatePicker,
    Select,
    Row,
    Col,
    Button,
    InputNumber,
} from 'antd';
import { Toast } from 'antd-mobile-v2'

import UUID from 'uuid/v1';

import {
    getBase64,
    uploadImgWithName
} from "../../../../utils/utils";

import axios from "axios"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../StarEdit/editor.css';

const { TextArea } = Input;

var imageType = 0;
let id = 0;


class BussFaBuYaoYueForm extends React.Component {

    state = {
        mainPicture: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUIAAAC2CAYAAACs2YQnAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDQvMjgvMjBn0Np+AAAEVUlEQVR4nO3dzYoUSRiF4S/yp120UF3UHXj/1+QVCIkgaluZEbNwFJ0BwYXmjOd5dkX14pA0LxlZRXcbY4z62xijnp+f636/V++94E/UWqtvfu0JMMaoaZpqnuda17XWda3W2tf32xhj9N7r7du39fHjx1qWpeZ5rmmavvtB+FMIYZ4xRo0xqvde+75XVdXDw0NdLpfPrRtjjG3b6jiOenp6qmVZTp4M8Gvt+17bttW6rvX09FTTcRz14cOHut1uIghEWJalrtdrPT8/13EcNX369KkeHh4cg4EoXx4D3u/3mvZ9r3mez94E8NvN81z7vtfUe69pms7eA3CKMUYpIBCrtVa991p8jQBI9eWzkcWHJEA6R2MgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBvOXsAubZt++719Xo9aQnphJDTCCH/FY7GQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjE89dngFittaoSQiDYGKOqHI0BhBBACIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDz/4P0PtW1bbdt29oyf8vr167Mn/NDlcqnb7Xb2DH4Bd4RAPCEE4gkhEM8zwj/U9Xqt6/V69owf+uczwVevXp20hHTuCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOItZw8g1+VyOXsCVJUQcqLb7Xb2BKgqR2MAIQQQQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEGuMUa01IQRyjTGqqmqapunrC4Akvfea57mmZVnqOI6z9wD8dsdx1LIsNa3rWvu+177vZ28C+G2O46h932td1893hC9evKht2+p+v5+9DeCXu9/v9ebNm3p8fKxpmqqNMUbvvd69e1fv37+v1lrN81zzPFdrrVprZ28GfkJrzXP/b3y5FmOM6r3Xvu81xqjHx8d6+fLl586Nb67YGKP2fa/7/V699+q9u6DwPyOE/zZNn78gM89zreta67p+9/5f8aXexViRkPsAAAAASUVORK5CYII=',
        personPicture: [],
        dianying: [],
        dianshiju: [],
        zongyi: [],
        yinyue: [],
        huodong: [],
        huojiangjingli: [],
        daiyan: [],
        userId: '',
        Introduce: ''
    }

    formRef = createRef();

    getArtistInfo = async (id) => {
        let params;
        params = {
            "userPID": id,
        }
        const res = await httpAxios(api.getArtistInfo, params)
        if (res.Code == 200) {
            let dataInfo = {};

            if (res.Data.info !== null) {
                let artistInfoDTO = res.Data.info;
                const dateFormat = "YYYY-MM-DD";

                //记录主图片
                if (artistInfoDTO.MainPicture != undefined) {
                    // console.log(artistInfoDTO.MainPicture);
                    this.setState({ mainPicture: artistInfoDTO.MainPicture });
                }
                //记录图片集
                if (artistInfoDTO.PictureList != undefined) {
                    let ps = artistInfoDTO.PictureList.split(",");
                    this.setState({ personPicture: ps });
                }

                dataInfo = {
                    ChineseName: artistInfoDTO.ChineseName,
                    EnglishName: artistInfoDTO.EnglishName,
                    Sex: artistInfoDTO.Sex,
                    Country: artistInfoDTO.Country,
                    Nation: artistInfoDTO.Nation,
                    Birthplace: artistInfoDTO.Birthplace,
                    // Birthday: moment(moment(artistInfoDTO.Birthday).format(dateFormat)),
                    Constellation: artistInfoDTO.Constellation,
                    BloodGroup: artistInfoDTO.BloodGroup,
                    // Profession: artistInfoDTO.Profession.split(','),
                    Height: artistInfoDTO.Height,
                    Weight: artistInfoDTO.Weight,
                    School: artistInfoDTO.School,
                    Language: artistInfoDTO.Language,
                    Speciality: artistInfoDTO.Speciality
                }

                this.setState({ Introduce: artistInfoDTO.Introduce });


                if (artistInfoDTO.Birthday != null) {
                    dataInfo.Birthday = moment(moment(artistInfoDTO.Birthday).format(dateFormat));
                }

                if (artistInfoDTO.Profession != null && artistInfoDTO.Profession?.length > 1) {
                    dataInfo.Profession = artistInfoDTO.Profession.split(',');
                }

                this.formRef.current.setFieldsValue(dataInfo);

                // this.editor.setContent(artistInfoDTO.Introduce);

                //主要作品
                this.setState({ dianying: res.Data.dianying });
                this.setState({ dianshiju: res.Data.dianshiju });
                this.setState({ zongyi: res.Data.zongyi });
                this.setState({ yinyue: res.Data.yinyue });
                this.setState({ huodong: res.Data.huodong });
                this.setState({ huojiangjingli: res.Data.huojiangjingli });
                this.setState({ daiyan: res.Data.daiyan });
            }
        }
    }

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userId: data.UserPID
            })
            that.getArtistInfo(data.UserPID);
        };
    }

    modules = {
        toolbar: false,
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
    ]

    //上传图片
    updateLicense = (type) => {
        imageType = type;
        this.LicenseInput.click();
    }
    handleLicenseSelect = async (e) => {
        const file = e.target.files[0];
        const file64 = await getBase64(file);
        const suffix = file.name.split('.')[1];
        const name = UUID() + '.' + suffix;
        if (file.size > 2 * 1024 * 1024) {
            message.error('上传图片大小不能超过2MB');
            return
        }
        const url = await uploadImgWithName(api.bcUploadProductImg, file, name, {
            FileName: name
        })
        if (url) {
            switch (imageType) {
                // case 1://营业执照
                //   this.setState({
                //     licenseImg: url
                //   })
                //   break;
                // case 2://公司logo
                //   this.setState({
                //     logoImg: url
                //   })
                //   break;
                case 3://主图片
                    this.setState({
                        mainPicture: url
                    })
                    break;
                case 4://个人图片
                    const newItem = this.state.personPicture.slice();
                    newItem.push(url);

                    this.setState({
                        personPicture: newItem
                    })
                    break;
            }
        }
    }

    //删除图片
    deleteImg = async (index) => {
        const res = axios.delete(api.bcDeleteImg.url + '?imgURL=' + this.state.personPicture[index])
        if (res) {
            const newItem = this.state.personPicture.slice();
            //删除数据
            newItem.splice(index, 1);

            this.setState({
                personPicture: newItem
            })
        }
    }

    //保存数据
    handleSubmit = async (values) => {

        let dataSave = {
            UserPID: this.state.userId,
            ChineseName: values.ChineseName,
            EnglishName: values.EnglishName,
            Sex: values.Sex,
            Country: values.Country,
            Nation: values.Nation,
            Birthplace: values.Birthplace,
            Birthday: values.Birthday,
            Constellation: values.Constellation,
            BloodGroup: values.BloodGroup,
            Profession: values.Profession?.toString(),
            Height: values.Height,
            Weight: values.Weight,
            School: values.School,
            Language: values.Language,
            Speciality: values.Speciality,
            Introduce: this.state.Introduce,
            MainPicture: this.state.mainPicture.indexOf("data:") != -1 ? "" : this.state.mainPicture,
            PictureList: this.state.personPicture.toString()
        };

        const res = await httpAxios(api.setArtistInfo, dataSave);

        let ok = 0;
        if (res.Code != 200) {
            ok = 1;
        }

        //电影
        if (this.state.dianying.length > 0) {
            dataSave = {
                UserPID: this.state.userId,
                Type: 1,
                Info: this.state.dianying
            };

            const res = await httpAxios(api.setArtistList, dataSave);

            if (res.Code != 200) {
                ok = 1;
            }
        }

        //电视剧
        if (this.state.dianshiju.length > 0) {
            dataSave = {
                UserPID: this.state.userId,
                Type: 2,
                Info: this.state.dianshiju
            };

            const res = await httpAxios(api.setArtistList, dataSave);

            if (res.Code != 200) {
                ok = 1;
            }
        }

        //综艺
        if (this.state.zongyi.length > 0) {
            dataSave = {
                UserPID: this.state.userId,
                Type: 3,
                Info: this.state.zongyi
            };

            const res = await httpAxios(api.setArtistList, dataSave);

            if (res.Code != 200) {
                ok = 1;
            }
        }

        //音乐
        if (this.state.yinyue.length > 0) {
            dataSave = {
                UserPID: this.state.userId,
                Type: 4,
                Info: this.state.yinyue
            };

            const res = await httpAxios(api.setArtistList, dataSave);

            if (res.Code != 200) {
                ok = 1;
            }
        }

        //活动
        if (this.state.huodong.length > 0) {
            dataSave = {
                UserPID: this.state.userId,
                Type: 5,
                Info: this.state.huodong
            };

            const res = await httpAxios(api.setArtistList, dataSave);

            if (res.Code != 200) {
                ok = 1;
            }
        }

        //获奖奖励
        if (this.state.huojiangjingli.length > 0) {
            dataSave = {
                UserPID: this.state.userId,
                Type: 6,
                Info: this.state.huojiangjingli
            };

            const res = await httpAxios(api.setArtistList, dataSave);

            if (res.Code != 200) {
                ok = 1;
            }
        }

        //代言、广告
        if (this.state.daiyan.length > 0) {
            dataSave = {
                UserPID: this.state.userId,
                Type: 7,
                Info: this.state.daiyan
            };

            const res = await httpAxios(api.setArtistList, dataSave);

            if (res.Code != 200) {
                ok = 1;
            }
        }

        if (ok == 0) {
            Toast.info("数据保存成功");
        }
        else {
            Toast.info("数据保存失败");
        }

    }

    render() {
        const {
            mainPicture,
            personPicture,
            dianying,
            dianshiju,
            zongyi,
            yinyue,
            huodong,
            huojiangjingli,
            daiyan
        } = this.state;
        const { Option, OptGroup } = Select;

        return (
            <StarInfoWrapper>
                <Form
                    layout="horizontal"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 18 }}
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="中文名" name='ChineseName' rules={[{ required: true, message: '请输入中文名!' }]}>
                                <Input placeholder="请填写中文名"></Input>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="别名" name='EnglishName'>
                                <Input placeholder="请填写别名"></Input>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="性别" name='Sex'>
                                <Select placeholder="请选择性别">
                                    <Select.Option value="男">男</Select.Option>
                                    <Select.Option value="女">女</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="国籍" name='Country'>
                                <Select placeholder="请选择国籍">
                                    <Option value="中国">中国大陆地区</Option>
                                    <Option value="中国香港特别行政区">中国香港特别行政区</Option>
                                    <Option value="中国澳门特别行政区">中国澳门特别行政区</Option>
                                    <Option value="中国台湾省">中国台湾省</Option>
                                    <Option value="境外">境外</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    {/* <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="民族" name='Nation'>
                                <Input placeholder="请填写民族"></Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="出生地" name='Birthplace'>
                                <Input placeholder="请填写出生地"></Input>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    {/* <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="生日" name='Birthday'>
                                <Input placeholder="请选择生日"></Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="星座" name='Constellation'>
                                <Input placeholder="请填写星座"></Input>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    {/* <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="民族" name='Nation'>
                                <Input placeholder="请填写民族"></Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="出生地" name='Birthplace'>
                                <Input placeholder="请填写出生地"></Input>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    {/* <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="血型" name='BloodGroup'>
                                <Select placeholder="请选择血型">
                                    <Option value="A">A</Option>
                                    <Option value="AB">AB</Option>
                                    <Option value="B">B</Option>
                                    <Option value="O">O</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="特长"
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 20 }}
                                name='Speciality'
                            >
                                <Input placeholder="请填写特长"></Input>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    {/* <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="身高（厘米）" name='Height'>
                                <InputNumber min={100} max={300} placeholder="请填写身高" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="体重（千克）" name='Weight'>
                                <InputNumber min={20} max={200} placeholder="请填写体重" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="所属单位" name='School'>
                                <Input placeholder="请填写所属单位"></Input>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="语言" name='Language'>
                                <Input placeholder="请填写语言"></Input>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="教学范围" name='Profession'>
                                <Select placeholder="请填写职业" mode="multiple" allowClear>
                                    <Option value="声乐">声乐</Option>
                                    <Option value="乐器">乐器</Option>
                                    <Option value="舞蹈">舞蹈</Option>
                                    <Option value="指挥">指挥</Option>
                                    <Option value="其他">其他</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            {/* className="ant-form-item-required" */}
                            <div className="ant-col ant-form-item-label" style={{ margin: '1.5rem 0 0.5rem' }}><label title="主图" style={{ fontSize: '1.2rem', fontWeight: '600' }}>主图</label><h5 className="sub-small-font text-muted">图片 500X500</h5></div>
                        </Col>
                        <Col span={24}>
                            <img src={mainPicture} alt="主图上传" onClick={() => this.updateLicense(3)} style={{ width: "100%" }} />
                            <input type="file" accept="image/*" hidden ref={ref => this.LicenseInput = ref} onChange={this.handleLicenseSelect} />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="ant-col ant-form-item-label" style={{ margin: '1.5rem 0 0.5rem' }}><label title="个人图片" style={{ fontSize: '1.2rem', fontWeight: '600' }}>个人图片</label><h5 className="sub-small-font text-muted">图片 500X500</h5></div>
                        </Col>
                        <Col span={24}>
                            <div className="row mt-3 banner-list">
                                {
                                    personPicture.map((image, index) => {
                                        return (
                                            image != "" &&
                                            <div id={`image${index}`} key={`image${index}`} className="col-3 mt-2 pr-0 position-relative img-wrap">
                                                <img src={image} style={{ width: "100%" }} alt="image" />
                                                <div className="edit_label" onClick={() => this.deleteImg(index)}><i className="mdi mdi-delete"></i>&nbsp;删除</div>
                                            </div>
                                        );
                                    })
                                }
                                <img id="img_land_poster" src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUIAAAC2CAYAAACs2YQnAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDQvMjgvMjBn0Np+AAAEVUlEQVR4nO3dzYoUSRiF4S/yp120UF3UHXj/1+QVCIkgaluZEbNwFJ0BwYXmjOd5dkX14pA0LxlZRXcbY4z62xijnp+f636/V++94E/UWqtvfu0JMMaoaZpqnuda17XWda3W2tf32xhj9N7r7du39fHjx1qWpeZ5rmmavvtB+FMIYZ4xRo0xqvde+75XVdXDw0NdLpfPrRtjjG3b6jiOenp6qmVZTp4M8Gvt+17bttW6rvX09FTTcRz14cOHut1uIghEWJalrtdrPT8/13EcNX369KkeHh4cg4EoXx4D3u/3mvZ9r3mez94E8NvN81z7vtfUe69pms7eA3CKMUYpIBCrtVa991p8jQBI9eWzkcWHJEA6R2MgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBvOXsAubZt++719Xo9aQnphJDTCCH/FY7GQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjE89dngFittaoSQiDYGKOqHI0BhBBACIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDz/4P0PtW1bbdt29oyf8vr167Mn/NDlcqnb7Xb2DH4Bd4RAPCEE4gkhEM8zwj/U9Xqt6/V69owf+uczwVevXp20hHTuCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOIJIRBPCIF4QgjEE0IgnhAC8YQQiCeEQDwhBOItZw8g1+VyOXsCVJUQcqLb7Xb2BKgqR2MAIQQQQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEE8IgXhCCMQTQiCeEALxhBCIJ4RAPCEE4gkhEGuMUa01IQRyjTGqqmqapunrC4Akvfea57mmZVnqOI6z9wD8dsdx1LIsNa3rWvu+177vZ28C+G2O46h932td1893hC9evKht2+p+v5+9DeCXu9/v9ebNm3p8fKxpmqqNMUbvvd69e1fv37+v1lrN81zzPFdrrVprZ28GfkJrzXP/b3y5FmOM6r3Xvu81xqjHx8d6+fLl586Nb67YGKP2fa/7/V699+q9u6DwPyOE/zZNn78gM89zreta67p+9/5f8aXexViRkPsAAAAASUVORK5CYII="} className="d-block" data-isvalidate="0" width="160" style={{ cursor: "pointer", width: "100%" }}
                                    onClick={() => this.updateLicense(4)} />
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group" style={{ margin: '1.5rem 0 0.5rem' }}>
                        <label htmlFor="brand_story" style={{ fontSize: '1.2rem', fontWeight: '600' }}>艺人介绍</label>
                        <div className="d-flex">
                            {/* <Form.Item>
                                    {getFieldDecorator('Introduce')
                                        (<TextArea rows={10} />)}
                                </Form.Item> */}
                            {/* <WangEditor imgType={64} ref={r => this.editor = r} uploadImageUrl={api.getDirectUploadSign} uploadImgType={4} uploadVideoCb={this.handleUploadVideo} getVideoImg={this.getVideoImg} /> */}
                            <ReactQuill theme="snow"
                                modules={this.modules}
                                formats={this.formats}
                                value={this.state.Introduce}
                                onChange={(value) => this.setState({ Introduce: value })
                                }
                            />
                        </div>
                    </div>
                    {/* {
                        dianying.map((item, index) => {
                            return (
                                <Row gutter={24} key={index}>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem', fontSize: '1.2rem', fontWeight: '600' }}>电影名称:</Col>
                                    <Col span={24} >
                                        <Input value={item.A} onChange={(e) => {
                                            let newItem = dianying.slice();
                                            newItem[index].A = e.target.value;

                                            this.setState({ dianying: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>播出时间:</Col>
                                    <Col span={24} >
                                        <DatePicker value={item.B == "" || item.B == undefined ? "" : moment(item.B)} onChange={(date, dateString) => {
                                            let newItem = dianying.slice();
                                            newItem[index].B = date;

                                            this.setState({ dianying: newItem })
                                        }
                                        } />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Select value={item.C == "" ? "请选择分工" : item.C} placeholder="请选择分工"
                                            onChange={(value) => {
                                                let newItem = dianying.slice();
                                                newItem[index].C = value;

                                                this.setState({ dianying: newItem })
                                            }}
                                        >
                                            <Option value="导演">导演</Option>
                                            <Option value="演员">演员</Option>
                                            <Option value="制片人">制片人</Option>
                                            <Option value="出品人">出品人</Option>
                                            <Option value="音乐">音乐</Option>
                                            <Option value="编剧">编剧</Option>
                                            <Option value="摄像">摄像</Option>
                                            <Option value="美术">美术</Option>
                                            <Option value="灯光">灯光</Option>
                                            <Option value="化妆">化妆</Option>
                                        </Select></Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>角色名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.D} onChange={(e) => {
                                            let newItem = dianying.slice();
                                            newItem[index].D = e.target.value;

                                            this.setState({ dianying: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Button type="primary" onClick={() => {
                                            let newItem = dianying.slice();
                                            //删除数据
                                            newItem.splice(index, 1);
                                            this.setState({ dianying: newItem });
                                        }}>删除</Button>
                                    </Col>
                                </Row>
                            );
                        })
                    } */}
                    {/* <Row gutter={24}>
                        <Col span={24}>
                            <Button type='primary' block onClick={() => {
                                this.setState({
                                    dianying: [...dianying, { A: "", B: "", C: "", d: "" }]
                                });
                            }}>添加电影</Button>
                        </Col>
                    </Row>
                    <DividingLine style={{ margin: '1rem auto' }} /> */}
                    {/* {
                        dianshiju.map((item, index) => {
                            return (
                                <Row gutter={24} key={index}>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem', fontSize: '1.2rem', fontWeight: '600' }}>电视剧名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.A} onChange={(e) => {
                                            let newItem = dianshiju.slice();
                                            newItem[index].A = e.target.value;

                                            this.setState({ dianshiju: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>播出时间:</Col>
                                    <Col span={24}>
                                        <DatePicker value={item.B == "" || item.B == undefined ? "" : moment(item.B)} onChange={(date, dateString) => {
                                            let newItem = dianshiju.slice();
                                            newItem[index].B = date;

                                            this.setState({ dianshiju: newItem })
                                        }
                                        } />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Select value={item.C == "" ? "请选择分工" : item.C} placeholder="请选择分工"
                                            onChange={(value) => {
                                                let newItem = dianshiju.slice();
                                                newItem[index].C = value;

                                                this.setState({ dianshiju: newItem })
                                            }}
                                        >
                                            <Option value="导演">导演</Option>
                                            <Option value="演员">演员</Option>
                                            <Option value="制片人">制片人</Option>
                                            <Option value="出品人">出品人</Option>
                                            <Option value="音乐">音乐</Option>
                                            <Option value="编剧">编剧</Option>
                                            <Option value="摄像">摄像</Option>
                                            <Option value="美术">美术</Option>
                                            <Option value="灯光">灯光</Option>
                                            <Option value="化妆">化妆</Option>
                                        </Select></Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>角色名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.D} onChange={(e) => {
                                            let newItem = dianshiju.slice();
                                            newItem[index].D = e.target.value;

                                            this.setState({ dianshiju: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Button type="primary" onClick={() => {
                                            let newItem = dianshiju.slice();
                                            //删除数据
                                            newItem.splice(index, 1);
                                            this.setState({ dianshiju: newItem });
                                        }}>删除</Button>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                    <Row gutter={24}>
                        <Col span={24}>
                            <Button type='primary' block onClick={() => {
                                this.setState({
                                    dianshiju: [...dianshiju, { A: "", B: "", C: "", d: "" }]
                                });
                            }}>添加电视剧</Button>
                        </Col>
                    </Row>
                    <DividingLine style={{ margin: '1rem auto' }} />
                    {
                        zongyi.map((item, index) => {
                            return (
                                <Row gutter={24} key={index}>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem', fontSize: '1.2rem', fontWeight: '600' }}>综艺名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.A} onChange={(e) => {
                                            let newItem = zongyi.slice();
                                            newItem[index].A = e.target.value;

                                            this.setState({ zongyi: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>播出时间:</Col>
                                    <Col span={24}>
                                        <DatePicker value={item.B == "" || item.B == undefined ? "" : moment(item.B)} onChange={(date, dateString) => {
                                            let newItem = zongyi.slice();
                                            newItem[index].B = date;

                                            this.setState({ zongyi: newItem })
                                        }
                                        } />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Button type="primary" onClick={() => {
                                            let newItem = zongyi.slice();
                                            //删除数据
                                            newItem.splice(index, 1);
                                            this.setState({ zongyi: newItem });
                                        }}>删除</Button>
                                    </Col>
                                </Row>
                            );
                        })
                    } */}
                    {/* <Row gutter={24}>
                        <Col span={24}>
                            <Button type='primary' block onClick={() => {
                                this.setState({
                                    zongyi: [...zongyi, { A: "", B: "", C: "", d: "" }]
                                });
                            }}>添加综艺</Button>
                        </Col>
                    </Row>
                    <DividingLine style={{ margin: '1rem auto' }} /> */}
                    {/* {
                        yinyue.map((item, index) => {
                            return (
                                <Row gutter={24} key={index}>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem', fontSize: '1.2rem', fontWeight: '600' }}>歌曲名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.A} onChange={(e) => {
                                            let newItem = yinyue.slice();
                                            newItem[index].A = e.target.value;

                                            this.setState({ yinyue: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>推出时间:</Col>
                                    <Col span={24}>
                                        <DatePicker value={item.B == "" || item.B == undefined ? "" : moment(item.B)} onChange={(date, dateString) => {
                                            let newItem = yinyue.slice();
                                            newItem[index].B = date;

                                            this.setState({ yinyue: newItem })
                                        }
                                        } />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Select value={item.C == "" ? "请选择分工" : item.C} placeholder="请选择分工"
                                            onChange={(value) => {
                                                let newItem = yinyue.slice();
                                                newItem[index].C = value;

                                                this.setState({ yinyue: newItem })
                                            }}
                                        >
                                            <Option value="制作人">制作人</Option>
                                            <Option value="演唱者">演唱者</Option>
                                            <Option value="词作者">词作者</Option>
                                            <Option value="曲作者">曲作者</Option>
                                        </Select></Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Button type="primary" onClick={() => {
                                            let newItem = yinyue.slice();
                                            //删除数据
                                            newItem.splice(index, 1);
                                            this.setState({ yinyue: newItem });
                                        }}>删除</Button>
                                    </Col>
                                </Row>
                            );
                        })
                    } */}
                    {/* <Row gutter={24}>
                        <Col span={24}>
                            <Button type='primary' block onClick={() => {
                                this.setState({
                                    yinyue: [...yinyue, { A: "", B: "", C: "", d: "" }]
                                });
                            }}>添加音乐</Button>
                        </Col>
                    </Row>
                    <DividingLine style={{ margin: '1rem auto' }} /> */}
                    {/* {
                        huodong.map((item, index) => {
                            return (
                                <Row gutter={24} key={index}>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem', fontSize: '1.2rem', fontWeight: '600' }}>活动名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.A} onChange={(e) => {
                                            let newItem = huodong.slice();
                                            newItem[index].A = e.target.value;

                                            this.setState({ huodong: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>活动时间:</Col>
                                    <Col span={24}>
                                        <DatePicker value={item.B == "" || item.B == undefined ? "" : moment(item.B)} onChange={(date, dateString) => {
                                            let newItem = huodong.slice();
                                            newItem[index].B = date;

                                            this.setState({ huodong: newItem })
                                        }
                                        } />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Button type="primary" onClick={() => {
                                            let newItem = huodong.slice();
                                            //删除数据
                                            newItem.splice(index, 1);
                                            this.setState({ huodong: newItem });
                                        }}>删除</Button>
                                    </Col>
                                </Row>
                            );
                        })
                    } */}
                    {/* <Row gutter={24}>
                        <Col span={24}>
                            <Button type='primary' block onClick={() => {
                                this.setState({
                                    huodong: [...huodong, { A: "", B: "", C: "", d: "" }]
                                });
                            }}>添加活动</Button>
                        </Col>
                    </Row>
                    <DividingLine style={{ margin: '1rem auto' }} /> */}
                    {
                        huojiangjingli.map((item, index) => {
                            return (
                                <Row gutter={24} key={index}>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem', fontSize: '1.2rem', fontWeight: '600' }}>奖励名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.A} onChange={(e) => {
                                            let newItem = huojiangjingli.slice();
                                            newItem[index].A = e.target.value;

                                            this.setState({ huojiangjingli: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>获奖时间:</Col>
                                    <Col span={24}>
                                        <DatePicker value={item.B == "" || item.B == undefined ? "" : moment(item.B)} onChange={(date, dateString) => {
                                            let newItem = huojiangjingli.slice();
                                            newItem[index].B = date;

                                            this.setState({ huojiangjingli: newItem })
                                        }
                                        } />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Button type="primary" onClick={() => {
                                            let newItem = huojiangjingli.slice();
                                            //删除数据
                                            newItem.splice(index, 1);
                                            this.setState({ huojiangjingli: newItem });
                                        }}>删除</Button>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                    <Row gutter={24}>
                        <Col span={24}>
                            <Button type='primary' block onClick={() => {
                                this.setState({
                                    huojiangjingli: [...huojiangjingli, { A: "", B: "", C: "", d: "" }]
                                });
                            }}>添加获奖经历</Button>
                        </Col>
                    </Row>
                    {/* <DividingLine style={{ margin: '1rem auto' }} />
                    {
                        daiyan.map((item, index) => {
                            return (
                                <Row gutter={24} key={index}>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem', fontSize: '1.2rem', fontWeight: '600' }}>项目名称:</Col>
                                    <Col span={24}>
                                        <Input value={item.A} onChange={(e) => {
                                            let newItem = daiyan.slice();
                                            newItem[index].A = e.target.value;

                                            this.setState({ daiyan: newItem })
                                        }} />
                                    </Col>
                                    <Col span={24} style={{ margin: '1.5rem 0 0.5rem' }}>
                                        <Button type="primary" onClick={() => {
                                            let newItem = daiyan.slice();
                                            //删除数据
                                            newItem.splice(index, 1);
                                            this.setState({ daiyan: newItem });
                                        }}>删除</Button>
                                    </Col>
                                </Row>
                            );
                        })
                    } */}
                    {/* <Row gutter={24}>
                        <Col span={24}>
                            <Button type='primary' block onClick={() => {
                                this.setState({
                                    daiyan: [...daiyan, { A: "", B: "", C: "", d: "" }]
                                });
                            }}>添加代言、广告项目</Button>
                        </Col>
                    </Row> */}
                    <DividingLine style={{ margin: '1rem auto' }} />
                    <MoreButtonBG style={{ marginBottom: '-2rem', marginLeft: '-4%', width: '100%' }}>
                        <Form.Item>
                            <Button type="primary" block htmlType="submit" style={{ backgroundImage: 'linear-gradient(135deg,#0060ec 0%,#B82cfc 100%)', borderRadius: '6px', height: '3.125rem', width: '92%', marginTop: '0.75rem', border: '0', marginLeft: '4%' }}>全部保存</Button>
                        </Form.Item>
                    </MoreButtonBG>
                </Form >
            </StarInfoWrapper>
        );
    }
}

class StarInfo extends Component {
    state = {
        activeNav: 'comment',
        bianjiID: 0
    }

    handleClick = (type) => {
        this.setState({
            activeNav: type
        })
    }

    getData = () => {

    }

    render() {

        return <>
            <BussFaBuYaoYueForm
            />
        </>
    }
    //发布邀约弹窗动作



    bussModal = async (e, id) => {

        const {
            form
        } = this.formRef.props;
        form.resetFields();
        if (e === '1') {
            const res = await httpAxios(api.getArtistInvitationDetailSelf, {
                "ID": id,
                "UserPID": this.state.userId,
            })
            // alert(JSON.stringify(res))
            if (res) {
                var data = {};
                data["title"] = res.Data?.Title;
                data["description"] = res.Data?.MoneyRange.split(',');
                data["invitationType"] = res.Data?.InvitationType.split(',');
                data["rangePicker"] = [moment(res.Data?.BeginDate), moment(res.Data?.EndDate)];
                data["place"] = res.Data?.Place;
                data["details"] = res.Data?.Detail;
                data["contacts"] = res.Data?.ContactsName;
                data["phone"] = res.Data?.ContactsPhone;
                form.setFieldsValue(data);
                this.setState({
                    bianjiID: id,
                });
            }
        } else {
            this.setState({
                bianjiID: 0,
            });
        }
    };

    handleFaBuYaoYueCreate = () => {
        const {
            form
        } = this.formRef.props;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            var data = {};
            data["Title"] = values.title;
            data["MoneyRange"] = values.description[0];
            data["InvitationType"] = values.invitationType.toString();
            data["BeginDate"] = values.rangePicker[0];
            data["EndDate"] = values.rangePicker[1];
            data["Place"] = values.place;
            data["Detail"] = values.details;
            data["ContactsName"] = values.contacts;
            data["ContactsPhone"] = values.phone;
            form.resetFields();
            //保存数据
            this.addFaBuYaoYue(data);
        });
    };



    addFaBuYaoYue = async (data) => {
        const res = await httpAxios(api.setArtistInvitation, {
            "ID": this.state.bianjiID,
            "UserPID": this.state.userId,
            "Type": '2',
            "Title": data.Title,
            "BeginDate": data.BeginDate,
            "EndDate": data.EndDate,
            "InvitationType": data.InvitationType,
            "Place": data.Place,
            "Detail": data.Detail,
            "ContactsName": data.ContactsName,
            "ContactsPhone": data.ContactsPhone,
            "MoneyRange": data.MoneyRange
        })
        if (res) {
            message.info('提交成功，您的邀约已经提交至管理员，稍后工作人员匹配双方后会与您取得联系。');
        } else {
            message.info('提交出现错误，请联系管理员');
        }
    }
}

export default StarInfo;