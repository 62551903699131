import React, {
    Component
} from 'react';
import httpAxios2 from "../../../http/httpAxios2";
import httpAxios from "../../../http/httpAxios";
import api from "../../../http/api";
import {
    MoreWrapper,
    AboutCompany,
    CompanyIntroduction,
    CompanyIntroductionMore,
    ExperiencesWrapper
} from './ContentStyle';

class CompanyContentMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            moreFocus: false,
            companyShop: [],
            companyExperiences: [],
        };
    }

    componentDidMount() {
        this.getUserBInfo();
        this.getCompanyShop();
        this.getCompanyExperiences();
    }
    //公司简介
    getUserBInfo = async () => {
        const res = await httpAxios(api.getUserBInfo, {
            userBID: this.props.location.search.replace('?', ''),//从网址中取栏目类型
        })
        if (res) {
            this.setState({
                info: res.Data
            })
        }
    }

    //线上店铺
    getCompanyShop = async () => {
        const res = await httpAxios2(api.getCompanyShop, {
            UserBID: this.props.location.search.replace('?', ''),//从网址中取栏目类型
        })
        if (res) {
            this.setState({
                companyShop: res.Data
            })
        }
    }
    //体验店
    getCompanyExperiences = async () => {
        const res = await httpAxios2(api.getCompanyExperiences, {
            UserBID: this.props.location.search.replace('?', ''),//从网址中取栏目类型
            pageIndex: 1,
            pageSize: 10
        })
        if (res) {
            this.setState({
                companyExperiences: res.Data,
            })
        }
    }

    render() {
        const {
            info,
            moreFocus,
            companyShop,
            companyExperiences
        } = this.state;
        return (
            <MoreWrapper>
                <AboutCompany>
                    <div>{info.CompanyName}简介</div>
                    {!moreFocus ? <CompanyIntroduction>{info.Introduction}</CompanyIntroduction> : <CompanyIntroductionMore>{info.Introduction}</CompanyIntroductionMore>}
                    {!info.Introduction && "这个企业很神秘，什么也没留下"}
                    {!moreFocus ? <span onClick={() => { this.setState({ moreFocus: true }) }}>点击查看更多<i className="iconfont">&#xe7b6;</i></span> : <span onClick={() => { this.setState({ moreFocus: false }) }}>收起内容<i className="iconfont">&#xe747;</i></span>}
                </AboutCompany>
                {companyShop?.ShopLink && <a href={companyShop?.ShopLink} target='_blank' rel="noopener noreferrer">
                    <AboutCompany>
                    <div>在线商城</div>
                        <CompanyIntroductionMore style={{color:'#015FEC'}}>{companyShop.ShopName}</CompanyIntroductionMore>
                        <i className="iconfont">&#xe744;</i>
                    </AboutCompany>
                </a>}
                {
                    companyExperiences[0] && <AboutCompany>
                        <div>线下体验店</div>
                        {companyExperiences.map(d => (
                            <ExperiencesWrapper>
                                <CompanyIntroductionMore>{d.ShopName}</CompanyIntroductionMore>
                                <span>地址：{d.ShopAddress}</span>
                                <span>电话：{d.Telephone}</span>
                            </ExperiencesWrapper>
                        ))
                        }
                    </AboutCompany>
                }
            </MoreWrapper>
        )
    }
}

export default CompanyContentMore