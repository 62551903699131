import styled from 'styled-components';
import {
  theme, themenew
} from '../../../common/color';

export const NavBar = styled.div`
  width:100%;
  height:8.5rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.375rem;
    color:${themenew.text_color};
    margin-top:3.6rem;
    font-weight:700;
  }
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  left:4%;
  top:3.5rem;
  position: absolute;
  z-index: 20;
  color: #fff;
`
export const ReturnButtons = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-right:0.5rem ;
  margin-top:0.25rem ;
  color: #fff;
`
export const EnterpriseWrapper = styled.div`
  width: 92%;
  padding-top:3.25rem ;
  margin-left:4% ;
  display:flex ;
  justify-content:space-between;
  margin-bottom:20px ;
`
export const SearchIcon = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-right:4%;
  margin-top:3.5rem;
  float:right;
`
export const TopWrapper = styled.div`
  background: #fff;
  position: relative;
`
export const BGWrapper = styled.div`
  height: 11.5rem;
  width: 100%;
  background: #fff;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
    min-height:11.5rem;
    object-fit: cover;
    opacity: 0.58;
  }
`
export const BGShading = styled.div`
  position: absolute;
  z-index: 10;
  top: 9.85rem;
  img{
    width: 100%;
  }
`
export const HeadImg = styled.div`
  position: absolute;
  z-index: 99;
  width: 7.46rem;
  height: 7.46rem;
  top: 6.38rem;
  left: 50%;
  margin-left: -3.73rem;
  border-radius: 50%;
  background: #fff;
  border: 5px solid #FFFFFF;
  box-shadow: 0 1px 24px 0 rgba(0,0,0,0.10);
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
    min-height:7.46rem;
    object-fit: cover;
  }
`
export const HeadImgs = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background: #fff;
  border: 0.5px solid #FFFFFF;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.10);
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right:5px ;
  img{
    width: 100%;
    min-height:1.875rem;
    object-fit: cover;
  }
`
export const Focused = styled.div`
  width:3.625rem;
  height:1.75rem!important;
  line-height:1.75rem!important;
  font-size:0.875rem;
  text-align:center;
  background-color:#fff;
  color:#000;
  border:1px solid #888;
  border-radius:0.25rem;
  position: absolute;
  z-index: 88;
  right: 4%;
  top:3.25rem;
`
export const Focuseds = styled.div`
  width:3.625rem;
  height:1.75rem!important;
  margin-top:0.0625rem ;
  line-height:1.75rem!important;
  font-size:0.875rem;
  text-align:center;
  background-color:#fff;
  color:#000;
  border:1px solid #888;
  border-radius:0.25rem;
`
export const Focus = styled.div`
  width:3.625rem;
  height:1.75rem!important;
  line-height:1.75rem!important;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  border-radius: 4px;
  font-size:0.875rem;
  text-align:center;
  color:#fff;
  border-radius:0.25rem;
  position: absolute;
  z-index: 88;
  right: 4%;
  top:3.25rem;
`
export const Focuss = styled.div`
  width:3.625rem;
  height:1.75rem!important;
  line-height:1.75rem!important;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  border-radius: 4px;
  font-size:0.875rem;
  text-align:center;
  color:#fff;
  border-radius:0.25rem;
`
export const EnterpriseName = styled.div`
  width: 92%;
  margin-left: 4%;
  text-align: center;
  font-size: 0.88rem;
  color: ${themenew.text_color};
  margin-bottom: 0.7rem;
`
export const EnterpriseNames = styled.div`
  width:200px ;
  line-height:1.875rem ;
  font-size: 20px;
  color: ${themenew.text_color};
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
`
export const SubTitle = styled.div`
  width: 92%;
  margin-left: 4%;
  text-align: center;
  font-size: 0.75rem;
  color: ${themenew.subtext_color};
  //margin-bottom: 1.15rem;
  line-height: 18px;
`
export const BrandWrapper = styled.div`
  width: 100%;
  padding: 0 4%;
  text-align: center;
  margin-bottom: 1.25rem;
`
export const Fragment = styled.div`
  background: #3544C4;
  display: inline-block;
  height: 2.25rem;
  color: #fff;
  font-size: 0.75rem;
  padding:0 1.375rem;
  line-height: 2.25rem;
  margin:0.375rem 0.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 5px 20px 0 rgba(53,68,196,0.30);
`
export const ShopButton = styled.div`
  width: 57%;
  height: 3.13rem;
  line-height: 3.13rem;
  background-image: linear-gradient(134deg, #3023AE 0%, #C86DD7 100%);
  box-shadow: 0 11px 15px 0 rgba(14,72,70,0.23);
  border-radius: 0.25rem;
  color: #fff;
  font-size: 0.88rem;
  margin:0 auto;
  text-align: center;
  margin-bottom: 1.25rem;
`
export const AboutCompany = styled.div`
  width: 92%;
  margin-left: 4%;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 0.375rem;
  font-size: 0.75rem;
  color: ${themenew.subtext_color};
  line-height: 1rem;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  position: relative;
  >div{
    margin-bottom: 0.5rem;
  }
  >span{
    display: block;
    text-align: right;
  }
  >i{
    font-size: 2rem;
    color: #D6D6D6;
    position: absolute;
    right: 0.5rem;
    top: 42%;
  }
`
export const CompanyIntroduction = styled.div`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  font-size: 0.88rem;
  color: ${themenew.text_color};
  margin-bottom: 0.5rem;
  line-height: 1.25rem;
`
export const CompanyIntroductionMore = styled.div`
  width: 100%;
  font-size: 0.88rem;
  color: ${themenew.text_color};
  margin-bottom: 0.5rem;
  line-height: 1.25rem;
`
export const NewNumWrapper = styled.div` 
  display:flex;
  //margin-top:14px;
  width:92%;
  height: 128px;
  margin-left:4%;
  background-image: url(${require("../../../images/newMyCenterBG.png")});
  background-size:100% auto;
  background-repeat: repeat-x;
  .fans {
    flex:1;
    width:3.75rem;
    height:4rem;
    float:left;
    text-align:center;
    padding-top: 36px;
    .num {
      color: #fff;
      font-size:1.3rem; 
    }
    .title{
      color: #fff;
      margin-top:.6rem;
      font-size:0.88rem;
    }
  }
`
export const NumWrapper = styled.div` 
  display:flex;
  width:92%;
  height:4.5rem;
  margin-left:4%;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
`
export const Fans = styled.div`
  flex:1;
  width:3.75rem;
  height:4rem;
  float:left;
  text-align:center;
  padding-top:0.8rem;
`
export const Num = styled.div`
  color:${themenew.text_color};
  font-size:1.3rem;
`
export const Title = styled.div`
  color:${themenew.subtext_color};
  margin-top:.6rem;
  font-size:0.88rem;
`
export const LabelOne = styled.span`
  position: absolute;
  z-index: 999;
  height: 30px;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  color:${themenew.text_white_color};
  line-height: 30px;
  padding:0 1rem;
  border-radius:1.25rem;
  font-size:0.88rem;
  top:7rem;
  left:18%;

`
export const LabelTwo = styled.span`
  position: absolute;
  z-index: 999;
  height: 30px;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  color:${themenew.text_white_color};
  line-height: 30px;
  padding:0 1rem;
  border-radius:1.25rem;
  font-size:0.88rem;
  top:11.19rem;
  left:23%;
`
export const LabelThree = styled.span`
  position: absolute;
  z-index: 999;
  height: 30px;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  color:${themenew.text_white_color};
  line-height: 30px;
  padding:0 1rem;
  border-radius:1.25rem;
  font-size:0.88rem;
  top:7.94rem;
  right: 14%;
`
export const MoreWrapper = styled.div`
  padding:1.25rem 0;
`
export const ExperiencesWrapper = styled.div`
  margin-bottom: 1.5rem !important;
  >span{
    display: block;
    line-height: 1.25rem;
    font-size: 0.88rem;
  }
`
export const ShopWrapper = styled.div`
  width:92%;
  height: 150px;
  margin-left:4%;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  padding:13px 15px ;
  position:relative;
`
export const ShopBox = styled.div`
  width:100% ;
  display:flex ;
  justify-content:space-between;
  `
export const CompanyShopWrapper = styled.div`
  width:46%;
  >span{
    font-size: 12px;
    color: ${themenew.subtext_color};
    margin-bottom:8px ;
    display: block;
  }
  b{
    display:block ;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
  }
  >a{
    >div{
      font-size: 12px;
      color:${themenew.text_color};
      line-height:20px ;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  >div{
    >div{
      font-size: 12px;
      color:${themenew.text_color};
      line-height:20px ;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap
    }
  }
`

export const ShopMove = styled.div`
  border-top:0.5px solid ${themenew.line_color};
  width:92% ;
  height:40px ;
  line-height:40px ;
  position:absolute ;
  bottom: 0;
  text-align:center ;
`
