import styled from 'styled-components';
import {
  themenew
} from '../../../../common/color';
export const StarInfoWrapper = styled.div`
  padding: 2rem 4% 3rem;
  background-color: #fff;
`
export const StarList = styled.div`
  width: 92%;
  margin-left: 4%;
  padding-top: 1rem;
  padding-bottom: .1rem;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >h5{
    >i{
        font-size: 22px;
    }
    float: right;
  }
  >h1{
    font-size: 18px;
    font-weight: 600;
    color: ${themenew.text_color};
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  >div{
    margin-bottom: 1rem;
    >span{
      display: inline-block;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      height: 1rem;
      >img{
        float: left;
        width: 15px;
        height: 15px;
        margin-right: 6px;
        color: ${themenew.subtext_color};
      }
      >h3{
        //float: left;
        display: inline;
        font-size: 14px;
        color: ${themenew.subtext_color};
        //margin-right: 16px;
      }
    }
    >div{
      width: 72%;
      float: left;
      >span{
        border:1px solid ${themenew.subtext_color};
        color: ${themenew.subtext_color};
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 4px;
        margin-right: 8px;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }
    
    >h2{
      font-size: 20px;
      color: #EF4266;
      float: right;
    }
    >a{
      float: right;
      color: ${themenew.text_color};
      >span{
        font-size: 12px;
        line-height: 27px;
        display: inline-block;
      }
      >i{
        font-size: 18px;
        float: right;
      }
    }
  }
`
export const CountryList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    .hidden {
    display: none;
    }
    .fengexian{
      width:100%;
      height: .5rem;;
      background-color: #F6F6F6;
      margin-bottom: .5rem;
    }
    #app {
    height: 100%;
    font-size: 14px;
    }

    [data-reactroot] {
    height: 100%;
    }
`
export const CommunityDetailWrapper = styled.div`
  width:100%;
  background-color:#fff;
  margin:0 auto;
  padding-bottom: 5rem;
  video{
    background-color: #000;
  }
`
export const ContentWrapper = styled.div`
  width:92%;
  padding-top:1rem;
  margin:0 4% 1.25rem;
`
export const Title = styled.div`
  width:100%;
  font-size:1.5rem;
  font-weight:bold;
  line-height:2rem;
  color: ${themenew.text_color};
`
export const SubTitle = styled.div`
  
  line-height: 55px;
  height: 55px;
  >span{
    width: 4px;
    height: 15px;
    background-color: #771AE3;
    display: inline-block;
    border-radius: 2px;
    margin-right:.5rem ;
    //float: left;
    margin-top:1.3rem;
  }
  >h2{
    font-size: 14px;
    color: ${themenew.text_color};
    display: inline-block;
  }
  >h1{
    display: inline-block;
    margin-left:20px;
    color: ${themenew.text_color};
    font-weight: 600;
  }
`
export const Content = styled.div`
  width:100%;
  font-size:1.1rem;
  line-height:2rem;
  min-height:25rem;
  img{
    width:100%!important;
    height:auto!important;
  }
  video{
    width:100%!important;
    height:auto!important;
  }
  .exhibitionBox{
    display: flex;
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }
  .exhibitionTitle{
    color:${themenew.text_color};
    font-size: 0.875rem;
    width: 22%;
  }
  .exhibitionText{
    color:${themenew.text_color};
    font-size: 0.875rem;
    width: 78%;
  }
`
export const MoreButtonBG = styled.div`
    width: 100%;
    height: 6.25rem;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 -2px 4px 0 rgba(2,4,51,0.08);
    z-index: 10;
`
export const MoreButton = styled.div`
    background-image: linear-gradient(135deg,#0060ec 0%,#B82cfc 100%);
    border-radius: 6px;
    height: 3.125rem;
    color: #fff;
    width: 88%;
    margin: 0 auto;
    text-align: center;
    font-size: 0.875rem;
    line-height: 3.125rem;
    margin-top: 0.75rem;
`
export const MoreFilterTab = styled.div`
    width: 23.5%;
    background: #f8f9fb;
    height: auto;
    position: fixed;
    top:3rem;
    div{
        height: 3.6rem;
        line-height: 3.6rem;
        text-align: center;
        font-size: 0.875rem;
        color: ${themenew.text_color};
    }
    .select{
        background: #fff;
    }
`