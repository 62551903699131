import * as constants from './constants';
import {fromJS} from 'immutable';

const defaultState=fromJS({
    login:"personlogin",
    ispersonlogin:false,
    iscompanylogin:false
});

export default(state=defaultState,action)=>{
    if(action.type===constants.PERSON_LOGIN_CLICK){
        return state.set('login','personlogin')
    }
    if(action.type===constants.COMPANY_LOGIN_CLICK){
        return state.set('login','companylogin')
    }
    if(action.type===constants.PERSON_LOGIN_BTN_CLICK){
        return state.set('ispersonlogin',action.value)
    }
    if(action.type===constants.COMPANY_LOGIN_BTN_CLICK){
        return state.set('iscompanylogin',action.value)
    }
    return state;
}

