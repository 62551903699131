import React, {
    Component
} from 'react';
import {
    message,
    Form,
    Input,
    Button,
    Cascader,
    Row,
    Col,
    Select
} from "antd";
import {
    Logo,
    RenZhengWrapper,
} from '../MycenterPortraitStyle';
import UUID from 'uuid/v1';
import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";

import {
    getBase64,
    uploadImgWithName
} from "../../../../utils/utils";

const {
    Option
} = Select;

const identityclass = [
    {
        value: '身份证',
        label: '身份证',
    },
    {
        value: '护照',
        label: '护照',
    },
    {
        value: '港澳居民来往内地通行证',
        label: '港澳居民来往内地通行证',
    },
    {
        value: '台湾居民往来大陆通行证',
        label: '台湾居民往来大陆通行证',
    },
    {
        value: '外国人居留证',
        label: '外国人居留证',
    },
]

var imageType = 0;

class SendCode extends Component {

    state = {
        time: '',
        phoneHash: ''
    }

    trick = () => {
        let {
            time,
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }

    phoneHashData = (event) => {
        this.setState({
            phoneHash: event
        });
    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    sendCode = async () => {
        if (!this.props.phone) {
            message.info('请输入手机号')
            return
        }
        // try {
        const res = await httpAxios(this.props.url, {
            phone: this.props.phone
        })
        if (res) {
            clearTimeout(this.timeId);
            this.setState({
                time: 59,
                phoneHash: res.Data
            })
            this.timeId = setInterval(this.trick, 1000);
            this.phoneHashData(this.state.phoneHash);
        }
    }
    render() {
        return (
            <>
                {
                    this.state.time > 0 ? <Button disabled>{`${this.state.time}s 后重发`}</Button> : <Button onClick={this.sendCode}>发送验证码</Button>
                }
            </>
        )
    }
}

class StarRenZheng extends Component {
    state = {
        userId: '',
        StarName: this.props.info?.UserName,
        GenderType: [],
        UserType: 2,
        IDCardType: this.props.info?.IDCardType === undefined ? ['身份证'] : [this.props.info?.IDCardType],
        Identity: this.props.info?.IDCardNum,
        prosImg: this.props.info?.IDCardFront || require('../../../../images/addimg.png'),
        consImg: this.props.info?.IDCardBack || require('../../../../images/addimg.png'),
        LegalPersonName: this.props.info?.ContactsName,
        PhoneNo: this.props.info?.ContactsPhone,
        validatePhone: "success",
        validateCode: '',
    };

    async componentDidMount() {
        // alert(this.state.prosImg)
        var that = this;
        window.userInfo = function (data, params) {
            // alert(JSON.stringify(data))
            that.setState({
                userId: data.UserPID
            })

            httpAxios(api.getBussInfo, {
                userid: data.UserPID,
                token: data.Token
            }).then(res => {
                if (res.Code === 200) {
                    if (res.Data.ManagerName !== null) {
                        that.setState({
                            StarName: res.Data.ManagerName,
                            GenderType: res.Data.Sex,
                            UserType: res.Data.IDCardType != undefined ? res.Data.UserType : that.state.UserType,
                            IDCardType: res.Data.IDCardType != undefined ? [res.Data.IDCardType] : that.state.IDCardType,
                            Identity: res.Data.IDCardNum,
                            prosImg: res.Data.IDCardFront != undefined ? res.Data.IDCardFront : require('../../../../images/addimg.png'),
                            consImg: res.Data.IDCardBack != undefined ? res.Data.IDCardBack : require('../../../../images/addimg.png'),
                            LegalPersonName: res.Data.ContactsName,
                            PhoneNo: res.Data.ContactsPhone,
                        });
                    }
                }
            });
        };
    }

    //上传图片
    updateLicense = (type) => {
        imageType = type;
        this.LicenseInput.click();
    }
    handleLicenseSelect = async (e) => {
        const file = e.target.files[0];
        const file64 = await getBase64(file);
        const suffix = file.name.split('.')[1];
        const name = UUID() + '.' + suffix;
        if (file.size > 2 * 1024 * 1024) {
            message.error('上传图片大小不能超过2MB');
            return
        }
        const url = await uploadImgWithName(api.bcUploadProductImg, file, name, {
            FileName: name
        })
        if (url) {
            switch (imageType) {
                case 3://证件证明
                    this.setState({
                        prosImg: url
                    })
                    break;
                case 4://证件反面
                    this.setState({
                        consImg: url
                    })
                    break;
            }
        }
    }

    //保存数据
    handleSave = () => {
        // e.preventDefault();

        let ok = "ok";
        if (this.state.StarName === "") {
            this.setState({ validateStarName: "error" });
            ok = "false1";
        }
        else {
            this.setState({ validateStarName: "success" });
        }

        if (this.state.GenderType === undefined) {
            this.setState({ validateGenderType: "error" });
            ok = "false2";
        }
        else {
            this.setState({ validateGenderType: "success" });
        }

        if (this.state.UserType === undefined) {
            this.setState({ validateUserType: "error" });
            ok = "false2";
        }
        else {
            this.setState({ validateUserType: "success" });
        }

        if (this.state.IDCardType === "" && this.state.IDCardType == null) {
            this.setState({ validateIDCardType: "error" });
            ok = "false8";
        }
        else {
            this.setState({ validateIDCardType: "success" });
        }

        if (this.state.Identity === "") {
            this.setState({ validateIdentity: "error" });
            ok = "false9";
        }
        else {
            this.setState({ validateIdentity: "success" });
        }

        if (this.state.prosImg.indexOf("http") === -1) {
            this.setState({ validateprosImg: "error" });
            ok = "false10";
        }
        else {
            this.setState({ validateprosImg: "success" });
        }

        if (this.state.consImg.indexOf("http") === -1) {
            this.setState({ validateconsImg: "error" });
            ok = "false11";
        }
        else {
            this.setState({ validateconsImg: "success" });
        }

        if (this.state.LegalPersonName === "") {
            this.setState({ validateLegalPersonName: "error" });
            ok = "false12";
        }
        else {
            this.setState({ validateLegalPersonName: "success" });
        }

        if (this.state.PhoneNo === "") {
            this.setState({ validatePhone: "error" });
            ok = "false13";
        }
        else {
            this.setState({ validatePhone: "success" });
        }

        if (ok !== "ok") {
            console.log(ok)
            return;
        }


        let data = {
            UserPID: this.state.userId,
            CompanyName: this.state.StarName,
            Sex: this.state.GenderType,
            UserType: this.state.UserType,
            IDCardType: this.state.IDCardType[0],
            IDCardNum: this.state.Identity,
            IDCardFront: this.state.prosImg,
            IDCardBack: this.state.consImg,
            ContactsName: this.state.LegalPersonName,
            ContactsPhone: this.state.PhoneNo,
            yzm: this.state.yzm
        }

        // console.log(data);

        httpAxios(api.bcArtistApply, data).then(res => {
            // console.log(res);
            if (res.Code === 200) {
                // this.getCheckAuthorization();
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证成功', weburl: '/RenZhengOK' }));
            }
        });
    }
    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    render() {
        const {
            StarName,
            validateStarName,
            GenderType,
            validateGenderType,
            UserType,
            validateUserType,
            IDCardType,
            validateIDCardType,
            Identity,
            validateIdentity,
            prosImg,
            validateprosImg,
            consImg,
            validateconsImg,
            LegalPersonName,
            validateLegalPersonName,
            PhoneNo,
            validatePhone,
            validateCode,
        } = this.state;
        const formItemLayout = {
            labelCol: {
                span: 5,
            },
            wrapperCol: {
                span: 10,
            },
        };
        const tailFormItemLayout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                xs: {
                    span: 0,
                    offset: 0,
                },
                sm: {
                    span: 10,
                    offset: 5,
                },
            },
        };
        return (
            <RenZhengWrapper>
                <Form {...formItemLayout} onFinish={this.handleSave}>
                    <Form.Item
                        label="艺人姓名"
                        validateStatus={validateStarName}
                        help={validateStarName === "error" ? "请输入艺人姓名" : ""}
                    >
                        <Input placeholder="请输入艺人姓名" value={StarName} onChange={(e) => { this.setState({ StarName: e.target.value.trim() }) }} />
                    </Form.Item>
                    <Form.Item
                        label="性别"
                        validateStatus={validateGenderType}
                        help={validateGenderType === "error" ? "请输选择性别" : ""}
                    >
                        <Select
                            value={GenderType}
                            onChange={(value) => this.setState({ GenderType: value })}
                        >
                            <Option key='女'>女</Option>
                            <Option key='男'>男</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="认证身份"
                        validateStatus={validateUserType}
                        help={validateUserType === "error" ? "请选择您要认证的身份" : ""}
                    >
                        <Select
                            value={UserType == "1" ? "艺人" : "教师"}
                            onChange={(value) => { this.setState({ UserType: value }) }}
                        >
                            <Option key='2'>教师</Option>
                            <Option key='1'>艺人</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="证件类型"
                        validateStatus={validateIDCardType}
                        help={validateIDCardType === "error" ? "请选择证件类型" : ''}
                    >
                        <Cascader
                            defaultValue={IDCardType}
                            options={identityclass}
                            onChange={(value) => this.setState({ IDCardType: value })}
                        />
                    </Form.Item>
                    <Form.Item label="证件号码"
                        validateStatus={validateIdentity}
                        help={validateIdentity === "error" ? "请输入证件号码" : ''}
                    >
                        <Input placeholder="请输入证件号码" value={Identity} onChange={(e) => { this.setState({ Identity: e.target.value.trim() }) }} />
                    </Form.Item>
                    <Form.Item label="上传证件正面"
                        validateStatus={validateprosImg}
                        help={validateprosImg === "error" ? "请上传证件正面" : ''}
                    >
                        <Logo>
                            <img src={prosImg} alt="" onClick={() => this.updateLicense(3)} />
                            <input type="file" accept="image/*" hidden ref={ref => this.LicenseInput = ref} onChange={this.handleLicenseSelect} />
                        </Logo>
                    </Form.Item>
                    <Form.Item label="上传证件反面"
                        validateStatus={validateconsImg}
                        help={validateconsImg === "error" ? "请上传证件反面" : ''}
                    >
                        <Logo>
                            <img src={consImg} alt="" onClick={() => this.updateLicense(4)} />
                        </Logo>
                    </Form.Item>
                    <Form.Item label="联系人姓名"
                        validateStatus={validateLegalPersonName}
                        help={validateLegalPersonName === "error" ? "请输入联系人姓名" : ''}
                    >
                        <Input placeholder="请输入联系人姓名" value={LegalPersonName} onChange={(e) => { this.setState({ LegalPersonName: e.target.value.trim() }) }} />
                    </Form.Item>
                    <Form.Item
                        label="联系人手机号"
                        validateStatus={validatePhone}
                        help={validatePhone === "error" ? "请输入正确的手机号" : ''}
                    >
                        <Input placeholder="请输入联系人手机号" value={PhoneNo} onChange={(e) => { this.setState({ PhoneNo: e.target.value.trim() }) }} onBlur={this.handlePhoneNo} />
                    </Form.Item>
                    <Form.Item
                        label="手机验证码"
                        validateStatus={validateCode}
                        help={validateCode === "error" ? "验证码不正确" : ''}
                    >
                        <Row gutter={8}>
                            <Col span={16}>
                                <Input onChange={(e) => { this.setState({ yzm: e.target.value.trim() }) }} />
                            </Col>
                            <Col span={8}>
                                <SendCode url={api.loginCode} phone={PhoneNo} />
                            </Col>
                        </Row>
                    </Form.Item >

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" block >
                            提交
                        </Button>
                    </Form.Item>
                </Form >
            </RenZhengWrapper>
        )
    }
}

export default StarRenZheng;