import styled from 'styled-components';
import {
  theme,
  themenew
} from "./color";

/**
 * created by sunchenyao on 2021/2/4
 */

//List相关
export const SettingsWrapper = styled.div`
.listheight {
  height:3.125rem;
  .am-list-content{
    color:${themenew.text_color};
  }
}
`
//NavBar相关
export const NavBar = styled.div`
width:100%;
height:5.5rem;
background-color:#fff;
position:fixed;
top:0;
z-index: 10;
box-shadow: 0 0.5px 0 ${themenew.line_color};
>span{
  float:left;
  margin-left:1.3rem;
  line-height:1.375rem;
  font-size:1.125rem;
  color:${themenew.text_color};
  margin-top:3.55rem;
}
>a{
  float:right;
  margin-right:1.3rem;
  line-height:1.375rem;
  font-size:0.875rem;
  color:${themenew.link_color};
  margin-top:3.675rem;
}
`
export const ReturnButton = styled.div`
width:1.375rem;
height:1.375rem;
margin-left:4%;
margin-top:3.5rem;
float:left;
`
//数据流相关
export const NewsDetailWrapper = styled.div`
  width:92%;
  margin:0 auto;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  //padding-bottom:1rem;
`

export const NewsImgWrap = styled.div`
  width:7.5rem;
  height:5rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-left:0.875rem;
  >img{
    width:100%;
    min-height:5rem;
    object-fit: cover;
  }
`
export const NewsThreeImgWrap = styled.div`
  display:flex;
  margin-top:0.375rem;
  div{
    flex:1;
    margin:0 0.093rem;
    height:4.6875rem;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    img{
      width:100%;
      min-height:4.6875rem;
      object-fit: cover;
    }
  }
`
export const NewsItem = styled.div`
  width:100%;
  display:flex;
  padding-top:0.8rem;
`
export const NewsItemOther = styled.div`
  width:100%;
  padding-top:0.8rem;
  position: relative;
`
export const NewsTitle = styled.div`
  flex:1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-height:3.125rem;
  font-size:1.125rem;
  line-height:1.7rem;
  color:${themenew.text_color};
  span{
    color:${themenew.link_color};
  }
`
export const NewsTitleNoImg = styled.div`
  flex:1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size:1.125rem;
  line-height:1.7rem;
  color:${themenew.text_color};
  span{
    color:${themenew.link_color};
  }
`
export const NewsTitleOneImg = styled.div`
  flex:1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height:5rem;
  font-size:1.125rem;
  line-height:1.7rem;
  color:${themenew.text_color};
  span{
    color:${themenew.link_color};
  }
`
export const NewsPubs = styled.div`
  width:100%;
  line-height:2rem;
  margin-top: 0.6rem;
  position: relative;
  >div{
    position: absolute;
    width: 8px;
    top: 7px;
    left: 12px;
    >img{
      width: 100%;
    }
  }
  >img{
    width:1.125rem;
    height:1.125rem;
    border-radius:50%;
    margin-right:0.375rem;
    vertical-align:middle;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  }
  >span{
    display: inline-block;
    height: 1.125rem;
    line-height: 1.125rem;
    max-width: 9rem;
    margin-right:0.625rem;
    font-size:0.75rem;
    color:${themenew.subtext_color};
    vertical-align:middle;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
  }
`
export const NewsPub = styled.div`
  width:100%;
  line-height:2rem;
  margin-top: 0.6rem;
  position: relative;
  >img{
    width:1.125rem;
    height:1.125rem;
    border-radius:50%;
    margin-right:0.375rem;
    vertical-align:middle;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  }
  >span{
    display: inline-block;
    height: 1.125rem;
    line-height: 1.125rem;
    max-width: 9rem;
    margin-right:0.625rem;
    font-size:0.75rem;
    color:${themenew.subtext_color};
    vertical-align:middle;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
  }
  .exhibition-time{
    float: right;
    font-size:0.75rem;
    color:${themenew.subtext_color};
  }
`
export const VideoImg = styled.div`
  width:100%;
  height:12.125rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:0.375rem;
  position: relative;
  >img{
    width:100%;
    min-height:12.125rem;
    object-fit: cover;
  }
`
export const NoFocusBtnNews = styled.div`
  float:right;
  color:${themenew.subtext_color};
  vertical-align:middle;
  >span{
    font-size:0.75rem;
  }
  i {
      color: ${theme.rose_color};
      font-size:1.5rem ;
  }
`
export const BtnNews = styled.div`
  float:right;
  color:${themenew.subtext_color};
  vertical-align:middle;
  margin-right:1rem ;
  >span{
    font-size:0.75rem;
  }
  i {
      color: ${themenew.text_color};
      font-size:1.5rem ;
  }
`

export const VideoIcon = styled.div`
  width: 3.125rem;
  height: 3.125rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  img{
    width: 100%;
    height: 100%;
  }
`
export const LiveState = styled.div`
  position: absolute;
  left:0.75rem;
  bottom: 0.75rem;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  border-radius: 0.25rem;
  height: 1.25rem;
  padding: 0 0.88rem;
  line-height: 1.25rem;
  color: #fff;
  font-size: 0.75rem;
`
export const LiveStates = styled.div`
  position: absolute;
  left:0.75rem;
  bottom: 0.75rem;
  //background-color: #000;
  //opacity: 0.5;
  background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
  border-radius: 0.25rem;
  height: 1.25rem;
  padding: 0 0.88rem;
  line-height: 1.25rem;
  color: #fff;
  font-size: 0.75rem;
`

//缺省页样式
export const EmptyWrapper = styled.div`
  width:100%;
  min-height:48rem;
  padding-top:4rem;
`
export const Empty = styled.div`
  width:20rem;
  height:23rem;
  text-align:center;
  margin:38% auto 3rem;
  img{
    width:15.5rem;
  }
  b{
    display:inline-block;
    width:100%;
    font-size: 1.25rem;
    color: #00222C;
    margin-bottom:1rem;
  }
  span{
    display:inline-block;
    width:100%;
    font-size: 1rem;
    color: #6D6D6D;
    margin-bottom:2.38rem;
  }
  botton{
    display:inline-block;
    width:18.2rem;
    height:3.5rem;
    background: #771AE3;
    border-radius: 0.5rem;
    line-height:3.5rem;
    color:#fff;
    :hover {
        background-color: ${theme.rose_color};
        cursor: pointer;
    }
  }
`

//网页翻页样式
export const PurplePaginationWrapper = styled.div`
  // width:75rem;
  text-align:center;
  margin-top:3rem;
  
  .ant-pagination{
    display: inline-block 
  }
  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active){
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

  }
  .ant-pagination-item{
    line-height: 1.5rem;
    display: inline-block;
    background: transparent;
    border-radius: 0.125rem;
    margin: 0rem 0.3125rem!important;
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.65)!important;
    &:hover{
        border: 0.0625rem solid ${theme.theme_color};
        a{
            color: rgba(0, 0, 0, 0.25);
        }
    }
    &:focus{
        border: 0.0625rem solid ${theme.theme_color};
        a{
            color: #fff
        }
    }
  }
  >span{
    vertical-align:top;
    color: rgba(0, 0, 0, 0.25)
  }
  a:visited{
    color: #fff;
  }
  .ant-pagination-item-active{
        border: 0.0625rem solid ${theme.theme_color};
  }
    .ant-pagination-item-active a{
        color: #fff;
        background-color: ${theme.theme_color}
    }
    .ant-pagination-prev, .ant-pagination-next{
        border: 0.0625rem solid rgba(0, 0, 0, 0.25);
        i {
            vertical-align: 0
        }
    }
`