import React, {
    Component
} from 'react';

import {
    PaymentButton,
    PaymentWrapper,
    TextBox
} from '../MycenterPortraitStyle';

import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";

class EnterpriseRenZhengPayment extends Component {
    state = {
        userId: "",
        userName: ""

    };

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            //alert(JSON.stringify(data))
            that.setState({
                userId: data.UserPID,
                userName: data.UserName
            })
        };

        //微信支付
        window.weiXinPay = function (ok, message) {
            if (ok == 0) {
                //alert(message);
            }
            else {
                //微信
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '支付完成', weburl: '/RenZhengOK' }));
            }
        }
    }

    //保存数据
    handleSave = (paymentType, flag) => {
        let data = {
            ID: localStorage.getItem("CurrentOrderFormID") ?? 0,
            UserID: this.state.userId,
            Subject: "企业认证费用",
            Body: "认证费用300元",
            ToalAmount: 300,
            PaymentType: paymentType
        }

        // console.log(data);

        httpAxios(api.bcOrderForm, data).then(res => {
            // console.log(res);
            if (res.Code === 200) {

                localStorage.setItem("CurrentOrderFormID", res.Data);

                //调用支付
                if (flag == 1) {
                    //微信预付款单
                    try {
                        httpAxios(api.addWeiXinAppPay, { PayNo: res.Data, UserName: this.state.userName }).then(res1 => {
                            if (res1.Code == 200) {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "weixinpay", payload: res1.Data }));
                            }
                            else {
                                alert(res1.Data);
                            }
                        });
                    }
                    catch (erro) {
                        alert("2" + JSON.stringify(erro));
                    }
                }

                if (flag == 2) {
                    //支付宝
                    // window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证成功', weburl: '/RenZhengOK' }));
                }

                // if (flag == 3) {
                //     //对公转账
                //     // window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '认证成功', weburl: '/RenZhengOK' }));
                // }
            }
        });
    }

    render() {
        const {

        } = this.state;
        return (
            <PaymentWrapper>
                <TextBox>
                    <h2>支付金额</h2>
                    <div>
                        <h3>¥</h3>
                        <h1>300.00</h1>
                    </div>
                </TextBox>
                <PaymentButton style={{ backgroundColor: '#1AAD19' }} onClick={() => this.handleSave("微信支付", 1)}>微信支付</PaymentButton>
                {/* <PaymentButton style={{ backgroundColor: '#118AE2' }} onClick={() => this.handleSave("支付宝支付", 2)}>支付宝支付</PaymentButton> */}
                {/* <PaymentButton style={{ backgroundColor: '#EA9E42' }} onClick={() => this.handleSave("公对公转账支付", 3)}>公对公转账支付</PaymentButton> */}
                <PaymentButton style={{ backgroundColor: '#EA9E42' }} onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '查看对公转账信息', weburl: '/DuiGong' })); }}>公对公转账支付</PaymentButton>

            </PaymentWrapper>
        )
    }
}

export default EnterpriseRenZhengPayment;