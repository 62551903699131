/**
 * created by ChenyaoSun on 2020/11/12
 */
import React, {
    Component
} from 'react';
import {
    Checkbox
} from 'antd';
import {
    Link
} from 'react-router-dom';
import httpAxios2 from "../../../http/httpAxios2";
import api from "../../../http/api";
import {
    UserCheckWrap,
    BoxWrapper,
    Info,
    Title,
} from "./style";
import {
    Code,
    FormItem,
    FormItemVerification,
    LoginButton,
    ReturnButton,
    NavBar
} from "../../login/LoginStyle";
import { Toast } from 'antd-mobile-v2';
import 'antd-mobile-v2/dist/antd-mobile.css';
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
const history = creatHistory();//返回上一页这段代码

const returnButton = require('../../../images/return_button.svg')

class SendCode extends Component {

    state = {
        time: '',
        phoneHash:''
    }

    trick = () => {
        let {
            time,
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }

    sendCode = async () => {
        if (!this.props.phone) return;
        try {
            const res = await httpAxios2(this.props.url, {
                phone: this.props.phone
            })
            if (res) {
                clearTimeout(this.timeId);
                this.setState({
                    time: 59,
                    phoneHash: res.Data
                })
                this.timeId = setInterval(this.trick, 1000);
            };
            this.props.phoneHashData(this.state.phoneHash);
            // console.log(this.state.phoneHash);
        }
        catch (ex) {
            if (ex.status === 400) {
                Toast.info('此手机号码已被注册');
                this.props.showPhomeErro()
            }
        }
    }

    render() {
        return (
            <span>
                {
                    this.state.time > 0 ? <Code>{`${this.state.time}s 后重发`}</Code> : <Code onClick={this.sendCode}>发送验证码</Code>
                }
            </span>
        )
    }
}
export default class PersonRegister extends Component {
    state = {
        name: '',
        loginEmail: '',
        password: '',
        confirmPass: '',
        code: '',
        phone: '',
        phoneHash: '',
        confirmProtocol: false,
        nameValid: false,
        loginEmailValid: false,
        passwordValid: false,
        confirmPassValid: false,
        codeValid: false,
        // phoneValid: false,
        passwordStatus: '',
        yzmStatus: '',
        PhoneStatus: '',
    }
    checkPassword = (e) => {
        let value = e.target.value;
        value = value.trim();
        let myreg = /^(?:(?=.*[a-zA-Z])(?=.*[0-9])).{6,20}$/
        if (!myreg.test(value)) {
            Toast.info('请输入6~20位密码，必须包含字母、数字');
            this.setState({
                passwordValid: false,
                passwordStatus: 'error'
            })
            return
        }
        this.setState({
            passwordValid: true,
            passwordStatus: 'success',
            password: value
        })
    }

    checkPhone = (e) => {
        let value = e.target.value;
        value = value.trim();
        let myreg = /^1[3456789]\d{9}$/
        if (!myreg.test(value)) {
            this.setState({
                PhoneStatus: 'error'
            })
        } else {
            this.setState({
                phone: value,
                PhoneStatus: 'success'
            })
        }

    }

    checkCode = async (e) => {
        let value = e.target.value;
        value = value.trim();
        if (!value) {
            this.setState({
                codeValid: false,
                yzmStatus: 'error'
            })
        } else {
            this.setState({
                codeValid: true,
                code: value,
                yzmStatus: 'success'
            })
        }
    }

    showPhomeErro = () => {
        this.setState({
            PhoneStatus: 'error',
        })
    }

    phoneHashData = (event) =>{
        this.setState({
            phoneHash: event
        });
        // console.log(this.state.phoneHash)
    }

    checkProtocol = () => {
        this.setState({
            confirmProtocol: !this.state.confirmProtocol
        })
    }

    register = async () => {
        const {
            password,
            code,
            phone,
            confirmProtocol,
            phoneHash
        } = this.state;
        if (!confirmProtocol) {
            Toast.info('请先勾选我同意《用户服务协议》')
            return
        }
        if (password && code && phone) {
            const res = await httpAxios2(api.userSignIn, {
                "NickName": "",
                "Phone": phone,
                "Password": password,
                "yzm": code,
                "Email": "",
                "PhoneHash": phoneHash
            }).then(res => {
                if (res.Code === 200) {
                    Toast.success(res.Message);
                    setTimeout(() => {
                        httpAxios2(api.userLogin, {
                            Account: phone,
                            Password: password
                        }).then(res => {
                            if (res.Code === 200) {
                                //给webview传值
                                window.ReactNativeWebView.postMessage(JSON.stringify(res.Data))
                            }
                        })
                    }, 1000)
                } else {
                    Toast.error(res.Message);
                }
            }).catch(e => {
                const Message = (e && e.data && e.data.Message) || {};
                // message.error(Message)
                const ERROR_MESSAGE = [
                    'NickName', 'Phone', 'Email', 'yzm'
                ];
                let target = [];
                for (let k in Message) {
                    for (let i = 0; i < ERROR_MESSAGE.length; i++) {
                        let item = ERROR_MESSAGE[i];
                        let status = item + 'Status'
                        if (k.includes(item)) {
                            this.setState({
                                [item]: Message[k][0],
                                [status]: 'error'
                            });
                            target.push(status);
                        }
                    }
                }
            })
        } else {
            Toast.info('请先将注册信息填写完整')
        }
    }

    toRegister = () => {
        sessionStorage.setItem('AUDIO_FILE_TYPE', 'register')
        this.registerInput.click()
    }
    goBackPage = () => {
        history.goBack();  //返回上一页这段代码
    }

    render() {
        const {
            passwordStatus,
            yzmStatus,
            PhoneStatus,
            phone,
        } = this.state;
        return (
            <>
                <NavBar>
                    <ReturnButton onClick={this.goBackPage}><img src={returnButton} alt="返回" /></ReturnButton>
                </NavBar>
                <Title><div>创建帐号</div><span>请按以下格式输入您的信息</span></Title>
                <BoxWrapper>
                    <span>手机号</span>
                    <FormItem>
                        <input className={PhoneStatus} type="number" placeholder='请输入您的手机号' onBlur={this.checkPhone} />
                    </FormItem>
                    <span>验证码</span>
                    <FormItemVerification>
                        <input className={yzmStatus} type="number" placeholder='请输入手机验证码' onBlur={this.checkCode} />
                        <SendCode url={api.userSendCode} phone={phone} showPhomeErro={this.showPhomeErro} phoneHashData={this.phoneHashData.bind(this)} />
                    </FormItemVerification>
                    <span>设置密码</span>
                    <FormItem>
                        <input className={passwordStatus} type="text" autoComplete="off" placeholder='请输入密码' onBlur={this.checkPassword} />
                    </FormItem>
                    <UserCheckWrap>
                        <Checkbox onClick={this.checkProtocol} />
                        <Info>我同意
                            <Link to='/file' ref={r => this.registerInput = r} />
                            <span className='cursor' onClick={this.toRegister}>《用户服务协议》</span>
                        </Info>
                    </UserCheckWrap>
                    <LoginButton onClick={this.register}>注册</LoginButton>
                </BoxWrapper>
            </>
        )
    }
}