import styled from 'styled-components';

export const MoreButtonBG = styled.div`
    width: 100%;
    //height: 7.8rem;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 -2px 4px 0 rgba(2,4,51,0.08);
    z-index: 10;
    .subtitle{
        text-align: center;
        margin-top: 4px;
        color: #7C8698;
        font-size: 12px;
    }
`
export const MoreButton = styled.div`
    background-image: linear-gradient(135deg,#0060ec 0%,#B82cfc 100%);
    border-radius: 6px;
    height: 3.125rem;
    color: #fff;
    width: 88%;
    margin: 0 auto;
    text-align: center;
    font-size: 0.875rem;
    line-height: 3.125rem;
    //margin-top: 0.75rem;
`
export const File = styled.div`
  width:100%;
  font-size:0.6875rem;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 12px;
  span{
    color:#015FEC;
  }
`
export const Focus = styled.div`
  height:28px;
  line-height:28px;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  border-radius: 4px;
  font-size:0.875rem;
  text-align:center;
  color:#fff;
  border-radius:0.25rem;
  width: 150px;
  margin: 0 auto;
  font-weight: 500;
`
export const DiyAppForm = styled.div`
  display: flex;
  width: 88%;
  margin-left: 6%;
  margin-right: 6%;
  height: 48px;
  font-size: 17px;
  color: #203152;
  border-bottom: #F2F2F2 1px solid;
  margin-top: 2px;
`
export const DiyAppFormLeft = styled.div`
  width: 33%;
  line-height: 48px;
`
export const DiyAppFormRight = styled.div`
  width: 67%;
  line-height: 48px;
  padding-top: 10px;
`
