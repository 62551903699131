import styled from 'styled-components';
import {
    themenew
} from '../../common/color';


export const MoreFilterWrapper = styled.div`
    .unchecked{
        display: none;
    }
`
export const CountryList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .hidden {
    display: none;
    }

    #app {
    height: 100%;
    font-size: 14px;
    }

    [data-reactroot] {
    height: 100%;
    }
`
// export const Detial = styled.div`
//     font-size: 16px;
//     color: #fff;
//     height: 50px;
//     line-height: 50px;
//     background-color: #fff;
//     text-align: center;
// `
export const CountryBorder = styled.div`
    flex: 1;
    overflow: auto;
    position: relative;
    .brandsList {
        padding: 0.75rem 4%;
        border-bottom: 0.5px solid #E1E1E1;
        text-align: left;
        background: #fff;
        height: 4.375rem;
    }
    .title{
        color: #4A4A4A;
        background: #F6F6F6;
        height: 1.75rem;
        padding: 0 4%;
        .buding{
            line-height: 1.75rem !important;
        }
    }
    .char-list-border {
        padding-top: 148px;
        position: fixed;
        right: 0;
        top: 0;
        width: 20px;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    .char-list-border-zhanhui {
        padding-top: 120px;
        padding-bottom: 60px;
        position: fixed;
        right: 0;
        top: 0;
        width: 20px;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    .char-list {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
    }
    .char-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
    .char-tip {
        position: fixed;
        width: 50px;
        height: 50px;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background-color: gray;
        border-radius: 6px;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 50px;
        text-align: center;
    }
`
export const BrandsListImg = styled.div`
    width: 2.875rem;
    height: 2.875rem;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 0.75rem;
    border-radius: 50%;
    >img{ 
        width:100%;
        min-height:2.875rem;
        object-fit: cover;
    }
`
export const BrandsListEn = styled.div`
    color: ${themenew.text_color};
    font-size: 1.125rem;
`
export const BrandsListEns = styled.div`
    color: ${themenew.text_color};
    font-size: 1.125rem;
    line-height: 46px;
    display: flex;
    justify-content:space-between;
    padding-right: 4%;
    i{
        font-size: 1.5rem;
        color: #015FEC;
    }
`
export const BrandsListCn = styled.div`
    color: ${themenew.subtext_color};
    font-size: 0.875rem;
    margin-top: 0.75rem;
`

export const NavBar = styled.div`
  width:100%;
  height:5.5rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.375rem;
    color:${themenew.text_color};
    margin-top:3.6rem;
    font-weight:700;
  }
`
export const SearchIcon = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-right:4%;
  margin-top:3.5rem;
  float:right;
`
export const FilterButton = styled.div`
    width: 100%;
    height: 3.75rem;
    line-height: 3.75rem;
    padding: 0 4%;
    background: #fff;
    border-bottom: 0.5px solid #D6D6D6;
    position:fixed;
    top:5.5rem;
    z-index: 9;
    font-size: 0.875rem;
    //position: relative;
    >div{
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        display: inline-block;
        background: #F3F5F9;
        border-radius: 4px;
        margin-right: 10px;
        color: #000;
    }
    >span{
        position: absolute;
        color: #000;
        display: inline-block;
        height: 3.65rem;
        background: #fff;
        right: 0;
        line-height: 3.65rem;
        width: 5rem;
        text-align: center;
        z-index: 5;
    }
`
export const FilterButtons = styled.div`
    width: 100%;
    height: 3.75rem;
    line-height: 3.75rem;
    padding: 0 4%;
    background: #fff;
    border-bottom: 0.5px solid #D6D6D6;
    position:fixed;
    z-index: 9;
    font-size: 0.875rem;
    >div{
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        display: inline-block;
        background: #F3F5F9;
        border-radius: 4px;
        margin-right: 10px;
        color: #000;
    }
    >span{
        position: absolute;
        color: #000;
        display: inline-block;
        height: 3.65rem;
        background: #fff;
        right: 0;
        line-height: 3.65rem;
        width: 5rem;
        text-align: center;
        z-index: 5;
    }
`
export const FilterContent = styled.div`
    width: 100%;
    height: 2.5rem;
    background: #fff;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    ::-webkit-scrollbar{
        width: 0;
        height: 0;
        display: none;
    } 
`
export const FilterContentlist = styled.div`
        height: 1.25rem;
        line-height: 1.25rem;
        flex-shrink: 0;
        background: #f3f5f9;
        padding: 0 0.5rem;
        border-radius: 0.25rem;
        margin-left: 0.75rem;
        margin-top: 0.5rem;
        font-size: 0.75rem;
        >i{
            float: right;
        }
`
export const FilterContentDeletion = styled.div`
    position: absolute;
    right: 0;
    width: 4.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    background: #fff; 
    padding-right: 1rem;
    i{
        font-size: 1.3rem;
        color: ${themenew.subtext_color};
        float: right;
    }
    span{
        font-size: 0.875rem;
        color: ${themenew.text_color};
        float: right;
    }
`
export const FilterContentDeletions = styled.div`
    position: fixed;
    right: 0;
    width: 4.5rem;
    height: 2.5rem;
    line-height: 2rem;
    background: #fff; 
    padding-right: 1rem;
    i{
        font-size: 1.3rem;
        color: ${themenew.subtext_color};
        float: right;
    }
    span{
        font-size: 0.875rem;
        color: ${themenew.text_color};
        float: right;
    }
`
export const ProductList = styled.div`
    width: 92%;
    margin-left: 4%;
    padding-top: 0.75rem;
    position: relative;
`
export const ProductListTitle = styled.div`
    width: 70%;
    font-size: 1.125rem;
    color: ${themenew.text_color};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.56rem;
    float: left;
`
export const ProductListImg = styled.div`
    width: 5rem;
    height: 5rem;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-bottom: 0.56rem;
    border: 0.5px solid #d6d6d6;
    border-radius: 0.375rem;
    img{ 
        width:100%;
        min-height:5rem;
        object-fit: cover;
    }
`
export const ProductListLine = styled.div`
    clear: both;
    border-bottom: 0.5px solid #d6d6d6;
`
export const ProductListPub = styled.div`
    line-height:2rem;
    position: absolute;
    bottom: 0.2rem;
    img{
        width:1.125rem;
        height:1.125rem;
        border-radius:50%;
        margin-right:0.375rem;
        vertical-align:middle;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }
    >span{
        display: inline-block;
        height: 1.125rem;
        line-height: 1.125rem;
        max-width: 9rem;
        margin-right:0.625rem;
        font-size:0.75rem;
        color:${themenew.subtext_color};
        vertical-align:middle;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap
    }
`
export const MoreButtonBG = styled.div`
    width: 100%;
    height: 6.25rem;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 -2px 4px 0 rgba(2,4,51,0.08);
    z-index: 10;
`
export const MoreButton = styled.div`
    background-image: linear-gradient(135deg,#0060ec 0%,#B82cfc 100%);
    border-radius: 6px;
    height: 3.125rem;
    color: #fff;
    width: 88%;
    margin: 0 auto;
    text-align: center;
    font-size: 0.875rem;
    line-height: 3.125rem;
    margin-top: 0.75rem;
`
export const MoreFilterTab = styled.div`
    width: 23.5%;
    background: #f8f9fb;
    height: auto;
    position: fixed;
    top:3rem;
    div{
        height: 3.6rem;
        line-height: 3.6rem;
        text-align: center;
        font-size: 0.875rem;
        color: ${themenew.text_color};
    }
    .select{
        background: #fff;
    }
`
export const MoreFilterRight = styled.div`
    width: 76.5%;
    min-height: 90%;
    background: #fff;
    position: absolute;
    top:3rem;
    right: 0;
    overflow:hidden;
    padding: 1rem 3% 8rem;
    font-size: 1.375rem;
    color: #262626;
    z-index: 1;
    >div{
        font-size: 1rem;
        margin-top: 1.375rem;
        margin-bottom: 1rem;
        font-weight: 600;
    }
    >span{
        font-size: 0.75rem;
        color: ${themenew.text_color};
        min-width: 30%;
        display: inline-block;
        background: #f8f9fb;
        text-align: center;
        height: 2.5rem;
        padding: 0 0.5rem;
        line-height: 2.5rem;
        border-radius: 0.25rem;
        margin: 1.65%;
    }
    .select{
        background: #ebf1ff;
        font-weight: 600;
        color: ${themenew.link_color};
    }
    
`
export const MoreFilterRightBrands = styled.div`
    width: 76.5%;
    min-height: 90%;
    background: #fff;
    position: absolute;
    top:3rem;
    right: 0;
    overflow:hidden;
    padding: 1rem 0 8rem;
    font-size: 1.375rem;
    color: #262626;
    >span{
        margin-left: 1rem;
        margin-bottom: 0.75rem;
        display: block;
    }
    .letter{
        background: #f6f6f6;
        height: 1.75rem;
        color: #4a4a4a;
        font-size: 1rem;
        padding-left: 1rem;
        line-height: 1.75rem;
    }
    .brands{
        width: 95%;
        background: #fff;
        margin-left: 5%;
        height: 4.375rem;
        line-height: 4.375rem;
        border-bottom: 0.5px solid #e1e1e1;
        display: flex;
        align-items: center;
        .brandsimg{
            width: 2.875rem;
            height: 2.875rem;
            overflow:hidden;
            display:flex;
            justify-content: center;
            align-items: center;
            float: left;
            margin-right: 0.75rem;
            border-radius: 50%;
            >img{ 
                width:100%;
                min-height:2.875rem;
                object-fit: cover;
            }
        }
        .brandsname{
            width: 55%;
            line-height: 1.56rem !important;
            font-size: 1.125rem;
        }
        .brandscheckbox{
            width: 3.125rem;
            text-align: center;
            margin-left: 0.25rem;
        }
    }
`
export const SearchWrapper = styled.div`
  width:100%; 
	height:3.25rem;
  background: #fff;
  padding-top: 0.625rem;
  //border-bottom: 0.0625rem solid #E6E6E6;
  button{
    float: right;
    margin-right: 3%;
    width: 13%;
    background: #fff;
    height: 2rem;
    color: #015FEC;
    padding:0;
  }
`
export const SearchInput = styled.div`
  width:79%; 
	height:2rem;
  background: #F3F5F9;
  border-radius: 0.375rem;
  line-height: 2rem;
  margin-left: 3%;
  display: inline-block;
  i{
    font-size: 1.4rem;
    color: #212C68;
    float: left;
    margin: 0 0.625rem;
  }
  input{
    height: 100%;
    width: 80%;
    border:0;
    background: #F3F5F9;
    float: left;
    font-size: 0.875rem;
  }
`



