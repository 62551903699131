import React, {
    Component
} from 'react';
import BigBanner from "./components/Banner";
import {
    HomeWrapper,
    NavBar,
    NavBarFloat,
    Search,
    KingKongWrapper,
    KingKong,
    KingKongss,
    HomeListWrapper,
    HomeListTitle,
    PermissionBox,
    PermissionBoxTitle,
    PermissionBoxBody,
    PermissionBoxButton,
    PermissionBoxButtonLeft,
    PermissionBoxButtonRight,
    MessageWrapper,
    ADDataWrapper,
    IndexSYWrapper
} from './HomeStyle';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleNoImg,
    NewsTitleOneImg,
    NewsPubs,
    VideoImg,
    VideoIcon,

} from '../../common/generalStyle';
import {
    getQueryVariable
} from "../../utils/utils";
import axios from "axios";
import api from "../../http/api";
import httpAxios from '../../http/httpAxios';
import httpAxios2 from '../../http/httpAxios2';
import {
    startMinsTime
} from '../../utils/utils';
import { Mask } from 'antd-mobile'
const playSvg = require('../../images/plays.svg');
const listVipLogo = require('../../images/listVipLogo.png');
const NUM_ROWS = 4;
let pageIndex = 1;

class Home extends Component {
    state = {
        userPID: "",
        textNews: [],
        newsType: '5',
        textComponents: [],
        textEvaluation: [],
        textExperience: [],
        addata: [],
        readType: '1',
        listType: '1',
        eType: '999',
        navAdd: false,
        disagree: false,
        openBox: true,
        isMessage: 0,
        NewStatus: {}
    }
    componentDidMount() {
        if (localStorage.getItem('disagree') == null) {
            this.setState({
                disagree: true
            });
        } else {
            this.setState({
                disagree: localStorage.getItem('disagree')
            });
        }
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getToMyMessageCount();
        }

        this.newsList();
        this.componentsList();
        this.evaluationList();
        this.getNewsBanner();
        this.experienceList();
        this.getNewStatus();
        window.addEventListener("scroll", this.handleScroll);//捕获屏幕滑动式执行handleScroll
    }

    componentWillMount() {
        if (getQueryVariable('uid')) {
            axios.put(api.unLockUser.url + '?uid=' + getQueryVariable('uid')).then(res => {
                if (res) {
                    window.location.replace('/')
                }
            })
        }
    }
    //获取我的系统消息统计数量
    getToMyMessageCount = async () => {
        const {
            userPID
        } = this.state;
        const res = await httpAxios(api.getToMyMessageCount, {
            userID: userPID,
        })
        if (res && res.Data) {
            this.setState({
                isMessage: res.Data,
            });
        }
    }
    newsList = async () => {
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "NewsType": this.state.newsType,
        }
        const newsRes = await httpAxios2(api.searchNewsList, params);
        // console.log(newsRes);
        if (newsRes) {
            this.setState({
                textNews: newsRes.Data
            });
        }
    }

    componentsList = async () => {
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "ReadType": this.state.readType,
            "ListType": this.state.listType
        }
        const componentsRes = await httpAxios2(api.getComList, params);
        // console.log(newsRes);
        if (componentsRes) {
            this.setState({
                textComponents: componentsRes.Data
            });
        }
    }
    //获取胶囊广告
    getNewsBanner = async () => {
        const res = await httpAxios2(api.getNewsBanner, {
            Type: 61
        });
        if (res) {
            this.setState({
                addata: res.Data
            })
        }
    }
    evaluationList = async () => {
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "EType": this.state.eType,
        }
        const componentsRes = await httpAxios2(api.getEvalList, params);
        // console.log(newsRes);
        if (componentsRes) {
            this.setState({
                textEvaluation: componentsRes.Data.EvaluationList
            });
            // console.log(this.state.textComponents)
        }
    }

    //视音
    experienceList = async () => {
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "Type": this.state.eType,
        }
        const componentsRes = await httpAxios2(api.getExperList, params);
        // console.log(newsRes);
        if (componentsRes) {
            this.setState({
                textExperience: componentsRes.Data
            });
            // console.log(this.state.textComponents)
        }
    }

    //获取最新状态
    getNewStatus = async () => {

        const componentsRes = await httpAxios2(api.getNewStatus);
        // console.log(newsRes);
        if (componentsRes) {
            this.setState({
                NewStatus: componentsRes.Data
            });
            // console.log(this.state.textComponents)
        }
    }

    handleScroll = () => {
        //定义handleScroll事件函数
        // let header = document.getElementById('header');
        // let footerfreeclass = document.getElementById('FooterFreeClass');
        let wholeScrollHeight = document.documentElement.scrollHeight, // 能够滚动的总高度
            visiableHeight = document.documentElement.clientHeight, // 可视区域高度
            currentOffset = document.documentElement.scrollTop; // 滚动的距离
        if (currentOffset > 20) {
            this.setState({
                navAdd: true
            });
            // header.classList.add('nav-header-change');
        } else {
            this.setState({
                navAdd: false
            });
            // header.classList.remove('nav-header-change');
        }
        // if (footerfreeclass !== undefined && footerfreeclass !== null) {
        //     if (currentOffset > 200 && wholeScrollHeight - currentOffset - visiableHeight > 400) {
        //         footerfreeclass.classList.add('footer-freeclass-block');
        //     } else {
        //         footerfreeclass.classList.remove('footer-freeclass-block');
        //     }
        // }

    }
    openBox = () => {
        this.setState({
            openBox: true,
        })
    }
    closeBox = () => {
        this.setState({
            openBox: false,
        })
    }
    closeBoxOK = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "access" }));
        localStorage.setItem('disagree', false);
        this.setState({
            openBox: false,
            disagree: false,
        })
    }
    toRegister = () => {
        // sessionStorage.setItem('AUDIO_FILE_TYPE', 'register')
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "用户服务协议", weburl: "/RegisterFile" }))
    }
    toPrivacy = () => {
        // sessionStorage.setItem('AUDIO_FILE_TYPE', 'privacy')
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "“中乐之声”隐私政策", weburl: "/Privacy" }))
    }
    goMyMessageSystem = () => {
        if (!this.state.userPID) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        this.setState({ isMessage: 0 });
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "系统消息", weburl: "/MyMessageSystem" + '?' + this.state.userPID }));
    }

    render() {
        const {
            textNews,
            textComponents,
            textEvaluation,
            navAdd,
            disagree,
            openBox,
            isMessage,
            addata,
            NewStatus,
            textExperience
        } = this.state;
        // alert(JSON.stringify(textNews))

        return (
            <HomeWrapper>
                {navAdd && <NavBarFloat>
                    <Search onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><i className="iconfont">&#xe7b4;</i><span>搜资讯、帖子、评测、视&音、展会、直播回看</span></Search>
                    <MessageWrapper onClick={this.goMyMessageSystem}>
                        {
                            isMessage == 0 ? <img src={require("../../images/message.png")} alt="" /> : <img src={require("../../images/messageDot.png")} alt="" />
                        }
                        <span>公告</span>
                    </MessageWrapper>
                </NavBarFloat>}
                <NavBar>
                    <Search onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><i className="iconfont">&#xe7b4;</i><span>搜资讯、帖子、评测、视&音、展会、直播回看</span></Search>
                    <MessageWrapper onClick={this.goMyMessageSystem}>
                        {
                            isMessage == 0 ? <img src={require("../../images/message.png")} alt="" /> : <img src={require("../../images/messageDot.png")} alt="" />
                        }
                        <span>公告</span>
                    </MessageWrapper>
                </NavBar>
                <BigBanner type={60} userPID={this.state.userPID} />
                <KingKongWrapper>
                    {/* <KingKongBox> */}

                    <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/news" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #FD5357 0%, #F9211F 100%)' }}>
                            <img src={require("../../images/indexIconZX.png")} alt="" />
                        </div>
                        <span>
                            资讯
                        </span>
                    </KingKong>
                    <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/community" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #00B0F6 0%, #0097DF 100%)' }}>
                            <img src={require("../../images/indexIconSQ.png")} alt="" />
                        </div>
                        <span>
                            社区
                        </span>
                    </KingKong>
                    <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/evaluation" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #FFAD20 0%, #FB8700 100%)' }}>
                            <img src={require("../../images/indexIconPC.png")} alt="" />
                        </div>
                        <span>
                            评测
                        </span>
                    </KingKong>
                    <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/audition" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #50E092 0%, #13C255 100%)' }}>
                            <img src={require("../../images/indexIconSY.png")} alt="" />
                        </div>
                        <span>
                            视{"&"}音
                        </span>
                    </KingKong>
                    <KingKongss onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/training" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #00B0F6 0%, #0097DF 100%)' }}>
                            <img src={require("../../images/indexIconZYZXs.png")} alt="" />
                        </div>
                        <span>
                            中乐之星
                        </span>
                    </KingKongss>
                    {/* <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "brand" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #00B0F6 0%, #0097DF 100%)' }}>
                            <img src={require("../../images/indexIconPPH.png")} alt="" />
                        </div>
                        <span>
                            品牌
                        </span>
                    </KingKong> */}
                    <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "exhibition" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #E6882B 0%, #DD5C00 100%)' }}>
                            <img src={require("../../images/indexIconZH.png")} alt="" />
                        </div>
                        <span>
                            展会
                        </span>
                    </KingKong>
                    <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/star" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #49EBC8 0%, #00B4B3 100%)' }}>
                            <img src={require("../../images/indexIconYRHZ.png")} alt="" />
                        </div>
                        <span>
                            艺人合作
                        </span>
                    </KingKong>
                    <KingKong onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/toLive" })) }}>
                        <div style={{ backgroundImage: 'linear-gradient(180deg, #DE61EC 0%, #D108E0 100%)' }}>
                            <img src={require("../../images/indexIconZB.png")} alt="" />
                        </div>
                        <span>
                            直播
                        </span>
                    </KingKong>
                    {/* </KingKongBox> */}
                </KingKongWrapper>
                {/* <ADDataWrapper>
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/exhibition/ExhibitionDetailList/45/萌新也HIFI——2022中乐之声音乐音响线上展第一期' })); }} >
                        <img src={require("../../images/mengxinzhanhui.png")} alt="" />
                    </a>
                </ADDataWrapper> */}
                {
                    addata.length > 0 && <ADDataWrapper>
                        {addata[0]?.LinkedID === 0 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "openlink", url: addata[0]?.URL })); }}>
                            <img src={addata[0]?.IMGURL} alt="" />
                        </a> : addata[0]?.LinkedID === 1 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/community/communitydetail/' + addata[0]?.URL + '/news' + '?' + this.props.userPID, })); }}>
                            <img src={addata[0]?.IMGURL} alt="" />
                        </a> : addata[0]?.LinkedID === 2 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/exhibition/ExhibitionDetailList/' + addata[0]?.URL })); }} >
                            <img src={addata[0]?.IMGURL} alt="" />
                        </a> : addata[0]?.LinkedID === 3 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/community/communitydetail/' + addata[0]?.URL + '/evaluation' + '?' + this.props.userPID, })); }}>
                            <img src={addata[0]?.IMGURL} alt="" />
                        </a> : addata[0]?.LinkedID === 4 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/community/communitydetail/' + addata[0]?.URL + '/audition' + '?' + this.props.userPID, })); }}>
                            <img src={addata[0]?.IMGURL} alt="" />
                        </a> : addata[0]?.LinkedID === 5 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/community/communitydetail/' + addata[0]?.URL + '/community' + '?' + this.props.userPID, })); }}>
                            <img src={addata[0]?.IMGURL} alt="" />
                        </a> : addata[0]?.LinkedID === 7 ? <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "ToLive", id: addata[0]?.URL })) }}>
                            <img src={addata[0]?.IMGURL} alt="" />
                        </a> : ""}
                    </ADDataWrapper>
                }
                <HomeListWrapper>
                    <div className='fengexian' />
                    <HomeListTitle>
                        <div><img src={require("../../images/titleIconZX.png")} alt="" /><span>最新资讯</span></div>{NewStatus.News && <img src={require("../../images/indexNew.png")} alt="" />}<span onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/news" })) }}>更多 {">"}</span>
                        {/* <DividingLine /> */}
                    </HomeListTitle>
                    {textNews.map(obj => {
                        return (
                            <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.Author, weburl: '/community/communitydetail/' + obj.ID + '/news' + '?' + this.state.userPID, })); }}>
                                    {
                                        obj.ShowType == 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    {
                                                        obj.PosterLandscape.indexOf('?') > 0 ? <img src={obj.PosterLandscape.slice(0, obj.PosterLandscape.indexOf('?')) + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                            : <img src={obj.PosterLandscape + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                    }
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 0 || obj.ShowType == 1 ?
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                            <NewsImgWrap>
                                                                {
                                                                    obj.PosterLandscape.indexOf('?') > 0 ? <img src={obj.PosterLandscape.slice(0, obj.PosterLandscape.indexOf('?')) + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                        : <img src={obj.PosterLandscape + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                }
                                                            </NewsImgWrap>
                                                        </NewsItem >
                                                        :
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle>{obj.Title}</NewsTitle>
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl.split(';').map((image, index) => (
                                                                        index < 3 && <div>
                                                                            {
                                                                                image.indexOf('?') > 0 ? <img src={image.slice(0, image.indexOf('?')) + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                                    : <img src={image + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                }
                                            </>
                                    }
                                    <NewsPubs>
                                        {
                                            obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                        }
                                        <img src={obj.HeadImg} alt="" /><span>{obj.Author}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span>
                                    </NewsPubs>
                                </a>
                            </NewsDetailWrapper>
                        )
                    })}
                    <div className='fengexian' style={{ marginTop: "3rem" }} />
                    <HomeListTitle>
                        <div><img src={require("../../images/titleIconSQ.png")} alt="" /><span>最新文章</span></div>{NewStatus.Community && <img src={require("../../images/indexNew.png")} alt="" />}<span onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/community" })) }}>更多 {">"}</span>
                        {/* <DividingLine /> */}
                    </HomeListTitle>
                    {textComponents.map(obj => {
                        return (
                            <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.Author, weburl: '/community/communitydetail/' + obj.ID + '/community' + '?' + this.state.userPID, })); }}>
                                    {
                                        obj.ShowType == 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    {
                                                        obj.ListIMG === null ? <img src={
                                                            obj.ImageAppUrl.split(';')[0]
                                                        } alt="" /> : <img src={obj.ListIMG} alt="" />
                                                    }
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 0 ?
                                                        <NewsItem div key={obj.ID} >
                                                            {obj.ListIMG != null ? <>
                                                                <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                                <NewsImgWrap>
                                                                    {/* <img src={obj.ListIMG} alt="" /> */}
                                                                    {
                                                                        obj.ListIMG.indexOf('?') > 0 ? <img src={obj.ListIMG.slice(0, obj.ListIMG.indexOf('?')) + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                            : <img src={obj.ListIMG + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                    }
                                                                </NewsImgWrap>
                                                            </> : <NewsTitleNoImg>{obj.Title}</NewsTitleNoImg>
                                                            }
                                                        </NewsItem >
                                                        :
                                                        <>
                                                            {
                                                                obj.ShowType == 2 ?
                                                                    <NewsItemOther div key={obj.ID} >
                                                                        <NewsTitle>{obj.Title}</NewsTitle>
                                                                        <NewsThreeImgWrap>
                                                                            {
                                                                                obj.ImageAppUrl.split(';').map((image, index) => (
                                                                                    index < 3 && <div>
                                                                                        {/* <img src={image} alt="" /> */}
                                                                                        {
                                                                                            image.indexOf('?') > 0 ? <img src={image.slice(0, image.indexOf('?')) + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                                                : <img src={image + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </NewsThreeImgWrap>
                                                                    </NewsItemOther >
                                                                    :
                                                                    <NewsItem div key={obj.ID} >
                                                                        <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                                        <NewsImgWrap>
                                                                            {
                                                                                obj.ListIMG === null ? <img src={
                                                                                    obj.ImageAppUrl.split(';')[0]
                                                                                } alt="" /> : <img src={obj.ListIMG} alt="" />
                                                                            }
                                                                        </NewsImgWrap>
                                                                    </NewsItem >
                                                            }
                                                        </>
                                                }
                                            </>
                                    }
                                    <NewsPubs>
                                        {
                                            obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                        }
                                        <img src={obj.HeadImg} alt="" /><span>{obj.Author}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.Addtime)}</span></NewsPubs>
                                </a>
                            </NewsDetailWrapper>
                        )
                    })}
                    <div className='fengexian' style={{ marginTop: "3rem" }} />
                    <HomeListTitle>
                        <div><img src={require("../../images/titleIconSY.png")} alt="" /><span>最新视&音</span></div>{NewStatus.Experience && <img src={require("../../images/indexNew.png")} alt="" />}<span onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/audition" })) }}>更多 {">"}</span>
                        {/* <DividingLine /> */}
                    </HomeListTitle>
                    <IndexSYWrapper >
                        {textExperience.map(item => {
                            return (
                                <div onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: item.UserName, weburl: '/community/communitydetail/' + item.id + '/audition' + '?' + this.state.userPID, })); }} >
                                    <span>{item.Title}<h1>{item.ViewNum}</h1></span>
                                    <div />
                                    <img src={item.PosterImg + '?x-oss-process=image/resize,w_400,m_lfit'} alt="" />
                                </div>
                            )
                        })}
                    </IndexSYWrapper>
                    <div className='fengexian' style={{ marginTop: "3rem" }} />
                    <HomeListTitle>
                        <div><img src={require("../../images/titleIconCP.png")} alt="" /><span>最新评测</span></div>{NewStatus.Evaluation && <img src={require("../../images/indexNew.png")} alt="" />}<span onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/evaluation" })) }}>更多 {">"}</span>
                        {/* <DividingLine /> */}
                    </HomeListTitle>
                    {textEvaluation.map(obj => {
                        return (
                            <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.ID + '/evaluation' + '?' + this.state.userPID, })); }}>
                                    {
                                        obj.ShowType == 3 ?
                                            <NewsItemOther div key={obj.ID} >
                                                <NewsTitle>{obj.Title}</NewsTitle>
                                                <VideoImg>
                                                    <img src={obj.CoverIMG + '?x-oss-process=image/resize,w_690,m_lfit'} alt="" />
                                                    <VideoIcon>
                                                        <img src={playSvg} alt="" />
                                                    </VideoIcon>
                                                </VideoImg>
                                            </NewsItemOther >
                                            :
                                            <>
                                                {
                                                    obj.ShowType == 0 || obj.ShowType == 1 ?
                                                        <NewsItem div key={obj.ID} >
                                                            <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                            <NewsImgWrap>
                                                                {/* <img src={obj.CoverIMG} alt="" /> */}
                                                                {
                                                                    obj.CoverIMG.indexOf('?') > 0 ? <img src={obj.CoverIMG.slice(0, obj.CoverIMG.indexOf('?')) + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                        : <img src={obj.CoverIMG + '?x-oss-process=image/resize,w_240,m_lfit'} alt="" />
                                                                }
                                                            </NewsImgWrap>
                                                        </NewsItem >
                                                        :
                                                        <NewsItemOther div key={obj.ID} >
                                                            <NewsTitle>{obj.Title}</NewsTitle>
                                                            <NewsThreeImgWrap>
                                                                {
                                                                    obj.ImageAppUrl.split(';').map((image, index) => (
                                                                        index < 3 && <div>
                                                                            {/* <img src={image} alt="" /> */}
                                                                            {
                                                                                image.indexOf('?') > 0 ? <img src={image.slice(0, image.indexOf('?')) + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                                    : <img src={image + '?x-oss-process=image/resize,w_226,m_lfit'} alt="" />
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </NewsThreeImgWrap>
                                                        </NewsItemOther >
                                                }
                                            </>
                                    }
                                    <NewsPubs>
                                        {
                                            obj.IsBussUser && <div><img src={listVipLogo} alt="" /></div>
                                        }
                                        <img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPubs>
                                </a>
                            </NewsDetailWrapper>
                        )
                    })}
                </HomeListWrapper>
                {
                    disagree == true ? <>
                        {openBox ? <>
                            <Mask
                                visible={disagree}
                                // onMaskClick={() => this.setState({ disagree: false })}
                                opacity={0}
                            />
                            <PermissionBox>
                                <PermissionBoxTitle>
                                    温馨提示
                                </PermissionBoxTitle>
                                <PermissionBoxBody>
                                    <div style={{ height: "40rem", backgroundColor: "#fff" }}>
                                        请充分阅读并理解<a onClick={this.toRegister}>《用户服务协议》</a>和<a onClick={this.toPrivacy}>《“中乐之声”隐私政策》</a>，中乐之声APP将会获取以下设备权限：<br />
                                        1.为了用户正常使用和浏览本软件，本软件将会获取网络权限，包括移动网络和WIFI网络使用权限；<br />
                                        2.为了保障用户可以在本软件中发布内容，本软件将会获取摄像头、录音、存储的权限，方便用户拍摄照片、视频后上传并使用；<br />
                                        3.为保障平台安全与运营安全，我们可能会申请系统权限手机号码、IMEI、IMSI等设备信息用于平台安全。<br />
                                        4.为了保证用户可以获取到最新版本软件，本软件将会获取下载新版本软件和安装的权限，此动作可以由用户在“个人中心”-“右上角设置”-“关于中乐之声”-“检测新版本”中主动点击，本软件也会在用户打开本软件后，自动检测版本信息。<br />
                                        5.为了保证统计信息的准确，粗略位置信息功能正常使用，保证您更好的体验，我们会申请位置信息定位权限。<br />
                                        6.为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启关联启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置 - 应用 - 应用启动管理 - 选择应用“中乐之声”- 关闭关联启动功能<br />
                                        7.我们可能会申请位置权限，用于为您提供基于位置的信息服务，向您所在地的当地的经销商提供产品报价服务。
                                    </div>
                                </PermissionBoxBody>
                                <PermissionBoxButton>
                                    <PermissionBoxButtonLeft onClick={this.closeBox}>
                                        不同意
                                    </PermissionBoxButtonLeft>
                                    <PermissionBoxButtonRight onClick={this.closeBoxOK}>
                                        同意
                                    </PermissionBoxButtonRight>
                                </PermissionBoxButton>
                            </PermissionBox></> : ""}</>
                        : ""
                }
            </HomeWrapper >
        )
    }
}

export default Home