import React, { Component } from 'react';
import {
    ARow,
    Box,
    SaveButton,
    MoreWrapper
} from './style';
import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";

export default class DuiGong extends Component {
    state = {
        DisplayButton: this.props.location.search.replace('?', ''),//从网址中取url?后面的字符串,
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            //alert(JSON.stringify(data))
            that.setState({
                userId: data.UserPID,
                userName: data.UserName
            })
        };
    }

    handleSave = (paymentType, flag) => {
        let data = {
            ID: localStorage.getItem("CurrentOrderFormID") ?? 0,
            UserID: this.state.userId,
            Subject: "企业认证费用",
            Body: "认证费用300元",
            ToalAmount: 300,
            PaymentType: paymentType
        }

        // console.log(data);

        httpAxios(api.bcOrderForm, data).then(res => {
            // console.log(res);
            if (res.Code === 200) {

                localStorage.setItem("CurrentOrderFormID", res.Data);

                //调用支付
                if (flag == 3) {
                    //对公转账
                    try {
                        httpAxios(api.getCompanyApplyState, { userBID: this.state.userId, orderID: res.Data }).then(res1 => {
                            if (res1.Code == 200) {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '支付完成', weburl: '/RenZhengOK' }));
                            }
                            else {
                                alert(res1.Data);
                            }
                        });
                    }
                    catch (erro) {
                        alert("2" + JSON.stringify(erro));
                    }
                }
            }
        });
    }

    render() {
        const {
            DisplayButton,
        } = this.state;
        // alert(DisplayButton)
        return (

            <MoreWrapper>
                <Box>
                    <span>对公转账信息</span>

                    <ARow>
                        <span>收款账号：</span><div>北京中悦之声信息技术有限公司</div>
                    </ARow>
                    <ARow>
                        <span>开户行：</span><div>中国银行股份有限公司北京金融街支行</div>
                    </ARow>
                    <ARow>
                        <span>账号：</span><div>337668683758</div>
                    </ARow>
                    <ARow>
                        <span>电话：</span><div>010-53322640</div>
                    </ARow>

                    <h1>
                        请复制并确认以上信息后再付款
                    </h1>
                </Box>
                {
                    DisplayButton != 'Button' && <>
                        <span>
                            <span>注：</span>对公转账并完成审核需要2-3个工作日，信息通过审核后即为您通过认证。
                        </span>
                        <SaveButton onClick={() => this.handleSave("公对公转账支付", 3)}>完成转账</SaveButton>
                        {/* <SaveButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '支付完成', weburl: '/RenZhengOK' }));}}>完成转账</SaveButton> */}
                    </>
                }
            </MoreWrapper>
        )
    }
}