import React, { Component } from 'react';

import {
    CountryList,
    CountryBorder,
    BrandsListImg,
    BrandsListEns,
    SearchWrapper,
    SearchInput,
    FilterContent,
    FilterContentDeletions,
    FilterContentlist,
} from '../../../brands/style';

import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';

class MyExDetailBrandList extends Component {
    constructor() {
        super();
        this.state = {
            isTouching: false,
            lastChar: 'A',
            country: [],
            charStr: [],
            searchName: '',
            search: false,
            searchList: [],
        }

        this.boxClientTop = 80;
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.getChar = this.getChar.bind(this);
        this.gotoChar = this.gotoChar.bind(this);
    }

    touchStart(e) {
        this.setState({ isTouching: true });
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchMove(e) {
        e.preventDefault();
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchEnd(e) {
        e.preventDefault();
        this.setState({ isTouching: false });
    }
    getChar(clientY) {
        const charHeight = this.refs.charBar.offsetHeight / this.state.charStr.length;
        const index = Math.floor((clientY - this.boxClientTop) / charHeight);
        return this.state.charStr[index];
    }
    gotoChar(char) {
        if (char === this.lastChar) {
            return false;
        }
        this.setState({ lastChar: char });
        if (char === '*') {
            this.refs.countryList.scrollTop = 0;
        } else if (char === '#') {
            this.refs.countryList.scrollTop = this.refs.countryList.scrollHeight;
        }
        const target = document.querySelector('[data-en="' + char + '"]');

        if (target) {
            window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
        }
    }

    componentDidMount() {
        this.getBrands();
    }

    componentDidUpdate() {
        var that = this;
        //右上角按钮动作
        window.saveInfo = function () {
            localStorage.setItem("brand_selected", JSON.stringify(that.state.searchList));

            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "search", search: JSON.stringify(that.state.country.filter(a => a.selected === true)) }));
        }
    }

    getBrands = async (searchName) => {
        if (searchName == null || searchName === "") {
            searchName = "0";
        }
        const res = await httpAxios(api.getAppBrands, { keyWord: searchName });
        if (res) {
            let brandSelected = localStorage.getItem("brand_selected");

            let brandData = res.Data.Data;

            if (brandSelected != null && brandSelected.length > 0) {
                let brandSelecteds = JSON.parse(brandSelected);

                this.setState({ searchList: brandSelecteds });

                for (let index = 0; index < brandData.length; index++) {
                    const element = brandData[index];

                    let tempBrand = brandSelecteds.filter(a => a.ID == element.ID);
                    if (tempBrand.length > 0) {
                        element.selected = true;
                    }
                    else {
                        element.selected = false;
                    }
                }
            }

            this.setState(
                {
                    charStr: res.Data.Letters,
                    country: brandData
                }
            );
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onBrandSelect = (id) => {
        let brandData = this.state.country;
        let tempBrand = brandData.filter(a => a.ID == id);

        if (tempBrand.length > 0) {
            tempBrand[0].selected = !tempBrand[0].selected;
        }

        let tempSelect = this.state.searchList;

        let flag = -1;
        for (let index = 0; index < tempSelect.length; index++) {
            const element = tempSelect[index];
            if (element.ID == id) {
                flag = index;
            }
        }

        if (flag != -1) {
            tempSelect.splice(flag, 1);
        }

        if (tempBrand.length > 0 && tempBrand[0].selected) {
            tempSelect.push(tempBrand[0]);
        }


        this.setState(
            {
                country: brandData,
                searchList: [...tempSelect]
            }
        );
    }

    //搜索动作
    goSearch = () => {
        const {
            searchName,
        } = this.state;
        this.getBrands(searchName);
        this.setState({ search: true })
    };
    //取消并清空搜索
    toSearch = () => {
        this.getBrands("0");
        this.setState({ search: false });
        this.setState({
            searchName: ''
        })
    }

    clearAll = () => {
        let brandData = this.state.country;

        brandData.forEach(element => {
            element.selected = false;
        });

        this.setState(
            {
                country: brandData,
                searchList: []
            }
        );

        localStorage.clear("brand_selected");
    }

    render() {
        const {
            country,
            charStr,
            searchName,
            search,
            searchList,
        } = this.state;
        return (
            <CountryList>
                <div style={{ position: 'fixed', top: 0, paddingTop: '0.45rem', backgroundColor: '#fff', minWidth: '100%', zIndex: '10' }}>
                    <SearchWrapper>
                        <SearchInput>
                            <i className='iconfont'>&#xe7b4;</i>
                            <input value={searchName} onChange={(e) => { this.setState({ searchName: e.target.value.trim() }) }} placeholder="请输入您要搜索的内容"></input>
                        </SearchInput>
                        {!search ? <button onClick={() => { localStorage.setItem("brand_selected", JSON.stringify(searchList)); this.goSearch(); }}>搜索</button> : <button onClick={() => { localStorage.setItem("brand_selected", JSON.stringify(searchList)); this.toSearch(); }}>取消</button>}
                        {/* <button onClick={() => { localStorage.setItem("brand_selected", JSON.stringify(searchList)); this.goSearch(); }}>搜索</button> */}
                    </SearchWrapper>

                    <FilterContentDeletions onClick={() => this.clearAll()}><span>清除</span><i className='iconfont'>&#xe748;</i></FilterContentDeletions>
                    <FilterContent>
                        {/* {
                            searchList.map(item => (
                                <FilterContentlist key={'s' + item}>{item}<i className='iconfont' onClick={() => { this.removeOneSelectLabel(item) }}>&#xe74c;</i></FilterContentlist>
                            ))
                        } */}
                        {
                            searchList.map(item => (
                                <FilterContentlist key={item.ID}>{item.BrandName}<i className='iconfont' onClick={() => { this.onBrandSelect(item.ID) }}>&#xe74c;</i></FilterContentlist>
                            ))
                        }
                        <div style={{ width: '5rem', flexShrink: '0' }}></div>{/* 作用是调整最后的标签不被清除按钮遮住 */}
                    </FilterContent>
                </div>
                <CountryBorder ref="countryList" style={{ marginTop: '6.2rem' }}>
                    {
                        country.map((item, index) => {
                            return (
                                <div key={index} onClick={() => this.onBrandSelect(item.ID)}>
                                    {item.First && <div data-en={item.Letter}></div>}
                                    <div className={item.First === true ? "title" : 'brandsList'}>
                                        {!item.First && <BrandsListImg><img src={item.BrandLogo + '?x-oss-process=image/resize,w_92,m_lfit'} alt="" /></BrandsListImg>}
                                        <BrandsListEns className='buding'>{item.BrandName}{(!item.First && item.selected) && <i className='iconfont'>&#xe775;</i>}</BrandsListEns>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="char-list-border-zhanhui">
                        <ul
                            className="char-list"
                            ref="charBar"
                            onTouchStart={this.touchStart}
                            onTouchMove={this.touchMove}
                            onTouchEnd={this.touchEnd}
                        >
                            {
                                charStr.map((char, index) => {
                                    return (
                                        <li className="char-item" key={index}>{char}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {this.state.isTouching && <div className="char-tip">{this.state.lastChar}</div>}
                </CountryBorder>
            </CountryList>
        )
    }
}

export default MyExDetailBrandList;
