import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import { ActionSheet, Button, Dialog, Space, Toast } from 'antd-mobile';
import {
    NewsPub,
    NavBar,
    NewsItem,
    NewsImgWrap,
    NewsTitleOneImg,
    NewsDetailWrapper,
    NewsSubTitle,
    NewsTitle,
    NewsRight
} from './ShopListStyle';

import Label from "./ShopListLabel";
import httpAxios2 from "../../../http/httpAxios2";
import api from "../../../http/api";

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class ShopListContent extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userPID: this.props.userid,
            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            // readType: '2',
            // listType: '1',
            targetID: '0',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
            visible: false,
            youyige: 0
        };
    }

    componentDidUpdate() {
        var that = this;
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            that.setState({
                visible: true
            })
        }
    }

    componentDidMount() {
        this.getList(null, null, null);

        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    getListRefresh = async (targetID, readType, listType) => {
        this.setState({
            data: [],
        })
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async (targetID, readType, listType) => {
        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;


        if (!targetID) targetID = this.state.targetID;

        //查询线上店是否有数据
        const res = await httpAxios2(api.getCompanyShop, {
            UserBID: this.state.userPID,
        })
        if (res) {
            if (res.Data != null) {
                this.setState({
                    youyige: 1
                });
            } else {
                this.setState({
                    youyige: 0
                });
            }
        }

        this.setState({ targetID: targetID });

        if (targetID == "1") {
            //线上店
            const res = await httpAxios2(api.getCompanyShops, {
                UserBID: this.state.userPID,
            })

            if (res) {
                if (res.Data != null) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })

                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.state.data),
                    });
                } else {
                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows([]),
                    });
                }
            }
        }
        else {
            //体验店
            const res = await httpAxios2(api.getCompanyExperiences, {
                UserBID: this.state.userPID,
            })
            if (res) {
                if (res.Data != null) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })

                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.state.data)
                    });
                } else {
                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows([]),
                    });
                }
            }
        }

        this.state.hasMore = false;
    }


    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
            comTypes,
            youyige
        } = this.state;
        const row = (rowData, sectionID, rowID) => {

            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }

            return (
                <div key={rowID}>
                    <NewsDetailWrapper >
                        <NewsItem >
                            <NewsImgWrap><img src={obj.ShopImage} alt="" /></NewsImgWrap>
                            <NewsRight>
                                {
                                    this.state.targetID == "1" ? <>
                                        <NewsTitle>{obj.ShopName}</NewsTitle>
                                        <NewsSubTitle>{obj.ShopLink}</NewsSubTitle>
                                        {/* <NewsPub>
                                            <i className="iconfont" onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "修改", buttonTitle: "发布", weburl: "/MyShopCenter/AddOnlineStore?" + obj.ID }))}>&#xe6f6;</i>
                                        </NewsPub> */}
                                    </>
                                        : <>
                                            <NewsTitleOneImg>{obj.ShopName}</NewsTitleOneImg>
                                            <NewsSubTitle>{obj.ShopAddress}</NewsSubTitle>
                                            <NewsSubTitle>电话：{obj.Telephone}</NewsSubTitle>
                                            {/* <NewsPub>
                                                <i className="iconfont" onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "修改", buttonTitle: "发布", weburl: "/MyShopCenter/AddExperienceStore?" + obj.ID }))}>&#xe6f6;</i>
                                            </NewsPub> */}
                                        </>
                                }
                            </NewsRight>
                        </NewsItem >
                    </NewsDetailWrapper>
                </div>
            );
        };
        return (<div>
            <NavBar>
                <Label cb={this.getListRefresh} list={comTypes} />
            </NavBar>
            <div style={{ height: '3rem' }}></div>
            <ListView
                // initialListSize={8}
                key={'1'}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (
                    <div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                    </div>)}
                renderRow={row}
                useBodyScroll
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    onRefresh={this.onRefresh}
                />}
                onEndReached={this.onEndReached}
                pageSize={NUM_ROWS}
            />
        </div>);
    }
}

ReactDOM.render(<ShopListContent />, document.getElementById('root'));
export default ShopListContent