import styled from 'styled-components';
import {
	theme, themenew
} from '../../common/color';

export const HomeWrapper = styled.div`
	width:100%;
	background-color:#fff;
`
export const NavBar = styled.div`
	width:100%;
	height:6.2rem;
	background-color:#fff;
	padding-top:3.375rem;
	display: -webkit-flex; /* Safari */
  	display: flex;
	flex-direction: row;
	justify-content: space-between;
`
export const NavBarFloat = styled.div`
	width:100%;
	height:6.6rem;
	background-color:#fff;
	padding-top:3.125rem;
	position:fixed;
	top:0;
	display: -webkit-flex; /* Safari */
  	display: flex;
	flex-direction: row;
	justify-content: space-between;
	z-index:999;
	border-bottom:0.03125rem #D6D6D6 solid;
`
export const SearchFloat = styled.div`
	width:92%;
	height:2rem;
	line-height:2rem;
	margin:0 auto 1.1rem;
	border-radius:0.375rem;
	font-size:1rem;
	background-color:#F3F5F9;
	i{
		display:block;
		float:left;
		margin:auto 0.625rem;
		font-size:1.5rem;
	}
	span{
		display:block;
		float:left;
	}
`
export const Search = styled.div`
	width:80%;
	height:2.8rem;
	line-height:2.8rem;
	//margin:0 auto 1.1rem;
	border: 1px solid #ECECEC;
	box-shadow: -1px 2px 8px -2px rgba(0,0,0,0.15);
	border-radius:0.375rem;
	font-size:1rem;
	background-color:#fff;
	margin-left: 4%;
	i{
		display:block;
		float:left;
		margin:auto 2%;
		font-size:1.5rem;
		color: #8F8F8F;
	}
	span{
		width: 86%;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		height: 2.8rem;
		display:block;
		float:left;
		color: #BEBEBE;
	}
`
export const MessageWrapper = styled.div`
  width:12%;
  text-align: center;
  margin-right: 3%;
  img{
	  width: 1.375rem;
	  margin-top: 0.3rem;
	  margin-bottom: 0.2rem;
  }
  span{
	  display: block;
	  font-size: 10px;
	color: #8F8F8F;
  }
`
export const KingKongWrapper = styled.div`
	width:100%;
	margin:1.375rem auto 0.8rem;
	//height:3.7rem;
	//overflow-x:auto;
  	//overflow-y:hidden;
	::-webkit-scrollbar{
		width:0;
		height: 0;
		display:none;
	} 
`
export const KingKongss = styled.div`
	//width:4.5rem;
	width:25%;
	height:3.7rem;
	margin-bottom: 12px;
	display:inline-block;
	>div{
		width:2.5rem;
		height:2.5rem;
		margin:0 auto;
		border-radius:0.5rem;
		line-height:2.5rem;
		text-align:center;
		overflow: hidden;
		>img{
			//margin-top:0.4375rem;
			width:100%;
			height: 100%;
		}
	}
	>span{
		display:block;
		text-align:center;
		font-size:0.75rem;
		margin-top:0.3rem;
		color:#203152;
	}

`
export const KingKong = styled.div`
	//width:4.5rem;
	width:25%;
	height:3.7rem;
	margin-bottom: 12px;
	display:inline-block;
	>div{
		width:2.5rem;
		height:2.5rem;
		margin:0 auto;
		border-radius:0.5rem;
		line-height:2.5rem;
		text-align:center;
		>img{
			margin-top:0.4375rem;
			width:1.625rem;
		}
	}
	>span{
		display:block;
		text-align:center;
		font-size:0.75rem;
		margin-top:0.3rem;
		color:#203152;
	}

`
export const KingKongs = styled.div`
	//width:20%;
	width:25%;
	height:3.7rem;
	display:inline-block;
	>div{
		width:2.5rem;
		height:2.5rem;
		margin:0 auto;
		border-radius:0.5rem;
		line-height:2.5rem;
		text-align:center;
		>img{
			margin-top:0.4375rem;
			width:1.625rem;
		}
	}
	>span{
		display:block;
		text-align:center;
		font-size:0.75rem;
		margin-top:0.3rem;
		color:#203152;
	}

`
export const HomeListWrapper = styled.div`
	width:100%;
	border-radius:0.5rem;
	background-color:#fff;
	.fengexian{
		width:100%;
		height: .5rem;;
		background-color: #F6F6F6;
		margin-bottom: .5rem;
	}
`
export const HomeListTitle = styled.div`
	width:92%;
	margin-left:4%;
	height:2.7rem;
	line-height:2.7rem;
	>img{
		float:left;
		width: 34px;
		height: 16px;
		margin-top: .8rem;
		margin-left: .5rem;
	}
	>div{
		float:left;
		font-size:1rem;
		color: #000;
		line-height:2.7rem;
		>img{
			width: 1.125rem;
			float: left;
			margin-top: .7rem;
			margin-right: .5rem;
		}
	}
	>span{
		float:right;
		font-size:0.75rem;
		color: #7C8698;
	}
`
export const FrameTrigger = styled.div`
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:998;
  //background-color:red;
`
export const PermissionBox = styled.div`
  background-color:#fff;
  width:17.8rem;
  height:24.9rem;
  position:fixed;
  top:50%;
  left:50%;
  margin-top:-12.45rem;
  margin-left:-8.9rem;
  z-index:1000;
  border-radius: 0.5rem;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0,0,0,0.30);
  div::-webkit-scrollbar {
	display: none;
	}
`
export const PermissnBG = styled.div`
background:rgba(0,0,0,0.5);
position:fixed;
top:0;
bottom:0;
left:0;
right:0;
z-index:999;
`
export const PermissionBoxTitle = styled.div`
  font-size:1rem;
  font-weight:600;
  margin:0 auto;
  margin-top:1.1875rem;
  margin-bottom:1rem;
  text-align:center;
  width:15.3rem;
  color:#2c2c2c;
`
export const PermissionBoxBody = styled.div`
  font-size:0.875rem;
  width:15.3rem;
  height:16.5rem;
  margin:0 auto;
  margin-bottom:1.25rem;
  overflow-y:auto;
  display: -webkit-box;
  -webkit-overflow-scrolling: auto;
  line-height:1.375rem;
  color:#333;
`
export const PermissionBoxButton = styled.div`
  width:15.9rem;
  margin:0 auto;
`
export const PermissionBoxButtonLeft = styled.div`
  width:7.625rem;
  height:2.5rem;
  float:left;
  line-height:2.5rem;
  border:1px #BEBEBE solid;
  border-radius:0.25rem;
  color:#646464;
  text-align:center;
`
export const PermissionBoxButtonRight = styled.div`
  width:7.625rem;
  height:2.5rem;
  float:right;
  line-height:2.5rem;
  border-radius:0.25rem;
  color:#fff;
  text-align:center;
  background: #015FEC;
`
export const KingKongBox = styled.div`
  width:110%;
  margin:0 0.4rem;
  height:3.7rem;
`
export const ADDataWrapper = styled.div`
	width:92%;
	margin:1.25rem 4% 0.5rem;
	height: 6.625rem;
	img{
		width: 100%;
		height: 100%;
	}
`
export const IndexSYWrapper = styled.div`
  width:100%;
  height: 200px;
  display: flex;
  overflow-x:auto;
  overflow-y:hidden;
  margin-top: 1rem;
  ::-webkit-scrollbar{
		width:0;
		height: 0;
		display:none;
	}
  >div{
	width: 200px;
	height: 200px;
	margin-left: 16px;
	flex-shrink: 0;
	overflow:hidden;
	display:flex;
	justify-content: center;
	align-items: center;
	position: relative;
	>img{
		width:100%;
		min-height:200px;
		object-fit: cover; 
	}
	>div{
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 10;
		background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);
	}
	>span{
		width: 90%;
		margin:0 auto;
		position: absolute;
		z-index: 11;
		color: #fff;
		bottom: 0;
		font-size: 14px;
		line-height: 20px;
		>h1{
			color: #fff;
			margin-top: .5rem;
			font-size: 12px;
			margin-bottom: .7rem;
		}
	}
  }
`