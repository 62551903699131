/**
 * created by ChenyaoSun on 2020/11/10
 */
import React, {
    Component,
} from 'react';
import {
    Checkbox
} from 'antd';
import {
    Link
} from 'react-router-dom';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";
import { Toast } from 'antd-mobile-v2';
import 'antd-mobile-v2/dist/antd-mobile.css';
import {
    LoginWrapper,
    BoxWrapper,
    Title,
    Registered,
    LoginButton,
    FormItem,
    FormItemPsassword,
    LoginTab,
    FormItemVerification,
    Code,
    File,
    FrameTrigger,
    PermissionBox,
    PermissnBG,
    PermissionBoxTitle,
    PermissionBoxBody,
    PermissionBoxButton,
    PermissionBoxButtonLeft,
    PermissionBoxButtonRight
} from './LoginStyle';
import {
    connect
} from 'react-redux';

// import * as actionCreators from "../../common/header/store/actionCreators";

class SendCode extends Component {

    state = {
        time: '',
        phoneHash: ''
    }

    trick = () => {
        let {
            time
        } = this.state;
        if (time > 0) {
            this.setState({
                time: time - 1
            })
        }
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.timeId)
    }
    sendCode = async () => {
        if (!this.props.phone) {
            Toast.info('请输入手机号')
            return
        }
        // try {
        const res = await httpAxios(this.props.url, {
            phone: this.props.phone
        })
        if (res) {
            clearTimeout(this.timeId);
            this.setState({
                time: 59,
                phoneHash: res.Data
            })
            this.timeId = setInterval(this.trick, 1000);
            this.props.phoneHashData(this.state.phoneHash);
        }
    }

    render() {
        return (
            <span>
                {
                    this.state.time > 0 ? <Code>{`${this.state.time}s 后重发`}</Code> : <Code onClick={this.sendCode}>发送验证码</Code>
                }
            </span>
        )
    }
}

class Login extends Component {
    state = {
        login: 'personlogin',
        userConfirm: false,
        companyConfirm: false,
        phoneValue: '',
        phoneHash: '',
        PasswordValue: '',
        BussAccountValue: '',
        BussPasswordValue: '',
        BussAccount: '',
        BussPassword: '',
        phoneStatus: '',
        PasswordStatus: '',
        BussPasswordStatus: '',
        userInputFocus: false,
        phoneInputFocus: false,
        phoneVerification: false,
        confirmProtocol: false,
        // passwordVerification: false,
        logintab: true,
        disagree: false,
        openBox: true,
        phone: '',
        // yzmStatus: '',
        lookStatus: 'password',
    }

    async componentDidMount() {
        if (localStorage.getItem('disagree') == null) {
            this.setState({
                disagree: true
            });
        } else {
            this.setState({
                disagree: localStorage.getItem('disagree')
            });
        }
        let that = this;
        document.onkeydown = function (event) {
            let e = event || window.event;
            if (e && e.keyCode == 13) { //回车键的键值为13
                const {
                    userInputFocus,
                    phoneInputFocus
                } = that.state;
                if (userInputFocus) {
                    that.handleUserLogin();
                }
                if (phoneInputFocus) {
                    that.handlePhoneLogin();
                    return
                }
            }
        };
    }

    handleUserLogin = async () => {
        const {
            phoneValue,
            PasswordValue,
            phoneVerification,
            // passwordVerification,
            confirmProtocol,
        } = this.state;
        if (!phoneValue || !PasswordValue) {
            // Toast.info('请输入手机号和密码');
            return
        };
        if (!phoneVerification) {
            Toast.info('请输入正确的手机号码');
            return
        };
        if (!confirmProtocol) {
            Toast.info('请先勾选我同意《用户服务协议》与《“中乐之声”隐私政策》')
            return
        }
        // if (!passwordVerification) {
        //     Toast.info('请输入正确的密码');
        //     return
        // };
        httpAxios(api.userLogin, {
            Account: phoneValue,
            Password: PasswordValue
        }).then(res => {
            if (res.Code === 200) {
                //给webview传值
                window.ReactNativeWebView.postMessage(JSON.stringify(res.Data));
                // Toast.info('登录成功');
            }
        }).catch(e => {
            const ERROR_MESSAGE = [
                "Account", "Password"
            ];
            const Message = (e && e.data && e.data.Message) || {};
            for (let k in Message) {
                for (let i = 0; i < ERROR_MESSAGE.length; i++) {
                    let item = ERROR_MESSAGE[i];
                    let status = item + 'Status'
                    if (k.includes(item)) {
                        this.setState({
                            [item]: Message[k][0],
                            [status]: 'error'
                        });
                    }
                }
            }
        })
    }
    checkProtocol = () => {
        this.setState({
            confirmProtocol: !this.state.confirmProtocol
        })
    }

    handlePhoneLogin = async () => {
        const {
            phoneValue,
            phoneVerification,
            code,
            phoneHash,
            confirmProtocol
        } = this.state;
        if (!phoneValue) {
            // Toast.info('请输入手机号和密码');
            return
        };
        if (!phoneVerification) {
            Toast.info('请输入正确的手机号码');
            return
        };
        if (!confirmProtocol) {
            Toast.info('请先勾选我同意《用户服务协议》与《“中乐之声”隐私政策》')
            return
        }
        httpAxios(api.userLoginPhone, {
            Account: phoneValue,
            Code: code,
            PhoneHash: phoneHash
        }).then(res => {
            if (res.Code === 403) {
                Toast.info(res.Message);
                return
            }
            if (res.Code === 200) {
                // Toast.info('登录成功');
                //给webview传值
                window.ReactNativeWebView.postMessage(JSON.stringify(res.Data));
                // localStorage.setItem('BUSS_CENTER_WEBAPP_USERINFO', JSON.stringify(res.Data));
            }
        }).catch(e => {

        })
    }

    // validatePass = (type, e) => {
    //     let value = e.target.value;
    //     value = value.trim();
    //     let myreg = /^(?:(?=.*[a-zA-Z])(?=.*[0-9])).{6,20}$/
    //     if (myreg.test(value)) {
    //         this.setState({
    //             [`${type}Status`]: 'success',
    //             [`${type}Value`]: value,
    //             passwordVerification: true,
    //         })
    //     } else {
    //         // Toast.info('请输入正确的密码');
    //         this.setState({
    //             [`${type}Status`]: 'error',
    //             passwordVerification: false,
    //         })
    //     }
    // }

    validatePass = (type, e) => {
        this.setState({ userInputFocus: false });
        let value = e.target.value;
        value = value.trim();
        let myreg = /^(?:(?=.*[a-zA-Z])(?=.*[0-9])).{6,20}$/
        if (myreg.test(value)) {
            this.setState({
                // [`${type}Status`]: 'success',
                [`${type}Value`]: value,
                // passwordVerification: true,
            })
        }
    }

    checkUserName = (type, e) => {
        let value = e.target.value;
        value = value.trim();
        let myreg1 = /^1[3456789]\d{9}$/
        if (!myreg1.test(value)) {
            // Toast.info('请输入正确的手机号码');
            this.setState({
                [`${type}Status`]: 'error',
                phoneVerification: false,
            })
            return
        }
        this.setState({
            [`${type}Status`]: 'success',
            [`${type}Value`]: value,
            phoneVerification: true,
            phone: value,
        })
    }

    zhangHao = () => {
        this.setState({
            logintab: true,
            userInputFocus: false
        })
    }

    shouJi = () => {
        this.setState({
            logintab: false,
            phoneInputFocus: false
        })
    }
    showPhomeErro = () => {
        this.setState({
            phoneStatus: 'error',
        })
    }
    phoneHashData = (event) => {
        this.setState({
            phoneHash: event
        });
    }
    checkCode = async (e) => {
        this.setState({ phoneInputFocus: false })
        let value = e.target.value;
        value = value.trim();
        if (!value) {
            this.setState({
                codeValid: false,
                // yzmStatus: 'error'
            })
        } else {
            this.setState({
                codeValid: true,
                code: value,
                // yzmStatus: 'success'
            })
        }
    }
    lookPsassword = () => {
        const {
            lookStatus,
            // icon
        } = this.state;
        if (lookStatus == 'password') {
            this.setState({
                lookStatus: 'text',

            })
        } else {
            this.setState({
                lookStatus: 'password',
            })
        }
    }
    toRegister = () => {
        sessionStorage.setItem('AUDIO_FILE_TYPE', 'register')
        this.registerInput.click()
    }
    toPrivacy = () => {
        sessionStorage.setItem('AUDIO_FILE_TYPE', 'privacy')
        this.registerInput.click()
    }
    openBox = () => {
        this.setState({
            openBox: true,
        })
    }
    closeBox = () => {
        this.setState({
            openBox: false,
        })
    }
    closeBoxOK = () => {
        localStorage.setItem('disagree', false);
        this.setState({
            openBox: false,
            disagree: false,
        })
    }

    render() {
        const {
            phoneStatus,
            phoneValue,
            PasswordValue,
            PasswordStatus,
            logintab,
            disagree,
            openBox,
            phone,
            // yzmStatus,
            lookStatus,
        } = this.state;
        return (
            <LoginWrapper>
                <Link to={`/register/person`}>
                    <Registered>注册</Registered>
                </Link>
                <Title><div>中乐之声</div><span>请按以下格式输入您的信息</span></Title>
                <BoxWrapper>
                    <span>手机号</span>
                    <FormItem>
                        <input className={phoneStatus} value={phoneValue} onChange={(e) => { this.setState({ phoneValue: e.target.value.trim() }) }} type="number" placeholder="请输入手机号码" onBlur={this.checkUserName.bind(this, 'phone')} />
                    </FormItem>
                    {
                        logintab == true ?
                            <>
                                <span>验证码</span>
                                <FormItemVerification>
                                    <input
                                        onFocus={() => {
                                            this.setState({ phoneInputFocus: true })
                                        }}
                                        onBlur={this.checkCode}
                                        // className={yzmStatus} type="number" placeholder='请输入手机验证码' onBlur={this.checkCode} />
                                        type="number" placeholder='请输入手机验证码' />
                                    <SendCode url={api.loginCode} phone={phone} showPhomeErro={this.showPhomeErro} phoneHashData={this.phoneHashData.bind(this)} />
                                </FormItemVerification>
                                <File><Checkbox onClick={this.checkProtocol} />&nbsp;&nbsp;我同意<span onClick={this.toRegister}>《用户服务协议》</span>及<span onClick={this.toPrivacy}>《“中乐之声”隐私政策》</span></File>
                                <LoginButton onClick={this.handlePhoneLogin}>登录</LoginButton>
                            </>
                            :
                            <>
                                <span>登录密码</span>
                                <FormItemPsassword>
                                    <input type={lookStatus} ref={r => this.inputUser = r}
                                        onFocus={() => {
                                            this.setState({ userInputFocus: true })
                                        }}
                                        onBlur={this.validatePass.bind(this, 'Password')}
                                        className={PasswordStatus} value={PasswordValue} onChange={(e) => { this.setState({ PasswordValue: e.target.value.trim() }) }} placeholder="请输入登录密码" />
                                    {!PasswordValue ? "" : <div>{lookStatus == 'password' ? <i className="iconfont" onClick={this.lookPsassword}>&#xe745;</i> : <i className="iconfont" onClick={this.lookPsassword}>&#xe75a;</i>}</div>}
                                </FormItemPsassword>
                                <File><Checkbox onClick={this.checkProtocol} />&nbsp;&nbsp;我同意<span onClick={this.toRegister}>《用户服务协议》</span>及<span onClick={this.toPrivacy}>《“中乐之声”隐私政策》</span></File>
                                <LoginButton onClick={this.handleUserLogin}>登录</LoginButton>
                            </>
                    }
                </BoxWrapper>
                {logintab ? <LoginTab onClick={this.shouJi} ><span>账号密码登录</span></LoginTab> : <LoginTab><span onClick={this.zhangHao}>手机号快捷登录</span><span>|</span><span><Link to={`/forget/user`}>忘记密码</Link></span></LoginTab>}

                <Link to='/file' ref={r => this.registerInput = r} />
                {disagree == true ? <><FrameTrigger onClick={this.openBox} />
                    {openBox ? <>
                        <PermissnBG onClick={this.closeBox} />
                        <PermissionBox>
                            <PermissionBoxTitle>
                                温馨提示
                            </PermissionBoxTitle>
                            <PermissionBoxBody>
                                <div style={{ height: "100rem" }}>    请充分阅读并理解<a onClick={this.toRegister}>《用户服务协议》</a>和<a onClick={this.toPrivacy}>《“中乐之声”隐私政策》</a>，中乐之声APP将会获取以下设备权限：<br />
                                        1.为了用户正常使用和浏览本软件，本软件将会获取网络权限，包括移动网络和WIFI网络使用权限；<br />
                                        2.为了保障用户可以在本软件中发布内容，本软件将会获取摄像头、录音、存储的权限，方便用户拍摄照片、视频后上传并使用；<br />
                                        3.为保障平台安全与运营安全，我们可能会申请系统权限手机号码、IMEI、IMSI等设备信息用于平台安全。<br />
                                        4.为了保证用户可以获取到最新版本软件，本软件将会获取下载新版本软件和安装的权限，此动作可以由用户在“个人中心”-“右上角设置”-“关于中乐之声”-“检测新版本”中主动点击，本软件也会在用户打开本软件后，自动检测版本信息。<br />
                                        5.为了保证统计信息的准确，粗略位置信息功能正常使用，保证您更好的体验，我们会申请位置信息定位权限。<br />
                                        6.为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启关联启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置 - 应用 - 应用启动管理 - 选择应用“中乐之声”- 关闭关联启动功能
                                    </div>
                            </PermissionBoxBody>
                            <PermissionBoxButton>
                                <PermissionBoxButtonLeft onClick={this.closeBox}>
                                    不同意
                                </PermissionBoxButtonLeft>
                                <PermissionBoxButtonRight onClick={this.closeBoxOK}>
                                    同意
                                </PermissionBoxButtonRight>
                            </PermissionBoxButton>
                        </PermissionBox>
                    </> : ""}</>
                    : ""}

            </LoginWrapper>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // setUserInfo(value) {
        //     dispatch(actionCreators.setUserInfo(value))
        // },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);