import React, {
    Component
} from 'react';

import {
    Wrapper,
    CountryList,
    CountryBorder,
    BrandsListCn,
    BrandsListEn,
    BrandsListImg,
} from './style';
import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';


class MyBrandsCenterAddBrands extends Component {
    constructor() {
        super();
        this.state = {
            isTouching: false,
            lastChar: 'A',
            country: [],
            charStr: [],
            userPID: '',
            brandID: 0
        }

        // this.isTouching = false;
        this.boxClientTop = 148;
        // this.lastChar = 'A';
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.getChar = this.getChar.bind(this);
        this.gotoChar = this.gotoChar.bind(this);
    }

    touchStart(e) {
        // e.preventDefault();
        // this.isTouching = true;
        this.setState({ isTouching: true });
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchMove(e) {
        e.preventDefault();
        const char = this.getChar(e.touches[0].clientY);
        this.gotoChar(char);
    }
    touchEnd(e) {
        e.preventDefault();
        this.setState({ isTouching: false });
    }
    getChar(clientY) {
        const charHeight = this.refs.charBar.offsetHeight / this.state.charStr.length;
        const index = Math.floor((clientY - this.boxClientTop) / charHeight);
        return this.state.charStr[index];
    }
    gotoChar(char) {
        if (char === this.lastChar) {
            return false;
        }
        this.setState({ lastChar: char });
        // this.lastChar = char;
        if (char === '*') {
            this.refs.countryList.scrollTop = 0;
        } else if (char === '#') {
            this.refs.countryList.scrollTop = this.refs.countryList.scrollHeight;
        }
        const target = document.querySelector('[data-en="' + char + '"]');

        if (target) {
            // target.scrollIntoView();
            window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
        }
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "没有找到要关联的产品", weburl: "/MyBrandsCenter/ApplyBrand" }))
        }
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {

            that.setState({
                userPID: data.UserPID
            }, () => {
                that.getBrands();
            })
        }

        //D2DE406E-4BE2-42C2-82A9-6CFE27CD0BB7
        // this.setState({
        //     userPID: 'D2DE406E-4BE2-42C2-82A9-6CFE27CD0BB7'
        // }, () => {
        //     this.getBrands();
        // })
    }

    getBrands = async () => {
        const res = await httpAxios(api.getAppBrands, {});
        if (res) {
            this.setState(
                {
                    charStr: res.Data.Letters,
                    country: res.Data.Data
                }
            );
        }
    }

    render() {
        const {
            country,
            charStr,
        } = this.state;
        return (
            <Wrapper>
                <CountryList>
                    <CountryBorder ref="countryList" >
                        {
                            country.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <a onClick={() => {
                                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "modal", modalTitl: '关联产品', weburl: "/MyBrandsCenter/AddBrandCheck/" + item.ID }));
                                        }}>
                                            {item.First && <div data-en={item.Letter}></div>}
                                            <div
                                                className={item.First === true ? "title" : 'brandsList'}
                                            >
                                                {!item.First && <BrandsListImg><img src={item.BrandLogo} alt="" /></BrandsListImg>}
                                                <BrandsListEn>{item.BrandName}</BrandsListEn>
                                                {!item.First && <BrandsListCn>{item.BrandCountry}</BrandsListCn>}
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }
                        <div className="char-list-border">
                            <ul
                                className="char-list"
                                ref="charBar"
                                onTouchStart={this.touchStart}
                                onTouchMove={this.touchMove}
                                onTouchEnd={this.touchEnd}
                            >
                                {
                                    charStr.map((char, index) => {
                                        return (
                                            <li className="char-item" key={index}>{char}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        {this.state.isTouching && <div className="char-tip">{this.state.lastChar}</div>}
                    </CountryBorder>
                </CountryList>
            </Wrapper >
        )
    }
}

export default MyBrandsCenterAddBrands