import React, {
	Component
} from 'react';
import {
	CountryList,
	StarList,
} from './style';
import {
	convertTime
} from '../../../../utils/utils';
import {
	DividingLine,
	ClearFloat,
} from '../../../../common/style';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";

const NUM_ROWS = 10;
let pageIndex = 1;

class StarMyRelease extends Component {
	constructor(props) {
		super(props);
		const dataSource = new ListView.DataSource({
			rowHasChanged: (row1, row2) => row1 !== row2,
		});

		this.state = {
			userPID: '',

			dataSource,
			refreshing: false,
			isLoading: true,
			height: document.documentElement.clientHeight,

			data: [],
			comTypes: [],
			typeKeys: {},
			targetID: '1',
			hasMore: true,
			rowCount: 0,

			liveID: 1,
			livedata: [],
			starData: []
		}
	}

	componentDidUpdate() {
		document.body.style.overflow = 'auto';
	}

	componentDidMount() {
		var that = this;
		window.userInfo = function (data, params) {
			that.setState({
				userPID: data.UserPID
			})
			that.getList(data.UserPID);
		}

		const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

		setTimeout(() => {
			this.setState({
				height: hei,
				refreshing: false,
				isLoading: false,
			});
		}, 1500);
	}

	getListRefresh = async () => {
		this.lv.scrollTo(0, 0);
		this.state.refreshing = true;
		pageIndex = 1;
		await this.getList(this.state.userPID);
		this.state.refreshing = false;
		this.setState({
			isLoading: false,
		});
	}

	getList = async (ID) => {
		let params;
		params = {
			"userPID": ID,
			"pageSize": NUM_ROWS,
			"pageIndex": pageIndex,
		}

		const res = await httpAxios(api.getArtistInvitationSelfList, params);
		if (res) {
			const str = res.Message;
			const count = Number(str);
			this.state.rowCount = count;
			if (res.Data.length > 0) {
				if (this.state.refreshing) {
					this.setState({
						data: res.Data,
					})
				}
				else {
					let tempData = [...this.state.data, ...res.Data];

					this.setState({
						data: tempData,
					})
				}
			} else {
				this.setState({
					data: [],
				})
				this.state.hasMore = false;
			}

			this.setState({
				dataSource: this.state.dataSource.cloneWithRows(this.state.data)
			});

			if (this.state.data.length == this.state.rowCount) {
				this.state.hasMore = false;
			}
			else {
				this.state.hasMore = true;
			}
		}
	}


	onRefresh = async () => {
		this.setState({ refreshing: true, isLoading: true });
		await this.getListRefresh();
		setTimeout(() => {
			this.setState({
				refreshing: false,
				isLoading: false,
			});
		}, 600);
	};

	onEndReached = async (event) => {
		if (!this.state.hasMore) {
			return;
		}
		++pageIndex;
		await this.getList(null, null, null);
		this.setState({ isLoading: true });
		setTimeout(() => {
			this.setState({
				isLoading: false,
			});
		}, 1000);
	};


	render() {

		const {
			data,
		} = this.state;
		const row = (rowData, sectionID, rowID) => {
			console.log(rowID);
			let obj = {};
			if (rowID < data.length) {
				obj = data[rowID];
			} else {

			}
			return (
				<div key={rowID}>
					<StarList onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "详情", weburl: "/MyInvitationDetail/" + obj.ID }))}>
						<h5>
							{obj.Status === 0 ? <h5 style={{ color: '#015FEC' }}>未审核</h5> : obj.Status === 1 ? <h5 style={{ color: '#E33422' }}>审核失败</h5> : <h5 style={{ color: '#203152' }}>已发布</h5>}
						</h5>
						<h1>{obj.Title}</h1>
						<div>
							<div>
								{
									obj.InvitationType?.split(',').map((d, index) => (
										<span key={index}>{d}</span>
									))
								}
							</div>
							<h2>{obj.MoneyRange}</h2>
						</div>
						<ClearFloat />
						<div>
							<span style={{ width: '30%' }}>
								<img src={require("../../../../images/StarDidian.png")} alt="" />
								<h3>{obj.Place}</h3>
							</span>
							<span style={{ width: '65%', marginLeft: '5%' }}>
								<img src={require("../../../../images/StarTime.png")} alt="" />
								<h3>{convertTime(obj.BeginDate, 'day')}—{convertTime(obj.EndDate, 'day')}</h3>
							</span>
						</div>
						<ClearFloat />
					</StarList>
				</div>
			);
		};
		return (
			<CountryList>
				<ListView
					initialListSize={8}
					key={'1'}
					ref={el => this.lv = el}
					dataSource={this.state.dataSource}
					renderFooter={() => (
						<div style={{ padding: 30, textAlign: 'center' }}>
							{this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
						</div>)}
					renderRow={row}
					useBodyScroll
					pullToRefresh={<PullToRefresh
						refreshing={this.state.refreshing}
						onRefresh={this.onRefresh}
					/>}
					onEndReached={this.onEndReached}
					pageSize={NUM_ROWS}
				/>
			</CountryList>
		)
	}
}

ReactDOM.render(<StarMyRelease />, document.getElementById('root'));
export default StarMyRelease