import React, {
    Component, createRef
} from 'react';

import {
    Wrapper,
} from './AddExperienceStoreStyle';

import {
    Form,
    Input,
    Select,
    Button
} from 'antd';

import reloadIMG from '../../../../images/addimg.png'
import httpAxios from '../../../../http/httpAxios';
import api from '../../../../http/api';

class AddExperienceStore extends Component {
    state = {
        loading: false,
        BrandLogo: null,
        userPID: ''
    }

    formRef = createRef();

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        window.logoImage = function (imageUrl) {
            if (imageUrl != "") {
                that.setState({
                    BrandLogo: imageUrl
                })
            }
        }

        //保存
        window.saveInfo = function () {
            that.formRef.current.submit();
        };

        //编辑信息
        var id = this.props.location.search.replace('?', '').trim();
        if (id != '') {
            httpAxios(api.getCommpanyExperiencesById, {
                id: id
            }).then(res => {
                if (res.Code == 200) {
                    let item = res.Data;

                    this.formRef.current.setFieldsValue({
                        title: item.ShopName,
                        description: item.ShopAddress,
                        telephone: item.Telephone,
                    });

                    this.setState({ BrandLogo: item.ShopImage });
                }
            });
        }
    }

    //显示上传图片
    handleLogoImage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "uploadLogoImage" }));
    }

    addCompanyExperience = async (values) => {
        var data = {};
        data["ShopName"] = values.title;
        data["ShopAddress"] = values.description;
        data["Telephone"] = values.telephone;
        if (this.state.BrandLogo !== "") {
            data["ShopImage"] = this.state.BrandLogo;
        }

        let params = {
            UserBID: this.state.userPID,
            ShopName: data.ShopName,
            ShopAddress: data.ShopAddress,
            Telephone: data.Telephone,
            ShopImage: data.ShopImage
        }

        var id = this.props.location.search.replace('?', '').trim();
        if (id != '') {
            params["ID"] = id;
        }

        const res = await httpAxios(api.addCompanyExperience, params)

        if (res.Code == 200) {
            // alert("发布成功");
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }

    render() {
        const {
            BrandLogo,
            //httpSelect
        } = this.state;
        return (
            <Wrapper>
                <Form layout="vertical" onFinish={(values) => this.addCompanyExperience(values)} ref={this.formRef}>
                    <Form.Item label="添加体验店封面图">
                        <img src={BrandLogo || reloadIMG} onClick={this.handleLogoImage} alt="" />
                    </Form.Item>
                    <Form.Item label="体验店名称" name="title" rules={[{ required: true, message: '请输入体验店名称!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="体验店地址" name="description" rules={[{ required: true, message: '请输入体验店地址!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="体验店电话" name="telephone" rules={[{ required: true, message: '请输入体验店电话!' }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Wrapper>
        )
    }
}

export default AddExperienceStore