import React, {
    Component
} from 'react';
import {
    Wrapper,
    Auto
} from './FileStyle';

class AboutUs extends Component {
    render() {
        return (
            <Wrapper>
                {/* <Title1>中乐之声（Chinadudio.net.cn）</Title1> */}
                <Auto>
                    <p>中乐之声网站是由中国音响网（51audio.com）改版后注册成立，是北京中悦之声信息技术有限公司旗下的音乐音响综合服务平台。</p>
                    <p>中乐之声网站于2020年8月8日正式上线运营。中乐之声手机APP于2021年2月在各手机商城陆续上线。</p>
                    <p>中乐之声网站设有展会⁺、直播、视&音、资讯、社区、评测、品牌汇等七个模块，另有艺术培训、文创、艺人云经济等业务陆续开展，向影音文娱领域全面发展，推进影音行业数字化，为广大从业人员及爱好者提供一个全方位一站式数据信息技术服务平台，实现音乐音响行业资源数据全盘整合。</p>
                    <p>中乐之声网站为企业及品牌商新增产品宣传渠道，为发烧友搭建互动交流平台，为音乐人提供风采展示窗口。网站不定期举办“各类音响器材综合展会⁺”及展览、品鉴会等线上直播，更有“视&音”板块可供行业领军人物、知名品牌专业工程师、资深评测人、音响发烧友、音乐人等业内人士对最新最前沿的行业资讯即时交流。</p>
                    <p>随着业务发展，中乐之声又上线了“中乐之星”开展高阶艺术培训，针对专业艺术考试及演出一对一定制提升方案。另有“艺人合作”版块开展艺人云经纪全新业务。打造艺人、片方、制作组、企业线上直约平台。</p>
                    <p>愿中乐之声网站成为值得信赖的影音行业平台，与广大同业者、爱好者共同致力于推动行业新发展。</p>
                </Auto>
            </Wrapper>
        )
    }
}

export default AboutUs