import React, { Component } from 'react';
import {
    CancellationWrapper,
    CancellationContent,
    CheckboxWrapper,
    Info,
    CancellationButton
} from './style';
import {
    Checkbox
} from 'antd';
import {
    Link
} from 'react-router-dom';
import { Toast } from 'antd-mobile-v2';
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

export default class Cancellation extends Component {
    state = {
        confirmProtocol: false,
        cancellationText: "",
        userPID: "",
    }
    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }
    }
    toCancellationFile = () => {
        sessionStorage.setItem('AUDIO_FILE_TYPE', 'cancellationfile');
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "账户注销协议", weburl: "/cancellationText" }))
    }
    checkProtocol = () => {
        this.setState({
            confirmProtocol: !this.state.confirmProtocol
        })
    }
    saveValue = e => {
        let value = e.target.value;
        this.setState({
            cancellationText: value,
        })
    }
    cancellation = async () => {
        const {
            confirmProtocol,
            cancellationText,
            userPID
        } = this.state;
        // alert(JSON.stringify("1111"));
        if (!confirmProtocol) {
            Toast.info('请先勾选我已阅读并同意《账户注销协议》')
            return
        }
        if (!cancellationText) {
            Toast.info('注销账户原因不能为空')
            return
        }
        if (userPID) {
            const res = await httpAxios(api.userApplyCancel, {
                "UserPID": userPID,
                "Reason": cancellationText,
            })
            if (res) {
                if (res.Code === 200) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detail", weburl: "/cancellationOK" }))
                }
            }
        } else {
            Toast.info('没有登陆无法注销')
            return
        }
    }
    render() {
        const {
            cancellationText,
        } = this.state;

        return (
            <CancellationWrapper>
                <CancellationContent>
                    <div>注销后，您将失去…</div>
                    <p>个人信息、账户信息将被清除且无法恢复，无法找回，您的账号将不可被检索、访问，您之前使用的头像、昵称等信息将允许被其他第三方使用。</p>
                    <p>您将无法再使用本“中乐之声”账户登录中乐之声APP及其相关网站。</p>
                    <span>本“中乐之声”账户一旦被注销将不可恢复，请您谨慎操作，请您在提交注销申请前自行备份与账户相关的所有信息和数据。为确认并非误操作，以下信息为必填项。</span>
                    <textarea placeholder={"请填写您要注销此账户的原因"} value={cancellationText} onChange={this.saveValue} />
                </CancellationContent>
                <CheckboxWrapper>
                    <Checkbox onClick={this.checkProtocol} />
                    <Info>我已阅读并同意
                            <span className='cursor' onClick={this.toCancellationFile}>《账户注销协议》</span>
                    </Info>
                </CheckboxWrapper>
                <CancellationButton onClick={this.cancellation}>申请注销</CancellationButton>
            </CancellationWrapper>
        )
    }
}
