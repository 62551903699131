import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { ActionSheet, Button, Dialog, Space, Toast } from 'antd-mobile';
import {
    NewsPub,
    NavBar,
    NewsItem,
    NewsImgWrap,
    NewsTitleOneImg,
    NewsDetailWrapper,
    NewsSubTitle,
    NewsTitle,
    NewsRight
} from './ContentStyle';

import Label from "./Label";
import httpAxios2 from "../../../../../http/httpAxios2";
import api from "../../../../../http/api";

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: this.props.userid,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            // readType: '2',
            // listType: '1',
            targetID: '0',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
            visible: false,
            youyige: 0
        };
    }

    componentDidUpdate() {
        var that = this;
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            that.setState({
                visible: true
            })
        }
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    getListRefresh = async (targetID, readType, listType) => {
        this.state.hasMore = true;
        this.setState({
            data: [],
            targetID
        });
        window.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async (targetID, readType, listType) => {
        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;


        if (!targetID) targetID = this.state.targetID;

        //查询线上店是否有数据
        const res = await httpAxios2(api.getCompanyShop, {
            UserBID: this.state.userPID,
        })
        if (res) {
            if (res.Data != null) {
                this.setState({
                    youyige: 1
                });
            } else {
                this.setState({
                    youyige: 0
                });
            }
        }

        this.setState({ targetID: targetID });

        if (targetID == "1") {

            //线上店
            const res = await httpAxios2(api.getCompanyShops, {
                UserBID: this.state.userPID,
            })

            if (res) {
                if (res.Data != null) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })
                } else {
                    this.setState({
                        data: [],
                        targetID,
                    })
                    this.state.hasMore = false;
                }
            }
        }
        else {
            //体验店
            const res = await httpAxios2(api.getCompanyExperiences, {
                UserBID: this.state.userPID,
            })
            if (res) {
                if (res.Data != null) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })
                } else {
                    this.setState({
                        data: [],
                        targetID,
                    })
                    this.state.hasMore = false;
                }
            }
        }

        this.state.hasMore = false;
    }


    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };
    //删除线上店
    handleRemoveCompanyShop = async (id) => {
        const res = await httpAxios2(api.removeCompanyShop, {
            UserBID: this.state.userPID,
            ID: id
        })
        if (res) {
            //重新加载数据
            this.getList(null, null, null);
        }
    }

    //删除体验店
    handleRemoveCompanyExperience = async (id) => {
        const res = await httpAxios2(api.removeCompanyExperience, {
            UserBID: this.state.userPID,
            ID: id
        })
        if (res) {
            //重新加载数据
            this.getList(null, null, null);
        }
    }

    render() {
        const {
            data,
            comTypes,
            youyige
        } = this.state;

        return (<div>
            <ActionSheet
                cancelText='取消'
                visible={this.state.visible}
                actions={
                    [
                        {
                            text: '添加线上店',
                            key: 'online',
                            // disabled: youyige == 1 ? true : false,
                            onClick: async () => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "添加线上店", buttonTitle: "发布", weburl: "/MyShopCenter/AddOnlineStore" }))
                            },
                        },
                        {
                            text: '添加体验店',
                            key: 'experience',
                            onClick: async () => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "添加体验店", buttonTitle: "发布", weburl: "/MyShopCenter/AddExperienceStore" }))
                            },
                        },
                    ]
                }
                closeOnAction={true}
                onClose={() => {
                    this.setState({
                        visible: false
                    })
                }}
            />
            <NavBar>
                <Label cb={this.getListRefresh} list={comTypes} />
            </NavBar>
            <div style={{ height: '3rem' }}></div>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            <NewsDetailWrapper >
                                <NewsItem >
                                    <NewsImgWrap><img src={obj.ShopImage} alt="" /></NewsImgWrap>
                                    <NewsRight>
                                        {
                                            this.state.targetID == "1" ? <>
                                                <NewsTitle>{obj.ShopName}</NewsTitle>
                                                <NewsSubTitle>{obj.ShopLink}</NewsSubTitle>
                                                <NewsPub>
                                                    <i className="iconfont" onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "修改", buttonTitle: "发布", weburl: "/MyShopCenter/AddOnlineStore?" + obj.ID }))}>&#xe6f6;</i>
                                                    <i className="iconfont" onClick={() => this.handleRemoveCompanyShop(obj.ID)}>&#xe748;</i>
                                                </NewsPub>
                                            </>
                                                : <>
                                                    <NewsTitleOneImg>{obj.ShopName}</NewsTitleOneImg>
                                                    <NewsSubTitle>{obj.ShopAddress}</NewsSubTitle>
                                                    <NewsSubTitle>电话：{obj.Telephone}</NewsSubTitle>
                                                    <NewsPub>
                                                        <i className="iconfont" onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "修改", buttonTitle: "发布", weburl: "/MyShopCenter/AddExperienceStore?" + obj.ID }))}>&#xe6f6;</i>
                                                        <i className="iconfont" onClick={() => this.handleRemoveCompanyExperience(obj.ID)}>&#xe748;</i>
                                                    </NewsPub>
                                                </>
                                        }
                                    </NewsRight>
                                </NewsItem >
                            </NewsDetailWrapper>
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content