import styled from 'styled-components';
import {
  themenew
} from '../../../common/color';

export const ButtonBoxWrapper = styled.div`
  width: 100%;
  height: 7rem;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgba(2,4,51,0.08);
  z-index: 10;
`
export const SaveButton = styled.div`
  //background-image: linear-gradient(135deg,#0060ec 0%,#B82cfc 100%);
  background-color: #015FEC;
  border-radius: 6px;
  height: 3.125rem;
  color: #fff;
  width: 88%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.875rem;
  line-height: 3.125rem;
  margin-top: 0.75rem;
  >span{
    font-size: 1.5rem;
    vertical-align: top;
    margin-right: 1rem;
  }
`
export const AgreementBox = styled.div`
  text-align: center;
  margin-top: 1rem;
`
export const OpenPaymentWrapper = styled.div`
  background: #F2F2F2;
  height: 16rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -2px 4px 0 rgba(2,4,51,0.08);
  z-index: 20;
  >span{
    display: block;
    width: 100%;
    background-color: #fff;
    color: #222222;
    text-align: center;
    height: 2.8rem;
    line-height: 2.8rem;
    position: absolute;
    bottom: 0;
  }
`
export const PaymentButton = styled.div`
  border-radius: 6px;
  height: 3.125rem;
  color: #fff;
  width: 88%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.875rem;
  line-height: 3.125rem;
  margin-top: 0.75rem;
`
export const PaymentWrapper = styled.div`
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
export const TextBox = styled.div`
  text-align: center;
  h1{
    color: #379C79;
    font-size: 40px;
    font-weight: 400;
  }
  >div{
    display: flex;
    justify-content: center;
    //align-items: flex-end;
    margin-bottom: 50px;
  }
  h2{
    color: #242833;
    font-size: 20px;
    margin-top: 60px;
    font-weight: 400;
    margin-bottom: 12px;
  }
  h3{
    color: #379C79;
    font-size: 24px;
    font-weight: 400;
    margin-top: 13px;
    margin-right: 10px;
  }
`

export const NewMycenterPortraitWrapper = styled.div`
  width:92%;
  margin-left:4%;
  background-color:#fff;
  box-shadow: 0 1.25rem 1.25rem 0 rgba(0,0,0,0.05);
  border-radius: 0.625rem;
  overflow:hidden;
`

export const MycenterPortraitWrapper = styled.div`
  width:92%;
  margin-left:4%;
  margin:1rem auto 0;
  background-color:#fff;
  box-shadow: 0 1.25rem 1.25rem 0 rgba(0,0,0,0.05);
  border-radius: 0.625rem;
  overflow:hidden;
`
export const TopWrapper = styled.div`
  width:100%;
  //height:20.7rem;
  position:relative;
  padding:1rem 0 0;
`
export const NewTopWrapper = styled.div`
  width:100%;
  display: flex;
  padding-top: 50px;
  .new-top-left{
    width: 60px;
    display: flex;
    justify-content:flex-end;
    margin-top: 26px;
    margin-left: 4%;
    .new-protrait{
      width:60px;
      height:60px;
      border-radius:50%;
      border: 2px solid #FFFFFF;
      box-shadow: 0 10px 28px 8px rgba(0,0,0,0.08);
      overflow:hidden;
      display:flex;
      justify-content: center;
      align-items: center;
      img{
        width:100%;
        min-height:60px;
        object-fit: cover;
      }
    }
  }
  .new-top-center{
    flex: 1;
    padding: 0 12px;
    margin-top: 26px;
    height: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .new-name{
      width: 100%;
      font-size:16px;
      font-weight: 500;
      color:${themenew.text_color};
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap
      >span{
        color:${themenew.link_color};
        text-decoration:underline;
      }
    }
    .new-message{
      width: 100%;
      margin-top: 10px;
      font-size:12px;
      line-height: 17px;
      color:${themenew.subtext_color};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .new-top-right{
    width: 100px;
    display: flex;
    justify-content:flex-end;
    flex-wrap: wrap;
    margin-right: 4%;
    .new-fix-item{
      width:2rem;
      height:2rem;
      color:${themenew.text_color};
      margin-bottom: 20px;
      i{
        font-size:2rem;
      }
    }
    .qiandao-button{
      min-width: 80px;
      padding: 0 10px;
      height: 30px;
      border-radius: 15px;
      background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
      font-size: 14px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }
`

export const Title = styled.div`
  color:${themenew.subtext_color};
  margin-top:.6rem;
  font-size:0.88rem;
`
export const Num = styled.div`
  color:${themenew.text_color};
  font-size:1.3rem;
`
export const Fans = styled.div`
  flex:1;
  width:3.75rem;
  height:4rem;
  float:left;
  text-align:center;
  padding-top:0.8rem;
`
export const Protrait = styled.div`
  width:8.7rem;
  height:8.7rem;
  margin:4rem auto 0;
  border-radius:50%;
  border: 0.3rem solid #FFFFFF;
  box-shadow: 0 10px 28px 8px rgba(0,0,0,0.08);
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    width:100%;
    min-height:8.7rem;
    object-fit: cover;
  }
`
export const Message = styled.div`
  margin-top:0.6rem;
  width:92%;
  margin-left:4%;
  text-align:center;
  font-size:0.88rem;
  color:${themenew.subtext_color};
  overflow:hidden;
`
export const ListWrapper = styled.div`
  width:92%;
  margin-left:5%;
  height:3.76rem;
  line-height:3.75rem;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  clear: both;
  >i{
    font-size: 20px;
    float: left;
    margin-right: 8px;
  }
  >span{
    color:${themenew.text_color};
    font-size:0.88rem;
    float: left;
  }
  >div{
    float:right;
    >i{
      float: right;
      color:${themenew.subtext_color};
      font-size:1.5rem;
    }
  }
`
export const NewNumWrapper = styled.div` 
  display:flex;
  margin-top:14px;
  width:92%;
  height: 112px;
  margin-left:4%;
  background-image: url(${require("../../../images/newMyCenterBG.png")});
  background-size:100% auto;
  background-repeat: repeat-x;
  .fans {
    flex:1;
    width:3.75rem;
    height:4rem;
    float:left;
    text-align:center;
    padding-top: 36px;
    .num {
      color: #fff;
      font-size:1.3rem; 
    }
    .title{
      color: #fff;
      margin-top:.6rem;
      font-size:0.88rem;
    }
  }
`
export const NumWrapper = styled.div` 
  display:flex;
  margin-top:2rem;
  background-color:#fff;
  width:92%;
  height:4.5rem;
  margin-left:4%;
  box-shadow: 0 1.25rem 1.25rem 0 rgba(0,0,0,0.05);
  border-radius: 0.625rem;
`
export const FixItem = styled.div`
  float:right;
  width:2rem;
  height:2rem;
  margin-top:2.5rem;
  margin-right:4%;
  color:${themenew.text_color};
  i{
    font-size:2rem;
  }
`
export const NewFixItem = styled.div`
  width:2rem;
  height:2rem;
  margin-top:40px;
  background: #000;
  color:${themenew.text_color};
  i{
    font-size:2rem;
  }
`
export const LabelOne = styled.span`
  position: absolute;
  z-index: 999;
  height:2.5rem;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  color:${themenew.text_white_color};
  line-height:2.5rem;
  padding:0 1.5rem;
  border-radius:1.25rem;
  font-size:0.88rem;
  top:5rem;
  left:15.7%;

`
export const LabelTwo = styled.span`
  position: absolute;
  z-index: 999;
  height:2.5rem;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  color:${themenew.text_white_color};
  line-height:2.5rem;
  padding:0 1.5rem;
  border-radius:1.25rem;
  font-size:0.88rem;
  top:11.19rem;
  left:8.8%;
`
export const LabelThree = styled.span`
  position: absolute;
  z-index: 999;
  height:2.5rem;
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  color:${themenew.text_white_color};
  line-height:2.5rem;
  padding:0 1.5rem;
  border-radius:1.25rem;
  font-size:0.88rem;
  top:7.94rem;
  right:5.3%;
`
export const PNickName = styled.div`
  margin-top:1.875rem;
  width:92%;
  margin-left:4%;
  text-align:center;
  font-size:1.25rem;
  color:${themenew.text_color};
  overflow:hidden;
  >span{
    color:${themenew.link_color};
    text-decoration:underline;
  }
`

export const SettingsWrapper = styled.div`
  .listheight {
    height:3.125rem;
    .am-list-content{
      color:${themenew.text_color};
    }
  }
  .listheights {
    height:6.125rem;
    .am-list-content{
      color:${themenew.text_color};
    }
  }
`
export const RenZhengWrapper = styled.div`
  padding: 2rem 4%;
`

export const Logo = styled.div`
  width:9.3rem;
  height:9.3rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    width:100%;
    min-height:9.3rem;
    object-fit: cover; 
  } 
`
export const NavBar = styled.div`
  width:100%;
  height:5.5rem;
  background-color:#fff;
  position:fixed;
  top:0;
  z-index: 10;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  >span{
    float:left;
    margin-left:1.3rem;
    line-height:1.375rem;
    font-size:1.125rem;
    color:${themenew.text_color};
    margin-top:3.55rem;
  }
  >a{
    float:right;
    margin-right:1.3rem;
    line-height:1.375rem;
    font-size:0.875rem;
    color:${themenew.link_color};
    margin-top:3.675rem;
  }
`
export const ReturnButton = styled.div`
  width:1.375rem;
  height:1.375rem;
  margin-left:4%;
  margin-top:3.5rem;
  float:left;
`

export const HeaderImgList = styled.div`
  height:4.625rem;
  //background-color:#fff;
  margin-left:15px;
  box-shadow: 0 0.5px 0 ${themenew.line_color};
  margin-bottom:1px;
  >span{
    color:${themenew.text_color};
    font-size:17px;
    line-height:4.625rem;
  }
  >div{
    width:50px;
    height:50px;
    float:right;
    margin-top:12px;
    margin-right:15px;
    >img{
      width:100%;
      min-height:50px;
    }
  }
`
export const ExitButton = styled.div`
  text-align:center;
  line-height:3.125rem;
  font-size:17px;
  color:${themenew.text_color};
`