import {
	createGlobalStyle
} from 'styled-components';

export const GlobalStyle = createGlobalStyle`　

/* 全局样式引用reset.css*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	//border: 0;
	font-size: 100%;
	//font: inherit;
	vertical-align: baseline;
	//antd的气泡组件全局卡死宽度50%
	.ant-popover {
		width: 50% !important;
	}
}
p{
	//margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	//font: inherit;
	vertical-align: baseline;
}
div, span, p ,a ,b , u, i{
	word-wrap: break-word;
    white-space: normal;
    word-break: break-all;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	font-family:Microsoft Yahei,Arial,Helvetica,Roboto,sans-serif;
	background-color:#FAFAFA;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
    outline: none;
	border: 0px;
	cursor:pointer
}
a{
	cursor:pointer
}
`