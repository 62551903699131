import React from 'react';
import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    NewsDetailWrapper,
    NewsItemOther,
    NewsTitle,
    NewsPub,
    VideoImg,
    LiveState,
    LiveStates
} from '../../../../common/generalStyle';

import {
    Wrapper,
    VideoIcon,
    ExhibitionDiv,
    ExhibitionTime,
    NavBar
} from './style';

import Label from "./Label";
import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class MyExhibition extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',

            refreshing: true,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            newsType: '5',
            auditionType: '999',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
            exType: 1
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "添加参展产品", weburl: "/MessageSettings" }))
        }
    }

    componentDidMount() {
        pageIndex = 0;

        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
                // }, () => {
                //     that.getList();
            });
        }

        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    getList = async () => {
        if (this.state.userPID == "") {
            return;
        }

        if (this.state.exType == 1) {
            const res = await httpAxios(api.getExhListNext, {
                UserBID: this.state.userPID,
            })
            if (res) {

                this.state.rowCount = res.Data.length;
                if (res.Data.length > 0) {
                    if (this.state.refreshing) {
                        this.setState({
                            data: res.Data,
                        })

                    }
                    else {
                        let tempData = [...this.state.data, ...res.Data];

                        this.setState({
                            data: tempData,
                        })
                    }
                } else {
                    this.setState({
                        data: [],
                    })
                }
            }

            this.state.hasMore = false;
        }
        else {
            let params;
            params = {
                "userBID": this.state.userPID,
                "pageSize": NUM_ROWS,
                "pageIndex": pageIndex,
                "type": this.state.exType
            }

            const res = await httpAxios(api.getExhListApp, params);
            if (res) {
                const str = res.Message;
                const count = Number(str);
                this.state.rowCount = count;
                if (res.Data.length > 0) {
                    if (this.state.refreshing) {
                        this.setState({
                            data: res.Data,
                        })

                        // if (res.Data.length == count) {
                            this.state.hasMore = false;
                        // }
                    }
                    else {
                        let tempData = [...this.state.data, ...res.Data];

                        this.setState({
                            data: tempData,
                        })

                        // if (tempData.length == count) {
                            this.state.hasMore = false;
                        // }
                    }
                } else {
                    this.setState({
                        data: [],
                    })
                    this.state.hasMore = false;
                }
            }
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        // setTimeout(() => {
        //     this.setState({
        //         refreshing: false,
        //         isLoading: false,
        //     });
        // }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    getListRefresh = (type) => {
        this.setState({
            data: [],
        })
        pageIndex = 0;
        this.state.hasMore = true;
        this.setState({ exType: type, refreshing: true, isLoading: true }
            //     , () => {
            //     this.getList();
            // }
        );

        // setTimeout(() => {
        //     this.setState({
        //         refreshing: false,
        //         isLoading: false,
        //     });
        // }, 600);
    }

    render() {
        const {
            data,
            comTypes,
            livedata,
            targetID
        } = this.state;

        // const row = (rowData, sectionID, rowID) => {
        //     // console.log(rowID);
        //     let obj = {};
        //     if (rowID < data.length) {
        //         obj = data[rowID];
        //     } else {

        //     }

        //     let id = obj.ExID;
        //     if (id == undefined || id == null) {
        //         id = obj.ID;
        //     }


        //     // return (
        //     //     <div key={rowID}>
        //     //         <NewsDetailWrapper >
        //     //             {/* <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", buttonTitle: obj.EXState != 3 ? "添加参展产品" : '', weburl: '/MyExhibition/MyExDetailList/' + id + '/' + encodeURI(obj.Title) })); }} > */}
        //     //             <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/MyExhibition/MyExDetailList/' + id + '/' + encodeURI(obj.Title) })); }} >
        //     //                 <NewsItemOther>
        //     //                     <NewsTitle>{obj.Title}</NewsTitle>
        //     //                     <VideoImg>
        //     //                         <img src={obj.CoverImg} alt="" />
        //     //                         <VideoIcon>
        //     //                             <span>{(new Date(obj.StartTime)).getFullYear()}</span>
        //     //                         </VideoIcon>
        //     //                         <ExhibitionDiv />
        //     //                         {obj.EXState == 2 && <LiveState>正在展出</LiveState>}
        //     //                         {obj.EXState == 3 && <LiveStates>往期展出</LiveStates>}
        //     //                         <ExhibitionTime>{(new Date(obj.StartTime)).getMonth() + 1}月{(new Date(obj.StartTime)).getDate()}日～{(new Date(obj.FinishTime)).getMonth() + 1}月{(new Date(obj.FinishTime)).getDate()}日</ExhibitionTime>
        //     //                     </VideoImg>
        //     //                 </NewsItemOther >
        //     //                 <NewsPub>
        //     //                     <img src={obj.HeadImage} alt="" />
        //     //                     <span>{obj.Organizer}</span>
        //     //                     <span>主办方</span>
        //     //                 </NewsPub>
        //     //             </a>
        //     //         </NewsDetailWrapper>
        //     //     </div>
        //     // );
        // };

        let tagID = this.state.exType == 1 ? "ID" : "ExID";

        return (<div>
            {/* <NavBar>
                 <span>展会</span>
                 <SearchIcon onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "searchnav", weburl: "/search" })) }}><img src={searchButton} alt="搜索" /></SearchIcon>
             </NavBar>
              */}
            <NavBar>
                <Label cb={this.getListRefresh} list={comTypes} />
            </NavBar>
            <div style={{ height: '3rem' }}></div>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            <NewsDetailWrapper >
                                <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", buttonTitle: obj.EXState != 3 ? "添加参展产品" : '', weburl: '/MyExhibition/MyExDetailList/' + obj[tagID] + '/' + encodeURI(obj.Title) })); }} >
                                    {/* <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", buttonTitle: obj.EXState != 3 ? "添加参展产品" : '', weburl: '/MyExhibition/MyExDetailList/' + (obj.ExID == undefined || obj.ExID == null) ? obj.ID : obj.ExID + '/' + encodeURI(obj.Title) })); }} > */}
                                    {/* <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", weburl: '/MyExhibition/MyExDetailList/' + (obj.ExID == undefined || obj.ExID == null) ? obj.ID : obj.ExID + '/' + encodeURI(obj.Title) })); }} > */}
                                    <NewsItemOther>
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.CoverImg} alt="" />
                                            <VideoIcon>
                                                <span>{(new Date(obj.StartTime)).getFullYear()}</span>
                                            </VideoIcon>
                                            <ExhibitionDiv />
                                            {obj.EXState == 2 && <LiveState>正在展出</LiveState>}
                                            {obj.EXState == 3 && <LiveStates>往期展出</LiveStates>}
                                            <ExhibitionTime>{(new Date(obj.StartTime)).getMonth() + 1}月{(new Date(obj.StartTime)).getDate()}日～{(new Date(obj.FinishTime)).getMonth() + 1}月{(new Date(obj.FinishTime)).getDate()}日</ExhibitionTime>
                                        </VideoImg>
                                    </NewsItemOther >
                                    <NewsPub>
                                        <img src={obj.HeadImage} alt="" />
                                        <span>{obj.Organizer}</span>
                                        <span>主办方</span>
                                    </NewsPub>
                                </a>
                            </NewsDetailWrapper>
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div>);
    }
}

ReactDOM.render(<MyExhibition />, document.getElementById('root'));
export default MyExhibition