import styled from 'styled-components';
import {
  theme, themenew
} from '../../../common/color';


export const ProductWrapper = styled.div`
  padding-top: 1.25rem;
  background: #fff;
  .space-carousel {
    padding: 2rem 0;
    overflow: hidden;
  }
`
export const ProductTitle = styled.div`
  width: 65%;
  margin-left: 4%;
  float: left;
  >div{
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.875rem;
    margin-bottom: 0.75rem;
  }
  >span{
    font-size: 0.875rem;
  }
`
export const CollectionButton = styled.div`
  background-image: linear-gradient(135deg, #0060EC 0%, #B82CFC 100%);
  border-radius: 4px;
  width: 3.625rem;
  height: 1.75rem;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  margin-right: 4%;
  float: right;
`
export const CollectionButtonSelect = styled.div`
  background: #f1f1f1;
  border-radius: 4px;
  width: 3.625rem;
  height: 1.75rem;
  color: ${themenew.text_color};
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  margin-right: 4%;
  float: right;
`
export const Tabs = styled.div`
  width:100%;
  margin:0 auto 0.5rem;
  display:flex;
  overflow-x:auto;
  overflow-y:hidden;
  font-size:1rem;
  ::-webkit-scrollbar{
		width:0;
		height: 0;
		display:none;
	}
  >div{
    padding:0 4%;
    flex-shrink: 0;
    color:${themenew.text_color};
    margin-top:0.33rem;
    >div{
      width:1.625rem;
      height:0.1875rem;
      margin:0.3rem auto 0;
      background-color:#4228CF;
      border-radius:0.093rem;
    }
  }
  .selected{
    font-size:1rem;
    font-weight:700;
  }
`
export const SplitLine = styled.div`
  border-bottom: 0.5px solid #D6D6D6;
`
export const ProductDetailBox = styled.div`
  padding: 1rem 4%;
  line-height: 1.25rem;
  font-size: 0.875rem;
  min-height: 10rem;
  video{
    width: 100%;
    background: #000;
    margin: 1rem 0;
  }
  img{
    width: 100%;
    background: #000;
    margin: 1rem 0;
  }
`
export const ListItem = styled.div`
  margin-bottom: 1rem;
  div{
    display: inline-block;
    margin-right: 1rem;
    font-size: 0.75rem;
    color: #7C8698;
  }
  span{
    display: inline-block;
    color: #203152;
    font-size: 0.75rem;
  }
`

export const DistributorBox = styled.div`
  width: 100%;
  height: 5rem;
  border-bottom: 0.5px solid #d6d6d6;
  padding-top: 1.25rem;
`
export const DistributorImg = styled.div`
  background: #000000;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.10);
  border-radius: 6px;
  width: 2.875rem;
  height: 2.875rem;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 0.5rem;
  img{
    width:100%;
    min-height: 2.875rem;
    object-fit: cover;
  }
`
export const DistributorTitle = styled.div`
  float: left;
  width: 60%;
  >div{
    font-size: 0.875rem;
    color: ${themenew.text_color};
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-bottom: 0.3rem;
  }
  span{
    font-size: 0.75rem;
    color: ${themenew.subtext_color};
  }
`
export const DistributorBotton = styled.div`
  margin-top: 0.6rem;
  background: #771AE3;
  border-radius: 6px;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.625rem;
  height: 1.625rem;
  text-align: center;
  width: 4.25rem;
  float: right;
`
export const NumWrapper = styled.div` 
  width:92%;
  height:60px;
  margin-left:4%;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  display:flex ;
  justify-content:space-between;
  align-items:center;
  padding-left:15px ;
  padding-right:26px ;
  >div{
    font-size: 12px;
    color:${themenew.subtext_color} ;
    >span{
      font-size: 20px;
      color:${themenew.error_color} ;
    }
  }
  >a{
    font-size: 14px;
    color:${themenew.text_color} ;
  }
`