import React, {
    Component
} from 'react';
import {
    CommunityDetailWrapper,
    CommentBox,
    CommentBoxLeft,
    CommentBoxRight,
    CommentBoxRightTwo,
    ContentWrapper,
    CommentWrapper,
    QuickReplyBuding,
    QuickReplyInputTwo,
    QuickReplyInputTwos,
    CommentInputButtonBuding,
    QuickReplyOperationTwo,
    Focused,
    Focus,
} from './CommunityDetailStyle';
import {
    DividingLine
} from '../../common/style'
import httpAxios2 from "../../http/httpAxios2";
import httpAxios from "../../http/httpAxios";
import api from "../../http/api";
import {
    filterHtml,
    startMinsTime
} from '../../utils/utils';
import {
    message
} from 'antd';
import axios from 'axios';
import { Toast } from 'antd-mobile-v2';
class ReplyComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoId: this.props.match.params.id,
            type: this.props.match.params.type,
            userId: this.props.location.search.replace('?', ''),//从网址中取栏目类型
            commentData: [],
            commentDataUserID: '',
            replyData: [],
            commentText: '',
            commentTextFocus: false,
            isLiked: false,
            replyID: 0,
            toUserID: '',
            focused: false,
            likeFocused: false,
        }
    }
    componentWillReceiveProps(next) {
        this.checkFocus(this.state.commentDataUserID);
    }
    componentDidMount() {
        this.getCommonCommentList();
        this.getCommonReplyList();
    }
    //获取回复分页数据
    getCommonReplyList = async (PageIndex = 1, PageSize = 100) => {
        const {
            type,
            infoId,
            userId
        } = this.state;
        let _userId = '00000000-0000-0000-0000-000000000000';
        if (userId != '') _userId = userId;
        const res = await httpAxios(api.getCommonReplyList, {
            CommentID: infoId,
            Type: type,
            UserID: _userId,
            PageIndex: PageIndex,
            PageSize: PageSize
        })
        if (res && res.Data) {
            this.setState({
                replyData: res.Data,
            });
        }
    }
    //根据评论id 和 type 获取评论信息
    getCommonCommentList = async () => {
        const {
            type,
            infoId,
            userId
        } = this.state;
        let _userId = '00000000-0000-0000-0000-000000000000';
        if (userId != '') _userId = userId;
        const res = await httpAxios2(api.getComment, {
            CommentID: infoId,
            Type: type,
            userID: _userId
        })
        if (res && res.Data) {
            this.setState({
                commentData: res.Data,
                toUserID: res.Data.UserID,
                commentDataUserID: res.Data.UserID,
            });
        }
    }

    //点击按钮input 输入框获取焦点时执行
    buttonInputFocus = (replyID, toUserID) => {
        this.setState({
            replyID: replyID,
            toUserID: toUserID,
            commentTextFocus: true
        }, () => {
            this.textInput.focus()
        })
    }
    //评论input 输入框获取焦点时执行
    handleInputFocus = () => {
        this.setState({
            commentTextFocus: true
        }, () => {
            this.textInput.focus()
        })
    }


    //发布回复
    submitReply = async () => {
        const {
            type,
            userId,
            infoId,
            commentText,
            replyID,
            toUserID,
            commentData
        } = this.state;
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (!this.state.commentText) return
        const res = await httpAxios(api.creatCommonReply, {
            "Type": type,
            "CommentID": infoId,
            "ReplyID": replyID,
            "UserID": userId,
            "ToUserID": toUserID,
            "Content": commentText
        })

        if (res) {
            this.getCommonReplyList();
            window.location.href = "#commentTop";
            this.setState({
                commentTextFocus: false,
                commentText: '',
                replyID: 0,
                toUserID: commentData.UserID,
            });
        }
    }

    //检查是否已经关注
    checkFocus = async (id) => {
        if (!id) return
        // if (!this.state.userId) return
        if (this.state.userId) {
            const res = await axios.get(api.checkUserFocus.url + '?userID=' + this.state.userId + '&focusUserID=' + id)
            if (res && res.status === 200) {
                if (res.data.Code !== 404) {
                    this.setState({
                        focused: true
                    })
                }
            }
        }
    }

    //增加关注
    addFocus = async (author) => {
        if (!this.state.userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        if (this.state.userId === author) {
            message.info('用户不能关注自己');
            return
        }
        const {
            focused
        } = this.state;
        if (focused) return
        const res = await httpAxios2(api.addUserFollow, {
            User1: this.state.userId,
            User2: author
        })
        if (res) {
            this.setState({
                focused: true
            })
        }
    }
    //移除关注
    removeFocus = async (author) => {
        const {
            focused
        } = this.state;
        if (!focused) return
        const res = await httpAxios2(api.removeUserFocus, {
            User1: this.state.userId,
            User2: author,
        })
        if (res) {
            this.setState({
                focused: false
            })
        }
    }
    //评论点赞
    submitCommentLike = async () => {
        const {
            userId,
            infoId,
            type
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        const res = await httpAxios2(api.createCommonCommentLike, {
            commentID: infoId,
            type: type,
            userID: userId,
        })
        if (res.Code == 200) {
            this.getCommonCommentList();
        } else {
            alert(res.Message);
        }
    }
    //删除评论点赞
    deleteCommonCommentLike = async () => {
        const {
            userId,
            infoId,
            type
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        const res = await httpAxios2(api.deleteCommonCommentLike, {
            commentID: infoId,
            type: type,
            userID: userId,
        })
        if (res.Code == 200) {
            this.getCommonCommentList();
        } else {
            alert(res.Message);
        }
    }

    //回复点赞
    creatCommonReplyLike = async (replyID, toUserID) => {
        const {
            userId,
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        let param = {
            "ID": replyID,
            "UserID": userId,
            "ToUserID": toUserID
        }
        const res = await httpAxios(api.creatCommonReplyLike, param)
        if (res.Code == 200) {
            this.getCommonReplyList();
        } else {
            alert(res.Message);
        }
    }
    //删除回复点赞
    deleteCommonReplyLike = async (replyID, toUserID) => {
        const {
            userId,
        } = this.state;
        if (!userId) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            return
        }
        let param = {
            "ID": replyID,
            "UserID": userId,
            "ToUserID": toUserID
        }
        const res = await httpAxios(api.deleteCommonReplyLike, param)
        if (res.Code == 200) {
            this.getCommonReplyList();
        } else {
            alert(res.Message);
        }
    }

    render() {
        const {
            commentData,
            replyData,
            userId,
            commentText,
            commentTextFocus,
            isLiked,
            focused,
        } = this.state;
        return (
            <CommunityDetailWrapper>
                <ContentWrapper>
                    <CommentBox>
                        <CommentBoxLeft>
                            <img src={commentData.HeadImg}></img>
                        </CommentBoxLeft>
                        <CommentBoxRightTwo>
                            <h1>
                                {commentData.PNickName}
                                {focused ? <Focused onClick={this.removeFocus.bind(this, commentData.UserID)}>已关注</Focused> : <Focus onClick={this.addFocus.bind(this, commentData.UserID)}>关注</Focus>}
                            </h1>
                            <h2>{filterHtml(commentData.Content)}</h2>
                            <div>
                                <span>{startMinsTime(commentData.AddTime)}</span>
                                {commentData.IsLike ? <div className="isLike" onClick={this.deleteCommonCommentLike}>
                                    <span>{commentData.LikeNum}</span>
                                    <i className="iconfont">&#xe780;</i>
                                </div> : <div onClick={this.submitCommentLike}>
                                    <span>{commentData.LikeNum}</span>
                                    <i className="iconfont">&#xe755;</i>
                                </div>}
                            </div>
                        </CommentBoxRightTwo>
                    </CommentBox>
                    <DividingLine />
                    <a name="commentTop"></a>
                    <CommentWrapper>
                        <span>全部回复</span>
                        {replyData.length == 0 && <CommentBox><span style={{
                            width: '100%',
                            height: '5rem',
                            textAlign: 'center',
                            display: 'block',
                            lineHeight: '5rem',
                            fontSize: '0.88rem',
                            color: '#7C8698'
                        }}>暂无回复，点击抢沙发</span></CommentBox>}
                        {
                            replyData.map((d) => (
                                <CommentBox>
                                    <CommentBoxLeft>
                                        <img src={d.HeadImg}></img>
                                    </CommentBoxLeft>
                                    <CommentBoxRight>
                                        <h1>
                                            {d.NikeName}
                                            {d.IsLike ? <span className="isLike" onClick={this.deleteCommonReplyLike.bind(this, d.ID, d.UserID)}>
                                                <span>{d.LikeNum}</span>
                                                <i className="iconfont">&#xe780;</i>
                                            </span> : <span onClick={this.creatCommonReplyLike.bind(this, d.ID, d.UserID)}>
                                                <span>{d.LikeNum}</span>
                                                <i className="iconfont">&#xe755;</i>
                                            </span>}
                                        </h1>
                                        {d.ReplyContent != null && <h3>@{d.ReplyNikeName}：{d.ReplyContent}</h3>}
                                        <h2>{filterHtml(d.Content)}</h2>
                                        <div>
                                            <div onClick={this.buttonInputFocus.bind(this, d.ID, d.UserID)}><span>回复</span><i className="iconfont">&#xe744;</i></div>
                                            <span>{startMinsTime(d.SendTime)}</span>
                                        </div>
                                    </CommentBoxRight>
                                </CommentBox>
                            ))
                        }
                    </CommentWrapper>
                </ContentWrapper>
                <QuickReplyBuding style={{ height: !commentTextFocus ? '3rem' : '5rem' }}>
                    {
                        !commentTextFocus ? <QuickReplyInputTwo onClick={this.handleInputFocus}>
                            <i className='iconfont'>&#xe62d;</i>
                            <span>写评论...</span>
                        </QuickReplyInputTwo> : <QuickReplyInputTwos >
                            <textarea
                                //value={commentText}
                                ref={r => this.textInput = r}
                                onChange={
                                    (e) => { this.setState({ commentText: e.target.value.trim() }) }
                                }
                                confirm-type='send'
                                placeholder='友善是交流的起点'
                            ></textarea>
                        </QuickReplyInputTwos>
                    }
                    {
                        commentTextFocus && <>
                            {!commentText ? <CommentInputButtonBuding style={{ color: '#666' }}>发布</CommentInputButtonBuding>
                                : <CommentInputButtonBuding onClick={this.submitReply}>发布</CommentInputButtonBuding>}
                        </>
                    }
                    {
                        !commentTextFocus && <QuickReplyOperationTwo >
                            {commentData.IsLike ? <div className='active' onClick={this.deleteCommonCommentLike}>
                                <i className="iconfont">&#xe780;</i>
                            </div> : <div onClick={this.submitCommentLike}>
                                <i className="iconfont">&#xe755;</i>
                            </div>}
                        </QuickReplyOperationTwo>
                    }
                </QuickReplyBuding>
                {commentTextFocus ? <div onClick={() => {
                    this.setState({
                        commentTextFocus: false,
                        replyID: 0,
                        toUserID: commentData.UserID,
                        commentText: '',
                    })
                }} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10 }}></div> : ''}
            </CommunityDetailWrapper>
        )
    }
}

export default ReplyComment