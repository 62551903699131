import React, { Component } from 'react';
import { Carousel } from 'antd-mobile-v2';
import {
    ProductWrapper,
    ProductTitle,
    CollectionButton,
    CollectionButtonSelect,
    Tabs,
    SplitLine,
    ProductDetailBox,
    ListItem,
    DistributorBox,
    DistributorImg,
    DistributorTitle,
    DistributorBotton,
    NumWrapper
} from './style';
import httpAxios from "../../../http/httpAxios";
import httpAxios2 from "../../../http/httpAxios2";
import api from "../../../http/api";
import {
    filterVideoSrc
} from "../../../utils/utils";
import DijiaModal from '../../../common/dijiaModal';

class ProductDetail extends Component {

    state = {
        userPID: '',
        info: {},
        imgHeight: 300,
        comTypes: [],
        activeKey: '0',
        isCollected: false,
        selectCity: '',
        liuyan: '',
        userData: []
    }

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID,
                userData: data
            })

            that.checkProductCollection();

        }

        window.gpsInfo = function (value) {
            that.setState({ selectCity: value });
        };

        window.search = (data) => {
            const dataJson = JSON.parse(data);
            if (dataJson.type == 1) {
                that.setState({ selectCity: dataJson.value });
            }

            if (dataJson.type == 2) {
                that.setState({ liuyan: dataJson.value })
            }
        }

        await this.getProInfo();
        this.getProductCompany();

        // setTimeout(() => {
        //     window.ReactNativeWebView.postMessage(JSON.stringify({ type: "getgpsinfo", value: 1 }));
        // }, 1000);
    }

    getProInfo = async () => {
        const res = await httpAxios(api.getProductDetail, {
            productID: this.props.match.params.id,
        });
        if (res) {
            this.setState({
                info: res.Data
            })
        }
        // alert(JSON.stringify(res.Data))
    }

    getProductCompany = async () => {
        const res = await httpAxios2(api.getProductCompany, {
            "ProductID": this.props.match.params.id,
        });
        if (res) {
            this.setState({
                Data: res.Data
            })
        }
    }

    //验证收藏
    checkProductCollection = async () => {
        const res = await httpAxios(api.checkProductCollection, { ProductID: this.props.match.params.id, UserID: this.state.userPID });
        if (res.Code === 200) {
            this.setState({ isCollected: res.Data.isCollected });
        }
    }

    //增加收藏
    creatProductCollection = async () => {
        const res = await httpAxios(api.creatProductCollection, { ProductID: this.props.match.params.id, UserID: this.state.userPID });
        if (res.Code === 200) {
            this.setState({ isCollected: true });
        }
    }

    //取消收藏
    removeProjectCollection = async () => {
        const res = await httpAxios(api.removeProjectCollection, { ProductID: this.props.match.params.id, UserID: this.state.userPID });
        if (res.Code === 200) {
            this.setState({ isCollected: false });
        }
    }

    handleClick = (id) => {
        this.setState({
            activeKey: id
        })
    }

    //收藏
    handleCollectionClick = () => {
        if (!this.state.userPID) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "login" }));
            // message.info('需要在登录后 才能收藏文章');
            return
        }

        if (this.state.isCollected) {
            this.removeProjectCollection();
        }
        else {
            this.creatProductCollection()
        }
    }

    render() {
        const {
            activeKey,
            info,
            Data,
            isCollected,
            selectCity,
            liuyan,
            userData,
        } = this.state;
        return (
            <ProductWrapper>
                <ProductTitle>
                    <div>{info.ProductName}</div>
                    <span>分类&nbsp;&nbsp;{info.ProductType}</span>
                </ProductTitle>
                {
                    isCollected ? <CollectionButtonSelect onClick={() => { this.handleCollectionClick() }}>已收藏</CollectionButtonSelect> : <CollectionButton onClick={() => { this.handleCollectionClick() }}>收藏</CollectionButton>
                }
                <Carousel className="space-carousel"
                    frameOverflow="visible"
                    cellSpacing={10}
                    slideWidth={0.9}
                    infinite
                    dots={false}
                    beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                    afterChange={index => this.setState({ slideIndex: index })}
                >
                    {info.ProductImgs != undefined && info.ProductImgs?.split(',').map((val, index) => (
                        <div style={{
                            height: this.state.imgHeight,
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <img
                                src={val}
                                alt=""
                                style={{
                                    width: '100%',
                                    minHeight: this.state.imgHeight,
                                    objectFit: 'cover'
                                }}
                                onLoad={() => {
                                    // fire window resize event to change height
                                    window.dispatchEvent(new Event('resize'));
                                }}
                            />
                        </div>
                    ))}
                </Carousel>
                <NumWrapper>
                    <div>参考价<span>￥ {info.ProductPrice}</span></div>
                    <DijiaModal selectCity={selectCity} liuyan={liuyan} userData={userData} infoId={info.ID} type={"product"} />
                    {/* <a>点击购买</a> */}
                </NumWrapper>
                <Tabs>
                    <div className={activeKey === '0' ? 'selected' : ''} onClick={this.handleClick.bind(this, '0')}>
                        <span>产品介绍</span>
                        {
                            activeKey === '0' ? <div /> : ''
                        }
                    </div>
                    <div className={activeKey === '1' ? 'selected' : ''} onClick={this.handleClick.bind(this, '1')}>
                        <span>产品视频</span>
                        {
                            activeKey === '1' ? <div /> : ''
                        }
                    </div>
                    <div className={activeKey === '2' ? 'selected' : ''} onClick={this.handleClick.bind(this, '2')}>
                        <span>产品参数</span>
                        {
                            activeKey === '2' ? <div /> : ''
                        }
                    </div>
                    <div className={activeKey === '3' ? 'selected' : ''} onClick={this.handleClick.bind(this, '3')}>
                        <span>相关经销商</span>
                        {
                            activeKey === '3' ? <div /> : ''
                        }
                    </div>
                </Tabs>
                <SplitLine />
                {
                    activeKey == '0' && <ProductDetailBox><div dangerouslySetInnerHTML={{ __html: filterVideoSrc(info.ProductDescription) }}></div></ProductDetailBox>
                }
                {
                    activeKey == '1' && <ProductDetailBox>
                        {
                            JSON.parse(info.VideoSrc).map(item => (
                                <video controls='controls' controlslist='nodownload' src={item.url}></video>
                            ))
                        }

                    </ProductDetailBox>
                }
                {
                    activeKey == '2' && <ProductDetailBox>
                        {
                            JSON.parse(info.ProductParameters).map(item => (
                                <ListItem>
                                    <div>{item.key}{'：'}</div>
                                    <span>{item.value}</span>
                                </ListItem>
                            ))
                        }

                    </ProductDetailBox>
                }
                {
                    activeKey == '3' && <ProductDetailBox>
                        {
                            Data.map(p => (
                                <DistributorBox>
                                    <DistributorImg>
                                        <img src={p.HeadImg} alt=""></img>
                                    </DistributorImg>
                                    <DistributorTitle>
                                        <div>{p.CompanyName}</div>
                                        <a onClick={() => {
                                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                                type: "author", weburl: "/CompanyAuthor/" + p.UserBID
                                            }))
                                        }}>
                                            <span>公司主页<i className="iconfont">&#xe744;</i></span>
                                        </a>
                                    </DistributorTitle>
                                    {p.Link != undefined && <a href={p.Link} target="_blank" rel="noopener noreferrer"><DistributorBotton>
                                        去购买
                                    </DistributorBotton></a>}
                                </DistributorBox>
                            ))
                        }
                    </ProductDetailBox>
                }
            </ProductWrapper >
        )
    }
}

export default ProductDetail;
