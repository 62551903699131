import React, {
    Component
} from 'react';
import MycenterPortrait from './components/MycenterPortrait';
import BussPortrait from './components/BussPortrait';

import {
    MycenterWrapper
} from './MycenterStyle';
import axios from 'axios'
import api from "../../http/api"
import httpAxios from "../../http/httpAxios";
import {
    CURRENT_USER_ID,
    CURRENT_USER_INFO
} from "../../const/const";
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
const history = creatHistory();//返回上一页这段代码

//import BusinessContentBrandsDetail from '../businesscenter/components/BusinessContentBrandsDetail'
class Mycenter extends Component {

    state = {
        info: {
            "UserPID": "",
            "PNickName": "",
            "HeadImg": null,
            "Sex": null,
            "City": null,
            "IsVIP": false,
            "VipEndTime": null,
            "Count": null,
            "FollowVal": null,
            "FansVal": null,
            "Titles": null,
            "IsLocked": false,
            "proclamationTime": null,
            "proclamationDelect": null,
            "CreatTime": "",
            "Remark": null,
            "MediaMan": false,
            "EvaluationMan": false,
            "BussUserID": null,
            "IsMapBussUser": false,
            "ZanTotal": null,
            WirteSB: '',
            Count1: 0,
            Count2: 0
        },
        ToMyTotalData: 0,
    }
    componentDidMount() {
        // alert('1');
        let userInfo = sessionStorage.getItem("BUSS_CENTER_WEBAPP_USERINFO");
        if (userInfo != null) {
            this.setState({
                info: JSON.parse(userInfo)
            })
        }
        // alert('2');
        var that = this;
        window.userInfo = function (data, params) {
            // alert(JSON.stringify(data));
            if (data != "") {
                httpAxios(api.getUserLoginInfo, {
                    userID: data.UserPID,
                    // token: data.Token
                }).then(res => {
                    if (res.Code === 200) {
                        that.setState({ info: res.Data });

                        //alert(res.Data);
                        sessionStorage.setItem('BUSS_CENTER_TO_BRAND_MANAGER_ID', data.UserPID);
                        sessionStorage.setItem('BUSS_CENTER_WEBAPP_USERINFO', JSON.stringify(res.Data));
                    }
                }).catch(e => {
                })

                that.getToMyTotalCount(data.UserPID);


                if (params != null) {
                    //跳转路由
                    let tempData = JSON.parse(params);
                    switch (tempData.type) {
                        case "personal":
                            if (that.props.history.location.pathname.indexOf("personal") == -1) {
                                that.props.history.push('/personal');
                            }
                            break;
                        case "settings":
                            if (that.props.history.location.pathname.indexOf("settings") == -1) {
                                that.props.history.push('/settings');
                            }
                            break;
                        case "community":
                            if (that.props.history.location.pathname.indexOf("community") == -1) {
                                that.props.history.push('community/communitydetail/' + tempData.id);
                            }
                            break;
                    }
                }
            }
        }

    }
    componentWillUnmount() {
        sessionStorage.setItem('AUDIO_MYCENTERN_BODY_TYPE', '')
    }
    toBrandManage = () => {
        sessionStorage.setItem('AUDIO_MYCENTERN_BODY_TYPE', 'manage');
        sessionStorage.setItem('BUSS_CENTER_TO_BRAND_MANAGER_ID', this.state.info.UserPID)
        sessionStorage.setItem('BUSS_CENTER_TO_BRAND_DETAIL_ID', CURRENT_USER_INFO.BrandID)
        this.setState({})
    }
    toOriginal = () => {
        sessionStorage.setItem('AUDIO_MYCENTERN_BODY_TYPE', 'original')
        this.setState({})
    }
    //获取总消息统计数量
    getToMyTotalCount = async (userPID) => {
        const res = await httpAxios(api.getToMyTotalCount, {
            userID: userPID,
        })
        if (res && res.Data) {
            this.setState({
                ToMyTotalData: res.Data,
            });
        }
    }
    render() {
        const {
            info,
            ToMyTotalData
        } = this.state;
        return (
            <MycenterWrapper>
                {
                    info.IsBussUser ? <BussPortrait info={info} toBrandManage={this.toBrandManage} ToMyTotalData={ToMyTotalData} /> :
                        <MycenterPortrait info={info} toBrandManage={this.toBrandManage} ToMyTotalData={ToMyTotalData} />
                }
            </MycenterWrapper>
        )
    }
}

export default Mycenter;