import React, {
    Component
} from 'react';
import { List, InputItem } from 'antd-mobile-v2';
import {
    SettingsWrapper,
} from './MycenterPortraitStyle';
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
import api from "../../../http/api"
import httpAxios from "../../../http/httpAxios"
import { Toast, Button } from 'antd-mobile-v2';

const history = creatHistory();//返回上一页这段代码


class SignedName extends Component {
    state = {
        WriteSB: '',
        userPID: ''
    };

    async componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
            that.getUserInfo(data.UserPID,data.Token);
        }
    }

    //获取用户信息
    getUserInfo = async (id,Token) => {
        const res = await httpAxios(api.getBussInfo, {
            userid: id,
            token: Token
        })
        if (res && res.Data) {
            this.setState({ WriteSB: res.Data.Introduction });
        }
    }

    //保存信息
    onSubmit = (key, value) => {
        let dataTemp = {
            UserBID: this.state.userPID
        };
        dataTemp[key] = value;

        httpAxios(api.bussUpdateUserInfo, dataTemp).then(res => {
            if (res) {
                Toast.info('信息修改成功')
            }
        })
        
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));

    }

    goBackPage = () => {
        history.goBack();  //返回上一页这段代码
    }

    render() {
        return (
            <SettingsWrapper>
                <List renderHeader={() => ''}>
                    <InputItem
                        className='listheight'
                        placeholder="请输入企业简介"
                        value={this.state.WriteSB}
                        onChange={(value) => this.setState({ WriteSB: value })}
                    >企业简介</InputItem>
                </List>
                <Button type="primary" style={{ margin: '1rem 4%' }} onClick={() => {
                    this.onSubmit('Introduction', this.state.WriteSB);
                }}>保存信息</Button>
            </SettingsWrapper>
        )
    }
}

export default SignedName;