import React, {
    Component
} from 'react';
import {
    Wrapper,
    Title1,
    Auto,
} from '../../../file/FileStyle';

class SharedList extends Component {
    render() {
        return (
            <Wrapper>
                <Title1>
                    一、第三方SDK目录
                </Title1>
                <Auto>
                    <p>为适配在不同手机设备上使用中乐之声APP及第三方平台服务，实现您的设备接收PUSH推送、数据统计等功能，中乐之声APP接入了第三方SDK。</p>
                    <p>我们对接入的相关第三方SDK在本目录中列明。我们会对努力保障您的信息安全，尽可能及时更新并向您说明中乐之声APP所接入第三方的SDK的最新情况。但请您注意，第三方SDK可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。
                    </p>
                </Auto>
                <Title1>
                    安卓系统第三方SDK列表
                </Title1>
                <Auto style={{ textIndent: '0' }}>
                    <p>
                        <b>新浪微博</b><br />
                        所属公司：北京微梦创科网络技术有限公司<br />
                        使用目的：微博登录和分享<br />
                        收集数据：设备识别符、MAC地址，网络状态、网络类型、网络信息、手机基本信息<br />
                        <a href='https://open.weibo.com/wiki/Sdk/privacy'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>腾讯QQ互联</b><br />
                        所属公司：腾讯科技（深圳）有限公司<br />
                        使用目的：QQ登录和分享<br />
                        收集数据：设备标识符、获取任务信息、读取手机状态、读/写入外部存储、访问网络、网络信息、检索正在运行的应用<br />
                        <a href='https://wiki.connect.qq.com/qq%E4%BA%92%E8%81%94sdk%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>微信开放平台SDK</b><br />
                        所属公司：深圳市腾讯计算机系统有限公司<br />
                        使用目的：微信登录和分享功能<br />
                        收集数据：用户相册图片和视频、微信头像、泥称、地区、语言、性别信息、软件安装列表、设备型号、MAC地址、设备参数和系统信息、网络信息、设备标识信息、位置信息<br />
                        <a href='https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>极光推送</b><br />
                        所属公司：深圳市和讯华谷信息技术有限公司<br />
                        使用目的：消息推送<br />
                        收集数据：设备参数及系统信息、设备识别符、网络信息与位置信息、APP应用列表及活跃状态信息<br />
                        <a href='https://www.jiguang.cn/license/privacy'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>华为推送</b><br />
                        所属公司：华为技术有限公司<br />
                        使用目的：消息推送<br />
                        收集数据：设备识别符、应用Token，Topic订阅关系、缓存消息内容、消息下发记录、Token申请记录、显示/点击/关闭打点上报记录<br />
                        <a href='https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>小米推送</b><br />
                        所属公司：小米移动软件有限公司<br />
                        使用目的：消息推送<br />
                        收集数据；设备识别符、设备参数、网络信息、MAC地址、任务列表、设备参数和系统信息、网络设备制造商<br />
                        <a href='https://dev.mi.com/console/doc/detail?pId=1822'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>oppo推送</b><br />
                        所属公司：广东欢太科技有限公司<br />
                        使用目的：消息推送<br />
                        收集数据：设备识别符、手机Region设置，设备型号，手机电量，手机操作系统版本及语言<br />
                        <a href='https://open.oppomobile.com/new/developmentDoc/info?id=10288'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>VIVO推送</b><br />
                        所属公司：维沃移动通信有限公司<br />
                        使用目的：消息推送<br />
                        收集数据；设备识别符、设备参数、网络信息、MAC地址、任务列表、设备参数和系统信息、网络设备制造商<br />
                        <a href='https://dev.vivo.com.cn/documentCenter/doc/366#w1-08512613'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>魅族推送</b><br />
                        所属公司：珠海市魅族通讯设备有限公司<br />
                        使用目的：消息推送<br />
                        收集数据：设备识别符，WiFi扫描列表，网络信息，软件安装列表，设备基本信息、推送到达信息、推送点击信息<br />
                        <a href='http://static.meizu.com/resources/term/privacy8.html'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>淘宝Agoo推送</b><br />
                        所属公司：浙江淘宝网络有限公司、淘宝（中国）软件有限公司<br />
                        使用目的：用于向用户推送消息<br />
                        收集数据：设备信息（IMSI、IMEI、MEID、运行中应用列表）<br />
                        <a href='https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao201703241622_61002.html?spm=a21bo.2017.1997523009.39.319b11d9wOV0o3'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>阿里push/阿里推送</b><br />
                        所属公司：阿里云计算有限公司<br />
                        收集用途/目的：提供推送信息服务<br />
                        收集个人信息类型：常用设备信息（如设备Mac地址、唯一设备IMEI/Android ID/device ID/GUID、SIM卡 IMSI信息等）；个人位置信息（GPS定位）；运⾏中进程信息<br />
                        可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、保持唤醒<br />
                        <a href='https://docs.jiguang.cn/jpush/guideline/intro/'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>阿里OSS</b><br />
                        所属公司：阿里云计算有限公司<br />
                        收集用途/目的：提供云储存服务<br />
                        收集个人信息类型：常用设备信息（如设备Mac地址、唯一设备IMEI/Android ID/device ID/GUID、SIM卡 IMSI信息等、运⾏中进程信息）<br />
                        可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、保持唤醒、内存卡写入、内存卡读取、访问外置内存卡、访问网络状态<br />
                        <a href='https://help.aliyun.com/document_detail/30052.html?spm=a2c4g.11186623.6.675.50a73a5aEFO3DI'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>阿里云mPaaS</b><br />
                        所属公司：阿里云计算有限公司<br />
                        收集用途/目的：阿里云mPaaS使用移动开发平台<br />
                        收集个人信息类型：常用设备信息（如设备Mac地址、唯一设备IMEI/Android ID/device ID/GUID、SIM卡 IMSI信息等、运⾏中进程信息）<br />
                        可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、保持唤醒、内存卡写入、内存卡读取、访问外置内存卡、访问网络状态<br />
                        <a href='http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202107091605_49213.html'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>React-native</b><br />
                        所属公司：Facebook<br />
                        使用目的：使用JavaScript语言实现跨平台移动应用开发<br />
                        收集数据：网络状态、设备识别符，Mac地址、网络地址、网络信息、运营商信息、设备参数和系统信息、网络状态、App版本信息、定位、粘贴板<br />
                        <a href='https://opensource.fb.com/legal/privacy/'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>友盟数据分析</b><br />
                        所属公司：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司<br />
                        使用目的：用户使用效果分析，并通过地理位置校准报表数据准确性，提供基础反作弊能力<br />
                        收集数据：网络状态、设备识别符、网络信息、任务列表、设备参数和系统信息<br />
                        <a href='https://www.umeng.com/policy'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>百度统计</b><br />
                        所属公司：北京百度网讯科技有限公司<br />
                        收集用途/目的：提供统计分析<br />
                        收集个人信息类型：设备信息、日志信息、位置信息、应用程序列表信息<br />
                        可能调用的权限：访问网络、访问网络状态、访问WI-FI状态、内存卡写入、内存卡读取、访问外置内存卡、访问网络状态<br />
                        <a href='https://tongji.baidu.com/web/help/article?id=330&type=0'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>移动一键登录</b><br />
                        所属公司：中移互联网有限公司<br />
                        使用场景：在用户使用移动手机号一键登录时使用<br />
                        收集个人信息类型：设备标识符 (如IDFV) 、WLAN接入点(如SSID、BSSID) 、位置信息 (如GPS) 、IP地址网络信息、设备MAC地址、软件列表、手机号、设备信息<br />
                        <a href='https://wap.cmpassport.com/resources/html/contract.html'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>联通一键登录</b><br />
                        所属公司：中国联合网络通信有限公司<br />
                        使用场景：在用户使用联通手机号一键登录时使用<br />
                        收集个人信息类型：WLAN接入点 (如SSID)、IP地址、手机号、网络信息、设备信息<br />
                        <a href='https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>电信一键登录</b><br />
                        所属公司：世纪龙信息网络有限责任公司<br />
                        使用场景：在用户使用电信手机号一键登录时使用<br />
                        收集个人信息类型：手机号、IP地址、网络信息、设备信息<br />
                        <a href='https://e.189.cn/sdk/agreement/detail.do'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>号码认证</b><br />
                        所属公司：网易公司<br />
                        收集用途/目的：号码认证<br />
                        使用场景：实现电话号码一键认证注册、登录<br />
                        收集个人信息类型：网络类型、Wi-Fi 类型、IMSI（仅 Android）<br />
                        <a href='https://dun.163.com/clause/privacy'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>网易易盾号码认证</b><br />
                        所属公司：网易公司<br />
                        收集用途/目的：号码认证<br />
                        使用场景：实现电话号码一键认证注册、登录<br />
                        收集个人信息类型：网络类型、Wi-Fi 类型、IMSI（仅 Android）<br />
                        <a href='https://dun.163.com/clause/privacy'>点击查看第三方隐私政策</a>
                    </p>
                </Auto>
                <Title1>
                    iOS系统第三方SDK列表
                </Title1>
                <Auto style={{ textIndent: '0' }}>
                    <p>
                        <b>新浪微博</b><br />
                        所属公司：北京微梦创科网络技术有限公司<br />
                        使用目的：微博登录和分享<br />
                        收集数据：设备识别符、MAC地址，网络状态、网络类型、网络信息、手机基本信息<br />
                        <a href='https://open.weibo.com/wiki/Sdk/privacy'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>腾讯QQ互联</b><br />
                        所属公司：腾讯科技（深圳）有限公司<br />
                        使用目的：QQ登录和分享<br />
                        收集数据：设备标识符、获取任务信息、读取手机状态、读/写入外部存储、访问网络、网络信息、检索正在运行的应用<br />
                        <a href='https://wiki.connect.qq.com/qq%E4%BA%92%E8%81%94sdk%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>微信开放平台SDK</b><br />
                        所属公司：深圳市腾讯计算机系统有限公司<br />
                        使用目的：微信登录和分享功能<br />
                        收集数据：用户相册图片和视频、微信头像、泥称、地区、语言、性别信息、软件安装列表、设备型号、MAC地址、设备参数和系统信息、网络信息、设备标识信息、位置信息<br />
                        <a href='https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>极光推送</b><br />
                        所属公司：深圳市和讯华谷信息技术有限公司<br />
                        使用目的：消息推送<br />
                        收集数据：设备参数及系统信息、设备识别符、网络信息与位置信息、APP应用列表及活跃状态信息<br />
                        <a href='https://www.jiguang.cn/license/privacy'>点击查看第三方隐私政策</a>
                    </p>
                    <p>
                        <b>React-native</b><br />
                        所属公司：Facebook<br />
                        使用目的：使用JavaScript语言实现跨平台移动应用开发<br />
                        收集数据：网络状态、设备识别符，Mac地址、网络地址、网络信息、运营商信息、设备参数和系统信息、网络状态、App版本信息、定位、粘贴板<br />
                        <a href='https://opensource.fb.com/legal/privacy/'>点击查看第三方隐私政策</a>
                    </p>
                </Auto>
                <Title1>
                    二、中乐之声与第三方共享数据列表
                </Title1>
                <Auto>
                    <p>为了向您提供更完善、优质的产品和服务，我们可能委托授权合作伙伴为您提供服务或代表我们履行职能，向其共享部分用户信息。对共享信息的合作方，我们会与其订立专门的数据保护协议或数据安全条款，约定严格的数据保护措施，在确保安全的前提下共享信息。<br />
                        <b>功能类型：</b>平台基础服务<br />
                        <b>涉及第三方：</b>包括一键登录、消息推送等合作方<br />
                        <b>共享目的：</b>实现实名认证、一键登录、消息推送等功能所需，详见第一部分《第三方SDK目录》<br />
                        <b>共享的个人信息：</b>基本通过SDK的方式实现，详见第一部分《第三方SDK目录》<br />
                        <b>功能类型：</b>数据服务<br />
                        <b>涉及第三方：</b>数据统计、数据分析的合作方<br />
                        <b>共享目的：</b>数据统计所需<br />
                        <b>共享的个人信息：</b>设备信息、终端型号、地理位置，详见第一部分《第三方SDK目录》<br />
                        <b>功能类型：</b>内容安全监测服务<br />
                        <b>涉及第三方：</b>安全监测合作方<br />
                        <b>共享目的：</b>违规文本、音视频标注和分析<br />
                        <b>共享的个人信息：</b>直播流信息
                    </p>
                </Auto>
            </Wrapper>
        )
    }
}

export default SharedList