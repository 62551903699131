import React, {
    Component
} from 'react';
import {
    CommunityWrapper,
    ExhibitionWrapper,
    ExhibitionTitle,
    ExhibitionList,
    ExhibitionListImg,
    ExhibitionIcon,
    NewsPub,
    ExhibitionListIcon
} from './style';

import { Dialog, Toast } from 'antd-mobile'

import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";

import AutoResponsive from 'autoresponsive-react';

import axios from 'axios';

const playSvg = require('../../../../images/exhibition_icon_play.svg');
const imgSvg = require('../../../../images/exhibition_icon_img.svg');
const imgDefault = require('../../../../images/damage.png')



const NUM_ROWS = 6;
let pageIndex = 1;


class MyExDetailList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userPID: '',

            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,
            data: [],
            targetID: '0',
            hasMore: true,
            rowCount: 0,
            intervalId: 0,
        };
    }

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                data: [],
            });

            that.setState({
                userPID: data.UserPID
            }, () => {
                that.getList();
            })
        }

        window.addEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate() {
        document.body.style.overflow = 'auto';
        let that = this;
        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "添加参展产品", buttonTitle: '发布', weburl: "/MyExhibition/MyExDetailListPost/" + that.props.match.params.id + "/0" }));
            let newIntervalId = 0
            let timeEnd = 0
            newIntervalId = setInterval(async () => {
                timeEnd++
                if (timeEnd === 1800) {
                    clearInterval(that.state.intervalId);
                }
                if (localStorage.getItem('REFRESH_TAGS') === 'start') {
                    pageIndex = 1;
                    that.setState({
                        refreshing: true
                    })
                    await that.getList();
                    clearInterval(that.state.intervalId);
                    localStorage.setItem('REFRESH_TAGS', 'end');
                }
            }, 1000);
            that.setState({
                intervalId: newIntervalId
            })
        }
    }
    getList = async () => {
        let params;
        params = {
            "UserBID": this.state.userPID,
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            "ExID": this.props.match.params.id
        }

        const res = await httpAxios(api.getExList, params);
        if (res) {
            this.state.rowCount = res.Data;

            if (res.Message.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Message,
                        refreshing: false
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Message];

                    this.setState({
                        data: tempData,
                    })
                }
            }
            else {
                this.state.hasMore = false;
            }
        }

        this.setState({ isLoading: false });
    }

    handleScroll = (e) => {

        if (this.getScrollTop() + this.getWindowHeight() + 100 > this.getScrollHeight()) {
            if (this.state.isLoading == false && this.state.hasMore) {
                this.setState({ isLoading: true });
                pageIndex++;
                this.getList();
            }
        }
    }

    getScrollTop = () => {
        var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
        if (document.body) {
            bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
            documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
        return scrollTop;
    }

    getScrollHeight = () => {
        var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
        if (document.body) {
            bodyScrollHeight = document.body.scrollHeight;
        }
        if (document.documentElement) {
            documentScrollHeight = document.documentElement.scrollHeight;
        }
        scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
        return scrollHeight;
    }

    getWindowHeight = () => {
        var windowHeight = 0;
        if (document.compatMode == "CSS1Compat") {
            windowHeight = document.documentElement.clientHeight;
        } else {
            windowHeight = document.body.clientHeight;
        }
        return windowHeight;
    }

    getAutoResponsiveProps() {
        return {
            itemMargin: 15,//布局元素间距(对下外边距)
            containerWidth: window.screen.width,//容器总体宽度
            itemClassName: 'item',//布局元素选择器
            gridWidth: window.screen.width / 2,//栅格宽度，栅格宽度值会影响布局的精确度，同时也会影响渲染效率
            transitionDuration: '0'//设置动画持续时间
        };
    }
    //删除展会产品
    handleRemoveProduct = (title, id) => {
        Dialog.confirm({
            title: '确定删除' + title + '展会产品吗?',
            content: '删除后此展会产品将不会在展会显示，如需展示可以再次添加！',
            confirmText: '删除',
            onConfirm: async () => {
                const res = await axios.delete(api.deleteExProduct.url + '?productID=' + id);
                if (res && res.data.Code === 200) {
                    Toast.show({
                        icon: 'success',
                        content: '删除成功',
                    })
                    this.setState({
                        refreshing: true
                    })
                    await this.getList();
                }
            },
        })
    }

    render() {
        const {
            data,
        } = this.state;
        return (
            <CommunityWrapper>
                <ExhibitionTitle>
                    <span>{this.props.match.params.title}</span>
                    <div />
                </ExhibitionTitle>
                <ExhibitionWrapper>
                    <AutoResponsive ref="container" {...this.getAutoResponsiveProps()} >
                        {data.map((item, index) => {
                            // let img = new Image();
                            // img.src = item.MainPicLandScape;
                            let style = {
                                width: '46%',
                                //height: img.height * (window.screen.width * 0.46 / img.width) + 130,
                                // height: item.ImageScale == '43' ? 270 : item.ImageScale == '11' ? 315 : 375,
                                height: item.ImageScale == '43' ? 290 : item.ImageScale == '11' ? 290 : 290,
                            };

                            return (
                                <ExhibitionList style={style} >
                                    <ExhibitionListImg style={{ height: '8.5rem' }} onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: item.CompanyName, weburl: '/community/communitydetail/' + item.ProductID + '/exhibition' + '?' + this.state.userPID, })); }}>

                                        {item.MainPicLandScape != "" ? <img src={item.MainPicLandScape} alt="" /> : <img src={imgDefault} alt="" />}
                                        <span />
                                        <ExhibitionIcon>
                                            {item.ProductVideo != "" && <img src={playSvg} alt="" />}
                                            {item.ProductVideo == "" && <img src={imgSvg} alt="" />}
                                        </ExhibitionIcon>
                                    </ExhibitionListImg>
                                    <span>{item.ProductName}</span>
                                    <NewsPub>
                                        <div><img src={item.BussLogo} alt="" /></div><span>{item.CompanyName}</span>
                                    </NewsPub>
                                    <ExhibitionListIcon>
                                        <i className="iconfont">&#xe753;</i>
                                        <span>{item.ClickNum}</span>
                                        <div>|</div>
                                        <i className="iconfont">&#xe755;</i>
                                        <span>{item.LikeNum}</span>
                                        <div>|</div>
                                        <i className="iconfont">&#xe7b2;</i>
                                        <span>{item.CommentNum}</span>
                                    </ExhibitionListIcon>
                                    <ExhibitionListIcon>
                                        <button onClick={() => {
                                            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "编辑参展产品", buttonTitle: '发布', weburl: "/MyExhibition/MyExDetailListPost/" + this.props.match.params.id + "/" + item.ProductID }))
                                            let newIntervalId = 0
                                            let timeEnd = 0
                                            newIntervalId = setInterval(async () => {
                                                timeEnd++
                                                if (timeEnd == 1800) {
                                                    clearInterval(this.state.intervalId);
                                                }
                                                if (localStorage.getItem('REFRESH_TAGS') == 'start') {
                                                    this.getScrollTop();
                                                    pageIndex = 1;
                                                    this.setState({
                                                        refreshing: true
                                                    })
                                                    await this.getList();
                                                    clearInterval(this.state.intervalId);
                                                    localStorage.setItem('REFRESH_TAGS', 'end');
                                                }
                                            }, 1000);
                                            this.setState({
                                                intervalId: newIntervalId
                                            })
                                        }}
                                        >编辑</button>
                                        <button onClick={() => { this.handleRemoveProduct(item.ProductName, item.ProductID) }}>删除</button>
                                    </ExhibitionListIcon>
                                </ExhibitionList>

                            )
                        })}
                    </AutoResponsive>
                </ExhibitionWrapper>
                <div style={{ padding: 30, textAlign: 'center' }}>
                    {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                </div>
            </CommunityWrapper >
        )
    }
}

export default MyExDetailList