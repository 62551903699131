import React, { Component } from 'react';
import List from './countryList.js';

class Brands extends Component {
    render() {
        return (
            <List></List>
        )
    }
}

export default Brands