import styled from 'styled-components';
import {
    themenew
} from '../../common/color';

export const CancellationWrapper = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:#fff;
`
export const CancellationContent = styled.div`
    width:92%;
    margin-left:4%;
    margin-top:1.75rem;
    >div{
        color: #203152;
        font-size:1.125rem;
        margin-bottom:0.8rem;
        font-weight:600;
      }
    >p{
        color: #203152;
        font-size:0.875rem;
        line-height:1.25rem; 
        margin-bottom:0.5rem;
        text-indent:1.6rem;
    }
    >span{
        display:block;
        color: #E33422;
        line-height:1.06rem; 
        font-size:0.75rem;
    }
    >textarea{
        width:100%;
        margin-top:0.5rem;
        border:0;
        background-color:#F7F7F7;
        height:10.9375rem;
        padding:0.8rem;
        line-height:1.25rem;
        color:#000;
    }
`
export const CheckboxWrapper = styled.div`
    margin-top:1.4375rem;
    margin-bottom:1.25rem;
    width:92%;
    margin-left:4%;
`
export const Info = styled.span`
    margin-left:0.5rem;
    color:${themenew.text_color};
    font-size:0.875rem;
    >span{
    color:${themenew.link_color};
    }
`
export const CancellationButton = styled.div`
    width:92%;
    margin-left:4%;
    background-color:#015FEC;
    height:3.125rem;
    color: #fff;
    text-align:center;
    line-height:3.3125rem;
    border-radius:0.375rem;
    font-size:1rem;
`



