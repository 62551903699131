/**
 * created by SunChenyao on 2020/12/11
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsThreeImgWrap,
    NewsItem,
    NewsItemOther,
    NewsTitle,
    NewsTitleOneImg,
    NewsPub,
    VideoImg,
    VideoIcon
} from '../../../../../common/generalStyle';
import {
    NavBar
} from './ContentStyle';

import Label from "./Label";
import httpAxios2 from "../../../../../http/httpAxios2";
import api from "../../../../../http/api";
import {
    startMinsTime
} from '../../../../../utils/utils';
const playSvg = require('../../../../../images/play.svg');

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userPID: this.props.location?.search.replace('?', ''),
            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            // readType: '2',
            // listType: '1',
            targetID: '0',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        // alert(this.props.pname)
        // this.getComType();
        this.getList(null, null, null);

        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    goBackPage = () => {
        if (this.props.location?.search === '?v=1') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goHome" }));
        } else {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
        }
    }

    getListRefresh = async (targetID, readType, listType) => {
        this.setState({
            data: [],
        })
        this.lv.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList(targetID, readType, listType);
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async (targetID, readType, listType) => {
        // if (!readType) readType = this.state.readType;
        // if (!listType) listType = this.state.listType;

        if (!targetID) targetID = this.state.targetID;
        let params;
        params = {
            UserPID: this.state.userPID,
            Type: targetID,
            pageSize: NUM_ROWS,
            pageIndex: pageIndex
        }

        this.setState({ targetID: targetID });

        const res = await httpAxios2(api.userLikeList, params);
        if (res) {
            const str = res.Data.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                        targetID,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                        targetID,
                    })
                }
            } else {
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    // getComType = async () => {
    //     const res = await httpAxios(api.evaluationType);
    //     let typeKeys = {};
    //     if (res) {
    //         res.Data.map(i => {
    //             typeKeys[i.ID] = i.Title
    //         })
    //         this.setState({
    //             comTypes: res.Data,
    //             typeKeys: typeKeys
    //         })
    //     }
    // }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        // setTimeout(() => {
        //     this.setState({
        //         refreshing: false,
        //         isLoading: false,
        //     });
        // }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
            comTypes,
            livedata,
            targetID
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <NewsDetailWrapper >
                        <a onClick={() => {
                            obj.type == 1 ? window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.EvalID + '/news' + '?' + this.state.userPID, })) :
                                obj.type == 2 ? window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.EvalID + '/exhibition' + '?' + this.state.userPID, })) :
                                    obj.type == 3 ? window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.EvalID + '/evaluation' + '?' + this.state.userPID, })) :
                                        obj.type == 4 ? window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.EvalID + '/audition' + '?' + this.state.userPID, })) :
                                            obj.type == 5 ? window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.EvalID + '/community' + '?' + this.state.userPID, })) : window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.PNickName, weburl: '/community/communitydetail/' + obj.EvalID + '/lookback' + '?' + this.state.userPID, }))
                        }}>
                            {
                                obj.ShowType == 3 ?
                                    <NewsItemOther div key={obj.ID} >
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <VideoImg>
                                            <img src={obj.CoverIMG} alt="" />
                                            <VideoIcon>
                                                <img src={playSvg} alt="" />
                                            </VideoIcon>
                                        </VideoImg>
                                    </NewsItemOther >
                                    :
                                    <>
                                        {
                                            obj.ShowType == 0 || obj.ShowType == 1 ?
                                                <NewsItem div key={obj.ID} >
                                                    <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg>
                                                    <NewsImgWrap><img src={obj.CoverIMG} alt="" /></NewsImgWrap>
                                                </NewsItem >
                                                :
                                                <NewsItemOther div key={obj.ID} >
                                                    <NewsTitle>{obj.Title}</NewsTitle>
                                                    <NewsThreeImgWrap>
                                                        {
                                                            obj.ImageAppUrl?.split(';').map((image, index) => (
                                                                index < 3 && <div><img src={image} alt="" /></div>
                                                            ))
                                                        }
                                                    </NewsThreeImgWrap>
                                                </NewsItemOther >
                                        }
                                    </>
                            }
                            <NewsPub><img src={obj.HeadImg} alt="" /><span>{obj.PNickName}</span><span>{obj.ClickNum}浏览</span><span>{startMinsTime(obj.AddTime)}</span></NewsPub>
                        </a>
                    </NewsDetailWrapper>
                </div>
            );
        };
        return (<div>
            <NavBar>
                <Label cb={this.getListRefresh} list={comTypes} />
            </NavBar>
            <div style={{ height: '3rem' }}></div>
            <ListView
                initialListSize={8}
                key={'1'}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (
                    <div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                    </div>)}
                renderRow={row}
                useBodyScroll
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    onRefresh={this.onRefresh}
                />}
                onEndReached={this.onEndReached}
                pageSize={NUM_ROWS}
            />
        </div>);
    }
}

ReactDOM.render(<Content />, document.getElementById('root'));
export default Content