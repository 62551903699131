export const LEVEL_ONE_CLICK='mycenter/LEVEL_ONE_CLICK';
export const BRAND_CLICK='mycenter/BRAND_CLICK';
export const PRODUCT_CLICK='mycenter/PRODUCT_CLICK';
export const NEWS_CLICK='mycenter/NEWS_CLICK';
export const EXHIBITION_CLICK='mycenter/EXHIBITION_CLICK';
export const POST_CLICK='mycenter/POST_CLICK';
export const EVALUATION_CLICK='mycenter/EVALUATION_CLICK';
export const AUDITION_CLICK='mycenter/AUDITION_CLICK';
export const MY_COLLECT_CLICK='mycenter/MY_COLLECT_CLICK';
export const MY_COMMUNITY_CLICK='mycenter/MY_COMMUNITY_CLICK';
export const MY_EVALUATION_CLICK='mycenter/MY_EVALUATION_CLICK';
export const MY_AUDITION_CLICK='mycenter/MY_AUDITION_CLICK';
export const MY_COMMUNITY_POST_CLICK='mycenter/MY_COMMUNITY_POST_CLICK';
export const MY_COMMUNITY_COMMENT_CLICK='mycenter/MY_COMMUNITY_COMMENT_CLICK';
export const MY_COMMUNITY_LIKE_CLICK='mycenter/MY_COMMUNITY_LIKE_CLICK';
export const MY_FOCUS_CLICK='mycenter/MY_FOCUS_CLICK';
export const MY_FANS_CLICK='mycenter/MY_FANS_CLICK';
export const MY_GROUP_CLICK='mycenter/MY_GROUP_CLICK';
export const MY_EVALUATION_POST_CLICK='mycenter/MY_EVALUATION_POST_CLICK';
export const MY_EVALUATION_COMMENT_CLICK='mtcenter/MY_EVALUATION_COMMENT_CLICK';
export const MY_EVALUATION_LIKE_CLICK='mycenter/MY_EVALUATION_LIKE_CLICK';
export const MY_AUDITION_POST_CLICK='mycenter/MY_AUDITION_POST_CLICK';
export const MY_AUDITION_LIKE_CLICK='mycenter/MY_AUDITION_LIKE_CLICK';
export const COMPONENT_USER_CLICK='mycenter/COMPONENT_USER_CLICK';
export const FOCUS_AND_FANS_CLICK='mycenter/FOCUS_AND_FANS_CLICK';