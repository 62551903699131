import React, {
    Component
} from 'react';
import {
    TopWrapper,
    Title,
    Num,
    Fans,
    NumWrapper,
    FixItem,
    FixItemLeft,
    HeadImg,
    EnterpriseName,
    SubTitle,
    BGShading,
    BussButton,
    BussButtonWrapper
} from './BussPortraitStyle';
import { Badge } from 'antd-mobile-v2';
class BussPortrait extends Component {


    render() {
        const {
            info,
            ToMyTotalData
        } = this.props;
        return (
            <>
                <TopWrapper>
                    <FixItemLeft onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '编辑企业信息', weburl: '/personalbuss' })); }}>
                        <i className="iconfont">&#xe6f6;</i>
                    </FixItemLeft>
                    <FixItem onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '设置', weburl: '/settings' })); }}>
                        <i className="iconfont">&#xe771;</i>
                    </FixItem>
                    <BGShading><img src={require("../../../images/BGShadings.png")} /></BGShading>
                    <HeadImg>
                        <img onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '编辑企业信息', weburl: '/personalbuss' })); }} src={info.HeadImg + '?x-oss-process=image/resize,w_278,m_lfit'} alt="" />
                    </HeadImg>
                    <div style={{ height: '14rem' }}></div>
                    <EnterpriseName onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "企业详情", weburl: "/CompanyContentMore" + "?" + info?.UserPID })) }}>{info?.PNickName}</EnterpriseName>
                    <SubTitle onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "企业详情", weburl: "/CompanyContentMore" + "?" + info?.UserPID })) }}>
                        了解企业详情<i className="iconfont">&#xe744;</i>
                    </SubTitle>
                    {/* <PNickName>{info.PNickName === "" ? <span onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: '设置昵称', weburl: '/nickname' })); }}>设置昵称</span> : info.PNickName}</PNickName> */}
                </TopWrapper>

                <NumWrapper>
                    <Fans onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的关注", weburl: "/Focus" + "?" + info.UserPID })) }}>
                        <Num>{info.FollowVal || 0}</Num>
                        <Title>关注</Title>
                    </Fans>
                    <Fans onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的粉丝", weburl: "/Fans" + "?" + info.UserPID })) }}>
                        <Num>{info.FansVal || 0}</Num>
                        <Title>粉丝</Title>
                    </Fans>
                    <Fans onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的点赞", weburl: "/MyLike" + "?" + info.UserPID })) }}>
                        <Num>{info.ZanTotal || 0}</Num>
                        <Title>点赞</Title>
                    </Fans>
                </NumWrapper>
                <BussButtonWrapper>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessage", title: "店铺管理", buttonTitle: "添加", weburl: "/MyShopCenter" + '?' + info.UserPID })) }}>
                        <div><img src={require("../../../images/shangdianBuss.svg")} /></div>
                        <span>店铺管理</span>
                    </BussButton>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "品牌中心", buttonTitle: "新增产品关联", weburl: "/MyBrandsCenter" + '?' + info.UserPID })) }}>
                        <div><img src={require("../../../images/pinpaiBuss.svg")} /></div>
                        <span>品牌中心</span>
                    </BussButton>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "展会⁺管理", weburl: "/MyExhibition" })) }}>
                        <div><img src={require("../../../images/zhanhuiBuss.svg")} /></div>
                        <span>展会⁺管理</span>
                    </BussButton>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessage", title: "直播管理", buttonTitle: "申请直播", weburl: "/MyLive" })) }}>
                        <div><img src={require("../../../images/zhiboBuss.svg")} /></div>
                        <span>直播管理</span>
                    </BussButton>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的发布", weburl: "/release" })) }}>
                        <div><img src={require("../../../images/fabuBuss.svg")} /></div>
                        <span>发布管理</span>
                    </BussButton>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的评论", weburl: "/comment" + "?" + info.UserPID })) }}>
                        <div><img src={require("../../../images/pinglunBuss.svg")} /></div>
                        <span>评论管理</span>
                    </BussButton>
                    {/* <BussButton>
                        <div><img src={require("../../../images/dianzanBuss.svg")} /></div>
                        <span>点赞管理</span>
                    </BussButton> */}
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我的收藏", weburl: "/collection" })) }}>
                        <div><img src={require("../../../images/shoucangBuss.svg")} /></div>
                        <span>收藏管理</span>
                    </BussButton>
                    {/* <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "公告管理", buttonTitle: "发布公告", weburl: "/msgtofans" + '?' + info.UserPID })) }}>
                        <div><img src={require("../../../images/gonggaoBuss.svg")} /></div>
                        <span>公告管理</span>
                    </BussButton> */}
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessage", title: "我的消息", buttonTitle: "消息设置", weburl: "/message" })) }}>
                        <div>
                            <div>
                                <Badge text={ToMyTotalData} overflowCount={99} />
                            </div>
                            <img src={require("../../../images/xiaoxiBuss.svg")} />
                        </div>
                        <span>消息管理</span>
                    </BussButton>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "中乐之星", weburl: "/YiPeiEdit" })) }}>
                        <div><img src={require("../../../images/mingxingBuss.svg")} /></div>
                        <span>中乐之星</span>
                    </BussButton>
                    <BussButton onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "艺人合作", weburl: "/StarEdit" })) }}>
                        <div><img src={require("../../../images/hezuoBuss.svg")} /></div>
                        <span>艺人合作</span>
                    </BussButton>

                </BussButtonWrapper>
            </>
        )
    }
}

export default BussPortrait;