import React, {
    Component
} from 'react';
import {
    Wrapper,
    Title,
    Dec
} from '../FileStyle';

class Infringement extends Component {
    render() {
        return (
            <Wrapper>
                <Title>网络信息不良、违法或侵权举报</Title>
                <Dec style={{textIndex: '0!important'}}>
                    <p>
                        举报电话：4008-0202-01
                    </p>

                    举报邮箱：公司法务部 LEGAL@chinaaudio.net.cn <br/>
                    <br/>
                    特别声明：<br/>
                    <div style={{textIndex: '2rem'}}>
                        本平台严格遵守国家相关法律法规，在此基础上尊重所有用户的一切权益。<br/>
                        被举报者将受到本平台书面正式通知，且有权提起申诉，需提供有法律效力的资料。如被举报者与举报者存在知识产权、名誉权等法律纠纷，本平台建议双方通过法律途径解决。平台暂时删除纠纷内容，最终是否恢复上线以法院判决为准。<br/>
                        被有效举报违法、不良信息达三次的账号将被禁言，达十次的账号将被封号处理，且永不复用。
                    </div>

                </Dec>
            </Wrapper>
        )
    }
}

export default Infringement