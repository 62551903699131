/**
 * created by SunChenyao on 2021/10/08
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { PullToRefresh, ListView } from 'antd-mobile-v2';

import {
    FilterButtons,
    FilterContent,
    FilterContentDeletion,
    FilterContentlist,
    ProductList,
    ProductListTitle,
    ProductListImg,
    ProductListLine,
    ProductListPub
} from './style';

import httpAxios from "../../http/httpAxios";
import api from "../../http/api";

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class FilterResultList extends React.Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            userPID: '',

            dataSource,
            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            readType: '2',
            listType: '1',
            targetID: '0',
            newsType: '5',
            auditionType: '999',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
            search: {
                country: "-1",
                brand: "-1",
                label: "-1"
            },
            searchList: []
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {

        let search = localStorage.getItem("_search");

        if (search != null) {
            let data = search.split(",");

            //标签
            if (parseInt(data[1]) === 1) {
                if (this.state.search.label === "-1") {
                    this.state.search.label = "'" + data[0] + "'";
                }
                else {
                    this.state.search.label += ",'" + data[0] + "'";
                }
            }

            //国别
            if (parseInt(data[1]) === 2) {
                if (this.state.search.country === "-1") {
                    this.state.search.country = "'" + data[0] + "'";
                }
                else {
                    this.state.search.country += ",'" + data[0] + "'";
                }
            }

            this.setState({ searchList: [data[0]] });
        }

        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            })
        }

        window.search = function (data) {
            //构建查询标签
            let tempData = [];
            if (data.country != "-1") {
                let list = data.country.split(',');
                list.forEach(element => {
                    tempData.push(element.replace(/'/g, ""));
                });

            }

            if (data.brand != "-1") {
                let list = data.brand.split(',');
                list.forEach(element => {
                    tempData.push(element.replace(/'/g, ""));
                });

            }

            if (data.label != "-1") {
                let list = data.label.split(',');
                list.forEach(element => {
                    tempData.push(element.replace(/'/g, ""));
                });

            }

            that.setState({ searchList: tempData });

            //记录查询条件
            localStorage.setItem("searchList", JSON.stringify(tempData));

            //记录查询内容
            that.setState({ search: data });
            //开始查询
            that.onRefresh();
        }

        // this.getComType();
        this.getList();

        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;

        setTimeout(() => {
            this.setState({
                height: hei,
                refreshing: false,
                isLoading: false,
            });
        }, 1500);
    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    getList = async () => {
        let params;
        params = {
            "pageSize": NUM_ROWS,
            "pageIndex": pageIndex,
            ...this.state.search
        }

        // alert(JSON.stringify(params));
        const res = await httpAxios(api.getSearch, params);
        if (res) {
            const str = res.Message;
            const count = Number(str);
            this.state.rowCount = count;
            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data,
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.state.hasMore = false;
            }

            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.state.data)
            });

            if (this.state.data.length === this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }

        this.setState({
            refreshing: false,
            isLoading: false,
        });
    }

    onRefresh = async () => {
        pageIndex = 1;
        this.setState({
            data: [],
        })
        this.setState({ refreshing: true, isLoading: true });
        this.getList();
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList();
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    //清除一个标签
    clearOneLabe = (name) => {
        let tempData = [];
        this.state.searchList.forEach(element => {
            if (element != name) {
                tempData.push(element);
            }
        });

        this.setState({ searchList: tempData });

        //记录查询条件
        localStorage.setItem("searchList", JSON.stringify(tempData));

        //查询值
        let tempDataCountry = [];
        if (this.state.search.country != "-1") {
            let list = this.state.search.country.split(',');
            list.forEach(element => {
                let value = element.replace(/'/g, "");
                if (value != name) {
                    tempDataCountry.push(value);
                }
            });

            this.state.search.country = tempDataCountry.length === 0 ? "-1" : "'" + tempDataCountry.join("','") + "'";
        }

        let tempDataBrand = [];
        if (this.state.search.brand != "-1") {
            let list = this.state.search.brand.split(',');
            list.forEach(element => {
                let value = element.replace(/'/g, "");
                if (value != name) {
                    tempDataBrand.push(value);
                }
            });

            this.state.search.brand = tempDataBrand.length === 0 ? "-1" : "'" + tempDataBrand.join("','") + "'";
        }

        let tempDataLabel = [];
        if (this.state.search.label != "-1") {
            let list = this.state.search.label.split(',');
            list.forEach(element => {
                let value = element.replace(/'/g, "");
                if (value != name) {
                    tempDataLabel.push(value);
                }
            });

            this.state.search.label = tempDataLabel.length === 0 ? "-1" : "'" + tempDataLabel.join("','") + "'";
        }

        //重新查询数据
        this.onRefresh();
    }

    //清除所有标签
    clearAll = () => {
        this.setState({ searchList: [] });
        //记录查询条件
        localStorage.setItem("searchList", "");

        this.state.search.country = "-1";
        this.state.search.brand = "-1";
        this.state.search.label = "-1";

        //重新查询数据
        this.onRefresh();
    }

    //标签直查
    selectLabel = (name, type) => {
        let list = this.state.searchList.filter(item => item === name);
        if (list.length > 0) {
            return;
        }

        let tempData = [name, ...this.state.searchList];
        this.setState({ searchList: tempData });

        //记录查询条件
        localStorage.setItem("searchList", JSON.stringify(tempData));

        //标签
        if (type === 1) {
            if (this.state.search.label === "-1") {
                this.state.search.label = "'" + name + "'";
            }
            else {
                this.state.search.label += ",'" + name + "'";
            }
        }

        //国别
        if (type === 2) {
            if (this.state.search.country === "-1") {
                this.state.search.country = "'" + name + "'";
            }
            else {
                this.state.search.country += ",'" + name + "'";
            }
        }

        //重新查询数据
        this.onRefresh();
    }

    render() {
        const {
            data,
            searchList
        } = this.state;

        const row = (rowData, sectionID, rowID) => {
            // console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }

            //alert(JSON.stringify(obj));
            return (
                <div key={rowID}>
                    <a onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: obj.BrandName, weburl: '/Brands/ProductDetail/' + obj.ID })); }}>
                        <ProductList>
                            <ProductListTitle>{obj.ProductName}</ProductListTitle>
                            <ProductListImg><img src={obj.MainPicLandScape} alt="" /></ProductListImg>
                            <ProductListPub>
                                <img src={obj.BrandLogo} alt="" />
                                <span>{obj.BrandName}</span>
                                <span>{obj.BrandCountry}</span>
                            </ProductListPub>
                            <ProductListLine />
                        </ProductList>
                    </a>
                </div>
            );
        };
        return (<div>
            <FilterButtons>
                <div onClick={() => { this.selectLabel("真无线耳机", 1) }}>真无线耳机</div>
                <div onClick={() => { this.selectLabel("落地音箱", 1) }}>落地音箱</div>
                <div onClick={() => { this.selectLabel("美国", 2) }}>美国</div>
                {/* <div onClick={() => { this.selectLabel("英国", 2) }}>英国</div> */}
                <span onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "modal", title: '更多筛选条件', weburl: "/Brands/MoreFilter" })) }}>更多筛选<i className='iconfont'>&#xe744;</i></span>
            </FilterButtons>
            <div style={{ height: '3.75rem' }}></div>
            <div style={{ position: 'relative' }}>
                <FilterContentDeletion onClick={() => this.clearAll()}><span>清除</span><i className='iconfont'>&#xe748;</i></FilterContentDeletion>
                <FilterContent>
                    {searchList.map(item => (
                        <FilterContentlist key={item}>{item}<i className='iconfont' onClick={() => this.clearOneLabe(item)}>&#xe74c;</i></FilterContentlist>
                    ))}
                    <div style={{ width: '5rem', flexShrink: '0' }}></div>{/* 作用是调整最后的标签不被清除按钮遮住 */}
                </FilterContent>
            </div>
            <ListView
                initialListSize={8}
                key={'1'}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (
                    <div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? '加载中...' : '-- 已经是最后一条声浪了 --'}
                    </div>)}
                renderRow={row}
                useBodyScroll
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    onRefresh={this.onRefresh}
                />}
                onEndReached={this.onEndReached}
                pageSize={NUM_ROWS}
            />
        </div>);
    }
}

ReactDOM.render(<FilterResultList />, document.getElementById('root'));
export default FilterResultList