import React, {
    Component
} from 'react';
import { List } from 'antd-mobile-v2';
import {
    SettingsWrapper,
} from '../MycenterPortraitStyle';

import api from "../../../../http/api";
import httpAxios from "../../../../http/httpAxios";

const Item = List.Item;

class StarEdit extends Component {
    state = {
        info: ''
    };

    componentDidMount() {
        var that = this;
        window.userInfo = function (data, params) {
            if (data != "") {
                httpAxios(api.getUserLoginInfo, {
                    userID: data.UserPID,
                    // token: data.Token
                }).then(res => {
                    if (res.Code === 200) {
                        that.setState({ info: res.Data });
                    }
                }).catch(e => {
                })

            }
        }

    }

    render() {
        const {
            info,
        } = this.state;
        return (
            <SettingsWrapper>
                <List renderHeader={() => ''}>
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我发布的", weburl: "/StarMyRelease" })); }}>我发布的</Item>
                    {info.IsStarUser && <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "个人资料展示", weburl: "/StarInfo" })); }}>个人资料展示</Item>}
                    {info.IsBussUser && <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "企业资料展示", weburl: "/CompanyInfo" })); }}>企业资料展示</Item>}
                    <Item className='listheight' arrow="horizontal" onClick={() => { window.ReactNativeWebView.postMessage(JSON.stringify({ type: "detailnav", title: "我感兴趣的", weburl: "/StarMyLike" })); }}>我感兴趣的</Item>
                </List>
            </SettingsWrapper>
        )
    }
}

export default StarEdit;