import styled from 'styled-components';

export const CommunityWrapper = styled.div`
  width:100%;
  margin:0 auto;
`
export const SearchWrapper = styled.div`
  width:100%; 
	height:3.25rem;
  background: #fff;
  padding-top: 0.625rem;
  //border-bottom: 0.0625rem solid #E6E6E6;
  button{
    float: right;
    margin-right: 3%;
    width: 13%;
    background: #fff;
    height: 2rem;
    color: #015FEC;
    padding:0;
  }
`
export const SearchInput = styled.div`
  width:78%; 
	height:2rem;
  background: #F3F5F9;
  border-radius: 0.375rem;
  line-height: 2rem;
  margin-left: 4%;
  display: inline-block;
  i{
    font-size: 1.4rem;
    color: #212C68;
    float: left;
    margin: 0 0.625rem;
  }
  input{
    height: 100%;
    width: 80%;
    border:0;
    background: #F3F5F9;
    float: left;
    font-size: 0.875rem;
  }
`
export const FansListWrapper = styled.div`
  width: 100%;
  background: #fff;
`
export const FansWrapper = styled.div`
  width: 96%;
  margin-left: 4%;
  height: 4.625rem;
  //line-height: 4.625rem;
  //border-bottom: 0.0625rem solid #E6E6E6;
  img{
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    border:0.0625rem solid #fff;
    float: left;
    margin-top: 0.75rem;
    margin-right: 0.625rem;
  }
  span{
    max-width: 50%;
    font-size: 1rem;
    color: #203152;
    float: left;
    margin-top: 1.625rem;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap
  }
  button{
    width: 5rem;
    height: 1.75rem;
    float: right;
    margin-top: 1.4375rem;
    margin-right: 1rem;
    font-size: 0.875rem;
    color: #203152;
    background: #F7F7F7;
    border-radius: 0.25rem;
    padding:0;
  }
  .buttonbg{
    background-color: #015FEC;
    color: #fff;
  }
`