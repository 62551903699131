import React, {
    Component
} from 'react';
import {
    Wrapper,
    Title,
    Dec
} from '../FileStyle';

class LinkUs extends Component {
    render() {
        return (
            <Wrapper>
                <Title>联系我们</Title>
                <Dec>
                    <div style={{textIndent: 0}}>
                        平台官方客服电话：4008-0202-01 <br/>
                        中乐之声：ZYZS@chinaaudio.net.cn <br/>
                        内容部：CP@chinaaudio.net.cn <br/>
                        市场部：MKT@chinaaudio.net.cn <br/>
                        媒介部：MEDIA@chinaaudio.net.cn <br/>
                        法务部：LEGAL@chinaaudio.net.cn <br/>
                        通讯地址：北京中关村房山园超级蜂巢科技广场北区
                    </div>
                </Dec>
            </Wrapper>
        )
    }
}

export default LinkUs