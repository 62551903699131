import styled from 'styled-components';
import {
  themenew
} from '../../common/color';

export const Tabs = styled.div`
  width:100%;
  //height:2.2rem;
  margin:0 auto;
  display:flex;
  overflow-x:auto;
  overflow-y:hidden;
  font-size:1rem;
  //background-color:red;
  ::-webkit-scrollbar{
		width:0;
		height: 0;
		display:none;
	}
  >div{
    padding:0 4%;
    flex-shrink: 0;
    color:${themenew.text_color};
    margin-top:0.33rem;
    >div{
      width:1.625rem;
      height:0.1875rem;
      margin:0.3rem auto 0;
      background-color:#4228CF;
      border-radius:0.093rem;
    }
  }
  .selected{
    font-size:1rem;
    font-weight:700;
  }
`