import styled from 'styled-components';
import {
  theme, themenew
} from '../../../common/color';

export const ReportWrapper = styled.div`
  width:100%;
  background: #fff;
  h6{
    width:100%;
    height:4.6rem;
    background:#F7F7F7;
    padding:0.95rem;
    font-size:0.875rem;
    line-height:1.25rem;
    margin-top:1rem;
    margin-bottom:0.9375rem;
    span{
      color: ${themenew.link_color};
    }
  }
`
export const SubText = styled.div`
  font-size: 0.75rem;
  color: #7C8698;
  padding-top: 1.125rem;
  padding-bottom: 0.625rem;
`
export const ItemSelected = styled.span`
  display: inline-block;
  margin: 0.3125rem 0.25rem;
  padding: 0 0.9375rem;
  line-height: 1.875rem;
  border-radius: 0.25rem;
  background-color: ${themenew.link_color};
  color: ${themenew.text_white_color};
  font-size: 0.875rem;
`
export const Item = styled.span`
  display: inline-block;
  margin: 0.3125rem 0.25rem;
  padding: 0 0.9375rem;
  line-height: 1.875rem;
  border-radius: 0.25rem;
  background-color: #F7F7F7;
  color: ${themenew.text_color};
  font-size: 0.875rem;
`
export const ReportInput = styled.textarea`
  width: 100%;
  height: 4.56rem;
  border-width: 0;
  background: #F7F7F7;
  font-size: 0.875rem;
  padding:0.625rem;
`
export const UpImg = styled.div`
  width: 5.3rem;
  height: 5.3rem;
  border:#D6D6D6 0.0625rem solid;
  line-height: 5.3rem;
  text-align: center;
  float: left;
  margin-right: 0.5rem;

  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  i{
      font-size: 3rem;
      color: #D6D6D6;
  }
  img{
    width: 100%;
    min-height:5.3rem;
    object-fit: cover;
  }
`
export const ReportCode = styled.div`
  input{
    width: 75%;
    height: 1.875rem;
    background: #F7F7F7;
    border-width: 0;
    padding-left:0.625rem;
  }
  span{
      width: 23%;
      margin-left: 2%;
      display: inline-block;
      background-color: #dedede;
      height: 1.875rem;
      line-height: 1.875rem;
      text-align: center;
  }
`
export const ReportContacts = styled.input`
    width: 100%;
    height: 1.875rem;
    background: #F7F7F7;
    border-width: 0;
    padding-left:0.625rem;
`
export const ReportButton = styled.div`
    width: 7.5rem;
    height: 1.875rem;
    background: ${themenew.link_color};
    line-height: 1.875rem;
    color: #fff;
    font-size: 0.875rem;
    text-align: center;
    margin: 1.25rem 0;
    float: right;
    border-radius: 0.25rem;
`


