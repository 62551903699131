/**
 * created by zhangling on 2020/2/24
 */

const EventType = {
    messageType: 'messageType',
    messageUser: 'messageUser',
    routerChange: 'routerChange'
}
export default EventType
