import React, {
    Component
} from 'react';

import {
    Wrapper,
    NewsPub,
    NewsTitle,
    NewsSubTitle,
    NewsLeft
} from './style';

import ReactDOM from 'react-dom';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import {
    NewsDetailWrapper,
    NewsImgWrap,
    NewsItem,
} from '../../../../common/generalStyle';

import httpAxios from "../../../../http/httpAxios";
import api from "../../../../http/api";

import {
    message,
} from "antd";

const NUM_ROWS = 10;
let pageIndex = 1;

function genData(pIndex = 0) {
    const dataArr = [];
    for (let i = 0; i < NUM_ROWS; i++) {
        dataArr.push(`row - ${(pIndex * NUM_ROWS) + i}`);
    }
    return dataArr;
}

class MyLive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userPID: '',

            refreshing: false,
            isLoading: true,
            height: document.documentElement.clientHeight,

            data: [],
            comTypes: [],
            typeKeys: {},
            targetID: '5',
            hasMore: true,
            rowCount: 0,

            liveID: 1,
            livedata: [],
            total: 0,
            pageSize: 10,
        };
    }

    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }

    componentDidMount() {
        pageIndex = 0;

        var that = this;
        window.userInfo = function (data, params) {
            that.setState({
                userPID: data.UserPID
            }
                // ,
                //     () => that.getListRefresh()
            )
        }

        //右上角按钮动作
        window.saveInfo = function () {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "添加直播", buttonTitle: "提交", weburl: "/MyLive/AddLiveApply" }))
        }

        // this.setState({
        //     userPID: '3394EBC9-3609-4D77-A5BF-083923B69329'
        // }, () => {

        //     this.getListRefresh();
        // })


        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 1500);


    }

    goBackPage = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "goBack" }));
    }

    getListRefresh = async () => {
        window.scrollTo(0, 0);
        this.state.refreshing = true;
        pageIndex = 1;
        await this.getList();
        this.state.refreshing = false;
        this.setState({
            isLoading: false,
        });
    }

    getList = async () => {

        if (this.state.userPID == "") {
            return;
        }

        const res = await httpAxios(api.getApplyLiveVideoList, {
            userBID: this.state.userPID,
            pageSize: this.state.pageSize,
            pageIndex: pageIndex,
        })
        // console.log(res);
        if (res) {
            const count = Number(res.Message);
            this.state.rowCount = count;

            if (res.Data.length > 0) {
                if (this.state.refreshing) {
                    this.setState({
                        data: res.Data
                    })
                }
                else {
                    let tempData = [...this.state.data, ...res.Data];

                    this.setState({
                        data: tempData,
                    })
                }
            } else {
                this.setState({
                    data: []
                })
                this.state.hasMore = false;
            }

            if (this.state.data.length == this.state.rowCount) {
                this.state.hasMore = false;
            }
            else {
                this.state.hasMore = true;
            }
        }
    }

    //删除申请的直播
    deleteApplyLive = async (id) => {
        this.setState({ refreshing: true });

        const res = await httpAxios(api.deleteApplyLive, {
            userBID: this.state.userPID,
            liveID: id
        })
        if (res.Code === 200) {
            message.info('删除成功');
            this.getList();
        }
    }

    getComType = async () => {
        const res = await httpAxios(api.newsType);
        let typeKeys = {};
        if (res) {
            res.Data.map(i => {
                typeKeys[i.ID] = i.Title
            })
            this.setState({
                comTypes: res.Data,
                typeKeys: typeKeys
            })
        }
    }

    onRefresh = async () => {
        this.setState({ refreshing: true, isLoading: true });
        await this.getListRefresh(null, null, null);
        setTimeout(() => {
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }, 600);
    };

    onEndReached = async (event) => {
        if (!this.state.hasMore) {
            return;
        }
        ++pageIndex;
        await this.getList(null, null, null);
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    };

    render() {
        const {
            data,
            comTypes,
            livedata,
            targetID
        } = this.state;
        const row = (rowData, sectionID, rowID) => {
            console.log(rowID);
            let obj = {};
            if (rowID < data.length) {
                obj = data[rowID];
            } else {

            }
            return (
                <div key={rowID}>
                    <NewsDetailWrapper >
                        <NewsItem div key={obj.ID} >
                            {/* <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg> */}
                            <NewsLeft>
                                <NewsTitle>{obj.Title}</NewsTitle>
                                <NewsSubTitle>{obj.Intro}</NewsSubTitle>
                            </NewsLeft>
                            <NewsImgWrap><img src={obj.ImageUrl} alt="" /></NewsImgWrap>
                        </NewsItem >
                        <NewsPub>
                            <span>直播时间</span><span>{obj.BeginTime}</span>
                            {obj.State === -1 && <span style={{ color: '#FF0000' }}>未通过</span>}
                            {obj.State === 0 && <span style={{ color: '#DA530D' }}>审核中</span>}
                            {obj.State === 1 && obj.LiveIsUse === 1 && <span style={{ color: '#149E06' }}>通过/未直播</span>}
                            {obj.State === 1 && obj.LiveIsUse === 2 && <span style={{ color: '#149E06' }}>通过/直播中</span>}
                            {obj.LiveIsUse === 3 && obj.State === 1 && <span style={{ color: '#A5A5A5' }}>已结束</span>}
                            <div><i className='iconfont' onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "修改", buttonTitle: "发布", weburl: "/MyLive/AddLiveApply?" + obj.ID }))}>&#xe6f6;</i><i className='iconfont' onClick={() => this.deleteApplyLive(obj.ID)} >&#xe748;</i></div>
                        </NewsPub>
                    </NewsDetailWrapper>
                </div >
            );
        };
        return (<div>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000)
                    await this.onRefresh();
                }}
            >
                {
                    data.map((obj, indexKey) => (
                        <div key={indexKey}>
                            <NewsDetailWrapper >
                                <NewsItem div key={obj.ID} >
                                    {/* <NewsTitleOneImg>{obj.Title}</NewsTitleOneImg> */}
                                    <NewsLeft>
                                        <NewsTitle>{obj.Title}</NewsTitle>
                                        <NewsSubTitle>{obj.Intro}</NewsSubTitle>
                                    </NewsLeft>
                                    <NewsImgWrap><img src={obj.ImageUrl} alt="" /></NewsImgWrap>
                                </NewsItem >
                                <NewsPub>
                                    <span>直播时间</span><span>{obj.BeginTime}</span>
                                    {obj.State === -1 && <span style={{ color: '#FF0000' }}>未通过</span>}
                                    {obj.State === 0 && <span style={{ color: '#DA530D' }}>审核中</span>}
                                    {obj.State === 1 && obj.LiveIsUse === 1 && <span style={{ color: '#149E06' }}>通过/未直播</span>}
                                    {obj.State === 1 && obj.LiveIsUse === 2 && <span style={{ color: '#149E06' }}>通过/直播中</span>}
                                    {obj.LiveIsUse === 3 && obj.State === 1 && <span style={{ color: '#A5A5A5' }}>已结束</span>}
                                    <div><i className='iconfont' onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: "mymessagedetails", title: "修改", buttonTitle: "发布", weburl: "/MyLive/AddLiveApply?" + obj.ID }))}>&#xe6f6;</i><i className='iconfont' onClick={() => this.deleteApplyLive(obj.ID)} >&#xe748;</i></div>
                                </NewsPub>
                            </NewsDetailWrapper>
                        </div>
                    ))
                }
            </PullToRefresh>
            <InfiniteScroll loadMore={this.onEndReached} hasMore={this.state.hasMore} />
        </div >);
    }
}

ReactDOM.render(<MyLive />, document.getElementById('root'));
export default MyLive